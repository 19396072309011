<template>
  <div class="tile dashboard-widget is-child" >
    <p class="widget-title">Site Areas/Floors</p>
    <div class="widget-content">
      <div class="mt-2" >
        <div
          v-for="(building, index) in site.$buildings.sort(alphanumericSortByProperty('$displayString'))"
          :key="building.id"
          :class="{ 'mt-2': index > 0 }"
        >
            <div class="is-inline-flex" >
            <building-display
              :value="building"
              :show-floors="false"
              :show-link-icon="building.$area.$calibrationState !== 'uncalibrated'"
              :show-training-mode-tooltip="false"
              show-training-mode-warning
            />
              <b-tooltip type="is-danger" active label="Area has not been trained" position="is-bottom" v-if="building.$area.$calibrationState === 'uncalibrated'">
                <b-icon class="ml-3 pointer fa-throb" icon="exclamation-circle" type="is-danger" @click.native="building.$goToTrainingOverview()"/>
              </b-tooltip>
            </div>
          <div
            class="floors"
            v-if="building.$floors && building.$floors.length"
          >
            <div v-for="floor in building.$floors" :key="floor" class="floor">
              <b-icon icon="crosshairs" class="ml-6" /><span class="ml-1"
                >Floor: {{ floor }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash";

export default {
  name: "SiteBuildingsFloorsTile",
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sortBy,
  },
};
</script>

<style scoped></style>
