<template>
  <div>
    <loading-card v-if="loading" :sections="['body', 'footer']" />
    <b-message v-else type="is-danger" class="has-text-centered">
      <b>Warning</b>: Continuing will sign
      <span v-if="currentUser.uid === userAccount.id">you</span>
      <span v-else>{{ userAccount._additional.display_name }}</span>
      out of AquaSeca on
      <b>all</b> devices, including the current one.
      <p class="has-text-weight-bold mt-6 mb-6">
        Are you sure you wish to do this?
      </p>
      <b-button expanded type="is-danger" @click="signOutOfAll()">
        <span v-if="currentUser.uid === userAccount.id"
          >Yes, I wish to sign out all of my devices</span
        >
        <span v-else
          >Yes, please sign {{ userAccount._additional.display_name }} out of
          all devices</span
        >
      </b-button>
    </b-message>
    <b-button
      expanded
      type="is-primary"
      v-if="currentUser.uid === userAccount.id"
      @click="$router.push({ name: 'logout' })"
    >
      I'd just like to log out of the current device
    </b-button>
    <hr />
  </div>
</template>

<script>

import { mapState } from "vuex";
import userDataMixin from "../../mixins/userDataMixin";
import LoadingCard from "../../components/Skeletons/LoadingCard";

export default {
  name: "SignOutOfAllDevices",
  mixins: [userDataMixin],
  components: { LoadingCard },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    signOutOfAll() {
      let router = this.$router,
        uid = this.userAccount.id,
        self = this;
      if (!uid) {
        throw "No user to revoke token for";
      }
      self.loading = true;
      this.$client
        .get(`/users/revoke-refresh-tokens/${uid}/`)
        .then((res) => {
          self.loading = false;
          if (res.success) {
            this.$buefy.dialog.alert({
              title: "All tokens revoked",
              message:
                "ID tokens for all devices have been revoked. " +
                  "This may take up to 1 hour to propagate to each device. " +
                  self.currentUser.uid ===
                self.userAccount.id
                  ? "<br/><br/>Please log-in again to continue."
                  : "All tokens have been revoked for " +
                    self.userAccount.email +
                    ".",
              onConfirm: () => {
                if (this.currentUser.uid === this.userAccount.id) {
                  router.push({ name: "logout" });
                }
              },
            });
          } else {
            self.$handleError(res.error, res.detail);
          }
        })
        .catch((e) => self.$handleError(e));
    },
  },
};
</script>

<style scoped></style>
