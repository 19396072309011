var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"draggable",rawName:"v-draggable:options",value:(_vm.options),expression:"options",arg:"options"}],staticClass:"aq-image-map-wrapper"},[(!_vm.loaded)?_c('loading-chart-card'):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],ref:"canvas",staticClass:"aq-image-map-canvas",attrs:{"id":"aq-image-map-canvas","width":_vm.fileObject.metadata.w,"height":_vm.fileObject.metadata.h,"src":_vm.getImageUrl(_vm.fileObject)},on:{"load":function($event){_vm.loaded = true}}}),(!_vm.loadedTimeoutMet)?_c('b-loading',{attrs:{"is-full-page":false}}):_c('div',[_c('div',{staticClass:"ok"}),_vm._l((_vm.positionedSensors),function(sensor){return _c('div',{key:sensor.obj.path,class:{
        'positioned draggable': true,
        'non-editable': !_vm.entityIsEditable(sensor),
      },style:(_vm.getStyleTag(sensor)),attrs:{"data-path":sensor.obj.path}},[(_vm.objectByID(sensor.obj.id, null, _vm.sensorPositions))?_c('span',[_c('b-icon',{class:_vm.entityIsEditable(sensor) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-sensor","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{ref:'generic-display-' + sensor.obj.id,refInFor:true,attrs:{"classes":"inline-block","collapsible":"","show-link-icon":false,"value":_vm.objectByID(sensor.obj.id, {}, _vm.sensorPositions)},scopedSlots:_vm._u([(_vm.entityIsEditable(sensor))?{key:"end",fn:function(){return [_c('a',{staticClass:"is-xs pointer",on:{"click":function($event){return _vm.resetPath(sensor.obj.path)}}},[_vm._v("☒")])]},proxy:true}:null],null,true)})],1)],1):_vm._e()])}),_vm._l((_vm.positionedGateways),function(gw){return _c('div',{key:gw.obj.path,class:{
        'positioned draggable': true,
        'non-editable': !_vm.entityIsEditable(gw),
      },style:(_vm.getStyleTag(gw)),attrs:{"data-path":gw.obj.path}},[(_vm.objectByID(gw.obj.id, null, _vm.gatewayPositions))?_c('span',[_c('b-icon',{class:_vm.entityIsEditable(gw) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-gateway","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{ref:'generic-display-' + gw.obj.id,refInFor:true,attrs:{"classes":"inline-block","collapsible":"","show-link-icon":false,"value":_vm.objectByID(gw.obj.id, {}, _vm.gatewayPositions)},scopedSlots:_vm._u([(_vm.entityIsEditable(gw))?{key:"end",fn:function(){return [_c('a',{staticClass:"is-xs pointer",on:{"click":function($event){return _vm.resetPath(gw.obj.path)}}},[_vm._v("☒")])]},proxy:true}:null],null,true)})],1)],1):_vm._e()])}),_vm._l((_vm.positionedSources),function(src){return _c('div',{key:src.obj.path,class:{
        'positioned draggable': true,
        'non-editable': !_vm.entityIsEditable(src),
      },style:(_vm.getStyleTag(src)),attrs:{"data-path":src.obj.path}},[(_vm.objectByID(src.obj.id, null))?_c('span',[_c('b-icon',{class:_vm.entityIsEditable(src) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-source","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{ref:'generic-display-' + src.obj.id,refInFor:true,attrs:{"classes":"inline-block","collapsible":"","show-link-icon":false,"value":_vm.objectByID(src.obj.id)},scopedSlots:_vm._u([(_vm.entityIsEditable(src))?{key:"end",fn:function(){return [_c('a',{staticClass:"is-xs pointer",on:{"click":function($event){return _vm.resetPath(src.obj.path)}}},[_vm._v("☒")])]},proxy:true}:null],null,true)})],1)],1):_vm._e()])}),_c('div',{staticClass:"image-map-buttons mb-6 is-clearfix"},[(_vm.positions && _vm.positions.length)?_c('div',{staticClass:"buttons is-pulled-left"},[(_vm.positions && _vm.positions.length)?_c('b-button',{on:{"click":_vm.expandAll}},[_vm._v(" Expand all ")]):_vm._e(),(_vm.positions && _vm.positions.length)?_c('b-button',{on:{"click":_vm.collapseAll}},[_vm._v(" Collapse all ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"buttons is-pulled-right"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.savingImageMap},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)]),_c('div',{staticClass:"non-positioned"},[_c('p',{staticClass:"has-text-centered has-text-weight-bold"},[_vm._v("Unplaced items")]),(_vm.nonPositionedEntities.length)?_c('div',{staticClass:"unplaced-inner"},[_c('div',{staticClass:"entities ml-5"},[_vm._l((_vm.nonPositionedSensors.filter(
              (s) => !_vm.entityParentId(s)
            )),function(sensor){return _c('div',{key:sensor.obj.path,class:{
              draggable: true,
              'non-editable': !_vm.entityIsEditable(sensor),
            },attrs:{"data-path":sensor.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(sensor) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-sensor","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(sensor.obj.id, {}, _vm.sensorPositions),"show-link-icon":false,"classes":"inline-block"}})],1)],1)}),_vm._l((_vm.nonPositionedGateways.filter(
              (s) => !_vm.entityParentId(s)
            )),function(gw){return _c('div',{key:gw.obj.path,class:{
              draggable: true,
              'non-editable': !_vm.entityIsEditable(gw),
            },attrs:{"data-path":gw.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(gw) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-gateway","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(gw.obj.id, {}, _vm.gatewayPositions),"show-link-icon":false,"classes":"inline-block"},scopedSlots:_vm._u([{key:"end",fn:function(){return [_vm._v(" "+_vm._s(_vm.entityParentId(gw))+" ")]},proxy:true}],null,true)})],1)],1)}),_vm._l((_vm.nonPositionedSources.filter(
              (s) => !_vm.entityParentId(s)
            )),function(src){return _c('div',{key:src.obj.path,class:{
              draggable: true,
              'non-editable': !_vm.entityIsEditable(src),
            },attrs:{"data-path":src.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(src) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-source","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(src.obj.id),"show-link-icon":false,"classes":"inline-block"}})],1)],1)})],2),_vm._l((_vm.buildings),function(building){return _c('div',{key:building.id,staticClass:"ml-5"},[_c('div',[_c('building-display',{attrs:{"value":building}})],1),_c('div',{staticClass:"entities ml-5"},[_vm._l((_vm.nonPositionedSensors.filter(
                (s) => _vm.entityParentId(s) === building.id
              )),function(sensor){return _c('div',{key:sensor.obj.path,class:{
                draggable: true,
                'non-editable': !_vm.entityIsEditable(sensor),
              },attrs:{"data-path":sensor.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(sensor) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-sensor","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(sensor.obj.id, {}, _vm.sensorPositions),"show-link-icon":false,"classes":"inline-block"}})],1)],1)}),_vm._l((_vm.nonPositionedGateways.filter(
                (s) => _vm.entityParentId(s) === building.id
              )),function(gw){return _c('div',{key:gw.obj.path,class:{
                draggable: true,
                'non-editable': !_vm.entityIsEditable(gw),
              },attrs:{"data-path":gw.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(gw) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-gateway","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(gw.obj.id, {}, _vm.gatewayPositions),"show-link-icon":false,"classes":"inline-block"}})],1)],1)}),_vm._l((_vm.nonPositionedSources.filter(
                (s) => _vm.entityParentId(s) === building.id
              )),function(src){return _c('div',{key:src.obj.path,class:{
                draggable: true,
                'non-editable': !_vm.entityIsEditable(src),
              },attrs:{"data-path":src.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(src) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-source","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(src.obj.id),"show-link-icon":false,"classes":"inline-block"}})],1)],1)})],2),_vm._l((_vm.rooms.filter(
              (r) => r.building && r.building.id === building.id
            )),function(room){return _c('div',{key:room.id,staticClass:"ml-5"},[_c('room-display',{attrs:{"value":room}}),_c('div',{staticClass:"entities ml-5"},[_vm._l((_vm.nonPositionedSensors.filter(
                  (s) => _vm.entityParentId(s) === room.id
                )),function(sensor){return _c('div',{key:sensor.obj.path,class:{
                  draggable: true,
                  'non-editable': !_vm.entityIsEditable(sensor),
                },attrs:{"data-path":sensor.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(sensor) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-sensor","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(sensor.obj.id, {}, _vm.sensorPositions),"show-link-icon":false,"classes":"inline-block"}})],1)],1)}),_vm._l((_vm.nonPositionedGateways.filter(
                  (s) => _vm.entityParentId(s) === room.id
                )),function(gw){return _c('div',{key:gw.obj.path,class:{
                  draggable: true,
                  'non-editable': !_vm.entityIsEditable(gw),
                },attrs:{"data-path":gw.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(gw) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-gateway","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(gw.obj.id, {}, _vm.gatewayPositions),"show-link-icon":false,"classes":"inline-block"}})],1)],1)}),_vm._l((_vm.nonPositionedSources.filter(
                  (s) => _vm.entityParentId(s) === room.id
                )),function(src){return _c('div',{key:src.obj.path,class:{
                  draggable: true,
                  'non-editable': !_vm.entityIsEditable(src),
                },attrs:{"data-path":src.obj.path}},[_c('b-icon',{class:_vm.entityIsEditable(src) ? 'handler' : 'no-handler',attrs:{"icon":"circle","type":"is-source","size":"is-small"}}),_c('span',{staticClass:"boxy"},[_c('generic-display',{attrs:{"value":_vm.objectByID(src.obj.id),"show-link-icon":false,"classes":"inline-block"}})],1)],1)})],2)],1)})],2)})],2):_c('div',{staticClass:"has-text-centered"},[_c('i',[_vm._v("No items in this scope")])]),_c('div',{staticClass:"legend mt-3"},[_c('p',{staticClass:"has-text-centered has-text-weight-bold"},[_vm._v("Legend")]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"level-left"},[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"circle","type":"is-source","size":"is-small"}})],1),_c('div',{staticClass:"level-right"},[_vm._v("Point-of-use")])]),_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"level-left"},[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"circle","type":"is-gateway","size":"is-small"}})],1),_c('div',{staticClass:"level-right"},[_vm._v("Gateway")])]),_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"level-left"},[_c('b-icon',{staticClass:"mr-3",attrs:{"icon":"circle","type":"is-sensor","size":"is-small"}})],1),_c('div',{staticClass:"level-right"},[_vm._v("Sensor")])])])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }