<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Attach probe to sensor</div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small"/>
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step">
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <p class="is-size-5">
              Select the building where you will be installing this probe
            </p>
            <building-radio
                :value="building"
                @input="handleBuildingSelection"
            />
          </div>
        </b-step-item>

        <b-step-item :step="1" label="Sensor" :type="stepType(1)">
          <transition name="slide">
            <div
                class="column is-offset-2-desktop is-8-desktop"
                v-if="!selectedSensor"
            >
              <p class="is-size-5">
                Which sensor are you attaching the probe to?
              </p>
              <scan-or-select-device
                  :area="area"
                  ref="step1Scan"
                  device-state="device_installed"
                  :device-types="['sensor']"
                  label=""
                  method-query-key="sm1"
                  @input="handleSensorSelection"
                  :selected-serial="sensorSerial"
                  @update:loading="componentLoading = $event"
              />
            </div>
          </transition>
          <transition name="slide">
            <div v-if="selectedSensor" class="column has-text-centered">
              <div class="has-text-centered">
                <p class="is-size-5">
                  You have selected sensor <b>{{ sensorSerial }}</b>
                </p>
                <div>
                  <b-button
                      icon-left="times"
                      outlined
                      size="is-small"
                      type="is-danger"
                      @click="
                      sensorSerial = null;
                      selectedSensor = null;
                    "
                  >
                    Change selected probe
                  </b-button>
                </div>
              </div>
              <div class="mt-3">
                <p class="is-size-5">
                  Which sensor port will you plug the probe into?
                </p>
                <transition name="slide">
                  <b-icon
                      size="is-large"
                      icon="sync"
                      class="fa-spin"
                      v-if="loading"
                  />
                </transition>
                <transition name="slide">
                  <b-field
                      v-if="!loading"
                      :message="
                      $availablePorts && $availablePorts.length
                        ? null
                        : 'There are no ports available'
                    "
                      :type="
                      $availablePorts && $availablePorts.length
                        ? null
                        : 'is-danger'
                    "
                  >
                    <sensor-port-radio
                        v-model="port"
                        horizontal
                        :selectable-options="$availablePorts"
                    >
                      <template #post-label="slotProps">
                        <i v-if="!$availablePorts.includes(slotProps.port)">
                          (in use by probe
                          <router-link
                              :to="{
                              name: 'devices:probe-detail',
                              params: {
                                serial:
                                  position['port_' + slotProps.port].serial,
                              },
                            }"
                          >
                            {{ position["port_" + slotProps.port].serial }}
                          </router-link>
                          )
                        </i>
                      </template>
                    </sensor-port-radio>
                  </b-field>
                </transition>
              </div>
            </div>
          </transition>
        </b-step-item>

        <b-step-item :step="2" label="Probe" :type="stepType(2)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <transition name="slide">
              <div class="has-text-centered">
                <p class="is-size-5 has-text-centered">
                  Which probe are you installing?
                </p>
                <scan-or-select-device
                    allow-serial-input
                    v-if="!selectedDevice"
                    ref="step2Scan"
                    :device-types="['probe']"
                    label=""
                    device-state="device_shipped"
                    method-query-key="sm2"
                    :selected-serial="serial"
                    @input="handleProbeSelection"
                    @update:loading="componentLoading = $event"
                />
              </div>
            </transition>
            <transition name="slide" v-if="selectedDevice && selectedSensor">
              <div class="has-text-centered m-3">
                <p class="is-size-5">
                  You have selected sensor <b>{{ sensorSerial }}</b>
                </p>
                <div>
                  <b-button
                      icon-left="times"
                      outlined
                      size="is-small"
                      type="is-danger"
                      @click="
                      sensorSerial = null;
                      selectedSensor = null;
                      goBackward();
                    "
                  >
                    Change selected sensor
                  </b-button>
                </div>
              </div>
            </transition>
            <transition name="slide">
              <div class="has-text-centered mb-6" v-if="selectedDevice">
                <p class="is-size-5">
                  You have selected probe <b>{{ selectedDevice.serial }}</b>
                </p>
                <div>
                  <b-button
                      icon-left="times"
                      outlined
                      size="is-small"
                      type="is-danger"
                      @click="handleProbeSelection(null)"
                  >
                    Change selected probe
                  </b-button>
                </div>
              </div>
            </transition>
            <transition name="slide">
              <b-message
                  v-if="probeInstalled"
                  has-icon
                  icon="exclamation"
                  title="Probe not available"
                  type="is-danger"
                  :closable="false"
              >
                <p>
                  This probe is already installed; Please
                  <a @click="handleProbeSelection(null)">select another</a>.
                </p>
                <p>
                  <router-link
                      :to="{ name: 'devices:probe-detail', params: { serial } }"
                  >
                    Click here
                  </router-link>
                  to make changes to the configuration of sensor
                  <b>{{ serial }}</b
                  >.
                </p>
              </b-message>
            </transition>
          </div>
        </b-step-item>

        <b-step-item :step="3" label="Location" :type="stepType(3)">
          <div class="columns">
            <div class="column">
              <transition name="slide">
                <p class="has-text-centered" v-if="checkingPLAssigned">
                  <b-icon icon="sync" class="fa-spin" size="is-large"/>
                </p>
              </transition>
              <transition name="slide">
                <b-message
                    type="is-warning"
                    title="Pipe location already assigned"
                    :closable="false"
                    has-icon
                    icon="exclamation-circle"
                    v-if="preAssignedPipeLocation"
                >
                  <div>
                    <p>
                      This sensor has previously been assigned to a pipe
                      location
                      <router-link
                          :to="{
                          name: 'administration:view-pipe-location',
                          params: { id: preAssignedPipeLocation.id },
                        }"
                      >
                        {{ preAssignedPipeLocation.name }}
                      </router-link>
                      .
                    </p>
                    <b-icon
                        v-if="unassigningPL"
                        icon="sync"
                        class="fa-spin"
                        size="is-small"
                    />
                    <p v-else>
                      To remove this assignment and set another pipe location,
                      click
                      <a @click="unassignPreAssignedPL()"> here </a>.
                    </p>
                  </div>
                </b-message>
              </transition>
            </div>
          </div>

          <transition name="slide">
            <div class="columns" v-if="!preAssignedPipeLocation">
              <div class="column">
                Please add or select the pipe location where this probe sits.
              </div>
            </div>
          </transition>
          <transition name="slide">
            <div class="columns" v-show="!preAssignedPipeLocation">
              <div class="column">
                <pipe-location-table
                    can-add
                    :area="area"
                    :building="building"
                    :rows="
                    $store.state.pipe_locations.filter(
                      (pl) =>
                        !pl.probe &&
                        !pl.position &&
                        pl.building &&
                        building &&
                        pl.building.id === building.id
                    )
                  "
                    :loading="$store.state.fetching.pipe_locations"
                    title="Select a pipe location"
                    :selected-id="pipeLocationId"
                    :paginate_by="25"
                    @input="
                    $store.commit('add', {
                      name: 'pipe_locations',
                      result: $event,
                    });
                    pipeLocationId = $event ? $event.id : null;
                  "
                >
                  <template #post-name/>
                </pipe-location-table>
                <div class="is-pulled-right">
                  <b-icon
                      v-if="building"
                      icon="sync"
                      :class="{
                      'fa-spin': $store.state.fetching.pipe_locations,
                      pointer: true,
                    }"
                  />
                </div>
              </div>
            </div>
          </transition>
        </b-step-item>

        <b-step-item label="Floor-plan" optional :step="4" :type="stepType(4)">
          <aq-image-map-array
              @no-files="(step === 4 && lastStep <= 4) ? goForward() : null"
              :parent-object="building"
              :show-non-editable="false"
              :editable-entity-paths="editableEntityPaths"
          />
        </b-step-item>
        <b-step-item :step="5" label="Confirm" :type="stepType(5)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps are missing data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div
              class="column is-6-desktop is-offset-3-desktop is-10-table is-offset-1-tablet"
              v-else
          >
            <div class="box">
              <p class="subtitle has-text-weight-bold">
                <b-icon
                    icon="check"
                    type="is-success"
                    v-if="!invalidSteps.length"
                />
                Attach Probe to sensor
              </p>
              <level :values="valuesToShow"/>
              <b-message
                  class="mt-6"
                  type="is-info"
                  :closable="false"
                  title="Plug probe in to sensor"
                  has-icon
                  icon="info-circle"
              >
                <p class="is-size-5">
                  Plug <b>probe {{ serial }}</b> in to <b>port {{ port }}</b> on
                  <b>sensor {{ sensorSerial }}</b
                  >.
                </p>
                <p class="is-size-5">
                  Once plugged in, click the confirm button below.
                </p>
              </b-message>
            </div>
          </div>
        </b-step-item>

        <template #navigation>
          <div class="has-text-centered">
            <forward-backward
                classes="float-right"
                :allow-forward="validate(step)"
                :min-step="stepArray[0]"
                :max-step="stepArray[stepArray.length - 1]"
                :step="step"
                :confirm-loading="confirmLoading"
                @forward="goForward"
                @backward="goBackward"
                @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import AqImageMapArray from "../../components/Wrappers/AqImageMapArray";
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";

import iconFor from "../../icons";
import {initial} from "lodash";
import PipeLocationTable from "../../tables/PipeLocationTable";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import SensorPortRadio from "../../components/Selects/Radios/SensorPortRadio";
import Level from "../../components/Level/Level";

import Probe from "../../dataClasses/Probe";

const stepArray = [0, 1, 2, 3, 4, 5];

/**
 * Device centric action component to
 */
export default {
  name: "AttachProbe",
  components: {
    AqImageMapArray,
    BuildingRadio,
    ForwardBackward,
    Level,
    PipeLocationTable,
    ScanOrSelectDevice,
    SensorPortRadio,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {object,null}, pipeLocationId: {string,null}}}
   */
  data() {
    return {
      confirmLoading: false,
      loadingPipeLocation: false,
      componentLoading: false,
      deviceType: "probe",
      pipeLocation: null,
      // Inputs
      position: null,
      selectedSensor: null,

      unassigningPL: false,
      preAssignedPipeLocation: null,
      checkingPLAssigned: false,
    };
  },
  mounted() {
    if (this.sensorSerial) {
      this.handleSensorSelection({serial: this.sensorSerial});
    }
  },
  computed: {
    valuesToShow() {
      return [
        {label: "Sensor", value: this.sensorSerial},
        {label: "Port", value: this.port},
        {label: "Probe", value: this.serial},
        {
          label: "Pipe location",
          value: this.pipeLocation ? this.pipeLocation.name : "None",
        },
      ];
    },
    port: {
      get() {
        return this.$route.query.port ? parseInt(this.$route.query.port) : null;
      },
      set(val) {
        this.queryReplace({port: val});
      },
    },
    $availablePorts() {
      return this.position ? this.position.$availablePorts : [];
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    /**
     * @returns {string} Serial for selected sensor
     */
    sensorSerial: {
      get() {
        return this.$route.query.sensorSerial;
      },
      set(val) {
        setTimeout(() => this.queryReplace({sensorSerial: val}), 25);
      },
    },
    pipeLocationId: {
      get() {
        return this.$route.query.pLId;
      },
      set(val) {
        setTimeout(() => this.queryReplace({pLId: val}), 25);
      },
    },
    /**
     * @returns {string|null} ID for building
     */
    buildingId() {
      return this.position ? this.position.get("building.id") : null;
    },
    /**
     * @returns {string|null} ID for room
     */
    roomId: {
      get() {
        return this.$route.query.room;
      },
      set(val) {
        this.queryReplace({room: val.id});
      },
    },
    /**
     * @returns {string}
     */
    notes: {
      get() {
        return this.$route.query.notes;
      },
      set(val) {
        this.queryReplace({notes: val});
      },
    },
    editableEntityPaths() {
      const eep = [];
      if (this.position) {
        eep.push(this.position.$path);
      }
      if (this.pipeLocation) {
        eep.push(this.pipeLocation.$path);
      }
      return eep;
    },
    probeInstalled() {
      return this.selectedDevice
          ? this.selectedDevice.get("device_state.event_type") ===
          "device_installed"
          : false;
    },
  },
  methods: {
    checkPLAssigned() {
      this.checkingPLAssigned = true;
      this.$dataClasses.PipeLocation.queryForSingle(
          [["probe", "==", this.selectedDevice.$FSRef]],
          {
            order_field: "probe",
          }
      )
          .then((obj) => {
            this.preAssignedPipeLocation = obj;
            this.pipeLocationId = obj.id;
          })
          .catch((e) => (this.preAssignedPipeLocation = null))
          .finally(() => (this.checkingPLAssigned = false));
    },
    unassignPreAssignedPL() {
      this.pipeLocationId = null;
      this.unassigningPL = true;
      this.$dataClasses.PipeLocation.save(this.preAssignedPipeLocation.id, {probe: null})
          .then(() => {
            this.preAssignedPipeLocation = null;
            this.pipeLocationId = null;
          })
          .finally(() => (this.unassigningPL = false));
    },
    iconFor,
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return (
            !!this.sensorSerial &&
            !!this.port &&
            this.$availablePorts.includes(this.port)
        );
      } else if (step === 2) {
        return !!this.serial && !!this.selectedDevice && !this.probeInstalled;
      } else if (step === 3) {
        return (
            !!this.building &&
            this.pipeLocationId &&
            this.pipeLocation &&
            !this.loadingPipeLocation
        );
      } else if (step === 4) {
        return true;
      } else if (step === 5) {
        return (
            initial(stepArray).filter((_step) => !this.validate(_step)).length ===
            0
        );
      }
    },
    /**
     * @param {object} building
     */
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && building && this.building) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} device
     */
    handleProbeSelection(device) {
      this.selectedDevice = device;
      this.serial = device ? device.serial : null;
      if (this.probeInstalled) {
        this.$handleError("This probe is not available for installation");
      } else {
        setTimeout(() => {
          if (this.selectedDevice && this.serial) {
            this.goForward();
          }
        }, 25);
      }
    },
    /**
     * @param {object} sensor
     */
    handleSensorSelection(sensor) {
      this.sensorSerial = sensor ? sensor.serial : null;
      this.selectedSensor = sensor;
    },
    /**
     * Finalize installation
     */
    onComplete() {
      if (this.invalidSteps.length) {
        this.$handleError(
            "Please correct any steps with missing data or errors",
            "Please correct any steps with missing data or errors"
        );
        return;
      }

      try {
        const self = this;
        self.confirmLoading = true;
        self.position
            .performAction("assign_probe_port", {
              pipe_location: self.pipeLocation.$FSRef,
              port: self.port,
              probe: self.toFSRef(this.selectedDevice, "probes"),
            })
            .then(() => self.reloadArea())
            .then(() => {
              self.$store.dispatch('get', {name: 'pipe_locations'});
              return Promise.resolve();
            })
            .then(() => {
              self.$handleSuccess(
                `Probe ${self.serial} has been attached to sensor ${self.sensorSerial}`
              );
              if (self.$route.query.next) {
                self.$router.push(self.$route.query.next)
              } else {
                self.$router.push({
                  name: "devices:sensor-detail",
                  params: {serial: self.sensorSerial},
                });
              }
            })
            .catch((e) =>
                self.$handleError("Could not complete action; See console", e)
            )
            .finally(() => (this.confirmLoading = false));
      } catch (e) {
        this.confirmLoading = false;
        this.$handleError("Error completing action; See console", e);
      }
    },
  },
  watch: {
    step(val) {
      if (val === 3) {
        this.checkPLAssigned();
      }
    },
    pipeLocationId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.pipeLocation = null;
        } else {
          this.loadingPipeLocation = true;
          this.$dataClasses.PipeLocation.fetchById(val)
              .then((obj) => {
                this.pipeLocation = obj;
                if (this.step === 3 && !this.preAssignedPipeLocation) {
                  this.goForward();
                }
              })
              .finally(() => (this.loadingPipeLocation = false));
        }
      },
    },
    port: {
      handler(val) {
        if (val && this.selectedSensor && this.step === 1) {
          this.goForward();
        }
      },
    },
    selectedSensor: {
      immediate: true,
      handler(sensor) {
        if (sensor) {
          this.loading = true;
          this.$dataClasses.Position.query([["serial", "==", sensor.serial]])
              .then((objs) => {
                if (objs && objs.length) {
                  this.position = objs[0];
                  this.positionId = this.position.id;
                  if (this.position.room) {
                    this.roomId = this.toId(this.position.room);
                  }
                } else {
                  this.handleSensorSelection(null);
                  this.$handleError(
                      `Sensor ${sensor.serial} is not currently installed`
                  );
                }
              })
              .catch((e) => this.$handleError(e, e))
              .finally(() => (this.loading = false));
        } else {
          this.position = null;
          this.positionId = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}
</style>
