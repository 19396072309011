<template>
  <b-field
    :horizontal="horizontal"
    :message="measurement === 'mm' ? `${asInch}&quot;` : `${asMM}mm`"
  >
    <b-input type="number" step=".01" v-model="inputValue" />
    <p class="control">
      <b-select v-model="measurement">
        <option value="mm">mm</option>
        <option value="inch">inches</option>
      </b-select>
    </p>
  </b-field>
</template>

<script>
export default {
  name: "InchOrMmInput",
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    //
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    let val = this.value;
    if (val && val instanceof String) {
      val = parseFloat(val);
    }
    return {
      inputValue: this.value || 0,
      measurement: "mm",
    };
  },
  computed: {
    asInch() {
      let val = this.inputValue;
      if (val) {
        if (val instanceof String) {
          val = parseFloat(val);
        }
      } else {
        val = 0;
      }
      return this.measurement === "inch" ? val : this.mmToInch(val);
    },
    asMM() {
      let val = this.inputValue;
      if (val) {
        if (val instanceof String) {
          val = parseFloat(val);
        }
      } else {
        val = 0;
      }
      return this.measurement === "mm" ? val : this.inchToMM(val);
    },
  },
  methods: {
    inchToMM(val) {
      if (val && val instanceof String) {
        val = parseFloat(val);
      }
      return Number((val / 0.03937007874).toFixed(2) || 0);
    },
    mmToInch(val) {
      if (val && val instanceof String) {
        val = parseFloat(val);
      }
      return Number((val * 0.03937007874).toFixed(2) || 0);
    },
    input() {
      this.$emit("input:mm", this.asMM);
      this.$emit("input:inch", this.asInch);
    },
  },
  watch: {
    measurement: {
      handler(val, old) {
        if (val === "mm" && old === "inch") {
          this.inputValue = this.inchToMM(this.inputValue);
        } else if (val === "inch" && old === "mm") {
          this.inputValue = this.mmToInch(this.inputValue);
        }
        this.input();
      },
    },
    displayValue: {
      handler() {
        this.input();
      },
    },
    inputValue: {
      handler() {
        this.input();
      },
    },
  },
};
</script>

<style scoped></style>
