<template>
  <div class="message-filter">
    <b-message
      :closable="false"
      class="filter-message"
      title="Filter messages"
      type="is-default"
    >
      <b-field label="Topics">
        <div class="block">
          <p v-for="topic in messageTopics" :key="topic.value">
            <b-checkbox
              v-model="selectedMessageTopics"
              @input="changed()"
              :type="'is-' + topic.value"
              :native-value="topic.value"
            >
              <b-icon
                :icon="iconFor(topic.value)"
                :type="'is-' + topic.value"
              />
              {{ topic.name }}
            </b-checkbox>
          </p>
        </div>
      </b-field>

      <b-field label="Site">
        <b-select
          expanded
          v-model="selectedSiteID"
          @input="changed()"
          placeholder="Show all"
        >
          <option :value="null">Show all</option>
          <option v-for="site in sites" :key="site.id" :value="site.id">
            {{ site.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Filter by building" v-if="selectedSiteID">
        <b-select
          expanded
          v-model="selectedBuildingID"
          @input="changed('bdg', $event)"
          placeholder="Show all"
        >
          <option :value="null">Show all</option>
          <option
            v-for="bdg in buildings.filter((b) => b.site.id === selectedSiteID)"
            :key="bdg.id"
            :value="bdg.id"
            :disabled="!bdg.area"
          >
            {{ bdg.name }}
            <span v-if="!bdg.area">(<i>NO AREA</i>)</span>
          </option>
        </b-select>
      </b-field>
      <b-button
        v-if="asFilters.length > 0"
        expanded
        size="is-small"
        type="is-info"
        @click="reset()"
        >Reset filters
      </b-button>
    </b-message>
  </div>
</template>

<script>
import iconFor from "../../icons";
import { mapState } from "vuex";
import { getObjectInArrayById } from "../../scripts/filterHelpers";

export default {
  name: "MessageFilter",
  data() {
    return {
      messageTopics: [
        { name: "Flow", value: "flow" },
        { name: "Low-flow / Stagnation", value: "noflow" },
        { name: "Source identification", value: "source_selection" },
        { name: "System status", value: "health" },
        { name: "Temperature", value: "temperature" },
        { name: "Training", value: "calibrate" },
      ],
      selectedMessageTopics: [],
      selectedSiteID: null,
      selectedBuildingID: null,
    };
  },
  computed: {
    ...mapState(["user_roles", "sites", "areas", "buildings"]),
    asFilters() {
      let filters = [];
      if (this.selectedMessageTopics.length) {
        filters.push(["alarm.topic", "in", this.selectedMessageTopics]);
      }
      if (this.selectedBuildingID) {
        let building = getObjectInArrayById(
          this.buildings,
          this.selectedBuildingID,
          null
        );
        filters.push([
          "alarm.area",
          "==",
          this.toFSRef(building.area, "areas"),
        ]);
      } else if (this.selectedSiteID) {
        let siteRef = this.$store.$db
          .collection(this.$dataClasses.Site.collectionName)
          .doc(this.selectedSiteID);
        filters.push(["alarm.site", "==", siteRef]);
      }
      return filters;
    },
  },
  methods: {
    iconFor,
    reset() {
      this.selectedMessageTopics = [];
      this.selectedSiteID = null;
      this.changed();
    },
    changed() {
      let filters = this.asFilters;
      this.$emit("change", filters);
    },
  },
  watch: {
    selectedSiteID() {
      this.selectedBuildingID = null;
      this.changed();
    },
  },
};
</script>

<style scoped>
.filter-message :deep(.message-header p) {
  margin-bottom: 0;
}
</style>
