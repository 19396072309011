<template>
  <div>
    <div v-if="parent.name">
      Point-of-use will be added at area <i>{{ parent.name }}</i
      >.
    </div>
    <b-field grouped>
      <b-field
        label="Area"
        :message="
          buildingRequired && !inputs.building ? 'Please select an area' : ''
        "
        :type="buildingRequired && !inputs.building ? 'is-danger' : ''"
      >
        <b-select v-model="buildingID" :disabled="buildingNotEditable">
          <option :value="null">
            Not part of an area
          </option>
          <option
            v-for="building in buildings"
            :key="building.id"
            :value="building.id"
            :class="{ 'is-italic-option': building.name === '__OPEN_MODAL__' }"
          >
            {{ building.$displayString || building.name }}
          </option>
        </b-select>
        <add-building-modal :parent="site" v-model="buildingModalActive" />
      </b-field>
      <b-field label="Room">
        <b-select v-model="roomID">
          <option :value="null">Not part of a room</option>
          <option
            :class="{ 'is-italic-option': room.name === '__OPEN_MODAL__' }"
            v-for="room in rooms"
            :key="room.id"
            :value="room.id"
          >
            {{
              room.$displayString || room.name
            }}
          </option>
        </b-select>
        <add-room-modal
          :parent="selectedBuilding"
          v-model="roomModalActive"
          @saved="roomID = $event.id"
        />
      </b-field>
    </b-field>
    <b-field label="Fixture type">
      <select-or-add-freeform-other-option
        :options="fixtureTypes"
        :standard-options="standardFixtureTypes"
        v-model="inputs.fixture"
        ref="fixtureInput"
      >
        <template #label="props">
          {{
            standardFixtureTypes.includes(props.option)
              ? capitalizeFirstLetter(props.option)
              : props.option
          }}
        </template>
      </select-or-add-freeform-other-option>
    </b-field>
    <b-field label="Point-of-use name" v-show="inputs.fixture">
      <b-input minlength="3" required type="text" v-model="inputs.name" />
    </b-field>
    <div class="mt-3">
      <b-field label="Temperature of source">
        <b-radio
          v-model="inputs.temperature"
          name="temperature"
          native-value="hot"
          type="is-hot"
        >
          <source-display
            :value="{ name: 'Hot', temperature: 'hot' }"
            show-icon
            icon-position="left"
            tooltip-position="is-bottom"
          />
        </b-radio>
        <b-radio
          v-model="inputs.temperature"
          name="temperature"
          type="is-mixed"
          native-value="mixed"
        >
          <source-display
            :value="{ name: 'Mixed', temperature: 'mixed' }"
            show-icon
            icon-position="left"
            tooltip-position="is-bottom"
          />
        </b-radio>
        <b-radio
          v-model="inputs.temperature"
          name="temperature"
          type="is-cold"
          native-value="cold"
        >
          <source-display
            :value="{ name: 'Cold', temperature: 'cold' }"
            show-icon
            icon-position="left"
            tooltip-position="is-bottom"
          />
        </b-radio>
        <b-radio
          v-model="inputs.temperature"
          name="temperature"
          type="is-dark"
          native-value="unknown"
        >
          Unknown
        </b-radio>
      </b-field>
    </div>
    <div class="mt-3">
      <b-field label="Flow rate (mean)">
        <unit-conversion-input
            convert-to-unit="l/s"
            measurement="volumeFlowRate"
            unit="l/s"
            :decimals="4"
            :initial-value="get(inputs, 'flow_rate.typical', null) || null"
            @input="handleFlowRateInput($event)"
        />
      </b-field>
    </div>
    <div class="mt-3">
      <b-field label="Tags/Groups">
        <tag-select
          v-model="inputs.tags"
          :maxtags="8"
          :related-tagged-objects="$store.state.sources"
        />
      </b-field>
    </div>
    <div>
      <transition name="slide">
        <div
          v-if="
            showAppliance &&
            inputs.fixture === 'appliance' &&
            $store.state.user_roles.is_root
          "
        >
          <b-field grouped>
            <b-field label="Manufacturer/Make">
              <b-input
                v-model="inputs.appliance.make"
                type="search"
                placeholder="Make/manufacturer"
              />
            </b-field>
            <b-field label="Model">
              <b-input v-model="inputs.appliance.model" placeholder="Model" />
            </b-field>
          </b-field>
          <b-field label="Appliance type">
            <select-or-add-freeform-other-option
              :options="applianceTypes"
              :standard-options="standardApplianceTypes"
              v-model="inputs.appliance.type"
            >
              <template #label="props">
                {{
                  standardApplianceTypes.includes(props.option)
                    ? capitalizeFirstLetter(props.option)
                    : props.option
                }}
              </template>
            </select-or-add-freeform-other-option>
          </b-field>
        </div>
      </transition>
    </div>
    <b-button
      class="mt-3"
      v-if="inputs.fixture === 'appliance' && $store.state.user_roles.is_root"
      @click="showAppliance = !showAppliance"
      size="is-small"
    >
      {{ `${showAppliance ? "Hide" : "Show"} appliance details` }}
    </b-button>
  </div>
</template>

<script>
import store from "../../../store";
import { cloneDeep, get } from "lodash";
import iconFor from "../../../icons";
import AddBuildingModal from "../../../components/Admin/Buildings/BuildingModal";
import AddRoomModal from "../../../components/Admin/Rooms/AddRoomModal";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import TagSelect from "../../../components/Selects/TagSelect";
import SelectOrAddFreeformOtherOption from "../../Selects/Radios/SelectOrAddFreeformOtherOption";
import { compareFieldFunc } from "../../../dataClasses/functions";

import UnitConversionInput from "../../Inputs/UnitConversionInput";

export default  {
  name: "SourceForm",
  components: {
    AddBuildingModal,
    AddRoomModal,
    UnitConversionInput,
    SelectOrAddFreeformOtherOption,
    TagSelect,
  },
  created() {
    this.onBuildingIDChange(this.buildingID);
    this.onRoomIDChange(this.roomID);
  },
  data() {
    let parentRef = this.toFSRef(this.parent, "areas"),
      buildingID,
      roomID = this.value.room ? this.value.room.id : null;
    if (this.building) {
      buildingID = this.building.id;
    } else {
      buildingID = (this.value && this.value.building) ? this.value.building.id : null;
    }
    return {
      buildingModalActive: false,
      roomModalActive: false,
      buildingID: buildingID,
      roomID: roomID,
      filteredTags: store.getters.tagsForObjectsInArray(
        store.state.sources,
        "",
        this.value.tags
      ),
      inputs: Object.assign(
        {
          temperature: "mixed",
          appliance: {},
          creation: "user",
          name: "",
          flow_rate: {},
          parent: this.parent ? parentRef : null,
        },
        this.value && this.value.id ? cloneDeep(this.value) : {}
      ),
      showAppliance: false,
    };
  },
  computed: {
    standardApplianceTypes() {
      return this.$dataClasses.Source.$commonApplianceTypes;
    },
    applianceTypes() {
      return this.$dataClasses.Source.$allApplianceValues;
    },
    standardFixtureTypes() {
      return this.$dataClasses.Source.$commonFixtureValues;
    },
    fixtureTypes() {
      return this.$dataClasses.Source.$allFixtureValues;
    },
    area() {
      let areaID = this.parent.id;
      return getObjectInArrayById(this.$store.state.areas, areaID, {});
    },
    site() {
      return getObjectInArrayById(
        this.$store.state.sites,
        this.area.site.id,
        {}
      );
    },
    buildings() {
      return [{ id: "__OPEN_MODAL__", name: "- Add new area" }].concat(
        this.$store.state.buildings
          .filter((b) => b.site.id === this.site.id)
          .sort(compareFieldFunc("name"))
      );
    },
    selectedBuilding() {
      return getObjectInArrayById(this.buildings, this.buildingID, null);
    },
    rooms() {
      let self = this,
        startingRooms = this.buildingID
          ? [{ id: "__OPEN_MODAL__", name: "- Add new room" }]
          : [];
      return startingRooms.concat(
        this.$store.state.rooms
          .filter((r) => {
            if (self.buildingID) {
              return r.building && r.building.id === self.buildingID;
            }
            return r.site.id === self.site.id;
          })
          .sort(compareFieldFunc("name"))
      );
    },
    isValid() {
      return !!(
        (this.inputs.building || !this.buildingRequired) &&
        this.inputs.name &&
        this.inputs.name.length > 2 &&
        this.inputs.fixture
      );
    },
  },
  props: {
    /**
     * parent === area
     */
    parent: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
    building: {
      type: Object,
      required: false,
    },
    buildingRequired: {
      type: Boolean,
      default: true,
    },
    buildingNotEditable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    get,
    iconFor,
    handleFlowRateInput($event) {
      if (!this.value.flow_rate) {
        this.value.flow_rate = {}
      }
      if ($event > 0) {
        this.$set(this.value.flow_rate, 'typical', $event);
      } else {
        this.$set(this.value.flow_rate, 'typical', null);
      }
    },
    cap(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getClearedData() {
      this.buildingID = this.value.building ? this.value.building.id : null;
      this.roomID = this.value.room ? this.value.room.id : null;
      return Object.assign(
        {
          parent: this.value
            ? this.toFSRef(this.value.parent.id, "areas")
            : null,
          building: this.buildingID
            ? this.toFSRef(this.buildingID, "buildings")
            : null,
          room: this.roomID ? this.toFSRef(this.roomID, "rooms") : null,
          name: this.value ? this.value.name : "",
          tags: this.value ? this.value.tags : [],
        },
        {}
      );
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
      this.validates();
    },
    validates() {
      this.$emit("validates", this.isValid);
    },
    onBuildingIDChange(val) {
      let building = val
        ? getObjectInArrayById(this.buildings, val, null)
        : null;
      this.inputs.building = building
        ? cloneDeep(this.toFSRef(building, "buildings"))
        : null;
      if (!val) {
        this.roomID = null;
      }
      this.onChange();
    },
    onRoomIDChange(val) {
      let room = val
        ? cloneDeep(getObjectInArrayById(this.rooms, val, null))
        : null;
      this.inputs.room = room ? this.toFSRef(room, "rooms") : null;
      if (room && room.building && !this.buildingID) {
        this.buildingID = room.building.id;
      }
      this.onChange();
    },
  },
  watch: {
    "inputs.name": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.appliance": {
      deep: true,
      handler(val, oldVal) {
        this.onChange();
      },
    },
    "inputs.fixture": {
      handler(val, oldVal) {
        let name = this.inputs.name ? this.inputs.name.toLowerCase() : "";
        if (val !== "appliance") {
          this.inputs.appliance = {};
        }
        if (
          val &&
          (!name ||
            (name && oldVal && name.toLowerCase() === oldVal.toLowerCase()))
        ) {
          this.inputs.name = this.cap(val);
        }
        this.onChange();
      },
    },
    "inputs.room_type": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.flow_rate.typical": {
      handler() {
        this.onChange()
      }
    },
    "inputs.flow_rate": {
      handler() {
        this.onChange()
      }
    },
    buildingID: {
      immediate: true,
      handler(val) {
        if (val === "__OPEN_MODAL__") {
          this.buildingModalActive = true;
          this.$nextTick(() => {
            this.buildingID = null;
            this.inputs.building = null;
          });
        } else {
          this.onBuildingIDChange(val);
        }
      },
    },
    roomID: {
      immediate: true,
      handler(val) {
        if (val === "__OPEN_MODAL__") {
          this.roomModalActive = true;
          this.$nextTick(() => {
            this.roomID = null;
            this.inputs.room = null;
          });
        } else {
          this.onRoomIDChange(val);
        }
      },
    },
  },
};
</script>

<style scoped>
.is-italic-option {
  font-style: italic !important;
}
</style>
