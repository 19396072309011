<template>
  <article class="media">
    <div class="media-content detail-media">
      <div :class="{ columns: showEventHistory }">
        <div class="media-col column is-one-third-tablet">
          <p class="has-text-weight-bold">Gateways</p>
          <ul
            v-if="bundle.gateways && bundle.gateways.length"
            class="inner-list"
          >
            <li v-for="gateway in bundle.gateways" :key="gateway.serial">
              <router-link
                :to="{
                  name: 'go-to-device',
                  params: { serial: gateway.serial },
                }"
              >
                {{ gateway.serial }}
              </router-link>
            </li>
          </ul>
          <p v-else class="ml-5">None assigned</p>
          <p class="has-text-weight-bold">Sensors</p>
          <ul v-if="bundle.sensors && bundle.sensors.length" class="inner-list">
            <li v-for="sensor in bundle.sensors" :key="sensor.serial">
              <router-link
                :to="{
                  name: 'go-to-device',
                  params: { serial: sensor.serial },
                }"
              >
                {{ sensor.serial }}
              </router-link>
            </li>
          </ul>
          <p v-else class="ml-5">None assigned</p>
          <p class="has-text-weight-bold">Probes</p>
          <ul v-if="bundle.probes && bundle.probes.length" class="inner-list">
            <li v-for="probe in bundle.probes" :key="probe.serial">
              <router-link
                :to="{
                  name: 'go-to-device',
                  params: { serial: probe.serial },
                }"
              >
                {{ probe.serial }}
              </router-link>
            </li>
          </ul>
          <p v-else class="ml-5">None assigned</p>
          <p class="has-text-weight-bold">Assigned to</p>
          <p v-if="subcustomer" class="ml-5">
            <router-link
              :to="{
                name: 'administration:view-subcustomer',
                params: { id: subcustomer.id },
              }"
            >
              {{ subcustomer.$displayString }}
            </router-link>
          </p>
          <p v-else-if="loading" class="ml-5">
            <b-icon icon="sync" size="is-small" class="fa-spin" />
          </p>
          <p v-else><i>None</i></p>
        </div>
        <div
          :class="{
            'media-col': true,
            'column is-two-thirds-tablet': showEventHistory,
          }"
          v-if="showEventHistory"
        >
          <event-history :tabs="tabs" :paginate-by="5" />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { get } from "lodash";

import Bundle from "../../dataClasses/Bundle";

export default {
  name: "BundleDetails",
  props: {
    bundle: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    subcustomer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    showEventHistory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabs() {
      let _ = {};
      _["Bundle"] = this.toFSRef(this.bundle, "bundles");
      return _;
    },
  },
  methods: {
    get,
  },
  data() {
    return {
      Bundle,
      window,
      checkedRows: [],
      lastEventByID: {},
    };
  },
};
</script>

<style scoped>
.inner-list {
  list-style: disc;
}
</style>
