import {get, set} from "lodash";
import store from "../../../store";
import toRef from "../../../appLevelFunctions/toRef";

// Recursively purge empty values
const prune = function (input) {
  if (typeof input === "object") {
    if (input instanceof Date) {
      return input.toISOString();
    } else if (!Array.isArray(input)) {
      let output = {};
      for (const member in input) {
        const value = prune(input[member]);
        if (value !== undefined) {
          output[member] = value;
        }
      }
      if (Object.keys(output).length > 0) {
        return output;
      }
    } else {
      let output = [];
      for (const member of input) {
        const value = prune(member);
        if (value !== undefined) {
          output.push(value);
        }
      }
      if (output.length > 0) {
        return output;
      }
    }
  } else if (input !== undefined && input !== "") {
    return input;
  }
};

// Recursively scaffold a model given a schema
const scaffold = function (input, f= ()=>{}, existingValues={}, key=null) {
  if (input.type === "object") {
    // console.log('scaffold1 (object)', {input, existingValues, key})
    let output = {};
    for (const [key,item] of Object.entries(input.properties)) {
      // console.log({key,item})
      output[item] = scaffold(input.properties[key], f, existingValues, key);
    }
    // console.log('  - result:', output)
    return output;
  } else if (input.type === "array") {
    // console.log('scaffold2 (array)', {input, existingValues, key})
    // console.log('  - result:', [scaffold(input.items, f, existingValues)].filter(i=>typeof i !== 'undefined'));
    return [scaffold(input.items, f, existingValues)].filter(i=>typeof i !== 'undefined');
  } else {
    if (
      existingValues &&
      key &&
      existingValues[key] &&
      input &&
      input.properties &&
      (!input.properties[key].enum || input.properties[key].enum.includes(existingValues[key]))
    ) {
      // console.log('scaffold3', {input, existingValues, key})
      f = () => existingValues[key]
      // console.log({is:'other:has-existing', key, input, f, existingValues})
    } else if (input.default && f === undefined) {
      // console.log('scaffold4', {input, existingValues, key})
      f = () => input.default;
      // console.log({is:'other:default', key, input, f, existingValues})
    }
    let result = f === undefined ? input : f(input);
    // console.log('  - result:', result)
    return result
  }
};

// Recursively combine two objects
const merge = function (first, second) {
  if (typeof first === "object" && typeof second === "object") {
    if (!Array.isArray(first)) {
      let output = {};
      for (const member in first) {
        output[member] = merge(first[member], second[member]);
      }
      return output;
    } else {
      // return second array only if it is not empty and its first member has non-empty values
      return Array.isArray(second) &&
        second.length > 0 &&
        prune(second[0]) !== undefined
        ? second
        : first;
    }
  } else {
    return second !== undefined ? second : first;
  }
};

function scaffoldFromSchema(schema, func, existingValues) {
  let values = scaffold(schema, func, existingValues);
  for (const [fieldName, value] of Object.entries(values)) {
    if (!get(schema.properties, fieldName)) {
      delete values[fieldName];
    }
  }
  for (const [name, prop] of Object.entries(schema.properties || {})) {
    if (
        prop && prop.collections
    ) {
      let existingForObjectPath = get(existingValues, name, null),
        existingForObjectCollection = existingForObjectPath ? existingForObjectPath.split('/')[0] : null;
      // console.log('BEGIN scaffold:for_object_path', {
      //   values,
      //   existingForObjectPath,
      //   name,
      //   prop
      // })
      if (!(prop && prop.collections && prop.collections.length)) {
        // console.log(" - 1")
        if (values[name]) {
          // console.log(" - 1.1")
          delete values[name]
        }
        if (values.scope) {
          // console.log(" - 1.2")
          delete values.scope
        }
      }
      else if (
        !existingForObjectCollection || !prop.collections.includes(existingForObjectCollection)
      ) {
        let scope = prop.collections[0],
            storeItems = get(store.state, prop.collections[0]),
            forObjectPath = toRef(storeItems[0]).path;
        // // UN_COMMENT BELOW LINE AND COMMENT OUT FOLLOWING CLAUSE IF WANT TO LOAD DEFAULT
        // console.log(" - 2", {scope, storeItems, forObjectPath})
        set(values, 'scope', scope);
        if (storeItems && storeItems.length) {
          // console.log(" - 2.1")
          set(values, name, null);
        }
      }
      else if (existingForObjectCollection && prop.collections.includes(existingForObjectCollection)) {
        set(values, name, existingForObjectPath);
      }
      // console.log('END scaffold:for_object_path', {values})
    }
    // else
    if (!get(values, name) && prop.default) {
      set(values, name, prop.default);
    }
  }
  return values;
}

function deepMerge(obj1, obj2) {
  return merge(obj1, obj2);
}

function pruneEmptyMembers(obj) {
  const pruned = prune(obj);
  return pruned === undefined ? {} : pruned;
}

export { scaffoldFromSchema, deepMerge, pruneEmptyMembers };
