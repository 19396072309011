import { mergeById, paginateBy } from "../shared";
import convert from "convert-units";
import Roles from "../../components/Roles";
import UserMessage from "../../dataClasses/UserMessage";
import { get } from "lodash";

const getters = {
  hasFlag: (state) => (key) => {
    return state.user_account &&
      state.user_account.flags &&
      state.user_account.flags[key]
      ? state.user_account.flags[key]
      : false;
  },
  localMeasurementSystem: (state, getters) => {
    return get(
      state,
      "user_account.measurement_system",
      get(getters, "site.measurement_system", "metric")
    );
  },
  standardUnits:
    () =>
    (entity, measurementSystem = "_standard") => {
      const units = {
        temperature: {
          si: "C",
          us: "F",
          _standard: "C",
        },
        volumeFlowRate: {
          si: "l/s",
          us: "gal/min",
          _standard: "l/s",
        },
      };
      if (!Object.keys(units).includes(entity)) {
        throw `Entity '${entity}' not found in unit registry (store.getters).`;
      }
      return units[entity][measurementSystem] || units[entity]._standard;
    },
  /**
   *
   * @returns {function({String}): *}
   */
  localUnit: (state, getters) => (entity) => {
    return getters.standardUnits(entity, getters.localMeasurementSystem);
  },
  localValue: (state, getters) => (entity, value, unit) => {
    return convert(value).from(unit).to(getters.localUnit(entity));
  },
  localizedTemperatureString: (state, getters) => (valueInC) => {
    const unit = getters.localUnit("temperature"),
      value = getters.localValue("temperature", valueInC, "C");
    return `${value.toFixed(1)}°${unit}`;
  },
  loaded: (state) => {
    return Object.values(state.fetching).filter((v) => !!v).length === 0;
  },
  customer: (state) => {
    return state.customers && state.customers.length
      ? state.customers[0]
      : null;
  },
  subcustomer: (state) => {
    return state.subcustomers && state.subcustomers.length
      ? state.subcustomers[0]
      : null;
  },
  site: (state) => {
    return state.sites && state.sites.length ? state.sites[0] : null;
  },
  requiresScopeSelection: (state) => {
    return state.user_roles && !state.user_roles.hasSingleScopeAtSiteOrLowerLevel;
  },
  mergeByID: () => (arr) => {
    return mergeById(arr);
  },
  incrementLastCharBy1: () => (aString) => {
    let lastCharCode = aString.charCodeAt(aString.length - 1),
      nextChar = String.fromCharCode(lastCharCode + 1);
    return aString.slice(0, -1) + nextChar;
  },
  allTags: (state, getters) => (text, valuesToRemove) => {
    return getters.tagsForObjectsInArray(
      getters.objectStack,
      text,
      valuesToRemove
    );
  },
  tagsForObjectsInArray: (state) => (arrayOfObjs, text, valuesToRemove) => {
    let allTags = arrayOfObjs.map((o) => o.tags || []).flat();
    allTags = [...new Set(allTags)];
    let textFilteredTags = allTags.filter((t) => {
        return !text || t.indexOf(text.toLowerCase()) >= 0;
      });
    return textFilteredTags.filter(
      (t) =>
        !valuesToRemove ||
        !valuesToRemove.length ||
        !valuesToRemove.includes(t)
    );
  },
  objectStack: (state) => {
    return [
      ...state.customers,
      ...state.subcustomers,
      ...state.sites,
      ...state.areas,
      ...state.sources,
      ...state.buildings,
      ...state.rooms,
      ...state.pipe_locations,
    ];
  },
  objectByID: (state, getters) => (id, defaultValue, arrayToSearch) => {
    if (!arrayToSearch) {
      arrayToSearch = getters.objectStack;
    }
    let results = arrayToSearch.filter((obj) => obj && obj.id === id);
    return results.length >= 1 ? results[0] : defaultValue;
  },
  objectByIDString: (state, getters) => (idString) => {
    if (!idString) {
      return null;
    }
    if (idString === "|root") {
      return { name: "Root" };
    }
    let results = getters.objectStack.filter(
      (obj) => obj && obj.id_str === idString
    );
    let obj = results.length >= 1 ? results[0] : null;
    if (obj) {
      return obj;
    } else if (!obj && state.idStrToObj[idString]) {
      return state.idStrToObj[idString];
    }
  },
  areasForSerial: (state) => (serial) => {
    const bySerial = (d) => d.serial === serial;
    return state.areas.filter(
      (a) => [...a.gateways, ...a.sensors].filter(bySerial).length > 0
    );
  },
  gatewaysForSensorSerial: (state, getters) => (serial) => {
    let gws = [];

    getters.areasForSerial(serial).forEach((a) => {
      gws = gws.concat(a.gateways);
    });
    return gws;
  },
  sensorsForGatewaySerial: (state, getters) => (serial) => {
    return getters
      .areasForSerial(serial)
      .map((a) => a.sensors)
      .flat();
  },
  objectTypeByIDString: (state) => (idString) => {
    if (!idString || typeof idString !== "string") {
      idString = "";
    }
    let byPipeCount = [
        "(empty)",
        "Root",
        "Customer",
        "Sub-Customer/Division",
        "Site",
        "Area",
        "Source",
        "abstract",
      ],
      pipes = idString.split("|").length - 1;
    if (pipes > byPipeCount.length) {
      return null;
    }
    return byPipeCount[pipes];
  },
  singleNameByIdString: (state) => (idString) => {
    if (!idString || typeof idString !== "string") {
      idString = "";
    }
    let byPipeCount = [
        "(empty)",
        "<root>",
        "customer",
        "subcustomer",
        "site",
        "area",
        "source",
        "<abstract>",
      ],
      pipes = idString.split("|").length - 1;
    if (pipes > byPipeCount.length) {
      return null;
    }
    return byPipeCount[pipes];
  },
  topLevel(state, getters) {
    let rolesAtPath =
      state.user_roles &&
      state.user_roles._roles_data &&
      state.selectedScopeIdStr
        ? state.user_roles._roles_data
            .filter((r) => state.selectedScopeIdStr.startsWith(r.access))
            .sort((a, b) => Roles.satisfies(a.role, b.role))
        : [];

    return state.selectedScopeIdStr &&
      getters.singleNameByIdString(state.selectedScopeIdStr)
      ? {
          idString: state.selectedScopeIdStr,
          object: getters.objectByIDString(state.selectedScopeIdStr),
          singularName: getters.singleNameByIdString(state.selectedScopeIdStr),
          stateVarName:
            getters.singleNameByIdString(state.selectedScopeIdStr) + "s",
          readableType: getters.objectTypeByIDString(state.selectedScopeIdStr),
          role: rolesAtPath.length ? rolesAtPath[0].role : null,
        }
      : {
          idString: null,
          object: null,
          singularName: null,
          stateVarName: null,
          readableType: null,
          role: null,
        };
  },
  loading: (state) => {
    return state.loading;
  },
  roles: (state) => {
    return state.user_roles || {};
  },
  profileData: (state) => {
    return state.user_account && state.user_account._additional
      ? state.user_account._additional
      : {};
  },
  limitFor: (state) => (variableName) => {
    let page = state.pagination[variableName] + 1;
    return page * paginateBy;
  },
  limitForNextPage: (state, getters) => (variableName) => {
    let page = state.pagination[variableName] + 1;
    return getters.limitFor(variableName, page);
  },
  messages: (state) => {
    return state._messages.map((messageRaw) => new UserMessage(messageRaw));
  },
  dismissedMessages: (state) => {
    return state._dismissedMessages.map(
      (messageRaw) => new UserMessage(messageRaw)
    );
  },
};

export default getters;
