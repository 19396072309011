<template>
  <div>
    <div class="mb-0 mt-3 has-text-weight-bold" v-if="title">
      {{ title }}
    </div>
    <b-table
      :checkable="checkable"
      :checked-rows.sync="checkedRows"
      :data="rows"
      :default-sort="defaultSort"
      :detail-key="detailKey"
      :detailed="detailed"
      :loading="loading"
      :opened-detailed="openedDetailed"
      @details-open="$emit('details-open', $event)"
      @details-close="$emit('details-close', $event)"
    >
      <template #empty>
        <div class="has-text-centered">
          <i>No rows to display</i>
        </div>
      </template>
      <slot name="name-column" v-if="showNameColumn">
        <b-table-column field="$displayString" label="Name" sortable v-slot="props" v-if="showNameColumn" :custom-sort="alphanumericSort">
          {{ props.row.$displayString }}
        </b-table-column>
      </slot>
      <slot name="pre-columns" />
      <slot name="columns">
        <b-table-column v-slot="props" sortable numeric label="POUs">
          {{ sourcesFor(props.row).length }}
        </b-table-column>
      </slot>
      <slot name="pre-select-column" />
      <slot name="select-column" v-if="hasSelectColumn">
        <b-table-column v-slot="props">
          <b-button
            @click="
              $router.push({
                name: 'administration:view-internal-area',
                params: { id: props.row.id },
              })
            "
            rounded
            type="is-primary is-outlined"
            size="is-small"
            class="is-pulled-right"
            icon-right="chevron-right"
            >Select
          </b-button>
        </b-table-column>
      </slot>
      <template #detail="props">
        <slot name="detail" v-bind:props="props" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { getChildrenOfIdByKey } from "../scripts/filterHelpers";

export default {
  name: "AreaTable",
  data() {
    return {
      checkedRows: [],
    };
  },
  props: {
    defaultSort: {
      type: Array,
      default: () => {return ['name']}
    },
    hasSelectColumn: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    detailKey: {
      type: String,
      default: "id",
    },
    openedDetailed: {
      type: Array,
      default: () => [],
    },
    showNameColumn: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
  methods: {
    sourcesFor(room) {
      return getChildrenOfIdByKey(
        this.$store.state.sources,
        room.id,
        [],
        "area"
      );
    },
  },
};
</script>

<style scoped></style>
