<template>
  <div>
    <b-collapse class="card mb-3" animation="slide">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title mt-2">Filter ground truth by:</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'" />
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="">
          <b-field label="Area" class="is-filter">
            <div class="fff ml-3 mb-0">
              <b-radio
                :disabled="disabled"
                native-value="ALL"
                :value="$route.query.buildingId"
                @input="inputs.buildingId = $event"
              >
                <i>Show all</i>
              </b-radio>
            </div>
            <div
              v-for="building in sortBy(availableBuildings, ['$displayString'])"
              class="fff ml-3 mb-0"
              :key="building.id"
            >
              <b-radio
                :disabled="disabled"
                :native-value="building.id"
                :value="$route.query.buildingId"
                @input="inputs.buildingId = $event"
              >
                <building-display :value="building" />
              </b-radio>
            </div>
          </b-field>
          <transition name="slide">
            <b-field
              label="Room"
              v-if="inputs.buildingId && inputs.buildingId !== 'ALL'"
              class="is-filter"
              :message="
                inputs.buildingId &&
                !['ALL', 'NOT_ASSIGNED'].includes(inputs.buildingId) &&
                !availableRooms.length
                  ? 'No rooms to show'
                  : null
              "
            >
              <div class="fff ml-3 mb-0">
                <b-radio
                  :disabled="disabled"
                  native-value="ALL"
                  :value="$route.query.roomId"
                  @input="inputs.roomId = $event"
                >
                  <i>Show all</i>
                </b-radio>
              </div>
              <div class="fff ml-3 mb-0">
                <b-radio
                  v-if="false"
                  :disabled="disabled"
                  native-value="NOT_ASSIGNED"
                  :value="$route.query.roomId"
                  @input="inputs.roomId = $event"
                >
                  <i>Room not assigned</i>
                </b-radio>
              </div>
              <div
                class="fff ml-3 mb-0"
                v-for="room in sortBy(availableRooms, [
                  '$building.floor',
                  '$displayString',
                ])"
                :key="room.id"
              >
                <b-radio
                  :disabled="disabled"
                  :native-value="room.id"
                  :value="$route.query.roomId"
                  @input="inputs.roomId = $event"
                >
                  <room-display :value="room" />
                </b-radio>
              </div>
            </b-field>
          </transition>
          <transition name="slide">
            <b-field
              label="Points-of-use"
              v-if="showSources"
              class="is-filter"
              :message="
                inputs.roomId &&
                !['ALL', 'NOT_ASSIGNED'].includes(inputs.roomId) &&
                !_sources.length
                  ? 'No points-of-use to show'
                  : null
              "
            >
              <div class="fff ml-3 mb-0">
                <b-radio
                  :value="$route.query.sourceId"
                  @input="inputs.sourceId = $event"
                  :disabled="disabled"
                  native-value="ALL"
                >
                  <i>Show for all POUs</i>
                </b-radio>
              </div>
              <div
                class="fff ml-3 mb-0"
                v-for="src in sortBy(_sources, [
                  '$displayString',
                  'temperature',
                ])"
                :key="src.id"
              >
                <b-radio
                  :disabled="disabled"
                  :value="$route.query.sourceId"
                  @input="inputs.sourceId = $event"
                  :native-value="src.id"
                >
                  <source-display :value="src" show-training-level />
                </b-radio>
              </div>
            </b-field>
          </transition>
          <transition name="slide">
            <b-field
              label="Sensor"
              class="is-filter"
              v-if="
                inputs.buildingId !== 'ALL' &&
                inputs.buildingId !== 'NOT_ASSIGNED'
              "
            >
              <div class="fff ml-3 mb-0">
                <b-radio
                  :native-value="'ALL'"
                  :disabled="disabled"
                  :value="$route.query.sensorSerial"
                  @input="inputs.sensorSerial = $event"
                >
                  <b>Show all sensors</b>
                </b-radio>
              </div>
              <div
                class="fff ml-3 mb-0"
                v-for="sensor in sortBy(_sensors, ['serial'])"
                :key="sensor.serial"
              >
                <b-radio
                  :native-value="sensor.serial"
                  :disabled="disabled"
                  :value="$route.query.sensorSerial"
                  @input="inputs.sensorSerial = $event"
                >
                  {{ sensor.serial }}
                </b-radio>
              </div>
            </b-field>
          </transition>
          <b-field :grouped="isDesktop" class="is-filter-group mt-3">
            <b-field label="Quality level" class="is-filter">
              <template #label>
                Quality level
                <b-icon
                  v-if="inputs.qualityLevel && inputs.qualityLevel.length"
                  @click.native="inputs.qualityLevel = []"
                  class="pointer"
                  icon="times"
                  size="is-small"
                  title="Clear"
                  type="is-primary"
                />
              </template>
              <b-field v-for="level in qualityLevels" :key="level">
                <b-checkbox
                  :disabled="disabled"
                  class="ml-3"
                  :native-value="level"
                  v-model="inputs.qualityLevel"
                >
                  {{ capitalizeFirstLetter(level) }}
                </b-checkbox>
              </b-field>
            </b-field>
          </b-field>
        </div>
      </div>
    </b-collapse>
    <transition name="slide">
      <div class="mt-0 mb-6" v-if="isNotInitial">
        <b-button
          expanded
          icon-left="times-circle"
          size="is-small"
          type="is-warning"
          @click="reset()"
        >
          Reset filters
        </b-button>
      </div>
    </transition>
  </div>
</template>

<script>
import trackWindowSize from "../../../mixins/trackWindowSize";
import { cloneDeep, isEqual, sortBy } from "lodash";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";

function getInitialFilters() {
  return {
    qualityLevel: [],
    buildingId: "ALL",
    roomId: "ALL",
    sensorSerial: "ALL",
    sourceId: "ALL",
  };
}

export default {
  name: "GroundTruthFilters",
  mixins: [trackWindowSize],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buildings: {
      type: Array,
      default: () => [],
    },
    sensorSerials: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      inputs: {},
      loadedInitial: false,
    };
  },
  mounted() {
    let keys = Object.keys(this.initialInputs),
      inputs = {},
      self = this;
    for (const qKey of Object.keys(self.initialInputs)) {
      if (keys.includes(qKey) && self.$route.query[qKey]) {
        inputs[qKey] = self.$route.query[qKey];
      } else {
        inputs[qKey] = self.initialInputs[qKey];
      }
    }
    self.inputs = inputs;
    self.inputsChanged();
    self.$nextTick(() => {
      self.loadedInitial = true;
    });
  },
  computed: {
    _sensorSerials() {
      let sensorSerials;
      if (this.inputs.buildingId) {
        sensorSerials = this.sensorSerials;
      } else {
        sensorSerials = this.sensorSerials;
      }
      return this.sensorSerials;
    },
    isNotInitial() {
      return (
        Object.values(this.inputs).filter(
          (v) =>
            v &&
            !["ALL"].includes(v) &&
            (!Array.isArray(v) || (Array.isArray(v) && v.length))
        ).length > 0
      );
    },
    site() {
      return this.$route.query.siteId
        ? getObjectInArrayById(
            this.$store.state.sites,
            this.$route.query.siteId,
            null
          )
        : null;
    },
    initialInputs() {
      return getInitialFilters();
    },
    showSources() {
      return (
        this.inputs.buildingId &&
        this.inputs.buildingId !== "ALL" &&
        this.inputs.roomId &&
        this.inputs.roomId !== "ALL" &&
        this.inputs.roomId !== "NOT_ASSIGNED"
      );
    },
    buildingSelected() {
      return this.inputs.buildingId && this.inputs.buildingId !== "ALL";
    },
    allRoomsSelected() {
      return this.inputs.roomId === "ALL";
    },
    areas() {
      return this.$store.state.areas.filter((a) => {
        return (
          !(this.site && this.site.id) || (a.site && a.site.id === this.site.id)
        );
      });
    },
    _sensors() {
      let _ = [],
        areas = [];
      if (
        this.inputs &&
        this.inputs.buildingId &&
        this.inputs.buildingId !== "ALL"
      ) {
        areas = this.$store.state.areas.filter(
          (area) => this.inputs.buildingId === area.building.id
        );
      } else {
        areas = this.$store.state.areas;
      }
      areas.forEach((area) => {
        _ = _.concat(area.sensors);
      });
      return sortBy(_, ["serial"]);
    },
    sensors() {
      return [];
    },
    qualityLevels() {
      return ["low", "medium", "good", "excellent"];
    },
    availableBuildings() {
      return this.buildings;
    },
    availableRooms() {
      return this.inputs.buildingId
        ? this.$store.state.rooms
            .filter((rm) => {
              return rm.building && rm.building.id === this.inputs.buildingId;
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        : this.$store.state.rooms.sort((a, b) => a.name.localeCompare(b.name));
    },
    _sources() {
      let self = this;
      if (!this.showSources) {
        return [];
      } else {
        return self.$store.state.sources.filter((src) => {
          if (src.name.endsWith(":noflow")) {
            return false;
          }
          if (
            self.inputs.roomId &&
            self.inputs.roomId.length &&
            self.inputs.roomId !== "ALL"
          ) {
            return self.allRoomsSelected
              ? true
              : src.room && src.room.id === self.inputs.roomId;
          } else if (
            self.inputs.buildingId &&
            self.inputs.buildingId.length &&
            self.inputs.buildingId !== "ALL"
          ) {
            return src.building && src.building.id === self.inputs.buildingId;
          } else {
            return true;
          }
        });
      }
    },
    _noRoomSources() {
      return this.$store.state.sources.filter((src) => !src.room);
    },
  },
  methods: {
    sortBy,
    getObjectInArrayById,
    isEqual,
    reset() {
      this.inputs = getInitialFilters();
    },
    inputsChanged() {
      this.$router
        .replace({
          query: Object.assign(cloneDeep(this.$route.query), this.inputs),
        })
        .catch(() => {});
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler: function () {
        if (!this.loadedInitial) {
          return;
        }
        this.inputsChanged();
      },
    },
    "inputs.buildingId": {
      handler: function (val, old) {
        if (this.loadedInitial && val !== old) {
          this.inputs.sourceId = "ALL";
          this.inputs.sensorSerial = "ALL";
          if (this.inputs.roomId !== "NOT_ASSIGNED") {
            this.inputs.roomId = "ALL";
          }
        }
      },
    },
    "inputs.roomId": {
      handler: function (val, old) {
        if (this.loadedInitial && val !== old) {
          this.inputs.sourceId = "ALL";
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.field.has-addons) {
  display: block !important;
}
</style>
