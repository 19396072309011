<template>
  <div class="column">
    <div class="card" v-if="$route.name === 'home'">
      <div
          class="card-header"
          v-if="$store.state.sites && $store.state.sites.length"
      >
        <div class="card-header-title" v-if="$store.getters.site">
          DashBoard for site
          <span class="ml-1"
          ><site-display :value="$store.getters.site"
          /></span>
        </div>
        <div class="card-header-icon">
          <b-icon :icon="iconFor('site')" size="is-small"/>
        </div>
      </div>
      <div class="card-header" v-else>
        <div class="card-header-title">Loading</div>
        <div class="card-header-icon">
          <b-icon icon="sync" class="fa-spin" size="is-small"/>
        </div>
      </div>
      <site-introspection-card-content>
        <transition name="slide">
          <loading-chart-card v-if="!$store.getters.loaded"/>
        </transition>
        <transition name="slide">
          <div v-if="$store.getters.loaded && site">
            <div class="tile is-ancestor">
              <div class="tile is-parent is-4">
                <site-location-tile :site="site"/>
              </div>
              <div class="tile is-parent is-4">
                <devices-installed-tile :site="site"/>
              </div>
              <div class="tile is-parent is-4">
                <system-status-tile
                    :loading="loadingPositions || loadingGatewayPositions"
                    :gateway-positions="gatewayPositions"
                    :positions="positions"
                />
              </div>
            </div>
            <div class="tile is-ancestor">
              <div class="tile is-parent is-4">
                <site-buildings-floors-tile :site="site"/>
              </div>
              <div class="tile is-parent is-4">
                <local-scope
                    :alarms="
                    alarmsByCategory.efficiency !== 'loading'
                      ? alarmsByCategory.efficiency
                      : []
                  "
                    alarm-category="efficiency"
                    :loading="alarmsByCategory.efficiency === 'loading'"
                    v-slot="{ alarms, alarmCategory, loading }"
                >
                  <user-message-dashboard-tile
                      :alarms="alarms"
                      :loading="loading"
                      :days="alarmsForLastDays"
                      :is-max="alarms && alarms.length === paginateAlarmsBy"
                      title="Unexpected Flow Alerts"
                      @alarm-click="
                      $router.push({
                        name: `${alarmCategory}:alarms`,
                        query: { detail: $event.id },
                      })
                    "
                      @bottom-click="
                      $router.push({ name: `${alarmCategory}:alarms` })
                    "
                  >
                    <template #ok>
                      <b-field
                          label="Days"
                          size="is-small"
                          style="zoom: 75%"
                          horizontal
                          class="mt-3"
                      >
                        <b-radio native-value="1" v-model="alarmsForLastDays"
                        >1
                        </b-radio
                        >
                        <b-radio native-value="7" v-model="alarmsForLastDays"
                        >7
                        </b-radio
                        >
                        <b-radio native-value="30" v-model="alarmsForLastDays"
                        >30
                        </b-radio
                        >
                      </b-field>
                    </template>
                  </user-message-dashboard-tile>
                </local-scope>
              </div>
              <div class="tile is-parent is-4">
                <local-scope
                    :alarms="
                    alarmsByCategory.hygiene !== 'loading'
                      ? alarmsByCategory.hygiene
                      : []
                  "
                    alarm-category="hygiene"
                    :loading="alarmsByCategory.hygiene === 'loading'"
                    v-slot="{ alarms, alarmCategory, loading }"
                >
                  <user-message-dashboard-tile
                      :alarms="alarms"
                      :loading="loading"
                      :days="alarmsForLastDays"
                      :is-max="alarms && alarms.length === paginateAlarmsBy"
                      title="Stagnation Risk Alerts"
                      @alarm-click="
                      $router.push({
                        name: `${alarmCategory}:alarms`,
                        query: { detail: $event.id },
                      })
                    "
                      @bottom-click="
                      $router.push({ name: `${alarmCategory}:alarms` })
                    "
                  >
                    <template #ok>
                      <b-field
                          label="Days"
                          size="is-small"
                          style="zoom: 75%"
                          horizontal
                          class="mt-3"
                      >
                        <b-radio native-value="1" v-model="alarmsForLastDays"
                        >1
                        </b-radio
                        >
                        <b-radio native-value="7" v-model="alarmsForLastDays"
                        >7
                        </b-radio
                        >
                        <b-radio native-value="30" v-model="alarmsForLastDays"
                        >30
                        </b-radio
                        >
                      </b-field>
                    </template>
                  </user-message-dashboard-tile>
                </local-scope>
              </div>
            </div>
            <div class="tile is-ancestor" v-if="!$store.state.loading.sources">
              <fullscreen :fullscreen.sync="fullscreen2" class="tile is-parent">
                <div class="tile dashboard-widget is-child is-taller">
                  <p class="widget-title">
                    Flow Activity Comparison
                    <span class="is-pulled-right">
                      <b-icon
                          title="Toggle full-screen"
                          :icon="fullscreen2 ? 'times' : 'expand-arrows-alt'"
                          class="pointer"
                          @click.native="fullscreen2 = !fullscreen2"
                      />
                    </span>
                  </p>
                  <div class="widget-content" ref="flow-activity-pie-chart">
                    <div class="widget-controls">
                      <standard-chart-controls
                          :buildings="sortedBuildings"
                          :group-by-options="groupByOptions"
                          :periods="periods"
                          :building-id.sync="pieBdgId"
                          :group-by.sync="pieGroupBy"
                          :period.sync="pieChartPeriod"
                      />
                    </div>
                    <local-scope
                        :rows="_pieChartRows"
                        :date-range-label="
                        flowComparison[pieChartPeriod] === 'loading'
                          ? null
                          : flowComparison[pieChartPeriod]
                          ? getDateRangeLabel(
                              pieChartPeriod,
                              flowComparison[pieChartPeriod].period
                            )
                          : null
                      "
                        :is-loading="flowComparison[pieChartPeriod] === 'loading'"
                        v-slot="{ rows, dateRangeLabel, isLoading }"
                    >
                      <transition name="slide">
                        <div v-if="isLoading" class="has-text-centered">
                          <b-icon
                              icon="sync"
                              size="is-small"
                              class="fa-spin mt-6"
                          />
                        </div>
                      </transition>
                      <transition name="slide">
                        <div v-if="!isLoading && dateRangeLabel">
                          <p
                              class="has-text-centered mt-6"
                              v-if="
                              !rows || !rows.filter((r) => r.value > 0).length
                            "
                          >
                            <i>No activity for {{ dateRangeLabel }} </i>
                          </p>
                          <div v-else>
                            <p class="has-text-centered" style="zoom: 80%">
                              <b>Period</b>: {{ dateRangeLabel }}
                            </p>
                            <pie-chart
                                ref="pieChart"
                                :font-size="fullscreen2 ? '22px' : null"
                                :width="pieChartWidgetWidth"
                                :height="fullscreen2 ? 800 : 280"
                                :rows="rows"
                                :chart-id="pieChartPeriod"
                                :switcheroo="switch2"
                                :label-formatter="labelFormatter"
                                :tooptip-function="pieChartTooltipFunction"
                                :show-legend="pieGroupBy !== '_' || fullscreen2"
                                @click-row="handleRowClick($event)"
                            />
                          </div>
                        </div>
                      </transition>
                    </local-scope>
                  </div>
                </div>
              </fullscreen>
              <fullscreen :fullscreen.sync="fullscreen" class="tile is-parent">
                <div class="tile dashboard-widget is-child is-taller">
                  <p class="widget-title">
                    Flow Activity Summary
                    <span class="is-pulled-right">
                      <b-icon
                          title="Toggle full-screen"
                          :icon="fullscreen ? 'times' : 'expand-arrows-alt'"
                          class="pointer"
                          @click.native="fullscreen = !fullscreen"
                      />
                    </span>
                  </p>
                  <div class="widget-content" ref="flow-activity-bar-chart">
                    <div class="widget-controls">
                      <standard-chart-controls
                          :buildings="sortedBuildings"
                          :group-by-options="groupByOptions"
                          :periods="periods"
                          :building-id.sync="barBdgId"
                          :group-by.sync="barGroupBy"
                          :period.sync="barChartPeriod"
                      />
                    </div>
                    <local-scope
                        :rows="_barChartRows"
                        :date-range-label="
                        flowComparison[barChartPeriod] === 'loading'
                          ? ''
                          : flowComparison[barChartPeriod]
                          ? getDateRangeLabel(
                              barChartPeriod,
                              (flowComparison[barChartPeriod] || {}).period
                            )
                          : ''
                      "
                        :is-loading="flowComparison[barChartPeriod] === 'loading'"
                        v-slot="{ rows, dateRangeLabel, isLoading }"
                    >
                      <transition name="slide">
                        <div v-if="isLoading" class="has-text-centered">
                          <b-icon
                              icon="sync"
                              size="is-small"
                              class="fa-spin mt-6"
                          />
                        </div>
                      </transition>
                      <transition name="slide">
                        <div v-if="!isLoading && dateRangeLabel">
                          <p
                              class="has-text-centered mt-6"
                              v-if="!rows.filter((r) => r.value > 0).length"
                          >
                            <i>No activity for {{ dateRangeLabel }}</i>
                          </p>
                          <div v-else>
                            <p class="has-text-centered" style="zoom: 80%">
                              <b>Period</b>:
                              {{ dateRangeLabel }}
                            </p>
                            <bar-chart
                                ref="barChart"
                                :font-size="fullscreen ? '20px' : null"
                                :height="50 + heightPerBar * rows.length"
                                :rows="rows"
                                :chart-id="`bar-${barChartPeriod}`"
                                :switcheroo="switch1"
                                data-labels-enabled
                                :x-axis-formatter="barChartXAxisFormatter"
                                :data-labels-formatter="
                                barChartDataLabelsFormatter
                              "
                                :tooptip-function="barChartTooltipFunction"
                                @click-row="handleRowClick($event)"
                            />
                          </div>
                        </div>
                      </transition>
                    </local-scope>
                  </div>
                </div>
              </fullscreen>
            </div>
            <transition name="slide">
              <div v-if="lastLoaded" class="has-text-justified has-text-right">
                Last loaded at <i>{{ lastLoaded.toLocaleTimeString() }}</i>
                <b-icon
                    style="cursor: pointer"
                    icon="sync"
                    :class="{
                    'ml-1': true,
                    'fa-spin':
                      loadingGatewayPositions ||
                      loadingPositions ||
                      loadingFlowResults,
                  }"
                    size="is-small"
                    @click.native="fetchData()"
                />
              </div>
            </transition>
          </div>
        </transition>
      </site-introspection-card-content>
    </div>
  </div>
</template>

<script>
import {LocalScope} from "vue-local-scope";

import LoadingChartCard from "../../components/Skeletons/LoadingChartCard";
import {mapState} from "vuex";

import BarChart from "../../components/DataVisualations/Simple/HorizontalBarChart";
import PieChart from "../../components/DataVisualations/Simple/PieChart";
import trackWindowSize from "../../mixins/trackWindowSize";
import {get, sortBy, sum} from "lodash";
import shortHumanizer from "../../components/DataVisualations/DataTransformations/durationHumanizerShort";


import DevicesInstalledTile from "./Tiles/DevicesInstalledTile";
import SiteBuildingsFloorsTile from "./Tiles/SiteBuildingsFloorsTile";
import SiteLocationTile from "./Tiles/SiteLocationTile";
import SystemStatusTile from "./Tiles/SystemStatusTile";
import UserMessageDashboardTile from "./Tiles/UserMessageDashboardTile";
import getHexColorArray from "../../components/DataVisualations/DataTransformations/getHexColorArray";

import fullscreenMixin from "../../mixins/fullscreenMixin";
import StandardChartControls from "../../components/DataVisualations/Controls/StandardChartControls";
import iconFor from "../../icons";
import AqImage from "../../components/Files/AqImage";
import SiteIntrospectionCardContent from "../../components/SystemIntrospection/SiteIntrospectionCardContent";

function getRandomString() {
  return Math.random().toString(36).slice(2, 7);
}

const groupByOptions = [
  {label: "Point-of-use", fieldPath: "_"},
  {label: "Fixture Type", fieldPath: "fixture"},
  {label: "Room Type", fieldPath: "$room.type"},
  {label: "Room", fieldPath: "$room.$displayString"},
];

export default {
  name: "DashBoard",
  mixins: [fullscreenMixin, trackWindowSize],
  components: {
    SiteIntrospectionCardContent,
    AqImage,
    BarChart,
    LoadingChartCard,
    LocalScope,
    PieChart,
    StandardChartControls,

    DevicesInstalledTile,
    SiteBuildingsFloorsTile,
    SiteLocationTile,
    SystemStatusTile,
    UserMessageDashboardTile,
  },
  props: {
    siteId: {
      type: String,
      required: false,
    },
  },
  /**
   *
   * @return {{Site: Site, flowComparison: {week: null, month: null, day: null}, lastLoaded: null, loadingFlowResults: boolean, positions: *[], alarmsByCategory: {}, loadingPositions: boolean, Source: Source, loadingGatewayPositions: boolean, gatewayPositions: *[], Area: Area, barChartWidgetWidth: null, pieChartWidgetWidth: null, groupByOptions: [{fieldPath: string, label: string},{fieldPath: string, label: string},{fieldPath: string, label: string}], heightPerBar: number}}
   */
  data() {
    return {
      groupByOptions,
      lastLoaded: null,
      barRows: [],
      pieRows: [],

      fullscreen2: false,
      switch1: false,
      switch2: false,

      alarmsByCategory: {},
      loadingFlowResults: false,
      flowComparison: {
        day: null,
        week: null,
        month: null,
      },

      loadingPositions: false,
      loadingGatewayPositions: false,
      positions: [],
      gatewayPositions: [],

      barChartWidgetWidth: null,
      pieChartWidgetWidth: null,
      barColors: [],
      pieColors: [],

      paginateAlarmsBy: 10,
    };
  },
  computed: {
    ...mapState(["fetching"]),
    heightPerBar() {
      return this.fullscreen ? 60 : 30;
    },
    sortedBuildings() {
      return sortBy(this.$store.state.buildings, ["name", "$site.address"]);
    },
    barBdgId: {
      get() {
        return this.$route.query.barBdgId || "all";
      },
      set(barBdgId) {
        this.queryReplace({barBdgId});
      },
    },
    alarmsForLastDays: {
      get() {
        return parseInt(this.$route.query.alarmDays || "1");
      },
      set(alarmDays) {
        this.queryReplace({alarmDays});
      },
    },
    pieBdgId: {
      get() {
        return this.$route.query.pieBdgId || "all";
      },
      set(pieBdgId) {
        this.queryReplace({pieBdgId});
      },
    },
    barGroupBy: {
      get() {
        return this.$route.query.barGroupBy || groupByOptions[0].fieldPath;
      },
      set(barGroupBy) {
        this.queryReplace({barGroupBy});
      },
    },
    pieGroupBy: {
      get() {
        return this.$route.query.pieGroupBy || groupByOptions[0].fieldPath;
      },
      set(pieGroupBy) {
        this.queryReplace({pieGroupBy});
      },
    },
    site() {
      return this.$store.getters.site;
    },
    areas() {
      return this.$store.state.areas;
    },
    periods() {
      return ["day", "week", "month"];
    },
    usageViewBy() {
      return "minutes";
    },
    pieChartPeriod: {
      get() {
        return this.$route.query.pieTab || "day";
      },
      set(val) {
        this.queryReplace({pieTab: val});
      },
    },
    barChartPeriod: {
      get() {
        return this.$route.query.barTab || "day";
      },
      set(val) {
        this.$nextTick(() => {
          this.queryReplace({barTab: val});
        });
      },
    },
    _barChartRows() {
      return this.barChartRows(this.barChartPeriod, this.barGroupBy);
    },
    _pieChartRows() {
      return this.pieChartRows(this.pieChartPeriod, this.pieGroupBy);
      ;
    }
  },
  methods: {
    iconFor,
    sortBy,
    handleRowClick(row) {
      if (row && row.label && row.label.startsWith("~id:") && row.source) {
        this.$router.push({
          name: "administration:view-source",
          params: {id: row.source.id},
        });
      }
    },
    labelFormatter(labelRaw) {
      if (labelRaw.startsWith("~id:")) {
        const sourceId = labelRaw.replace("~id:", ""),
            pou = this.$dataClasses.Source.fromStore(this.$store.state.sources, sourceId, {});
        return pou.$locationString;
      }
      return labelRaw;
    },
    pieChartTooltipFunction({series, seriesIndex, dataPointIndex, w}) {
      const minutes = series[seriesIndex],
          seconds = minutes * 60,
          ms = seconds * 1000,
          labelRaw = w.globals.labels[seriesIndex];
      return `<div class="arrow_box p-2"><i>${shortHumanizer(
          ms
      )}</i> flow for <b>${this.labelFormatter(labelRaw)}</b></div>`;
    },
    barChartXAxisFormatter(value) {
      // return value;
      return shortHumanizer(value * 1000 * 60).replaceAll(" ", "");
    },
    barChartTooltipFunction({series, seriesIndex, dataPointIndex, w}) {
      const count = series[seriesIndex][dataPointIndex],
          ms = count * 1000 * 60,
          labelRaw = w.globals.labels[dataPointIndex];
      return `<div class="arrow_box p-2"><i>${shortHumanizer(
          ms
      )}</i> flow for <b>${this.labelFormatter(labelRaw)}</b></div>`;
    },
    barChartDataLabelsFormatter(value, opt) {
      let labelRaw = opt.w.globals.labels[opt.dataPointIndex],
          durationStr = shortHumanizer(value * 1000 * 60).replaceAll(" ", "");
      return this.labelFormatter(labelRaw) + " - " + durationStr;
    },
    pieChartRows(period, groupBy = "_") {
      let results = get(this.flowComparison, `${period}.results`, []),
          bdgId = this.pieBdgId;
      if (!results || !results.length) {
        return [];
      }
      results = results
          .map((result) => {
            return {
              ...result,
              _source: this.$dataClasses.Source.fromStore(
                  this.$store.state.sources,
                  result.id
              ),
            };
          })
          .filter((result) => {
            return (
                result._source &&
                !result._source.get("name", "SRC:").startsWith("SRC:") &&
                (bdgId === "all"
                    ? true
                    : result._source.get("$building.id") === bdgId)
            );
          })
          .map((result) => {
            return {
              label: "~id:" + result._source.id,
              source: result._source,
              value: result.minutes,
            };
          });

      if (groupBy === "_") {
        const colors = getHexColorArray({count: results.length});
        results.forEach((el, index) =>
            Object.assign(el, {color: colors[index]})
        );
        return sortBy(results, ["value", "label", "source.id"]).reverse();
      } else {
        const allValues = Array.from(
                new Set(results.map((r) => r.source.get(groupBy, "Unknown")))
            ).sort(),
            output = [],
            colors = getHexColorArray({count: allValues.length});
        allValues.forEach((fieldValue, index) => {
          output.push({
            label:
                typeof fieldValue === "string"
                    ? this.capitalizeFirstLetter(fieldValue)
                    : fieldValue,
            value: sum(
                results
                    .filter((res) => res.source.get(groupBy) === fieldValue)
                    .map((res) => res.value)
            ),
            color: colors[index],
          });
        });
        return sortBy(output, "label");
      }
    },
    barChartRows(period, groupBy = "_") {
      let results = get(this.flowComparison, `${period}.results`, []),
          bdgId = this.barBdgId;
      if (!results) {
        return [];
      }
      results = results
          .map((result) => {
            return {
              ...result,
              _source: this.$dataClasses.Source.fromStore(
                  this.$store.state.sources,
                  result.id
              ),
            };
          })
          .filter((result) => {
            return (
                result._source &&
                !result._source.get("name", "SRC:").startsWith("SRC:") &&
                (bdgId === "all"
                    ? true
                    : result._source.get("$building.id") === bdgId)
            );
          })
          .map((result) => {
            return {
              label: "~id:" + result._source.id,
              source: result._source,
              value: result.minutes,
            };
          });
      if (groupBy === "_") {
        const colors = getHexColorArray({count: results.length});
        results.forEach((el, index) =>
            Object.assign(el, {color: colors[index]})
        );
        return sortBy(results, ["value", "label", "source.id"]).reverse();
      } else {
        let allValues = Array.from(
                new Set(results.map((r) => r.source.get(groupBy, "Unknown")))
            ).sort(),
            output = [],
            colors = getHexColorArray({count: allValues.length});
        allValues.forEach((fieldValue, index) => {
          output.push({
            label:
                typeof fieldValue === "string"
                    ? this.capitalizeFirstLetter(fieldValue)
                    : fieldValue,
            value: sum(
                results
                    .filter((res) => res.source.get(groupBy) === fieldValue)
                    .map((res) => res.value)
            ),
            color: colors[index],
          });
        });
        return sortBy(output, ["value", "label"]).reverse();
      }
    },
    subtractDaysFromNow(days) {
      var dateOffset = 24 * 60 * 60 * 1000 * days; //5 days
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      return myDate;
    },
    getAlarms() {
      const categories = ["efficiency", "hygiene"],
          self = this;
      categories.forEach((category) => {
        self.$set(self.alarmsByCategory, category, "loading");
        this.$dataClasses.Alarm.byTopic({
          site: this.site,
          category,
          paginate_by: this.paginateAlarmsBy,
          startDate: this.subtractDaysFromNow(this.alarmsForLastDays),
          additionalQueries: [["alert_type", "==", "alarm"]],
        })
            .then((results) => {
              self.$set(self.alarmsByCategory, category, results);
            })
            .catch((e) => {
              self.$set(self.alarmsByCategory, category, []);
              self.$handleError(
                  "Could not load alarms for category '" + category + "'",
                  e
              );
            });
      });
    },
    getFlowActivity() {
      this.periods.forEach((period) => {
        this.$set(this.flowComparison, period, "loading");
        this.site
            .getCurrentAndPreviousAggregatedFlowActivity({
              group_by: period,
            })
            .then((response) => {
              this.$set(this.flowComparison, period, response);
            })
            .catch((e) => {
              this.$set(this.flowComparison, period, []);
              this.$handleError(
                  `Could not load flow activity by ${period}; See console`,
                  e
              );
            });
      });
    },
    getDevicesForSystemHealth() {
      const self = this;
      if (!this.site) {
        return;
      }
      self.loadingPositions = true;
      self.$dataClasses
          .Position
          .query([["site", "==", self.site.$FSRef]], {
            order_field: "site",
          })
          .then((results) => {
            self.positions = sortBy(results, "serial");
          })
          .catch((e) => self.$handleError("Could not load sensor health", e))
          .finally(() => (self.loadingPositions = false));

      self.loadingGatewayPositions = true;
      self.$dataClasses.GatewayPosition.query(
          [["parent", "in", self.$store.state.areas.map((a) => a.$FSRef)]],
          {
            order_field: "parent",
          }
      )
          .then((results) => {
            self.gatewayPositions = sortBy(results, "serial");
          })
          .catch((e) => self.$handleError("Could not load gateway health", e))
          .finally(() => (self.loadingGatewayPositions = false));
    },
    /**
     *
     * @param period {'week'|'month'|'day'}
     * @param periodDateRange {{start:Date,end:Date}}
     * @return {string}
     */
    getDateRangeLabel(period, periodDateRange) {
      if (period === "day") {
        return periodDateRange.start.toLocaleDateString();
      } else if (period === "week") {
        return (
            periodDateRange.start.toLocaleDateString() +
            "-" +
            periodDateRange.end.toLocaleDateString()
        );
      } else if (period === "month") {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return `${
            monthNames[periodDateRange.start.getMonth()]
        } ${periodDateRange.start.getFullYear()}`;
      }
    },
    fetchData() {
      if (!this.areas || !this.areas.length) {
        return;
      }
      this.getAlarms();
      this.getFlowActivity();
      this.getDevicesForSystemHealth();
      this.lastLoaded = new Date();
    },
  },
  watch: {
    alarmsForLastDays: {
      handler() {
        this.getAlarms();
      },
    },
    "$store.state.sources": {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
    fullscreen: {
      handler() {
        setTimeout(() => {
          this.switch1 = !this.switch1;
        }, 100);
      },
    },
    fullscreen2: {
      handler() {
        setTimeout(() => {
          this.switch2 = !this.switch2;
        }, 100);
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
