import {
  stateVariables,
  defaultIsArray,
  getHierarchyChildren,
  getHierarchyParent,
  getStateVariableDefault,
  shouldFetchSingle,
  shouldFetchList,
} from "./configuredVariables";

import {
  assertAllProperies,
  assertSomeProperies,
  hasAllProperties,
  hasSomeProperties,
  mergeById,
} from "./generalFunctions";

import {
  filterOutObjectWithIDFromArray,
  filterOutObjectWithParentIdFromArray,
  is_equal_or_lower_in_hierarchy,
  typeGivenIDString,
} from "./hierarchy";

const paginateBy = 500;

export {
  // functions
  assertAllProperies,
  assertSomeProperies,
  hasAllProperties,
  hasSomeProperties,
  mergeById,
  defaultIsArray,
  filterOutObjectWithIDFromArray,
  filterOutObjectWithParentIdFromArray,
  getHierarchyChildren,
  getHierarchyParent,
  getStateVariableDefault,
  is_equal_or_lower_in_hierarchy,
  shouldFetchList,
  shouldFetchSingle,
  typeGivenIDString,
  // constants
  paginateBy,
  stateVariables,
};
