<template>
  <div>
    <div v-if="parent.name">
      Site will be added for sub-customer/division <i>{{ parent.name }}</i
      >.
    </div>
    <b-field label="Site name">
      <b-input
        type="text"
        required
        minlength="3"
        v-model="inputs.name"
        placeholder="Area 1A"
      />
    </b-field>
    <b-field label="Site address">
      <div class="control is-clearfix">
        <div v-show="inputs.address">
          Address is currently set as <b>{{ inputs.address }}</b
          >.
        </div>
        <gmap-autocomplete
          v-show="!inputs.address"
          ref="addressInput"
          :options="{ types: ['address'] }"
          :class="{
            'input is-fullwidth': true,
            'is-danger': gmapFocused && !inputs.address,
          }"
          @focusin="gmapFocused = true"
          @place_changed="autocompleteChanged($event)"
        >
        </gmap-autocomplete>
        <a
          v-if="inputs.address || inputs.address_place_id"
          @click="clearAddress()"
          >Clear address</a
        >
      </div>
    </b-field>
    <timezone-picker v-model="inputs.timezone" />
    <b-field label="Default measurement system">
      <select-measurement-system v-model="inputs.measurement_system" />
    </b-field>
    <b-field
      label="Flagged for internal monitoring"
      v-if="$store.state.user_roles.is_root"
    >
      <b-select v-model="inputs._internally_monitored">
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </b-select>
    </b-field>
    <MapWithMarker v-if="latlng && latlng.lat && latlng.lng" :marker="latlng" />

    <div v-else>
      <div class="mb-6">ㅤ</div>
      <div class="mb-6">ㅤ</div>
      <div class="mb-2">ㅤ</div>
    </div>
    <b-field label="Tags/Groups">
      <tag-select
        v-model="inputs.tags"
        :maxtags="8"
        :related-tagged-objects="$store.state.rooms"
      />
    </b-field>
  </div>
</template>

<script>
import TagSelect from "../../Selects/TagSelect";
import TimezonePicker from "../../../components/Selects/TimezonePicker";
import MapWithMarker from "../../../components/MapWithMarker";
import firebase from "firebase/app";
import "firebase/firestore";

import { cloneDeep } from "lodash";
import SelectMeasurementSystem from "../../Selects/SelectMeasurementSystem";

export default {
  name: "SiteForm",
  components: {
    MapWithMarker,
    SelectMeasurementSystem,
    TagSelect,
    TimezonePicker,
  },
  data() {
    return {
      inputs: Object.assign(
        { parent: this.parent ? cloneDeep(this.parent._reference) : null },
        cloneDeep(this.value)
      ),
      gmapFocused: false,
      searchInput: "",
      mapsData: {
        address: {},
        searchInput: this.value.address || "",
      },
    };
  },
  computed: {
    isValid() {
      return this.inputs.name.length > 2 && this.inputs.timezone;
    },
    latlng() {
      return {
        lat: this.inputs.lat_long ? this.inputs.lat_long.latitude : null,
        lng: this.inputs.lat_long ? this.inputs.lat_long.longitude : null,
      };
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    validates() {
      this.$emit("validates", this.isValid);
    },
    getClearedData() {
      return Object.assign({
        parent: this.value.parent,
        name: this.value.name,
        address: "",
        address_place_id: null,
        lat_long: null,
        timezone: null,
        _internally_monitored: false,
      });
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.getAddressDataFromAPIResponse();
      this.$emit("input", this.inputs);
      this.validates();
    },
    /**
     * Takes geocoded data from Google Maps Places API
     * and assigns it to component data.
     */
    getAddressDataFromAPIResponse(mapsData) {
      if (!mapsData) {
        return;
      }
      let newInputsData = Object.assign({}, this.inputs);
      newInputsData.address = mapsData.formatted_address;
      newInputsData.address_place_id = mapsData.place_id;
      let lat = mapsData.geometry ? mapsData.geometry.location.lat() : null,
        long = mapsData.geometry ? mapsData.geometry.location.lng() : null;
      newInputsData.lat_long =
        lat && long ? new firebase.firestore.GeoPoint(lat, long) : null;
      this.inputs = newInputsData;
    },
    autocompleteChanged(returnedData) {
      let coords = this.getCoordinates(returnedData),
        timestamp = Math.round(new Date().getTime() / 1000).toString();
      this.getAddressDataFromAPIResponse(returnedData);
      if (coords) {
        fetch(
          `https://maps.googleapis.com/maps/api/timezone/json?location=${coords.lat},${coords.lng}&timestamp=${timestamp}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`
        )
          .then((res) => res.json())
          .then((json) => {
            if (json.timeZoneId) {
              this.inputs.timezone = json.timeZoneId;
              this.inputs = Object.assign({}, this.inputs);
            }
          })
          .catch((e) => {
            this.$handleError("Could not fetch time zone; Please set manually", e);
          });
      }
    },
    getCoordinates(returnedData) {
      let lat, lng;
      try {
        lat = returnedData.geometry.location.lat();
        lng = returnedData.geometry.location.lng();
      } catch (e) {
        console.error("Could not get coordinates");
      }
      return lat && lng
        ? {
            lat: lat,
            lng: lng,
          }
        : null;
    },
    clearAddress() {
      // let's not delete the site name
      this.$refs.addressInput.$el.value = "";
      this.inputs.address = "";
      this.inputs.address_place_id = null;
      this.inputs.lat_long = {};
      this.inputs.timezone = null;
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        return this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
