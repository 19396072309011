<template>
  <b-modal
    has-modal-card
    can-cancel
    class="is-modal-max"
    :active="value"
    @close="$emit('input', false)"
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          {{ id ? "Edit" : "Add" }} pipe location
        </div>
        <div class="modal-card-icon">
          <b-icon :icon="iconFor('pipe_location')" />
        </div>
      </div>
      <div class="modal-card-body">
        <b-loading :is-full-page="false" :active="loading" />
        <pipe-location-form
          v-if="!loading"
          ref="form"
          :parent="parent"
          :building="building"
          :room="room"
          v-model="inputs"
          @validate="validates = $event"
        />
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button expanded icon-left="times" @click="$emit('input', false)">
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button
            expanded
            :icon-right="iconFor('save')"
            :loading="submitting"
            :disabled="!validates"
            type="is-primary"
            @click="submit"
          >
            Submit
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import toFSRef from "../../../appLevelFunctions/toRef";
import iconFor from "../../../icons";
import PipeLocationForm from "./PipeLocationForm";


export function getDefaultData(room, building) {
  return {
    characteristics: {
      inner_diameter: null,
      outer_diameter: null,
      material: "other",
      type: "branch",
    },
    name: "",
    room: room ? toFSRef(room, "rooms") : null,
    building: building ? toFSRef(building, "buildings") : null,
    downstream_sources: [],
    temperature: "mixed",
  };
}

export default {
  name: "AddPipeLocationModal",
  components: {
    PipeLocationForm,
  },
  data() {
    return {
      loading: false,
      submitting: false,
      validates: false,
      inputs: getDefaultData(this.room, this.building),
    };
  },
  props: {
    /**
     *
     */
    parent: {
      type: Object,
      required: true,
      validator(val) {
        return (
          !val ||
          (val && val._reference && val._reference.path.startsWith("areas/"))
        );
      },
    },
    room: {
      type: Object,
      required: false,
      validator(val) {
        return (
          !val ||
          (val && val._reference && val._reference.path.startsWith("rooms/"))
        );
      },
    },
    building: {
      type: Object,
      required: true,
      validator(val) {
        return (
          !val ||
          (val &&
            val._reference &&
            val._reference.path.startsWith("buildings/"))
        );
      },
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    iconFor,
    /**
     * Used by parent via $refs in a couple places
     * @param {object} data
     */
    updateInputs(data) {
      for (const [key, value] of Object.entries(data)) {
        this.$set(this.inputs, key, value);
      }
    },
    submit() {
      this.id ? this.submitUpdate() : this.submitCreate();
    },
    submitCreate() {
      if (this.id) {
        throw "Cannot create when ID present";
      }
      if (!this.validates) {
        this.$handleError("Please correct any form errors");
        return;
      }
      this.submitting = true;
      this.$forceUpdate();
      this.$dataClasses.PipeLocation.save(this.id, this.inputs)
        .then((obj) => {
          this.$emit("save", obj);
          this.$handleSuccess("Your pipe location has been added");
          this.inputs = getDefaultData(this.room, this.building);
        })
        .catch((e) => this.$handleError(e, e))
        .finally(() => (this.submitting = false));
    },
    submitUpdate() {
      if (!this.id) {
        throw "ID required for update";
      }
      if (!this.validates) {
        this.$handleError("Please correct any form errors");
        return;
      }
      this.submitting = true;
      this.$dataClasses.PipeLocation.save(this.id, this.inputs)
        .then((obj) => {
          this.$emit("save", obj);
          this.$handleSuccess("Your pipe location has been updated");
        })
        .catch((e) => this.$handleError(e, e))
        .finally(() => (this.submitting = false));
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(_id) {
        this.loading = true;
        if (!_id) {
          this.inputs = getDefaultData(this.room, this.building);
          this.loading = false;
        } else {
          this.$dataClasses.PipeLocation.fetchById(_id)
            .then((obj) => (this.inputs = obj.$data))
            .catch((e) =>
              this.$handleError("Could not fetch pipe location with id " + _id, e)
            )
            .finally(() => (this.loading = false));
        }
      },
    },
    value: {
      handler(modalShown) {
        if (modalShown) {
          this.$nextTick(() => {
            try {
              this.$refs.form.$refs.nameInput.focus();
            } catch (e) {
              console.log("skip focus");
            }
          });
        }
      },
    },
  },
};
</script>

<style scoped>
:deep(.modal-card) {
  width: auto !important;
}
</style>
