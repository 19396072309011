<template>
  <div :class="classes">
    <slot name="pre-icon" />
    <b-icon :icon="icon" />
    {{ showFloors ? value.$displayString : value.name }}
    <in-training-mode
      :next="next || $route.fullPath"
      :area="value.$area"
      :tooltip-active="showTrainingModeTooltip"
      v-if="showTrainingModeWarning && value.$area && value.$area.$isInTrainingMode"
    />
    <slot>
      <span v-if="showLinkIcon" :class="{'is-pulled-right mr-3':!linkIconNoPull}" >
        <b-icon
          :icon="linkIcon"
          class="pointer"
          type="is-link"
          @click.native="
            $router.push(getLinkFunction(value));
            $event.preventDefault();
          "
        />
      </span>
    </slot>
    <slot name="post"></slot>
    <slot name="end" />
  </div>
</template>

<script>
import iconFor from "../../icons";

export default {
  name: "BuildingDisplay",
  computed: {
    icon() {
      return iconFor("buildings");
    },
  },
  data() {
    return {};
  },
  props: {
    linkIconNoPull: {
      type: Boolean,
      default: false
    },
    next: {
      type: String,
      required: false,
    },
    showTrainingModeWarning: {
      type: Boolean,
      default: true,
    },
    showTrainingModeTooltip: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
    showFloors: {
      type: Boolean,
      default: true,
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    linkIcon: {
      type: String,
      default: iconFor("update"),
    },
    getLinkFunction: {
      type: Function,
      default: function (src) {
        return this.$router.match({
          name: "administration:view-area",
          params: { id: src.id },
          query: {
            loadScope: src.site_id_str,
          },
        });
      },
    },
  },
};
</script>

<style scoped></style>
