<template>
  <transition name="fade">
    <div class="wrapper" v-show="imageLoaded">
      <slot name="inner" />
      <div class="gateway has-text-centered" :style="`top:${top};`">
        <img
          :src="gatewayImg"
          class="gateway-image"
          alt=""
          @load="imageLoaded = true"
        />
        <div class="serial centered">{{ serial }}</div>
        <div class="last-seen centered mb-3" v-if="position && showLastSeen">
          Last seen:
          <span v-if="position" :class="position.$lastSeenTextClass">
            {{sinceLastSeen}}
          </span>
          <i v-else>Unknown</i>
          <span v-if="position" class="is-pulled-right">
            <b-icon
              icon="cloud-upload-alt"
              :type="position.$isHealthy ? 'is-success' : 'is-danger'"
              :class="position.$isHealthy ? '' : 'fa-throb'"
            />
          </span>
        </div>
      </div>
      <slot name="body"></slot>
      <div
        v-if="showSensors && position"
        :class="{
          'sensor-wrapper': true,
          centered: position && position.$area.sensors.length <= 2,
        }"
      >
        <div class="sensor-text" v-if="position && position.$area.sensors.length <= 0">
          No sensors found
        </div>
        <live-sensor-display
          v-else-if="showSensors && position"
          v-for="sensor in position.$area.sensors"
          :key="sensor.serial"
          :serial="sensor.serial"
          :signal-strength="sensor.signalStrength"
          :battery-level="sensor.batteryLevel"
        ></live-sensor-display>
      </div>
      <slot name="post-sensor"></slot>
    </div>
  </transition>
</template>

<script>
import shortHumanizer from "../../components/DataVisualations/DataTransformations/durationHumanizerShort";
import gatewayImg from "../../assets/gateway.png";
import LiveSensorDisplay from "./LiveSensorDisplay";
import intervalFunctionMixin from "../../mixins/intervalFunctionMixin";


export default {
  name: "LiveGatewayDisplay",
  mixins: [intervalFunctionMixin],
  components: { LiveSensorDisplay },
  data() {
    return {
      gatewayImg: gatewayImg,
      imageLoaded: false,
      sinceLastSeen: this.position ? this.position.$sinceLastSeenText : null,
    };
  },
  props: {
    position: {
      type: Object,
      required: false,
    },
    serial: {
      type: String,
      required: true,
    },
    showLastSeen: {
      type: Boolean,
      default: true,
    },
    showSensors: { type: Boolean, default: false },
    top: {
      type: String,
      default: "0",
    },
  },
  computed: {
    sensors() {
      return this.$store.getters.sensorsForGatewaySerial(this.serial);
    },
  },
  methods: {
    shortHumanizer,
    onPoll() {
      this.sinceLastSeen = this.position ? this.position.$sinceLastSeenText : null;
    }
  },
  watch: {
    showSensors: {
      handler(val) {
        if (val) {
          // TODO: fetch sensor data here and update; timeout?
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: inline-block;
}

.gateway {
  position: relative;
  text-align: center;
  max-width: 100%;
  height: auto;

  & .signal {
    position: relative;
    display: block;
  }

  & .gateway-image {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
  }

  & .gateway-label {
    font-weight: bold;
  }
}

.last-seen {
  position: relative;
  top: -8px;
  font-size: 14px;
}

.serial {
  position: relative;
  font-size: 10px;
  top: -8px;
}

.centered {
  position: relative;
  text-align: center;
}

.sensor-text {
  margin-top: 60px;
  text-align: center;
  font-style: italic;
}

.sensor-wrapper {
  margin-top: 40px;
  height: 250px !important;
  max-height: 250px !important;
}
</style>
