<template>
  <div class="card" v-show="value">
    <header class="card-header" v-if="sections.includes('header')">
      <div class="card-header-icon">
        <b-skeleton width="150px" height="150px" class="mb-5" animated circle />
      </div>
      <div class="card-header-title">
        <b-skeleton width="40%" animated></b-skeleton>
      </div>
    </header>
    <section class="card-content" v-if="sections.includes('body')">
      <b-loading :is-full-page="false" v-if="spinner" />
      <section>
        <div class="block">
          <b-skeleton width="20%" animated></b-skeleton>
          <b-skeleton width="30%" animated></b-skeleton>
          <b-skeleton width="40%" animated></b-skeleton>
          <b-skeleton width="80%" animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
        </div>
      </section>
    </section>
    <footer class="card-footer" v-if="sections.includes('footer')">
      <a class="card-footer-item">
        <b-skeleton size="is-large"></b-skeleton>
      </a>
      <a class="card-footer-item">
        <b-skeleton size="is-large"></b-skeleton>
      </a>
      <a class="card-footer-item">
        <b-skeleton size="is-large"></b-skeleton>
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    /**
     * Whether to show animated skeleton
     */
    value: {
      type: Boolean,
      default: true,
    },
    /**
     * Which sections to show; By default is all- ['header', 'body', 'footer']
     */
    sections: {
      type: Array,
      required: false,
      default: () => {
        return ["header", "body", "footer"];
      },
    },
    spinner: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
