import apiClient from "../apiClient";
import {
  cloneDeep,
  flatten,
  chunk,
  range,
  reverse,
  get as loGet,
  set as loSet,
  sortBy,
} from "lodash";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import toRef from "../appLevelFunctions/toRef";
import { alphanumericSortByProperty } from "../appLevelFunctions/alphanumericSort";


function queryElementIsChunkable(q) {
  return (
    ["in", "array_contains", "array_contains_any", "not_in"].includes(q[1]) &&
    Array.isArray(q[2]) &&
    q[2].length > 10
  );
}

function cartesian(...args) {
  var r = [],
    max = args.length - 1;

  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  }

  helper([], 0);
  return r;
}

export let FSClasses = new Set();

/**
 * Base class definition to be overridden per resource.
 * Keys of object are *only to be from the stored document data*.
 * Any helper data should be as a property (`get propName()...`).
 */
export default class FirestoreDataClass {
  static APIResourceName = null;
  static collectionName = null;
  static defaultDisplayString = "Un-named";
  id = null;

  /**
   *
   * @param {Object} data
   */
  constructor(data) {
    FSClasses.add(this.constructor);
    this.updateData(data);
  }

  refresh() {
    if (!this.id) {
      throw "Cannot refresh object without id";
    }
    const self = this;
    return new Promise(function (resolve, reject) {
      self.constructor
        .fetchById(self.id)
        .then((result) => {
          Object.keys(self.$data).forEach((key) => delete self[key]);
          self.updateData(result);
          resolve(self);
        })
        .catch((e) => reject(e));
    });
  }

  /**
   *
   * @param data
   * @returns {firebase.firestore.DocumentReference|firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*}
   */
  static $toFSRef(data) {
    return toRef(data, this.collectionName);
  }

  /**
   * API client instance.
   * @returns {Client}
   */
  static get $client() {
    return apiClient;
  }

  get $client() {
    return this.constructor.$client;
  }

  /**
   * First url segment for resource, i.e. '/rooms'
   * @returns {string}
   */
  static get APIEndpointSegment() {
    return `/${this.APIResourceName || this.collectionName}`;
  }

  get APIEndpointSegment() {
    return this.constructor.APIEndpointSegment;
  }

  /**
   * Used for type checking on instance
   * @param obj {any}
   * @returns {boolean}
   */
  static isInstance(obj) {
    return obj instanceof this;
  }

  /**
   *
   * @param {Array.<FirestoreDataClass>} storeArray
   * @param {String} id
   * @param {any=null} defaultValue
   * @returns {FirestoreDataClass|null}
   */
  static fromStore(storeArray, id, defaultValue = null) {
    const objData = getObjectInArrayById(storeArray, id, defaultValue);
    return objData ? new this(objData) : null;
  }

  /**
   * Returns a promise. *Not* a threat
   * @param id
   * @returns {Promise.<FirestoreDataClass|Error>}
   */
  static fetchById(id) {
    const self = this;
    if (!id) {
      throw "ID is required";
    }
    return new Promise(function (resolve, reject) {
      self.$client
        .get(`${self.APIEndpointSegment}/${id}/`)
        .then((res) => {
          try {
            let instance = new self(res.result);
            resolve(instance);
          } catch (e) {
            reject(e);
          }
        })
        .catch((e) => reject(e));
    });
  }

  /**
   * Get data from object
   * @param {string} keyOrDottedPath
   * @param {any} defaultValue
   * @returns {Exclude<FirestoreDataClass[keyof FirestoreDataClass], undefined>}
   */
  get(keyOrDottedPath, defaultValue = null) {
    return loGet(this, keyOrDottedPath, defaultValue);
  }

  /**
   * set data on object
   * @param keyOrDottedPath
   * @param value
   * @returns {FirestoreDataClass}
   */
  set(keyOrDottedPath, value) {
    loSet(this, keyOrDottedPath, value);
    return this;
  }

  /**
   *
   * @param {Object} data
   */
  updateData(data) {
    for (const [key, value] of Object.entries(data)) {
      this.set(key, value);
    }
    return this;
  }

  /**
   *
   * @returns {FirestoreDataClass}
   */
  clone() {
    return new this.constructor(cloneDeep(this.$data));
  }

  /**
   * All data.
   * @returns {Object}
   */
  get $data() {
    const output = {};
    Object.keys(this).forEach((key) => (output[key] = this[key]));
    return output;
  }

  /**
   *
   * @returns {firebase.firestore.DocumentReference|firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*}
   */
  get $FSRef() {
    return this.constructor.$toFSRef(this);
  }

  /**
   * Standard property; used when rendering presence of object.
   * Override as needed.
   *
   * @returns {string}
   */
  get $displayString() {
    return this.get("name", this.constructor.defaultDisplayString);
  }

  get $isUnnamed() {
    return (
      !this.$displayString ||
      this.$displayString === this.constructor.defaultDisplayString
    );
  }

  get $path() {
    if (!this.id) {
      throw `' ${this.constructor.collectionName}' object has no id`;
    }
    return `${this.constructor.collectionName}/${this.id}`;
  }

  /**
   * We will assume object is saved if it has an id. OK!?!??!?!?!?!?!?
   * @returns {boolean}
   */
  get saved() {
    return !!this.id;
  }

  static deleteById(id) {
    const self = this;
    if (!id) {
      throw `Expected id; received '${id}'`;
    }

    return new Promise(function (resolve, reject) {
      self.$client
        .delete(`${self.APIEndpointSegment}/${id}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => reject(e));
    });
  }

  static bulkDelete(idArray) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self.$client
        .delete(`${self.APIEndpointSegment}/bulk-delete/`, { ids: idArray })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => reject(e));
    });
  }

  static list(urlParams) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self.$client
        .get(`${self.APIEndpointSegment}/`, {}, urlParams)
        .then((res) => {
          try {
            resolve(res.results.map((r) => new self(r)));
          } catch (e) {
            reject(e);
          }
        })
        .catch((e) => reject(e));
    });
  }

  /**
   *
   * @param {Array.<Array.<string>>} queries
   * @param {{order_field: (String|undefined), order_direction: ("asc"|"desc"|undefined), paginate_by:(Number|undefined)}} urlParams
   * @returns {Promise<Array.<FirestoreDataClass>|Error>}
   */
  static query(queries, urlParams = {}) {
    const self = this;
    if (!queries || !queries.length) {
      throw "Queries empty";
    }
    queries.forEach((q) => {
      if (q.length !== 3) {
        throw `Each query must be 3 length array of [field, operator, value]; received ${q.length} items: ${q}`;
      }
    });
    let hasChunks = queries.some(queryElementIsChunkable),
      chunksPerIndex = {};
    if (!hasChunks) {
      return new Promise(function (resolve, reject) {
        self.$client
          .post(`${self.APIEndpointSegment}/query/`, { queries }, urlParams)
          .then((res) => {
            try {
              return resolve(
                res.results
                  .map((r) => new self(r))
                  .sort(alphanumericSortByProperty("$displayString"))
              );
            } catch (e) {
              reject(e);
            }
          })
          .catch((e) => reject(e));
      });
    }

    for (const [idx, val] of Object.entries(queries)) {
      chunksPerIndex[idx] = chunk(val[2], 10);
    }
    let chunkRanges = Object.values(chunksPerIndex).map((chunks) => {
        return chunks.length ? range(chunks.length) : [0];
      }),
      finalQueryChunkCombos = cartesian(...chunkRanges);
    let qEl,
      finalQueries = finalQueryChunkCombos.map((chunkIdxs) => {
        qEl = [];
        for (let [index, elChunkIndex] of chunkIdxs.entries()) {
          qEl.push([
            queries[index][0],
            queries[index][1],
            Array.isArray(chunksPerIndex[index][elChunkIndex])
              ? chunksPerIndex[index][elChunkIndex]
              : queries[index][2],
          ]);
        }
        return qEl;
      });

    const promises = finalQueries.map(
      (queryChunk) =>
        new Promise(function (resolve, reject) {
          self.$client
            .post(
              `${self.APIEndpointSegment}/query/`,
              { queries: queryChunk },
              urlParams
            )
            .then((res) => {
              try {
                return resolve(
                  res.results
                    .map((r) => new self(r))
                    .sort(alphanumericSortByProperty("$displayString"))
                );
              } catch (e) {
                reject(e);
              }
            })
            .catch((e) => reject(e));
        })
    );
    return new Promise(function (resolve, reject) {
      Promise.all(promises)
        .then((resultChunks) => {
          try {
            let results = flatten(resultChunks);
            if (urlParams["order_field"]) {
              results = sortBy(results, urlParams["order_field"]);
              if (urlParams["direction"] === "desc") {
                results = reverse(results);
              }
            }
            return resolve(results);
          } catch (e) {
            reject(e);
          }
        })
        .catch((e) => reject(e));
    });
  }

  /**
   *
   * @param {Array.<[string,string,any]>} queries
   * @param {object} urlParams
   * @returns {Promise<FirestoreDataClass|Error>}
   */
  static queryForSingle(queries, urlParams = {}) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self
        .query(queries, { paginate_by: 2, ...urlParams })
        .then((results) => {
          if (results && results.length && results.length === 1) {
            resolve(results[0]);
          } else {
            reject(`Expected 1 result; Received ${results.length}`);
          }
        })
        .catch((e) => reject(e));
    });
  }

  /**
   * Static/Class def, accepts data
   * @param id
   * @param data
   * @returns {Promise<FirestoreDataClass|Error>}
   */
  static save(id, data) {
    const instance = new this({ ...data, ...{ id } });
    return instance.save();
  }

  /**
   *
   * @returns {Promise<null|Error>}
   */
  delete() {
    if (!this.saved) {
      throw "Object can not be deleted";
    }
    return this.constructor.deleteById(this.id);
  }

  /**
   * Call action by object type
   * @param {String} action
   * @param {String} id
   * @param {Object} args
   * @return {Promise<Object.<success:Boolean>|Error>}
   */
  static performAction(action, id, args = {}) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self.$client
        .put(`${self.APIEndpointSegment}/${id}/`, args, { action })
        .then((response) =>
          response.success
            ? resolve(response)
            : reject("Response did not indicate success")
        )
        .catch((e) => reject(e));
    });
  }

  /**
   * Call action on object
   * @param {string} action
   * @param {object} args
   * @returns {Promise<Object|Error>}
   */
  performAction(action, args = {}) {
    if (!this.id) {
      throw "Can not perform action on object without id";
    }
    return this.constructor.performAction(action, this.id, args);
  }

  /**
   *
   * @returns {Promise<FirestoreDataClass|Error>}
   */
  save() {
    const self = this;
    return new Promise(function (resolve, reject) {
      const method = self.saved ? "patch" : "post";
      self.constructor.$client[method](
        `${self.constructor.APIEndpointSegment}/${
          self.saved ? self.id + "/" : ""
        }`,
        self.$data
      )
        .then((res) => {
          try {
            resolve(new self.constructor(res.result));
          } catch (e) {
            reject(e);
          }
        })
        .catch((e) => reject(e));
    });
  }
}
