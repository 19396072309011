<template>
  <b-table :data="rows" :show-header="false">
    <b-table-column field="label" v-slot="props">
      {{ props.row.label }}
    </b-table-column>
    <b-table-column field="value" v-slot="props">
      <building-display
        :value="props.row.value"
        v-if="props.row.label === 'Area'"
        show-link-icon
        :show-training-mode-warning="false"
      />
      <site-display
        :value="props.row.value"
        v-else-if="props.row.label === 'Site'"
        show-address
        show-link-icon
      />
      <span v-else :class="props.row.class ? props.row.class : ''">
        {{ props.row.value }}
      </span>
      <span
        class="has-text-danger has-text-weight-bold pointer"
        v-if="props.row.warning"
        @click="$emit('warning-clicked', props.row)"
      >
        <b-icon type="is-danger" icon="exclamation-circle" />
        {{ props.row.warning }}
      </span>
    </b-table-column>
  </b-table>
</template>

<script>
import Area from "../dataClasses/Area";

export default {
  name: "AreaDetailTable",
  props: {
    area: {
      type: Area,
      required: true,
    },
    positions: {
      type: Array,
      default: () => [],
    },
    gatewayPositions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rows() {
      let trainingWarning =
        this.positions &&
        this.positions.filter((p) => p.$isInTrainingMode).length > 0
          ? ` (${
              this.positions.filter((p) => p.$isInTrainingMode).length
            } in training mode)`
          : "";
      return [
        {
          label: "Current operating mode",
          value: this.capitalizeFirstLetter(
            this.area.$operatingState || "unknown"
          ),
        },
        {
          label: "Calibration state",
          value: this.capitalizeFirstLetter(
            this.area.$calibrationState || "unknown"
          ),
          warning: this.area.$isInTrainingMode ? " (training mode)" : "",
          class:
            this.area.$calibrationState === "trained"
              ? "has-text-success has-text-weight-bold"
              : "",
        },
        {
          label: "Site",
          value: this.area.$site,
        },
        {
          label: "Area",
          value: this.area.$building,
        },
        {
          label: "Gateways",
          value: this.gatewayPositions.length,
        },
        {
          label: "Sensors",
          value: this.positions.length,
          warning: trainingWarning,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
