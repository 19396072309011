<template>
  <div
    class="mb-5"
    v-if="(alarm.sensors && alarm.sensors.length) || flowLength || startTime"
  >
    <p class="has-text-weight-bold">Details</p>
    <ul class="ml-4">
      <li v-if="startTime">
        <b>Started at</b>:
        {{ toDate(startTime) || "None" }}
        <i v-if="readableStartTimeDelta">({{ readableStartTimeDelta }} ago)</i>
      </li>
      <li v-if="alarm && alarm.classified_as && alarm.classified_as.flowType">
        <b>Classification:</b>
        {{ alarm.classified_as.flowType }}
      </li>
      <li v-if="flowLength"><b>Duration</b>: {{ flowLength }}</li>
      <li v-if="alarm.sensors && alarm.sensors.length">
        <b>Sensors involved</b>:
        <ul>
          <li class="ml-4" v-for="sensor in alarm.sensors" :key="sensor.serial">
            <b>• Serial</b>:
            <router-link
              :to="{
                name: 'go-to-device',
                params: { serial: sensor.serial },
              }"
            >
              {{ sensor.serial }}
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="classifiedAsSource && classifiedAsSource.id">
        <b>Classified as POU:</b>
        <div class="ml-3">
          <source-display
            :value="classifiedAsSource"
            show-parents
            show-link-icon
            show-temp-icon
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import isUserMessageMixin from "../../../mixins/isUserMessageMixin";

export default {
  name: "AlarmSensorsBlock",
  mixins: [isUserMessageMixin],
  components: {  },
  props: {
    alarmObject: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
