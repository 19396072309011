import Vue from "vue";
import VueRouter from "vue-router";
import Roles from "./components/Roles";

import firebase from "firebase/app";
// Home screen (on login)
// Administration
import FileDetail from "./applications/Files/FileDetail";
import ManageHierarchy from "./applications/Administration/ManageHierarchy";
import ManageShipments from "./applications/Administration/ManageShipments";
import ManageUsers from "./applications/Administration/ManageUsers";
import CreateCustomer from "./applications/Administration/create/CreateCustomer";
import ViewArea from "./applications/Administration/View/ViewArea";
import ViewBuilding from "./applications/Administration/View/ViewBuilding";
import ViewCustomer from "./applications/Administration/View/ViewCustomer";
import ViewRoom from "./applications/Administration/View/ViewRoom";
import ViewShipment from "./applications/Administration/View/ViewShipment";
import ViewSite from "./applications/Administration/View/ViewSite";
import ViewSource from "./applications/Administration/View/ViewSource";
import ViewSubcustomer from "./applications/Administration/View/ViewSubcustomer";
import ViewUser from "./applications/Administration/View/ViewUser";
// Alerts
import MessagingHome from "./applications/Alerts/Home";
import UserMessageDetail from "./components/UserMessage/UserMessageDetail";
import AlarmHistoryApp from "./applications/Alerts/AlarmHistoryApp";
// Auth
import Login from "./applications/Auth/Login";
import Logout from "./applications/Auth/Logout";
import RedirectToHome from "./applications/Auth/RedirectToHome";
// Profile
import ProfileHome from "./applications/Profile/Home";
import ProfileEdit from "./applications/Profile/Edit";
import Subscriptions from "./applications/Profile/Subscriptions";
import SignOutOfAllDevices from "./applications/Profile/SignOutOfAllDevices";
// Usage
import UsageReports from "./applications/Usage/Reports";
// Settings
import DeveloperTokens from "./applications/Settings/Sections/DeveloperTokens";
import MessageSettingsTab from "./components/UserMessage/MessageSettingsTab";
//Utilities
import ReportSubscriptionList from "./applications/ReportSubscriptions/ReportSubscriptionList";
import GroundTruth from "./applications/Utilities/GroundTruth/GroundTruth";
import PullReports from "./applications/Utilities/PullReports";
import AreaSourceDetail from "./applications/Utilities/AreaSourceDetail/AreaSourceDetail";
import DevicePagesBase from "./applications/Settings/Devices/DevicePagesBase";
import SensorDetail from "./applications/Settings/Devices/SensorDetail";
import GatewayDetail from "./applications/Settings/Devices/GatewayDetail";
import SiteDetail from "./applications/Settings/Devices/SiteDetail";
import RedirectToDevice from "./applications/Settings/RedirectToDevice";
import iconFor from "./icons";

// Installation
import AddGateway from "./applications/DeviceActionComponents/AddGateway";
import AddSensor from "./applications/DeviceActionComponents/AddSensor";
import AttachProbe from "./applications/DeviceActionComponents/AttachProbe";
import RemoveGateway from "./applications/DeviceActionComponents/RemoveGateway";
import RemoveProbe from "./applications/DeviceActionComponents/RemoveProbe";
import RemoveSensor from "./applications/DeviceActionComponents/RemoveSensor";

import ProbeDetail from "./applications/Settings/Devices/ProbeDetail";
import ViewPipeLocation from "./applications/Administration/View/ViewPipeLocation";
import AreaList from "./applications/Settings/Devices/AreaList";
import AreaDetail from "./applications/Settings/Devices/AreaDetail";
import DashBoard from "./applications/DashBoard/DashBoard";

// training
import TrainingOverview from "./applications/Training/TrainingOverview";
import TrainPointOfUse from "./applications/Training/TrainPointOfUse";

import capitalizeFirstLetter from "./mixins/global/capitalizeFirstLetter";

// Do not re-navigate if already current route
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

/*
  ROUTE EXAMPLE
  @example
    {
      path: "/",            // url
      name: "home",         // reverse name
      component: Home,      // Vue component
      meta: {               //
        icon: "times",      // fas icon to use
        inSidebar: true,     // Show in sidebar
        title: "Home",      // Title for document, elements, etc
        public: false ,     // If true, bypass auth middleware
        allowIf: ({user,roles}) {          // Evaluates whether to show/allow access to route.
          return boolean
        },
      },
    },
 */
function alwaysFalse({user, roles}) {
    return false;
}

alwaysFalse.rejectMessage = "No way!";

function userIsLoggedIn({user}) {
    return user;
}

userIsLoggedIn.rejectMessage = "You must be logged in to do that";

function userIsInstaller({roles, topLevel}) {
    return (
        (roles && roles.is_root) ||
        (topLevel.role && Roles.satisfies(topLevel.role, "installer"))
    );
}

userIsInstaller.rejectMessage =
    "You do not have permission to access that resource";

function userIsAdmin({roles, topLevel}) {
    return (
        (roles && roles.is_root) ||
        (topLevel.role && Roles.satisfies(topLevel.role, "admin"))
    );
}

userIsAdmin.rejectMessage =
    "You do not have permission to access that resource";

function userIsInstallerOrLoggedOut({roles, topLevel, user}) {
    return (
        !user ||
        (roles && roles.is_root) ||
        (topLevel.role && Roles.satisfies(topLevel.role, "installer"))
    );
}

userIsInstallerOrLoggedOut.rejectMessage =
    "You do not have permission to install new components; Please contact your administrator";

function userIsRoot({roles}) {
    return roles && roles.is_root;
}

userIsRoot.rejectMessage = "You do not have permission to access that resource";

function makeReportRouter(solutionCategory, allowIf = userIsLoggedIn) {
    if (!solutionCategory) {
        throw "Need solutionCategory string";
    }
    return {
        path: "reports",
        name: `${solutionCategory}:reports`,
        component: UsageReports,
        props: {category: solutionCategory},
        meta: {
            title: "Reports",
            inSidebar: false,
            icon: iconFor("report"),
            allowIf,
        },
    };
}

/**
 *
 * @param {String} solutionCategory
 * @param  {Function} allowIf
 * @param  {String} title
 * @returns {Object}
 */
function makeAlarmsRouter(
    solutionCategory,
    allowIf = userIsLoggedIn,
    title = null
) {
    if (!solutionCategory) {
        throw "Need solutionCategory string";
    }
    return {
        path: "alarms",
        name: `${solutionCategory}:alarms`,
        // TODO:
        component: AlarmHistoryApp,
        props: {
            category: solutionCategory,
            title: title || `Alarms: ${capitalizeFirstLetter(solutionCategory)}`,
        },
        meta: {
            title: "Alarms",
            inSidebar: true,
            icon: iconFor("alarm"),
            allowIf,
        },
    };
}

const router = new VueRouter({
    mode: "history",
    routes: [
        // Profile
        {
            path: "/profile/:userId?",
            name: "profile",
            component: ProfileHome,
            props: true,
            meta: {
                inNavbar: true,
                heading: "Profile",
                title: "Profile",
                icon: "user",
                allowIf: userIsLoggedIn,
            },
            children: [
                {
                    path: "new",
                    name: "profile:new",
                    component: ProfileEdit,
                    props: false,
                    meta: {
                        inNavbar: false,
                        title: "Add New User",
                        icon: "user",
                        showAsButton: false,
                        allowIf: userIsAdmin,
                    },
                },
                {
                    path: "edit",
                    name: "profile:edit",
                    component: ProfileEdit,
                    props: true,
                    meta: {
                        inNavbar: false,
                        title: "Edit",
                        icon: "address-card",
                        showAsButton: true,
                        allowIf: userIsLoggedIn,
                    },
                },
                {
                    path: "subscriptions",
                    name: "profile:subscriptions",
                    component: Subscriptions,
                    props: true,
                    meta: {
                        title: "Subscriptions",
                        inNavbar: true,
                        icon: "envelope-open-text",
                        showAsButton: true,
                        allowIf: userIsLoggedIn,
                    },
                },
                {
                    path: "universal-sign-out",
                    name: "profile:universal-sign-out",
                    component: SignOutOfAllDevices,
                    props: true,
                    meta: {
                        title: "Sign Out Of All Devices",
                        inNavbar: false,
                        icon: "times-circle",
                        type: "is-danger",
                        showAsButton: true,
                        allowIf: userIsLoggedIn,
                    },
                },
            ],
        },

        // SIDEBAR ITEMS - Uses meta.inSidebar and meta.allowIf()
        {
            path: "/",
            name: "AquaSeca",
            component: RedirectToHome,
            meta: {
                inSidebar: false,
                title: "Re-Directing...",
            },
        },
        // Home
        {
            path: "/home:siteId?",
            name: "home",
            component: DashBoard,
            meta: {
                inSidebar: true,
                title: "Your DashBoard",
                icon: "columns",
                allowIf: userIsLoggedIn,
            },
        },
        // Notifications
        {
            path: "/notifications",
            alias: "/!",
            name: "notifications",
            component: MessagingHome,
            meta: {
                inNavbar: true,
                inSidebar: false,
                title: "Notifications",
                icon: "bell",
                allowIf: userIsLoggedIn,
            },
            children: [
                {
                    path: "view/:id",
                    alias: ":id",
                    name: "notifications:view",
                    component: UserMessageDetail,
                    props: true,
                    meta: {
                        inNavbar: false,
                        title: "View Notification",
                        icon: "message",
                        allowIf: userIsLoggedIn,
                    },
                },
            ],
        },

        // Usage
        {
            path: "/unexpected-flow",
            name: "unexpected-flow",
            alias: "/efficiency", // for url tracing
            component: {render: (h) => h("router-view")},
            redirect: {name: "efficiency:alarms"},
            meta: {
                title: "Unexpected Flow",
                inSidebar: "children-only",
                allowIf: userIsLoggedIn,
                icon: "question-circle",
                // customIconFilename: "water-efficiency.svg",
            },
            children: [
                makeAlarmsRouter(
                    "efficiency",
                    userIsLoggedIn,
                    "Alarms: Unexpected Flow"
                ),
                // makeReportRouter("efficiency"),
            ],
        },
        {
            path: "/efficiency",
            name: "efficiency",
            component: {render: (h) => h("router-view")},
            redirect: {name: "efficiency:reports"},
            meta: {
                title: "Water Efficiency",
                inSidebar: false,
                allowIf: userIsLoggedIn,
                customIconFilename: "water-efficiency.svg",
            },
            children: [
                makeAlarmsRouter("efficiency", userIsLoggedIn, 'Alarms: Water Efficiency'),
                makeReportRouter("efficiency", userIsLoggedIn),
            ],
        },
        {
            path: "/hygiene",
            name: "hygiene",
            component: {render: (h) => h("router-view")},
            redirect: {name: "hygiene:alarms"},
            meta: {
                inSidebar: "children-only",
                title: "Water Hygiene",
                customIconFilename: "water-hygiene.svg",
                allowIf: userIsLoggedIn,
            },
            children: [
                makeAlarmsRouter("hygiene"),
                makeReportRouter("hygiene")
            ],
        },
        {
            path: "/leaks",
            name: "leaks",
            component: {render: (h) => h("router-view")},
            redirect: {name: "leaks:alarms"},
            meta: {
                inSidebar: "children-only",
                title: "Leak Detection",
                customIconFilename: "leak-detection.svg",
                allowIf: userIsRoot,
                horizontalRuleAfter: true,
            },
            children: [
                makeAlarmsRouter("leaks", userIsRoot),
                makeReportRouter("leaks", userIsRoot),
            ],
        },
        {
            path: "/device/:serial",
            name: "go-to-device",
            component: RedirectToDevice,
            props: true,
            meta: {
                title: "Re-Directing",
                inSidebar: false,
                icon: iconFor("device"),
                allowIf: userIsLoggedIn,
            },
        },
        // Settings
        {
            path: "/settings",
            name: "settings",
            component: {render: (h) => h("router-view")},
            redirect: {name: "devices"},
            meta: {
                title: "Settings",
                inSidebar: "children-only",
                heading: "Settings",
                headingIcon: "clipboard",
                icon: iconFor("settings"),
                horizontalRuleAfter: true,
                allowIf: userIsInstaller,
            },
            children: [
                {
                    path: "schedule/:siteId?",
                    name: "settings:alarmSchedule",
                    component: MessageSettingsTab,
                    props: true,
                    meta: {
                        title: "Alarm Schedules",
                        inSidebar: true,
                        icon: iconFor("schedule"),
                        allowIf: userIsAdmin,
                    },
                },
                {
                    path: "tokens",
                    name: "settings:api-tokens",
                    component: DeveloperTokens,
                    props: true,
                    meta: {
                        title: "API Tokens",
                        inSidebar: true,
                        icon: iconFor("token"),
                        allowIf: userIsAdmin,
                        enabledByFeatureFlag: "developer_mode",
                    },
                },
                // devices
                {
                    path: "devices/",
                    name: "devices",
                    component: DevicePagesBase,
                    meta: {
                        title: "Your Devices",
                        inSidebar: true,
                        icon: iconFor("device"),
                        allowIf: userIsInstaller,
                    },
                    children: [
                        {
                            path: "",
                            name: "devices:site-detail",
                            component: SiteDetail,
                            props: true,
                            meta: {
                                title: "Devices On-Site",
                                inSidebar: false,
                                icon: iconFor("site"),
                                allowIf: userIsInstaller,
                            },
                            children: [
                                {
                                    path: "by-area",
                                    name: "devices:area-list",
                                    component: AreaList,
                                    meta: {
                                        title: "Manage Devices",
                                        inSidebar: true,
                                        icon: iconFor("area"),
                                        allowIf: userIsInstaller,
                                    },
                                    children: [
                                        {
                                            path: ":areaId",
                                            name: "devices:area-detail",
                                            component: AreaDetail,
                                            props: true,
                                            meta: {
                                                title: "Area Devices Detail",
                                                inSidebar: false,
                                                icon: iconFor("area"),
                                                allowIf: userIsInstaller,
                                            },
                                        },
                                    ],
                                },

                            ],
                        },
                        {
                            path: "remove-gateway",
                            name: "remove-gateway",
                            component: RemoveGateway,
                            meta: {
                                title: "Remove Gateway",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "remove-sensor",
                            name: "remove-sensor",
                            component: RemoveSensor,
                            meta: {
                                title: "Remove Sensor",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "attach-probe",
                            name: "attach-probe",
                            component: AttachProbe,
                            meta: {
                                title: "Attach Probe",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "remove-probe",
                            name: "remove-probe",
                            component: RemoveProbe,
                            meta: {
                                title: "Remove Probe",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "add-gateway",
                            name: "add-gateway",
                            component: AddGateway,
                            meta: {
                                title: "Add Gateway",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "add-sensor",
                            name: "add-sensor",
                            component: AddSensor,
                            meta: {
                                title: "Add Sensor",
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "systems",
                            name: "devices:system-list",
                            redirect: {name: "devices:area-list"},
                            children: [
                                {
                                    path: ":areaId",
                                    name: "devices:system-detail",
                                    redirect: {name: "devices:area-detail"},
                                },
                            ],
                        },
                        {
                            path: "gateways/:serial",
                            name: "devices:gateway-detail",
                            component: GatewayDetail,
                            props: true,
                            meta: {
                                title: "Gateway Detail",
                                inSidebar: false,
                                icon: iconFor("gateway"),
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "sensors/:serial",
                            name: "devices:sensor-detail",
                            component: SensorDetail,
                            props: true,
                            meta: {
                                title: "Sensor Detail",
                                inSidebar: false,
                                icon: iconFor("sensor"),
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "probes/:serial",
                            name: "devices:probe-detail",
                            component: ProbeDetail,
                            props: true,
                            meta: {
                                title: "Probe Detail",
                                inSidebar: false,
                                icon: iconFor("probe"),
                                allowIf: userIsInstaller,
                            },
                        },
                    ],
                },
            ],
        },

        // Administration
        {
            path: "/administration",
            name: "administration",
            component: {render: (h) => h("router-view")},
            redirect: {name: "administration:hierarchy"},
            meta: {
                title: "Administration",
                icon: iconFor("administration"),
                inSidebar: "children-only",
                allowIf: userIsInstaller,
                horizontalRuleAfter: true,
            },
            children: [
                {
                    path: "/report-subscriptions",
                    name: "report-subscriptions",
                    component: ReportSubscriptionList,
                    meta: {
                        icon: "chart-area",
                        title: "Report subscriptions",
                        inSidebar: true,
                        allowIf: userIsAdmin,
                    },
                },
                {
                    path: "files/:filePath(.*)",
                    name: "administration:file-detail",
                    component: FileDetail,
                    props: true,
                    meta: {
                        title: "File Detail",
                        icon: iconFor("file"),
                        inSidebar: false,
                        allowIf: userIsLoggedIn,
                    },
                },
                {
                    path: "shipments",
                    name: "administration:shipments",
                    component: ManageShipments,
                    meta: {
                        title: "Manage Shipments",
                        icon: iconFor("shipment"),
                        inSidebar: true,
                        allowIf: userIsRoot,
                    },
                    children: [
                        {
                            path: ":id",
                            name: "administration:shipments:view",
                            component: ViewShipment,
                            props: true,
                            meta: {
                                title: "View Shipment",
                                inSidebar: false,
                                allowIf: userIsRoot,
                            },
                        },
                    ],
                },
                {
                    path: "~",
                    name: "administration:hierarchy",
                    component: ManageHierarchy,
                    meta: {
                        title: "Site Administration",
                        icon: iconFor("hierarchy"),
                        inSidebar: true,
                        allowIf: userIsInstaller,
                    },
                    children: [
                        {
                            path: "new-customer",
                            name: "administration:create-customer",
                            component: CreateCustomer,
                            meta: {
                                title: "Create Customer",
                                icon: iconFor("create"),
                                inSidebar: true,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "customers/:id",
                            name: "administration:view-customer",
                            component: ViewCustomer,
                            props: true,
                            meta: {
                                title: "View Customer",
                                icon: iconFor("customer"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "subcustomers/:id",
                            name: "administration:view-subcustomer",
                            component: ViewSubcustomer,
                            props: true,
                            meta: {
                                title: "View Sub-Customer/Division",
                                icon: iconFor("subcustomer"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "sites/:id",
                            name: "administration:view-site",
                            component: ViewSite,
                            props: true,
                            meta: {
                                title: "View Site",
                                icon: iconFor("site"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "buildings/:id",
                            name: "administration:view-building",
                            redirect: {name: "administration:view-area"},
                            props: true,
                        },
                        {
                            path: "areas/:id",
                            name: "administration:view-area",
                            component: ViewBuilding,
                            props: true,
                            meta: {
                                title: "View Area",
                                icon: iconFor("building"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "rooms/:id",
                            name: "administration:view-room",
                            component: ViewRoom,
                            props: true,
                            meta: {
                                title: "View Room",
                                icon: iconFor("room"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "internal-areas/:id",
                            name: "administration:view-internal-area",
                            component: ViewArea,
                            props: true,
                            meta: {
                                title: "View Internal Area",
                                icon: iconFor("area"),
                                inSidebar: false,
                                allowIf: userIsRoot,
                            },
                        },
                        {
                            path: "pipe-locations/:id",
                            name: "administration:view-pipe-location",
                            component: ViewPipeLocation,
                            props: true,
                            meta: {
                                title: "View Pipe Location",
                                icon: iconFor("pipe_location"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                        {
                            path: "sources/:id",
                            name: "administration:view-source",
                            component: ViewSource,
                            props: true,
                            meta: {
                                title: "View Point-Of-Use",
                                icon: iconFor("source"),
                                inSidebar: false,
                                allowIf: userIsInstaller,
                            },
                        },
                    ],
                },
                {
                    path: "users/:id",
                    name: "administration:users:edit",
                    component: ViewUser,
                    props: true,
                    meta: {
                        title: "Edit",
                        icon: "user",
                        inSidebar: false,
                        allowIf: userIsAdmin,
                    },
                },
                {
                    path: "users",
                    name: "administration:users",
                    component: ManageUsers,
                    meta: {
                        title: "User Administration",
                        icon: "users",
                        inSidebar: true,
                        allowIf: userIsAdmin,
                    },
                },
            ],
        },

        ////  Not in Menu
        // Training

        // System
        {
            path: "/utilities",
            alias: "/system",
            name: "utilities",
            component: {render: (h) => h("router-view")},
            redirect: {name: "system:alarms"},
            meta: {
                icon: iconFor("utilities"),
                title: "System / Utilities",
                inSidebar: "children-only",
                public: false,
                allowIf: userIsLoggedIn,
                horizontalRuleAfter: true,
            },
            children: [
                {
                    path: "pull-reports",
                    name: "utilities:pull-reports",
                    component: PullReports,
                    meta: {
                        icon: iconFor('report'),
                        title: "Pull Reports",
                        inSidebar: true,
                        public: false,
                        allowIf: userIsLoggedIn,
                    },
                },
                makeAlarmsRouter("system", userIsLoggedIn, 'System alarms'),
                {
                    path: "ground-truth/at/:siteId?/:id?",
                    alias: ["ground-truth/at/:siteId?"],
                    name: "utilities:ground-truth",
                    component: GroundTruth,
                    props: true,
                    meta: {
                        icon: iconFor("ground_truth"),
                        title: "Ground Truth",
                        inSidebar: true,
                        public: false,
                        allowIf: userIsInstaller,
                    },
                },
                makeReportRouter("system"),
                {
                    path: "source-detail",
                    name: "utilities:source-detail",
                    component: AreaSourceDetail,
                    props: true,
                    meta: {
                        icon: iconFor("source_detail"),
                        title: "Source Detail",
                        inSidebar: true,
                        public: false,
                        allowIf: userIsRoot,
                    },
                },
                {
                    path: "/training/:areaId?",
                    name: "training:overview",
                    component: TrainingOverview,
                    props: true,
                    meta: {
                        icon: iconFor("training"),
                        title: "Training",
                        public: false,
                        inSidebar: true,
                        allowIf: userIsInstaller,
                    },
                    children: [
                        {
                            path: "src/:sourceId",
                            name: "training:by-source",
                            component: TrainPointOfUse,
                            props: true,
                            meta: {
                                icon: null,
                                title: "Train point-of-use",
                                public: false,
                                allowIf: userIsInstaller,
                            },
                        },
                    ],
                },
            ],
        },

        {
            path: "/login",
            name: "login",
            component: Login,
            meta: {
                icon: iconFor("login"),
                title: "Log-In",
                inSidebar: false,
                public: true,
            },
        },
        {
            path: "/logout",
            name: "logout",
            component: Logout,
            meta: {
                title: "Log Out",
                inNavbar: false,
                icon: "sign-out-alt",
                public: true,
            },
        },
        /**
         * OLD INSTALL WIZARD
         */
        {
            path: "/install/:code?",
            name: "settings:install",
            // component: DashBoard,
            redirect: {name: "redirectToHome"},
            props: true,
            meta: {
                title: "Install",
                inSidebar: false,
                icon: iconFor("install"),
                allowIf: userIsLoggedIn,
                public: true,
            },
            children: [
                {
                    path: "wizard",
                    name: "settings:install:wizard",
                    // component: InstallationWizard,
                    redirect: {name: "redirectToHome"},
                    props: true,
                    meta: {
                        title: "Wizard",
                        inSidebar: false,
                        icon: "",
                        allowIf: userIsLoggedIn,
                        public: true,
                    },
                },
            ],
        },
        {
            path: "/go-home",
            alias: "*",
            name: "redirectToHome",
            component: RedirectToHome,
            meta: {
                title: "Re-Directing...",
                public: true,
            },
        },
    ],
});

// Auth middleware via Route Guard
/**
 * Authentication routeguard works as such:
 * - If toRoute.meta.public is truthy, let through no matter what.
 * - Otherwise toRoute.meta.allowIf (callable) is present,
 *     let through if truthy value of allowIf({ user, roles }) is returned.
 *     When this happens, display callable.displayMessage if present.
 * - Else, require authenticated user.
 *
 * See top of file for allowIf functions that can be used
 */
router.beforeEach(async (to, from, next) => {
    if (to.meta.public && !to.meta.allowIf) {
        return next();
    }

    const store = router.app.$store,
        user = await firebase.auth().currentUser,
        requiresAuth = to.matched.some(
            (record) => record.meta && !record.meta.public
        );
    if (user) {
        let allowed =
            !to.meta.allowIf ||
            to.meta.allowIf({
                user,
                roles: store.state.user ? store.state.user_roles : null,
                topLevel: store.getters.topLevel,
            });
        if (allowed) {
            if (to.matched && !to.query.loadScope && (
                store.state.selectedScopeIdStr || (store.state.user_account && store.state.user_account.default_site_id_str)
            )) {
                // console.log(1.001)
                to.query.loadScope = store.state.selectedScopeIdStr || store.state.user_account.default_site_id_str;
                next({path: to.path, query: to.query});
            } else if (to.matched) {
                // console.log(1.1)
                next()
            } else {
                // console.log(1.2, {from, to})
                await router.push({
                    name: "redirectToHome",
                    query: {
                        nextUrl: from.query.nextUrl,
                    },
                });
            }
        } else {
            // console.log(2)
            router.app.$handleError(to.meta.allowIf.rejectMessage);
        }
    } else if (requiresAuth) {
        // console.log(3)
        if (from.name && from.name !== "login") {
            // if not, will be from external; defer message
            router.app.$handleError(userIsLoggedIn.rejectMessage);
        }
        let query = to ? {nextUrl: to.fullPath} : {};
        next({
            name: "login",
            query: query,
        });
    } else {
        // console.log(4)
        next();
    }
});

// Update document title
const DEFAULT_TITLE = "AquaSeca - Water Intelligence";
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = `${to.meta.title} | AquaSeca` || DEFAULT_TITLE;
    });
});

export default router;
