<template>
  <div>
    <div v-if="$route.name === 'administration:hierarchy'">
      <div>
        <div class="columns">
          <div
            class="column is-8-desktop is-offset-2-desktop is-10-tablet is-offset-1-tablet"
          >
            <div class="card">
              <div class="card-header">
                <div class="card-header-title">
                  General administration <span v-if="topLevel && topLevel.object">for {{ topLevel.object.name }}</span>
                </div>
              </div>
              <div class="card-content">
                <div v-if="topLevel.object">
                  <div v-if="$store.getters.requiresScopeSelection">
                    You are currently working within the scope of
                    <b>{{ topLevel.object.name }}</b> (<i>{{
                      topLevel.readableType
                    }}</i
                    >).
                    <a @click="$store.dispatch('showSelectScopeModal')"
                      >Click here</a
                    >
                    to choose another.
                  </div>
                  <br />
                  <b-message
                    type="is-info"
                    title="Site administration"
                    has-icon
                  >
                    <b-icon
                      icon-left="info-circle"
                      type="is-info"
                      size="is-medium"
                    />
                    Setting up your sites in AquaSeca is easy. Click
                    <a
                      href="https://wiki.aquaseca.com/en/user-manual/site-administration"
                      target="_blank"
                      >here</a
                    >
                    to learn more.
                  </b-message>
                  <level :values="displayTable"></level>
                </div>
              </div>
              <div class="card-footer">
                <div class="card-footer-item" v-if="user_roles.is_root">
                  <b-button
                    expanded
                    @click="
                      $router.push({ name: 'administration:create-customer' })
                    "
                    icon-left="plus"
                  >
                    {{ isMobile ? "Add" : "Create a new customer" }}
                  </b-button>
                </div>
                <div class="card-footer-item">
                  <b-button
                    expanded
                    type="is-link"
                    icon-right="chevron-right"
                    tag="router-link"
                    :to="{
                      name: 'administration:view-' + topLevel.singularName,
                      params: { id: topLevel.object.id },
                    }"
                  >
                    {{ isMobile ? "View" : "Go to " + topLevel.object.name }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import iconFor from "../../icons";
import { mapGetters, mapState } from "vuex";
import Level from "../../components/Level/Level";
import trackWindowSize from "../../mixins/trackWindowSize";

export default {
  name: "ManageHierarchy",
  mixins: [trackWindowSize],
  components: { Level },
  computed: {
    ...mapGetters(["topLevel"]),
    ...mapState([
      "user_roles",
      "subcustomers",
      "sites",
      "buildings",
      "rooms",
      "areas",
      "sources",
    ]),
    displayTable() {
      let self = this,
        showSubcustomers = false,
        hierarchy = [];
      hierarchy = hierarchy.concat([
        {
          label: "Areas",
          value: this.buildings.length,
          icon: iconFor("building"),
        },
        {
          label: "Rooms",
          value: this.rooms.length,
          icon: iconFor("room"),
        },
        {
          label: "Points-of-use (POUs)",
          value: this.sources.length,
          icon: iconFor("source"),
        },
        {
          label: "Pipe locations",
          value: this.$store.state.pipe_locations.length,
          icon: iconFor("pipe_location"),
        },
        {
          label: "Areas ",
          value: this.buildings.length,
          icon: iconFor("building"),
        }
      ]);
      return hierarchy;
    },
  },
  data() {
    return {
      cardLoading: false,
      editId: null,
      modalOpen: false,
      showSelection: false,
    };
  },
  methods: {
    openModal(id) {
      this.editId = id;
      this.modalOpen = true;
    },
  },
  watch: {
    cardLoading: {
      handler(isLoading) {
        // Careful, as this may be triggered by children. Only respect if base page is current.
      },
    },
  },
};
</script>

<style scoped></style>
