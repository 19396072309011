import FirestoreDataClass from "./_baseClasses";

export default class Bundle extends FirestoreDataClass {
  static collectionName = "bundles";

  get shippingSlipUrl() {
    return this.id
      ? `${this.constructor.$client.basePath}/bundles/${this.id}/files/shipping-slip/`
      : null;
  }
}
