<template>
  <span class="vu-meter">
      <canvas
          :width="width"
          :height="height"
          v-draw-meter="{ amp: dBVal, peak: dBPeakVal, clipSize }"
      />
    </span>
</template>

<script>

export default {
  name: "vu-meter",
  data() {
    return {
      peakVal: 10,
    }
  },
  props: {
    /**
     * 0 - 10
     */
    val: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 10
    },
    height: {
      type: Number,
      default: 150
    },
  },
  computed: {
    clipSize() {
      return this.peakVal;
    },
    dBVal() {
      return 20 * Math.log10(this.val * .1);
    },
    dBPeakVal() {
      return 20 * Math.log10(this.peakVal);
    },
  },
  directives: {
    drawMeter: function (canvas, binding) {
      var clipSize = binding.value.peak;
      var amp = ((binding.value.amp / 152 + 1 ) * 1.0345 ) ** 8;
      var w = canvas.width;
      var h = canvas.height;
      var hInRange = h - clipSize ;
      var ctx = canvas.getContext("2d");
      var gradient = ctx.createLinearGradient(0, 0, 0, h);
      gradient.addColorStop(0, "orange");
      gradient.addColorStop(clipSize / (h - h*.25), "yellow");
      gradient.addColorStop(clipSize / (h - h*.5), "greenyellow");
      gradient.addColorStop(1, "lime");
      ctx.clearRect(0, 0, w, h);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, h - hInRange * amp, w, hInRange * amp);
    }
  }
}
</script>

<style scoped>
canvas {
  background: #000;
  z-index:10
}

</style>