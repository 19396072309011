var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"media"},[_c('div',{staticClass:"media-content detail-media"},[_c('div',{class:{ columns: _vm.showEventHistory }},[_c('div',{staticClass:"media-col column is-one-third-tablet"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Gateways")]),(_vm.bundle.gateways && _vm.bundle.gateways.length)?_c('ul',{staticClass:"inner-list"},_vm._l((_vm.bundle.gateways),function(gateway){return _c('li',{key:gateway.serial},[_c('router-link',{attrs:{"to":{
                name: 'go-to-device',
                params: { serial: gateway.serial },
              }}},[_vm._v(" "+_vm._s(gateway.serial)+" ")])],1)}),0):_c('p',{staticClass:"ml-5"},[_vm._v("None assigned")]),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Sensors")]),(_vm.bundle.sensors && _vm.bundle.sensors.length)?_c('ul',{staticClass:"inner-list"},_vm._l((_vm.bundle.sensors),function(sensor){return _c('li',{key:sensor.serial},[_c('router-link',{attrs:{"to":{
                name: 'go-to-device',
                params: { serial: sensor.serial },
              }}},[_vm._v(" "+_vm._s(sensor.serial)+" ")])],1)}),0):_c('p',{staticClass:"ml-5"},[_vm._v("None assigned")]),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Probes")]),(_vm.bundle.probes && _vm.bundle.probes.length)?_c('ul',{staticClass:"inner-list"},_vm._l((_vm.bundle.probes),function(probe){return _c('li',{key:probe.serial},[_c('router-link',{attrs:{"to":{
                name: 'go-to-device',
                params: { serial: probe.serial },
              }}},[_vm._v(" "+_vm._s(probe.serial)+" ")])],1)}),0):_c('p',{staticClass:"ml-5"},[_vm._v("None assigned")]),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Assigned to")]),(_vm.subcustomer)?_c('p',{staticClass:"ml-5"},[_c('router-link',{attrs:{"to":{
              name: 'administration:view-subcustomer',
              params: { id: _vm.subcustomer.id },
            }}},[_vm._v(" "+_vm._s(_vm.subcustomer.$displayString)+" ")])],1):(_vm.loading)?_c('p',{staticClass:"ml-5"},[_c('b-icon',{staticClass:"fa-spin",attrs:{"icon":"sync","size":"is-small"}})],1):_c('p',[_c('i',[_vm._v("None")])])]),(_vm.showEventHistory)?_c('div',{class:{
          'media-col': true,
          'column is-two-thirds-tablet': _vm.showEventHistory,
        }},[_c('event-history',{attrs:{"tabs":_vm.tabs,"paginate-by":5}})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }