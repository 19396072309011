var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[(!_vm.forced)?_c('div',{class:{
        'column is-12 is-5-desktop is-4-fullhd': true,
        'is-hidden-mobile is-hidden-tablet-only':
          _vm.$route.name === 'notifications:view',
      }},[_c('b-tabs',{staticClass:"tab-item-outer",attrs:{"type":"is-boxed","expanded":"","size":"is-small"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('b-tab-item',{staticClass:"tab-item-inner",attrs:{"label":'New (' + _vm.messages.length + ')'}},[_c('div',{staticClass:"scroller",attrs:{"id":"scroller-messages"}},[(_vm.selected.length)?_c('b-dropdown',{staticClass:"mb-2",attrs:{"aria-role":"list","expanded":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{attrs:{"expanded":"","label":_vm.selected.length +
                    ' message' +
                    (_vm.selected.length === 1 ? '' : 's') +
                    ' selected',"type":"is-primary","icon-right":active ? 'chevron-up' : 'chevron-down'}})]}}],null,false,497143991)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.dismissSelected}},[_vm._v(" Dismiss selected ")])],1):_vm._e(),_c('transition-group',{attrs:{"name":"slide","tag":"p"}},_vm._l((_vm.messages.filter((m) => !m.dismissed)),function(message){return _c('div',{key:message.id,class:{
                  selected: _vm.selected.includes(message.id),
                }},[(message)?_c('user-message-box',{ref:'message-' + message.id,refInFor:true,attrs:{"state-var":"_messages","message":message,"loading":_vm.loadingMessageIds.includes(message.id),"id":'message-' + message.id,"selected":_vm.selected.includes(message.id)},on:{"update:loading":function($event){return _vm.handleLoading($event)},"close":function($event){return _vm.returnToUnselectedView(message)},"click":function($event){return _vm.scrollToMessage(message)}}}):_vm._e()],1)}),0),(
                !_vm.$store.state.loadingMessages &&
                (!_vm.messages || !_vm.messages.length)
              )?_c('p',{staticClass:"has-text-centered mt-6"},[_vm._v(" No messages found. "),(_vm.filters && _vm.filters.length)?_c('span',[_vm._v(" You may want to try "),_c('a',{on:{"click":function($event){_vm.filters = []}}},[_vm._v("resetting filters")]),_vm._v(". ")]):_vm._e()]):_vm._e()],1),(_vm.messages.length === _vm.activePage * 10)?_c('b-button',{staticClass:"mt-2",attrs:{"expanded":""},on:{"click":function($event){_vm.activePage += 1}}},[_vm._v(" View more ")]):_vm._e()],1),_c('b-tab-item',{staticClass:"tab-item-inner",attrs:{"label":'Dismissed (' + _vm.dismissedMessages.length + ')'}},[_c('div',{staticClass:"scroller"},[_c('transition-group',{attrs:{"name":"slide","tag":"p"}},_vm._l((_vm.dismissedMessages.filter(
                  (m) => m.dismissed
                )),function(message){return _c('user-message-box',{key:message.id,ref:'message-' + message.id,refInFor:true,attrs:{"state-var":"_dismissedMessages","loading":_vm.loadingMessageIds.includes(message.id),"id":'message-' + message.id,"message":message},on:{"update:loading":function($event){return _vm.handleLoading($event)},"close":function($event){return _vm.returnToUnselectedView(message)},"click":function($event){return _vm.scrollToMessage(message)}}})}),1),(
                !_vm.$store.state.loadingDismissedMessages &&
                (!_vm.dismissedMessages || !_vm.dismissedMessages.length)
              )?_c('p',{staticClass:"has-text-centered mt-6"},[_vm._v(" No dismissed messages found. "),(_vm.filters && _vm.filters.length)?_c('span',[_vm._v(" You may want to try "),_c('a',{on:{"click":function($event){_vm.filters = []}}},[_vm._v("resetting filters")]),_vm._v(". ")]):_vm._e()]):_vm._e()],1),(_vm.dismissedMessages.length === _vm.dismissedPage * 10)?_c('b-button',{attrs:{"expanded":""},on:{"click":function($event){_vm.dismissedPage += 1}}},[_vm._v(" View more ")]):_vm._e()],1),_c('b-tab-item',{staticClass:"tab-item-inner",attrs:{"label":'Filters (' + _vm.filters.length + ')'}},[_c('div',{staticClass:"scroller",attrs:{"id":"scroller-dismissedMessage"}},[_c('message-filter',{on:{"change":function($event){_vm.activePage = 1;
                _vm.dismissedPage = 1;
                _vm.filters = $event;}}})],1)])],1)],1):_vm._e(),_c('div',{class:{
        'column is-7-desktop is-8-fullhd': !_vm.forced,
        'column is-12': _vm.forced,
      }},[_c('router-view',{attrs:{"loading":_vm.loadingMessageIds.includes(_vm.$route.params.id)},on:{"update:loading":function($event){return _vm.handleLoading($event)}}}),_c('div',{staticClass:"content is-hidden-tablet-only is-hidden-mobile-only"},[(_vm.$route.name === 'notifications')?_c('div',{staticClass:"has-text-centered"},[_c('b-message',{attrs:{"type":"is-info","title":"About messages and alarms","icon-left":"info-circle","closable":false,"has-icon":_vm.windowWidth > 883}},[_c('div',{staticClass:"mb-5"},[_vm._v(" Select a message to view more details or use "),_c('a',{on:{"click":function($event){_vm.tab = 2}}},[_vm._v("filters")]),_vm._v(" to find specific message types. ")]),_c('b-button',{attrs:{"expanded":"","icon-right":_vm.iconFor('newWindow'),"type":"is-info"},on:{"click":function($event){return _vm.window.open(
                  'https://wiki.aquaseca.com/en/user-manual/messages-and-alarms',
                  '_blank'
                )}}},[_vm._v(" Click here to learn more ")])],1)],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }