<template>
  <div :class="'buttons ' + classes ? classes : ''">
    <b-button
      class="m-2"
      @click="$emit('backward')"
      :disabled="!canGoBackward"
      icon-left="chevron-left"
    >
      Go back
    </b-button>
    <b-button
      class="m-2"
      v-if="!isLastStep"
      @click="$emit('forward')"
      :disabled="!canGoForward"
      icon-right="chevron-right"
    >
      Continue
    </b-button>
    <b-button
      class="m-2"
      v-if="isLastStep"
      @click="$emit('complete')"
      :disabled="!allowForward"
      :loading="confirmLoading"
      :icon-right="iconFor('save')"
      :type="confirmType || 'is-success'"
    >
      Confirm
    </b-button>
  </div>
</template>

<script>
import iconFor from "../../../icons";
export default {
  name: "ForwardBackward",
  props: [
    "step",
    "minStep",
    "maxStep",
    "allowForward",
    "classes",
    "confirmLoading",
    "confirmType",
  ],
  computed: {
    canGoBackward() {
      return this.step > this.minStep;
    },
    canGoForward() {
      return this.allowForward && !this.isLastStep;
    },
    isLastStep() {
      return this.step >= this.maxStep;
    },
  },
  methods: {
    iconFor,
  },
};
</script>

<style scoped>
.float-right {
  justify-content: end;
}
</style>
