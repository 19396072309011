<template>
  <div>
    <div class="column mb-0 mt-3 has-text-weight-bold" v-if="title">
      {{ title }}
      <b-button
        v-if="canAdd"
        icon-left="plus"
        type="is-success"
        class="is-pulled-right"
        size="is-small"
        @click.native="modalActive = true"
      >
        Add new
      </b-button>
    </div>
    <b-table
      :loading="loading"
      :data="rows"
      striped
      scrollable
      :default-sort="['floor', 'name']"
      default-sort-direction="asc"
      sort-icon="chevron-down"
    >
      <template #empty>
        <slot name="empty-content">
          <i
            >No pipe locations to display.
            <a @click="modalActive = true">Click here</a> to add a new pipe
            location.
          </i>
        </slot>
      </template>
      <b-table-column label="Name" v-slot="props" field="$displayString" sortable :custom-sort="alphanumericSort">
        {{ props.row.$displayString }}
      </b-table-column>
      <slot name="post-name">
        <b-table-column label="Device" v-slot="props">
          <span v-if="props.row.position">Sensor</span>
          <span v-else-if="props.row.probe">Probe</span>
          <span v-else><b-icon icon="times" type="is-danger" /></span>
        </b-table-column>
      </slot>

      <b-table-column
        label="Area"
        v-slot="props"
        sortable
        :custom-sort="compareFieldFunc('$building.name')"
      >
        <span style="zoom: 80%">
          <building-display
            :value="getBuilding(props.row.building)"
            :show-floors="false"
          />
        </span>
      </b-table-column>
      <b-table-column
        label="Room"
        v-slot="props"
        sortable
        :custom-sort="compareFieldFunc('$room.name')"
      >
        <span style="zoom: 80%">
          <room-display
            :value="getRoom(props.row.room)"
            v-if="props.row.room"
            :show-floor="false"
          />
        </span>
      </b-table-column>
      <b-table-column label="Floor" v-slot="props" field="$floor" sortable>
        {{ props.row.$floor || "" }}
      </b-table-column>
      <b-table-column
        label="POUs"
        v-slot="props"
        sortable
        :custom-sort="compareFieldFunc('$source.name')"
      >
        {{ (props.row.get("downstream_sources", null) || []).length }}
      </b-table-column>
      <b-table-column label="Temp." v-slot="props" sortable field="temperature">
        <b-icon
          :icon="iconFor(props.row.temperature || 'unknown')"
          size="is-small"
          :type="`is-${props.row.temperature}`"
        />
      </b-table-column>
      <b-table-column v-slot="props">
        <b-icon
          v-if="canCopy"
          :icon="iconFor('copy')"
          type="is-link"
          class="pointer mr-3"
          size="is-small"
          @click.native="$emit('copy-clicked', props.row)"
        />
        <b-button
          type="is-primary"
          :outlined="selectedId !== props.row.id"
          rounded
          size="is-small"
          :icon-right="selectedId !== props.row.id ? 'chevron-right' : null"
          @click="
            $emit('input', selectedId === props.row.id ? null : props.row)
          "
        >
          Select{{ selectedId === props.row.id ? "ed" : "" }}
        </b-button>
      </b-table-column>
      <template #footer>
        <pagination-controls
          :has-more-results="rows.length >= paginate_by"
          :loading="loading"
          :page="page"
          @input="page = $event.page"
        />
      </template>
    </b-table>
    <add-pipe-location-modal
      v-if="area && building"
      :parent="area"
      :building="building"
      v-model="modalActive"
      @save="handleNewPipeLocation"
    />
  </div>
</template>

<script>
import PaginationControls from "../components/Pagination/PaginationControls";
import AddPipeLocationModal from "../components/Admin/PipeLocation/AddPipeLocationModal";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import iconFor from "../icons";
import { compareFieldFunc } from "../dataClasses/functions";

export default {
  name: "PipeLocationTable",
  components: {
    AddPipeLocationModal,
    PaginationControls,
  },
  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    canCopy: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginate_by: {
      type: Number,
      default: 25,
    },
    rows: {
      type: Array,
      required: true,
    },
    selectedId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Pipe locations",
    },
    area: {
      type: Object,
      validator(val) {
        return val && val._reference.path.startsWith("areas/");
      },
    },
    building: {
      type: Object,
      validator(val) {
        return val && val._reference.path.startsWith("buildings/");
      },
    },
  },
  computed: {
    modalActive: {
      get() {
        return !!this.$route.query.showPLModal;
      },
      set(val) {
        this.queryReplace({ showPLModal: val ? val : null });
      },
    },
    page: {
      get() {
        return parseInt(this.$route.query.plPage || 1);
      },
      set(val) {
        this.queryReplace({ plPage: val });
      },
    },
  },
  methods: {
    compareFieldFunc,
    iconFor,
    getBuilding(buildingRef) {
      if (buildingRef) {
        return getObjectInArrayById(
          this.$store.state.buildings,
          this.toId(buildingRef),
          null
        );
      }
    },
    getRoom(roomRef) {
      if (roomRef) {
        return getObjectInArrayById(
          this.$store.state.rooms,
          this.toId(roomRef),
          null
        );
      }
    },
    handleNewPipeLocation(pipeLocation) {
      this.$emit("update:rows", [...this.rows, pipeLocation]);
      this.$emit("input", pipeLocation);
      this.modalActive = false;
    },
  },
  watch: {
    page: {
      handler(pg) {
        this.$emit("page-change", pg);
      },
    },
  },
};
</script>

<style scoped></style>
