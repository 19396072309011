<template>
  <b-field
    label="Time zone"
    :message="{ 'Please select a valid time zone': !isValid }"
    :type="{ 'is-danger': !isValid }"
    :custom-class="!isValid ? 'has-text-danger' : null"
  >
    <div v-if="value">
      Selected: <b>{{ value.replaceAll("_", " ") }}</b
      >. Click <a @click="clear()">here</a> to select another.
    </div>
    <b-autocomplete
      v-else
      @select="onChange($event)"
      :disabled="disabled"
      :loading="loading"
      clearable
      v-model="name"
      :custom-formatter="
        (opt) => {
          return opt.replaceAll('_', ' ');
        }
      "
      :data="timezones"
    />
  </b-field>
</template>

<script>
import moment from "moment-timezone";
const allTimezones = moment.tz.names();

export default {
  name: "TimezonePicker",
  computed: {
    timezones() {
      return this.name
        ? allTimezones.filter((tz) =>
            tz
              .toLowerCase()
              .replaceAll("_", " ")
              .includes(this.name.toLowerCase())
          )
        : allTimezones;
    },
    isValid() {
      return this.value && allTimezones.includes(this.value);
    },
  },
  data() {
    return {
      name: this.value || "",
    };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
      this.$emit("update:value", val);
    },
    clear() {
      this.name = "";
      this.$emit("input", null);
      this.$emit("update:value", null);
    },
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.name = "";
        }
      },
    },
  },
};
</script>

<style scoped></style>
