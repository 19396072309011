<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Remove probe from sensor</div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small"/>
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step" has-navigation>
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <p class="is-size-5 has-text-centered">
              Select the area where the probe to remove is installed:
            </p>
            <b-field/>
            <building-radio
                :value="building"
                @input="handleBuildingSelection"
            />
          </div>
        </b-step-item>
        <b-step-item :step="1" label="Sensor" :type="stepType(1)">
          <transition name="slide">
            <div class="column is-offset-2-desktop is-8-desktop">
              <p class="is-size-5 has-text-centered">
                Select the sensor you'd like to remove the probe from:
              </p>
              <scan-or-select-device
                  :area="area"
                  ref="step1Scan"
                  device-state="device_installed"
                  :device-types="['sensor']"
                  label=""
                  method-query-key="sm1"
                  @input="handleSensorSelection"
                  :selected-serial="sensorSerial"
                  @update:loading="componentLoading = $event"
              />
            </div>
          </transition>
        </b-step-item>

        <b-step-item :step="2" label="Probe" :type="stepType(2)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <p class="is-size-5 has-text-centered">
              Select the probe to remove from sensor {{ sensorSerial }}:
            </p>
            <transition name="slide">
              <scan-or-select-device
                  ref="step2Scan"
                  :area="area"
                  :device-types="['probe']"
                  :device-paths="devicePaths"
                  device-state="device_installed"
                  label="Which probe would you like to remove"
                  :show-options="false"
                  :selected-serial="serial"
                  @input="handleProbeSelection"
                  @update:loading="componentLoading = $event"
              >
                <template #inner>
                  <b-table-column name="port" label="Port" v-slot="props">
                    {{ props.row.device_state.port }}
                  </b-table-column>
                </template>
                <template #empty>
                  No probes found attached to sensor {{ sensorSerial }}
                </template>
              </scan-or-select-device>
            </transition>
          </div>
        </b-step-item>

        <b-step-item :step="3" label="Confirm" :type="stepType(5)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps are have invalid data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            <div
                class="column is-6-desktop is-offset-3-desktop is-10-table is-offset-1-tablet"
            >
              <div class="box">
                <p class="subtitle has-text-weight-bold">
                  <b-icon
                      icon="check"
                      type="is-success"
                      v-if="!invalidSteps.length"
                  />
                  Remove probe from sensor
                </p>
                <level :values="valuesToShow"/>
                <b-message
                    class="mt-6"
                    type="is-info"
                    :closable="false"
                    title="Unplug probe from sensor"
                    has-icon
                    icon="info-circle"
                >
                  <p class="is-size-5">
                    Un-plug <b>probe {{ serial }}</b> from
                    <b>port {{ port }}</b> on <b>sensor {{ sensorSerial }}</b
                  >.
                  </p>
                  <p class="is-size-5">
                    Once this is complete, click the confirm button below.
                  </p>
                </b-message>
              </div>
            </div>
          </div>
        </b-step-item>

        <template #navigation>
          <div class="has-text-centered">
            <forward-backward
                classes="float-right"
                :allow-forward="validate(step)"
                :confirm-loading="confirmLoading"
                confirm-type="is-warning"
                :min-step="stepArray[0]"
                :max-step="stepArray[stepArray.length - 1]"
                :step="step"
                @forward="goForward"
                @backward="goBackward"
                @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";

import iconFor from "../../icons";
import {initial} from "lodash";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import {getObjectInArrayById} from "../../scripts/filterHelpers";
import Level from "../../components/Level/Level";


const stepArray = [0, 1, 2, 3];

/**
 * Device centric action component to
 */
export default {
  name: "RemoveProbe",
  components: {
    BuildingRadio,
    ForwardBackward,
    Level,
    ScanOrSelectDevice,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {object,null}, pipeLocationId: {string,null}}}
   */
  data() {
    return {
      componentLoading: false,
      deviceType: "probe",
      confirmLoading: false,
      // Inputs
      position: null,
      selectedSensor: null,
    };
  },
  mounted() {
    if (this.sensorSerial) {
      this.handleSensorSelection({serial: this.sensorSerial});
    }
    this.goToNextInvalidOrFinalStep();
  },
  computed: {
    valuesToShow() {
      return [
        {label: "Probe", value: this.serial},
        {label: "Sensor", value: this.sensorSerial},
      ];
    },
    port() {
      if (!(this.position && this.serial)) {
        return null;
      }
      if (this.position.port_0.serial === this.serial) {
        return 0;
      } else if (this.position.port_1.serial === this.serial) {
        return 1;
      } else if (this.position.port_2.serial === this.serial) {
        return 2;
      }
      return null;
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    building: {
      get() {
        let buildingId = this.$route.query.building;
        return buildingId
            ? getObjectInArrayById(this.$store.state.buildings, buildingId, null)
            : null;
      },
      set(val) {
        if (val instanceof Object) {
          val = val.id;
        }
        this.queryReplace({building: val});
      },
    },
    /**
     * @returns {string} Serial for selected sensor
     */
    sensorSerial: {
      get() {
        return this.$route.query.sensorSerial;
      },
      set(val) {
        setTimeout(() => this.queryReplace({sensorSerial: val}), 25);
      },
    },
    /**
     * @returns DocumentReference
     */
    pipeLocation() {
      if (!this.position || this.port === null) {
        return;
      }
      return this.position[`port_${this.port}`].pipe_location;
    },
    devicePaths() {
      const output = [];
      for (const i of ["port_1", "port_2"]) {
        if (this.position && this.position[i] && this.position[i].probe) {
          output.push(this.position[i].probe.path);
        }
      }
      return output;
    },
  },
  methods: {
    iconFor,
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return !!this.sensorSerial && !!this.selectedSensor;
      } else if (step === 2) {
        return !!this.serial && !!this.port && !!this.selectedDevice;
      } else if (step === 3) {
        return (
            initial(stepArray).filter((_step) => !this.validate(_step)).length ===
            0
        );
      }
    },
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && this.validate(0)) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} device
     */
    handleProbeSelection(device) {
      if (device && !this.devicePaths.includes(device._reference.path)) {
        this.$handleError(
            `Probe ${device.serial} is not currently assigned ${
                this.selectedSensor ? "sensor " + this.sensorSerial : ""
            }`
        );
      }
      this.selectedDevice = device;
      this.serial = device ? device.serial : null;
      setTimeout(() => {
        if (this.step === 2 && this.validate(2)) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} sensor
     */
    handleSensorSelection(sensor) {
      this.sensorSerial = sensor ? sensor.serial : null;
      this.selectedSensor = sensor;
      setTimeout(() => {
        if (this.step === 1 && this.validate(1)) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * Finalize installation
     */
    onComplete() {
      const self = this;
      if (this.invalidSteps.length) {
        this.$handleError(
            "Please correct any steps with missing data or errors",
            "Please correct any steps with missing data or errors"
        );
        return;
      }
      this.confirmLoading = true;
      this.position
          .performAction("unassign_probe_port", {
            port: self.port,
            probe: self.selectedDevice
                ? self.toFSRef(self.selectedDevice, "probes")
                : null,
            pipe_location: self.pipeLocation,
          })
          .then(() => self.reloadArea())
          .then(() => {
            self.$store.dispatch('get', {name: 'pipe_locations'});
            return Promise.resolve();
          })
          .then(() => {
            self.$handleSuccess(`Probe ${self.serial} has been removed`);
            if (self.$route.query.next) {
              self.$router.push(self.$route.query.next)
            } else {
              self.$router.push({
                name: "devices:sensor-detail",
                params: {serial: self.sensorSerial},
              });
            }
          })
          .catch((e) =>
              self.$handleError(
                  "Could not complete probe removal; see console for details",
                  e
              )
          )
          .finally(() => (self.confirmLoading = false));
    },
  },
  watch: {
    selectedSensor: {
      immediate: true,
      handler(sensor) {
        if (sensor) {
          this.loading = true;
          this.$dataClasses.Position.queryForSingle([["serial", "==", sensor.serial]])
              .then((obj) => {
                this.position = obj;
                this.positionId = obj.id;
              })
              .catch(
                  (e) =>
                      this.handleSensorSelection(null) &&
                      this.$handleError(
                          `Sensor ${sensor.serial} is not currently installed`,
                          e
                      )
              )
              .finally(() => (this.loading = false));
        } else {
          this.position = null;
          this.positionId = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}
</style>
