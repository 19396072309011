<template>
  <div>
    <b-icon icon="spinner" class="fa-spin" v-if="schemaLoading || loading"/>
    <div v-else-if="schemas && schemas.length">
      <b-field label="Report" required>
        <b-select
            :value="selectedSchemaObj"
            v-if="get(selectedSchemaObj, 'schema.properties.for_object_path', null)"
            @input="selectedSchemaObj=$event;"
        >
          <option :value="null">
            <i
            >Please select a <b>{{ category }}</b> report</i
            >
          </option>
          <option
              v-for="schemaObj in schemas"
              :key="schemaObj.report_name"
              :value="schemaObj"
          >
            {{ schemaObj.title }}
          </option>
        </b-select>
      </b-field>
      <div class="report-form box" v-if="selectedSchemaObj">
        <aq-json-schema-form
            v-if="created && !schemaLoading"
            :fields-to-hide="fieldsToHide"
            :loading="schemaLoading"
            :schema="selectedSchemaObj.schema"
            :scope="scope"
            @update:scope="$nextTick(() => {scope = $event})"
            :value="formValue"
            @input="formValue=$event"
            :value.sync="formValue"
            @submit="submit($event)"
        >
          <template slot="more-actions">
            <b-button
                v-if="$store.state.user_roles.is_root"
                icon-left="sync"
                :disabled="schemaLoading"
                :class="{ 'fa-spin': schemaLoading }"
                @click="fetchSchemas()"
            >
              Refresh schema
            </b-button>
            <slot name="post-buttons"/>
          </template>
        </aq-json-schema-form>
      </div>
    </div>
    <div v-else>
      No <b>{{ category }}</b> reports are available
    </div>
  </div>
</template>

<script>
import AqJsonSchemaForm from "../../components/forms/AqJsonSchemaForm";

import {get, isEqual} from "lodash";

const fetchReportSchemas = (category, apiClient) => {
  let headers = {},
      url = `${process.env.VUE_APP_AQUACLOUD_URL}/reporting/report-schemas?category=${category}`;
  headers[apiClient.authHeaderKey] = apiClient.authHeaderValue;
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers,
    })
        .then((res) => {
          if (!res.ok) {
            throw Error(res.statusText);
          }
          return res.json();
        })
        .then((json) => {
          return resolve(json.schemas);
        })
        .catch((e) => reject(e));
  });
};

export default {
  name: "ReportForm",
  components: {
    AqJsonSchemaForm,
  },
  props: {
    category: {
      type: String,
      required: false,
      default: "",
    },
    fieldsToHide: {
      type: Array,
      default: () => [],
    },
    initial: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reportName: {
      type: String,
      default: null,
    }
  },
  mounted() {
    this.queryReplace({form_value: JSON.stringify(this.initial)})
  },
  computed: {
    formValue: {
      get() {
        return JSON.parse(this.$route.query.form_value || "{}")
      },
      set(formValueObj) {
        formValueObj ?
            this.queryReplace({
              form_value: JSON.stringify(formValueObj || {})
            }) :
            this.queryRemoveKeys(['form_value'])
      },
    },
    scope: {
      get() {
        return this.$route.query.scope
      },
      set(scope) {
        return scope ? this.queryReplace({scope}) : this.queryRemoveKeys(['scope'])
      }
    },
    selectedSchemaObj: {
      get() {
        let reportName = this.reportName || this.$route.query.report,
            matches = this.schemas.filter(
                (sch) => sch.report_name === reportName
            );
        return (matches && matches.length) ? matches[0] : null;
      },
      set(val) {
        if (val && val.report_name) {
          this.$nextTick(() => {
            this.$emit('update:report-name', val.report_name);
            this.queryReplace({report: val.report_name});
          });
        } else {
          this.$emit('update:report-name', null);
          this.queryRemoveKeys(["report"]);
        }
      },
    },
  },
  methods: {
    get,
    submit(values) {
      this.$emit("submit", {
        values,
        selectedSchemaObj: this.selectedSchemaObj,
      });
      this.formValue = values;
    },
    fetchSchemas() {
      let self = this;
      self.schemaLoading = true;
      fetchReportSchemas(self.category, self.$client)
          .then((results) => {
            self.schemas = results;
            if (
                !self.selectedSchemaObj ||
                !self.schemas
                    .map((s) => s.report_name)
                    .includes(self.selectedSchemaObj.report_name)
            ) {
              self.selectedSchemaObj = self.schemas[0];
            }
          })
          .catch((e) => self.$handleError("Could not load report schemas", e))
          .finally(() => (self.schemaLoading = false));
    },
  },
  created() {
    this.fetchSchemas();
    this.created = true;
  },
  data() {
    return {
      created: false,
      schemaLoading: false,
      schemas: [],
    };
  },
  watch: {
    selectedSchemaObj: {
      handler(selectedSchema, prev) {
        if (isEqual(selectedSchema, prev)) {
          return
        }
        if (selectedSchema && selectedSchema.collections && !selectedSchema.collections.includes(this.scope)) {
          this.$emit('update:scope', selectedSchema.collections)
        } else if (this.formValue && this.formValue.for_object_path) {
          this.$emit('update:scope', this.formValue.for_object_path.split('/')[0])
        }
        // this.log({afterVals: this.formValue})
      }
    },
    category: {
      handler: function () {
        this.selectedSchemaObj = null;
        this.fetchSchemas();
      },
    },
  },
};
</script>

<style scoped></style>
