<template>
  <div class="card mb-3">
    <p v-if="!schedule">No schedule</p>
    <div class="card-header" v-if="schedule">
      <div class="card-header-title">
        <b-field
          :type="!schedule.name ? 'is-danger' : 'is-default'"
          :message="{ 'Please enter a name': !schedule.name }"
          expanded
        >
          <b-input
            expanded
            ref="name"
            :disabled="loading"
            v-model="schedule.name"
            placeholder="Name this schedule"
          />
        </b-field>
      </div>
      <div class="card-header-icon">
        <b-button
          @click="deleteSchedule(schedule)"
          class="is-pulled-right"
          type="is-danger"
          :icon-left="iconFor('delete')"
          :disabled="loading"
          :loading="deleting"
        />
      </div>
    </div>
    <div class="card-content" v-if="schedule">
      <b-field
        label="Active"
        :type="{ 'is-danger': !schedule.is_active }"
        :message="{
          'If not active, this will not trigger any alerts':
            !schedule.is_active,
        }"
      >
        <b-switch v-model="schedule.is_active" />
      </b-field>
      <b-field
        label="Alarm type"
        :message="{
          'Please select an alarm type': !Object.keys(alarmTypes).includes(
            schedule.alarm_type
          ),
        }"
        :type="{
          'is-danger': !Object.keys(alarmTypes).includes(schedule.alarm_type),
        }"
      >
        <p v-if="schedule.id">{{ alarmTypes[schedule.alarm_type] }}</p>
        <b-radio
          v-else
          :disabled="schedule.id || loading"
          v-for="(readableType, type) in alarmTypes"
          :native-value="type"
          :key="type"
          v-model="schedule.alarm_type"
          @input="$event ? assignTopic(schedule.alarm_type) : null"
        >
          {{ readableType }}
        </b-radio>
      </b-field>
      <b-field label="Scope" :disabled="loading">
        <b-radio
          v-model="scopeType"
          native-value="building"
          :disabled="loading"
        >
          An area
        </b-radio>
        <b-radio
          v-model="scopeType"
          native-value="sources"
          :disabled="loading || schedule.alarm_type === 'unrecognized'"
        >
          Selected points-of-use only
        </b-radio>
        <b-radio
          v-model="scopeType"
          native-value="pipe_locations"
          :disabled="loading || schedule.alarm_type !== 'temperature'"
        >
          Selected pipe locations
        </b-radio>
      </b-field>
      <div class="ml-6">
        <b-field
          v-if="['pipe_locations', 'sources'].includes(scopeType)"
          :label="`${
            scopeType === 'sources' ? 'Points-of-use' : 'Pipe locations'
          } to include`"
          :type="{
            'is-danger': !schedule[scopeType] || !schedule[scopeType].length,
          }"
          :message="
            !schedule[scopeType] || !schedule[scopeType].length
              ? 'You must select at least one ' +
                scopeType.slice(0, -1).replaceAll('_', ' ')
              : null
          "
        >
          <select-hierarchy
            v-if="scopeType === 'sources' && showHier"
            :options="hierOptions"
            :array-name="'sources'"
            :site-ids="[site.id]"
            :value="sourceIds"
            @change="
              $set(schedule, 'reference_path', null);
              $set(schedule, scopeType, $event);
            "
            :return-transform-function="normalizer"
            :disabled="loading"
          />
          <select-hierarchy
            v-else-if="showHier"
            :options="hierOptions"
            :array-name="'pipe_locations'"
            :site-ids="[site.id]"
            :value="pipeLocationIds"
            @change="
              $set(schedule, 'reference_path', null);
              $set(schedule, scopeType, $event);
            "
            :return-transform-function="normalizer"
            :disabled="loading"
          />
        </b-field>
        <b-field
          v-else
          label="Area"
          :type="{ 'is-danger': !selectedBuildingId }"
          :message="{ 'Please select an area': !selectedBuildingId }"
        >
          <b-radio
            :disabled="loading"
            v-for="building in buildings"
            v-model="selectedBuildingId"
            :key="building.id"
            :native-value="building.id"
          >
            <building-display
              :value="building"
              :show-training-mode-tooltip="false"
              show-training-mode-warning
            >
              <template #post>
                <b-tooltip
                  type="is-danger"
                  v-if="!building.area"
                  :label="
                    'This building (id:' +
                    building.id +
                    ') does not have an area assigned.'
                  "
                >
                  <b-icon icon="exclamation" type="is-danger" />
                </b-tooltip>
              </template>
            </building-display>
          </b-radio>
        </b-field>
      </div>
      <transition name="slide">
        <b-field
          v-if="schedule.alarm_type === 'noflow'"
          label="Aggregate by room"
          message="If active, will show information at the room level, not at POU level"
        >
          <b-checkbox v-model="schedule.room_aggregation" />
        </b-field>
      </transition>
      <b-field
        label="Active hours"
        class="mt-3"
        :type="{
          'is-danger':
            !schedule.schedule ||
            !schedule.schedule.times ||
            !schedule.schedule.times.length,
        }"
        :message="{
          'Please specify the times for this alarm':
            !schedule.schedule.times || !schedule.schedule.times.length,
        }"
      >
        <div class="week-range-outer">
          <vue-week-time-range-picker
            has-half-hour
            :value="DOWFormatToTimerange(schedule.schedule.times)"
            @input="
              set(schedule, 'schedule.times', timerangeToDOWFormat($event))
            "
            @select-all="schedule.schedule.description = 'Always'"
          />
        </div>
      </b-field>
      <b-field label="Description of alarm period (i.e. 'After-hours')">
        <b-input v-model="schedule.schedule.description" :disabled="loading" />
      </b-field>
      <b-field
        label="Do you want this alarm schedule to trigger SMS (text) messages?"
        :type="{ 'is-danger': !alertTypes.includes(schedule.alert_type) }"
        :message="{
          'Please select an alert type': !alertTypes.includes(
            schedule.alert_type
          ),
          'Info-level alarms will trigger notifications but not send text messages':
            schedule.alert_type === 'info',
          'Warning-level alarms will trigger notifications and text messages':
            schedule.alert_type === 'warning',
        }"
      >
        <b-radio
          v-for="type in alertTypes"
          :type="'is-' + (type === 'info' ? 'info' : 'danger')"
          :native-value="type"
          :key="type"
          v-model="schedule.alert_type"
          :disabled="loading"
        >
          {{ type === "info" ? "No" : "Yes" }}
          (<span :class="'has-text-' + (type === 'info' ? 'info' : 'danger')"
            >{{ type }}
          </span>
          level)
        </b-radio>
      </b-field>

      <transition name="slide">
        <!-- AGGREGATED FLOW FILTER -->
        <b-message
          v-if="schedule.alarm_type === 'agg_filter_30min'"
          title="Aggregated flow threshold settings"
          :closable="false"
          class="mt-4"
        >
          <b-field group-multiline>
            <div class="control">
              <b-field
                label="Alert when flow detected for more than"
                :type="{ 'is-danger': !schedule.criteria.minimum }"
                :message="{
                  'Please the amount of aggregated flow that will trigger an alarm':
                    !schedule.criteria.minimum,
                }"
              >
                <time-picker
                  :max-minutes="59"
                  reset-value="00:30"
                  :disabled="loading"
                  :value="
                    fromSecondsToTimePickerString(schedule.criteria.minimum)
                  "
                  @input="
                    set(
                      schedule,
                      'criteria.minimum',
                      fromTimePickerStringToSeconds($event)
                    )
                  "
                />
              </b-field>
            </div>
            <div class="control">
              <b-field
                label="over a period of"
                :type="{ 'is-danger': !schedule.criteria.minimum }"
                :message="{
                  'Please specify  time period to monitor for aggregated flow':
                    !schedule.criteria.minimum,
                }"
              >
                <time-picker
                  :show-minutes="false"
                  reset-value="03:00"
                  :disabled="loading"
                  :value="
                    fromPDStringToTimePickerString(schedule.criteria.agg_window)
                  "
                  @input="
                    set(
                      schedule,
                      'criteria.agg_window',
                      fromTimePickerStringToPDString($event)
                    )
                  "
                />
              </b-field>
            </div>
          </b-field>
        </b-message>

        <!-- NO-FLOW FILTER -->
        <b-message
          v-if="schedule.alarm_type === 'noflow'"
          title="Low-flow/Stagnation settings"
          :closable="false"
          class="mt-4"
        >
          <b-field
            label="Alert if flow does not occur for at least"
            :type="{ 'is-danger': !schedule.criteria.maximum }"
            :message="{
              'Please specify flow required to prevent alerts':
                !schedule.criteria.maximum,
            }"
          >
            <time-picker
              :max-minutes="59"
              reset-value="00:30"
              :disabled="loading"
              :value="fromSecondsToTimePickerString(schedule.criteria.maximum)"
              @input="
                set(
                  schedule,
                  'criteria.maximum',
                  fromTimePickerStringToSeconds($event)
                )
              "
            />
          </b-field>
          <b-field
            label="over a period of"
            :type="{ 'is-danger': !schedule.criteria.agg_window }"
            :message="{
              'Please specify the time period to monitor for lack of flow':
                !schedule.criteria.agg_window,
            }"
          >
            <time-picker
              :allowed-hours="aggWindowNoFlowChoices"
              :show-minutes="false"
              reset-value="72:00"
              :disabled="loading"
              :value="
                fromPDStringToTimePickerString(schedule.criteria.agg_window)
              "
              @input="
                set(
                  schedule,
                  'criteria.agg_window',
                  fromTimePickerStringToPDString($event)
                )
              "
            />
          </b-field>
        </b-message>

        <!-- TEMPERATURE -->
        <b-message
          v-if="schedule.alarm_type === 'temperature'"
          title="Temperature threshold settings"
          :closable="false"
          class="mt-4"
        >
          <b-field label="Generate an alarm" horizontal>
            <div style="display: none">.</div>
          </b-field>
          <b-field
            label="when the"
            horizontal
            :type="{ 'is-danger': !schedule.criteria.aggregation_method }"
            :message="{
              'Please select the calculated temperature value for this alarm schedule':
                !schedule.criteria.aggregation_method,
            }"
          >
            <b-select v-model="schedule.criteria.aggregation_method">
              <option
                v-for="(name, value) in aggregation_methods"
                :id="value"
                :key="value"
                :value="value"
              >
                {{ name }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="temperature is"
            horizontal
            :type="{ 'is-danger': !schedule.criteria.operator }"
            :message="{
              'Select a comparison method': !schedule.criteria.operator,
            }"
          >
            <b-select v-model="schedule.criteria.operator">
              <option
                v-for="(name, value) in criteriaOperators"
                :key="value"
                :value="value"
                :id="value"
              >
                {{ name }}
              </option>
            </b-select>
          </b-field>
          <b-field
            label="a value of "
            horizontal
            :type="{ 'is-danger': !schedule.criteria.limit }"
          >
            <unit-conversion-input
              ref="tempInput"
              display-prefix="°"
              measurement="temperature"
              convert-to-unit="C"
              unit="C"
              :initial-value="schedule.criteria.limit"
              :decimals="2"
              @input="schedule.criteria.limit = $event"
            />
          </b-field>
          <b-field
            label="aggregated over "
            horizontal
            :type="{
              'is-danger': !(
                schedule &&
                schedule.criteria &&
                schedule.criteria.agg_window
              ),
            }"
          >
            <b-select v-model="schedule.criteria.agg_window">
              <option
                v-for="an_agg_window in temperature_agg_windows"
                :key="an_agg_window"
                :value="an_agg_window"
              >
                {{ an_agg_window }}
              </option>
            </b-select>
          </b-field>
          <p
            v-if="
              schedule.criteria.limit &&
              schedule.criteria.aggregation_method &&
              schedule.criteria.agg_window &&
              $refs.tempInput
            "
          >
            An alarm will be generated when the
            <b>
              {{
                aggregation_methods[
                  schedule.criteria.aggregation_method
                ].toLowerCase()
              }}
            </b>
            temperature is <b> {{ schedule.criteria.operator }}</b>
            <b>{{ schedule.criteria.limit.toFixed(1) }}°C</b>
            <span
              v-if="
                $refs.tempInput._data._unit &&
                $refs.tempInput._data._unit !== 'C'
              "
            >
              (<b>{{ $refs.tempInput._data._value }}</b>
              <b>°{{ $refs.tempInput._data._unit }}</b
              >)
            </span>
            over the last <b>{{ schedule.criteria.agg_window }}</b>
          </p>
        </b-message>

        <!-- USER FILTER -->
        <b-message
          v-if="
            schedule.alarm_type === 'user_filter' ||
            schedule.alarm_type === 'unrecognized'
          "
          title="Flow threshold settings"
          :closable="false"
          class="mt-4"
        >
          <b-field
            label="Minimum flow length before alert"
            :type="{ 'is-danger': !schedule.criteria.minimum }"
            :message="{
              'Please specify how long a continuous flow should occur before triggering alerts':
                !schedule.criteria.minimum,
            }"
          >
            <time-picker
              :disabled="loading"
              :value="fromSecondsToTimePickerString(schedule.criteria.minimum)"
              @input="
                set(
                  schedule,
                  'criteria.minimum',
                  fromTimePickerStringToSeconds($event)
                )
              "
              :reset-value="schedule.alert_type === 'info' ? '00:02' : '00:05'"
            />
          </b-field>
        </b-message>
      </transition>

      <FeatureFlagWrapper flag="developer_mode" show-tooltip>
        <b-field
          label="Webhook URL"
          :type="{ 'is-danger': !isValidWebhook }"
          :message="{
            'Please enter a valid url': !isValidWebhook,
            'Webhook (optional) will be sent a request via POST method with alarm data as payload when an alarm is triggered':
              isValidWebhook,
          }"
        >
          <b-input v-model="schedule.webhook_url" />
        </b-field>
      </FeatureFlagWrapper>
    </div>
    <div class="card-footer" v-if="schedule">
      <div class="card-footer-item">
        <b-button
          :disabled="!isValid"
          :loading="loading"
          type="is-success"
          class="is-pulled-right"
          @click="submit"
        >
          {{ schedule.id ? "Update schedule" : "Create schedule" }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureFlagWrapper from "../Flags/FeatureFlagWrapper";
import hierarchyToTree from "../../components/Selects/functions/hierarchyToTree";
import vueWeekTimeRangePicker from "../../external/vue-week-time-range-picker/src";
import {
  firestoreToWidgetScheduleFormat,
  widgetToFirestoreScheduleFormat,
} from "../../external/vue-week-time-range-picker/src/util/toDOWFormat";
import { flatMap, set } from "lodash";
import SelectHierarchy from "../../components/Selects/SelectHierarchy";
import iconFor from "../../icons";
import TimePicker from "../../components/Selects/TimePicker";

import { getObjectInArrayById } from "../../scripts/filterHelpers";
import store from "../../store";
import toId from "../../appLevelFunctions/toId";

import AlarmSchedule from "../../dataClasses/AlarmSchedule";
import UnitConversionInput from "../Inputs/UnitConversionInput";

const criteriaOperators = {
  ">": "greater than",
  "<": "less than",
};

/**
 *
 * @param schedule
 * @returns {{schedule: ({reference_path}|*), deleting: boolean, selectedBuildingId: (*|null), scopeType: (string), topics: {flow: string, noflow: string}, alertTypes: string[], aggWindowNoFlowChoices: number[], alarmTypes: {agg_filter_30min: string, user_filter: string, noflow: string}, loading: boolean}}
 */
function getData(schedule) {
  let area = schedule.reference_path
      ? getObjectInArrayById(
          store.state.areas,
          toId(schedule.reference_path),
          null
        )
      : null,
    building = area ? area.$building : null;
  return {
    aggWindowNoFlowChoices: [
      12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168,
    ],
    criteriaOperators,
    topics: {
      flow: "Flow alerts",
      // health: "Health alerts",
      noflow: "No-flow alerts",
    },
    alarmTypes: AlarmSchedule.alarmTypeToReadable,
    aggregation_methods: AlarmSchedule.aggregationMethodsToReadable,
    temperature_agg_windows: [
      "1h",
      "2h",
      "3h",
      "4h",
      "6h",
      "8h",
      "12h",
      "24h",
      "48h",
    ],
    alertTypes: ["info", "warning"],
    deleting: false,
    loading: false,
    room_aggregation: false,
    schedule: schedule,
    selectedBuildingId: building ? building.id : null,
    scopeType: !(
      schedule.reference_path ||
      schedule.sources ||
      schedule.pipe_locations
    )
      ? "building"
      : schedule.pipe_locations && schedule.pipe_locations.length
      ? "pipe_locations"
      : schedule.sources && schedule.sources.length
      ? "sources"
      : "building",
    showHier: true,
  };
}

export default {
  name: "MessageSettingsIndivRecordRaw",
  components: {
    FeatureFlagWrapper,
    SelectHierarchy,
    TimePicker,
    UnitConversionInput,
    vueWeekTimeRangePicker,
  },
  /**
   *
   * @returns {{schedule: ({reference_path}|*), deleting: boolean, selectedBuildingId: (*|null), scopeType: string, topics: {flow: string, noflow: string}, alertTypes: string[], aggWindowNoFlowChoices: number[], alarmTypes: {agg_filter_30min: string, user_filter: string, noflow: string}, loading: boolean}}
   */
  data() {
    return getData(this.value.clone());
  },
  props: {
    value: {
      type: Object,
      required: false,
      validator: (val) => val instanceof AlarmSchedule,
      default: () => {
        return new AlarmSchedule({
          name: "",
          sources: [],
          continuous: {
            info: { active: false },
            warning: { active: false },
          },
          aggregated: {
            warning: { active: false },
          },
          noflow: {
            warning: { active: false },
          },
        });
      },
    },
    site: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hierOptions() {
      let arrayToFilter = [];
      if (this.scopeType === "pipe_locations") {
        arrayToFilter = this.$store.state.pipe_locations.filter(
          (pl) => pl && pl.probe
        );
      } else if (this.scopeType === "sources") {
        arrayToFilter = this.$store.state.sources.filter(
          (src) => src && src.name && !src.name.startsWith("SRC:")
        );
      }

      return hierarchyToTree({
        _sites: this.$store.state.sites,
        _buildings: this.$store.state.buildings,
        arrayToFilter,
      });
    },
    isValidWebhook() {
      let webhook_url = this.schedule.webhook_url;
      if (!webhook_url) {
        return true;
      } else {
        try {
          new URL(webhook_url);
        } catch (e) {
          return false;
        }
        return true;
      }
    },
    buildings() {
      return this.$store.state.buildings
        .filter((b) => b.site.id === this.site.id)
    },
    isValid() {
      return (
        this.schedule.name &&
        Object.keys(this.alarmTypes).includes(this.schedule.alarm_type) &&
        this.alertTypes.includes(this.schedule.alert_type) &&
        this.schedule &&
        this.schedule.schedule &&
        this.schedule.schedule.times &&
        this.schedule.schedule.times.length > 1 &&
        this.isValidWebhook &&
        ((this.scopeType === "sources" && this.sourceIds.length > 0) ||
          (this.scopeType === "pipe_locations" &&
            this.pipeLocationIds.length > 0) ||
          (this.scopeType === "building" && this.selectedBuildingId)) &&
        this.criteriaMet(this.schedule.alarm_type)
      );
    },
    sourceIds() {
      return this.scopeType === "sources" && this.schedule.sources
        ? this.schedule.sources.map((src) => {
            return { id: this.toId(src) };
          })
        : [];
    },
    pipeLocationIds() {
      return this.scopeType === "pipe_locations" && this.schedule.pipe_locations
        ? this.schedule.pipe_locations.map((pl) => {
            return { id: this.toId(pl) };
          })
        : [];
    },
  },
  methods: {
    iconFor,
    hierarchyToTree,
    set,
    timerangeToDOWFormat: widgetToFirestoreScheduleFormat,
    DOWFormatToTimerange: firestoreToWidgetScheduleFormat,
    criteriaMet(type) {
      if (type === "user_filter") {
        return this.schedule.criteria.minimum > 0;
      } else if (type === "agg_filter_30min") {
        return (
          this.schedule.criteria.minimum > 0 &&
          this.schedule.criteria.agg_window
        );
      } else if (type === "noflow") {
        return (
          this.schedule.criteria.maximum > 0 &&
          this.schedule.criteria.agg_window
        );
      } else if (type === "temperature") {
        return (
          typeof this.schedule.criteria.limit !== "undefined" &&
          this.schedule.criteria.limit !== null &&
          this.schedule.criteria.operator &&
          this.schedule.criteria.aggregation_method &&
          this.schedule.criteria.agg_window
        );
      } else {
        return true;
      }
    },
    reset() {
      this.schedule = this.schedule.clone();
      this.$emit("input", this.schedule);
      this.$emit("update:value", this.schedule);
    },
    fromSecondsToTimePickerString(seconds) {
      let totalMinutes = seconds / 60,
        hours = Math.floor(totalMinutes / 60),
        minutes = Math.floor(totalMinutes % 60),
        padding = hours < 100 ? 2 : 3;
      return `${hours.toString().padStart(padding, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
    fromTimePickerStringToSeconds(tpString) {
      let hours = parseInt(tpString.split(":")[0]),
        minutes = parseInt(tpString.split(":")[1]);
      return hours * 60 * 60 + minutes * 60;
    },
    fromTimePickerStringToPDString(tpString) {
      let hours = parseInt(tpString.split(":")[0]);
      return hours ? `${hours}h` : "";
    },
    fromPDStringToTimePickerString(pdString) {
      let hours = pdString ? parseInt(pdString.split("h")[0]) : 0;
      let padding = hours < 100 ? 2 : 3;
      return `${hours.toString().padStart(padding, "0")}:00`;
    },
    fromPDStringToSeconds(pdString) {
      let hours = pdString ? parseInt(pdString.split("h")[0]) : 0;
      return hours * 60 * 60;
    },
    deleteSchedule(schedule) {
      let nameStr = schedule.name
        ? `<div>&nbsp;</div><p>Name: <b>${schedule.name}</b></p>`
        : "",
        self = this;
      self.$buefy.dialog.confirm({
        message: `Are you sure you want to ${
          schedule.id ? "delete" : "remove"
        } this alarm schedule? ${nameStr}<p>Site: <b>${self.site.name}</b></p>`,
        title: "Confirm deletion",
        type: "is-danger",
        onConfirm: () => {
          if (!schedule.id) {
            // does not exist
            self.$emit("delete", schedule);
          } else {
            self.loading = true;
            self.deleting = true;
            schedule
              .delete()
              .then(() => {
                self.$handleSuccess("Alarm schedule has been removed");
                self.$emit("delete", schedule);
              })
              .catch((e) => self.$handleError(e, e))
              .finally(() => {
                self.loading = false;
                self.deleting = false;
              });
          }
        },
      });
    },
    submit() {
      let self = this;
      self.loading = true;
      if (self.scopeType === "sources" || self.scopeType === "pipe_locations") {
        self.schedule.reference_path = null;
        self.schedule.for = null;
        self.schedule.for_object = null;
        if (Object.prototype.hasOwnProperty.call(self.schedule, "building")) {
          self.schedule.building = null;
        }
        if (
          Object.prototype.hasOwnProperty.call(self.schedule, "reference_path")
        ) {
          self.schedule.reference_path = null;
        }
        if (self.scopeType === "sources" && self.schedule.pipe_locations) {
          delete self.schedule.pipe_locations;
        } else if (
          self.scopeType === "pipe_locations" &&
          self.schedule.sources
        ) {
          delete self.schedule.sources;
        }
      } else if (self.scopeType === "building") {
        delete self.schedule.sources;
        delete self.schedule.pipe_locations;
        let building = getObjectInArrayById(
          self.$store.state.buildings,
          self.selectedBuildingId,
          null
        );
        if (!building) {
          self.$handleError(
            "Could not find building with ID " + self.selectedBuildingId
          );
          self.loading = false;
          return;
        }
        self.schedule.building = self.toFSRef(building);
        self.schedule.sources = null;
        self.schedule.pipe_locations = null;
        self.schedule.reference_path = building.area;
      } else {
        throw "Unknown scopeType: " + this.scopeType;
      }
      self.schedule.site = self.toFSRef(self.site);

      let criteria = [];
      if (self.schedule.criteria.minimum) {
        criteria.push({
          criteria: [
            "cumulated_flow_duration",
            ">",
            self.schedule.criteria.minimum,
          ],
        });
      }
      if (self.schedule.criteria.maximum) {
        criteria.push({
          criteria: [
            "cumulated_flow_duration",
            "<",
            self.schedule.criteria.maximum,
          ],
        });
      }
      self.schedule
        .set("criteria.criteria_tuples", criteria)
        .save()
        .then((result) => {
          let action = self.schedule.id ? "update" : "create";
          self.schedule = result;
          self.$emit(action, self.schedule);
          self.$emit("input", self.schedule);
          self.$emit("update:value", self.schedule);
          self.$handleSuccess(
            `Alarm schedule '${self.schedule.name}' has been saved`
          );
        })
        .catch((e) => self.$handleError("Could not save alarm schedule", e))
        .finally(() => {
          self.loading = false;
        });
    },
    normalizer(valArray) {
      return flatMap(valArray, (val) => {
        if (val.isA === "source") {
          return this.toFSRef(val.id, "sources");
        } else if (val.isA === "pipe_location") {
          return this.toFSRef(val.id, "pipe_locations");
        } else {
          return this.normalizer(val.children);
        }
      });
    },
    assignTopic(alarmType) {
      if (alarmType === "noflow") {
        this.schedule.topic = "noflow";
        this.schedule.schedule.times = ["0", "1", "2", "3", "4", "5", "6"];
        if (!this.schedule.criteria.maximum) {
          this.schedule.criteria.maximum = 60 * 30; // half hour
        }
        if (
          !this.schedule.criteria.agg_window ||
          !this.aggWindowNoFlowChoices.includes(
            this.schedule.criteria.agg_window
          )
        ) {
          this.schedule.criteria.agg_window = "72h";
        }
      } else if (alarmType === "agg_filter_30min") {
        this.schedule.topic = "flow";
        if (!this.schedule.criteria.minimum) {
          this.schedule.criteria.minimum = 60 * 30; // half hour
        }
        if (
          !this.schedule.criteria.agg_window ||
          this.fromPDStringToSeconds(this.schedule.criteria.agg_window) >
            60 * 60 * 10
        ) {
          this.schedule.criteria.agg_window = "3h";
        }
      } else {
        this.schedule.topic = "flow";
        if (!this.schedule.criteria.minimum) {
          this.schedule.criteria.minimum = 60 * 5;
        }
      }
      this.schedule = this.schedule.clone();
    },
  },
  watch: {
    scopeType: {
      handler(val, oldVal) {
        this.showHier = false;
        setTimeout(() => {
          this.showHier = true;
        }, 100);
      },
    },
    "schedule.alarm_type": {
      handler(val, oldVal) {
        if (oldVal === "noflow" && val !== "noflow") {
          // unset room_agg
          this.schedule.room_aggregation = false;
        }
        if (val === "unrecognized") {
          this.scopeType = "building";
        }
        if (
          (val === "noflow" || val === "unrecognized") &&
          this.schedule.schedule.times !== ["0", "1", "2", "3", "4", "5", "6"]
        ) {
          this.schedule.schedule.times = ["0", "1", "2", "3", "4", "5", "6"];
        }
      },
    },
  },
};
</script>

<style scoped>
.week-range-outer {
  overflow-x: scroll;
  padding-bottom: -15px;
}

.field.has-addons,
:deep(.field.has-addons) {
  display: block !important;
}
</style>
