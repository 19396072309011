<template>
  <div
    :title="tooltip"
    :class="{ 'signal-indicator': true, warning: level <= 0 }"
  >
    <div :class="'wv1 wave ' + verboseStrength">
      <div :class="'wv1 wv2 wave ' + verboseStrength">
        <div :class="'wv1 wv2 wv3 wave ' + verboseStrength">
          <div :class="'wv1 wv2 wv3 wv4 wave ' + verboseStrength"></div>
        </div>
      </div>
    </div>
    <div class="sensor-exclaim" v-if="level <= 0">❗</div>
  </div>
</template>

<script>
export default {
  name: "signal-indicator",
  data() {
    return {};
  },
  computed: {
    level() {
      return this.strength;
    },
    verboseStrength() {
      if (this.level >= 4) {
        return "high";
      } else if (this.level >= 3) {
        return "medium-high";
      } else if (this.level >= 2) {
        return "medium";
      } else if (this.level >= 1) {
        return "low";
      } else {
        return "no-signal";
      }
    },
    tooltip() {
      return (
        "Signal level: " +
        this.verboseStrength.charAt(0).toUpperCase() +
        this.verboseStrength.slice(1).replace("-", " ")
      );
    },
  },
  props: {
    strength: {
      type: Number,
      required: true,
    },
  },
  watch: {
    level: {
      handler(val) {
        this.$emit("stength-change", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
/* CSS used here will be applied after bootstrap.css */
.signal-indicator {
  position: relative;
  height: 40px;
}
.sensor-exclaim {
  color: red;
  position: absolute;
  width: 100%;
  font-size: 20px;
  top: 16px;
  left: 10px;
}
.wave {
  display: inline-block;
  border: 6px solid transparent;
  border-top-color: #eee;
  border-radius: 50%;
  margin: 3px;
}

.wave {
  border-top-color: #eee;
}

.high.wv1 {
  border-top-color: #30b455;
  color: #30b455;
}

.medium-high.wv2 {
  color: #85c92c;
  border-top-color: #85c92c;
}

.medium.wv3 {
  color: #ffd452;
  border-top-color: #ffd452;
}

.low.wv4 {
  color: #e81309;
  border-top-color: #e81309;
}

@keyframes signalFlash {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-signal {
  color: grey;
  border-top-color: grey;
  animation-name: signalFlash;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}
</style>
