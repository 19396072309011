<template>
  <div class="level-wrapper">
    <div class="level" v-for="i in lines" :key="i.label">
      <div class="level-left">
        <div class="level-item has-text-weight-bold">
          <b-icon v-if="i.icon" :icon="i.icon" class="mr-3" />
          {{ i.label }}
        </div>
      </div>
      <div class="level-right">
        <div :class="'level-item '+(i.class||'')">
          <b-icon
            size="is-small"
            :icon="i.icon"
            v-if="i.icon"
            :type="i.iconType"
            class="mr-3"
          />
          {{ i.value }}
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
function cap(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function objToArray(obj, keys) {
  let data = [];
  Object.keys(obj)
    .filter((k) => keys.includes(k))
    .forEach((key) => {
      data.push({
        label: cap(key.replace("_", " ").replace("_", " ")),
        value: obj[key],
      });
    });
  return data;
}
export default {
  name: "Level",
  computed: {
    lines() {
      let valid = Array.isArray(this.values) || (this.valueObj && this.keys);
      if (!valid) {
        throw "Level needs values or valuesObj&&keys";
      }
      return Array.isArray(this.values)
        ? this.values
        : objToArray(this.valueObj, this.keys);
    },
  },
  /**
   *
   */
  props: {
    /**
     * @values Array of objects ({label:String,value:Any,class:(null|String)}
     */
    values: {
      type: Array,
    },
    valueObj: {
      type: Object,
    },
    keys: {
      type: Array,
    },
  },
};
</script>

<style scoped></style>
