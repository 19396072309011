import { render, staticRenderFns } from "./BundleTable.vue?vue&type=template&id=56449a02&scoped=true&"
import script from "./BundleTable.vue?vue&type=script&lang=js&"
export * from "./BundleTable.vue?vue&type=script&lang=js&"
import style0 from "./BundleTable.vue?vue&type=style&index=0&id=56449a02&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56449a02",
  null
  
)

export default component.exports