var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.object)?_c('div',[_c('generic-view',{attrs:{"object-name":"internal-area","parent-name":"site","parent-title":"Site","ancestors":_vm.ancestors,"parent":_vm.parent,"object":_vm.object,"children":_vm.children,"child-name":"source","child-title":"Points-of-use (POUs)","state-var-name":_vm.endpointSegment},on:{"edit-clicked":function($event){return _vm.editClicked()}},scopedSlots:_vm._u([{key:"childrendisplay",fn:function(){return [_c('source-table',{attrs:{"rows":_vm.children,"show-flow-rate":""}})]},proxy:true},{key:"addnewchild",fn:function(){return [_c('b-button',{on:{"click":function($event){_vm.childModalOpen = true}}},[_vm._v("Add point-of-use")])]},proxy:true}],null,false,86781078)},[_c('level',{attrs:{"values":_vm.levelArray}}),_c('div',{staticClass:"mt-3 buttons"},[_c('b-button',{on:{"click":function($event){return _vm.$router.push({
            name: 'devices:area-detail',
            params: { areaId: _vm.object.id },
          })}}},[_vm._v(" Go to devices in area "),(_vm.object.$isInTrainingMode)?_c('in-training-mode',{attrs:{"area":_vm.object}}):_vm._e()],1),_c('b-button',{on:{"click":function($event){return _vm.$router.push({
            name: 'administration:view-area',
            params: { id: _vm.object.$building.id },
          })}}},[_vm._v(" Go to external area ")])],1)],1),(_vm.object && _vm.object.id)?_c('area-modal',{attrs:{"parent":_vm.object.parent,"id":_vm.object.id,"inputs":_vm.object},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}}):_vm._e(),(_vm.object && _vm.object.id)?_c('source-modal',{attrs:{"parent":_vm.object},model:{value:(_vm.childModalOpen),callback:function ($$v) {_vm.childModalOpen=$$v},expression:"childModalOpen"}}):_vm._e()],1):_c('div',[_c('b-message',{attrs:{"type":"is-error"}},[_vm._v(" No object found with that ID")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }