<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title" v-if="area">
        Devices in area:
        {{
          area.$building ? area.$building.$displayString : area.$displayString
        }}
        <span
          class="ml-2"
          v-if="
            (area.$site && area.$site.address) ||
            (area.$building && area.$building.unit)
          "
        >
          (
          <i v-if="area.get('$site.address')"> {{ area.$site.address }}</i>
          <span v-if="area.get('$building.unit')" class="ml-2">
            {{ area.$site && area.$site.address ? " - " : "" }}
            Unit/Suite {{ area.$building.unit }}
          </span>
          )
        </span>
      </div>
      <div class="card-header-icon">
        <b-icon
          v-if="loading || loadingAction"
          icon="sync"
          class="fa-spin"
          size="is-small"
        />
      </div>
    </div>
    <div class="card-content" v-if="loading">
      <loading-chart-card value spinner />
    </div>
    <div class="card-content" v-else-if="area">
      <b-tabs v-model="tab">
        <b-tab-item value="system" label="Area device summary">
          <div class="columns">
            <div class="column is-2">
              <area-actions-dropdown
                @refresh:positions="loadPositions()"
                :auto-next-route="false"
                :area="area"
                :gateway-positions="gatewayPositions"
                :positions="positions"
                :loading.sync="loading"
              />
            </div>
            <div class="column is-10">
              <div class="panel">
                <div class="panel-heading">
                  Area summary for
                  <span v-if="area.$building"
                    > <b>{{ area.$building.$displayString }}</b></span
                  >
                  <span v-else
                    >area: <b>{{ area.$displayString }}</b></span
                  >
                </div>
                <div class="panel-block is-fullwidth">
                  <area-detail-table
                    :area="area"
                    :gateway-positions="gatewayPositions"
                    :positions="positions"
                    @warning-clicked="trainingOffSensorsModalOpen = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item
          value="devices"
          :label="`Devices (${area.$devices.length})`"
        >
          <device-table
            show-training-mode-warning
            :loading="loadingPositions || loadingGatewayPositions"
            :rows="[...gatewayPositions, ...positions]"
            @training-mode-clicked="trainingOffSensorsModalOpen = true"
            @click="goToDeviceDetail"
            show-installation-location
          />
          <p v-if="lastUpdated" class="has-text-justified has-text-right">
            Last updated: <i>{{ lastUpdated.toLocaleString() }}</i>
            <b-icon
              icon="sync"
              style="cursor: pointer"
              :class="{
                'fa-spin has-text-blue':
                  loadingGatewayPositions || loadingPositions,
                'pointer ml-1': true,
              }"
              @click.native="loadPositions"
            />
          </p>
        </b-tab-item>
        <b-tab-item
          value="pou"
          :label="`Points-of-use (${area.$sources.length})`"
        >
          <source-table
            :rows="area.$sources"
            :row-class="getSourceTableRowClass"
            show-flow-rate
          />
          <div class="card has-shadow mdl-shadow--8dp mt-3">
            <div
              class="card-header pointer"
              @click="summaryOpen = summaryOpen ? 0 : 1"
            >
              <div class="card-header-title">
                Summary of point-of-use characteristics
              </div>
              <div class="card-header-icon">
                <b-icon :icon="'chevron-' + (summaryOpen ? 'up' : 'down')" />
              </div>
            </div>
            <transition name="fade">
              <div class="card-content" v-show="summaryOpen">
                <count-occurence-table
                  :fields="['fixture', '$room.floor', 'temperature']"
                  :rows="area.$sources"
                  @hover="hoveredRow = $event"
                />
              </div>
            </transition>
          </div>
        </b-tab-item>
        <b-tab-item
          value="pl"
          :label="`Pipe Locations (${
            $store.state.fetching.pipe_locations ? '...' : pipeLocations.length
          })`"
        >
          <pipe-location-table
            :rows="pipeLocations"
            :area="area"
            :building="area.$building"
            :loading="$store.state.fetching.pipe_locations"
            can-copy
            :paginate_by="500"
            @input="
              $store.commit('add', { name: 'pipe_locations', result: $event });
              $router.push({
                name: 'administration:view-pipe-location',
                params: { id: $event.id },
              });
            "
          >
            <template #post-name>
              <b-table-column v-slot="props" label="Device">
                <local-scope
                  :sensor-position="
                    props.row.position && positions && positions.length
                      ? getObjectInArrayById(positions, props.row.position.id)
                      : null
                  "
                  :probe="
                    props.row.probe ? probeById(props.row.probe.id) : null
                  "
                  v-slot="{ sensorPosition, probe }"
                >
                  <probe-display :value="probe" v-if="probe" show-link-icon />
                  <position-display
                    :value="sensorPosition"
                    v-else-if="sensorPosition"
                    show-link-icon
                  />
                  <span v-else>Not found</span>
                </local-scope>
              </b-table-column>
            </template>
          </pipe-location-table>
        </b-tab-item>
      </b-tabs>
    </div>
    <div class="card-header">
      <b-button
        expanded
        outlined
        icon-left="chevron-left"
        @click="$router.push({ name: 'devices:area-list' })"
        type="is-link"
      >
        <b-icon :icon="iconFor('list')" class="ml-3 mr-3" />
        View all systems
      </b-button>
    </div>
  </div>
</template>

<script>
import AreaActionsDropdown from "../../../components/Menus/AreaActionsDropdown";
import AreaDetailTable from "../../../tables/AreaDetailTable";

import iconFor from "../../../icons";
import DeviceTable from "../../../tables/DeviceTable";
import SourceTable from "../../../tables/SourceTable";
import CountOccurenceTable from "../../../tables/CountOccurenceTable";
import PipeLocationTable from "../../../tables/PipeLocationTable";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import { LocalScope } from "vue-local-scope";
import LoadingChartCard from "../../../components/Skeletons/LoadingChartCard";

export default {
  name: "AreaDetail",
  components: {
    AreaActionsDropdown,
    AreaDetailTable,
    CountOccurenceTable,
    DeviceTable,
    LoadingChartCard,
    LocalScope,
    PipeLocationTable,
    SourceTable,
  },
  props: {
    areaId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hoveredRow: null,
      loading: false,

      lastUpdated: null,
      loadingAction: false,
      loadingGatewayPositions: false,
      loadingPositions: false,
      gatewayPositions: [],
      positions: [],
    };
  },
  computed: {
    /**
     * @returns {Area|null}
     */
    area() {
      return this.$dataClasses.Area.fromStore(this.$store.state.areas, this.areaId);
    },
    trainingOffSensorsModalOpen: {
      get() {
        return Object.hasOwn(this.$route.query, "trainingOffModal");
      },
      set(val) {
        val
          ? this.queryReplace({ trainingOffModal: true })
          : this.queryRemoveKeys(["trainingOffModal"]);
      },
    },
    summaryOpen: {
      get() {
        return this.$route.query.sumOpen == 1;
      },
      set(val) {
        setTimeout(() => {
          this.queryReplace({ sumOpen: val });
        }, 25);
      },
    },
    pipeLocations() {
      return this.$store.state.pipe_locations
        ? this.$store.state.pipe_locations.filter(
            (pl) =>
              pl.building &&
              this.area.$building &&
              pl.building.id === this.area.$building.id
          )
        : [];
    },
    tab: {
      get() {
        return this.$route.query.aTab || "system";
      },
      set(val) {
        this.queryReplace({ aTab: val });
      },
    },
    probeEntries() {
      let entries = [];
      const ports = [0, 1, 2];
      this.positions.forEach((position) => {
        ports.forEach((port) => {
          if (
            position &&
            position[`port_${port}`] &&
            position[`port_${port}`].serial
          ) {
            entries.push(position[`port_${port}`]);
          }
        });
      });
      return entries;
    },
  },
  methods: {
    getObjectInArrayById,
    iconFor,
    probeById(id) {
      const matches = this.probeEntries.filter((e) => e.probe.id === id);
      return matches && matches.length ? matches[0] : null;
    },
    getSourceTableRowClass(row, index) {
      if (!this.hoveredRow) {
        return;
      }
      let val = row.get(this.hoveredRow.property);
      return (!this.hoveredRow.value && !val) || val === this.hoveredRow.value
        ? "is-primary"
        : null;
    },
    goToDeviceDetail(deviceObj) {
      this.$router.push({
        name: "devices:" + deviceObj.$deviceType + "-detail",
        params: {
          siteId: this.area.$site.id,
          serial: deviceObj.serial,
        },
      });
    },
    loadPositions() {
      if (this.loadingGatewayPositions || this.loadingPositions) {
        return;
      }
      if (!this.area) {
        this.gatewayPositions = [];
        this.positions = [];
        return;
      }
      this.loadingGatewayPositions = true;
      this.$dataClasses.GatewayPosition.query([["parent", "==", this.area.$FSRef]], {
        order_field: "parent",
      })
        .then((results) => (this.gatewayPositions = results))
        .catch((e) =>
          this.$handleError("Could not load gateway positions; see console", e)
        )
        .finally(() => (this.loadingGatewayPositions = false));
      this.loadingPositions = true;
      this.$dataClasses.Position.query([["area", "==", this.area.$FSRef]], {
        order_field: "area",
      })
        .then((results) => {
          this.lastUpdated = new Date();
          this.positions = results;
        })
        .catch((e) => this.$handleError("Could not load positions; see console", e))
        .finally(() => (this.loadingPositions = false));
    },
  },
  watch: {
    devices: {
      immediate: true,
      handler() {
        this.loadPositions();
      },
    },
    area: {
      immediate: true,
      handler(val, old) {
        if (val && (!old || val.id !== old.id)) {
          this.loadPositions();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../mixins/buefy/styles.scss";

:deep(tr.is-primary) {
  background-color: $primary;
  color: $white;
  transition: 0.1s background-color;
  text-decoration: none !important;
}
</style>
