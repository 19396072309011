<template>
  <b-field grouped>
    <b-input v-model.number="$data._value" type="number" :step="step" />
    <p class="control">
      <b-select v-model="$data._unit">
        <option v-for="val in aliases" :id="val" :value="val">
          {{displayPrefix}}{{ val }}
        </option>
      </b-select>
    </p>
  </b-field>
</template>

<script>
import convert from "convert-units";

export default {
  name: "UnitConversionInput",
  props: {
    /**
     * Number of decimals to round to
     */
    decimals: {
      type: Number,
      default: 2,
    },
    /**
     * Unit for given measurement, must be key in convert-units.
     * This is the base unit you plan to store in
     */
    convertToUnit: {
      type: String,
      // default: "C",
      // validator(val) {
      //   return !val || convert().possibilities("temperature").includes(val);
      // },
    },
    /**
     * Measurement type, must be key in convert-units.
     */
    measurement: {
      type: String,
      required: true,
    },
    /**
     * Initial numeric value in base unit
     */
    initialValue: {
      type: Number,
      default: null,
      required: false,
    },
    unit: {
      type: String,
    },
    displayPrefix: {
      type: String,
      default: ''
    }
  },
  created() {
    let localUnit = this.$store.getters.localUnit(this.measurement) || this.convertToUnit;
    if (localUnit !== this.convertToUnit) {
      this.$nextTick(() => {
        this.$data._unit = localUnit;
      });
    }
  },
  computed: {
    aliases() {
      return convert().possibilities(this.measurement);
    },
    roundingNumber() {
      return 10 ** this.decimals
    },
    step() {
      return (1 / this.roundingNumber).toString()
    }
  },
  data() {
    return {
      _unit: this.unit,
      _value: this.initialValue,
    };
  },
  methods: {
    updateRawValue(value) {
      this.$data._value = value;
    },
    unitChange(unit, oldUnit) {
      this.$emit("update:unit", unit);
      if (unit && oldUnit && unit !== oldUnit && this.$data._value !== null) {
          let newVal = convert(this.$data._value).from(oldUnit).to(unit);
          this.$data._value = Math.round(newVal * this.roundingNumber) / this.roundingNumber;
      }
    },
    valueChange() {
      if (this.$data._unit && this.$data._value !== null) {
        this.$nextTick(() => {
          let newVal = convert(this.$data._value)
              .from(this.$data._unit)
              .to(this.convertToUnit),
            value = Math.round(newVal * this.roundingNumber) / this.roundingNumber;
          this.$emit("update:value", value ? value : null);
          this.$emit("input", value ? value : null);
        });
      }
    },
  },
  watch: {
    "$data._unit": {
      immediate: true,
      handler(unit, oldUnit) {
        this.unitChange(unit, oldUnit);
      },
    },
    "$data._value": {
      immediate: true,
      handler(value) {
        this.valueChange(value);
      },
    },
  },
};
</script>

<style scoped></style>
