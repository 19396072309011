import FirestoreDataClass from "./_baseClasses";

export default class DeveloperToken extends FirestoreDataClass {
  static collectionName = "developer_tokens";

  static revoke(id) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self.$client
        .post(`${self.APIEndpointSegment}/revoke/`, { apiToken: id })
        .then((res) =>
          res.success
            ? resolve(res)
            : reject("Response did not indicate success")
        )
        .catch((e) => reject(e));
    });
  }

  revoke() {
    return this.constructor.revoke(this.id);
  }
}
