<template>
  <div class="container">
    <div class="columns">
      <div :class="{ column: true, 'is-4': showImage }">
        <div v-if="fileObject.content_type.startsWith('image/')">
          <div v-if="showImage">
            <transition name="fade">
              <aq-image :src="getImageUrl(fileObject)" responsive />
            </transition>
          </div>
        </div>
        <b-image v-else :src="NotAvailableSrc" @load="loaded = true" />
      </div>
      <div :class="{ column: true, 'is-8': showImage }">
        <slot name="pre-fields" />
        <form v-if="updatedMetadata">
          <b-field
            v-for="field in Object.keys(updatedMetadata)
              .filter((k) => !hiddenMetadataKeys.includes(k))
              .sort()"
            :key="field"
            :disabled="savingMetadata"
            :label="capitalizeFirstLetter(field.replace('_', ' '))"
          >
            <b-input
              :disabled="disabledMetadataKeys.includes(field)"
              :loading="savingMetadata"
              v-model="updatedMetadata[field]"
            />
          </b-field>
        </form>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <slot name="pre-buttons" />
        <div class="buttons mt-3 mb-6 is-justify-content-space-between">
          <b-button
            type="is-danger"
            :icon-left="iconFor('delete')"
            @click="deleteFileDialog(fileObject)"
            :loading="deleting"
          >
            Delete
          </b-button>
          <slot name="inside-buttons" />
          <b-button
            type="is-primary"
            :icon-left="iconFor('save')"
            @click="updateMetadata(fileObject)"
            :loading="savingMetadata"
          >
            Save file metadata
          </b-button>
          <slot name="post-buttons" />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import NotAvailableSrc from "../../assets/General/previewNotAvailable.png";
import { cloneDeep } from "lodash";

import iconFor from "../../icons";
import trackWindowSize from "../../mixins/trackWindowSize";
import AqImage from "../../components/Files/AqImage";
import { isEqual } from "lodash";

export default {
  name: "FileData",
  components: { AqImage },
  mixins: [trackWindowSize],
  props: {
    fileObject: {
      type: Object,
      default: () => {},
    },
    showImage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      NotAvailableSrc,
      loaded: false,
      hiddenMetadataKeys: ["user_id", "w", "h"],
      disabledMetadataKeys: ["category"],
      loadingImage:
        this.fileObject.content_type &&
        this.fileObject.content_type.toLowerCase().startsWith("image/"),
      savingMetadata: false,
      deleting: false,
      updatedMetadata: cloneDeep(this.fileObject.metadata),
    };
  },
  computed: {
    changed() {
      return !isEqual(this.fileObject.metadata, this.updatedMetadata);
    },
  },
  methods: {
    iconFor,
    getImageUrl(file) {
      return process.env.VUE_APP_API_URL + "/files/" + file.path;
    },
    detailRowLoaded() {
      this.loadingImage = false;
    },
    updateMetadata(fileData) {
      this.savingMetadata = true;
      this.$client
        .post("/files/update/" + fileData.path + "/", this.updatedMetadata)
        .then((r) => {
          this.fileUpdated(r.result);
          this.updatedMetadata = cloneDeep(r.result.metadata);
          r.result._imageLoaded = fileData._imageLoaded;
        })
        .catch((e) => this.$handleError(e, e))
        .finally(() => {
          this.savingMetadata = false;
          this.$handleSuccess(
            "File metadata saved for: " + this.updatedMetadata.filename
          );
        });
    },
    deleteFile(file) {
      let deleteUrl = this.viewOrDeleteFileUrl(false, file);
      this.deleting = true;
      this.$client
        .delete(deleteUrl)
        .then(() => {
          this.$handleSuccess("File has been removed: " + file.metadata.filename);
          this.fileDeleted(file);
        })
        .catch((e) => this.$handleError(e))
        .finally(() => {
          this.deleting = false;
        });
    },
    fileDeleted(file) {
      this.$emit("file-deleted", file);
    },
    fileUpdated(fileData) {
      this.$emit("file-updated", fileData);
    },
    viewOrDeleteFileUrl(includeDomain, file) {
      let domain = includeDomain ? process.env.VUE_APP_API_URL : "";
      return domain + `/files/` + file.path;
    },
    deleteFileDialog(file) {
      let self = this;
      self.$buefy.dialog.confirm({
        type: "is-danger",
        hasIcon: true,
        icon: iconFor("delete"),
        message: `Are you sure you want to delete file '${file.metadata.filename}'? This will also delete any data tied to this file.`,
        onConfirm: () => {
          self.deleteFile(file);
        },
      });
    },
  },
};
</script>

<style scoped></style>
