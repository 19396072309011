export const theadFirstTd = "Day/Time";

export const theadTimeRange = ["00:00 - 12:00", "12:00 - 24:00  "];

// export const theadHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

export const theadWithHalfHours = [
  {
    hour: 0,
    time: "00:00",
  },
  {
    hour: 30,
    time: "00:30",
  },
  {
    hour: 1,
    time: "01:00",
  },
  {
    hour: 30,
    time: "01:30",
  },
  {
    hour: 2,
    time: "02:00",
  },
  {
    hour: 30,
    time: "02:30",
  },
  {
    hour: 3,
    time: "03:00",
  },
  {
    hour: 30,
    time: "03:30",
  },
  {
    hour: 4,
    time: "04:00",
  },
  {
    hour: 30,
    time: "04:30",
  },
  {
    hour: 5,
    time: "05:00",
  },
  {
    hour: 30,
    time: "05:30",
  },
  {
    hour: 6,
    time: "06:00",
  },
  {
    hour: 30,
    time: "06:30",
  },
  {
    hour: 7,
    time: "07:00",
  },
  {
    hour: 30,
    time: "07:30",
  },
  {
    hour: 8,
    time: "08:00",
  },
  {
    hour: 30,
    time: "08:30",
  },
  {
    hour: 9,
    time: "09:00",
  },
  {
    hour: 30,
    time: "09:30",
  },
  {
    hour: 10,
    time: "10:00",
  },
  {
    hour: 30,
    time: "10:30",
  },
  {
    hour: 11,
    time: "11:00",
  },
  {
    hour: 30,
    time: "11:30",
  },
  {
    hour: 12,
    time: "12:00",
  },
  {
    hour: 30,
    time: "12:30",
  },
  {
    hour: 13,
    time: "13:00",
  },
  {
    hour: 30,
    time: "13:30",
  },
  {
    hour: 14,
    time: "14:00",
  },
  {
    hour: 30,
    time: "14:30",
  },
  {
    hour: 15,
    time: "15:00",
  },
  {
    hour: 30,
    time: "15:30",
  },
  {
    hour: 16,
    time: "16:00",
  },
  {
    hour: 30,
    time: "16:30",
  },
  {
    hour: 17,
    time: "17:00",
  },
  {
    hour: 30,
    time: "17:30",
  },
  {
    hour: 18,
    time: "18:00",
  },
  {
    hour: 30,
    time: "18:30",
  },
  {
    hour: 19,
    time: "19:00",
  },
  {
    hour: 30,
    time: "19:30",
  },
  {
    hour: 20,
    time: "20:00",
  },
  {
    hour: 30,
    time: "20:30",
  },
  {
    hour: 21,
    time: "21:00",
  },
  {
    hour: 30,
    time: "21:30",
  },
  {
    hour: 22,
    time: "22:00",
  },
  {
    hour: 30,
    time: "22:30",
  },
  {
    hour: 23,
    time: "23:00",
  },
  {
    hour: 30,
    time: "23:30",
  },
];

export const theadWithHours = [
  {
    hour: 0,
    time: "00:00",
  },
  {
    hour: 1,
    time: "01:00",
  },
  {
    hour: 2,
    time: "02:00",
  },
  {
    hour: 3,
    time: "03:00",
  },
  {
    hour: 4,
    time: "04:00",
  },
  {
    hour: 5,
    time: "05:00",
  },
  {
    hour: 6,
    time: "06:00",
  },
  {
    hour: 7,
    time: "07:00",
  },
  {
    hour: 8,
    time: "08:00",
  },
  {
    hour: 9,
    time: "09:00",
  },
  {
    hour: 10,
    time: "10:00",
  },
  {
    hour: 11,
    time: "11:00",
  },
  {
    hour: 12,
    time: "12:00",
  },
  {
    hour: 13,
    time: "13:00",
  },
  {
    hour: 14,
    time: "14:00",
  },
  {
    hour: 15,
    time: "15:00",
  },
  {
    hour: 16,
    time: "16:00",
  },
  {
    hour: 17,
    time: "17:00",
  },
  {
    hour: 18,
    time: "18:00",
  },
  {
    hour: 19,
    time: "19:00",
  },
  {
    hour: 20,
    time: "20:00",
  },
  {
    hour: 21,
    time: "21:00",
  },
  {
    hour: 22,
    time: "22:00",
  },
  {
    hour: 23,
    time: "23:00",
  },
];

export const allHours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const allWithHalfHours = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
