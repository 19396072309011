<template>
  <div>
    <div v-if="object">
      <generic-view
          object-name="internal-area"
          parent-name="site"
          parent-title="Site"
          :ancestors="ancestors"
          :parent="parent"
          :object="object"
          :children="children"
          child-name="source"
          child-title="Points-of-use (POUs)"
          :state-var-name="endpointSegment"
          @edit-clicked="editClicked()"
      >
        <level :values="levelArray"/>
        <div class="mt-3 buttons">
          <b-button
              @click="
            $router.push({
              name: 'devices:area-detail',
              params: { areaId: object.id },
            })
            "
          >
            Go to devices in area
            <in-training-mode
                :area="object"
                v-if="object.$isInTrainingMode"
            />
          </b-button>
          <b-button
              @click="
            $router.push({
              name: 'administration:view-area',
              params: { id: object.$building.id },
            })
            "
          >
            Go to external area
          </b-button>
        </div>
        <template v-slot:childrendisplay>
          <source-table :rows="children" show-flow-rate/>
        </template>
        <template v-slot:addnewchild>
          <b-button @click="childModalOpen = true">Add point-of-use</b-button>
        </template>
      </generic-view>

      <area-modal
          v-if="object && object.id"
          v-model="modalOpen"
          :parent="object.parent"
          :id="object.id"
          :inputs="object"
      ></area-modal>

      <source-modal
          v-if="object && object.id"
          v-model="childModalOpen"
          :parent="object"
      ></source-modal>
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import {getAncestors, getChildrenOfId, getObjectInArrayById,} from "../../../scripts/filterHelpers";
import AreaModal from "../../../components/Admin/Area/AreaModal";
import Level from "../../../components/Level/Level";
import SourceModal from "../../../components/Admin/Sources/AddSourceModal";
import SourceTable from "../../../tables/SourceTable";
import GenericView from "./genericView";
import {mapState} from "vuex";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";

export default {
  name: "ViewArea",
  components: {AreaModal, GenericView, Level, SourceModal, SourceTable},
  mixins: [onScopeChangeMixin],
  methods: {
    editClicked() {
      this.modalOpen = true;
    },
  },
  computed: {
    ...mapState(["buildings", "rooms"]),
    ancestors() {
      return this.object && this.object.id
          ? getAncestors([
            {
              title: "Customer",
              object: getObjectInArrayById(
                  this.$store.state.customers,
                  this.object.customer.id,
                  null
              ),
              endpointName: "administration:view-customer",
            },
            {
              title: "Sub-Customer/Division",
              object: getObjectInArrayById(
                  this.$store.state.subcustomers,
                  this.object.subcustomer.id,
                  null
              ),
              endpointName: "administration:view-subcustomer",
            },
            {
              title: "Site",
              object: getObjectInArrayById(
                  this.$store.state.sites,
                  this.object.site.id,
                  null
              ),
              endpointName: "administration:view-site",
            },
          ])
          : null;
    },
    levelArray() {
      return this.object ? [{label: "Name", value: this.object.name}] : [];
    },
    parent() {
      return this.object && this.object.parent
          ? getObjectInArrayById(this.$store.state.sites, this.object.parent.id)
          : null;
    },
    object() {
      let obj = getObjectInArrayById(this.$store.state.areas, this.id, null);
      if (!obj) {
        return {};
      }
      if (!obj.buildings) {
        obj.buildings = [];
      }
      if (!obj.rooms) {
        obj.rooms = [];
      }
      return obj;
    },
    children() {
      return this.object
          ? getChildrenOfId(this.$store.state.sources, this.id)
          : null;
    },
  },
  data() {
    return {
      childModalOpen: Object.keys(this.$route.query).includes("addChild"),
      modalOpen: Object.keys(this.$route.query).includes("edit"),
      endpointSegment: "areas",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    modalOpen: {
      handler(val) {
        this.$router.replace({query: val ? {edit: null} : {}});
      },
    },
    childModalOpen: {
      handler(val) {
        this.$router.replace({query: val ? {addChild: null} : {}});
      },
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
