<template>
  <div>
    <div v-if="parent.name">
      Sub-customer/division will be added below customer <i>{{ parent.name }}</i
      >.
    </div>
    <b-field label="Sub-customer/Division name">
      <b-input minlength="3" required type="text" v-model="inputs.name" />
    </b-field>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "SubCustomerForm",
  data() {
    return {
      inputs: Object.assign({ parent: this.parent }, cloneDeep(this.value)),
    };
  },
  computed: {
    isValid() {
      return this.inputs.name.length > 2;
    },
  },
  props: {
    parent: {
      type: Object,
      required: false,
    },
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    validates() {
      this.$emit("validates", this.isValid);
    },
    getClearedData() {
      return Object.assign({
        parent: this.value.parent,
        name: this.value.name,
      });
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
      this.validates();
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
