<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Alarm schedules and settings</div>
    </div>
    <div class="card-content">
      <message-settings-for-site
        v-if="siteId && Object.keys(selectedSettingsSite).length"
        :site="selectedSettingsSite"
      />
    </div>
  </div>
</template>

<script>
import MessageSettingsForSite from "../../components/UserMessage/MessageSettingsForSite";
import { mapState } from "vuex";
import { get, set } from "lodash";
import { getObjectInArrayById } from "../../scripts/filterHelpers";

export default {
  name: "MessageSettingsTab",
  components: { MessageSettingsForSite },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(["selectedScopeIdStr", "sites", "sources", "user_roles"]),
    selectedSettingsSite() {
      return this.$store.getters.site;
    },
    rows() {
      return this.selectedSettingsSite
        ? [this.selectedSettingsSite]
        : this.sites;
    },
  },
  methods: {
    get,
    set,
    getSubcustomer(id) {
      return getObjectInArrayById(this.$store.state.subcustomers, id, {
        name: "Not found",
      });
    },
  },
  props: {
    siteId: {
      type: String,
      required: false,
    },
  },
  watch: {
    siteId: {
      immediate: true,
      handler(val) {
        if (this.rows.length === 1 && !val) {
          this.$router.replace({
            name: "settings:alarmSchedule",
            params: { siteId: this.rows[0].id },
            query: this.$route.query,
          });
        }
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler(val, oldVal) {
        if (val !== oldVal && this.siteId) {
          this.$router.replace({ params: { siteId: undefined } });
        }
      },
    },
  },
};
</script>

<style scoped></style>
