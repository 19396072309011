<template>
  <b-table
    :loading="loading"
    :checkable="checkable"
    :checked-rows.sync="checkedRows"
    :data="rows"
    :default-sort="['timestamp']"
    default-sort-direction="desc"
    sort-icon="chevron-up"
    icon-pack="fas"
    sort-icon-size="is-small"
    striped
    bordered
    sticky-header
    height="inherit"
  >
    <b-table-column field="filename" label="File name" sortable v-slot="props"
      >{{
        props.row.filename.replaceAll("_", " ").replaceAll("AquaSeca- ", "")
      }}
    </b-table-column>
    <slot name="columns">
      <b-table-column v-slot="props" label="For">
        <generic-display
          v-if="$store.getters.objectByID(props.row.for_object.id)"
          :value="$store.getters.objectByID(props.row.for_object.id)"
        />
        <span v-else>
          <i>Not found</i>
        </span>
      </b-table-column>
      <b-table-column v-slot="props" sortable label="Date" field="timestamp">
        {{ props.row.timestamp.toLocaleDateString() }}
      </b-table-column>
    </slot>
    <b-table-column v-slot="props">
      <b-button
        @click="download(props.row)"
        :loading="currentlyDownloading(props.row.id)"
        icon-left="file-download"
        rounded
        size="is-small"
        class="is-pulled-right"
        icon-right="chevron-right"
        >Download</b-button
      >
    </b-table-column>
  </b-table>
</template>

<script>

export default {
  name: "ReportTable",
  data() {
    return {
      checkedRows: [],
      downloadingIds: {},
    };
  },
  methods: {
    currentlyDownloading(reportId) {
      return this.downloadingIds[reportId];
    },
    download(reportObj) {
      let self = this;
      self.downloadingIds[reportObj.id] = true;
      self.downloadingIds = Object.assign({}, self.downloadingIds);
      reportObj
        .download()
        .catch((e) => self.$handleError("Could not download report", e))
        .finally(() => {
          self.downloadingIds[reportObj.id] = false;
          self.downloadingIds = Object.assign({}, self.downloadingIds);
        });
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
};
</script>

<style scoped></style>
