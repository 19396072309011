<template>
  <b-modal
      v-if="internalArea"
      :active="value"
      :trap-focus="trapFocus"
      @input="$emit('input',$event)"
      @close="$emit('input',$event)"
      has-modal-card
      type="is-info"
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          Sensor thresholds for <b>{{ internalArea.$displayString }}</b>
        </div>
      </div>
      <div class="modal-card-body">
        <threshold-edit
            :internal-area="internalArea"
            :positions="positions"
            @close="$emit('input', false)"
        />
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button
              class="is-fullwidth"
              @click="$emit('input', false)">
            Close
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import thresholdEdit from "../../../applications/Settings/thresholdEdit";
import Area from "../../../dataClasses/Area";

export default {
  name: "EditThresholdsModal",
  components: {thresholdEdit},
  props: {
    trapFocus: {
      type: Boolean,
      default: false
    },
    internalArea: {
      type: Area,
      required: true
    },
    positions: {
      type: Array,
      default: () => {
      }
    },
    value: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>