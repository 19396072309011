<template>
  <div
      class="training-overview card"
      @keyup.esc="
      showModelModal || showThresholdModal
        ? null
        : $route.query.detail
        ? queryReplace({ detail: null })
        : $router.push({
            name: 'training:overview',
            params: {
              areaId: $route.params.sourceId ? $route.params.areaId : null,
              sourceId: null,
            },
            query: $route.query,
          })
    "
      tabindex="0"
  >
    <div class="card-header">
      <div class="card-header-title">System training</div>
      <div class="card-header-icon">
        <b-icon :icon="iconFor('training')"/>
      </div>
    </div>
    <div class="card-content">
      <div class="card" v-if="area && area.$area">
        <div class="card-header">
          <div class="card-header-title">
            Area: &nbsp;
            <building-display
                v-if="area"
                :value="area"
                :next="$router.resolve({ name: 'training:overview' }).href"
            />
            <div
                v-if="
                $store.state.fetching.areas ||
                loadingPositions ||
                loadingGWPositions
              "
            >
              <b-icon icon="sync" class="fa-spin ml-1"/>
            </div>
          </div>
          <div class="card-header-icon">
            <transition name="fade">
              <area-actions-dropdown
                  classes="mr-3"
                  outlined
                  dropdown-position="is-bottom-left"
                  v-if="area"
                  @refresh:positions="getPositions()"
                  :positions="
                  areaId
                    ? positions.filter((p) => toId(p.building) === areaId)
                    : []
                "
                  :gateway-positions="
                  areaId
                    ? GWPositions.filter((p) => toId(p.building) === areaId)
                    : []
                "
                  :area="area.$area"
                  auto-next-route
                  :loading="loadingModelRebuild || loadingActions"
                  @loading:update="loadingActions = $event"
              />
            </transition>
            <b-icon
                icon="times"
                class="pointer"
                @click.native="$router.push({ name: 'training:overview' })"
            />
          </div>
        </div>

        <div class="card-content">
          <div>
            <transition name="slide">
              <div
                  class="has-text-centered"
                  v-if="loadingPositions || loadingGWPositions"
              >
                <spinner-with-checkmark
                    :loading="loadingPositions || loadingGWPositions"
                />
              </div>
            </transition>
            <div
                v-if="
                area &&
                area.$area &&
                !loadingPositions &&
                !loadingGWPositions &&
                (notInTrainingMode || !hardwareConnected)
              "
            >
              <div class="columns mb-3">
                <b-message
                    :closable="false"
                    class="column m-3"
                    title="No gateways installed"
                    type="is-danger"
                    v-if="!gatewaysInstalledForAreaId.length"
                >
                  <div class="has-text-centered">
                    <spinner-with-checkmark/>
                  </div>
                  <div class="m-6 has-text-centered">
                    You must have at least one gateway installed to begin
                    training.
                  </div>
                  <div class="has-text-centered">
                    <b-button
                        class="is-danger is-outlined"
                        @click="
                        area.$area.$goToAddGateway({ next: $route.fullPath })
                      "
                    >
                      Add a gateway
                    </b-button>
                  </div>
                </b-message>
                <b-message
                    :closable="false"
                    class="column m-3"
                    title="No sensors installed"
                    type="is-danger"
                    v-else-if="!sensorsInstalledForAreaId.length"
                >
                  <div class="has-text-centered">
                    <spinner-with-checkmark/>
                  </div>
                  <div class="m-6 has-text-centered">
                    You must have at least one sensor installed to begin
                    training.
                  </div>
                  <div class="has-text-centered">
                    <b-button
                        class="is-danger is-outlined"
                        @click="
                        area.$area.$goToAddSensor({ next: $route.fullPath })
                      "
                    >
                      Add a sensor
                    </b-button>
                  </div>
                </b-message>
                <b-message
                    v-else-if="
                    !hardwareConnected && !hardwareNotConnectedDismissed
                  "
                    :closable="false"
                    class="column m-3"
                    title="Hardware not connected"
                    type="is-danger"
                    @close="hardwareNotConnectedDismissed = true"
                >
                  <system-status-tile
                      class="has-background-danger-light"
                      classes="has-bodfy"
                      title-classes="has-text-danger"
                      centered
                      :loading="loadingGWPositions || loadingPositions"
                      :positions="
                      positions.filter((p) => toId(p.building) === areaId)
                    "
                      :gateway-positions="
                      GWPositions.filter((p) => toId(p.building) === areaId)
                    "
                  >
                    <template #title><span/></template>
                    <template #link><span/></template>
                  </system-status-tile>
                </b-message>
                <b-message
                    v-if="notInTrainingMode && sensorsInstalledForAreaId.length"
                    type="is-danger"
                    class="column m-3"
                    title="No sensors in training mode"
                    :closable="false"
                    message="There are no sensors operating in training mode. Please select which sensors you wish to put
                  into training mode."
                >
                  <div class="has-text-centered">
                    <spinner-with-checkmark/>
                  </div>
                  <div>
                    There are no sensors operating in training mode. Please
                    select which sensors you wish to put into training mode.
                  </div>
                  <div class="has-text-centered">
                    <b-button
                        class="mt-5"
                        type="is-danger is-outlined"
                        @click="queryReplace({ trainingModal: true })"
                    >
                      Put sensors in training mode
                    </b-button>
                  </div>
                </b-message>
              </div>
              <div class="has-text-centered has-text-danger mb-3">
                <i>
                  Please remediate the issues above before continuing with
                  training.
                </i>
              </div>
            </div>
            <div
                v-else-if="areaId && hardwareConnected && onBasePage"
                class="has-text-centered"
            >
              <spinner-with-checkmark success/>
              <p class="has-text-success">Ready for training</p>
            </div>
            <transition name="slide">
              <div v-if="onBasePage">
                <div class="box mt-3 mb-3">
                  <p class="subtitle has-text-centered">
                    Which point-of-use would you like to train?
                  </p>
                  <source-table
                      show-flow-rate
                      :rows="trainingSources"
                      :show-area="false"
                      :on-click="
                      (row) => {
                        $router.push({
                          name: 'training:by-source',
                          params: { areaId, sourceId: row.id },
                          query: $route.query,
                        });
                      }
                    "
                  />
                  <div
                      v-if="$store.state.user_roles.is_installer"
                      class="has-text-centered mb-3"
                  >
                    <b-field label="Show internal sources" v-if="$store.state.user_roles.is_root">
                      <b-checkbox
                          size="is-small"
                          v-model="showInternalSources"
                      />
                    </b-field>
                    <div class="buttons is-centered">
                      <b-button
                          type="is-primary is-outlined"
                          @click="
                          $router.push({
                            name: 'administration:view-area',
                            params: { id: areaId },
                            query: { pouModal: true, next: $route.fullPath },
                          })
                        "
                      >
                        Add new point-of-use
                      </b-button>
                      <b-button
                          type="is-primary is-outlined"
                          @click="showModelModal = true"
                      >
                        Review training quality
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <router-view
                v-if="!loadingPositions"
                :positions="
                areaId
                  ? positions.filter((p) => toId(p.building) === areaId)
                  : []
              "
                :gateway-positions="
                areaId
                  ? GWPositions.filter((p) => toId(p.building) === areaId)
                  : []
              "
                :all-sources="trainingSources"
                @rebuild-model-clicked="showRebuildshowModelModal()"
                @threshold-edit-clicked="showThresholdModal = true"
            />
          </div>
        </div>
      </div>
      <div v-if="areaId && !area">
        Area not found.
        <router-link :to="{ name: 'training:overview' }"
        >Click here
        </router-link
        >
        to go back to area selection.
      </div>
      <div v-else-if="!areaId">
        <site-introspection-card-content>
          <p class="subtitle has-text-centered">
            Which area would you like to perform training in?
          </p>
          <building-table :rows="$store.state.buildings" :on-click="onAreaClick">
            <template #pre-select-column>
              <b-table-column v-slot="props" label="Sensors" numeric>
                <b-icon
                    icon="sync"
                    class="fa-spin"
                    v-if="loadingPositions"
                    size="is-small"
                />
                <span v-else>
                {{
                    positions.filter((p) => p.building.id === props.row.id).length
                  }}
              </span>
              </b-table-column>
              <b-table-column v-slot="props" label="Gateways" numeric>
                <b-icon
                    icon="sync"
                    class="fa-spin"
                    v-if="loadingGWPositions"
                    size="is-small"
                />
                <span v-else>
                {{
                    GWPositions.filter(
                        (p) => p.building && p.building.id === props.row.id
                    ).length
                  }}
              </span>
              </b-table-column>
            </template>
          </building-table>
          <div class="has-text-centered mt-3" v-if="onBasePage">
            <b-button
                type="is-primary is-outlined"
                @click="
              $router.push({
                name: 'administration:view-site',
                params: { id: $store.getters.site.id },
                query: { areaModal: true, next: $route.fullPath },
              })
            "
            >
              Add a new area
            </b-button>
          </div>
        </site-introspection-card-content>
      </div>
    </div>
    <ground-truth-summary-modal
        :active.sync="showModelModal"
        :area="area"
        @click="
        queryRemoveKeys(['showModelModal'], () => {
          $nextTick(() => {
            let params = { ...$route.params },
              query = { ...$route.query, sources: $event.map(src=>src.id) };
            params.sourceId = $event[0].id;
            $router.push({ name: 'training:by-source', params, query });
          });
        })
      "
    />
  </div>
</template>

<script>
import BuildingTable from "../../tables/BuildingTable";
import SourceTable from "../../tables/SourceTable";
import iconFor from "../../icons";
import {LocalScope} from "vue-local-scope";

import {sortBy} from "lodash";
import SystemStatusTile from "../DashBoard/Tiles/SystemStatusTile";
import AreaActionsDropdown from "../../components/Menus/AreaActionsDropdown";
import GroundTruthSummaryModal from "../../components/Dialogs/Modals/GroundTruthSummaryModal";
import intervalFunctionMixin from "../../mixins/intervalFunctionMixin";
import SiteIntrospectionCardContent from "../../components/SystemIntrospection/SiteIntrospectionCardContent";

export default {
  name: "TrainingOverview",
  components: {
    SiteIntrospectionCardContent,
    GroundTruthSummaryModal,
    AreaActionsDropdown,
    SystemStatusTile,
    SourceTable,
    BuildingTable,
    LocalScope,
  },
  mixins: [intervalFunctionMixin],
  props: {
    millisecondsPerPoll: {
      type: Number,
      default: 2 * 60 * 1000, // 2 mins
    }
  },
  /**
   *
   * @returns {{positions: [Position], loadingPositions: boolean, loadingGWPositions: boolean, GWPositions: [GatewayPosition]}}
   */
  data() {
    return {
      positionsFirstFetched: false,
      loadingActions: false,
      loadingModelRebuild: false,
      loadingPositions: false,
      loadingGWPositions: false,
      positions: [],
      GWPositions: [],
      hardwareNotConnectedDismissed: false,
      conditions: [],
    };
  },
  created() {
    if (
        this.$store.state.buildings &&
        this.$store.state.buildings.length === 1
    ) {
      let areaId = this.$store.state.buildings[0].id;
      this.$router.replace({
        name: this.$route.name,
        query: this.$route.query,
        params: {areaId},
      });
    }
  },
  computed: {
    area() {
      return this.areaId
          ? this.$dataClasses.Building.fromStore(
              this.$store.state.buildings,
              this.areaId
          )
          : null;
    },
    gatewaysInstalledForAreaId() {
      return this.areaId && this.GWPositions
          ? this.GWPositions.filter((p) => this.toId(p.building) === this.areaId)
          : [];
    },
    sensorsInstalledForAreaId() {
      return this.areaId && this.GWPositions
          ? this.positions.filter((p) => this.toId(p.building) === this.areaId)
          : [];
    },
    showInternalSources: {
      get() {
        return this.$route.query.hasOwnProperty("showInternalSources");
      },
      set(showInternalSources) {
        showInternalSources
            ? this.queryReplace({showInternalSources})
            : this.queryRemoveKeys(["showInternalSources"]);
      },
    },
    trainingSources() {
      const sources = sortBy(this.area.$sources, [
        "$room.floor",
        "$room.unit",
        "$room.name",
        "name",
      ]);
      return this.showInternalSources
          ? sources
          : sources.filter((src) => src.creation === "user");
    },
    showThresholdModal: {
      get() {
        return this.$route.query.hasOwnProperty("showThresholdModal");
      },
      set(showThresholdModal) {
        showThresholdModal
            ? this.queryPush({showThresholdModal})
            : this.removeQueryKeysPush(["showThresholdModal"]);
      },
    },
    showModelModal: {
      get() {
        return this.$route.query.hasOwnProperty("showModelModal");
      },
      set(showModelModal) {
        showModelModal
            ? this.queryPush({showModelModal})
            : this.removeQueryKeysPush(["showModelModal"]);
      },
    },
    areaId: {
      get() {
        return this.$route.params.areaId || null;
      },
      set(areaId) {
        this.$router.push({
          name: this.$route.name,
          query: this.$route.query,
          params: {areaId},
        });
      },
    },
    hardwareConnected() {
      const gwPositionsHealthy =
              this.gatewaysInstalledForAreaId.filter((gwP) => !gwP.$isHealthy)
                  .length === 0,
          positionsHealthy =
              this.sensorsInstalledForAreaId.filter((p) => !p.$isHealthy).length ===
              0;

      this.conditions = [
        !this.loadingPositions,
        !this.loadingGWPositions,
        !!this.areaId,
        !!this.area,
        !!this.sensorsInstalledForAreaId,
        this.sensorsInstalledForAreaId.length,
        !!this.gatewaysInstalledForAreaId,
        this.gatewaysInstalledForAreaId.length,
        gwPositionsHealthy,
        positionsHealthy,
      ];
      return this.conditions.every((c) => !!c);
    },
    notInTrainingMode() {
      return this.area && this.area.$area && !this.area.$area.$isInTrainingMode;
    },
    onBasePage() {
      return this.$route.name === "training:overview";
    },
  },
  methods: {
    iconFor,
    sortBy,
    onPoll() {
      if (this.positionsFirstFetched) {
        this.getPositions()
      }
    },
    getPositions() {
      if (!(this.$store.state.areas && this.$store.state.areas.length)) {
        this.positions = [];
        this.GWPositions = [];
        return;
      }
      if (!(this.positions.length)) {
        this.loadingPositions = true;
      }
      let positionPromise = this.$dataClasses.Position.query([
        ["area", "in", this.$store.state.areas.map((a) => this.toFSRef(a))],
      ])
          .then((results) => {
            this.positions = results;
          })
          .catch((e) => {
            this.positions = [];
            this.$handleError(e);
          })
          .finally(() => {
            setTimeout(() => (this.loadingPositions = false), 1500);
          });
      if (!this.GWPositions.length) {
        this.loadingGWPositions = true;
      }
      let gwPositionPromise = this.$dataClasses.GatewayPosition.query([
        ["parent", "in", this.$store.state.areas.map((a) => this.toFSRef(a))],
      ])
          .then((results) => (this.GWPositions = results))
          .catch((e) => {
            this.GWPositions = [];
            this.$handleError(e)
          })
          .finally(() => (this.loadingGWPositions = false));
      return Promise.all([positionPromise, gwPositionPromise]).then(() => {
        this.positionsFirstFetched = true;
      });
    },
    onAreaClick(area) {
      this.areaId = area ? area.id : null;
    },
    showRebuildshowModelModal() {
      this.showModelModal = true;
    },
  },
  mounted() {
    this.getPositions();
  },
  watch: {
    showModelModal: {
      immediate: true,
      handler(isActive) {
        if (isActive) {
          this.$nextTick(() => {
            this.queryRemoveKeys(["returnToModelBuild", "sources"]);
          });
        }
      },
    },
    "$store.state.areas": {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        if (!this.positionsFirstFetched) {
          this.getPositions();
        }
      },
    },
  },
};
</script>

<style scoped></style>
