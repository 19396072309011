<template>
  <div>
    <div v-if="object">
      <generic-view
        :editable="user_roles.is_installer"
        v-if="user_roles.is_installer"
        object-name="site"
        parent-name="subcustomer"
        parent-title="Division"
        :ancestors="ancestors"
        :parent="parent"
        :object="object"
        :children="[]"
        :other-children="buildings ? buildings : []"
        child-title="Areas"
        :child-name="null"
        :state-var-name="endpointSegment"
        @edit-clicked="editClicked()"
      >
        <div v-if="object && marker && object.address">
          <map-with-marker :marker="marker" />
        </div>
        <level class="mt-5" :values="displayArray">
          <div class="level">
            <div class="level-left">
              <div class="level-item has-text-weight-bold">Tags/Groups</div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-taglist v-if="object.tags && object.tags.length">
                  <b-tag v-for="tag in object.tags" :key="tag">{{ tag }}</b-tag>
                </b-taglist>
                <p v-else>None</p>
              </div>
            </div>
          </div>
        </level>

        <template v-slot:pre-children>
          <children
            name="building"
            :children="buildings ? buildings : []"
            class="mt-3"
          >
            <template v-slot:childrendisplay>
              <div class="card children-card">
                <div class="card-header">
                  <div class="card-header-icon">
                    <b-icon :icon="iconFor('building')" />
                  </div>
                  <div class="card-header-title">Areas</div>
                </div>
                <div class="card-content">
                  <p
                    class="has-text-centered has-text-grey-light"
                    v-if="!buildings.length"
                  >
                    None found
                  </p>
                  <building-table v-else :rows="buildings" />
                </div>
                <div class="card-footer">
                  <div class="card-footer-item" v-if="object.id">
                    <slot name="addnewchild">
                      <b-button
                        @click="childBuildingModalOpen = true"
                        type="is-create is-outlined"
                        v-if="$store.state.user_roles.is_installer"
                      >
                        Add new area
                      </b-button>
                    </slot>
                  </div>
                </div>
              </div>
            </template>
          </children>
        </template>

        <template v-slot:childrendisplay>
          <area-table class="mt-15" :rows="children" />
        </template>
        <template v-slot:addnewchild v-if="$store.state.user_roles.is_root">
          <b-button
            @click="childAreaModalOpen = true"
            type="is-create is-outlined"
            >Add new internal area</b-button
          >
        </template>
      </generic-view>

      <add-site-modal
        v-model="modalOpen"
        :parent="object.parent"
        :id="object.id"
        :inputs="object"
      ></add-site-modal>
      <area-modal
          v-model="childAreaModalOpen"
          :parent="object"
      />
      <building-modal
          v-model="childBuildingModalOpen"
          :parent="object"
      />
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import iconFor from "../../../icons";
import {
  getObjectInArrayById,
  getChildrenOfId,
  getChildrenOfIdByKey,
  getAncestors,
} from "../../../scripts/filterHelpers";
import { mapState } from "vuex";

import AreaModal from "../../../components/Admin/Area/AreaModal";
import AreaTable from "../../../tables/AreaTable";
import BuildingTable from "../../../tables/BuildingTable";
import Children from "../../../applications/Administration/View/children";
import GenericView from "./genericView";
import Level from "../../../components/Level/Level";
import MapWithMarker from "../../../components/MapWithMarker";
import AddSiteModal from "../../../components/Admin/Sites/AddSiteModal";
import BuildingModal from "../../../components/Admin/Buildings/BuildingModal";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";

export default {
  name: "ViewSite",
  components: {
    BuildingModal,
    AddSiteModal,
    AreaModal,
    AreaTable,
    BuildingTable,
    Children,
    GenericView,
    Level,
    MapWithMarker,
  },
  mixins: [onScopeChangeMixin],
  methods: {
    iconFor,
    editClicked() {
      this.modalOpen = true;
    },
  },
  computed: {
    ...mapState(["user_roles"]),
    ancestors() {
      return (this.object && this.object.id)
        ? getAncestors([
            {
              title: "Customer",
              object: getObjectInArrayById(
                this.$store.state.customers || [],
                this.object.customer,
                null
              ),
              endpointName: "administration:view-customer",
            },
            {
              title: "Sub-Customer",
              object: getObjectInArrayById(
                this.$store.state.subcustomers || [],
                this.object.subcustomer,
                null
              ),
              endpointName: "administration:view-subcustomer",
            },
          ])
        : {};
    },
    marker() {
      return this.object && this.object.lat_long
        ? {
            lat: this.object.lat_long.latitude,
            lng: this.object.lat_long.longitude,
          }
        : null;
    },
    parent() {
      return this.object && this.object.id
        ? getObjectInArrayById(
            this.$store.state.subcustomers,
            this.object.parent,
            null
          )
        : null;
    },
    object() {
      return getObjectInArrayById(this.$store.state.sites, this.id, null);
    },
    children() {
      return this.object && this.object.id
        ? getChildrenOfId(this.$store.state.areas, this.id)
        : null;
    },
    buildings() {
      return this.object && this.object.id
        ? getChildrenOfIdByKey(this.$store.state.buildings, this.id, [], "site")
        : null;
    },
    displayArray() {
      if (!(this.object && this.object.id)) {
        return [];
      }
      let arr = [
        { label: "Name", value: this.object.name },
        { label: "Address", value: this.object.address || "None entered" },
      ];
      if (this.$store.state.user_roles.is_root) {
        arr.push({
          label: "Flagged for monitoring",
          value: this.object._internally_monitored ? "Yes" : "No",
        });
      }
      return arr;
    },
    childBuildingModalOpen: {
      get() {return this.$route.query.hasOwnProperty('areaModal')},
      set(areaModal) {areaModal ? this.queryPush({areaModal}) : this.removeQueryKeysPush(['areaModal'])},
    },
    childAreaModalOpen: {
      get() {return this.$route.query.hasOwnProperty('intAreaModal')},
      set(intAreaModal) {intAreaModal ? this.queryPush({intAreaModal}) : this.removeQueryKeysPush(['intAreaModal'])},
    }
  },
  data() {
    return {
      endpointSegment: "sites",
      modalOpen: Object.keys(this.$route.query).includes("edit"),
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
