import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/messaging";
import Vue from "vue";
import { firestorePlugin } from "vuefire";
import { firestoreOptions } from 'vuexfire';

firestoreOptions.maxRefDepth = 0;

Vue.use(firestorePlugin, {
    maxRefDepth: 0
});

const fbApp = firebase.initializeApp(
  JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
);

export default fbApp;
// Get a Firestore instance
export const firestore = fbApp.firestore();
export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };
