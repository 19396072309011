<template>
  <option-driven-radio
    :options="options"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import OptionDrivenRadio from "./OptionDrivenRadio";

export const options = [
  { value: "aluminum", label: "Aluminum" },
  { value: "brass", label: "Brass" },
  { value: "copper", label: "Copper" },
  { value: "plastic", label: "Plastic" },
  { value: "steel", label: "Steel" },
  { value: "other", label: "Other/Unknown" },
];

export default {
  name: "PipeMaterialRadio",
  components: { OptionDrivenRadio },
  props: {
    value: {
      type: String,
      validator(val) {
        return !val || options.map((o) => o.value).includes(val);
      },
    },
  },
  data() {
    return {
      options,
    };
  },
};
</script>

<style scoped></style>
