<template>
  <div>
    <modal-with-form
        v-if="isMounted"
        :allow-delete="
        $store.state.user_roles && $store.state.user_roles.is_admin
      "
        :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
        :value="value"
        @input="$emit('input', $event);"
        :can-submit="isValid"
        :full-page="false"
        :id="id"
        :inputs="inputs"
        :class="stateVarName + '-modal'"
        obj-display-name="Room"
        :state-var-name="stateVarName"
        @closed="activeChanged(false)"
        @saved="saved($event)"
        @deleted="deleted($event)"
    >
      <room-form
          v-model="inputs"
          :parent="parent"
          :id="id"
          @input="inputs = Object.assign({}, inputs, $event)"
          @validates="validChanged($event)"
      ></room-form>
    </modal-with-form>
  </div>
</template>

<script>
import RoomForm from "./RoomForm";
import ModalWithForm from "../generic/ModalWithForm";
import {getObjectInArrayById} from "../../../scripts/filterHelpers";

export default {
  name: "RoomModal",
  components: {
    RoomForm,
    ModalWithForm,
  },
  mounted() {
    this.isMounted = true;
  },
  data() {
    let self = this,
        parent = self.parent ? self.toFSRef(self.parent.id, "buildings") : null;
    return {
      inputs: this.id
          ? Object.assign(
              getObjectInArrayById(this.$store.state.rooms, this.id),
              {parent: parent}
          )
          : {parent: parent},
      isValid: false,
      isMounted: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    parent: {
      type: Object,
      required: true,
      validator(val) {
        return (
            val && val._reference && val._reference.path.startsWith("buildings/")
        );
      },
    },
    stateVarName: {
      type: String,
      default: "rooms",
    },
  },
  methods: {
    validChanged(valid) {
      if (this.isMounted) {
        this.isValid = valid;
      }
    },
    activeChanged(val) {
      if (val === this.value) {
        return;
      }
      this.$emit("input", val);
    },
    saved(val) {
      this.$store.commit("add", {
        name: "rooms",
        result: val,
      });
      this.$emit("saved", val);
      this.$nextTick(() => {
        let room = getObjectInArrayById(this.$store.state.rooms, val.id);
        this.inputs = val.id
            ? Object.assign(
                room,
                {parent: this.parent}
            )
            : {parent: this.parent};
      });
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
};
</script>

<style scoped></style>
