<template>
  <date-range-picker
    auto-apply
    show-ranges
    opens="right"
    :time-picker="timePicker"
    :time-picker-increment="5"
    :disabled="disabled"
    :max-date="maxDate"
    v-model="dateRange"
    ref="picker"
  />
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "DateRangeSelect",
  components: { DateRangePicker },
  data() {
    return {
      dateRange: this.value,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          startDate: new Date(),
          endDate: new Date(),
        };
      },
      validator: (prop) => "startDate" in prop && "endDate" in prop,
    },
    maxDate: {
      type: Date,
      required: false,
      default: () => new Date(),
    },
  },
  watch: {
    dateRange: {
      handler(val) {
        let dateRange = Object.assign({}, val);
        if (dateRange.startDate) {
          dateRange.startDate.setHours(0, 0, 0);
        }
        if (dateRange.endDate) {
          dateRange.endDate.setHours(23, 59, 59);
        }
        this.$emit("input", dateRange);
      },
    },
  },
};
</script>

<style scoped></style>
