<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Gateway detail for {{ serial }}</div>
      <div class="card-header-icon">
        <b-icon :icon="iconFor('gateway')"/>
      </div>
    </div>
    <div class="card-content">
      <div class="columns" v-if="loading || positionLoading">
        <div class="column">
          <loading-card/>
        </div>
      </div>
      <div class="columns" v-else-if="!device">
        <div class="column has-text-centered">
          <p class="subtitle mt-6 mb-6">
            Error: No gateway found with serial <b>{{serial}}</b>
          </p>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column is-2-desktop is-2-table has-text-centered">
          <div class="block">
            <live-gateway-display
                :serial="serial"
                :position="position"
                :show-last-seen="false"
                :show-sensors="false"
            />
          </div>
          <b-dropdown
              :triggers="isMobile ? ['click'] : ['hover', 'click']"
              aria-role="list"
              class="has-text-left"
              v-if="serial"
              :expanded="isMobile"
          >
            <template #trigger>
              <b-button
                  label="I'd like to:"
                  type="is-primary"
                  :expanded="isMobile"
                  icon-right="chevron-down"
              />
            </template>

            <b-dropdown-item
                :disabled="!!position"
                aria-role="listitem"
                @click="device.$goToAddGateway()"
            >
              Install this gateway
            </b-dropdown-item>
            <b-dropdown-item
                :disabled="!position || !serial || !canRemoveGateway"
                aria-role="listitem"
                @click="position.$goToRemoveGateway()"
            >
              Remove this gateway
            </b-dropdown-item>
            <hr/>
            <b-dropdown-item
                aria-role="listitem"
                @click="position.$goToAddSensor()"
                :disabled="!position"
            >
              Add a sensor to this gateway
            </b-dropdown-item>
            <b-dropdown-item
                aria-role="listitem"
                :disabled="!position"
                @click="position.$goToRemoveSensors()"
            >
              Remove one or more sensors from this gateway
            </b-dropdown-item>

            <hr/>
            <b-dropdown-item
                aria-role="listitem"
                @click="sendSupportEmailClicked('gateway')"
            >
              Request technical support
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-10-desktop is-10-tablet" v-if="device">
          <div class="columns">
            <div class="column is-half-desktop is-half-table">
              <div class="panel is-info">
                <div class="panel-heading">Gateway information</div>
                <div class="panel-block is-fullwidth">
                  <gateway-detail-table
                      v-if="device"
                      :serial="serial"
                      :gateway="device"
                      :gateway-position="position"
                  >
                    <div class="buttons mt-5">
                      <b-button
                          v-if="$store.state.user_roles.is_root && device"
                          expanded
                          type="is-link"
                          @click="  window.open((position||device).$qrCodeUrl, '_blank')"
                      >
                        QR Code
                      </b-button>
                    </div>
                  </gateway-detail-table>
                  <transition name="slide">
                    <b-message
                        class="mt-5"
                        v-if="position && connectWeightsOpen"
                        title="Attached sensors"
                        :closable="false"
                    >
                      <connect-weight-table
                          v-if="areaGateway"
                          :key="areaGateway.aqdid"
                          :sensors="sensors"
                          :connect-weights="areaGateway.connect_weights"
                      >
                        <template #empty>
                          <i class="mt-3 mb-3">
                            No connected sensors found for gateway {{ serial }}
                          </i>
                        </template>
                        <template #end>
                          <b-table-column v-slot="props">
                            <b-button
                                class="is-pulled-right"
                                size="is-small"
                                tag="router-link"
                                type="is-link"
                                :to="{
                                name: 'devices:sensor-detail',
                                params: {
                                  siteId: $route.params.siteId,
                                  serial: sensorForId(props.row.sensor.id)
                                    .serial,
                                },
                              }"
                            >
                              View
                            </b-button>
                          </b-table-column>
                        </template>
                      </connect-weight-table>
                    </b-message>
                  </transition>
                </div>
              </div>
            </div>
            <div class="column is-half-desktop is-half-tablet">
              <div class="panel is-primary">
                <div class="panel-heading">Installation location</div>
                <div class="panel-block is-fullwidth">
                  <install-location
                      v-if="device && position && position.id"
                      allow-edit
                      :show-pipe-location="false"
                      :position="position"
                        :table-classes="[
                        'is-bordered',
                        'is-striped',
                        'is-fullwidth',
                        'is-fullhd',
                      ]"
                  >
                    <aq-image-upload
                        class="mt-3"
                        category="install_picture"
                        :parent-object-path="'gateway_positions/' + position.id"
                    />
                  </install-location>
                 <div class="has-text-centered" v-else>
                    <i>
                      This device is not currently installed
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="device">
            <transition name="slide">
              <b-button
                  expanded
                  size="is-small"
                  v-if="!showEventHistory"
                  @click="showEventHistory = true"
              >
                View gateway event history
              </b-button>
            </transition>
            <transition name="slide">
              <event-history v-if="showEventHistory" :tabs="tabs"/>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide">
      <div class="card-header" v-if="device && position && position.id">
        <b-button
            :loading="!(position && position.$area)"
            expanded
            outlined
            icon-left="chevron-left"
            @click="
              $router.push({
                name: 'devices:area-detail',
                params: { areaId: position.$area.id },
              })
            "
            type="is-link"
        >
          <b-icon :icon="iconFor('system')" class="ml-3 mr-3"/>
          {{ (position && position.$area) ? position.$area.$displayString : ''}}
        </b-button>
      </div>
    </transition>
  </div>
</template>

<script>
import trackWindowSize from '../../../mixins/trackWindowSize';
import LoadingCard from '../../../components/Skeletons/LoadingCard';
import ConnectWeightTable from '../../../applications/Settings/Devices/ConnectWeightTable';
import iconFor from '../../../icons';
import BuildingAndRoomModal from '../../../components/Selects/BuildingAndRoomModal';
import GatewayDetailTable from '../../../tables/GatewayDetailTable';
import DeviceSupportEmailMixin from '../../../applications/Settings/Devices/DeviceSupportEmailMixin';
import AqImageUpload from '../../../components/Files/Editors/AqImageUpload';

import {uniqBy} from 'lodash';

export default {
  name: 'GatewayDetail',
  components: {
    AqImageUpload,
    BuildingAndRoomModal,
    ConnectWeightTable,
    GatewayDetailTable,
    LoadingCard,
  },
  mixins: [DeviceSupportEmailMixin, trackWindowSize],
  data() {
    return {
      window,
      connectWeightsOpen: true,
      device: null,
      loading: false,
      modalOpen: false,
      positionRaw: null,
      positionId: null,
      positionLoading: false,
      showEventHistory: false,
    };
  },
  props: {
    serial: {
      type: String,
      required: true,
    },
  },
  computed: {
    /**
     * @return {GatewayPosition|null}
     */
    position() {
      return this.positionRaw ? new this.$dataClasses.GatewayPosition(this.positionRaw) : null;
    },
    sensors() {
      return this.position && this.position.$area
          ? uniqBy(this.position.$area.sensors, 'serial')
          : [];
    },
    areaGateway() {
      return this.position.$recordInArea;
    },
    hasAnyConnectWeights() {
      return (
          !this.areaGateway ||
          !this.areaGateway.connect_weights ||
          this.areaGateway.connect_weights.filter(
              (cw) => cw.weight && cw.weight > 0,
          ).length > 0
      );
    },
    canRemoveGateway() {
      const conditions = [this.position];
      return conditions.every((c) => !!c);
    },
    tabs() {
      let val = {};
      if (this.device) {
        val.Gateway = this.toFSRef(this.device, 'gateways');
      }
      if (this.positionId) {
        val.Position = this.$db.collection(this.$dataClasses.Position.collectionName).doc(this.positionId);
      }
      return val;
    },
  },
  methods: {
    iconFor,
    sensorForId(id) {
      if (!this.sensors || !this.sensors.length) {
        return;
      }
      let matches = this.sensors.filter((s) => s.sensor.id === id);
      return matches.length ? matches[0] : null;
    },
    getGatewayDevice() {
      if (!this.serial) {
        this.device = null;
        this.positionId = null;
        return;
      }
      this.loading = true;
      this.$dataClasses.Gateway.query([['serial', '==', this.serial]]).then((results) => {
        if (results && results.length) {
          this.device = results[0];
        } else {
          throw 'Could not find gateway with serial: ' + this.serial;
        }
        return this.$dataClasses.GatewayPosition.queryForSingle([
          ['serial', '==', this.serial],
        ]);
      }).then((result) => {
        this.positionId = result ? result.id : null;
      }).catch((e) => {
        this.positionId = null;
        this.positionRaw = null;
        // this.$handleError(e, e);
      }).finally(() => (this.loading = false));
    },
  },
  watch: {
    serial: {
      immediate: true,
      handler() {
        this.getGatewayDevice();
      },
    },
    positionId: {
      immediate: true,
      handler(val) {
        this.loadingPosition = true;
        if (!val) {
          if (this.positionRaw) {
            this.$unbind('positionRaw');
          }
          this.positionRaw = null;
          this.loadingPosition = false;
        } else {
          // subscribe
          this.$bind(
              'positionRaw',
              this.$db.collection(this.$dataClasses.GatewayPosition.collectionName).doc(val),
          ).then(() => (this.loadingPosition = false));
        }
      },
    },
  },
};
</script>

<style scoped></style>
