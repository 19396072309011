/**
 * Requirements:
 *  - Carries from one window to another on sign-in (SPA ✓, Flask x)
 *  - Carries from one window to another on sign-out (SPA ✓, Flask ✓) when cookie.AUTHORIZATION==='SIGNOUT'
 *  - Refresh apiClient
 *  - Carries across subdomains (✓)
 *  - Subdomains use same script (x)
 *  - Auto-refreshing (x)
 *  - Triggers vuex on auth state changed only after getIDToken
 *  - Work with FirebaseUI in SPA
 */
import { get } from "lodash";
import dateFromISO8601 from "./dateFromISO8601";
import Cookies from "js-cookie";
import firebaseApp from "../firebase";

const getDomainWithoutSubdomain = (url) => {
  const hostname = new URL(url).hostname,
    urlParts = hostname.split(".");
  if (urlParts[0] === "127") {
    return hostname;
  }
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join(".");
};

/**
 *
 * @param callback {function}
 * @return {function(...[*]=)}
 */
function handleAuthCookieChange(callback) {
  let lastCookie = Cookies.get("Authorization");
  return function () {
    const currentCookie = Cookies.get("Authorization");
    if (currentCookie !== lastCookie) {
      // something useful like parse cookie, run a callback fn, etc.
      if (!currentCookie && lastCookie) {
        // Do something; this means it is not initial load
        firebaseApp
          .auth()
          .signOut()
          .then(() => {
            if (callback) {
              callback();
            }
          });
      }
      lastCookie = currentCookie; // store latest cookie
    }
  };
}

/**
 * @param refreshIfExpiresWithinMS {Number}
 * @return Boolean
 */
function shouldRefreshTokenIfExpiring(refreshIfExpiresWithinMS) {
  let expiresISO = Cookies.get("expires", null),
    expires = expiresISO ? dateFromISO8601(expiresISO) : null,
    now = new Date();
  return expires && expires - now < refreshIfExpiresWithinMS;
}

const fbAuthMixin = {
  /**
   * On mount, run polling to automatically refresh token. First trigger is pollEveryMS after Vueapp mounted
   */
  mounted() {
    const self = this,
      refreshIfExpiresWithinMS = 30 * 60 * 1000, // 10 min
      pollEveryMS = 2 * 60 * 1000, // 2 min
      handleAuthCookieCallable = handleAuthCookieChange(self.handleNoUser); // 30 secs
    window.setInterval(() => {
      if (shouldRefreshTokenIfExpiring(refreshIfExpiresWithinMS)) {
        self.checkForValidIDToken(firebaseApp.auth().currentUser, false);
      }
    }, pollEveryMS);
    window.setInterval(() => {
      handleAuthCookieCallable();
    }, 1000);
    self.$on("tabVisibilityChange", function (tabIsVisible) {
      // See main.js for visibility event mounting
      if (
        tabIsVisible &&
        shouldRefreshTokenIfExpiring(refreshIfExpiresWithinMS)
      ) {
        self.checkForValidIDToken(firebaseApp.auth().currentUser, true);
      }
    });
  },
  computed: {
    nextUrl() {
      return this.$route.query.nextUrl || this.$route.query.next || null;
    },
  },
  /**
   * On initial app creation, check for token w/out forceRefresh.
   * When tab visibility changes, attempt to retrieve and refresh token by force. Will log out if bad token.
   */
  created() {
    let self = this;
    self.$fb.auth().onAuthStateChanged((user) => {
      self.$nextTick(() => {
        self.checkForValidIDToken(user, false);
      });
    });
  },
  methods: {
    /**
     * @param user {Object} - As supplied via FB Auth lib
     * @param forceRefresh {Boolean} - If true, force token refresh
     */
    checkForValidIDToken(user, forceRefresh) {
      let self = this;
      let fromRoute = self.$route,
        onBadToken = () =>
          self.$fb
            .auth()
            .signOut()
            .then(() => {
              self.handleNoUser(fromRoute);
            });
      // self.$store.commit("setLoading", !self.$fb.auth().currentUser);
      if (user && self.$fb.auth().currentUser) {
        self.$fb
          .auth()
          .currentUser.getIdTokenResult(forceRefresh)
          .then((idTokenResult) => {
            // self.$store.commit("setLoading", false);
            if (idTokenResult) {
              user.claims = idTokenResult.claims;
              self.handleUser(
                user,
                idTokenResult.token,
                new Date(idTokenResult.expirationTime)
              );
            } else {
              onBadToken();
            }
          });
      } else {
        onBadToken();
      }
    },
    /**
     * Sets user/token values on API client and in cookies
     * @param idToken {String}
     * @param refreshToken {String}
     * @param expires {Date}
     * @param baseUrl {String}
     */
    setAuthValues(idToken, refreshToken, expires, baseUrl) {
      let expiresISO = expires ? expires.toISOString() : null,
        options = {
          domain: baseUrl,
          expires: expires,
        };
      this.$client.setAuth(idToken, refreshToken, expires);
      Cookies.set("Authorization", idToken, options);
      Cookies.set("refreshToken", refreshToken, options);
      Cookies.set("expires", expiresISO, options);
    },
    /**
     * Helper to execute when auth state changes and user+token are valid
     * @param user {Object}
     * @param idToken {String}
     * @param expirationTime {Date|null}
     */
    handleUser(user, idToken, expirationTime) {
      let baseUrl = getDomainWithoutSubdomain(window.location.href);
      if (baseUrl.includes(":")) {
        baseUrl = baseUrl.split(":")[0];
      }
      this.setAuthValues(
        "Bearer " + idToken,
        user.refreshToken,
        expirationTime,
        baseUrl
      );
      this.$store.dispatch("onAuthStateChanged", {
        user: user,
        nextUrl: this.nextUrl,
        router: this.$router,
      }); // handles changing user id
    },
    /**
     * Helper to execute when user is not signed out, token expires w/out refresh, etc.
     * Does NOT call firebase.auth().signOut; instead, should be performed after that promise resolves.
     */
    handleNoUser(fromRoute) {
      // close all dialogs and modals
      document.querySelectorAll(".dialog").forEach((modal) => {
        modal.__vue__?.$vnode?.context?.close();
      });
      let self = this,
        selectedScopeIdStr = get(
          self.$store.state.selectedScopeIdStr,
          "id",
          null
        ),
        route = fromRoute || self.$route,
        baseUrl = getDomainWithoutSubdomain(window.location.href);
      if (selectedScopeIdStr) {
        route.query.loadScope = selectedScopeIdStr;
      }
      if (baseUrl.includes(":")) {
        baseUrl = baseUrl.split(":")[0];
      }
      let options = {
        domain: baseUrl,
      };
      self.$client.unsetAuth();
      ["Authorization", "refreshToken", "expires"].forEach((key) =>
        Cookies.remove(key, options)
      );
      self.$store.dispatch("onAuthStateChanged", { user: null });
      let query = {};
      if (route && route.meta && !route.meta.public) {
        query =
          self.$route.name !== "logout"
            ? { nextUrl: route.fullPath }
            : { nextUrl: fromRoute.fullPath };
        self.$router.push({ name: "login", query });
      } else if (route.name === "logout") {
        self.$router.push({ name: "login", query });
      }
    },
  },
};

export default fbAuthMixin;
