<template>
  <div>
    <div v-if="titleName" class="has-text-weight-bold has-text-centered">
      {{ titleName }}
    </div>
    <slot name="childrendisplay">
      <b-button
        expanded
        v-for="child in children"
        :key="child.id"
        @click="
          $router.push({
            name: 'administration:view-' + name,
            params: { id: child.id },
          })
        "
      >
        {{ child.name }}
      </b-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: "children",
  props: {
    name: {
      type: String,
      required: true,
    },
    titleName: {
      type: String,
      required: false,
      default: "",
    },
    children: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style scoped></style>
