var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading && (!_vm.rows || !_vm.rows.length))?_c('i',[_vm._v(" No ground truth found for this object ")]):_c('b-table',{attrs:{"bordered":"","checkable":"","detailed":"","striped":"","default-sort":['information.post_dt'],"default-sort-direction":"desc","checked-rows":_vm.checkedRows,"custom-is-checked":(a, b) => {
          return a.id === b.id;
        },"detail-key":"id","data":_vm.finalRows,"is-row-checkable":_vm.rowIsCheckable,"loading":_vm.loading,"paginated":_vm.rows && _vm.rows.length > _vm.perPage,"per-page":_vm.perPage},on:{"check":function($event){_vm.$emit('check', $event);_vm.$emit('update:checked-rows',$event)}},scopedSlots:_vm._u([{key:"detail",fn:function(props){return [_c('ground-truth-detail',{attrs:{"value":props.row},on:{"update":function($event){return _vm.rowUpdated($event)},"deleted":function($event){return _vm.rowDeleted($event)}}})]}},{key:"bottom-left",fn:function(){return [_vm._t("bottom-left",function(){return [_c('b-field',{attrs:{"label":"Show per page"}},[_c('b-select',{model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('option',{domProps:{"value":10}},[_vm._v("10")]),_c('option',{domProps:{"value":25}},[_vm._v("25")]),_c('option',{domProps:{"value":50}},[_vm._v("50")]),_c('option',{domProps:{"value":100000}},[_vm._v("All")])])],1)]})]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"label":"Time","field":"information.post_dt","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.information && props.row.information.post_dt)?_c('span',[_vm._v(" "+_vm._s(_vm.shortenedLocaleString(props.row.information.post_dt))+" ")]):_c('i',[_vm._v("None")])]}}])}),_c('b-table-column',{attrs:{"label":"Room","field":"roomName","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.sourceWithNm(props.row.information.actual_source).room)?_c('room-display',{attrs:{"value":_vm.getObjectInArrayById(
            _vm.$store.state.rooms,
            _vm.sourceWithNm(props.row.information.actual_source).room,
            null
          )}}):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"POU","sortable":"","custom-sort":_vm.sortPOU},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('source-display',{attrs:{"value":_vm.sourceWithNm(props.row.information.actual_source),"show-training-level":""}})]}}])}),_c('b-table-column',{attrs:{"label":"Sensor","field":"information.sensor_serial","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.information.sensor_serial)?_c('span',[_vm._v(" "+_vm._s(props.row.information.sensor_serial)+" ")]):_c('i',[_vm._v("Not assigned")])]}}])}),_c('b-table-column',{attrs:{"label":"Duration","field":"information.duration_seconds","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
          props.row &&
          props.row.information &&
          props.row.information.duration_seconds
        )?_c('span',{class:{'has-text-danger':props.row.information.duration_seconds > 58}},[_vm._v(" "+_vm._s(_vm.shortHumanizer(props.row.information.duration_seconds * 1000))+" ")]):_c('i',[_vm._v("Not assigned")])]}}])}),_c('b-table-column',{attrs:{"label":"Quality level","sortable":"","numeric":"","field":"evaluation.quality","custom-sort":_vm.sortQualityFunction},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.evaluation && props.row.evaluation.quality)?_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(props.row.evaluation.quality))+" ")]):_c('i',[_vm._v("Not assigned")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }