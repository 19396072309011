<template>
  <div>
    Hello
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <loading-card v-if="loading" />
        <empty-card v-else-if="!object" />
        <div class="card" v-else>
          <b-loading :is-full-page="false" :active="loading" />
          <div class="card-header generic-card-header">
            <div class="card-header-title">
              {{ profileData.display_name }}
              <b-button class="is-pulled-right" icon-right="edit" />
            </div>
          </div>
          <div class="card-content">
            <p v-if="parentLoading" class="has-text-centered">
              <i>Loading...</i>
            </p>
            <p v-else-if="parentName" class="has-text-centered">
              Member of <b>{{ parentName }}</b>
            </p>
            <p v-else class="has-text-centered">...</p>
            <level :values="levelData" />
            <hr />
            <user-roles
              :parent-path="object.parent_path"
              v-model="object.roles"
              @validates="validRoles = $event ? $event : false"
            />
          </div>
          <div class="card footer"></div>
        </div>
        <b-button
          tag="router-link"
          :to="{ name: 'administration:users' }"
          icon-left="chevron-left"
        >
          Return to user administration
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyCard from "../../../components/Skeletons/EmptyCard";
import Level from "../../../components/Level/Level";
import LoadingCard from "../../../components/Skeletons/LoadingCard";
import UserRoles from "../../../components/Roles/UserRoles";

export default {
  name: "User",
  components: { Level, EmptyCard, LoadingCard, UserRoles },
  computed: {
    canEdit() {
      return true;
    },
    profileData() {
      return this.object._additional ? this.object._additional : {};
    },
    levelData() {
      return [
        { label: "Name", value: this.profileData.display_name },
        { label: "Email", value: this.profileData.email },
        { label: "Phone", value: this.profileData.phone || "Not set" },
      ];
    },
  },
  data() {
    return {
      loading: true,
      object: null,
      parent: null,
      parentLoading: false,
      parentName: null,
      validRoles: false,
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      let self = this;
      this.loading = true;
      this.$dataClasses.UserAccount.fetchById(this.id)
        .then((result) => {
          self.object = result;
        })
        .catch((e) => {
          self.$handleError(e, e);
          this.object = null;
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    object: {
      handler(userAccount) {
        let self = this;
        if (
          userAccount &&
          self.selectedScopeIdStr &&
          userAccount.parent_path.startsWith(self.selectedScopeIdStr)
        ) {
          self.parent = self.$store.getters.topLevel.object;
        } else {
          self.parent = null;
        }
      },
    },
    parent: {
      handler(par) {
        this.parentName = par && par.name ? par.name : null;
      },
    },
    id: {
      handler() {
        this.get();
      },
    },
  },
};
</script>

<style scoped></style>
