<template>
  <div :class="classes">
    <b-dropdown
      :triggers="loading ? [] : isMobile ? ['click'] : ['hover', 'click']"
      aria-role="list"
      class="has-text-left is-centered"
      :expanded="isMobile"
      :position="dropdownPosition"
    >
      <template #trigger>
        <slot name="dropdown-trigger">
          <b-button
            :label="label"
            :type="`is-primary ${outlined?'is-outlined':''}`"
            icon-right="chevron-down"
            :expanded="isMobile"
            :loading="loading"
            :disabled="disabled"
          />
        </slot>
      </template>
      <b-dropdown-item
        aria-role="listitem"
        @click="area.$goToAddGateway(qParams)"
      >
        Add a gateway to this area
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="gatewayPositions.length < 1"
        aria-role="listitem"
        @click="area.$goToRemoveGateway(qParams)"
      >
        Remove a gateway from this area
      </b-dropdown-item>
      <hr />
      <b-dropdown-item
        :disabled="gatewayPositions.length === 0"
        aria-role="listitem"
        @click="area.$goToAddSensor(qParams)"
      >
        Add a sensor to this area
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="positions.length === 0"
        aria-role="listitem"
        @click="area.$goToRemoveSensor(qParams)"
      >
        Remove a sensor from this area
      </b-dropdown-item>
      <hr />
      <b-dropdown-item
        :disabled="positions.length === 0"
        aria-role="listitem"
        @click="area.$goToAttachProbe(qParams)"
      >
        Attach a probe to a sensor
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="positions.filter((p) => p.$anyPortInUse).length === 0"
        aria-role="listitem"
        @click="area.$goToRemoveProbe(qParams)"
      >
        Remove a probe from a sensor
      </b-dropdown-item>
      <hr />
      <b-dropdown-item
        aria-role="listitem"
        @click="area.$building.$goToReviewTrainingQuality()">
        Review training quality or re-build model
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="
          positions.filter((p) => p.$isInTrainingMode).length ===
          positions.length
        "
        @click="trainingSensorsModalOpen = true"
        aria-role="listitem"
      >
        Put sensors in training mode
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="positions.filter((p) => p.$isInTrainingMode).length === 0"
        @click="trainingOffSensorsModalOpen = true"
        aria-role="listitem"
      >
        <b-icon
          type="is-danger"
          icon="exclamation-circle"
          v-if="
            positions &&
            positions.length &&
            positions.filter((p) => p.$isInTrainingMode).length > 0
          "
        />
        Exit training mode
        <span v-if="positions.filter((p) => p.$isInTrainingMode).length > 0">
          ({{ positions.filter((p) => p.$isInTrainingMode).length }} sensor{{
            positions.filter((p) => p.$isInTrainingMode).length !== 1
              ? "s"
              : ""
          }})
        </span>
      </b-dropdown-item>
      <hr />
      <b-dropdown-item
        :disabled="!positions || positions.length === 0"
        @click="showThresholdModal = true"
        aria-role="listitem"
      >
        Edit sensor thresholds
      </b-dropdown-item>
      <b-dropdown-item
        @click="autoThresholdModalOpen = true"
        aria-role="listitem"
      >
        Start auto-threshold loop
      </b-dropdown-item>
      <b-dropdown-item
        @click="
          promisary(
            area.$stopAutoThreshold(),
            'The auto-threshold loop for this area has been stopped'
          )
        "
        aria-role="listitem"
      >
        Stop auto-threshold loop
      </b-dropdown-item>
    </b-dropdown>
    <edit-thresholds-modal
      trap-focus
      v-model="showThresholdModal"
      :internal-area="area"
      :positions="positions"
    />
    <select-positions-for-auto-threshold-modal
      @input="handleAutoThresholdModalSubmit($event)"
      :active.sync="autoThresholdModalOpen"
      :area="area"
      :loading="loading"
      :positions="positions"
    />
    <select-sensors-for-training-modal
      @input="handleTrainingSensorsModalSubmit($event)"
      :active.sync="trainingSensorsModalOpen"
      @close="trainingSensorsModalOpen=false"
      :area="area"
      :loading="loading"
      :positions="positions"
    />
    <select-sensors-to-take-out-of-training-mode
      @input="handleTrainingModeOffSensorsModalSubmit($event)"
      :active.sync="trainingOffSensorsModalOpen"
      :area="area"
      :loading="loading"
      :positions="positions.filter((p) => p.$isInTrainingMode)"
    />
  </div>
</template>

<script>
import SelectSensorsForTrainingModal from "../../applications/DeviceActionComponents/SelectSensorsForTrainingModal";
import SelectSensorsToTakeOutOfTrainingMode from "../../applications/DeviceActionComponents/SelectSensorsToTakeOutOfTrainingMode";
import SelectPositionsForAutoThresholdModal from "../../applications/DeviceActionComponents/SelectPositionsForAutoThresholdModal";
import trackWindowSize from "../../mixins/trackWindowSize";
import Area from "../../dataClasses/Area";
import iconFor from "../../icons";
import EditThresholdsModal from "../Dialogs/Modals/EditThresholdsModal";

export default {
  name: "AreaActionsDropdown",
  mixins: [trackWindowSize],
  components: {
    EditThresholdsModal,
    SelectSensorsForTrainingModal,
    SelectPositionsForAutoThresholdModal,
    SelectSensorsToTakeOutOfTrainingMode,
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    dropdownPosition: {
      type: String,
      default: "is-bottom-right",
    },
    label: {
      type: String,
      default: "I'd like to:",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * @property {Boolean}
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * @property {Area}
     */
    area: {
      type: Area,
      required: true,
    },
    /**
     * @property {Array<GatewayPosition>}
     */
    gatewayPositions: {
      type: Array,
      default: () => [],
    },
    /**
     * @property {Array<Position>}
     */
    positions: {
      type: Array,
      default: () => [],
    },
    autoNextRoute: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    qParams() {
      return this.autoNextRoute ? { next: this.$route.fullPath } : {};
    },
    autoThresholdModalOpen: {
      get() {
        return Object.hasOwn(this.$route.query, "autoThresholdModal");
      },
      set(val) {
        val
          ? this.queryReplace({ autoThresholdModal: true })
          : this.queryRemoveKeys(["autoThresholdModal"]);
      },
    },
    showThresholdModal: {
      get() {
        return Object.hasOwn(this.$route.query, "showThresholdModal");
      },
      set(showThresholdModal) {
        showThresholdModal
          ? this.queryReplace({ showThresholdModal })
          : this.queryRemoveKeys(["showThresholdModal"]);
      },
    },
    trainingSensorsModalOpen: {
      get() {
        return Object.hasOwn(this.$route.query, "trainingModal");
      },
      set(val) {
        val
          ? this.queryReplace({ trainingModal: true })
          : this.queryRemoveKeys(["trainingModal"]);
      },
    },
    trainingOffSensorsModalOpen: {
      get() {
        return Object.hasOwn(this.$route.query, "trainingOffModal");
      },
      set(val) {
        val
          ? this.queryReplace({ trainingOffModal: true })
          : this.queryRemoveKeys(["trainingOffModal"]);
      },
    },
  },
  methods: {
    iconFor,
    /**
     *
     * @param {Promise} promise
     * @param {String|null} message
     */
    promisary(promise, message = "Action complete") {
      this.$emit("loading:update", true);
      promise
        .then(() => {
          this.$handleSuccess(message);
        })
        .catch((e) => {
          this.$handleError("Could not complete action; see console", e);
        })
        .finally(() => {
          // self.loadingAction = false;
          this.$emit("loading:update", false);
        });
    },
    /**
     *
     * @param evt {{createModel: Boolean}}
     */
    handleTrainingModeOffSensorsModalSubmit(evt) {
      const self = this,
        createModel = evt.createModel,
        message = createModel
          ? "and a model build has been initiated"
          : "without building a model";
      self.promisary(
        self.area
          .$exitTrainingMode(createModel)
          .then(() => self.area.refresh())
          .then((area) => {
            this.$store.commit("add", {
              name: "areas",
              result: area,
              hideFetching: true,
            });
            return Promise.resolve();
          })
          .then(() => {
            this.$emit('refresh:positions');
            if (this.$route.query.next) {
              return this.$router.push(this.$route.query.next);
            } else {
              return Promise.resolve();
            }
          }),
        `Sensors have been taken out of training mode ${message}`
      );
    },
    handleTrainingSensorsModalSubmit(sensorRefs) {
      const self = this;
      if (!sensorRefs.length) {
        this.$buefy.toast.open({
          message: "No sensors selected",
        });
      }
      self.promisary(
        self.area
          .$enterTrainingMode({
            devices: sensorRefs,
          })
          .then(() => self.area.refresh())
          .then((area) => {
            this.$store.commit("add", {
              name: "areas",
              result: area,
              hideFetching: true,
            });
            return Promise.resolve();
          })
          .then(() => {
            this.$emit("refresh:positions")
            if (this.$route.query.next) {
              return this.$router.push(this.$route.query.next);
            } else {
              return Promise.resolve();
            }
          }),
        `${sensorRefs.length} sensor${
          sensorRefs.length === 1 ? " has" : "s have"
        } been put into training mode`
      );
    },
    /**
     *
     * @param {Array} sensorRefs: Array of references to /sensors docs
     */
    handleAutoThresholdModalSubmit(sensorRefs) {
      const self = this;
      self.promisary(
        self.area.$startAutoThreshold({ devices: sensorRefs }),
        `Auto-threshold loop started for ${sensorRefs.length} sensor${
          sensorRefs.length === 1 ? "" : "s"
        }`
      );
    },
  },
};
</script>

<style scoped></style>
