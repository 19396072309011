<template>
  <b-table
    :loading="loading"
    :checkable="checkable"
    :checked-rows.sync="checkedRows"
    :default-sort="['floor', 'unit', 'name']"
    v-if="rows.length"
    :data="rows"
  >
    <b-table-column field="$displayString" label="Name" sortable v-slot="props" :custom-sort="alphanumericSort">
      {{ props.row.$displayString }}
    </b-table-column>
    <slot name="columns">
      <b-table-column v-slot="props" sortable label="Type" field="type">
        {{ props.row.type ? props.row.type : "None" }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        sortable
        label="Floor"
        field="floor"
        numeric
      >
        {{ props.row.floor ? props.row.floor : "None" }}
      </b-table-column>
      <b-table-column v-slot="props" sortable label="Unit/Suite" field="unit">
        {{ props.row.unit ? props.row.unit : "None" }}
      </b-table-column>
      <b-table-column v-slot="props" label="POUs">
        {{ sourcesFor(props.row).length }}
      </b-table-column>
      <b-table-column v-slot="props" label="PLs">
        {{ plsFor(props.row).length }}
      </b-table-column>
    </slot>
    <slot name="select-column">
      <b-table-column v-slot="props">
        <b-button
          @click="
            $router.push({
              name: 'administration:view-room',
              params: { id: props.row.id },
            })
          "
          rounded
          type="is-primary is-outlined"
          size="is-small"
          class="is-pulled-right"
          icon-right="chevron-right"
          >Select</b-button
        >
      </b-table-column>
    </slot>
  </b-table>
</template>

<script>
import { getChildrenOfIdByKey } from "../scripts/filterHelpers";

export default {
  name: "RoomTable",
  data() {
    return {
      checkedRows: [],
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
  methods: {
    sourcesFor(room) {
      return getChildrenOfIdByKey(
        this.$store.state.sources,
        room.id,
        [],
        "room"
      );
    },
    plsFor(room) {
      return this.$store.state.pipe_locations.filter(
        (pl) => pl.room && pl.room.id === room.id
      );
    },
  },
};
</script>

<style scoped></style>
