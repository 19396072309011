<template>
  <div :class="classes">
    <slot name="pre-icon" />
    <b-icon :icon="icon" v-if="showIcon" />
    {{ value.$getDisplayString({showUnit, showFloor}) }}
    <slot>
      <span v-if="showLinkIcon" :class="{'is-pulled-right mr-3':!linkIconNoPull}" >
        <b-icon
          :icon="linkIcon"
          class="pointer"
          type="is-link"
          @click.native="
            $router.push(getLinkFunction(value));
            $event.preventDefault();
          "
        />
      </span>
    </slot>
    <slot name="end" />
  </div>
</template>

<script>
import iconFor from "../../icons";

export default {
  name: "Room",
  computed: {
    icon() {
      return iconFor("room");
    },
    floorDisplay() {
      return this.value.floor;
    },
  },
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
    linkIconNoPull: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    showUnit: {
      type: Boolean,
      default: true,
    },
    linkIcon: {
      type: String,
      default: iconFor("update"),
    },
    showFloor: {
      type: Boolean,
      default: true,
    },
    getLinkFunction: {
      type: Function,
      default: function (src) {
        return this.$router.match({
          name: "administration:view-room",
          params: { id: src.id },
          query: {
            loadScope: src.site_id_str,
          },
        });
      },
    },
  },
};
</script>

<style scoped>
.inline-block {
  display: inline-block;
}
</style>
