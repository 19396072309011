<template>
  <b-modal
      ref="modal"
      has-modal-card
      class="is-select-modal has-overflow"
      :can-cancel="
      $store.state.selectedScopeIdStr ? ['escape', 'x', 'outside'] : []
    "
      :active="active || cardLoading"
      @close="$emit('close')"
      @cancel="$emit('close')"
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title" v-if="cardLoading">Fetching site data</div>
        <div class="modal-card-title" v-else>Load a new site</div>
      </div>
      <div class="modal-card-body">
        <b-message
            :type="'is-default'"
        >
          <div
              v-if="$store.getters.topLevel.object"
              class="mb-5 subtitle has-text-centered"
          >
            Currently selected -
            <b>
              <router-link
                  :to="{ name: 'administration:hierarchy' }"
                  v-on:click.native="$store.dispatch('hideSelectScopeModal')"
              >
                {{ $store.getters.topLevel.object.name }}
              </router-link>
            </b>
            (<i>{{ $store.getters.topLevel.readableType }}</i
          >)
            <b-icon
                icon="home"
                type="is-primary"
                v-if="
                $store.state.selectedScopeIdStr ===
                $store.state.user_account.default_site_id_str
              "
            />
            <div
                class="has-text-centered mt-1"
                v-if="!cardLoading && $store.state.user_account && $store.state.user_account.default_site_id_str"
            >
              <b-button
                  size="is-small"
                  :disabled="$store.state.selectedScopeIdStr === $store.state.user_account.default_site_id_str"
                  @click="selectEntityForScope({id_str:$store.state.user_account.default_site_id_str})"
              >
                <span v-if="$store.state.selectedScopeIdStr === $store.state.user_account.default_site_id_str">
                  Your default site is loaded
                </span>
                <span v-else>
                  Load default site
                </span>
              </b-button>
            </div>
          </div>

          <transition-group
              v-if="cardLoading"
              class="card-loader"
              tag="div"
              name="slide"
          >
            <b-progress
                type="is-light"
                size="is-medium"
                show-value
                v-for="level in ['customers','subcustomers','sites','areas','sources','pipe_locations',].filter((l) => $store.state.fetching[l])"
                :key="level"
            >
              Loading <b>{{ level.replaceAll("_", " ") }}</b
            >...
            </b-progress>
          </transition-group>
          <loading-chart-card v-else-if="loading"/>
          <div
              class="mt-5"
              v-else-if="
              $store.state.selectedScopeIdStr && !showSelection && !cardLoading
            "
          >
            <b-button expanded type="is-warning" @click="showSelection = true"
            >Change your working scope
            </b-button>
          </div>
          <div class="mt-5" v-else>
            <div>
              <div v-if="!cardLoading">
                <div v-if="hasAnyRoles" class="mt-3 box">
                  <customer-hierarchy-selection
                      :cascade-select="false"
                      @lowest-selected="selectEntityForScope($event)"
                      down-to-scope="site"
                      :down-to-editable="false"
                      :allow-at-root="$store.state.user_roles.is_root"
                  >
                    <template #down-to-text>
                      <div>
                        <b-field grouped class="mt-3" message="Check here to set your selection as your default site">
                          <b-checkbox v-model="setAsDefault"/>
                          <b-field label="Set as default" @click.native="setAsDefault = !setAsDefault" class="pointer"/>
                        </b-field>
                      </div>
                    </template>
                  </customer-hierarchy-selection>

                  <b-message
                      type="is-info"
                      class="mt-6"
                      title="Load a site"
                      has-icon
                      icon="info-circle"
                      :closable="false"
                  >
                    <p>
                      Select a site to load from the above dropdowns.
                    </p>
                    <p class="mt-3">
                      Your available sites are determined by the roles/permissions set for
                      <router-link :to="{name:'profile:edit', params: {userId: $store.state.user.uid}}">
                        your user account
                      </router-link>.
                    </p>
                  </b-message>
                </div>
                <div v-else-if="$store.state.userTokenLoading" class="has-text-centered">
                  <b-icon icon="sync" class="fa-spin mb-4" />
                </div>
                <div v-else-if="$store.state.user && $store.state.user.email">
                  <span class="has-text-danger">
                    Warning:
                  </span>
                  You have no permissions to view anything for the account associated with email address
                  <b>{{ $store.state.user.email }}</b>.
                  <div class="mt-3">
                    Please contact your administrator if you believe this is in error.
                  </div>
                  <div class="mt-3">
                    <b-button @click="$router.push({name:'logout'})">Log out</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-message>
      </div>
      <div class="modal-card-foot" v-if="$store.state.selectedScopeIdStr">
        <b-button @click="close()">Cancel</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";
import {mapState} from "vuex";
import LoadingChartCard from "../../components/Skeletons/LoadingChartCard";


export default {
  name: "SelectSelectedScope",
  components: {
    LoadingChartCard,
  },
  mixins: [trackWindowSize],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setAsDefault: false,
      showSelection: false,
      tableSelectedObject: this.$store.getters.topLevel.object,
      loading: false,
      openedDetailed: [],
    };
  },
  created() {
    this.autoLoad();
  },
  computed: {
    ...mapState(["fetching", "user_roles"]),
    hasAnyRoles() {
      return Object.values(this.hasAnyRolesFor).some(i => !!i) || this.$store.state.user_roles.is_root;
    },
    cardLoading() {
      return Object.values(this.fetching).filter(a=>!!a).length > 0;

    },
    pathTypeArray() {
      return (this.user_roles.paths || []).map((accessPath) =>
          this.$store.getters.singleNameByIdString(accessPath)
      );
    },
    hasAnyRolesFor() {
      return {
        customers: this.pathTypeArray.includes("customer"),
        subcustomers: this.pathTypeArray.includes("subcustomer"),
        sites: this.pathTypeArray.includes("site"),
      };
    },
  },
  methods: {
    setDefaultSite(site) {
      return this.$dataClasses.UserAccount.save(this.$store.state.user_account.id, {
        default_site_id_str: site ? site.id_str : null,
      })
          .then((result) => {
            this.$store.commit("setUserAccount", result);
            return Promise.resolve();
          })
          .then(() => {
            let message = `Your default site has been ${site ? 'set to ' + site.name : 'unset'}`;
            this.$handleSuccess(message);
          })
          .catch((e) =>
              this.$handleError("Could not update default site, see console", e)
          );
    },
    selectEntityForScope(entity) {
      let self = this,
          setDefault = this.setAsDefault;
      if (
          self.selectedScopeIdStr &&
          self.selectedScopeIdStr === entity.id_str
      ) {
        self.showSelection = false;
        self.close();
        return;
      }
      self.loading = true;
      this.queryReplace({loadScope: entity.id_str})
      this.$store.commit("set", {name: "selectedScopeIdStr", value: null});
      return this.$store
          .dispatch("setScopeFromIdStr", {
            id_str: entity ? entity.id_str : null,
            onFinally: () => {
              self.tableSelectedObject = this.$store.getters.topLevel.object;
              self.$emit("select", entity);
              self.loading = false;
              self.close();
            },
          })
          .then(() => {
            if (setDefault) {
              this.setDefaultSite(entity);
            }
          });
    },
    close() {
      this.$emit("close");
    },
    autoLoad() {
      if (
          !this.$store.state.selectedScopeIdStr &&
          this.$store.state.user_roles.hasSingleScopeAtSiteOrLowerLevel
      ) {
        let entityId = this.$store.state.user_roles.roleData[0].access
            .split("|")
            .at(-1);
        this.selectEntityForScope({
          id: entityId,
          id_str: this.$store.state.user_roles.roleData[0].access,
          name: "...",
        });
      }
    },
  },
  watch: {
    cardLoading: {
      handler: function (isLoading, wasLoading) {
        this.$emit("loading", isLoading);
        if (!isLoading && wasLoading && this.active) {
          this.autoLoad();
        }
      },
    },
    "$store.state.user_roles": {
      handler(val) {
        if (val) {
          this.autoLoad();
        }
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler(val) {
        this.openedDetailed = [];
        this.showSelection = !!val;
      },
    },
    active: {
      handler(isActive, wasActive) {
        if (isActive && !wasActive) {
          this.setAsDefault = false;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../mixins/buefy/styles";

.is-select-modal {
  z-index: 50 !important;
  @media screen and (max-width: $desktop - 1px) {
    zoom: 80%;
    padding: 0 20px;
  }
}

.progress-wrapper {
  min-height: 12px;
}
</style>
