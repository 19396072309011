<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Ground Truth</div>
        <div class="card-header-icon">
          <b-icon :icon="iconFor('ground_truth')" />
        </div>
      </div>
      <div class="card" v-if="selectedSite">
        <div class="card-content">
          <div class="columns">
            <div class="column is-one-third-desktop" v-show="isFullHD">
              <b-message
                has-icon
                title="About ground truth"
                type="is-info"
                :closable="false"
              >
                Link to wiki here
              </b-message>
            </div>
            <div class="column is-12-tablet is-two-thirds-fullhd">
              <ground-truth-filters
                :buildings="buildings"
                :sensor-serials="sensorSerials"
                :disabled="loading"
              />
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="mb-3 is-flex">
                  <i v-if="loading">
                    <b-icon icon="spinner" class="fa-spin" />
                    Loading
                  </i>
                  <div v-else>
                    <span>
                      Viewing
                      {{
                        filteredGTLength === groundTruth.length
                          ? groundTruth.length
                          : `${filteredGTLength} (filtered) of ${groundTruth.length}`
                      }}
                      ground truth records at site
                    </span>
                    <site-display
                      v-if="!loading"
                      :value="selectedSite"
                      classes="inline-block"
                    />
                    .
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <b-field
                label="Viewing ground truth summary by area"
              />
              <building-table
                :rows="selectedBuilding ? [selectedBuilding] : buildings"
                detailed
                detail-key="areaId"
                :opened-detailed="areaIdsOpened"
                @details-open="buildingRowOpened($event)"
                @details-close="buildingRowClosed($event)"
                :has-select-column="false"
              >
                <template #first-column>
                  <b-table-column
                    label="Area"
                    field="name"
                    sortable
                    v-slot="props"
                  >
                    <building-display :value="props.row" />
                  </b-table-column>
                </template>
                <template #pre-select-column>
                  <b-table-column
                    label="Ground truth records"
                    v-slot="props"
                    numeric
                  >
                    <b-icon icon="spinner" class="fa-spin" v-if="loading" />
                    <span v-else>{{
                      loadedGtForArea(get(props, "row.area.id", "!")).length
                    }}</span>
                  </b-table-column>
                </template>
                <template #column-floors><span /></template>
                <template #detail="props">
                  <ground-truth-table
                    v-if="
                      props.props &&
                      props.props.row &&
                      props.props.row.area &&
                      props.props.row.area.id
                    "
                    :rows="
                      loadedGtForArea(get(props, 'props.row.area.id', '!'))
                    "
                    :loading="loading"
                    :checked-rows="checkedByArea[props.props.row.area.id] || []"
                    @check="checked(props.props.row.area.id, $event)"
                    @update="mergeUpdatedGroundTruth($event)"
                    @delete="removeGroundTruth($event)"
                  />
                  <p
                    v-if="
                      props.props &&
                      props.props.row &&
                      props.props.row.area &&
                      props.props.row.area.id
                    "
                    class="is-pulled-right mb-6"
                  >
                    <b-button
                      :loading="loadingModelRebuild"
                      type="is-warning"
                      @click="rebuildModel(props.props.row.area.id)"
                      icon-left="clock"
                    >
                      Rebuild prediction models
                    </b-button>
                  </p>
                </template>
              </building-table>

              <div class="level mt-3" v-if="!loading">
                <div class="level-left"></div>
                <div class="level-right">
                  <div class="level-item">
                    <b-field class="buttons">
                      <b-button
                        type="is-primary"
                        title="Re-load ground truth"
                        class=" pointer"
                        icon-left="sync"
                        @click="getGroundTruthForSite(selectedSite, true)"
                        v-if="selectedSite && $store.state.user_roles.is_root"
                        :loading="loading"
                      />
                      <b-button
                        type="is-danger"
                        icon="times"
                        :disabled="
                          !checkedRows ||
                          !checkedRows.length ||
                          checkedRows.length > 10
                        "
                        @click="openDeleteDialog()"
                      >
                        Delete checked ({{ checkedRows.length }})
                      </b-button>
                      <b-button
                          type="is-primary is-outlined"
                          @click="checkedByArea = {}"
                          v-if="checkedRows && checkedRows.length"
                        >
                          Clear selection
                        </b-button>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { cloneDeep, get, sum, uniqBy } from "lodash";

import { getObjectInArrayById } from "../../../scripts/filterHelpers";

import { mergeById } from "../../../store/shared";

import iconFor from "../../../icons";
import trackWindowSize from "../../../mixins/trackWindowSize";
import AreaTable from "../../../tables/AreaTable";
import BuildingTable from "../../../tables/BuildingTable";
import GroundTruthFilters from "./GroundTruthFilters";
import GroundTruthTable from "../../../tables/GroundTruthTable";

export default {
  name: "GroundTruth",
  mixins: [trackWindowSize],
  components: {
    AreaTable,
    BuildingTable,
    GroundTruthFilters,
    GroundTruthTable,
  },
  data() {
    return {
      groundTruth: [],
      loading: false,
      loadingModelRebuild: false,
      filters: [],
      rows: [],
      firstLoad: true,
      checkedByArea: {},
    };
  },
  mounted() {
    if (this.selectedSite) {
      this.getGroundTruthForSite(this.selectedSite);
    }
  },
  computed: {
    ...mapState(["sites"]),
    areas() {
      return this.selectedSite
        ? this.$store.state.areas.filter(
            (a) => a.site && a.site.id === this.selectedSite.id
          )
        : [];
    },
    areaIdsWithGroundTruth() {
      let areaIds = this.groundTruth
        .filter((gt) => gt && gt.location_reference)
        .map((gt) => gt.location_reference.id);
      return [...new Set(areaIds)];
    },
    areaIdsOpened() {
      return this.$route.query.detailAreas
        ? this.$route.query.detailAreas.split(",").filter((a) => a)
        : [];
    },
    buildings() {
      let b = [];
      if (this.selectedSite) {
        b = cloneDeep(
          this.$store.state.buildings.filter(
            (b) => b.site && b.site.id === this.selectedSite.id
          )
        );
      }
      b.forEach((_b) => (_b.areaId = _b.area ? _b.area.id : null));
      return b;
    },
    filterCallables() {
      let filters = [],
        buildingSelected =
          this.inputs.buildingId && this.inputs.buildingId !== "ALL",
        qualityLevelSelected =
          this.inputs.qualityLevel && this.inputs.qualityLevel.length,
        roomSelected = this.inputs.roomId && this.inputs.roomId !== "ALL",
        onlyShowNoRoomSources = this.inputs.roomId === "NOT_ASSIGNED",
        sensorSelected =
          this.inputs.sensorSerial && this.inputs.sensorSerial !== "ALL",
        sourceSelected = this.inputs.sourceId && this.inputs.sourceId !== "ALL";

      if (qualityLevelSelected) {
        filters.push((obj) => {
          return (
            obj &&
            obj.evaluation &&
            this.inputs.qualityLevel.includes(obj.evaluation.quality)
          );
        });
      }
      if (sensorSelected) {
        filters.push((obj) => {
          return (
            obj &&
            obj.information &&
            obj.information.sensor_serial === this.inputs.sensorSerial
          );
        });
      }
      if (onlyShowNoRoomSources) {
        filters.push((obj) => {
          return (
            obj &&
            obj.information &&
            obj.information.source &&
            this._noRoomSources
              .map((src) => src.id)
              .includes(obj.information.source.id)
          );
        });
      } else if (roomSelected) {
        filters.push((obj) => {
          return (
            obj &&
            obj.information &&
            obj.information.actual_source &&
            this._sources
              .map((src) => src.id)
              .includes(obj.information.actual_source.replace("SRC:", ""))
          );
        });
      }
      if (buildingSelected) {
        let building = getObjectInArrayById(
            this.$store.state.buildings,
            this.inputs.buildingId,
            null
          ),
          area =
            building && building.area
              ? getObjectInArrayById(
                  this.$store.state.areas,
                  building.area.id,
                  null
                )
              : null;
        if (area && area.id) {
          filters.push((gt) => {
            return (
              gt &&
              gt.location_reference &&
              gt.location_reference.id === area.id
            );
          });
        }
      }
      if (sourceSelected) {
        filters.push((obj) => {
          return (
            obj &&
            obj.information &&
            obj.information.actual_source === "SRC:" + this.inputs.sourceId
          );
        });
      }
      return filters;
    },
    filteredGTLength() {
      let it = this.areas.map((a) => this.loadedGtForArea(a.id).length);
      return sum(it);
    },
    inputs() {
      const queryKeys = [
        "qualityLevel",
        "buildingId",
        "roomId",
        "sensorSerial",
        "sourceId",
      ];
      let entries = Object.entries(this.$route.query).filter((kvArr) =>
        queryKeys.includes(kvArr[0])
      );
      return Object.fromEntries(entries);
    },
    predModelDates() {
      return this.predictionModels.map((pm) => pm.post_dt);
    },
    _sources() {
      let self = this,
        sources = self.selectedSite
          ? self.$store.state.sources.filter(
              (src) => src.site && src.site.id === self.selectedSite.id
            )
          : self.$store.state.sources;

      if (self.inputs.buildingId && self.inputs.buildingId !== "ALL") {
        let building = getObjectInArrayById(
            this.$store.state.buildings,
            this.inputs.buildingId,
            null
          ),
          area =
            building && building.area
              ? getObjectInArrayById(
                  this.$store.state.areas,
                  building.area.id,
                  null
                )
              : null;
        sources = sources.filter((src) => src.area && src.area.id === area.id);
      }
      sources = sources.filter((src) => {
        if (self.inputs.roomId === "NOT_ASSIGNED") {
          return !src.room;
        } else if (self.inputs.roomId && self.inputs.roomId !== "ALL") {
          return src.room && src.room.id === self.inputs.roomId;
        } else {
          return true;
        }
      });
      return sources;
    },
    _noRoomSources() {
      return this.$store.state.sources.filter((src) => !src.room);
    },
    sensorSerials() {
      return uniqBy(
        this.groundTruth.filter(
          (gt) => gt && gt.information && gt.information.sensor_serial
        ),
        (obj) => obj.information.sensor_serial
      ).map((gt) => gt.information.sensor_serial);
    },
    viewAreaOrBuilding() {
      return "building";
    },
    selectedSite() {
      return this.$store.getters.site;
    },
    selectedArea() {
      let matches = [];
      if (
        this.viewAreaOrBuilding === "area" &&
        this.inputs.buildingId &&
        this.inputs.buildingId !== "ALL"
      ) {
        matches = this.$store.state.areas.filter(
          (a) => a && a.building && a.building.id === this.inputs.buildingId
        );
      }
      return matches.length ? matches[0] : null;
    },
    selectedBuilding() {
      let matches = [];
      if (
        this.viewAreaOrBuilding === "building" &&
        this.inputs.buildingId &&
        this.inputs.buildingId !== "ALL"
      ) {
        matches = this.buildings.filter((b) => b.id === this.inputs.buildingId);
      }
      return matches.length ? matches[0] : null;
    },
    sources() {
      return this.selectedArea
        ? this.$store.state.sources
            .filter((s) => s.area && s.area.id === this.selectedArea.id)
            .filter((s) => !(s && s.generic_name === "noflow source"))
        : [];
    },
    noFlowSources() {
      return this.sources.filter(
        (src) => src && src.generic_name === "noflow source"
      );
    },
    checkedRows() {
      let val = [],
        self = this;
      self.areas.forEach((a) => {
        if (self.checkedByArea[a.id]) {
          self.checkedByArea[a.id]
            .filter((v) => !!v)
            .forEach((gt) => {
              val.push(gt);
            });
        }
      });
      return val;
    },
  },
  methods: {
    get,
    getObjectInArrayById,
    iconFor,
    rebuildModel(areaId) {
      let self = this;
      self.loadingModelRebuild = true;
      this.$dataClasses.Area.fromStore(this.$store.state.areas, areaId)
        .performAction("create_model")
        .then(() => {
          self.$handleSuccess("Model rebuild has been scheduled");
        })
        .catch((e) => {
          self.$handleError("Could not schedule model rebuild; see console", e);
        })
        .finally(() => {
          self.loadingModelRebuild = false;
        });
    },
    openDeleteDialog() {
      let self = this;
      self.$buefy.dialog.confirm({
        title: "Delete ground truth?",
        type: "is-danger",
        message: `Are you sure you want to delete ${self.checkedRows.length} piece(s) of ground truth?`,
        onConfirm() {
          self.editingSource = false;
          self.loading = true;
          self.$dataClasses.GroundTruth.bulkDelete(self.checkedRows.map((r) => r.id))
            .then((res) => {
              self.$handleSuccess(res.message);
              let deletedIds = res.results.map((r) => r.id);
              self.checkedByArea = {};
              self.groundTruth = self.groundTruth.filter(
                (gt) => !deletedIds.includes(gt.id)
              );
            })
            .catch((e) =>
              self.$handleError(
                "Could not delete ground truth; See console for details",
                e
              )
            )
            .finally(() => {
              self.loading = false;
            });
        },
      });
    },
    checked(areaId, rows) {
      this.checkedByArea[areaId] = rows;
      this.checkedByArea = cloneDeep(this.checkedByArea);
    },
    areaRowOpened(row) {
      let query = this.$route.query ? cloneDeep(this.$route.query) : {},
        self = this;
      if (
        self.$route.query.detailAreas &&
        self.$route.query.detailAreas.length > 1
      ) {
        query.detailAreas = query.detailAreas + "," + row.id;
      } else {
        query.detailAreas = row.id;
      }
      self.$router.replace({ query }).catch(() => {});
    },
    areaRowClosed(row) {
      let query = this.$route.query ? cloneDeep(this.$route.query) : {};
      if (
        this.$route.query.detailAreas &&
        this.$route.query.detailAreas.length
      ) {
        query.detailAreas = query.detailAreas
          .replace(row.id + ",", "")
          .replace(row.id, "");
      }
      this.$router.replace({ query }).catch(() => {});
    },
    buildingRowClosed(row) {
      if (row && row.area && row.area.id) {
        this.areaRowClosed(row.area);
      }
    },
    buildingRowOpened(row) {
      if (row && row.area && row.area.id) {
        this.areaRowOpened(row.area);
      }
    },
    getGroundTruthForSite(site, showSuccess) {
      if (!this.selectedSite || !this.$store.state.sources || !this.$store.state.sources.length) {
        this.groundTruth = [];
        return;
      }
      let self = this;
      self.loading = true;
      self.groundTruth = [];
      self.$dataClasses.GroundTruth.query(
        [
          ["information.source", "in", self.$store.state.sources.map(src => self.toFSRef(src, 'sources'))],
        ],
        {
          paginate_by: 1000,
          order_field: "information.source",
        }
      )
        .then((results) => {
          // remove based on noflow sources?
          self.groundTruth = results.filter((gt) => true);
          if (showSuccess) {
            self.$handleSuccess("Loaded " + results.length + " ground truth records");
          }
        })
        .catch((e) => {
          self.$handleError("Could not load ground truth; See console for detail", e);
          self.groundTruth = [];
        })
        .finally(() => {
          self.loading = false;
        });
    },
    // getPredictionModelsForSite(site, showSuccess) {
    //   let self = this;
    //   self.loadingPredictionModels = true;
    //   self.predictionModels = [];
    //   GroundTruth.query([
    //       ["for", "==", self.toFSRef(site, "sites")]
    //
    //   ], {
    //     paginate_by: 1000,
    //   })
    //     .then((results) => {
    //       self.predictionModels = results;
    //     })
    //     .catch((e) => {
    //       this.$handleError(
    //         "Could not load associated model details; See console for details",
    //         e
    //       );
    //       self.predictionModels = [];
    //     })
    //     .finally(() => (self.loadingPredictionModels = false));
    // },
    loadedGtForArea(areaId) {
      return this.groundTruth.filter((gt) => {
        return (
          gt &&
          gt.location_reference &&
          gt.location_reference.id === areaId &&
          (!this.filterCallables ||
            (this.filterCallables && !this.filterCallables.length) ||
            this.filterCallables.every((func) => func(gt)))
        );
      });
    },
    mergeUpdatedGroundTruth(groundTruthRecord) {
      this.groundTruth = mergeById(this.groundTruth).with([groundTruthRecord]);
      this.$handleSuccess("Ground truth record updated");
    },
    removeGroundTruth(deletedGTObj) {
      this.groundTruth = this.groundTruth.filter(
        (gt) => gt.id !== deletedGTObj.id
      );
      this.$handleSuccess("Ground truth record deleted");
    },
    showAllSites() {
      let query = cloneDeep(this.$route.query);
      query.siteId = null;
      query.detailAreas = null;
      this.$router
        .replace({
          name: "utilities:ground-truth",
          query,
        })
        .catch(() => {});
    },
    selectViewBy(viewBy, query) {
      if (!query) {
        query = this.$route.query ? cloneDeep(this.$route.query) : {};
      }
      query.viewBy = viewBy;
      this.$router.replace({ query }).catch(() => {});
    },
  },
  watch: {
    checkedRows: {
      handler(val, oldVal) {
        if (val.length > 10) {
          let idsToUncheck = val
            .map((r) => r.id)
            .filter((id) => !oldVal.map((r) => r.id).includes(id));
          Object.keys(this.checkedByArea).forEach((aId) => {
            this.checkedByArea[aId] = this.checkedByArea[aId].filter(
              (r) => !idsToUncheck.includes(r.id)
            );
          });
          this.$handleError("You cannot check more than 10 rows at a time");
        }
      },
    },
    "$store.state.sources": {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.getGroundTruthForSite(val);
        }
      },
    },
    "inputs.buildingId": {
      handler(val, oldVal) {
        let query = cloneDeep(this.$route.query);
        if (val && val !== "ALL") {
          let building = getObjectInArrayById(
            this.$store.state.buildings,
            val,
            {}
          );
          query.detailAreas = building.area ? building.area.id : null;
        } else if (oldVal && oldVal !== "ALL") {
          query.detailAreas = val === "ALL" ? null : query.detailAreas;
        } else {
          return;
        }
        this.$router.replace({ query: query }).catch(() => {});
      },
    },
  },
};
</script>

<style scoped>
.field.has-addons,
:deep(.field.has-addons) {
  display: block !important;
}

.inline-block {
  display: inline-block;
}
</style>
