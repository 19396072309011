<template>
  <div>
    <div v-if="$store.getters.loaded">
      <span v-for="(value, key) in hierarchyObjs" :key="key">
        <div v-if="key === 'source' && sourceIsUnrecognized">
          <b-icon size="is-small" :icon="iconFor('source')" class="ml-1" />
          <span class="ml-2" style="font-style: italic"
            >Unrecognized source</span
          >
        </div>
        <pipe-location-display
          v-else-if="key === 'pipe_location' && value"
          :value="value"
          :show-link-icon="showLinkIcons"
          :show-tooltip="showTooltip"
          show-temp-icon
        />
        <source-display
          v-else-if="key === 'source' && value && value.name"
          :value="value"
          :show-link-icon="showLinkIcons"
          :show-tooltip="showTooltip"
          show-temp-icon
        />
        <room-display
          v-else-if="key === 'room' && value && value.name"
          :value="value"
          :show-link-icon="showLinkIcons"
          show-unit
          show-floor
        />
        <building-display
          v-else-if="key === 'building' && value && value.name"
          :show-floors="showFloors"
          :value="value"
          :show-link-icon="showLinkIcons"
          show-training-mode-warning
          :show-training-mode-tooltip="isDetail"
        />
        <site-display
          v-else-if="key === 'site' && value"
          :value="value"
          :show-address="showAddress"
          :show-link-icon="showLinkIcons"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import { mapState } from "vuex";
import iconFor from "../../icons";

export default {
  name: "MessageBreadcrumb",

  computed: {
    ...mapState([
      "customers",
      "subcustomers",
      "sites",
      "areas",
      "buildings",
      "pipe_locations",
      "rooms",
      "sources",
      "user_roles",
    ]),
    showLinkIcons() {
      let siteIdStr = get(this.hierarchyObjs["site"], "id_str", "|||");
      return this.user_roles.match("installer", siteIdStr) && this.showLinks;
    },
    /**
     * ID to FS obj data
     * @return {{}}
     */
    hierarchyObjs() {
      let hier = {};
      if (
        !this.$store.getters.loaded ||
        !this.$store.state.selectedScopeIdStr
      ) {
        return hier;
      }
      if (this.sourceId || this.roomId || this.pipeLocationId) {
        let pipeLocation = this.pipeLocationId
            ? getObjectInArrayById(
                this.pipe_locations,
                this.pipeLocationId,
                null
              ) || null
            : null,
          source = this.sourceId
            ? getObjectInArrayById(this.sources, this.sourceId, null) || null
            : null,
          room =
            this.roomId ||
            (source && source.room) ||
            (pipeLocation && pipeLocation.room)
              ? getObjectInArrayById(
                  this.rooms,
                  this.roomId || get((source || pipeLocation).room, "id", null),
                  null
                )
              : null,
          building =
            room || source
              ? getObjectInArrayById(
                  this.buildings,
                  (room || source || pipeLocation).building,
                  {}
                )
              : null,
          site = getObjectInArrayById(
            this.sites,
            get(building || room || pipeLocation || source, "site.id", "")
          ),
          subcustomer = getObjectInArrayById(
            this.subcustomers,
            site.subcustomer.id,
            {}
          );
        hier["subcustomer"] = subcustomer;
        hier["site"] = site;
        hier["building"] = building;
        hier["room"] = room;
        hier["source"] = source;
        hier["pipe_location"] = pipeLocation;
      } else if (this.areaId) {
        let area = getObjectInArrayById(this.areas, this.areaId, {}),
          site = area.site
            ? getObjectInArrayById(this.sites, get(area, "site.id", {}), {})
            : {},
          subcustomer = area.subcustomer
            ? getObjectInArrayById(
                this.subcustomers,
                get(area, "subcustomer.id", {}),
                {}
              )
            : {},
          building =
            area.building && area.building.id
              ? getObjectInArrayById(
                  this.buildings,
                  this.toId(area.building),
                  {}
                )
              : {};
        hier["subcustomer"] = subcustomer;
        hier["site"] = site;
        hier["area"] = area;
        hier["building"] = building;
      }
      return hier;
    },
  },
  props: {
    areaId: {
      type: String,
      required: false,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showFloors: {
      type: Boolean,
      default: true,
    },
    showLinks: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    pipeLocationId: {
      type: String,
      required: false,
    },
    roomId: {
      type: String,
      required: false,
    },
    sourceId: {
      type: String,
      required: false,
    },
    sourceIsUnrecognized: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    iconFor,
  },
};
</script>

<style scoped></style>
