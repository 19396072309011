<template>
  <div>
    <div v-if="parent.name">
      Area will be added at site <i>{{ parent.name }}</i
      >.
    </div>
    <map-with-marker
      v-if="parent && parent.lat_long"
      :marker="{
        lat: parent.lat_long.latitude,
        lng: parent.lat_long.longitude,
      }"
    />
    <b-field label="Area name">
      <b-input
        type="text"
        minlength="3"
        v-model="inputs.name"
        placeholder="How do you refer to this building?"
        required
      />
    </b-field>
    <b-field group-multiline>
      <b-field label="Area type">
        <b-select v-model="inputs.type">
          <option disabled :value="null">Select below</option>
          <option v-for="type in buildingTypes" :key="type" :value="type">
            {{ type }}
          </option>
        </b-select>
      </b-field>
      <b-field class="ml-3" label="Suite (optional)">
        <b-input type="text" v-model="inputs.unit" placeholder="Optional" />
      </b-field>
    </b-field>
    <b-field group-multiline>
      <b-field label="Floors above ground">
        <b-numberinput
          v-model="inputs.floors"
          controls-alignment="right"
          min="0"
          max="150"
        />
      </b-field>
      <b-field class="ml-3" label="Floors below ground">
        <b-numberinput
          v-model="inputs.sub_floors"
          controls-alignment="right"
          min="0"
          max="30"
        />
      </b-field>
    </b-field>
  </div>
</template>

<script>
import MapWithMarker from "../../../components/MapWithMarker";
import { cloneDeep } from "lodash";

const buildingTypes = [
  "Commercial",
  "Industrial",
  "Mixed-use",
  "Multi-unit residential",
  "Office",
  "Single-family residential",
  "Other",
];

export default {
  name: "BuildingForm",
  components: { MapWithMarker },
  data() {
    let parentRef = this.toFSRef(cloneDeep(this.parent));
    return {
      inputs: Object.assign(
        {
          site: this.parent ? parentRef : null,
          name: "",
          unit: "",
          floors: 1,
          sub_floors: 0,
        },
        cloneDeep(this.value)
      ),
    };
  },
  computed: {
    isValid() {
      return this.inputs.name.length > 2;
    },
    buildingTypes() {
      return buildingTypes;
    },
  },
  props: {
    parent: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    getClearedData() {
      return Object.assign({
        site: this.inputs.site,
        name: this.inputs.name,
      });
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
      this.validates();
    },
    validates() {
      this.$emit("validates", this.isValid);
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        return this.onChange();
      },
    },
    "inputs.type": {
      handler(val) {
        if (!this.inputs.name && val) {
          this.inputs.name = val;
        }
      },
    },
  },
};
</script>

<style scoped></style>
