<template>
  <div>
    <div v-if="parent.name">
      Area will be added at site <i>{{ parent.name }}</i
      >.
    </div>
    <map-with-marker
      v-if="parent && parent.lat_long"
      :marker="{
        lat: parent.lat_long.latitude,
        lng: parent.lat_long.longitude,
      }"
    />
    <b-field label="Area name">
      <b-input
        type="text"
        minlength="3"
        v-model="inputs.name"
        placeholder="i.e. Kitchen, Garage, East Wing..."
        required
      />
    </b-field>
  </div>
</template>

<script>
import MapWithMarker from "../../../components/MapWithMarker";
import { cloneDeep } from "lodash";
import { mapState } from "vuex";

export default {
  name: "AreaForm",
  components: { MapWithMarker },
  data() {
    let parentRef = this.parent ? cloneDeep(this.toFSRef(this.parent)) : null;
    let val = cloneDeep(this.value);
    return {
      inputs: Object.assign(
        {
          parent: parentRef,
          rooms: val.rooms ? cloneDeep(val.rooms) : [],
        },
        val
      ),
    };
  },
  computed: {
    ...mapState(["buildings", "rooms"]),
    isValid() {
      return this.inputs.name.length > 2;
    },
    cloneDeep() {
      return cloneDeep;
    },
  },
  props: {
    parent: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    getClearedData() {
      return Object.assign(
        {
          parent: this.inputs.parent,
          name: this.inputs.name,
          rooms: [],
        },
        {}
      );
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
      this.validates();
    },
    validates() {
      this.$emit("validates", this.isValid);
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        return this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
