<template>
  <div>
    <b-field label="Customer name">
      <b-input minlength="3" required type="text" v-model="inputs.name" />
    </b-field>
  </div>
</template>

<script>
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import { cloneDeep } from "lodash";

export default {
  name: "CustomerForm",
  data() {
    return {
      inputs: this.id
        ? getObjectInArrayById(this.$store.state.customers, this.id)
        : cloneDeep(this.value),
    };
  },
  computed: {
    isValid() {
      return this.inputs.name.length >= 3;
    },
  },
  props: {
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    validates() {
      this.$emit("validates", this.isValid);
    },
    getClearedData() {
      return Object.assign(
        {
          name: this.inputs.name,
        },
        {}
      );
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
      this.validates();
    },
  },
  watch: {
    inputs: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
