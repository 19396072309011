<template>
  <span :class="classes">
    <span v-if="showNumber"> {{ convertedValue }} {{ unit }} </span>
    <span v-else>
      <i>{{ noValueDisplay }}</i>
    </span>
  </span>
</template>

<script>
import convert from "convert-units";

export default {
  name: "UnitConversionDisplay",
  props: {
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
    },
    noValueDisplay: {
      type: String,
      default: "n/a",
    },
    decimals: {
      type: Number,
      default: 2,
    },
    measurement: {
      type: String,
      required: true,
    },
  },
  computed: {
    showNumber() {
      return typeof this.value === "number";
    },
    unit() {
      return this.$store.getters.localUnit(this.measurement);
    },
    storedUnit() {
      return this.$store.getters.standardUnits(this.measurement, "_standard");
    },
    convertedValue() {
      let val = convert(this.value).from(this.storedUnit).to(this.unit);
      return Math.round(val * this.roundingNumber) / this.roundingNumber;
    },
    aliases() {
      return convert().possibilities(this.measurement);
    },
    roundingNumber() {
      return 10 ** this.decimals;
    },
  },
};
</script>

<style scoped></style>
