<template>
  <div class="card" v-if="site">
    <div class="card-header">
      <div class="card-header-title">
        <site-display :value="site" />
      </div>
    </div>
    <div class="card-content">
      <div class="media" v-if="!site.timezone">
        <div class="media-left">
          <b-icon type="is-danger" icon="exclamation-circle" size="is-large" />
        </div>
        <div class="media-content">
          You must
          <router-link
            :to="{
              name: 'administration:view-site',
              params: { id: site.id },
              query: { edit: null },
            }"
          >
            set a timezone for this site
          </router-link>
          before you may edit schedules.
        </div>
      </div>
      <b-loading v-if="loadingAll" active :is-full-page="false" />
      <div v-else-if="site.timezone">
        <div class="has-text-justified has-text-right-tablet has-text-right-desktop mb-2" style="zoom:85%;">
          <b-field size="is-small" label="Filter by area">
            <b-select v-model="areaId">
              <option :value="null">None</option>
              <option v-for="building in buildings" :key="building.id" :value="building.$area.id">{{building.$displayString}}</option>
            </b-select>
          </b-field>
        </div>

        <alarm-schedule-table
          :internal-area-id="areaId"
          @remove="
            allAlarmSchedules = $event.id
              ? allAlarmSchedules.filter((a) => a.id !== $event.id)
              : allAlarmSchedules.filter((a) => !!a.id)
          "
          :value.sync="allAlarmSchedules"
          :site="site"
        />
        <div class="mb-6">
          <b-button
            v-if="$store.state.user_roles.is_admin"
            class="is-pulled-right mt-3"
            :disabled="allAlarmSchedules.filter((a) => !a.id).length >= 1"
            :icon-left="iconFor('create')"
            @click="
              allAlarmSchedules.push(
                new $dataClasses.AlarmSchedule({
                  is_active: true,
                  name: '',
                  criteria: {},
                  schedule: {
                    description: '',
                    times: [],
                  },
                })
              )
            "
          >
            Add new
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chunk } from "lodash";
import iconFor from "../../icons";
import { mapState } from "vuex";

import { getObjectInArrayById} from "../../scripts/filterHelpers";
import AlarmScheduleTable from "../../tables/AlarmScheduleTable";
import { mergeById } from "../../store/shared";
import Site from "../../dataClasses/Site";

class Util {
  static emptyPromise(val = null) {
    return new Promise((resolve) => {
      resolve(val);
    });
  }
}

export default {
  name: "MessageSettingsForSite",
  components: { AlarmScheduleTable },
  mounted() {
    this.getRawSchedules();
  },
  data() {
    return {
      AlarmSchedule: this.$dataClasses.AlarmSchedule,
      allAlarmSchedules: [],
      loading: false,
      loadingAll: false,
      noflowLoading: false,
      schedules: [],
      noflowSchedules: [],
    };
  },
  props: {
    site: {
      type: Site,
      required: true,
    },
  },
  computed: {
    ...mapState(["sites", "user_roles"]),
    areas() {
      return this.$store.state.areas;
    },
    buildings() {
      return this.$store.state.buildings;
    },
    areaId: {
      get() {
        return this.$route.query.areaId
      },
      set(areaId) {
        areaId ? this.queryReplace({areaId}) : this.queryRemoveKeys(['areaId'])
      }
    },
    internalArea() {
      return this.areaId ? getObjectInArrayById(
          this.$store.state.areas,
          this.areaId,
          null
      ) : null
    }
  },
  methods: {
    iconFor,
    handleDeletion(componentKey, index) {
      this[componentKey].splice(index, 1);
    },
    getRawSchedules() {
      if (!this.site) {
        this.allAlarmSchedules = [];
        return;
      }
      let self = this;
      self.loadingAll = true;
      this.$dataClasses.AlarmSchedule.query([["site", "==", self.site.$FSRef]])
        .then((results) => {
          self.allAlarmSchedules = results || [];
          let areas = self.site.$areas.map((a) => a.$FSRef);
          let areaChunks = chunk(areas, 10);
          if (areas.length) {
            let promiseArray = [];
            for (const areaChunk of areaChunks) {
              promiseArray = areaChunks.map((areaChunk) =>
                this.$dataClasses.AlarmSchedule.query([["reference_path", "in", areaChunk]])
              );
            }
            return Promise.all(promiseArray);
          } else {
            return Util.emptyPromise([{ results: [] }]);
          }
        })
        .then((responseArray) => {
          for (const results of responseArray) {
            self.allAlarmSchedules = mergeById(self.allAlarmSchedules).with(
              results
            ).sort(self.alphanumericSortByProperty('$displayName'));
          }
        })
        .catch((e) => self.$handleError(e))
        .finally(() => {
          self.loadingAll = false;
        });
    },
  },
  watch: {
    site: {
      handler() {
        this.getRawSchedules();
      },
    },
    "$store.state.areas": {
      handler() {
        this.getRawSchedules();
      },
    },
  },
};
</script>

<style scoped>
.has-button-pulled-right {
  min-height: 30px;
}
</style>
