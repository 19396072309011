<template>
  <div>
    <transition name="slide">
      <div v-if="loading">
        <loading-card/>
      </div>
    </transition>
    <transition name="slide">
      <div v-if="!loading">
        <b-table
            :data="subscriptions"
        >
          <b-table-column field="id_str" label="Location" v-slot="props">
            {{ readableNameFromIDStr(props.row.id_str) }}
          </b-table-column>
          <b-table-column field="topics" label="Topics" v-slot="props" width="40%">
            <b-tag
                v-for="topic in props.row.topics"
                :key="topic"
                class="mr-1"
                @close="removeTopic(topic, props.row)"
                :type="
                currentlyDeleting(topic, props.row)
                  ? 'is-default'
                  : `is-${topic} is-light`
              "
                :closable="
                !currentlyDeleting(topic, props.row)
              "
                :disabled="currentlyDeleting(topic, props.row)"
            >
              {{ readableTopic(topic) }}
            </b-tag>
          </b-table-column>
          <b-table-column label="Edit" v-slot="props">
            <b-button
                @click="
                selectedSubscriptionLink = Object.assign({}, props.row);
                selectedSubscriptionLinkDownTo = typeGivenIDString(
                  props.row.id_str
                );
                modalActive = true;
              "
                type="is-primary"
                size="is-small"
                icon-left="edit"
            />
          </b-table-column>
          <template #empty>
            <div class="m-3 has-text-centered">
              <i>No subscriptions found for this user</i>
            </div>
          </template>
          <template #footer>
            <div class="has-text-right" :style="`opacity:${syncing.length?'1':'0'}; transition:.3s all;`">
              <b-icon icon="sync" class="fa-spin"/>
            </div>
          </template>
        </b-table>

        <div class="has-text-centered">
          <b-button
              @click="
              setInitialSelectedSubscriptionLink();
              modalActive = true;
            "
              type="is-primary"
              :disabled="!(topics && topics.length)"
              class="mb-5 mt-5"
          >
            Create a new subscription
          </b-button>
        </div>

      </div>
    </transition>

    <b-modal @close="modalActive=false" :active.sync="modalActive" has-modal-card can-cancel>
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title" v-if="selectedSubscriptionLink.id">
            Edit your subscription
            <span v-if="selectedSubscriptionLink.id_str">
              to
              {{ readableNameFromIDStr(selectedSubscriptionLink.id_str) }}</span
            >
          </div>
          <div class="modal-card-title" v-else>Create a new subscription</div>
        </div>
        <div class="modal-card-body">
          <b-loading :active="loading" :is-full-page="false"/>
          <div>
            <div v-if="selectedSubscriptionLink.id">
              <p v-if="selectedSubscriptionLink.id_str">
                You are currently editing your subscription to
                <b>{{
                    readableNameFromIDStr(selectedSubscriptionLink.id_str)
                  }}</b
                >.
              </p>
            </div>
            <div v-else>
              <customer-hierarchy-selection
                  :down-to-options="['site', 'building']"
                  :down-to-scope.sync="selectedSubscriptionLinkDownTo"
                  down-to-editable
                  @select="setSelectedIdFrom($event)"
                  v-show="!loading"
              />
              <p class="has-text-danger" v-if="selectedSubscriptionClashes()">
                You are already subscribed to
                {{ readableNameFromIDStr(selectedSubscriptionLink.id_str) }}.
                Changing the settings below may overwrite what is currently
                saved.
              </p>
            </div>
            <div>
              <b-field label="Topics">
                <div class="block">
                  <b-field v-for="topic in topics"
                           :key="topic">
                    <b-checkbox-button
                        class="newline"
                        v-model="selectedSubscriptionLink.topics"
                        :native-value="topic"
                        :disabled="loading"
                        :type="`is-${topic} is-light`"
                    >
                      <b-icon
                          icon="check"
                          v-if="(selectedSubscriptionLink.topics || []).includes(topic)"
                      />
                      {{ readableTopic(topic) }}
                    </b-checkbox-button>
                  </b-field>
                </div>
              </b-field>
              <b-button
                  class="mr-2 mb-3"
                  :disabled="
                  selectedSubscriptionLink.topics &&
                  selectedSubscriptionLink.topics.length >= topics.length
                "
                  @click="selectedSubscriptionLink.topics = topics"
                  :loading="loading"
              >
                Select all topics
              </b-button>
              <b-button
                  :disabled="
                  selectedSubscriptionLink.topics &&
                  selectedSubscriptionLink.topics.length === 0
                "
                  @click="selectedSubscriptionLink.topics = []"
                  :loading="loading"
              >
                Clear
              </b-button>
              <b-button
                  type="is-danger"
                  v-if="selectedSubscriptionLink.id"
                  class="is-pulled-right"
                  @click="deleteSubscriptionPrompt(selectedSubscriptionLink)"
                  :loading="loading"
              >
                Delete this subscription
              </b-button>
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <div class="card-footer-item">
            <b-button expanded @click="modalActive = false">Cancel</b-button>
          </div>
          <div class="card-footer-item">
            <b-button
                expanded
                type="is-primary"
                class="is-pulled-right"
                @click="submitSelected()"
                :disabled="!canSubmit"
                :loading="loading || syncing.length > 0"
            >
              Submit
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {typeGivenIDString} from "../../store/shared";
import {debounce} from 'lodash';

import store from "../../store";
import LoadingCard from "../../components/Skeletons/LoadingCard";
import {getObjectInArrayById} from "../../scripts/filterHelpers";

export default {
  name: "Subscriptions",
  components: {LoadingCard},
  data() {
    return {
      modalActive: false,
      subscriptions: [],
      loading: false,
      syncing: [],
      topics: [],
      deleting: [],
      customers: store.state.customers || [],
      selectedSubscriptionLink: {topics: []},
      selectedSubscriptionLinkDownTo: "site",
      user: {uid: this.userId},
    };
  },
  props: {
    userId: {
      type: String,
      default: () => {
        return store.state.user.uid;
      },
    },
  },
  mounted() {
    this.getSubscriptions();
    this.getTopics();
    this.setInitialSelectedSubscriptionLink();
  },
  computed: {
    allIdStrings() {
      return this.subscriptions.map((subscription) => subscription.id_str);
    },
    canSubmit() {
      let obj = this.selectedSubscriptionLink
          ? this.$store.getters.objectByIDString(
              this.selectedSubscriptionLink.id_str
          )
          : null;
      return (
          obj &&
          obj.id_str &&
          this.selectedSubscriptionLink &&
          this.selectedSubscriptionLink.topics &&
          this.selectedSubscriptionLink.topics.length > 0
      );
    },
  },
  methods: {
    currentlyDeleting(topic, subscriptionLink) {
      return this.deleting.includes(topic + ":::" + subscriptionLink.id);
    },
    deletingTopicForRow(subscriptionLink) {
      return this.deleting.some((d) => d.endsWith(":::" + subscriptionLink.id));
    },
    readableNameFromIDStr(idString) {
      let object = this.$store.getters.objectByIDString(idString) ||
              this.$store.dispatch("getObjFromIdStringAction", {idString}),
          _type = this.$store.getters.objectTypeByIDString(idString);
      return `${
          object && object.name ? object.name : _type === "Root" ? "" : "..."
      } ${_type ? `(${_type})` : ""}`;
    },
    sorted(arr) {
      return arr.sort();
    },
    selectedSubscriptionClashes() {
      return (
          !this.selectedSubscriptionLink.id &&
          this.selectedSubscriptionLink.id_str &&
          this.allIdStrings.some((id_str) =>
              id_str.startsWith(this.selectedSubscriptionLink.id_str)
          )
      );
    },
    setSelectedIdFrom(event) {
      // console.log('setSelectedIdFrom:$event.downTo', event[this.selectedSubscriptionLinkDownTo])
      let obj = event[this.selectedSubscriptionLinkDownTo],
          isBuilding = !!(
              obj &&
              obj._reference &&
              obj._reference.path.startsWith("buildings/")
          ),
          id_str;
      if (isBuilding) {
        // then use attached area
        let area = getObjectInArrayById(
            this.$store.state.areas,
            obj.area.id,
            {}
        );
        id_str = area ? area.id_str : null;
      } else {
        id_str = obj ? obj.id_str : null;
      }
      this.selectedSubscriptionLink = {
        user_id: this.user.uid,
        topics: this.selectedSubscriptionLink.topics,
        id_str: id_str,
      };
    },
    unsetSelectedSubscriptionLocation() {
      this.selectedSubscriptionLink.id_str = null;
      this.selectedSubscriptionLink = Object.assign(
          {},
          this.selectedSubscriptionLink
      );
    },
    setInitialSelectedSubscriptionLink() {
      this.selectedSubscriptionLink = {
        topics: [],
        id_str: null,
      };
    },
    typeGivenIDString,
    removeTopic(topic, subscription_link) {
      let self = this,
          hasAnyLeft =
              subscription_link.topics.filter((t) => t !== topic).length > 0,
          deletionString = topic + ":::" + subscription_link.id,
          index = this.subscriptions.map((e) => e.id).indexOf(subscription_link.id);
      subscription_link.topics = subscription_link.topics.filter((t) => t !== topic);
      this.$set(
          this.subscriptions,
          index,
          subscription_link
      );
      self.deleting = [...self.deleting, deletionString];
      if (hasAnyLeft) {
        self.syncing.push(deletionString);
        this.$dataClasses
            .UserSubscriptionLink
            .save(subscription_link.id, {
              topics: subscription_link.topics.filter((t) => t !== topic),
            })
            .then(() => self.getSubscriptions(false))
            .catch((e) => self.$handleError(e))
            .finally(() => {
              self.$nextTick(() => {
                setTimeout(() => self.deleting = self.deleting.filter((i) => i !== deletionString), 1200);
                self.syncing = self.syncing.filter(el => el !== deletionString);
              });
            });
      } else {
        self.deleteSubscription(subscription_link);
      }
    },
    submitSelected() {
      let self = this,
          subId = self.selectedSubscriptionLink.id;
      self.syncing = [...self.syncing, subId];
      self.modalActive = false;
      this.$dataClasses
          .UserSubscriptionLink.save(
          self.selectedSubscriptionLink.id,
          self.selectedSubscriptionLink
      )
          .then((result) => {
            self.getSubscriptions(false);
          })
          .catch((e) => self.$handleError(e, e))
          .finally(() => {
            self.syncing = self.syncing.filter(e => e !== subId);
          });
    },
    deleteSubscriptionPrompt(subscription_link) {
      let self = this;
      self.$buefy.dialog.confirm({
        message: `Are you sure you'd like to remove your subscription
                  to ${self.readableNameFromIDStr(subscription_link.id_str)}?`,
        type: "is-warning",
        onConfirm() {
          self.deleteSubscription(subscription_link, true);
        },
      });
    },
    deleteSubscription(subscription_link) {
      let self = this;
      self.syncing.push(subscription_link.id);
      this.$dataClasses
          .UserSubscriptionLink
          .deleteById(subscription_link.id)
          .then(() => self.getSubscriptions(false))
          .catch((e) => self.$handleError(e))
          .finally(() => {
            self.modalActive = false;
            self.syncing = self.syncing.filter(el => el !== subscription_link.id);
          });
    },
    readableTopic(topic) {
      return this.capitalizeFirstLetter(
          topic.replace("noflow", "No/low flow").replaceAll("_", " ")
      );
    },
    getSubscriptions: debounce(function (updateLoading = true) {
          let self = this,
              queryPromises = [
                this.$dataClasses
                    .UserSubscriptionLink
                    .query([["user_id", "==", self.userId]]),
                this.$dataClasses
                    .UserSubscriptionLink
                    .query([["user_id", "==", 'ALL']]),
              ];
          if (updateLoading) {
            self.loading = true;
          }
          self.subscriptions = [];
          Promise
              .all(queryPromises)
              .then((arrOfArrOfResults) => {
                arrOfArrOfResults.forEach(arrOfResults => {
                  self.subscriptions = self.subscriptions.concat(
                      arrOfResults.filter(
                          (sub) => sub.topics && sub.topics.length > 0
                      )
                  )
                })
              })
              .catch((e) => self.$handleError(e))
              .finally(() => {
                if (updateLoading) {
                  self.loading = false;
                }
              });
        },
    ),
    getTopics() {
      this.topics = ["calibrate", "flow", "noflow", 'temperature'];
    },
  },
};
</script>

<style scoped>
.newline {
  display: block;
  margin-bottom: 5px;
}
</style>
