<template>
  <b-loading
    :is-full-page="false"
    v-if="$store.state.fetching.pipe_locations"
  />
  <div :class="classes" v-else-if="value">
    <div v-if="showParents">
      <site-display
        :value="value.$site"
        v-if="value.$site"
        :show-link-icon="showLinkIcon"
      />
      <building-display
        :value="value.$building"
        v-if="value.$building"
        :show-link-icon="showLinkIcon"
      />
      <room-display
        :value="value.$room"
        v-if="value.$room"
        :show-link-icon="showLinkIcon"
        show-floor
      />
    </div>
    <slot name="pre-icon" />
    <b-icon v-if="showIcon && icon" :icon="icon" :size="size" />
    <span v-if="iconPosition === 'right'">
      <slot name="pre-name" />
      {{ value.$displayString }}
    </span>
    <b-tooltip
      :active="showTooltip"
      v-if="showTempIcon && tempIcon"
      :label="'Water temperature: ' + temperature"
      :type="iconColor"
      :position="tooltipPosition"
    >
      <div v-if="value.temperature === 'mixed' && showTempIcon">
        <b-icon :icon="iconFor('hot')" type="is-hot" :size="size" />
        <b-icon :icon="iconFor('cold')" type="is-cold" :size="size" />
      </div>
      <div v-else-if="showTempIcon">
        <b-icon
          v-if="showTempIcon"
          :icon="tempIcon"
          :type="iconColor"
          :size="size"
        />
      </div>
    </b-tooltip>
    <span v-if="iconPosition === 'left'">
      <slot name="pre-name" />
      {{ value.$displayString }}
    </span>
    <span v-if="showLinkIcon" :class="{'is-pulled-right mr-3':!linkIconNoPull}" >
      <b-icon
        :icon="linkIcon"
        class="pointer"
        type="is-link"
        @click.native="
          $router.push(getLinkFunction(value));
          $event.preventDefault();
        "
      />
    </span>
    <slot name="end" />
  </div>
</template>

<script>
import { get } from "lodash";
import iconFor from "../../icons";
import BuildingDisplay from "./BuildingDisplay";
import RoomDisplay from "./RoomDisplay";
import SiteDisplay from "./SiteDisplay";

const temperatureColors = {
  hot: "is-hot",
  mixed: "is-mixed",
  cold: "is-cold",
};

export default {
  name: "PipeLocationDisplay",
  components: { BuildingDisplay, RoomDisplay, SiteDisplay },
  props: {
    linkIconNoPull: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showTempIcon: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
    showParents: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String,
      default: "right",
      validator(value) {
        return ["right", "left"].includes(value);
      },
    },
    tooltipPosition: {
      type: String,
      default: "is-right",
      validator(value) {
        return ["is-right", "is-left", "is-top", "is-bottom"].includes(value);
      },
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    linkIcon: {
      type: String,
      default: iconFor("update"),
    },
    getLinkFunction: {
      type: Function,
      default: function (src) {
        return this.$router.match({
          name: "administration:view-pipe-location",
          params: { id: src.id },
          query: {
            loadScope: src.id_str
              ? src.id_str.split("|").slice(0, 5).join("|")
              : null,
          },
        });
      },
    },
    classes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    iconFor,
  },
  computed: {
    temperature() {
      return get(this.value, "temperature", null);
    },
    icon() {
      return iconFor("pipe_location");
    },
    tempIcon() {
      return iconFor(this.temperature);
    },
    iconColor() {
      return get(temperatureColors, this.temperature, null);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.hovering {
  background-color: deepskyblue;
}
</style>
