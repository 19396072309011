<template>
  <div class="wrapper is-primary columns">
    <div
      class="column is-4-desktop is-offset-4-desktop is-8-tablet is-offset-2-tablet"
    >
      <b-loading :active="loading" is-full-page />
      <div v-show="!loading">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title subtitle mb-0">AquaSeca</div>
            <div
              class="card-header-icon"
              @click="window_.open('https://aquaseca.com/', '_blank')"
            >
              <img :src="logo" />
            </div>
          </div>
          <div class="card-content">
            <div class="ui-wrapper mb-6 mt-5" v-show="!user">
              <div id="firebaseui-auth-container"></div>
            </div>
            <div v-if="user">
              You are logged in as {{ user.email }}.
              <router-link :to="{ name: 'home' }">Click here</router-link>
              to view your dashboard.
            </div>
            <div class="columns">
              <div
                class="column is-6-xs has-text-centered is-size-7 is-faded-link mb-0"
              >
                <a href="https://aquaseca.com/privacy-policy/" target="_blank">
                  Privacy policy
                </a>
              </div>
              <div
                class="column is-6-xs has-text-centered is-size-7 is-faded-link mb-0"
              >
                <a
                  href="https://aquaseca.com/terms-conditions/"
                  target="_blank"
                >
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../../assets/swoosh-sm.png";
import { mapState } from "vuex";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import fbAuthMixin from "../../auth";

export default {
  data() {
    return { logo };
  },
  mixins: { fbAuthMixin },
  computed: {
    window_() {
      return window;
    },
    ...mapState(["loading", "user"]),
    /**
     * Calculates the next URL to visit on successful login
     * @returns {{location: Location; route: Route; href: string; normalizedTo: Location; resolved: Route}}
     */
    loadScope() {
      return this.$route.query.loadScope;
    },
  },
  /**
   * On mounting - start up auth application
   */
  mounted() {
    let self = this;
    let uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: () => {
          self.$handleSuccess("You are now logged in");
        },
      },
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
            adminEmail: "info@aquaseca.com",
          },
        },
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          disableSignUp: {
            status: true,
            adminEmail: "info@aquaseca.com",
          },
        },
      ],
    };

    // Only mount if not already existing
    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      ui.start("#firebaseui-auth-container", uiConfig);
    } else {
      const ui = new firebaseui.auth.AuthUI(self.$fb.auth());
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  },
  watch: {
    "$route.name": {
      immediate: true,
      handler(val) {
        if (
          val === "login" &&
          this.$store.state.user &&
          this.$store.state.user_roles
        ) {
          this.$router.push(this.$route.query.nextUrl || { name: "home" });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../mixins/buefy/styles";

.ui-wrapper {
  color: black;
}

.is-size-7 {
  font-size: 7px;
}

.wrapper {
  margin-top: 10vh;
}

.is-faded-link a {
  color: #b2b2b2 !important;
}
.is-faded-link a:hover {
  color: #949494 !important;
}
</style>
