<template>
  <div class="is-fullwidth">
    <b-table
      v-if="loading || (files && files.length)"
      :data="files"
      :default-sort="['created_at']"
      detailed
      detail-key="path"
      detail-transition="fade"
      default-sort-direction="desc"
      :loading="loading"
    >
      <b-table-column
        label="Filename"
        v-slot="props"
        field="metadata.filename"
        sortable
      >
        <a :href="viewOrDeleteFileUrl(true, props.row)" target="_blank">
          {{ props.row.metadata.filename }}
        </a>
      </b-table-column>
      <b-table-column
        label="Category"
        v-slot="props"
        field="metadata.category"
        sortable
      >
        {{ props.row.metadata.category.replace("_", " ") }}
      </b-table-column>
      <b-table-column
        label="Uploaded"
        v-slot="props"
        field="created_at"
        sortable
      >
        {{
          props.row.created_at
            ? props.row.created_at.toLocaleDateString() +
              " " +
              props.row.created_at.toLocaleTimeString()
            : ""
        }}
      </b-table-column>
      <slot name="select-column">
        <b-table-column v-if="canDelete" v-slot="props">
          <b-button
            class="is-pulled-right"
            type="is-link"
            size="is-small"
            @click="viewFileDetail(props.row)"
          >
            View detail
          </b-button>
        </b-table-column>
      </slot>
      <template #detail="props">
        <file-data
          :file-object="props.row"
          @file-deleted="fileDeleted"
          @file-updated="fileUpdated"
        />
      </template>
    </b-table>
    <div v-else class="has-text-centered mt-5 mb-5">
      <i>There are no files attached to this object</i>
    </div>
  </div>
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";

export default {
  name: "FileTable",
  mixins: [trackWindowSize],
  props: {
    category: {
      type: String,
      required: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    fileDeleted(file) {
      this.$emit("file-deleted", file);
    },
    fileUpdated(fileData) {
      this.$emit("file-updated", fileData);
    },
    viewOrDeleteFileUrl(includeDomain, file) {
      let domain = includeDomain ? process.env.VUE_APP_API_URL : "";
      return domain + `/files/` + file.path;
    },
    viewFileDetail(file) {
      let params = {
        name: "administration:file-detail",
        params: {
          filePath: file.path,
        },
      };
      this.$router.push(params);
    },
  },
};
</script>

<style scoped></style>
