/**
 * Access mutations via store.commit(actionName).
 * Used *only* for data mutation. Do **not** use for fetching data from APIs, etc.
 * TODO: Cleanup all the scrappy methods. Move things out of Vuex into wizards, etc.
 */

import firebase from "firebase/app";
import {
  assertAllProperies,
  assertSomeProperies,
  defaultIsArray,
  getStateVariableDefault,
  hasAllProperties,
  mergeById,
  stateVariables,
} from "../shared";
import { vuexfireMutations } from "vuexfire";

import Roles from "../../components/Roles";
import {alphanumericSortByProperty} from "../../appLevelFunctions/alphanumericSort";

const mutations = {
  ...vuexfireMutations,
  setFirebaseConfig(state, val) {
    state.fbConfig = val;
    if (!state.firebase) {
      state.firebase = firebase.initializeApp(state.fbConfig);
    }
  },
  setUserTokenLoading(state, val) {
    state.userTokenLoading = val;
  },
  setMostRecentMessageReceivedAt(state, val) {
    state.mostRecentMessageReceivedAt = val;
  },
  loadingMessages(state, val) {
    state.loadingMessages = val;
  },
  loadingDismissedMessages(state, val) {
    state.loadingDismissedMessages = val;
  },
  setHasAccountNative(state, val) {
    state.hasAccountNative = val;
  },
  closeDialog(state) {
    state.dialog.active = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  saveInstallData(state, installData) {
    state.installData = installData;
  },
  removeUser(state, email) {
    state.users = state.users.filter((u) => u.email !== email);
  },
  setUserAccount(state, userAccount) {
    if (state.user) {
      state.user = Object.assign({}, state.user);
      state.user_account = userAccount;
      state.user_roles = new Roles(userAccount ? userAccount.roles : []);
    }
  },
  /**
   * Reset a variable to it's default, as defined in ./shared
   * @param state
   * @param payload: Must have 'name' string member (name of variable to reset).
   */
  reset(state, payload) {
    assertAllProperies(payload, ["name"]);
    function resetStateVariable(_key) {
      let objMeta = stateVariables[_key],
        children = objMeta ? objMeta.children || [] : [];
      state[_key] = objMeta ? objMeta.default || null : null;
      state.pagination[_key] = 0;
      children.forEach((childKey) => resetStateVariable(childKey));
      state.lastUpdated[_key] = new Date();
    }
    resetStateVariable(payload.name);
  },
  /**
   * Replace the value for a Vuex state variable.
   * @param state
   * @param payload {Object}: Must have "name" member (which variable to update) and "value" member,
   *  the value which to update to.
   */
  set(state, payload) {
    assertAllProperies(payload, ["name", "value"]);
    state[payload.name] = this._vm.$toDataClass(payload.name, payload.value);
    state.lastUpdated[payload.name] = new Date();
  },
  /**
   * Add a member to state variable (array)
   * @param state
   * @param payload Must have member 'result' or 'results'. If member 'page',
   *   sets the most recently fetched page in state.pagination.<collection>
   */
  add(state, payload) {
    assertAllProperies(payload, ["name"]);
    assertSomeProperies(payload, ["results", "result"]);
    if (hasAllProperties(payload, ["results", "result"])) {
      throw `Cannot execute add:${payload.name} without payload result or results (and not both)`;
    }
    if (!defaultIsArray(payload.name)) {
      throw `.add mutation is not available for non-array defaulted state variables. (pl:${payload.name})`;
    }
    state[payload.name] = mergeById(state[payload.name]).with(
      payload.result
        ? this._vm.$toDataClass(payload.name, payload.result)
        : payload.results.map((r) => this._vm.$toDataClass(payload.name, r))
    ).sort(alphanumericSortByProperty('$displayString'));
    state.lastUpdated[payload.name] = new Date();
  },
  /**
   * Removes single entry from array state variable, resets to default.
   * @param state
   * @param payload
   */
  remove(state, payload) {
    assertAllProperies(payload, ["name"]);
    if (defaultIsArray(payload.name)) {
      assertAllProperies(payload, ["id"]);
      state[payload.name] = state[payload.name].filter(
        (obj) => obj.id !== payload.id
      );
    } else {
      state[payload.name] = getStateVariableDefault(payload.name);
    }
    state.lastUpdated[payload.name] = new Date();
  },
  /**
   * When a toast is opened (likely via store/shared/toastHandlers.js),
   *   this saves a referenced to it by position.
   *
   * If a toast was already opened for the position, it is programmatically closed.
   */
  toastOpened(state, { toast }) {
    if (state.lastToast) {
      state.lastToast.close();
    }
    state.lastToast = toast;
  },
};

export default mutations;
