import { get } from "lodash";

export const iconDirectory = {
  // ALL ENTRIES MUST BE LOWERCASE
  // actions
  edit: "edit",
  update: "edit",
  create: "plus-circle",
  add: "plus-circle",
  delete: "trash",
  copy: "copy",
  dismiss: "minus-circle",
  undismiss: "sync",
  danger: "exclamation-circle",
  filter: "filter",
  detail: "poll-h",
  results: "list",
  list: "list",
  save: "check",

  // help
  help: "question-circle",
  info: "info-circle",
  schedule: "clock",
  newWindow: "external-link-alt",
  warning: "exclamation-circle",

  // general
  administration: "users-cog",
  device: "satellite-dish",
  download: "download",
  ground_truth: "headphones",
  hierarchy: "building",
  home: "home",
  install: "laptop-house",
  login: "sign-in-alt",
  logout: "sign-out-alt",
  notifications: "bell",
  profile: "user-circle",
  settings: "network-wired",
  shipment: "box-open",
  token: "coins",
  training: "brain",
  utilities: "cogs",
  source_detail: "asterisk",

  // hierarchy
  customer: "layer-group",
  subcustomer: "square-full",
  sub_customer: "square-full",
  site: "map-marked-alt",
  area: "object-group",
  building: "building",
  room: "kaaba",
  source: "hand-holding-water",
  file: "file",
  report: "file",
  alarm: "user-clock",
  pipe_location: "wrench",

  // devices
  position: "tachometer-alt",
  sensor: "tachometer-alt",
  gateway_position: "dungeon",
  gateway: "dungeon",
  probe: "project-diagram",

  // source types
  faucet: "faucet",
  shower: "shower",
  toilet: "toilet",
  appliance: "blender",
  unknownsourcetype: "hand-holding-water",

  //temperatures
  hot: "temperature-high",
  mixed: "thermometer-half",
  cold: "temperature-low",
  unknown: "question",

  // topics
  calibrate: "tools",
  flow: "tint",
  health: "heartbeat",
  noflow: "tint-slash",
  source_selection: "fill",
  temperature: "thermometer-half",

  // solution categories
  hygiene: "pump-medical",
  efficiency: "efficiency",
  leaks: "fill-drip",

  // Ratings:
  low_quality: "exclamation-triangle",
  medium_quality: "flag",
  high_quality: "check-circle"
};

/**
 *
 * @param objectName
 * @return {null|Array.<Object.keys(iconDirectory)>}
 */
export default function iconFor(objectName) {
  if (!objectName) {
    return null;
  }
  objectName = objectName.toLowerCase().split("/")[0].replace("-", "_");
  let icon = get(iconDirectory, objectName, null);
  if (!icon && objectName.endsWith("s")) {
    icon = get(iconDirectory, objectName.slice(0, -1), null);
  }
  return icon;
}
