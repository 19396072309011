import firebase from "firebase/app";
import "firebase/firestore";
const { GeoPoint, Timestamp } = firebase.firestore;
export let FirestoreTimestamp = Timestamp;

const typeKey = "_type";

export default function deserializer(x) {
  /*
    deserializes data to be submitted to the FS REST API
  */
  if (isArray(x)) {
    return traverseArray(x);
  } else if (typeof x === "object" && x !== null) {
    if (x[typeKey] === "date") {
      return new Date(x.value);
    } else if (x[typeKey] === "geopoint") {
      return new GeoPoint(x.latitude, x.longitude);
    } else if (x[typeKey] === "reference") {
      let collectionName = x.path.split("/")[0],
        docID = x.path.split("/")[1];
      return firebase.firestore().collection(collectionName).doc(docID);
    } else if (x) {
      return traverseObject(x);
    }
  }
  return x;
}

function traverseArray(arr) {
  let value = [];
  arr.forEach(function (x) {
    value.push(deserializer(x));
  });
  return value;
}

function traverseObject(obj) {
  let value = {};
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      value[key] = deserializer(obj[key]);
    }
  }
  return value;
}

function isArray(o) {
  return Object.prototype.toString.call(o) === "[object Array]";
}
