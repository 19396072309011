<template>
  <b-select :value="value" @input="input($event)">
    <option value="all_mean">mean value (similarity score) per POU</option>
    <option value="all_max">max value (similarity score) per POU</option>
    <option value="sensors">individual table per sensor</option>
  </b-select>
</template>

<script>
export default {
  name: "SimilarityViewSelect",
  props: {
    value: {
      type: String
    }
  },
  methods: {
    input(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>

</style>