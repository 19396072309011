<template>
  <div v-draggable:options="options" class="aq-image-map-wrapper">
    <loading-chart-card v-if="!loaded" />
    <img
      ref="canvas"
      class="aq-image-map-canvas"
      id="aq-image-map-canvas"
      :width="fileObject.metadata.w"
      :height="fileObject.metadata.h"
      :src="getImageUrl(fileObject)"
      @load="loaded = true"
      v-show="loaded"
    />
    <b-loading :is-full-page="false" v-if="!loadedTimeoutMet" />
    <div v-else>
      <div class="ok"></div>
      <div
        v-for="sensor in positionedSensors"
        :style="getStyleTag(sensor)"
        :key="sensor.obj.path"
        :data-path="sensor.obj.path"
        :class="{
          'positioned draggable': true,
          'non-editable': !entityIsEditable(sensor),
        }"
      >
        <span v-if="objectByID(sensor.obj.id, null, sensorPositions)">
          <b-icon
            :class="entityIsEditable(sensor) ? 'handler' : 'no-handler'"
            icon="circle"
            type="is-sensor"
            size="is-small"
          />

          <span class="boxy">
            <generic-display
              :ref="'generic-display-' + sensor.obj.id"
              classes="inline-block"
              collapsible
              :show-link-icon="false"
              :value="objectByID(sensor.obj.id, {}, sensorPositions)"
            >
              <template #end v-if="entityIsEditable(sensor)">
                <a class="is-xs pointer" @click="resetPath(sensor.obj.path)"
                  >☒</a
                >
              </template>
            </generic-display>
          </span></span
        >
      </div>
      <div
        v-for="gw in positionedGateways"
        :key="gw.obj.path"
        :class="{
          'positioned draggable': true,
          'non-editable': !entityIsEditable(gw),
        }"
        :data-path="gw.obj.path"
        :style="getStyleTag(gw)"
      >
        <span v-if="objectByID(gw.obj.id, null, gatewayPositions)">
          <b-icon
            :class="entityIsEditable(gw) ? 'handler' : 'no-handler'"
            icon="circle"
            type="is-gateway"
            size="is-small"
          />
          <span class="boxy">
            <generic-display
              classes="inline-block"
              collapsible
              :ref="'generic-display-' + gw.obj.id"
              :show-link-icon="false"
              :value="objectByID(gw.obj.id, {}, gatewayPositions)"
            >
              <template #end v-if="entityIsEditable(gw)">
                <a class="is-xs pointer" @click="resetPath(gw.obj.path)">☒</a>
              </template>
            </generic-display>
          </span>
        </span>
      </div>
      <div
        v-for="src in positionedSources"
        :style="getStyleTag(src)"
        :class="{
          'positioned draggable': true,
          'non-editable': !entityIsEditable(src),
        }"
        :key="src.obj.path"
        :data-path="src.obj.path"
      >
        <span v-if="objectByID(src.obj.id, null)">
          <b-icon
            :class="entityIsEditable(src) ? 'handler' : 'no-handler'"
            icon="circle"
            type="is-source"
            size="is-small"
          />
          <span class="boxy">
            <generic-display
              classes="inline-block"
              collapsible
              :ref="'generic-display-' + src.obj.id"
              :show-link-icon="false"
              :value="objectByID(src.obj.id)"
            >
              <template #end v-if="entityIsEditable(src)">
                <a class="is-xs pointer" @click="resetPath(src.obj.path)">☒</a>
              </template>
            </generic-display>
          </span>
        </span>
      </div>

      <div class="image-map-buttons mb-6 is-clearfix">
        <div
          class="buttons is-pulled-left"
          v-if="positions && positions.length"
        >
          <b-button @click="expandAll" v-if="positions && positions.length">
            Expand all
          </b-button>
          <b-button @click="collapseAll" v-if="positions && positions.length">
            Collapse all
          </b-button>
        </div>
        <div class="buttons is-pulled-right">
          <b-button type="is-primary" :loading="savingImageMap" @click="save">
            Save
          </b-button>
        </div>
      </div>

      <div class="non-positioned">
        <p class="has-text-centered has-text-weight-bold">Unplaced items</p>
        <div class="unplaced-inner" v-if="nonPositionedEntities.length">
          <div class="entities ml-5">
            <div
              v-for="sensor in nonPositionedSensors.filter(
                (s) => !entityParentId(s)
              )"
              :class="{
                draggable: true,
                'non-editable': !entityIsEditable(sensor),
              }"
              :key="sensor.obj.path"
              :data-path="sensor.obj.path"
            >
              <b-icon
                :class="entityIsEditable(sensor) ? 'handler' : 'no-handler'"
                icon="circle"
                type="is-sensor"
                size="is-small"
              />
              <span class="boxy">
                <generic-display
                  :value="objectByID(sensor.obj.id, {}, sensorPositions)"
                  :show-link-icon="false"
                  classes="inline-block"
                />
              </span>
            </div>

            <div
              v-for="gw in nonPositionedGateways.filter(
                (s) => !entityParentId(s)
              )"
              :class="{
                draggable: true,
                'non-editable': !entityIsEditable(gw),
              }"
              :key="gw.obj.path"
              :data-path="gw.obj.path"
            >
              <b-icon
                :class="entityIsEditable(gw) ? 'handler' : 'no-handler'"
                icon="circle"
                type="is-gateway"
                size="is-small"
              />
              <span class="boxy">
                <generic-display
                  :value="objectByID(gw.obj.id, {}, gatewayPositions)"
                  :show-link-icon="false"
                  classes="inline-block"
                >
                  <template #end>
                    {{ entityParentId(gw) }}
                  </template>
                </generic-display>
              </span>
            </div>

            <div
              v-for="src in nonPositionedSources.filter(
                (s) => !entityParentId(s)
              )"
              :class="{
                draggable: true,
                'non-editable': !entityIsEditable(src),
              }"
              :key="src.obj.path"
              :data-path="src.obj.path"
            >
              <b-icon
                :class="entityIsEditable(src) ? 'handler' : 'no-handler'"
                icon="circle"
                type="is-source"
                size="is-small"
              />
              <span class="boxy">
                <generic-display
                  :value="objectByID(src.obj.id)"
                  :show-link-icon="false"
                  classes="inline-block"
                />
              </span>
            </div>
          </div>
          <div class="ml-5" v-for="building in buildings" :key="building.id">
            <div>
              <building-display :value="building" />
            </div>
            <div class="entities ml-5">
              <div
                v-for="sensor in nonPositionedSensors.filter(
                  (s) => entityParentId(s) === building.id
                )"
                :class="{
                  draggable: true,
                  'non-editable': !entityIsEditable(sensor),
                }"
                :key="sensor.obj.path"
                :data-path="sensor.obj.path"
              >
                <b-icon
                  :class="entityIsEditable(sensor) ? 'handler' : 'no-handler'"
                  icon="circle"
                  type="is-sensor"
                  size="is-small"
                />
                <span class="boxy">
                  <generic-display
                    :value="objectByID(sensor.obj.id, {}, sensorPositions)"
                    :show-link-icon="false"
                    classes="inline-block"
                  />
                </span>
              </div>

              <div
                v-for="gw in nonPositionedGateways.filter(
                  (s) => entityParentId(s) === building.id
                )"
                :class="{
                  draggable: true,
                  'non-editable': !entityIsEditable(gw),
                }"
                :key="gw.obj.path"
                :data-path="gw.obj.path"
              >
                <b-icon
                  :class="entityIsEditable(gw) ? 'handler' : 'no-handler'"
                  icon="circle"
                  type="is-gateway"
                  size="is-small"
                />
                <span class="boxy">
                  <generic-display
                    :value="objectByID(gw.obj.id, {}, gatewayPositions)"
                    :show-link-icon="false"
                    classes="inline-block"
                  >
                  </generic-display>
                </span>
              </div>

              <div
                v-for="src in nonPositionedSources.filter(
                  (s) => entityParentId(s) === building.id
                )"
                :class="{
                  draggable: true,
                  'non-editable': !entityIsEditable(src),
                }"
                :key="src.obj.path"
                :data-path="src.obj.path"
              >
                <b-icon
                  :class="entityIsEditable(src) ? 'handler' : 'no-handler'"
                  icon="circle"
                  type="is-source"
                  size="is-small"
                />
                <span class="boxy">
                  <generic-display
                    :value="objectByID(src.obj.id)"
                    :show-link-icon="false"
                    classes="inline-block"
                  />
                </span>
              </div>
            </div>
            <div
              class="ml-5"
              v-for="room in rooms.filter(
                (r) => r.building && r.building.id === building.id
              )"
              :key="room.id"
            >
              <room-display :value="room" />
              <div class="entities ml-5">
                <div
                  v-for="sensor in nonPositionedSensors.filter(
                    (s) => entityParentId(s) === room.id
                  )"
                  :class="{
                    draggable: true,
                    'non-editable': !entityIsEditable(sensor),
                  }"
                  :key="sensor.obj.path"
                  :data-path="sensor.obj.path"
                >
                  <b-icon
                    :class="entityIsEditable(sensor) ? 'handler' : 'no-handler'"
                    icon="circle"
                    type="is-sensor"
                    size="is-small"
                  />
                  <span class="boxy">
                    <generic-display
                      :value="objectByID(sensor.obj.id, {}, sensorPositions)"
                      :show-link-icon="false"
                      classes="inline-block"
                    />
                  </span>
                </div>

                <div
                  v-for="gw in nonPositionedGateways.filter(
                    (s) => entityParentId(s) === room.id
                  )"
                  :class="{
                    draggable: true,
                    'non-editable': !entityIsEditable(gw),
                  }"
                  :key="gw.obj.path"
                  :data-path="gw.obj.path"
                >
                  <b-icon
                    :class="entityIsEditable(gw) ? 'handler' : 'no-handler'"
                    icon="circle"
                    type="is-gateway"
                    size="is-small"
                  />
                  <span class="boxy">
                    <generic-display
                      :value="objectByID(gw.obj.id, {}, gatewayPositions)"
                      :show-link-icon="false"
                      classes="inline-block"
                    />
                  </span>
                </div>
                <div
                  v-for="src in nonPositionedSources.filter(
                    (s) => entityParentId(s) === room.id
                  )"
                  :class="{
                    draggable: true,
                    'non-editable': !entityIsEditable(src),
                  }"
                  :key="src.obj.path"
                  :data-path="src.obj.path"
                >
                  <b-icon
                    :class="entityIsEditable(src) ? 'handler' : 'no-handler'"
                    icon="circle"
                    type="is-source"
                    size="is-small"
                  />
                  <span class="boxy">
                    <generic-display
                      :value="objectByID(src.obj.id)"
                      :show-link-icon="false"
                      classes="inline-block"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="has-text-centered" v-else>
          <i>No items in this scope</i>
        </div>

        <div class="legend mt-3">
          <p class="has-text-centered has-text-weight-bold">Legend</p>
          <div class="level">
            <div class="level-item">
              <div class="level-left">
                <b-icon
                  class="mr-3"
                  icon="circle"
                  type="is-source"
                  size="is-small"
                />
              </div>
              <div class="level-right">Point-of-use</div>
            </div>
            <div class="level-item">
              <div class="level-left">
                <b-icon
                  class="mr-3"
                  icon="circle"
                  type="is-gateway"
                  size="is-small"
                />
              </div>
              <div class="level-right">Gateway</div>
            </div>
            <div class="level-item">
              <div class="level-left">
                <b-icon
                  class="mr-3"
                  icon="circle"
                  type="is-sensor"
                  size="is-small"
                />
              </div>
              <div class="level-right">Sensor</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableDirective } from "vue-draggable";


import { mapGetters } from "vuex";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import { isEqual } from "lodash";
import LoadingChartCard from "../../Skeletons/LoadingChartCard";
import GatewayPosition from "../../../dataClasses/GatewayPosition";

export default {
  name: "AqImageMap",
  components: {
    LoadingChartCard,
  },
  directives: {
    draggable: VueDraggableDirective,
  },
  props: {
    /**
     * Which entities can be moved?
     * If array, elements should be paths
     * @type {('all')|null|Array.<String>}
     */
    editableEntityPaths: {
      type: [String, Array],
      default: "all",
      validator(val) {
        return val === "all" || Array.isArray(val) || !val;
      },
    },
    /**
     * @type {Object}
     */
    fileObject: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * @type {Object}
     */
    parentObject: {
      type: Object,
      required: true,
    },
    /**
     * @type {Boolean}
     */
    showNonEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      changesSinceLoad: false,
      loaded: false,
      loadedTimeoutMet: false,
      bg: null,
      positions: [], // NOT general def of positions, but pos of entities on image
      initialPositions: [],
      sensorPositions: [], // fetch here
      gatewayPositions: [],
      sensorPositionsLoading: false,
      gatewayPositionsLoading: false,
      imageMapId: null,
      loadingImageMap: false,
      savingImageMap: false,
      imageClientWidth: 0,
      imageClientHeight: 0,
      lastEvt: {},
    };
  },
  created() {
    window.addEventListener("resize", this.setImageClientDimensions);
  },
  mounted() {
    this.setImageClientDimensions();
  },
  destroyed() {
    window.removeEventListener("resize", this.setImageClientDimensions);
  },
  beforeRouteLeave(to, from, next) {
    if (!isEqual(this.initialPositions, this.positions)) {
      this.$buefy.dialog.confirm({
        title: "Unsaved location data",
        message:
          "Location data for this object has changed since last saved; Are you sure you want to leave this page?",
        type: "in-info",
        onConfirm: () => {
          next(true);
        },
        onCancel: () => {
          next(false);
        },
      });
    } else {
      next(true);
    }
  },
  computed: {
    ...mapGetters(["objectByID"]),
    imageFullWidth() {
      return this.fileObject.metadata.w;
    },
    imageFullHeight() {
      return this.fileObject.metadata.h;
    },
    canvas() {
      return this.$refs.canvas;
    },
    changed() {
      return !isEqual(this.initialPositions, this.positions);
    },
    buildings() {
      if (this.fileObject.path.startsWith("buildings/")) {
        let buildingId = this.fileObject.path.split("/")[1],
          building = getObjectInArrayById(
            this.$store.state.buildings,
            buildingId,
            null
          );
        return building ? [building] : [];
      } else if (this.fileObject.path.startsWith("rooms/")) {
        let roomId = this.fileObject.path.split("/")[2],
          room = getObjectInArrayById(this.$store.state.rooms, roomId, {}),
          buildingId = room.building ? room.building.id : null,
          building = getObjectInArrayById(
            this.$store.state.buildings,
            buildingId,
            null
          );
        return building ? [building] : [];
      }
      return this.$store.state.buildings;
    },
    rooms() {
      let buildingIds = this.buildings.map((b) => b.id);
      return this.$store.state.rooms.filter(
        (r) => r.building && buildingIds.includes(r.building.id)
      );
    },
    options() {
      return {
        dropzoneSelector: "#aq-image-map-canvas",
        draggableSelector: "div.draggable",
        handlerSelector: ".handler",
        onDrop: this.onPosChanged,
        multipleDropzonesItemsDraggingEnabled: false,
        showDropzoneAreas: true,
      };
    },
    sources() {
      return this.$store.state.sources.filter(this.entityFilter);
    },
    sourceArray() {
      let self = this;
      return this.sources.map((src) => {
        return { obj: self.toFSRef(src.id, "sources") };
      });
    },
    sensorPositionArray() {
      let self = this;
      return this.sensorPositions.map((sen) => {
        return { obj: self.toFSRef(sen.id, "positions") };
      });
    },
    gatewayPositionArray() {
      let self = this;
      return this.gatewayPositions.filter(this.entityFilter).map((gw) => {
        return { obj: self.toFSRef(gw.id, "gateway_positions") };
      });
    },
    nonPositions() {
      let self = this,
        allPosObj = [
          ...this.sourceArray,
          ...this.sensorPositionArray,
          ...this.gatewayPositionArray,
        ];
      return allPosObj.filter(
        (i) => !self.positionedPaths.includes(i.obj.path)
      );
    },
    positionedPaths() {
      return this.positions.map((p) => p.obj.path);
    },
    positionedGateways() {
      return this.positions.filter((p) =>
        p.obj.path.startsWith("gateway_positions/")
      );
    },
    positionedSensors() {
      return this.positions.filter((p) => p.obj.path.startsWith("positions/"));
    },
    positionedSources() {
      return this.positions.filter((p) => p.obj.path.startsWith("sources/"));
    },
    positionedEntities() {
      return this.positionedGateways.concat(
        this.positionedSensors,
        this.positionedSources
      );
    },
    parentCollectionName() {
      return this.fileObject.path.split("/")[0];
    },
    parentId() {
      let segments = this.fileObject.path.split("/");
      return segments[1];
    },
    entityFilter() {
      const callableFilters = {
          buildings: (obj) => obj.building && obj.building.id === this.parentId,
          rooms: (obj) => obj.room && obj.room.id === this.parentId,
        },
        noMatchFunc = () => true;
      // return noMatchFunc
      return callableFilters[this.parentCollectionName] || noMatchFunc;
    },
    nonPositionedGateways() {
      return this.nonPositions.filter(
        (p) =>
          p.obj.path.startsWith("gateway_positions/") &&
          this.entityIsEditable(p)
      );
    },
    nonPositionedSensors() {
      return this.nonPositions.filter(
        (p) => p.obj.path.startsWith("positions/") && this.entityIsEditable(p)
      );
    },
    nonPositionedSources() {
      return this.nonPositions.filter(
        (p) => p.obj.path.startsWith("sources/") && this.entityIsEditable(p)
      );
    },
    nonPositionedEntities() {
      return this.nonPositionedGateways.concat(
        this.nonPositionedSensors,
        this.nonPositionedSources
      );
    },
    widthDisplayRatio() {
      return this.imageClientWidth / this.imageFullWidth;
    },
    heightDisplayRatio() {
      return this.imageClientHeight / this.imageFullHeight;
    },
    site() {
      return this.$store.getters.site;
    },
    siteRef() {
      return this.site ? this.toFSRef(this.site) : null;
    },
  },
  methods: {
    entityIsEditable(entity) {
      if (this.editableEntityPaths === "all") {
        return true;
      } else if (this.editableEntityPaths) {
        return this.editableEntityPaths.includes(entity.obj.path);
      }
    },
    leave() {
      if (this.changesSinceLoad) {
        let self = this;
        this.$buefy.dialog.confirm({
          title: "Locations have changed",
          message:
            "Locations on this image have not been saved. Are you sure you wish to leave?",
          onConfirm() {
            self.queryRemoveKeys(["detailEdit"]);
          },
        });
      } else {
        this.queryRemoveKeys(["detailEdit"]);
      }
    },
    resizeEvent() {
      const evt = new Event("resize");
      setTimeout(() => window.dispatchEvent(evt), 100);
    },
    setImageClientDimensions() {
      this.imageClientWidth = this.$refs.canvas
        ? this.$refs.canvas.clientWidth
        : 0;
      this.imageClientHeight = this.$refs.canvas
        ? this.$refs.canvas.clientHeight
        : 0;
      this.resizeEvent();
    },
    entityParentId(obj) {
      let foundObj;
      if (obj.obj && obj.obj.path) {
        // is from ImageMap.positions arr
        foundObj = this.objectByID(obj.obj.id, null, [
          ...this.$store.getters.objectStack,
          ...this.sensorPositions,
          ...this.gatewayPositions,
        ]);
      }
      if (!foundObj) {
        return;
      }
      if (foundObj.room && foundObj.room.id) {
        return foundObj.room.id;
      } else if (foundObj.building && foundObj.building.id) {
        return foundObj.building.id;
      } else {
        return this.site ? this.site.id : null;
      }
    },
    load() {
      let self = this,
        filePath = self.fileObject.path;
      if (!filePath) {
        return;
      }
      self.loadingImageMap = true;
      self.$dataClasses.ImageMap.queryForSingle([["filename", "==", filePath]])
        .then((result) => {
          self.imageMapId = result.id;
          self.positions = result.positions;
          self.initialPositions = result.positions || [];
        })
        .catch((e) => self.$handleError("Could not load image map", e))
        .finally(() => {
          self.loadingImageMap = false;
        });
    },
    save() {
      let self = this;
      self.savingImageMap = true;

      self.$dataClasses.ImageMap.save(self.imageMapId, {
        filename: self.fileObject.path,
        positions: self.positions,
        id: self.imageMapId,
      })
        .then((result) => {
          self.positions = result.positions;
          self.imageMapId = result.id;
          self.$handleSuccess("Your data has been saved");
          self.changesSinceLoad = false;
        })
        .catch((e) => self.$handleError("Could not save location data", e))
        .finally(() => (self.savingImageMap = false));
    },
    collapseAll() {
      let refs = this.$refs;
      this.positions
        .map((p) => p.obj.id)
        .forEach((id) => {
          let refArr = refs["generic-display-" + id];
          if (refArr && refArr.length) {
            refArr.forEach((ref) => (ref.collapsed = true));
          }
        });
    },
    expandAll() {
      let refs = this.$refs;
      this.positions
        .map((p) => p.obj.id)
        .forEach((id) => {
          let refArr = refs["generic-display-" + id];
          if (refArr && refArr.length) {
            refArr.forEach((ref) => (ref.collapsed = false));
          }
        });
    },
    loadRelated() {
      this.loadSensorPositions();
      this.loadGatewayPositions();
    },
    loadSensorPositions() {
      this.sensorPositionsLoading = true;
      this.$dataClasses.Position.query([["site", "==", this.siteRef]])
        .then((results) => {
          this.sensorPositions = results;
        })
        .catch((e) => this.$handleError("Could not load sensor positions", e))
        .finally(() => (self.sensorPositionsLoading = false));
    },
    loadGatewayPositions() {
      let self = this;
      self.gatewayPositionsLoading = true;
      GatewayPosition.query([
        ["building", "in", self.buildings.map((b) => this.toFSRef(b))],
      ])
        .then((results) => (self.gatewayPositions = results))
        .catch((e) => self.$handleError("Could not load gateway positions", e))
        .finally(() => (self.gatewayPositionsLoading = false));
    },
    getStyleTag(pos) {
      // w/h of icon: 16px; use 8px offset
      let topDropOffset = -12,
        leftDropOffset = -16;
      let top = Math.round(
          pos.top * this.heightDisplayRatio +
            topDropOffset * this.heightDisplayRatio
        ),
        left = Math.round(
          pos.left * this.widthDisplayRatio +
            leftDropOffset * this.widthDisplayRatio
        );
      return `top: ${top}px;left: ${left}px`;
    },
    /**
     * Will remove draggable item from dropzone
     */
    resetPath(path) {
      this.positions = [...this.positions.filter((p) => p.obj.path !== path)];
    },
    /**
     *
     */
    getImageUrl(file) {
      return process.env.VUE_APP_API_URL + "/files/" + file.path;
    },
    onPosChanged(event) {
      function getOffset(el) {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
          _x += el.offsetLeft - el.scrollLeft;
          _y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
        }
        return { top: _y, left: _x };
      }

      let item = event.items[0],
        target = document.getElementById("aq-image-map-canvas"),
        targetOffset = getOffset(target),
        y = event.nativeEvent.pageY,
        x = event.nativeEvent.pageX,
        path = item.getAttribute("data-path");
      if (
        !x ||
        !y ||
        !event.droptarget ||
        !event.droptarget.id === "aq-image-map-canvas"
      ) {
        this.$handleError("Please drop item within the image boundaries");
        return;
      }
      let left =
          Math.round((x - targetOffset.left) * (1 / this.widthDisplayRatio)) -
          22,
        top =
          Math.round((y - targetOffset.top) * (1 / this.heightDisplayRatio)) -
          16;

      let pos = {
        left, // offset to use center of circle icon
        top, // offset to use center of circle icon
        obj: this.toFSRef(path.split("/")[1], path.split("/")[0]),
      };
      this.lastEvt = {
        left: left,
        top: top,
        path: path,
        event: event.nativeEvent,
      };
      // console.log(`onPosChanged (${left},${top})`, pos)
      this.positions = this.positions.filter((i) => i.obj.path !== path);
      this.positions.push(pos);
      this.positions = [...this.positions];
      this.changesSinceLoad = true;
    },
  },
  watch: {
    positions: {
      handler() {
        this.resizeEvent();
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler() {
        this.loadRelated();
      },
    },
    loaded: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.setImageClientDimensions();
            this.loadedTimeoutMet = true;
          }, 500);
        }
      },
    },
    parentObject: {
      immediate: true,
      handler: function () {
        this.loadRelated();
        this.load();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.draggable {
  background: transparent;
  z-index: 6;
  overflow: hidden;
  white-space: nowrap;

  &.non-editable {
    opacity: 0.5;
    z-index: 5;
  }

  &.positioned {
    position: absolute;
  }

  .boxy {
    border: 2px solid lightgrey;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.95);
    display: inline-block;
    padding: 0 2px;
  }

  .handler {
    cursor: pointer;
  }

  .handler,
  .no-handler {
    vertical-align: top;
    text-align: left;
  }
}

.aq-image-map-wrapper {
  margin: 0;
  padding: 0;
  position: relative;

  .aq-image-map-canvas {
    border: 2px solid darkgrey;
    margin: 0;
    padding: 0;
    object-fit: cover !important;
    width: unset;
    height: unset;
    flex-shrink: 0 !important;
  }
}

.ok {
  width: 2px;
  height: 2px;
  background-color: red;
  z-index: 100000;
  position: absolute;
}

.legend,
.non-positioned {
  border: 1px solid lightgrey;
  padding: 10px;
}

.unplaced-inner {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
