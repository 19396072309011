<template>
  <div class="sensor-detail-for-clip">
    <div class="mb-6">
      Sensor <b>{{ position.serial }}</b>
      <b-icon
          v-if="includedInAlarm"
          icon="check"
          type="is-success"
      />
      <b-icon
          v-else
          icon="times-circle"
          type="is-danger"
          class="is-outlined"
      />
    </div>
    <div
        v-if="areaSensorEntry"
        class="has-text-justified has-text-justified-left"
    >
      <b>Threshold: </b>
      <div class="ml-6">
        {{areaSensorEntry.settings.detect_threshold}}
        <b-icon icon="edit" type="is-primary" class="pointer" @click.native.prevent.stop="$emit('threshold-edit-clicked', {serial:position.serial})"/>
      </div>
    </div>
    <div
        v-if="amplitudeRatio !== null"
        class="has-text-justified has-text-justified-left"
    >
      <b>Amplitude ratio for event</b>:
      <div class="ml-6">
        {{ amplitudeRatio.toFixed(2) }}
        ({{ ((amplitudeRatio / maxAmplitudeRatio) * 100).toFixed(1) }}%)
      </div>
    </div>
    <hierarchy-objects-display :hierarchy-objects="position.$hierarchyObjects"/>
    <a
        class="mt-3 has-text-link"
        @click="position.$goToSensorDetail()"
    >
      View sensor detail
    </a>
  </div>
</template>

<script>
import HierarchyObjectsDisplay from "./HierarchyObjectsDisplay";
import Position from "../../dataClasses/Position";

export default {
  name: "SensorDetailForClip",
  components: {HierarchyObjectsDisplay},
  computed: {
    areaSensorEntry() {
      if (!(this.position && this.position.$area)) {
        return null
      }
      let matches = (this.position.$area.sensors || []).filter(sensorEntry => sensorEntry.serial === this.position.serial);
      return (matches && matches.length) ? matches[0] : null
    }
  },
  props: {
    amplitudeRatio: {
      type: Number,
      default: null
    },
    maxAmplitudeRatio: {
      type: Number,
      default: null
    },
    includedInAlarm: {
      type: Boolean,
      default: false
    },
    position: {
      type: Position,
      required: true,
    }
  }
}
</script>

<style scoped>

</style>