import Vue from "vue";

import apiClient from "./apiClient";
import App from "./applications/App.vue";
import fbAuthMixin from "./auth";
import mostRecentMessageNotificationMixin from "./mixins/mostRecentMessageNotificationMixin";
import router from "./router";
import { messaging } from "./firebase";
// App level css
import "@fortawesome/fontawesome-free/css/all.min.css";
// ApexCharts
import VueApexCharts from "vue-apexcharts";
// Buefy (front-end framework on top of bulma)
import Buefy from "buefy";
// PageVisibilityAPI wrapper; see https://github.com/Yuliang-Lee/vue-visibility-change
import visibility from "vue-visibility-change";
// Google Maps config
import * as VueGoogleMap from "vue2-google-maps";
// firebase config, use $fb.firebase to access FB components
import store from "./store";
// Prototype-level functions (more in firebase.js)
import registerVueFormulate from "./mixins/VueFormulateAppMixin";
import registerToastHandlers from "./registrars/toasts";
import registerDataClasses from "./registrars/dataClasses";
import "chartjs-plugin-zoom/chartjs-plugin-zoom";

import globalMixin from "./mixins/global";
import VueTelInput from "vue-tel-input";
import registerGlobalComponents from "./registrars/components";

// Dataviz
Vue.use(VueApexCharts);
Vue.component("apex-chart", VueApexCharts);
Vue.use(Buefy, { defaultIconPack: "fas" });
// Sets error reporting level, etc
Vue.productionTip = process.env.VUE_APP_ENVIRONMENT_NAME === "production";
Vue.use(VueTelInput, [{ defaultCountry: "US" }]);
Vue.use(visibility);
Vue.use(VueGoogleMap, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  },
});
Vue.prototype.$client = apiClient;
Vue.prototype.$db = store.$db;
Vue.prototype.$fb = store.$fb;
Vue.prototype.$messaging = messaging;
// Mounts globally available methods
Vue.mixin(globalMixin);
registerDataClasses();
registerToastHandlers();
registerVueFormulate();
// component registration
registerGlobalComponents();

// Main entry-point
export const app = new Vue({
  el: "#app",
  router,
  store,
  render: (h) => {
    return h(App);
  },
  mixins: [fbAuthMixin, mostRecentMessageNotificationMixin],
});

// TAB VISIBILITY: When tab visibility changes, emit app-level event
visibility.change((evt, hidden) => {
  // do something
  app.$emit("tabVisibilityChange", !hidden);
});

// For debug, attach app to window-level var in dev
window.app = app;
// window.app = process.env.VUE_APP_ENVIRONMENT_NAME !== "production" ? app : null;
