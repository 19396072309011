var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{attrs:{"data":_vm.trainingQuality,"title":"Training quality"}},[_c('b-table-column',{attrs:{"label":"Quality","field":"quality"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:props.row.quality === 'good'
                    ? 'has-text-success'
                    : 'has-text-danger has-text-weight-bold'},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(props.row.quality))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"POU","field":"source_nm"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('source-display',{attrs:{"value":_vm.$store.getters.objectByID(
                    props.row.source_nm.replace('SRC:', '')
                  )}})]}}])}),_c('b-table-column',{attrs:{"label":"Room"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                  _vm.$store.getters.objectByID(
                    props.row.source_nm.replace('SRC:', ''),
                    {},
                    _vm.$store.state.sources
                  ).$room
                )?_c('room-display',{attrs:{"value":_vm.$store.getters.objectByID(
                    props.row.source_nm.replace('SRC:', ''),
                    {},
                    _vm.$store.state.sources
                  ).$room,"show-icon":false,"show-floor":false,"show-unit":false,"show-link-icon":false}}):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Floor"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                  _vm.get(
                    _vm.$store.getters.objectByID(
                      props.row.source_nm.replace('SRC:', ''),
                      {},
                      _vm.$store.state.sources
                    ),
                    '$room.floor',
                    null
                  )
                )?_c('span',[_vm._v(" "+_vm._s(_vm.get( _vm.$store.getters.objectByID( props.row.source_nm.replace('SRC:', ''), {}, _vm.$store.state.sources ), '$room.floor', null ))+" ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Unit"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                  _vm.get(
                    _vm.$store.getters.objectByID(
                      props.row.source_nm.replace('SRC:', ''),
                      {},
                      _vm.$store.state.sources
                    ),
                    '$room.unit',
                    null
                  )
                )?_c('span',[_vm._v(" "+_vm._s(_vm.get( _vm.$store.getters.objectByID( props.row.source_nm.replace('SRC:', ''), {}, _vm.$store.state.sources ), '$room.unit', null ))+" ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Sensors","field":"sensor_count","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.sensor_count)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Amount (seconds)","field":"nb_seconds","sortable":"","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:!props.row.nb_seconds
                    ? 'has-text-danger'
                    : props.row.nb_seconds < 30
                    ? 'has-text-warning'
                    : 'has-text-success'},[_vm._v(" "+_vm._s((props.row.nb_seconds || 0).toFixed(1))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Suggestion"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{attrs:{"size":"is-small","type":props.row.quality === 'good' ? 'is-default is-outlined is-light' : 'is-success'},on:{"click":function($event){_vm.sourceClicked(_vm.$store.getters.objectByID(props.row.source_nm.replace('SRC:', '')))}}},[_vm._v(" Add training ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }