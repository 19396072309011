export default function dateFromISO8601(isoDateString) {
  let parts = isoDateString.match(/\d+/g),
    isoTime = Date.UTC(
      parts[0],
      parts[1] - 1,
      parts[2],
      parts[3],
      parts[4],
      parts[5]
    );
  return new Date(isoTime);
}
