<template>
  <div class="message-time mt-5 mb-0">
    <b-icon icon="clock" />
    <span class="message-time">
      {{ asReadableDate(alarm.last_update_dt || alarm.issue_dt) }}
    </span>
    <p v-if="readableDelta" class="mb-0 ml-1">
      <b-icon />
      <span v-if="readableDelta">{{ readableDelta }} ago</span>
    </p>
    <p v-else class="mb-0 ml-6">
      <b-icon icon="sync" class="fa-spin" size="is-small" />
    </p>
  </div>
</template>

<script>
import isUserMessageMixin from "../../../mixins/isUserMessageMixin";

export default {
  name: "MessageTimeDetail",
  mixins: [isUserMessageMixin],
  props: {
    alarmObject: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
