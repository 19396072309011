/*
 Vue mixin that adds window.height and window.width data (live updated)
 to component

 @example

     import WindowSizeMixin from "./trackWindowSize";
     import Vue from "vue";

     export default {
        name: "MyComponent",
        mixins: [WindowSizeMixin],
        ...
     }

 */

export default {
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
    isTablet() {
      return this.windowWidth >= 769 && this.windowWidth < 1024;
    },
    isDesktop() {
      return this.windowWidth >= 1024;
    },
    isWidescreen() {
      return this.windowWidth >= 1216;
    },
    isFullHD() {
      return this.windowWidth > 1408;
    },
    nineTenthsWidth() {
      return Math.round(this.windowWidth * 0.9);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.onResize();
    },
    onResize() {},
  },
};
