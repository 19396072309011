<template>
  <b-taginput
    @keyup.native.esc.prevent.stop="(evt) => evt.preventDefault()"
    ref="taginput"
    :value="value"
    allow-new
    autocomplete
    placeholder="Add a tag/group"
    :attached="attached"
    :data="filteredTags"
    :has-counter="hasCounter"
    :maxtags="maxtags"
    :open-on-focus="true"
    maxlength="15"
    :create-tag="(tagToAdd) => tagToAdd.toString().toLowerCase()"
    @typing="getFilteredTags"
    @input="onChange"
  ></b-taginput>
</template>

<script>
import store from "../../store";
export default {
  name: "TagSelect",
  data() {
    return {
      filteredTags: store.getters.tagsForObjectsInArray(
        this.relatedTaggedObjects,
        "",
        this.value
      ),
    };
  },
  props: {
    attached: {
      type: Boolean,
      default: false,
    },
    hasCounter: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxtags: {
      type: Number,
      default: 8,
    },
    relatedTaggedObjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    getFilteredTags() {
      let textInput = this.$refs.taginput.$refs.autocomplete.newValue;
      this.filteredTags = this.$store.getters.tagsForObjectsInArray(
        this.relatedTaggedObjects,
        textInput,
        this.value
      );
    },
    onChange(val) {
      this.$emit("input", (val||[]).sort(this.alphanumericSort));
    },
  },
  watch: {
    value: {
      handler() {
        this.$nextTick(this.getFilteredTags);
      },
    },
  },
};
</script>

<style scoped></style>
