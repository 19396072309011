<template>
  <div class="file-modal-wrapper mt-5 mb-5">
    <b-modal
      :width="nineTenthsWidth"
      has-modal-card
      :active="active"
      @close="changeActive(false, null)"
    >
      <file-attachment-component
        v-if="active"
        :object="object"
        :categories="categories"
        :category="selectedCategory"
      />
    </b-modal>
    <div class="buttons" v-if="!active">
      <b-button
        v-for="cat in categories"
        @click="changeActive(!active, cat)"
        :key="cat"
      >
        {{ active ? "Hide" : "Show" }} <b>{{ cat.replace("_", " ") }}</b> files
      </b-button>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import FileAttachmentComponent from "./FileAttachmentComponent";
import trackWindowSize from "../../mixins/trackWindowSize";

const allCategories = ["document", "floor_plan", "install_picture"];

export default {
  name: "FileAttachmentModal",
  mixins: [trackWindowSize],
  components: {
    FileAttachmentComponent,
  },
  data() {
    return {
      selectedCategory: this.$route.query.fileModal,
    };
  },
  props: {
    canUpload: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      default: () => allCategories,
    },
    classes: {
      type: String,
      default: "",
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeActive(val, category) {
      this.selectedCategory = category;
      let q = cloneDeep(this.$route.query);
      if (val && !q.fileModal) {
        q.fileModal = category;
      } else if (q.fileModal) {
        delete q.fileModal;
      }
      if (val) {
        this.queryReplace(q);
      } else {
        this.queryRemoveKeys(["fileModal"]);
      }
    },
  },
  computed: {
    active() {
      return !!this.$route.query.fileModal;
    },
  },
};
</script>

<style scoped></style>
