<template>
  <vue-apex-charts
    ref="apex-chart"
    type="bar"
    :height="height"
    :id="chartId"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BarChart",
  components: { VueApexCharts },
  props: {
    dataLabelsEnabled: {
      type: Boolean,
      default: false,
    },
    dataLabelsFormatter: {
      type: Function,
      default: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
    },
    fontSize: {
      type: String,
      required: false,
    },
    chartId: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    /**
     * @type {Array.<{value:Number, label: String, color: (null|String)}>}
     */
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tooptipFunction: {
      type: Function,
      required: true,
    },
    xAxisLabelsEnabled: {
      type: Boolean,
      default: false,
    },
    xAxisFormatter: {
      type: Function,
      required: false,
    },
    switcheroo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    series() {
      return [
        {
          data: this.rows.map((row) => row.value),
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: this.height,
          toolbar: {
            show: false,
          },
          width: "100%",
          id: this.chartId,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$emit("click-row", this.rows[config.dataPointIndex]);
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "bottom", // use 'bottom' for left and 'top' for right align
            },
          },
        },
        legend: {
          fontSize: this.fontSize,
          showForZeroSeries: false,
        },
        dataLabels: this.dataLabelsEnabled
          ? {
              enabled: true,
              textAnchor: "start",
              style: {
                colors: ["#000"],
                fontSize: this.fontSize,
              },
              formatter: this.dataLabelsFormatter,
              dropShadow: {
                enabled: true,
                color: "#fff",
              },
            }
          : { enabled: false },
        xaxis: {
          categories: this.rows.map((row) => row.label),
          labels: {
            formatter: this.xAxisFormatter,
            hideOverlappingLabels: true,
            count: 3,
            max: 3,
            style: {
              fontSize: this.fontSize,
            },
          },
          tickAmount: 3,
          tickPlacement: "on",
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          custom: this.tooptipFunction,
          fontSize: this.fontSize,
          style: {
            fontSize: this.fontSize,
          },
        },
        yaxis: {
          labels: {
            show: this.xAxisLabelsEnabled,
            minWidth: this.xAxisLabelsEnabled ? 80 : 0,
          },
        },
        _yolo: this.switcheroo,
      };
    },
  },
};
</script>

<style scoped></style>
