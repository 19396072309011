import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import router from "../router";
import shortHumanizer from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import {toDate} from "../appLevelFunctions/toDate";
import Sensor from "./Sensor";
import store from "../store";
import toId from '../appLevelFunctions/toId';

export const healthyIfSeenWithinMinutes = 16.5;

export default class GatewayPosition extends FirestoreDataClass {
  static collectionName = "gateway_positions";

  get $qrCodeUrl() {
    if (!this.serial) {
      return "";
    }
    return this.$client.basePath + "/devices/qr_code/?serial=" + this.serial;
  }

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site, null)
      : null;
  }

  get $area() {
    return this.parent
      ? getObjectInArrayById(store.state.areas, this.parent, null)
      : null;
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building, null)
      : null;
  }

  get $room() {
    return this.room
      ? getObjectInArrayById(store.state.rooms, this.room.id, null)
      : null;
  }

  get $recordInArea() {
    return this.$area
      ? this.$area.gateways.filter((gw) => gw.serial === this.serial)[0]
      : null;
  }

  get $connectWeights() {
    return this.$recordInArea
      ? this.$recordInArea.connect_weights.filter((cw) => !!cw.weight)
      : [];
  }

  get $lastSeen() {
    let val = this.get("telemetry.status.timestamp");
    return toDate(val);
  }

  get $msSinceLastSeen() {
    const val = this.$lastSeen;
    return this.$lastSeen ? new Date() - val.getTime() : null;
  }

  get $secondsSinceLastSeen() {
    return this.$msSinceLastSeen
      ? Math.floor(this.$msSinceLastSeen / 1000)
      : null;
  }

  get $sinceLastSeenText() {
    return this.$msSinceLastSeen
      ? "Last seen " +
          shortHumanizer(this.$secondsSinceLastSeen * 1000).replaceAll(
            " ",
            ""
          ) +
          " ago"
      : "No record of last connection";
  }

  get $lastSeenClassification() {
    if (
      !this.$msSinceLastSeen ||
      this.$secondsSinceLastSeen > 60 * healthyIfSeenWithinMinutes
    ) {
      return "danger";
    } else {
      return "success";
    }
  }

  get $isHealthy() {
    return [this.$lastSeenClassification === "success"].every((e) => !!e);
  }

  get $notHealthyText() {
    const issues = [];
    if (this.$lastSeenClassification !== "success") {
      issues.push(this.$sinceLastSeenText);
    }
    return issues.join("; ");
  }

  get $lastSeenBClass() {
    return `is-${this.$lastSeenClassification}`;
  }

  get $lastSeenTextClass() {
    return `has-text-${this.$lastSeenClassification}`;
  }

  /**
   * @returns Promise
   */
  $getConnectedSensors() {
    let sensorRefs = this.$connectWeights.map((cw) => cw.sensor);
    if (!sensorRefs.length) {
      return Promise.resolve([]);
    }
    return Sensor.query([["id", "in", sensorRefs.map((ref) => ref.id)]], {
      order_field: "id",
    });
  }

  $goToRemoveGateway() {
    router.push({
      name: "remove-gateway",
      query: {
        serial: this.serial,
        building: toId(this.get("building")),
      },
    });
  }

  $goToAddSensor(qParams) {
    router.push({
      name: "add-sensor",
      query: {
        building: toId(this.get("$building")),
        gatewaySerial: this.serial,
        ...qParams,
      },
    });
  }

  $goToRemoveSensors(qParams) {
    router.push({
      name: "remove-sensor",
      query: {
        building: toId(this.get("building")),
        gatewaySerial: this.serial,
        ...qParams,
      },
    });
  }

  get $deviceType() {
    return "gateway";
  }
}
