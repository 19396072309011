import { cloneDeep } from "lodash";

export function copyFirestoreData(firestoreObject, extraKeysToDelete = []) {
  const keysToDelete = ["id", "_about", "_reference", ...extraKeysToDelete],
    obj = cloneDeep(firestoreObject);
  for (const key of keysToDelete) {
    if (key in obj) {
      delete obj[key];
    }
  }
  return obj;
}
