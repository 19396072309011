<template>
  <b-modal
    :active.sync="active"
    @close="$emit('update:active', false); $emit('close')"
    can-cancel
    has-modal-card
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          <b-icon type="is-danger" icon="exclamation-circle" class="mr-1" />
          Exit training mode
        </div>
      </div>
      <div class="modal-card-body">
        <b-message
          has-icon
          icon-left="info-circle"
          type="is-info"
          title="About training mode"
        >
          <p>
            <b>Training mode</b> is how your the devices in an area associate activity with a
            particular point-of-use.
          </p>

          <p class="mt-3">
            <a @click="area.$goToTrainingOverview()">Click here</a> to return to training.
          </p>

          <p class="mt-3">
            To learn more, click
            <a
              href="https://wiki.aquaseca.com/en/user-manual/calibration"
              target="_blank"
              >here</a
            >.
          </p>
        </b-message>

        <b-loading :is-full-page="false" :active="loading" />

        <p class="mb-3 has-text-centered has-text-weight-bold">
          Please select which sensors you'd like to take out of training mode:
        </p>
        <div >
          <b-table
            v-if="positions && positions.length"
            :data="positions"
            :loading="loading"
            :checkable="false"
            :default-sort="['serial']"
            default-sort-direction="asc"
          >
            <b-table-column
              field="serial"
              sortable
              label="Serial"
              v-slot="props"
            >
              {{ props.row.serial }}
            </b-table-column>
            <b-table-column
              field="$calibrationState"
              label="Current state"
              sortable
              v-slot="props"
            >
              {{
                capitalizeFirstLetter(
                  get(props.row, "$calibrationState", "Unknown")
                )
              }}
            </b-table-column>
            <b-table-column
              field="$calibrationState"
              label="Since"
              sortable
              v-slot="props"
            >
              {{
                get(props.row, "analytics.calibration.timestamp", "")
                  ? get(
                      props.row,
                      "analytics.calibration.timestamp",
                      ""
                    ).toLocaleDateString("en-US") +
                    " " +
                    get(
                      props.row,
                      "analytics.calibration.timestamp",
                      ""
                    ).toLocaleTimeString("en-US")
                  : ""
              }}
            </b-table-column>
            <b-table-column
              field="$building.name"
              label="Area"
              sortable
              v-slot="props"
            >
              <building-display
                :value="props.row.$building"
                v-if="props.row.$building"
                :show-training-mode-warning="false"
              />
            </b-table-column>
            <b-table-column
              field="$room.name"
              label="Room"
              sortable
              v-slot="props"
            >
              <room-display :value="props.row.$room" v-if="props.row.$room" />
            </b-table-column>
          </b-table>
          <div v-else class="box">
            <div class="has-text-centered">
              There are no sensors currently in training mode
            </div>
          </div>

          <div class="m-6">
            <div class="is-text is-size-5 has-text-centered">
              Click a button below to exit training mode:
            </div>
            <b-button
              expanded
              class="mt-2"
              type="is-primary"
              @click="submitClicked(true)"
            >
              Exit training mode and build model
            </b-button>
            <b-button
              expanded
              class="mt-2"
              type="is-primary is-light"
              @click="submitClicked(false)"
            >
              Exit without building model
            </b-button>
          </div>
        </div>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button expanded @click="$emit('update:active', false)">
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";
import Area from "../../dataClasses/Area";
import { get } from "lodash";

export default {
  name: "SelectSensorsToTakeOutOfTrainingMode",
  mixins: [trackWindowSize],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Area,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    positions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedRows: [],
      buildModel: true,
    };
  },
  methods: {
    get,
    /**
     *
     * @param createModel {Boolean}
     */
    submitClicked(createModel) {
      this.$emit("input", {
        createModel,
        area: this.area,
      });
      this.$emit("update:active", false);
    },
    initializeCheckedRows() {
      this.buildModel = true;
      this.checkedRows = this.positions.filter((p) => p && p.$isInTrainingMode);
    },
  },
  watch: {
    positions: {
      handler() {
        this.initializeCheckedRows();
      },
    },
    active: {
      immediate: true,
      handler(is, was) {
        this.initializeCheckedRows();
        if (!is && was) {
          this.$route.query.next ? this.$router.push(this.$route.query.next) : null
        }
      },
    },
  },
};
</script>

<style scoped></style>
