<template>
  <div class="aq-image" ref="aqImageWrapper">
    <img
      v-if="forSmallRow"
      :class="'small ' + classesToInject"
      :src="src"
      @load="onThumbnailLoad()"
      @click.prevent="
        thumbnailClicked = true;
        isImageModalActive = true;
      "
      alt="AquaSeca, Inc."
    />
    <b-image
      :class="classesToInject"
      :src="src"
      :src-fallback="NotAvailableSrc"
      :responsive="responsive"
      @load="onThumbnailLoad()"
      @click.native.prevent="
        thumbnailClicked = true;
        isImageModalActive = true;
      "
      v-else
    >
      <template #caption>
        <slot name="caption" />
      </template>
      <template #placeholder>
        <slot name="placeholder">
          <p class="has-text-centered">
            <b-loading active :is-full-page="false" />
          </p>
        </slot>
      </template>
    </b-image>
    <b-button
      size="is-small"
      rounded
      title="Delete this file"
      v-show="thumbnailImageLoaded"
      :icon-left="iconFor('delete')"
      type="is-danger"
      v-if="hasDeleteButton"
      class="is-image-button is-delete-button"
      :loading="isBeingDeleted"
      @click.prevent="$emit('delete-clicked')"
    />
    <b-button
      size="is-small"
      rounded
      title="Edit file metadata"
      v-show="thumbnailImageLoaded"
      :icon-left="iconFor('edit')"
      :disabled="isBeingDeleted"
      type="is-primary"
      v-if="hasEditButton"
      class="is-image-button is-edit-button"
      @click.prevent="$emit('edit-clicked')"
    />
    <slot />
    <div v-if="thumbnailClicked">
      <b-loading :active="!modalImageLoaded" is-full-page />
      <b-modal v-model="isImageModalActive">
        <p class="image">
          <b-image
            :src="src"
            @load="onModalImageLoad()"
            @error="
              modalImageLoaded = false;
              isImageModalActive = false;
            "
          />
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import iconFor from "../../icons";
import NotAvailableSrc from "../../assets/General/previewNotAvailable.png";

export default {
  name: "AqImage",
  props: {
    classes: {
      type: String,
      default: "",
    },
    isBeingDeleted: {
      type: Boolean,
      default: false,
    },
    hasEditButton: {
      type: Boolean,
      default: false,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
      required: true,
    },
    forSmallRow: {
      type: Boolean,
      default: false,
    },
    enableModal: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: null,
    }
  },
  computed: {
    classesToInject() {
      return this.classes + this.enableModal ? " pointer " : "";
    },
  },
  data() {
    return {
      NotAvailableSrc,
      thumbnailClicked: false,
      isImageModalActive: false,
      modalImageLoaded: false,
      thumbnailImageLoaded: false,
    };
  },
  methods: {
    iconFor,
    onThumbnailLoad() {
      this.$emit("load", true);
      this.thumbnailImageLoaded = true;
    },
    onModalImageLoad() {
      this.modalImageLoaded = true;
    },
    onClose() {
      this.isImageModalActive = false;
    },
  },
  watch: {
    isImageModalActive: {
      handler(val) {
        if (val) {
          this.modalImageLoaded = false;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
img.small {
  height: 150px !important;
  width: auto !important;
}

.is-image-button {
  position: relative;
  z-index: 5;
  display: inline-block;
  &.is-delete-button {
    top: 5px;
    right: 42px;
  }
  &.is-edit-button {
    top: 114px;
    right: 78px;
  }
}

.high-z {
  z-index: 100000;
}
</style>
