import { render, staticRenderFns } from "./SystemStatusTile.vue?vue&type=template&id=873680fa&scoped=true&"
import script from "./SystemStatusTile.vue?vue&type=script&lang=js&"
export * from "./SystemStatusTile.vue?vue&type=script&lang=js&"
import style0 from "./SystemStatusTile.vue?vue&type=style&index=0&id=873680fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873680fa",
  null
  
)

export default component.exports