var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"probe-detail-wrapper is-fullwidth",staticStyle:{"width":"100%"}},[_c('table',{staticClass:"table is-striped is-bordered is-fullwidth"},[_c('tbody',_vm._l((_vm.info),function(value,name){return _c('tr',{key:name},[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(name))]),(name === 'Installed')?_c('td',[_c('b-icon',{attrs:{"size":"is-small","icon":value ? 'check' : 'times',"type":`is-${value ? 'success' : 'danger'}`}})],1):(name === 'Sensor' && value)?_c('td',[_c('router-link',{attrs:{"to":{ name: 'devices:sensor-detail', params: { serial: value } }}},[_vm._v(_vm._s(value)+" ")])],1):(name === 'Shipped to')?_c('td',[(value)?_c('router-link',{attrs:{"to":{
              name: 'administration:view-subcustomer',
              params: { id: value.id },
            }}},[_vm._v(" "+_vm._s(value.$displayString)+" ")]):_c('i',[_vm._v("n/a")])],1):(name === 'Bundle')?_c('td',[(value)?_c('div',[(_vm.$store.state.user_roles.is_root)?_c('router-link',{attrs:{"to":{
                name: 'administration:shipments:view',
                params: { id: value.id },
              }}},[_vm._v(" "+_vm._s(value.$displayString)+" ")]):_c('span',[_vm._v(_vm._s(value.$displayString))])],1):_c('div',[_c('i',[_vm._v("n/a")])])]):_c('td',[(!!value)?_c('span',[_vm._v(_vm._s(value))]):_c('i',[_vm._v("n/a")])])])}),0)]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }