<template>
  <div>
    <div class="location-wrapper">
      <table :class="['table'].concat(tableClasses)">
        <tbody style="overflow-x: scroll">
        <tr v-for="(value, name) in rows" :key="name">
          <td class="has-text-weight-bold">{{ name }}</td>
          <td class="has-pre">
            <router-link v-if="linkFor(name, value)" :to="linkFor(name, value)">
              {{
                typeof value === "object"
                    ? value.$displayString || value.name
                    : value
              }}
            </router-link>
            <span v-else
            >&nbsp;{{
                typeof value === "object"
                    ? value.$displayString || value.name
                    : value
              }}</span
            >
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <slot></slot>
    <div v-if="allowEdit">
      <b-button
          expanded
          icon-left="edit"
          @click="modalOpen = true"
          class="mb-5"
          v-if="rows.Site"
      >
        Edit install location
      </b-button>
      <building-and-room-modal
          v-if="position"
          :active.sync="modalOpen"
          :name="`${position.$deviceType} ${position.serial}`"
          :site="rows.Site"
          :building-editable="false"
          :object="position"
          :initial-pipe-location-id="position.pipe_location ? toId(position.pipe_location) : null"
          :patch-endpoint="`/${position.APIEndpointSegment}/${position.id}/`"
          @close="modalOpen = false"
          show-notes
          :show-pipe-location="showPipeLocation"
      >
        <div class="has-text-danger" v-if="warningInModal">
          {{warningInModal}}
        </div>
      </building-and-room-modal>
    </div>
  </div>
</template>

<script>
import {get} from "lodash";
import BuildingAndRoomModal from "../Selects/BuildingAndRoomModal";
import {getObjectInArrayById} from "../../scripts/filterHelpers";

export default {
  name: "InstallLocation",
  components: {BuildingAndRoomModal},
  props: {
    allowEdit: {
      type: Boolean,
      default: false,
    },
    tableClasses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    position: {
      type: Object,
      required: true,
    },
    probeSerial: {
      type: String,
      required: false,
    },
    showPipeLocation: {
      type: Boolean,
      default: false,
    },
    showPipeLocationOnly: {
      type: Boolean,
      default: false,
    },
    warningInModal: {
      type: String,
      default: null
    }
  },
  methods: {
    linkFor(name, value) {
      try {
        if (
            this.$store.state.user_roles.is_admin &&
            ["Site", "Area", "Room"].includes(name) &&
            value &&
            value.id
        ) {
          return this.$router.match({
            name: `administration:view-${name.toLowerCase()}`,
            params: {id: value.id},
          });
        } else if (name === "Pipe location" && value && value.id) {
          return this.$router.match({
            name: "administration:view-pipe-location",
            params: {id: value.id},
          });
        }
      } catch (e) {
        return;
      }
    },
  },
  computed: {
    modalOpen: {
      get() {
        return this.$route.query.hasOwnProperty('modalBR');
      },
      set(modalBR) {
        modalBR ? this.queryPush({modalBR}) : this.queryRemoveKeys(['modalBR'])
      },
    },
    plId() {
      if (!this.position) {return}
      return this.toId(get(this.position, 'pipe_location', null));
    },
    pipeLocation() {
      return getObjectInArrayById(
          this.$store.state.pipe_locations,
          this.plId,
          null
      );
    },
    rows() {
      if (this.showPipeLocationOnly) {
        return {
          "Site": this.pipeLocation ? this.pipeLocation.$site : {name: "Not assigned"},
          "Area": this.pipeLocation ? this.pipeLocation.$building: {name: "Not assigned"},
          "Room": this.pipeLocation ? this.pipeLocation.$room : {name: "Not assigned"},
          "Pipe location": this.pipeLocation || {name: "Not assigned"}
        }
      }
      if (!this.position || !Object.keys(this.position).length) {
        let obj = {};
        ["Site", "Area", "Room"].forEach((k) => {
          obj[k] = "Loading...";
        });
        return obj;
      }
      let _ = {},
          parentArea = getObjectInArrayById(
              this.$store.state.areas,
              this.position.parent,
              {name: "Not found"}
          );
      _["Site"] = this.position.site
          ? getObjectInArrayById(
              this.$store.state.sites,
              this.position.site.id,
              {name: "Not found"}
          )
          : parentArea && parentArea.site
              ? getObjectInArrayById(
                  this.$store.state.sites,
                  parentArea.site,
                  {name: "Not found"}
              )
              : "Not assigned";
      if (this.$store.state.user_roles.is_root) {
        _["Area (internal)"] = parentArea ? parentArea : {name: "Not found"};
      }
      _["Area"] =
          this.position.buildingId || this.position.building
              ? getObjectInArrayById(
                  this.$store.state.buildings,
                  this.position.buildingId || this.position.building,
                  {name: "Not found"}
              )
              : "Not assigned";
      _["Room"] =
          this.position.roomId || this.position.room
              ? getObjectInArrayById(
                  this.$store.state.rooms,
                  this.position.roomId || this.position.room,
                  {$displayString: "Not found"}
              )
              : "Not assigned";
      if (this.showPipeLocation) {
        _["Pipe location"] = this.pipeLocation || {name: "Not assigned"}
      }
      if (this.position.installation_notes) {
        _["Notes"] = this.position.installation_notes;
      }
      return _;
    },
  },
};
</script>

<style scoped>
.has-pre {
  white-space: pre;
}

.location-wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}
</style>
