<template>
  <vue-apex-charts
    ref="apex-chart"
    type="pie"
    :height="height"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "PieChart",
  components: { VueApexCharts },
  props: {
    fontSize: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      required: true,
    },
    /**
     * [{
     *  label: String,
     *  value: Number,
     * }...]
     */
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    labelFormatter: {
      type: Function,
      default: (label) => label,
    },
    tooptipFunction: {
      type: Function,
      required: true,
    },
    switcheroo: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    series() {
      return this.rows.map((row) => row.value);
    },
    labels() {
      return this.rows.map((row) =>
        this.labelFormatter(row && row.label ? row.label : "")
      );
    },
    colors() {
      const colorsFromData = this.rows.map((r) => r.color).filter((c) => !!c),
        useCustomColors = colorsFromData.length === this.rows.length;
      return useCustomColors ? colorsFromData : null;
    },
    chartOptions() {
      return {
        colors: this.colors,
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.$emit("click-row", this.rows[config.dataPointIndex]);
            },
          },
        },
        labels: this.labels,
        dataLabels: {
          style: {
            fontSize: this.fontSize,
          },
        },
        legend: {
          position: "bottom",
          fontSize: this.fontSize,
          show: this.showLegend,
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          custom: this.tooptipFunction,
          fontSize: this.fontSize,
          style: {
            fontSize: this.fontSize,
          },
        },
        yaxis: {
          labels: {
            minWidth: 40,
            fontSize: this.fontSize,
          },
        },
        _yolo: this.switcheroo,
      };
    },
  },
};
</script>

<style scoped></style>
