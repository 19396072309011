import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import router from "../router";
import { get } from "lodash";
import store from "../store";
import toId from '@appLevelFunctions/toId';

export default class Area extends FirestoreDataClass {
  static collectionName = "areas";

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  /**
   *
   * @returns {Array[{position_model_count:Number,position_model_timestamp:String,sensor_count:Number,source:firebase.firestore.DocumentReference, source_nm:String, std_log_spectrum_max:Number}]}
   */
  get $classifiedSourceArray() {
    return get(this, "analytics.classified_sources", null) || [];
  }

  get $devices() {
    return [
      ...(this.gateways || []),
      ...(this.sensors || []),
      ...(this.probes || []),
    ];
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building.id, null)
      : null;
  }

  get $rooms() {
    if (!this.id) {
      return [];
    }
    return store.state.rooms.filter((s) => s.area && s.area.id === this.id);
  }

  get $sources() {
    if (!this.id) {
      return [];
    }
    return store.state.sources.filter((s) => s.area && s.area.id === this.id);
  }

  get $calibrationState() {
    return this.get("analytics.calibration.state", "Unknown");
  }

  /**
   * Whether is currently in training mode
   * @returns {boolean}
   */
  get $isInTrainingMode() {
    return ["precalibration", "recalibration"].includes(this.$calibrationState);
  }

  get $operatingState() {
    return this.get("analytics.operating.state", "Unknown");
  }

  get $gatewayCount() {
    return (this.get("gateways", []) || []).length;
  }

  get $sensorCount() {
    return (this.get("sensors", []) || []).length;
  }

  $getSensorEntry(serial) {
    return (
      (this.get("sensors") || []).filter((s) => s.serial === serial) || null
    );
  }

  get $probeCount() {
    return (this.get("probes", []) || []).length;
  }

  $goToAreaDevicesDetail(query = {}) {
    return router.push({
      name: "devices:area-detail",
      params: { areaId: this.id },
      query,
    });
  }

  $goToAutoThresholdDialog({ next = null, _query = {} }) {
    let query = { autoThresholdModal: true, ..._query };
    return this.$goToAreaDevicesDetail().then(() => {
      router.replace({
        query: {
          ...query,
          next: next,
        },
      });
    });
  }

  /**
   *
   * @param {String} next
   * @param {object} _query
   * @returns {Promise<Route>}
   */
  $goToTurnOffTrainingMode({ next = null, _query = {} }) {
    let query = { aTab: "system", ..._query };
    return this.$goToAreaDevicesDetail({
      ...query,
      next,
      trainingOffModal: true,
    });
  }

  $goToTrainingOverview(query={}) {
    return this.$building.$goToTrainingOverview(query);
  }

  $goToAddGateway(qParams) {
    return router.push({
      name: "add-gateway",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToRemoveGateway(qParams) {
    return router.push({
      name: "remove-gateway",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToAddSensor(qParams) {
    return router.push({
      name: "add-sensor",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToRemoveSensor(qParams) {
    return router.push({
      name: "remove-sensor",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToAttachProbe(qParams) {
    return router.push({
      name: "attach-probe",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToRemoveProbe(qParams) {
    return router.push({
      name: "remove-probe",
      query: { building: toId(this.get("building")), ...qParams },
    });
  }

  $goToSystemDetail(query = {}) {
    return this.$goToAreaDevicesDetail(query);
  }

  /**
   *
   * @param enableForMinutes
   * @return {Promise<unknown>}
   */
  $enableRanging(enableForMinutes = 5) {
    function addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    }

    let now = new Date(),
      self = this,
      rangeUntil = addMinutes(now, enableForMinutes);
    return new Promise(function (resolve, reject) {
      self
        .performAction("update_in_transaction", {
          "default_settings.enable_ranging_until": rangeUntil.toISOString(),
        })
        .then((response) => resolve(response))
        .catch((e) => reject(e));
    });
  }

  $startAutoThreshold(payload) {
    const self = this;
    return this.performAction("start_autothreshold", payload);
  }

  $stopAutoThreshold(payload = {}) {
    const self = this;
    return this.performAction("stop_autothreshold", payload);
  }

  /**
   *
   * @param {object} json
   * @return {Promise<Object|Error>}
   */
  $enterTrainingMode(json = {}) {
    return this.performAction("continue_calibration", json);
  }

  /**
   *
   * @return {Promise<Area|Error>}
   */
  $exitTrainingMode(createModel = false) {
    const self = this;
    return new Promise(function (resolve, reject) {
      self
        .performAction("stop_calibration")
        .then((response) => {
          if (response && response.success && createModel) {
            return self.performAction("create_model");
          } else if (response.success) {
            return Promise.resolve(response);
          } else {
            throw new Error("Error exiting training mode");
          }
        })
        .then((_response) => {
          if (_response.success) {
            return self.refresh().then((r) => resolve(r));
          } else {
            reject("Model creation not successful");
          }
        })
        .catch((e) => reject(e));
    });
  }
}
