<template>
  <div class="threshold-edit">
    <div class="table-wrapper">
      <table class="table is-fullwidth">
        <tr
            class="threshold-row"
            v-for="(sensor, index) in (internalArea.sensors || [])"
            :key="sensor.serial"
        >
          <td style="width: 20%;" v-if="positionFor(sensor.serial)">
            <live-sensor-display
                disable-tooltip
                :position="sensor"
                :show-signal-indicator="false"
                :show-battery="false"
            />
          </td>
          <td style="width: 80%" v-if="positionFor(sensor.serial) && sensorsClone && sensorsClone.length">
            <b-field
                v-if="positionFor(sensor.serial) && ! $store.state.loading.rooms && !$store.state.loading.pipe_locations"
                grouped
            >
              <b-field
                  label="Room"
                  :class="{'mr-6':!positionFor(sensor.serial).$room}">
                <room-display
                    v-if="positionFor(sensor.serial).$room"
                    classes="is-inline-block"
                    :value="positionFor(sensor.serial).$room"
                    :show-link-icon="false"
                />
                <i v-else>None</i>
              </b-field>
              <b-field label="Pipe location">
                <pipe-location-display
                    v-if="positionFor(sensor.serial).$pipeLocation"
                    classes="is-inline-block"
                    :value="positionFor(sensor.serial).$pipeLocation"
                    :show-link-icon="false"
                    :show-temp-icon="false"
                    :show-tooltip="false"
                />
                <i v-else>None</i>
              </b-field>
            </b-field>
            <b-field
                grouped
                v-if="!showAsSlider.includes(index)"
                label="Threshold"
                :type="isValidThresholdInput(sensorsClone[index].settings.detect_threshold) ? null:'is-danger'"
            >
              <b-input
                  step=".00000000000000001"
                  :min="minThreshold"
                  :max="maxThreshold"
                  type="number"
                  :value="sensorsClone[index].settings.detect_threshold"
                  @input="$set(sensorsClone[index].settings, 'detect_threshold', parseFloat($event))"
              />
              <p class="control">
                <b-button
                    icon-left="times"
                    title="reset"
                    :disabled="sensorsClone[index].settings.detect_threshold === sensor.settings.detect_threshold"
                    @click="sensorsClone[index].settings.detect_threshold = sensor.settings.detect_threshold"
                />
              </p>
            </b-field>
            <b-field
                v-else
                grouped
                label="Threshold"
                :type="isValidThresholdInput(sensorsClone[index].settings.detect_threshold) ? null:'is-danger'"
            >
              <b-slider
                  style="margin-top: 12px; margin-bottom: 12px;"
                  :min="minThreshold"
                  :max="maxThreshold"
                  :step="sensorsClone[index].settings.detect_threshold < 10 ? 0.001 : 1"
                  :value="sensorsClone[index].settings.detect_threshold"
                  :type="isValidThresholdInput(sensorsClone[index].settings.detect_threshold) ? 'is-primary':'is-danger'"
                  @input="sensorsClone[index].settings.detect_threshold = round($event, $event <= 10 ? 2: 0)"
              />
              <p class="control">
                <b-button
                    class="ml-3 mt-3"
                    icon-left="times"
                    title="reset"
                    size="is-small"
                    :disabled="sensorsClone[index].settings.detect_threshold === sensor.settings.detect_threshold"
                    @click="sensorsClone[index].settings.detect_threshold = sensor.settings.detect_threshold"
                />
              </p>
            </b-field>
            <b-field>
              <b-button
                  size="is-small"
                  @click="
                      sensorsClone[index].settings.detect_threshold = sensor.settings.detect_threshold;
                      showAsSlider.includes(index) ? showAsSlider = showAsSlider.filter(v=>v!==index) : showAsSlider.push(index)
                    ">
                Enter as <span v-if="!showAsSlider.includes(index)">slider</span><span v-else>number</span>
              </b-button>
            </b-field>
            <b-field
                type="is-danger"
                v-if="!isValidThresholdInput(sensorsClone[index].settings.detect_threshold)"
                :message="isValidThresholdInput(sensorsClone[index].settings.detect_threshold) ? 'Valid' : 'Please insert a valid value'"
            />
            <div class="buttons">
              <b-button
                  type="is-primary is-outlined"
                  @click="$buefy.dialog.confirm({
                      title: `Update threshold for sensor ${sensorsClone[index].serial}`,
                      message: `Change the threshold from ${sensor.settings.detect_threshold} to ${sensorsClone[index].settings.detect_threshold}?`,
                      onConfirm: () => {submit(index)}
                    })"
                  :disabled="sensorsClone[index].settings.detect_threshold === sensor.settings.detect_threshold || sensorsClone[index].settings.detect_threshold < minThreshold || sensorsClone[index].settings.detect_threshold > maxThreshold"
                  :loading="loadingSerials.includes(sensorsClone[index].serial)"
              >
                Update threshold to <b>{{ sensorsClone[index].settings.detect_threshold }}</b>?
              </b-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Area from "../../dataClasses/Area";
import {cloneDeep, round} from "lodash";


export default {
  name: "thresholdEdit",
  data() {
    return {
      sensorsClone: [],
      minThreshold: 0.01,
      maxThreshold: 200,
      showAsSlider: [],
      loadingSerials: [],
    }
  },
  props: {
    internalArea: {
      type: Area,
      required: true
    },
    positions: {
      type: Array,
      default: () => {
      }
    },
  },
  methods: {
    round,
    /**
     *
     * @param serial
     * @returns {Position|null}
     */
    positionFor(serial) {
      let matches = this.positions.filter(pos => pos.serial === serial);
      return matches.length ? matches[0] : null
    },
    isValidThresholdInput(val) {
      return val && val >= this.minThreshold && val <= this.maxThreshold;
    },
    submit(index) {
      let detect_threshold = this.sensorsClone[index].settings.detect_threshold,
          serial = this.sensorsClone[index].serial,
          updateDict = {};
      if (this.positions.length === 1) {
        this.$emit('close')
      }
      updateDict[`sensors.${index}.settings.detect_threshold`] = detect_threshold;
      this.loadingSerials.push(serial);
      this.internalArea
          .performAction('update_in_transaction', updateDict)
          .then(() => this.internalArea.refresh())
          .then(area => {
            this.$store.commit("add", {name: "areas", result: area});
            this.resync();
            return Promise.resolve();
          })
          .then(() => this.$handleSuccess(`Sensor ${serial} now has an updated threshold value of ${detect_threshold}`))
          .catch(e => this.$handleError(e))
          .finally(() => this.loadingSerials = this.loadingSerials.filter(ser => ser !== serial))
    },
    resync() {
      this.sensorsClone = cloneDeep(this.internalArea.sensors);
    }
  },
  watch: {
    internalArea: {
      immediate: true,
      handler() {
        this.resync();
      }
    }
  }
}
</script>

<style scoped>

</style>