export const FEATURE_FLAGS = [
  {
    key: "advanced_reporting",
    title: "Advanced reports",
    description: "Access advanced and experimental reports",
  },
  {
    key: "developer_mode",
    title: "Developer mode",
    description: "Show advanced developer features",
  },
];
export const VALID_FLAGS = FEATURE_FLAGS.map((f) => f.key);
export default FEATURE_FLAGS;
