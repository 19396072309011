<template>
  <div>
    <div class="mb-0 mt-3 has-text-weight-bold" v-if="title">
      {{ title }}
    </div>
    <b-table
        :checkable="checkable"
        :checked-rows.sync="checkedRows"
        :data="rows"
        default-sort="$displayString"
        :detailed="detailed"
        :detail-key="detailKey"
        :loading="loading"
        :opened-detailed="openedDetailed"
        @details-open="$emit('details-open', $event)"
        @details-close="$emit('details-close', $event)"
    >
      <template #empty v-if="!loading">
        <slot name="empty">
          <div class="has-text-centered m-3">
            <i>No areas to display</i>
          </div>
        </slot>
      </template>
      <slot name="first-column">
        <b-table-column
            field="$displayString"
            label="Name"
            :custom-sort="alphanumericSort"
            sortable
            v-slot="props"
        >{{ props.row.$displayString }}
          <in-training-mode
              :area="props.row.$area"
              v-if="
              showTrainingModeWarning &&
              props.row &&
              props.row.$area &&
              props.row.$area.$isInTrainingMode
            "
          />
        </b-table-column>
      </slot>
      <slot name="columns">
        <b-table-column v-if="showSite" label="Site" v-slot="props">
          <site :value="siteFor(props.row)" v-if="siteFor(props.row)"/>
        </b-table-column>
        <slot name="column-floors">
          <b-table-column
              numeric
              sortable
              field="floors"
              label="Floors"
              v-slot="props"
          >
            {{ props.row.floors }}
          </b-table-column>
          <b-table-column
              numeric
              sortable
              field="floors"
              label="Sub-floors"
              v-slot="props"
              v-if="rows.filter((r) => r.sub_floors && r.sub_floors > 0).length"
          >
            {{ props.row.sub_floors }}
          </b-table-column>
        </slot>
        <b-table-column v-slot="props" numeric label="Rooms">
          {{ roomsFor(props.row).length }}
        </b-table-column>
        <b-table-column v-slot="props" numeric label="POUs">
          {{ sourcesFor(props.row).length }}
        </b-table-column>
        <b-table-column v-slot="props" numeric label="PLs">
          {{ plsFor(props.row).length }}
        </b-table-column>
      </slot>
      <slot name="pre-select-column"/>
      <slot name="select-column" v-if="hasSelectColumn">
        <b-table-column v-slot="props">
          <b-button
              @click="onClick(props.row)"
              rounded
              type="is-primary is-outlined"
              size="is-small"
              class="is-pulled-right"
              icon-right="chevron-right"
          >Select
          </b-button>
        </b-table-column>
      </slot>
      <template #detail="props">
        <slot name="detail" v-bind:props="props"/>
      </template>
    </b-table>
  </div>
</template>

<script>
import {getChildrenOfIdByKey, getObjectInArrayById,} from "../scripts/filterHelpers";
import router from "../router";

export default {
  name: "BuildingTable",
  data() {
    return {
      checkedRows: [],
    };
  },
  props: {
    showTrainingModeWarning: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    detailKey: {
      type: String,
      default: "id",
    },
    openedDetailed: {
      type: Array,
      default: () => [],
    },
    hasSelectColumn: {
      type: Boolean,
      default: true,
    },
    showSite: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: (row) => {
        router.push({
          name: 'administration:view-area',
          params: {id: row.id},
        });
      },
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
  methods: {
    siteFor(row) {
      if (row.parent || row.site) {
        return getObjectInArrayById(
            this.$store.state.sites,
            (row.parent || row.site),
            null
        );
      }
      return null;
    },
    roomsFor(building) {
      return getChildrenOfIdByKey(
          this.$store.state.rooms,
          building.id,
          [],
          "building"
      );
    },
    sourcesFor(building) {
      return getChildrenOfIdByKey(
          this.$store.state.sources,
          building.id,
          [],
          "building"
      );
    },
    plsFor(building) {
      return getChildrenOfIdByKey(
          this.$store.state.pipe_locations,
          building.id,
          [],
          "building"
      );
    },
  },
}
</script>

<style scoped></style>
