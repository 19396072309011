import { mapGetters, mapState } from "vuex";
import { get } from "lodash";
import intervalFunctionMixin from "./intervalFunctionMixin";
import { Timestamp } from "../firebase";

import UserMessage from "../dataClasses/UserMessage";
import Alarm from "../dataClasses/Alarm";


/**
 * This mixin provides helper methods/attributes for UserMessage FS objects.
 * It expects the base object to have a `user_message` prop or an `alarm` prop
 */
export default {
  name: "IsUserMessageMixin",
  mixins: [intervalFunctionMixin],
  props: {
    alarmObject: {
      type: Object,
      required: false,
      validator: (val) => {
        return !val || val instanceof Alarm;
      },
    },
    message: {
      type: Object,
      required: false,
      validator: (val) => !val || val instanceof UserMessage,
    },
  },
  data() {
    return {
      // delta
      deltaMS: null,
      updateDeltaMS: null,
      startTimeDeltaMS: null,
      dismissClicked: false,
    };
  },
  computed: {
    ...mapGetters(["messages", "dismissedMessages"]),
    ...mapState(["sources", "user_roles"]),
    actions() {
      if (!this.message || !Object.keys(this.message).length) {
        return {};
      } else {
        let buttonArray = this.message.dynamic_button_array || [];
        return buttonArray.filter(
          (but) =>
            !["dismiss", "un-dismiss"].includes(but.button_title.toLowerCase())
        );
      }
    },
    alarm() {
      return this.message ? new Alarm(this.message.alarm) : this.alarmObject;
    },
    pipeLocation() {
      //alarm && alarm.flow_data && (alarm.flow_data.source||alarm.flow_data.pipe_location)
      return this.alarm.topic === "temperature"
        ? get(this.alarm, "temperature_data.pipe_location", null)
        : get(this.alarm, "flow_data.pipe_location");
    },
    alarmSite() {
      return this.alarm.$site;
    },
    area() {
      // In 'collection/id' format
      return this.alarm.$area;
    },
    classifiedAsSource() {
      return this.alarm.$classifiedAsSource || { name: "Not found" };
    },
    description() {
      return this.alarm.description;
    },
    disabled() {
      return this.loading;
    },
    startTime() {
      // return it
      return this.toDate(this.alarm.$startTime);
    },
    selected() {
      return this.$route.params.id && this.message.id === this.$route.params.id;
    },
    site() {
      return this.alarm.$site;
    },
    sourcesForSelectedMessage() {
      if (this.alarm.area) {
        let areaId = this.toId(this.alarm.area);
        return this.sources.filter((src) => src.area && src.area.id === areaId);
      } else if (this.alarm.site) {
        let siteId = this.toId(this.alarm.site);
        return this.sources.filter((src) => src.site && src.site.id === siteId);
      }
      return [];
    },
    title() {
      return this.alarm.$title;
    },
    type() {
      return this.alarm.$BClass;
    },
    predictedSource() {
      return this.alarm.$predictedSource;
    },
    flowLength() {
      return this.alarm.$flowLengthReadable;
    },
    readableDelta() {
      return this.deltaMS ? this.timeConversion(this.deltaMS) : "";
    },
    readableUpdateDelta() {
      return this.updateDeltaMS ? this.timeConversion(this.updateDeltaMS) : "";
    },
    readableStartTimeDelta() {
      return this.startTimeDeltaMS
        ? this.timeConversion(this.startTimeDeltaMS)
        : "";
    },
    readableStartTime() {
      let startTime = this.startTime;
    },
  },
  methods: {
    onPoll() {
      let now = new Date();
      let lastUpdateDt = this.toDate(this.alarm.last_update_dt),
        issueDt = this.toDate(this.alarm.issue_dt);
      if (!this.alarm) {
        return;
      }
      this.deltaMS = issueDt ? now - issueDt : null;
      this.updateDeltaMS = lastUpdateDt ? now - lastUpdateDt : null;
      this.startTimeDeltaMS = this.startTime ? now - this.startTime : null;
    },
    asReadableDate(fsTimestamp) {
      if (!fsTimestamp) {
        return;
      }
      let dateObj = this.toDate(fsTimestamp);
      if (!dateObj) {
        return "";
      }
      let time = dateObj.toLocaleTimeString(),
        date = this.isToday(dateObj)
          ? ""
          : " (" + dateObj.toLocaleDateString() + ")";
      return time + date;
    },
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    toggleDismissed(stateVar) {
      let self = this;
      this.$emit("update:dismissed", !self.message.dismissed);
      self.dismissClicked = true;
      self.$store.dispatch("partial_update", {
        name: stateVar,
        data: {
          id: self.message.id,
          dismissed: !self.message.dismissed,
        },
        onFinally: () => {
          self.dismissing = false;
          self.dismissClicked = false;
          if (self.$route.params.id === self.message.id) {
            self.$router.push({ name: "notifications" });
          }
        },
      });
    },
  },
  watch: {
    "message.dismissed": {
      handler: function (isDismissed, wasDismissed) {
        if (isDismissed !== wasDismissed) {
          this.$emit("update:dismissed", isDismissed);
        }
      },
    },
  }
};
