<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Report Email Subscriptions</div>
    </div>
    <div class="card-content">
      <b-field label="Show report subscriptions" v-show="!id">
        <b-radio
          v-model="showBy"
          native-value="site"
          >
          within the current scope
        </b-radio>
        <b-radio
          v-model="showBy"
          native-value="membership"
          >
          that I am subscribed to or created
        </b-radio>
      </b-field>
      <loading-chart-card v-if="loading"/>
      <report-subscription-form v-else-if="add" @submit="returnToList()"/>
      <report-subscription-form
          :id="id"
          v-else-if="id"
          @submit="returnToList()"
      />
      <report-subscription-table
          v-else
          :data="rows"
          :loading="loading"
          :object-cache="cachedObjects"
          @input="handleSelect($event)"
      />
    </div>
    <div class="card-footer">
      <div class="card-footer-item">
        <transition name="fade">
          <b-button
              v-if="add || id"
              expanded
              icon-left="chevron-left"
              @click="returnToList()"
          >
            Return to list
          </b-button>
        </transition>
      </div>
      <div class="card-footer-item" v-if="!id">
        <b-button
            type="is-primary"
            expanded
            :disabled="add || id"
            :icon-right="iconFor('add')"
            @click="add = true"
        >
          {{ id ? "Update" : "Add a new" }} subscription
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {chunk, get} from "lodash";
import ReportSubscriptionForm from "./ReportSubscriptionForm";
import ReportSubscriptionTable from "./ReportSubscriptionTable";

import iconFor from "../../icons";
import LoadingChartCard from "../../components/Skeletons/LoadingChartCard";
import {mergeById} from "../../store/shared"

export default {
  name: "ReportSubscriptionList",
  components: {
    LoadingChartCard,
    ReportSubscriptionForm,
    ReportSubscriptionTable,
  },
  data() {
    return {
      rows: [],
      loading: false,
      cachedObjects: []
    };
  },
  computed: {
    showBy: {
      get() {return this.$route.query.showBy || 'site'},
      set(showBy) {showBy ? this.queryPush({showBy}) : this.queryRemoveKeys(['showBy'])},
    },
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        this.queryPush({id});
      },
    },
    add: {
      get() {
        return Object.keys(this.$route.query).includes("add");
      },
      set(val) {
        if (val) {
          this.queryPush({add: 1});
        } else {
          this.queryRemoveKeys(["add"]);
        }
      },
    },
    forObjectPaths() {
      let objects = this.$store.state.sites.concat(this.$store.state.buildings);
      return objects.map((o) => o.$FSRef.path);
    },
    queries() {
      if (this.showBy === "site") {
        let forObjectPathChunks = chunk(this.forObjectPaths, 10);
        return forObjectPathChunks.map((pathChunk) =>
          this.$dataClasses.ReportEmailSubscription.query(
            [["parameters.for_object_path", "in", pathChunk]],
            { order_field: "parameters.for_object_path" }
          )
        );
      } else {
        return [
          this.$dataClasses.ReportEmailSubscription.query(
              [
                  ["email_addresses", "array_contains", this.$store.state.user.email]
              ],
              {order_field: "email_addresses"}
          ),
          this.$dataClasses.ReportEmailSubscription.query(
            [["created_by", "==", this.$store.state.user_account.$FSRef]],
            { order_field: "created_by" }
          ),
        ];
      }
    },
  },
  methods: {
    iconFor,
    /**
     *
     * @param rem {ReportEmailSubscription}
     */
    handleSelect(rem) {
      let forObjectPath = get(rem, 'parameters.for_object_path'),
          forObjectId = forObjectPath.split('/')[1],
          forObject;
      if (this.$store.getters.objectStack.filter(obj => obj.id === forObjectId).length === 0) {
        this.loading = true;
        this.$client.post(
            '/by-paths/', {paths: [forObjectPath]}
        )
        .then(res => {
          forObject = res.results[0];
          this.queryReplace({
            id: rem.id,
            loadScope: (forObject.id_str || forObject.site_id_str).split('|').slice(0, 5).join('|')
          });
        })
        .catch(
            e => this.$handleError(e)
        )
        .finally(
            () => this.loading = false
        )
      } else {
        this.id = rem.id;
      }
    },
    get() {
      let showBy = this.showBy;
      this.loading = true;
      Promise
          .all(
            this.queries
          )
          .then((responses) => {
            this.rows = [];
            responses.forEach(res => this.rows = mergeById(this.rows).with(res));
            return Promise.resolve();
          })
          .then(() => {
            if (showBy === 'site') {
              return Promise.resolve({results: []})
            } else {
              let objStackPaths = this.$store.getters.objectStack.concat(this.cachedObjects).map(obj => this.toFSRef(obj).path);
              let paths = this.rows.map(
                  res => get(res, 'parameters.for_object_path')
              ).filter(
                  path => !objStackPaths.includes(path)
              );
              return (paths && paths.length) ? this.$client.post('/by-paths/', {paths}) : Promise.resolve({results: []})
            }
          })
          .then(res => {
            let results = res.results.map(result => {
              return this.$toDataClass(result._reference.path.split('/')[0], result);
            });
            this.cachedObjects = mergeById(this.cachedObjects).with(results);
          })
          .catch((e) => this.$handleError("Could not load report subscriptions", e))
          .finally(() => this.loading = false);
    },
    returnToList() {
      this.add = false;
      this.$nextTick(() => {
        this.id = null;
        this.get();
        this.$router.push({
          name: "report-subscriptions",
        });
      });
    },
  },
  watch: {
    showBy: {
      immediate: true,
      handler() {
        this.get();
      },
    },
    forObjectPaths: {
      immediate: true,
      handler() {
        this.get();
      },
    },
  },
};
</script>

<style scoped></style>
