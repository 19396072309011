import FirestoreDataClass from "./_baseClasses";
import {getObjectInArrayById} from "../scripts/filterHelpers";
import store from "../store";

export default class AlarmSchedule extends FirestoreDataClass {
  static collectionName = "alarm_schedules";

  static alarmTypeToReadable = {
    agg_filter_30min: "Aggregated flow",
    user_filter: "General flow",
    // health_alarm: "System status",
    noflow: "Low-flow / stagnation",
    temperature: "Temperature",
    unrecognized: "Unrecognized flow",
  };

  static aggregationMethodsToReadable = {
    max: "Maximum",
    mean: "Mean",
    median: "Median",
    min: "Minimum",
  };

  static defaultDisplayString = "Alarm schedule";

  get $internalArea() {
    const refPath = this.get('reference_path');
    return refPath ? getObjectInArrayById(
        store.state.areas,
        refPath,
        null
      ) : null;
  }

  get $area() {
    return this.$internalArea ? this.$internalArea.$building : null
  }

  /**
   *
   * @returns {'Pipe locations'|'Points-of-use'|'Area'|'Not set'}
   */
  get $scopeType() {
    const PLs = this.get('pipe_locations'),
        sources = this.get('sources'),
        area = this.$area;
    if (PLs && PLs.length) {
      return "Pipe locations"
    } else if (sources && sources.length) {
      return "Points-of-use"
    } else if (area) {
      return 'Area'
    } else {
      return "Not set"
    }
  }

  /**
   * For display
   * @returns {string}
   */
  get $scopeString() {
    const scopeType = this.$scopeType,
      plArray = this.get('pipe_locations', []) || [],
        sourceArray = this.get('sources', []) || [];
    if (scopeType === 'Pipe locations') {
      return `${scopeType}: ${plArray.length}`
    } else if (scopeType === 'Points-of-use') {
      return `${scopeType}: ${sourceArray.length}`
    } else if (scopeType === 'Area') {
      return `${scopeType}: ${this.$area.$displayString}`
    } else {
      return scopeType
    }
  }

  get $displayString() {
    const name = this.get("name", this.constructor.defaultDisplayString),
        scopeString = this.$scopeString;
    return `${name} for ${scopeString}`
  }

  get $readableAlarmType() {
    return (
      this.constructor.alarmTypeToReadable[this.alarm_type] ||
      "Unknown type of alarm"
    );
  }
}
