<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Probe detail for {{ serial }}</div>
      <div class="card-header-icon">
        <b-icon :icon="iconFor('probe')"/>
      </div>
    </div>
    <div class="card-content">
      <transition name="slide">
        <div class="columns" v-if="loading">
          <div class="column">
            <loading-card/>
          </div>
        </div>
      </transition>
      <transition name="slide">
        <div class="columns" v-if="!loading && !device">
          <div class="column has-text-centered">
            <p class="subtitle mt-6 mb-6">
              Error: No probe found with serial <b>{{ serial }}</b>
            </p>
          </div>
        </div>
      </transition>
      <transition name="slide">
        <div class="columns" v-if="!loading && device">
          <div class="column is-2-desktop has-text-centered">
            <div class="block">
              <p>Probe</p>
              <p class="has-text-weight-bold">{{ serial }}</p>
            </div>
            <transition name="slide">
              <b-dropdown
                  :triggers="isMobile ? ['click'] : ['hover', 'click']"
                  aria-role="list"
                  class="has-text-left"
                  v-if="serial && probe"
                  :expanded="isMobile"
              >
                <template #trigger>
                  <b-button
                      label="I'd like to:"
                      type="is-primary"
                      :expanded="isMobile"
                      icon-right="chevron-down"
                  />
                </template>
                <b-dropdown-item
                    :disabled="!canAttachProbe"
                    aria-role="listitem"
                    @click="goToAttachProbe()"
                >
                  Attach this probe to a sensor
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!canRemoveProbe"
                    aria-role="listitem"
                    @click="goToRemoveProbe()"
                >
                  Un-attach this probe
                  <span v-if="position">from sensor {{ position.serial }}</span>
                </b-dropdown-item>
                <hr/>
                <b-dropdown-item
                    aria-role="listitem"
                    @click="sendSupportEmailClicked('probe')"
                >
                  Request technical support
                </b-dropdown-item>
              </b-dropdown>
            </transition>
          </div>
          <div class="column is-5-desktop">
            <div class="panel is-info">
              <div class="panel-heading">Probe information</div>
              <div class="panel-block">
                <transition name="slide">
                  <probe-detail-table
                      v-if="probe"
                      :sensor-position="position"
                      :bundle="bundle"
                      :probe="probe"
                  >
                    <div class="buttons">
                      <b-button
                          v-if="$store.state.user_roles.is_root"
                          expanded
                          type="is-link"
                          @click="window.open(probe.$qrCodeUrl, '_blank')"
                      >
                        QR Code
                      </b-button>
                    </div>
                  </probe-detail-table>
                </transition>
              </div>
            </div>
          </div>
          <div class="column is-5-desktop">
            <div class="panel is-primary">
              <div class="panel-heading">Installation location</div>
              <div class="panel-block is-fullwidth">
                <div v-if="position">
                  <install-location
                      v-if="probe"
                      :position="positionProbeEntry"
                      show-pipe-location-only
                      :probe-serial="probe.serial"
                      :table-classes="[
                        'is-bordered',
                        'is-striped',
                        'is-fullwidth',
                        'is-fullhd',
                      ]"
                  />
                </div>
                <b-button
                    class="mt-3 is-fullwidth"
                    icon-left="times"
                    type="is-danger"
                    :loading="loadingPosition"
                    v-if="installed"
                    @click="goToRemoveProbe"
                >
                  Remove probe from sensor
                </b-button>
                <b-loading :is-full-page="false" :active="loadingPosition"/>
                <transition name="slide">
                  <div class="has-text-centered mt-3" v-if="!loadingPosition && !position">
                    <i>This probe is not currently installed</i>
                    <p>&nbsp;</p>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="columns">
        <div class="column is-offset-2-desktop">
          <transition name="slide">
            <div v-if="!loading && device">
              <b-button
                  expanded
                  size="is-small"
                  v-if="!showEventHistory"
                  @click="showEventHistory = true"
              >
                View probe event history
              </b-button>
            </div>
          </transition>
          <transition name="slide">
            <event-history
                v-if="showEventHistory"
                :tabs="{ Probe: toFSRef(probe) }"
            />
          </transition>
        </div>
      </div>


    </div>
    <div class="card-footer" v-if="position">
      <div class="card-footer-item">
        <b-button
            :loading="!(position && position.$area)"
            expanded
            outlined
            icon-left="chevron-left"
            @click="$router.push({name:'devices:system-detail', params: {areaId: position.$area.id}})"
            type="is-link"
        >
          Return to area
        </b-button>
      </div>
      <div class="card-footer-item">
        <b-button
            :loading="!singleGateway"
            expanded
            outlined
            icon-left="chevron-left"
            @click="
            $router.push({
              name: 'devices:gateway-detail',
              params: { serial: singleGateway.serial },
            })
          "
            type="is-link"
        >
          <b-icon v-if="isDesktop" :icon="iconFor('gateway')" class="ml-3 mr-3"/>
          Gateway {{ singleGateway ? singleGateway.serial : '' }}
        </b-button>
      </div>
      <div class="card-footer-item">
        <b-button
            :loading="!position"
            expanded
            icon-left="chevron-left"
            type="is-link"
            outlined
            :disabled="loadingPosition"
            @click="
            position
              ? $router.push({
                  name: 'devices:sensor-detail',
                  params: { serial: position.serial },
                })
              : null
          "
        >
          <b-icon v-if="isDesktop" :icon="iconFor('sensor')" class="ml-3 mr-3"/>
          Sensor {{ position ? position.serial : '' }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import trackWindowSize from '../../../mixins/trackWindowSize';
import iconFor from '../../../icons';

import DeviceSupportEmailMixin from './DeviceSupportEmailMixin';
import LoadingCard from '../../../components/Skeletons/LoadingCard';
import ProbeDetailTable from '../../../tables/ProbeDetailTable';
import {get} from 'lodash';
import InstallLocation from "../../../components/Display/InstallLocation";
import AqImageUpload from "../../../components/Files/Editors/AqImageUpload";
import {getObjectInArrayById} from "../../../scripts/filterHelpers";

export default {
  name: 'ProbeDetail',
  components: {
    AqImageUpload,
    InstallLocation,
    LoadingCard,
    ProbeDetailTable,
  },
  mixins: [DeviceSupportEmailMixin, trackWindowSize],
  props: {
    serial: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bundle: null,
      loading: false,
      loadingPipeLocation: false,
      loadingPosition: null,
      position: null,
      probe: null,
      showEventHistory: false,
      window,
    };
  },
  computed: {
    device() {
      return this.probe;
    },
    canAttachProbe() {
      return this.probe && !this.installed;
    },
    canRemoveProbe() {
      return !!this.probe && this.installed;
    },
    gateways() {
      return this.serial
          ? this.$store.getters.gatewaysForSensorSerial(this.position.serial)
          : [];
    },
    installed() {
      return this.probe && this.position && this.position.serial;
    },
    pipe_location() {
      let plId = this.positionProbeEntry ? this.toId(this.positionProbeEntry.pipe_location) : null;
      return plId ? getObjectInArrayById(this.$store.state.pipe_locations, plId) : null;
    },
    positionProbeEntry() {
      if (!this.position) {
        return null
      }
      let matches = [
        this.position.port_1,
        this.position.port_2
      ].filter(
          port => this.toId(port.probe) === this.toId(this.device)
      );
      return matches.length ? matches[0] : null;
    },
    singleGateway() {
      if (!this.position) {
        return null;
      }
      let gws = this.gateways.filter((gw) =>
          gw.connect_weights.filter(
              (cw) => cw.sensor.id === this.positionId && !!cw.weight,
          ),
      );
      return gws.length === 1 ? gws[0] : null;
    },
  },
  methods: {
    iconFor,
    goToAttachProbe() {
      if (!this.canAttachProbe) {
        this.$handleError('This action is not available');
        return;
      }
      this.$router.push({
        name: 'attach-probe',
        query: {
          serial: this.probe.serial,
        },
      });
    },
    goToRemoveProbe() {
      if (!this.canRemoveProbe) {
        this.$handleError('This action is not available');
        return;
      }
      let areaRef = this.position ? this.position.area : null,
          areaId = areaRef ? areaRef.id : null,
          buildingMatches = areaId
              ? this.$store.state.buildings.filter(
                  (b) => b.area && b.area.id === areaId,
              )
              : [],
          building = buildingMatches.length ? buildingMatches[0] : null;
      this.$router.push({
        name: 'remove-probe',
        query: {
          serial: this.probe.serial,
          sensorSerial: this.position ? this.position.serial : null,
          building: building ? building.id : null,
        },
      });
    },
  },
  watch: {
    serial: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.probe = null;
          this.bundle = null;
          this.loading = false;
        }
        this.loading = true;
        this.bundle = null;
        this.$dataClasses.Probe.queryForSingle([['serial', '==', this.serial]]).then((obj) => {
          this.probe = obj;
          this.loadingPosition = true;
          if (this.probe.get('device_state.position.id', null)) {
            this.$dataClasses.Position.fetchById(this.probe.device_state.position.id).then((obj) => (this.position = obj)).catch((e) =>
                this.$handleError('Could not fetch associated sensor data', e),
            ).finally(() => (this.loadingPosition = false));
          } else {
            this.position = null;
            this.loadingPosition = null;
          }
          let bundleRef = get(obj, 'device_state.bundle');
          return bundleRef ? this.$dataClasses.Bundle.fetchById(this.toId(bundleRef)) : Promise.resolve(null);
        }).then(
            bundle => this.bundle = bundle
        ).catch((e) =>
            this.$handleError(
                `Could not retrieve probe with serial ${this.serial}`,
                e,
            ),
        ).finally(() => (this.loading = false));
      },
    },
  },
};
</script>

<style scoped>
.location-wrapper {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
}

@media (max-width: 960px) {
  .card-footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .card-footer-item {
    flex: 1 0 21%; /* explanation below */

  }
}

</style>
