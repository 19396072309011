export const allCategories = ["document", "floor_plan", "install_picture"];

/**
 *
 * @param $client {Object} API Client instance
 * @param objectPath {String} FS Path for object
 * @param callback {Function} Callback on success; takes one arg (response obj)
 * @param onFinally {Function,null} Callback on finally
 *
 * @return {undefined}
 */
export function getFilesForObjectPath(
  $client,
  objectPath,
  callback,
  onFinally
) {
  $client
    .post("/files/list/", {
      object: objectPath,
    })
    .then((response) => {
      callback(response);
    })
    .catch((e) => this.$handleError("Could not load files", e))
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
}

export default {
  name: "hasObjectWithFilesMixin",
  props: {
    canUpload: {
      type: Boolean,
      default: true,
    },
    categories: {
      type: Array,
      default: () => allCategories,
    },
    category: {
      type: String,
      required: false,
      validator(value) {
        return !value || allCategories.includes(value);
      },
    },
    object: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filesForObject: [],
      loadingFiles: false,
    };
  },
  computed: {
    collection() {
      return this.objectPath.split("/")[0];
    },
    dropzoneOptions() {
      let headers = {
        "Cache-Control": "",
        "x-requested-with": "",
      };
      headers[this.$client.authHeaderKey] = this.$client.authHeaderValue;
      return {
        addRemoveLinks: false,
        duplicateCheck: true,
        headers: headers,
        maxFilesize: 25,
        method: "post",
        thumbnailWidth: 150,
        timeout: 10 * 1000,
        uploadMultiple: false,
        url: process.env.VUE_APP_API_URL + "/files/" + this.objectPath + "/",
        withCredentials: true,
      };
    },
    endpointSegment() {
      return this.objectPath.split("/")[0];
    },
    filesToShow() {
      return this.category
        ? this.filesForObject.filter(
            (f) => f.metadata.category === this.category
          )
        : this.filesForObject;
    },
    id() {
      return this.objectPath.split("/")[1];
    },
    objectPath() {
      if (!this.object) {
        return;
      }
      return this.object._reference.path;
    },
  },
  methods: {
    fileRemoved(file) {
      this.filesForObject = this.filesForObject.filter(
        (f) => f.path !== file.path
      );
    },
    fileUpdated(file) {
      this.filesForObject = this.filesForObject.filter(
        (f) => f.path !== file.path
      );
      this.filesForObject.push(file);
    },
    getImagePath(imageObject) {
      return process.env.VUE_APP_API_URL + "/files/" + imageObject.path;
    },
    sendingEvent(file, xhr, formData) {
      formData.append("category", this.category);
    },
  },
  watch: {
    objectPath: {
      immediate: true,
      handler(val) {
        this.filesForObject = [];
        if (!val) {
          return;
        }
        this.loadingFiles = true;
        getFilesForObjectPath(
          this.$client,
          val,
          (response) => {
            response.result.files.forEach((result) => {
              this.filesForObject.push(result);
            });
          },
          () => (this.loadingFiles = false)
        );
      },
    },
  },
};
