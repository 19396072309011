import { Timestamp } from "../firebase";

export function toDate(timestamp) {
  if (!timestamp) {
    return;
  }
  if (typeof timestamp.toDate === "function") {
    return timestamp.toDate();
  }
  if (timestamp instanceof Timestamp) {
    return new Date(timestamp.seconds * 1000);
  }
  return timestamp;
}