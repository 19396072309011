import { getDeviceTypeFromSerial } from "../../../scripts/deviceQrCodes";

/**
 * A mixin for
 */

export default {
  name: "DeviceSupportEmailMixin",
  data() {
    return {
      deviceType: "device",
    };
  },
  computed: {
    emailParams() {
      let deviceType = getDeviceTypeFromSerial(this.serial),
        body = `Hello,%0D%0A
%0D%0A
I am seeking support for my ${deviceType} with serial #${this.serial}.%0D%0A
%0D%0A
The issue I am experiencing is: <please-fill-out>%0D%0A
%0D%0A
The device can be viewed at: ${this.viewUrl}%0D%0A
%0D%0A%0D%0A
Thank you,%0D%0A %0D%0A
${this.$store.state.user.displayName}%0D%0A
${this.$store.state.user.email}%0D%0A
${this.$store.state.user_account.phone || ""}`;
      return {
        body: body,
        subject: `Support request for ${deviceType}`,
      };
    },
    viewUrl() {
      return `${window.location.origin}/device/${this.serial}/`;
    },
  },
  methods: {
    objectToUrlParams(obj) {
      return Object.keys(obj)
        .map(function (key) {
          return key + "=" + obj[key];
        })
        .join("&");
    },
    sendSupportEmailClicked() {
      let deviceType = getDeviceTypeFromSerial(this.serial);
      window.open(
        `mailto:info@aquaseca.com?${this.objectToUrlParams(this.emailParams)}`
      );
    },
  },
};
