<template>
  <div class="pipe-location-form">
    <b-field
      horizontal
      label="Name"
      :message="fieldErrors.name"
      :type="fieldErrors.name ? 'is-danger' : null"
    >
      <b-input autofocus v-model="inputs.name" ref="nameInput" />
    </b-field>
    <transition name="slide">
      <select-building-and-room
        horizontal
        :room-error-message="fieldErrors.room"
        :site="$store.getters.site"
        :building-editable="false"
        :disabled="!!room"
        :selected-building-id="building.id"
        :selected-room-id="inputs.room ? inputs.room.id : null"
        @update:room="inputs.room = $event ? toFSRef($event, 'rooms') : null"
      />
    </transition>
    <b-field
      horizontal
      label="Pipe temperature"
      :message="fieldErrors.temperature"
      :type="fieldErrors.temperature ? null : 'is-danger'"
    >
      <pipe-temperature-radio v-model="inputs.temperature" />
    </b-field>
    <b-field label="Style of plumbing" horizontal>
      <plumbing-style-radio v-model="inputs.characteristics.type" />
    </b-field>
    <transition name="slide">
      <b-field
        horizontal
        label="Downstream POUs"
        v-if="building"
      >
        <div style="max-width: 800px">
          <select-hierarchy
            array-name="sources"
            :disabled="loading"
            :options="hierarchyToTree({ _buildings: [building] })"
            :return-transform-function="normalizer"
            :value="$store.state.sources.filter(src => inputs.downstream_sources.map(_src => _src.id).includes(src.id))"
            @change="handleDownstreamSourcesChange($event)"
          />
        </div>
      </b-field>
    </transition>
    <b-message type="is-default" :closable="false" title="Pipe characteristics">
      <b-field
        grouped
        group-multiline
        :type="!diamsValidate ? 'is-danger' : null"
        :message="
          diamsValidate
            ? null
            : 'Inner diameter must be less than outer diameter'
        "
      >
        <b-field label="Inner Diam.">
          <inch-or-mm-input
            :value="inputs.characteristics.inner_diameter"
            @input:mm="
              inputs.characteristics.inner_diameter = $event
                ? parseFloat($event)
                : 0
            "
          />
        </b-field>
        <b-field label="Outer Diam.">
          <inch-or-mm-input
            :value="inputs.characteristics.outer_diameter"
            @input:mm="
              inputs.characteristics.outer_diameter = $event
                ? parseFloat($event)
                : 0
            "
          />
        </b-field>
      </b-field>
      <b-field label="Pipe material">
        <pipe-material-radio v-model="inputs.characteristics.material" />
      </b-field>
    </b-message>
  </div>
</template>

<script>
import InchOrMmInput from "../../Inputs/InchOrMmInput";

import { cloneDeep, flatMap, uniqBy } from "lodash";
import { getDefaultData } from "./AddPipeLocationModal";
import hierarchyToTree from "../../Selects/functions/hierarchyToTree";
import SelectHierarchy from "../../Selects/SelectHierarchy";
import SelectBuildingAndRoom from "../../Selects/BuildingAndRoom";
import PipeTemperatureRadio from "../../Selects/Radios/PipeTemperatureRadio";
import PlumbingStyleRadio from "../../Selects/Radios/PlumbingStyleRadio";
import PipeMaterialRadio from "../../Selects/Radios/PipeMaterialRadio";

function hasAnyTruthyValues(object) {
  return (
    object &&
    Object.values(object).some((v) =>
      v && typeof v === "object" ? hasAnyTruthyValues(v) : !!v
    )
  );
}

export default {
  name: "PipeLocationForm",
  components: {
    InchOrMmInput,
    PipeMaterialRadio,
    PipeTemperatureRadio,
    PlumbingStyleRadio,
    SelectBuildingAndRoom,
    SelectHierarchy,
  },
  mounted() {
    if (this.room) {
      this.inputs.room = this.toFSRef(this.room);
    }
    this.validate();
  },
  props: {
    canInherit: {
      type: Boolean,
      default: false,
    },
    /**
     * parent === area
     */
    parent: {
      type: Object,
      required: true,
      validator(val) {
        return (
          !val ||
          (val && val._reference && val._reference.path.startsWith("areas/"))
        );
      },
    },
    room: {
      type: Object,
      required: false,
      validator(val) {
        return (
          !val ||
          (val && val._reference && val._reference.path.startsWith("rooms/"))
        );
      },
    },
    building: {
      type: Object,
      required: true,
      validator(val) {
        return (
          !val ||
          (val &&
            val._reference &&
            val._reference.path.startsWith("buildings/"))
        );
      },
    },
    value: {
      required: false,
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      shouldInherit: false,
      inputs: this.value ? cloneDeep(this.value) : getDefaultData(),
    };
  },
  computed: {
    diamsValidate() {
      let id = this.inputs.characteristics.inner_diameter,
        od = this.inputs.characteristics.outer_diameter;
      return !id || !od || id < od;
    },
    fieldErrors() {
      return {
        characteristics: {
          inner_diameter: !this.diamsValidate
            ? "Inner diameter must be less than outer diameter"
            : null,
          outer_diameter: null,
          material: null,
          type: !this.inputs.characteristics.type
            ? "This field is required"
            : null,
        },
        name: !this.inputs.name ? "This field is required" : null,
        room: !this.inputs.room ? "This field is required" : null,
        downstream_sources: null,
        temperature: !this.inputs.temperature ? "This field is required" : null,
      };
    },
    validates() {
      return !hasAnyTruthyValues(this.fieldErrors);
    },
  },
  methods: {
    clear() {
      for (const [key, value] of Object.entries(getDefaultData())) {
        this.$set(this.inputs, key, value);
      }
    },
    hierarchyToTree,
    handleDownstreamSourcesChange(val) {
      this.inputs.downstream_sources = val;
    },
    input() {
      let data = { ...this.inputs };
      data.parent = this.area ? this.toFSRef(this.area, "areas") : null;
      data.building = this.building
        ? this.toFSRef(this.building, "buildings")
        : null;
      if (this.room) {
        this.inputs.room = this.toFSRef(this.room, "rooms");
      }
      this.$emit("input", data);
      this.validate();
    },
    validate() {
      this.$emit("validate", this.validates);
    },
    normalizer(valArray) {
      let results = flatMap(valArray, (val) => {
        if (val.isA === "source") {
          return this.toFSRef(val.id, "sources");
        } else {
          return this.normalizer(val.children);
        }
      }).filter((e) => !!e);
      return uniqBy(results, "id");
    },
  },
  watch: {
    inputs: {
      // CANNOT DO DEEP WATCHING WITHOUT RECURSION
      handler() {
        this.input();
      },
    },
    "inputs.characteristics": {
      deep: true,
      handler() {
        this.input();
      },
    },
    "inputs.temperature": {
      handler() {
        this.input();
      },
    },
    "inputs.downstream_sources": {
      handler() {
        this.input();
      },
    },
    "inputs.name": {
      handler() {
        this.input();
      },
    },
    "inputs.room": {
      handler() {
        this.input();
      },
    },
    "inputs.characteristics.type": {
      handler(val) {
        if (val !== "branch") {
          this.inputs.downstream_sources = [];
        }
      },
    },
    validates: {
      handler() {
        this.validate();
      },
    },
  },
};
</script>

<style scoped></style>
