<template>
  <div class="standard-chart-controls-wrapper mt-2">
    <b-field
      class="mt-1"
      horizontal
      label="Area"
      size="is-small"
      style="zoom: 75%"
      v-if="buildings && buildings.length > 1"
    >
      <b-select :value="buildingId" @input="emitUpdate('building-id', $event)">
        <option value="all" class="has-background-grey-light">Show all</option>
        <option
          v-for="bdg in buildings"
          :value="bdg.id"
          :key="bdg.id"
          class="has-background-grey-light"
        >
          {{ bdg.name }}
        </option>
      </b-select>
    </b-field>
    <b-field label="Group by" horizontal size="is-small" style="zoom: 75%">
      <b-radio
        v-for="{ label, fieldPath } in groupByOptions"
        :key="fieldPath"
        :native-value="fieldPath"
        :value="groupBy"
        @input="emitUpdate('group-by', $event)"
      >
        {{ label }}
      </b-radio>
    </b-field>
    <b-field label="Period" horizontal size="is-small" style="zoom: 75%">
      <b-radio
        v-for="_period in periods"
        :key="_period"
        :native-value="_period"
        :value="period"
        @input="emitUpdate('period', $event)"
      >
        {{ capitalizeFirstLetter(_period) }}
      </b-radio>
    </b-field>
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: "StandardChartControls",
  props: {
    buildingId: {
      type: String,
      default: null,
    },
    buildings: {
      type: Array,
      default: () => [],
    },
    groupBy: {
      type: String,
      default: "_",
    },
    groupByOptions: {
      type: Array,
      default: () => [
        { label: "Point-of-use", fieldPath: "_" },
        { label: "Fixture Type", fieldPath: "fixture" },
        { label: "Room Type", fieldPath: "$room.type" },
      ],
    },
    period: {
      type: String,
      default: "day",
    },
    periods: {
      type: Array,
      required: true,
    },
  },
  methods: {
    emitUpdate(fieldName, value) {
      this.$emit(`update:${fieldName}`, value);
    },
  },
};
</script>

<style scoped></style>
