<template>
  <div>
    <transition name="slide">
      <qrcode-drop-zone
        @decode="onDecode"
        @init="logErrors"
        v-if="isOpen && !noStreamApiSupport"
      >
        <qrcode-stream @decode="onDecode" @init="onInit" />
      </qrcode-drop-zone>
      <qrcode-capture v-if="isOpen && noStreamApiSupport" @decode="onDecode" />
    </transition>
    <b-button
      :class="{ 'mt-3': isOpen, 'mb-3': true }"
      :icon-left="isOpen ? 'times' : 'qrcode'"
      :label="isOpen ? 'Close' : label"
      :size="size"
      @click="isOpen = !isOpen"
    />
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  name: "QrCodeReaderButton",
  components: { QrcodeStream, QrcodeDropZone, QrcodeCapture },
  data() {
    return {
      isOpen: false,
      result: "",
      noStreamApiSupport: false,
    };
  },
  props: {
    closeOnDecode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Scan QR Code",
    },
    showFeedbackMessages: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    onDecode(decodedString) {
      this.result = decodedString;
      this.$emit("input", decodedString);
      if (decodedString && this.showFeedbackMessages) {
        this.$handleSuccess("Your QR code has been read", {});
      }
      this.isOpen = false;
    },
    logErrors(promise) {
      promise.catch(console.error);
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
          this.noStreamApiSupport = true;
        }
      }
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(val) {
        this.$emit(val ? "open" : "close");
        this.$emit("open-changed", val);
      },
    },
  },
};
</script>

<style scoped></style>
