<template>
  <b-modal :active="active" @close="emitClose()" has-modal-card >
    <div class="modal-card" style="min-height: 75vh;">
      <div class="modal-card-head">
        <div class="modal-card-title">Create a new API Token</div>
      </div>
      <div class="modal-card-body">
        <b-loading :active="loading" :is-full-page="false" />
        <b-field
          label="API token expiry date"
          :message="
            !expires ? 'You must set an expiry date for this token' : ''
          "
          :type="!expires ? 'is-danger' : ''"
        >
          <b-datepicker
            placeholder="Type or select a date..."
            v-model="expires"
            :unselectable-dates="(date) => date < new Date()"
            icon="calendar"
          />
        </b-field>
        <b-field label="Membership">
          <b-message>
            <p>
              Please select the entity this API token should belong to. This
              specifies who can see the token, not the permissions granted given
              the token.
            </p>
            <customer-hierarchy-selection
              :cascade-select="false"
              @lowest-selected="
                accessPath = $event && $event.id_str ? $event.id_str : null
              "
              :down-to-scope="downTo"
              :allow-at-root="$store.state.user_roles.is_root"
            />
          </b-message>
        </b-field>
        <b-field
          v-if="accessPath"
          label="Roles/Permissions"
          :type="!roles || !roles.length || !rolesValid ? 'is-danger' : ''"
          :message="
            !roles || !roles.length
              ? 'Please set at least 1 role for this API token'
              : rolesValid
              ? ''
              : 'Invalid roles'
          "
          class="mb-6"
        >
          <user-roles
            :value="roles"
            editable
            :parent-path="accessPath"
            @input="roles = $event"
            @validates="rolesValid = $event"
            noun="token"
          />
        </b-field>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button
            expanded
            icon="chevron-left"
            @click="$emit('update:active', false)"
          >
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button
            @click="getAccessToken()"
            expanded
            :loading="loading"
            :disabled="!canSubmit"
            :icon-right="iconFor('create')"
            type="is-primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import iconFor from "../../../icons";
import UserRoles from "../../../components/Roles/UserRoles";

export default {
  name: "AddDeveloperTokenModal",
  components: {
    UserRoles,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      downTo: "site",
      expires: null,
      accessPath: null,
      roles: [],
      rolesValid: false,
    };
  },
  computed: {
    canSubmit() {
      return (
        this.expires &&
        this.accessPath &&
        this.accessPath.length &&
        this.roles &&
        this.roles.length &&
        this.rolesValid
      );
    },
  },
  methods: {
    iconFor,
    emitClose() {
      this.$emit("close");
      this.$emit("update:active", false);
    },
    getAccessToken() {
      let self = this;
      self.loading = true;
      this.$openToast({ message: "Generating new token" });
      this.$dataClasses.DeveloperToken.save(null, {
        roles: self.roles,
        access_path: self.accessPath,
        expires: self.expires,
      })
        .then((result) => {
          document.querySelector("#developer-token-confirmed").value =
            result.id;
          let copySuccess = false;
          self.$buefy.dialog.alert({
            canCancel: false,
            type: "is-success",
            message: `
                            <p>
                              Your new developer API token is listed below ${
                                copySuccess
                                  ? "and has been copied to your clipboard"
                                  : "."
                              }
                            </p>
                            <p>
                              <b>API Token</b>: ${result.id}
                            </p>
                            <p>
                              Please save this in a secure location before closing this window.
                              You will <i>not</i> be shown the full token again.
                            </p>
                            `,
            onConfirm() {
              self.$emit("updated");
            },
          });
        })
        .catch((e) => self.$handleError(e, e))
        .finally(() => {
          self.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
