var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-message',[_vm._t("title",function(){return [_c('b',[_vm._v("Roles")])]}),(_vm.value && _vm.value.length)?_c('b-table',{attrs:{"data":_vm.sortedRows}},[_c('b-table-column',{attrs:{"label":"Role","field":"role"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(!_vm.editingRow(props.row.access))?_c('div',[_c('span',[_vm._v(_vm._s(_vm.title(props.row.role)))]),(
              _vm.user_roles.canEditPath(_vm.parentPath) &&
              props.row.access &&
              props.row.role !== 'root'
            )?_c('b-button',{staticClass:"ml-3",attrs:{"icon-left":"edit","disabled":_vm.disabled || !_vm.editable,"size":"is-small"},nativeOn:{"click":function($event){props.row.role = null;
              _vm.editRow(props.row);}}}):_vm._e()],1):_c('div',[(!_vm.editingRow(props.row.access))?_c('span',[_vm._v(_vm._s(_vm.title(props.row.role)))]):_vm._e(),(props.row.access)?_c('b-field',{attrs:{"message":'What role level would you like this ' + _vm.noun + ' to have?',"type":props.row.role ? 'is-success' : 'is-danger'}},[_c('b-select',{staticStyle:{"zoom":"80%"},attrs:{"required":"","expanded":"","disabled":_vm.disabled || !_vm.editable,"value":props.row.role},on:{"click":function($event){return _vm.click($event)},"input":function($event){_vm.selectedRole = $event;
                props.row.role = $event;
                props.row = Object.assign({}, props.row);
                _vm.check(props.index, $event);}}},[_c('option',{domProps:{"value":null}},[_c('i',[_vm._v("Please select a role")])]),_vm._l((_vm.availableRoles(props.row.access)),function(role){return _c('option',{key:role,domProps:{"value":role}},[_vm._v(" "+_vm._s(_vm.title(role))+" ")])})],2)],1):_vm._e()],1)]}}],null,false,779502677)}),_c('b-table-column',{attrs:{"label":"For"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.access)?_c('div',[(props.row.access)?_c('span',{attrs:{"title":_vm.user_roles.is_root ? props.row.access : ''}},[_vm._v(" "+_vm._s(_vm.readableNameFromIDStr(props.row.access))+" ")]):_vm._e()]):_c('div',[_c('customer-hierarchy-selection',{attrs:{"allow-at-root":_vm.user_roles.is_root && _vm.parentPath === '|root',"down-to-scope":"site"},on:{"lowest-selected":function($event){_vm.currentLowestSelected = $event}}}),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"mt-4",attrs:{"icon-right":"check","type":"is-success","disabled":!_vm.currentLowestSelected},on:{"click":function($event){return _vm.setAccessPathForEmptyRow(
                  _vm.currentLowestSelected ? _vm.currentLowestSelected.id_str : null
                )}}},[_vm._v(" Select ")])],1)],1)]}}],null,false,2625091503)}),(_vm.user_roles.canEditPath(_vm.parentPath))?_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"buttons is-pulled-right"},[(_vm.editable)?_c('b-button',{attrs:{"disabled":_vm.disabled,"title":"Delete","size":"is-small","type":"is-danger","icon-right":"times"},on:{"click":function($event){_vm.removeRow(props.index);
              _vm.editingRolePath = false;}}}):_vm._e()],1)]}}],null,false,443848069)}):_vm._e()],1):_c('p',[_vm._v(" This "+_vm._s(_vm.noun)+" has no valid roles. Without any valid role the "+_vm._s(_vm.noun)+" will not be able to access anything within AquaSeca. Add a role to get started. ")]),(_vm.validationMessage)?_c('p',{staticClass:"has-text-danger is-size-6"},[_vm._v(" "+_vm._s(_vm.validationMessage)+" ")]):_vm._e(),(
        !_vm.value || _vm.value.length === 0 || _vm.user_roles.canEditPath(_vm.parentPath)
      )?_c('b-button',{staticClass:"is-pulled-right mt-2",attrs:{"size":"is-small","icon-left":"plus","type":_vm.validationMessage ? 'is-danger' : '',"disabled":!_vm.editable ||
        _vm.disabled ||
        _vm.validationMessage ||
        _vm.editingRolePath ||
        (_vm.value.length &&
          _vm.value.filter((row) => row.access && row.role).length <
            _vm.value.length) ||
        _vm.value.filter((r) => r.role === 'root').length > 0},on:{"click":function($event){return _vm.addRow()}}},[_vm._v("Add another role ")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }