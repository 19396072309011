export function b64toBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var contentType = getContentTypeFromb64(dataURI);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
}

export function getContentTypeFromb64(dataURI) {
  return dataURI.split(";")[0].replace("data:", "");
}
