var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageLoaded),expression:"imageLoaded"}],class:`sensor-wrapper is-size-${_vm.size} ${_vm.classes}`,style:(`zoom:${_vm.zoom};`)},[_c('b-tooltip',{class:{
        pointer:
          _vm.position.$notHealthyText &&
          _vm.position.$notHealthyText.length &&
          !_vm.disableTooltip,
      },attrs:{"active":!_vm.disableTooltip && !!_vm._tooltip,"label":_vm._tooltip,"multilined":_vm.isMobile,"position":_vm.isTablet ? 'is-right' : 'is-top'}},[_c('div',{style:(`opacity:${_vm.isInactive ? '.35' : '1.0'};`)},[(_vm.sensorImage)?_c('img',{class:{
            sensor: true,
            'has-signal-indicator': _vm.showSignalIndicator,
          },attrs:{"src":_vm.sensorImage},on:{"load":function($event){_vm.imageLoaded = true}}}):_vm._e(),_vm._t("within"),(_vm.showSignalIndicator)?_c('sensor-signal-indicator',{staticClass:"signal",attrs:{"position":_vm.position}}):_vm._e(),(_vm.showBattery)?_c('sensor-battery',{attrs:{"position":_vm.position}}):_vm._e(),_c('div',{class:{
            serial: true,
            'has-signal-indicator': _vm.showSignalIndicator,
          }},[_vm._v(" "+_vm._s(_vm._serial)+" ")]),(_vm.showLocation)?_vm._t("post-serial",function(){return [_c('div',{staticClass:"dillbee"},[(_vm.position.$room)?_c('room-display',{attrs:{"value":_vm.position.$room,"show-link-icon":false,"show-icon":false,"show-unit":false,"show-floor":false}}):_vm._e()],1),(_vm.position.$room && _vm.position.$room.$getUnitOrFloorText())?_c('div',{staticClass:"dillbee",staticStyle:{"zoom":"80%"}},[_vm._v(" "+_vm._s(_vm.position.$room.$getUnitOrFloorText())+" ")]):_vm._e(),_c('div',{staticClass:"dillbee"},[(
                _vm.position.$pipeLocation &&
                ['hot', 'mixed'].includes(_vm.position.$pipeLocation.temperature)
              )?_c('b-icon',{attrs:{"icon":_vm.iconFor('hot'),"type":"is-hot","size":"is-small"}}):_vm._e(),(
                _vm.position.$pipeLocation &&
                ['cold', 'mixed'].includes(_vm.position.$pipeLocation.temperature)
              )?_c('b-icon',{attrs:{"icon":_vm.iconFor('cold'),"type":"is-cold","size":"is-small"}}):_vm._e()],1)]}):_vm._e()],2)]),_vm._t("default"),_vm._t("body"),(_vm.inAutothresholdLoop)?_c('b-tooltip',{staticClass:"pointer floater",attrs:{"type":"is-info","label":`This sensor is in an auto-threshold until ${
        _vm.autothresholdData.end_timestamp ? _vm.toDate(_vm.autothresholdData.end_timestamp).toLocaleTimeString() : 'an unknown time'
      }`}},[_c('b-icon',{staticClass:"fa-throb has-text-info",attrs:{"icon":"assistive-listening-systems","size":"is-large"}})],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }