<template>
  <div>
    <b-field label="What would you like to select?" v-if="downToEditable">
      <b-select v-model="downTo" expanded>
        <option value="root" v-if="downToValues.includes('root')">Root</option>
        <option
          value="customer"
          v-if="downToValues.includes('customer') && downToScope !== 'root'"
        >
          A whole organization
        </option>
        <option
          value="subcustomer"
          v-if="
            downToValues.includes('subcustomer') &&
            ['subcustomer', 'site', 'building'].includes(downToScope)
          "
        >
          A division/sub-customer
        </option>
        <option
          value="site"
          v-if="
            downToValues.includes('site') &&
            ['site', 'building'].includes(downToScope)
          "
        >
          An individual site
        </option>
        <option value="building" v-if="downToValues.includes('building')">
          A specific area
        </option>
      </b-select>
    </b-field>
    <div class="mb-3" v-else>
      <slot name="down-to-text"> Please select a {{ downTo }}.</slot>
    </div>
    <formulate-form>
      <b-button
          size="is-small"
          v-if="downTo === 'site' && $store.getters.site && $store.state.user_roles.is_root"
          @click="customerSelected($store.state.customers[0]);$nextTick(() => {subcustomerSelected($store.state.subcustomers[0]);$nextTick(()=>{siteSelected($store.getters.site)})})">
        Use current site
      </b-button>
      <b-field
        v-if="user_roles.is_root && allowAtRoot && downTo === 'root'"
        label="Select root access"
      >
        <b-checkbox v-model="isRoot"></b-checkbox>
      </b-field>
      <b-field
        :label="labels.customer || 'Select a customer'"
        v-show="downTo !== 'root'"
        v-if="shouldShowCustomer"
      >
        <div>
          <b-autocomplete
            :keep-first="false"
            open-on-focus
            v-model="customerInput"
            placeholder="Customer name"
            :icon="iconFor('customer')"
            field="name"
            clearable
            :check-infinite-scroll="true"
            :data="syncData.customers.results.filter(res=>(customerInput && customerInput.length) ? res.$displayString.toLowerCase().includes(customerInput.toLowerCase()) : true)"
            :loading="syncData.customers.isFetching"
            @infinite-scroll="getSyncData('customers')"
            @typing="getSyncData('customers')"
            @focus="!syncData.customers.page ? getSyncData('customers') : null"
            @select="(option) => customerSelected(option)"
          >
            <template #empty>
              <b-icon icon="sync" v-if="syncData.customers.isFetching" class="fa-spin" size="is-small"/>
              <span v-else>No results</span>
            </template>
          </b-autocomplete>
        </div>
      </b-field>
      <b-field
        :label="labels.subcustomer || 'Select a division/sub-customer'"
        v-show="customer"
        v-if="shouldShowSubcustomer"
      >
        <b-autocomplete
          open-on-focus
          :keep-first="false"
          v-model="subcustomerInput"
          placeholder="Division/sub-customer name"
          :icon="iconFor('subcustomer')"
          field="name"
          clearable
          :check-infinite-scroll="true"
          :data="syncData.subcustomers.results"
          :loading="syncData.subcustomers.isFetching"
          @infinite-scroll="getSyncData('subcustomers')"
          @typing="getSyncData('subcustomers')"
          @focus="!syncData.subcustomers.page ? getSyncData('subcustomers') : null"
          @select="(option) => subcustomerSelected(option)"
        >
          <template #header>
            <a>
              <span @click="openSubcustomerDialog(customer)">
                Add a new division/sub-customer
              </span>
            </a>
          </template>
          <template #empty>
              <b-icon icon="sync" v-if="syncData.subcustomers.isFetching" class="fa-spin" size="is-small"/>
              <span v-else>No results</span>
            </template>
        </b-autocomplete>
      </b-field>
      <b-field
        :label="labels.site || 'Select a site'"
        v-show="subcustomer"
        v-if="shouldShowSite"
      >
        <b-autocomplete
          open-on-focus
          :keep-first="false"
          v-model="siteInput"
          placeholder="Site name"
          :icon="iconFor('site')"
          field="name"
          clearable
          :custom-formatter="
            (option) =>
              option.$displayString
          "
          :check-infinite-scroll="true"
          :data="syncData.sites.results"
          :loading="syncData.sites.isFetching"
          @infinite-scroll="getSyncData('sites')"
          @typing="getSyncData('sites')"
          @focus="!syncData.sites.page ? getSyncData('sites') : null"
          @select="(option) => siteSelected(option)"
        >
          <template #header>
            <a @click="openSiteDialog(subcustomer)">
              <span> Add a new site </span>
            </a>
          </template>
          <template slot-scope="props">
            <site-display :value="props.option" show-address />
          </template>
          <template #empty>
            <b-icon icon="sync" v-if="syncData.sites.isFetching" class="fa-spin" size="is-small"/>
            <span v-else>No results</span>
          </template>
        </b-autocomplete>
      </b-field>
      <b-field
        :label="labels.building || 'Select an area'"
        v-show="site"
        v-if="shouldShowBuilding"
        :message="{
          'Warning: This building has no area assigned. Please contact support.':
            building && !building.area,
        }"
        :type="{ 'is-danger': building && !building.area }"
      >
        <b-autocomplete
          open-on-focus
          :keep-first="false"
          v-model="buildingInput"
          placeholder="Area name"
          :icon="iconFor('building')"
          field="name"
          clearable
          :check-infinite-scroll="true"
          :data="syncData.buildings.results"
          :loading="syncData.buildings.isFetching"
          @infinite-scroll="getSyncData('buildings')"
          @typing="getSyncData('buildings')"
          @focus="!syncData.sites.page ? getSyncData('buildings') : null"
          @select="(option) => buildingSelected(option)"
        >
          <template #header>
            <a @click="openBuildingDialog(site)">
              <span> Add a new area </span>
            </a>
          </template>
          <template slot-scope="props">
            <building-display :value="props.option" />
          </template>
          <template #empty>
            <b-icon icon="sync" v-if="syncData.buildings.isFetching" class="fa-spin" size="is-small"/>
            <span v-else>No results</span>
          </template>
        </b-autocomplete>
      </b-field>
    </formulate-form>
    <add-sub-customer-modal
      v-if="customer"
      @close="subCustomerModalIsOpen = false"
      @save="
        subcustomer = $event;
        subcustomerInput = $event.name;
      "
      :parent="customer"
      :value="subCustomerModalIsOpen"
      :id="null"
    ></add-sub-customer-modal>
    <add-site-modal
      v-if="subcustomer"
      @close="siteModalIsOpen = false"
      @save="
        site = $event;
        siteInput = $event.name;
      "
      :parent="subcustomer"
      :value="siteModalIsOpen"
      :id="null"
    ></add-site-modal>
    <add-building-modal
      v-if="site"
      @close="buildingModalIsOpen = false"
      @save="
        building = $event;
        buildingInput = $event.name;
        assignArea(toId($event.area));
      "
      :parent="site"
      :value="buildingModalIsOpen"
      :id="null"
    ></add-building-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import iconFor from "../../icons";
import { get } from "lodash";
import { mapState } from "vuex";
import AddSubCustomerModal from "../Admin/SubCustomers/AddSubcustomerModal";
import AddSiteModal from "../Admin/Sites/AddSiteModal";
import AddBuildingModal from "../Admin/Buildings/BuildingModal";
const LEVELS = ["customer", "subcustomer", "site", "building"];

function optionMatchesParent(parent, opt) {
  return !parent || opt.parent.id === parent.id;
}

export default {
  name: "CustomerHierarchySelectionFromList",
  components: {
    AddBuildingModal,
    AddSiteModal,
    AddSubCustomerModal,
  },
  props: {
    downToOptions: {
      type: Array,
      default: () => ["customer", "subcustomer", "site", "building"],
    },
    /**
     * Initial values for customer, subcustomer, site, building
     */
    initial: {
      type: Object,
      default: () => {
        return {
          root: null,
          customer: null,
          subcustomer: null,
          site: null,
          building: null,
        };
      },
    },
    /**
     * Object: keys are customer, subcustomer, site, building, value is label for form. If not supplied, each level
     * shows a default ('Select a <level>')
     */
    labels: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * Which level to require selection at (will not show lower levels)- i.e. 'site'
     * @values customer, subcustomer, site, building
     */
    downToScope: {
      type: String,
      default: null,
      required: false,
    },
    downToEditable: {
      type: Boolean,
      default: true,
    },
    /**
     * If root selection is allowed. Will also require user_roles.is_root
     */
    allowAtRoot: {
      type: Boolean,
      default: false,
    },
    cascadeSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      downTo: this.downToScope || "customer",
      isRoot: get(this.initial, "root", false),
      customer: get(this.initial, "customer", null),
      customerInput: get(this.initial, "customer.name", ""),
      subcustomer: get(this.initial, "subcustomer", null),
      subcustomerInput: get(this.initial, "subcustomer.name", ""),
      site: get(this.initial, "site", null),
      siteInput: get(this.initial, "site.name", ""),
      building: get(this.initial, "building", null),
      buildingInput: get(this.initial, "building.name", ""),

      subCustomerModalIsOpen: false,
      siteModalIsOpen: false,
      buildingModalIsOpen: false,

      syncData: {
        customers: {results:[], page:0, dataClass: this.$dataClasses.Customer, isFetching: false, hasMore: true, parent: null, },
        subcustomers: {results:[], page:0, dataClass: this.$dataClasses.SubCustomer, isFetching: false, hasMore: true, parent:'customer', parentField: 'customer',},
        sites: {results:[], page:0, dataClass: this.$dataClasses.Site, isFetching: false, hasMore: true, parent: 'subcustomer', parentField: 'subcustomer'},
        buildings: {results:[], page:0, dataClass: this.$dataClasses.Building, isFetching: false, hasMore: true, parent: 'site', parentField: 'site'},
      }
    };
  },
  watch: {
    isRoot: {
      handler: "handleIsRoot",
    },
    customer: {
      handler: "handleCustomer",
    },
    subcustomer: {
      handler: "handleSubcustomer",
    },
    site: {
      handler: "handleSite",
    },
    building: {
      handler: "handleBuilding",
    },
    lowestSelected: {
      handler: "handleLowestSelected",
    },
    downTo: {
      handler: function (val) {
        this.$emit("update:down-to-scope", val);
      },
    },
    downToScope: {
      handler(val) {
        this.downTo = val;
        this.buildingModalIsOpen = false;
        this.siteModalIsOpen = false;
        this.subCustomerModalIsOpen = false;
      },
    },
  },
  computed: {
    ...mapState(["user_roles", "selectedScopeIdStr"]),
    downToValues() {
      let options = this.downToOptions;
      if (this.allowAtRoot && this.user_roles.is_root) {
        options.unshift("root");
      }
      return options;
    },
    selected() {
      let rootValue = null,
        selectedData = {
          customer: this.customer,
          subcustomer: this.subcustomer,
          site: this.site,
          building: this.building,
        };
      if (this.isRoot && this.user_roles.is_root && this.allowAtRoot) {
        rootValue = { id_str: "|root" };
        selectedData.root = rootValue;
      }
      return selectedData;
    },
    lowestSelected() {
      let c = this.customer,
        sc = this.subcustomer,
        s = this.site,
        b = this.building,
        downTo = this.downTo,
        levels = [c, sc, s, b];
      if (this.downTo === "root" && this.user_roles.is_root) {
        return this.selected.root;
      }
      return levels[LEVELS.indexOf(downTo)];
    },
    shouldShowCustomer() {
      return this.shouldShow("customer", this.user_roles.is_root, this.downTo);
    },
    shouldShowSubcustomer() {
      return this.shouldShow("subcustomer", this.user_roles.is_root, this.downTo);
    },
    shouldShowSite() {
      return this.shouldShow("site", this.user_roles.is_root, this.downTo);
    },
    shouldShowBuilding() {
      return this.shouldShow("building", this.user_roles.is_root, this.downTo);
    },
  },
  mounted() {
    if (this.downTo && !LEVELS.includes(this.downTo)) {
      throw `Unknown data type: ${this.downTo}`;
    }
    if (this.value) {
      this.innerValue = this.value;
    }
    this.handleIsRoot(this.isRoot);
    this.handleCustomer(this.customer);
    this.handleSubcustomer(this.subcustomer);
    this.handleSite(this.site);
    this.handleBuilding(this.building);
    LEVELS.forEach((level) => {
      if (this.shouldShow(level)) {
        this.$emit(`${level}-selected`, this.selected[level]);
      }
    });
  },
  methods: {
    getSyncData: debounce(function(level) {
      let syncDataObj = this.syncData[level],
          page = syncDataObj.page+1,
          paginate_by = 25;
      if (syncDataObj.isFetching || !syncDataObj.hasMore) {
        return
      }
      this.syncData[level].isFetching = true;
      let promise = syncDataObj.parent ?
          syncDataObj.dataClass.query(
              [[syncDataObj.parentField, '==', this.toFSRef(this.$data[syncDataObj.parent], syncDataObj.parent)]],
              {paginate_by:1000}
          ): syncDataObj.dataClass.list(
          {page, paginate_by}
      )
      promise
          .then(results => {
            this.syncData[level].results = [...this.syncData[level].results, ...results];
            if (!syncDataObj.parent) {
              this.syncData[level].page = page;
              this.syncData[level].hasMore = results.length === paginate_by;
            } else {
              this.syncData[level].hasMore = false;
            }
          })
          .finally(
              () => this.syncData[level].isFetching = false
          )
    }),
    handleIsRoot(val) {
      if (val) {
        this.customer = null;
        this.subcustomer = null;
        this.site = null;
        this.building = null;
      }
      this.rootSelected(val);
    },
    handleCustomer(val) {
      this.customerInput = val ? val.name : "";
      this.subcustomer = null;
      this.customerSelected(val);
    },
    handleSubcustomer(val) {
      this.subcustomerInput = val ? val.name : "";
      this.site = null;
      this.subcustomerSelected(val);
    },
    handleSite(val) {
      this.siteInput = val ? val.name : "";
      this.building = null;
      this.buildingInput = "";
      this.siteSelected(val);
    },
    handleBuilding(val) {
      this.buildingInput = val ? val.name : "";
      this.buildingSelected(val);
    },
    handleLowestSelected(val) {
      this.$emit("lowest-selected", val);
    },
    iconFor,
    rootSelected() {
      let val = this.isRoot && this.user_roles.is_root && this.allowAtRoot;
      this.$emit("root-selected", val);
      this.select();
    },
    customerSelected(val) {
      /**
       * Fires when customer selection changes (or is cleared)
       * @event customer-selected
       * @type {object}
       */
      this.customer = val;
      if (val) {
        this.customerInput = val.name;
      }

      this.$emit("customer-selected", val);
      this.select();
      this.syncData.subcustomers = {...this.syncData.subcustomers, results: [], hasMore: true, page: 0, isFetching: false}
      this.syncData.sites = {...this.syncData.sites, results: [], hasMore: true, page: 0, isFetching: false}
      this.syncData.buildings = {...this.syncData.buildings, results: [],hasMore: true, page: 0, isFetching: false}
    },
    subcustomerSelected(val) {
      /**
       * Fires when subcustomer selection changes (or is cleared)
       * @event subcustomer-selected
       * @type {object}
       */
      this.subcustomer = val;
      if (val) {
        this.subcustomerInput = val.name;
      }
      this.$emit("subcustomer-selected", val);
      this.select();
      this.syncData.sites = {...this.syncData.sites, results: [],hasMore: true, page: 0, isFetching: false}
      this.syncData.buildings = {...this.syncData.buildings, results: [],hasMore: true, page: 0, isFetching: false}
    },
    siteSelected(val) {
      /**
       * Fires when site selection changes (or is cleared)
       * @event site-selected
       * @type {object}
       */
      this.site = val;
      if (val) {
        this.siteInput = val.name;
      }
      this.$emit("site-selected", val);
      this.select();
      this.syncData.buildings = {...this.syncData.buildings, results: [], hasMore: true, page: 0, isFetching: false}
    },
    buildingSelected(val) {
      /**
       * Fires when building selection changes (or is cleared)
       * @event building-selected
       * @type {object}
       */
      this.building = val;
      if (val) {
        this.buildingInput = val.name;
      }
      this.$emit("building-selected", val);
      this.select();
    },
    select() {
      /**
       * Fires when *any* of the selections of this component changes.
       * @event select
       * @type {object} with keys customer, subcustomer, site, building
       */
      this.$emit("select", this.selected);
    },
    hasSubstring(fullStr, matchStr) {
      /**
       * Used to test what to show in the Autocomplete
       */
      return !fullStr || fullStr.toLowerCase().includes(matchStr.toLowerCase());
    },
    shouldShow(level, userIsRoot, downToScope) {
      /**
       * Tests if a given level of the customer hierarchy should have it's select element shown
       */
      let rootTakeOver = userIsRoot && downToScope === "root";
      if (level === "root") {
        return rootTakeOver;
      }
      return (
        !rootTakeOver && // if root, hide all others
        (!downToScope || LEVELS.indexOf(downToScope) >= LEVELS.indexOf(level))
      );
    },
    openSubcustomerDialog(parentOfSubcustomer) {
      /**
       * Opens dialog with input for subcustomer name; only opens when customer is selected (and is assumed to be parent
       * of new subcustomer)
       */
      if (!parentOfSubcustomer) {
        throw "New subcustomer must have parent";
      }
      this.subCustomerModalIsOpen = false;
      this.$nextTick(() => {
        this.subCustomerModalIsOpen = true;
      });
    },
    openSiteDialog(parentOfSite) {
      /**
       * Used to add a new site. Opens 2 dialogs (for now) to deal with
       */
      if (!parentOfSite) {
        throw "New site must have parent";
      }
      this.siteModalIsOpen = false;
      this.$nextTick(() => {
        this.siteModalIsOpen = true;
      });
    },
    openBuildingDialog(parentOfBuilding) {
      if (!parentOfBuilding) {
        throw "New area must have parent";
      }
      this.buildingModalIsOpen = false;
      this.$nextTick(() => {
        this.buildingModalIsOpen = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../mixins/buefy/styles";

.bottom-pad {
  padding-bottom: 35px;
}
</style>
