/**
 * Fetches device given serial
 * @param {string} serial
 * @returns {null, Object}
 */
import { getObjectInArrayById } from "../scripts/filterHelpers";
import {
  getDeviceInstanceFromSerial,
} from "../scripts/deviceQrCodes";
import Building from "../dataClasses/Building";

/**
 *
 * @param {string} serial
 * @return {Promise<Gateway|Sensor|Probe|Error>}
 */
export function getSelectedDeviceBySerial({ serial }) {
  return getDeviceInstanceFromSerial(serial);
}

export default {
  name: "IsDeviceActionComponentMixin",
  /**
   * @returns {{selectedDevice: {null, Object}}}
   */
  mounted() {
    if (!this.$route.query.step) {
      setTimeout(this.goToNextInvalidOrFinalStep, 250);
    }
  },
  /**
   *
   */
  data() {
    return {
      lastStep: null,
      loading: false,
      selectedDeviceLoading: false,
      selectedDevice: null,
    };
  },
  methods: {
    stepType(step) {
      if (this.step > step && !this.validate(step)) {
        return "is-danger";
      }
    },
    goForward() {
      this.$nextTick(() => (this.step = parseInt(this.step) + 1));
    },
    goBackward() {
      this.$nextTick(() => (this.step = parseInt(this.step) - 1));
    },
    validate(step) {
      throw "Must be overridden";
    },
    goToNextInvalidOrFinalStep() {
      let step =
        this.invalidSteps && this.invalidSteps.length
          ? this.invalidSteps[0]
          : (this.step = this.stepArray[this.stepArray.length - 1]);
      this.queryReplace({ step });
    },
    /**
     *
     * @return {Promise<any>}
     */
    reloadArea() {
      if (!this.area) {
        return;
      }
      return this.area
        .refresh()
        .then((area) => {
          this.$store.commit("add", {name: "areas", result: area})
          return Promise.resolve();
        });
    },
  },
  computed: {
    pipeLocations() {
      return this.$store.state.pipe_locations;
    },
    stepArray() {
      throw "Must be overridden";
    },
    invalidSteps() {
      return this.stepArray.filter((step) => !this.validate(step));
    },
    invalidPreConfirmSteps() {
      return this.invalidSteps.filter(
        (step) => step !== this.stepArray.slice(-1)[0]
      );
    },
    step: {
      get() {
        return this.$route.query.step
          ? parseInt(this.$route.query.step)
          : this.stepArray[0];
      },
      set(val) {
        this.queryReplace({ step: val });
      },
    },
    serial: {
      get() {
        return this.$route.query.serial;
      },
      set(val) {
        this.queryReplace({ serial: val });
      },
    },
    area() {
      return this.building ? this.building.$area : null;
    },
    building: {
      get() {
        let buildingId = this.$route.query.building,
          data = buildingId
            ? getObjectInArrayById(
                this.$store.state.buildings,
                buildingId,
                null
              )
            : null;
        return data ? new Building(data) : null;
      },
      set(val) {
        if (val instanceof Object) {
          val = val.id;
        }
        this.queryReplace({ building: val });
      },
    },
  },
  watch: {
    serial: {
      immediate: true,
      handler(serial) {
        if (!serial) {
          this.selectedDevice = null;
          return;
        }
        this.selectedDeviceLoading = true;
        getSelectedDeviceBySerial({
          serial,
        })
          .then((result) => (this.selectedDevice = result))
          .finally(() => (this.selectedDeviceLoading = false));
      },
    },
    step: {
      handler(val, oldVal) {
        this.lastStep = oldVal;
      }
    }
  },
};
