<template>
  <signal-indicator
    :strength="position.$categoricalSignalStrength"
    @strength-change="$emit('strength-change', $event)"
  />
</template>

<script>
import SignalIndicator from "../signal-indicator";
import Position from "../../dataClasses/Position";

export default {
  name: "SensorSignalIndicator",
  components: {
    SignalIndicator,
  },
  props: {
    position: {
      type: Position,
      required: true,
    },
  },
};
</script>

<style scoped></style>
