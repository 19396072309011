<template>
  <b-table :data="trainingQuality" title="Training quality">
    <b-table-column label="Quality" field="quality" v-slot="props">
                <span
                    :class="
                    props.row.quality === 'good'
                      ? 'has-text-success'
                      : 'has-text-danger has-text-weight-bold'
                  "
                >
                  {{ capitalizeFirstLetter(props.row.quality) }}
                </span>
    </b-table-column>
    <b-table-column label="POU" field="source_nm" v-slot="props">
      <source-display
          :value="
                    $store.getters.objectByID(
                      props.row.source_nm.replace('SRC:', '')
                    )
                  "
      />
    </b-table-column>
    <b-table-column label="Room" v-slot="props">
      <room-display
          :value="
                    $store.getters.objectByID(
                      props.row.source_nm.replace('SRC:', ''),
                      {},
                      $store.state.sources
                    ).$room
                  "
          v-if="
                    $store.getters.objectByID(
                      props.row.source_nm.replace('SRC:', ''),
                      {},
                      $store.state.sources
                    ).$room
                  "
          :show-icon="false"
          :show-floor="false"
          :show-unit="false"
          :show-link-icon="false"
      />
    </b-table-column>
    <b-table-column label="Floor" v-slot="props">
                <span
                    v-if="
                    get(
                      $store.getters.objectByID(
                        props.row.source_nm.replace('SRC:', ''),
                        {},
                        $store.state.sources
                      ),
                      '$room.floor',
                      null
                    )
                  "
                >
                  {{
                    get(
                        $store.getters.objectByID(
                            props.row.source_nm.replace('SRC:', ''),
                            {},
                            $store.state.sources
                        ),
                        '$room.floor',
                        null
                    )
                  }}
                </span>
    </b-table-column>
    <b-table-column label="Unit" v-slot="props">
                <span
                    v-if="
                    get(
                      $store.getters.objectByID(
                        props.row.source_nm.replace('SRC:', ''),
                        {},
                        $store.state.sources
                      ),
                      '$room.unit',
                      null
                    )
                  "
                >
                  {{
                    get(
                        $store.getters.objectByID(
                            props.row.source_nm.replace('SRC:', ''),
                            {},
                            $store.state.sources
                        ),
                        '$room.unit',
                        null
                    )
                  }}
                </span>
    </b-table-column>
    <b-table-column
        label="Sensors"
        field="sensor_count"
        numeric
        v-slot="props"
    >
      {{ props.row.sensor_count }}
    </b-table-column>
    <b-table-column
        label="Amount (seconds)"
        field="nb_seconds"
        sortable
        numeric
        v-slot="props"
    >
                <span
                    :class="
                    !props.row.nb_seconds
                      ? 'has-text-danger'
                      : props.row.nb_seconds < 30
                      ? 'has-text-warning'
                      : 'has-text-success'
                  "
                >
                  {{ (props.row.nb_seconds || 0).toFixed(1) }}
                </span>
    </b-table-column>
    <b-table-column
        v-slot="props"
        label="Suggestion"
    >
      <b-button
          size="is-small"
          :type="props.row.quality === 'good' ? 'is-default is-outlined is-light' : 'is-success'"
          @click="sourceClicked($store.getters.objectByID(props.row.source_nm.replace('SRC:', '')))"
      >
        Add training
      </b-button>
    </b-table-column>
  </b-table>
</template>

<script>
import {get} from 'lodash';

export default {
  name: "GroundTruthTrainingQualityTable",
  props: {
    trainingQuality: {
      type: Array,
      default: () => {return[]}
    }
  },
  methods: {
    get,
    sourceClicked(src) {
      this.$emit('input', src);
    }
  }
}
</script>

<style scoped>

</style>