<template>
  <div>
    <i v-if="!loading && (!rows || !rows.length)">
      No ground truth found for this object
    </i>
    <b-table
        v-else
        bordered
        checkable
        detailed
        striped
        :default-sort="['information.post_dt']"
        default-sort-direction="desc"
        :checked-rows="checkedRows"
        :custom-is-checked="
          (a, b) => {
            return a.id === b.id;
          }
        "
        detail-key="id"
        :data="finalRows"
        :is-row-checkable="rowIsCheckable"
        :loading="loading"
        :paginated="rows && rows.length > perPage"
        :per-page="perPage"
        @check="$emit('check', $event);$emit('update:checked-rows',$event)"
    >
      <b-table-column
          v-slot="props"
          label="Time"
          field="information.post_dt"
          sortable
      >
        <span v-if="props.row.information && props.row.information.post_dt">
          {{ shortenedLocaleString(props.row.information.post_dt) }}
        </span>
        <i v-else>None</i>
      </b-table-column>
      <b-table-column v-slot="props" label="Room" field="roomName" sortable>
        <room-display
            v-if="sourceWithNm(props.row.information.actual_source).room"
            :value="
            getObjectInArrayById(
              $store.state.rooms,
              sourceWithNm(props.row.information.actual_source).room,
              null
            )
          "
        />
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="POU"
          sortable
          :custom-sort="sortPOU"
      >
        <source-display
            :value="sourceWithNm(props.row.information.actual_source)"
            show-training-level
        />
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Sensor"
          field="information.sensor_serial"
          sortable
      >
        <span v-if="props.row.information.sensor_serial">
          {{ props.row.information.sensor_serial }}
        </span>
        <i v-else>Not assigned</i>
      </b-table-column>
      <b-table-column
          label="Duration"
          field="information.duration_seconds"
          sortable
          v-slot="props"
      >
        <span
            v-if="
            props.row &&
            props.row.information &&
            props.row.information.duration_seconds
          "
            :class="{'has-text-danger':props.row.information.duration_seconds > 58}"
        >
          {{
            shortHumanizer(props.row.information.duration_seconds * 1000)
          }}
        </span>
        <i v-else>Not assigned</i>
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Quality level"
          sortable
          numeric
          field="evaluation.quality"
          :custom-sort="sortQualityFunction"
      >
        <span v-if="props.row.evaluation && props.row.evaluation.quality">
          {{ capitalizeFirstLetter(props.row.evaluation.quality) }}
        </span>
        <i v-else>Not assigned</i>
      </b-table-column>
      <template #detail="props">
        <ground-truth-detail
            :value="props.row"
            @update="rowUpdated($event)"
            @deleted="rowDeleted($event)"
        />
      </template>
      <template #bottom-left>
        <slot name="bottom-left">
          <b-field label="Show per page">
            <b-select v-model="perPage">
              <option :value="10">10</option>
              <option :value="25">25</option>
              <option :value="50">50</option>
              <option :value="100000">All</option>
            </b-select>
          </b-field>
        </slot>
      </template>
      <template #footer>
        <slot name="footer"/>
      </template>
    </b-table>
  </div>
</template>

<script>
import {get} from "lodash";
import shortHumanizer from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import iconFor from "../icons";
import {getObjectInArrayById} from "../scripts/filterHelpers";
import GroundTruthDetail from "../applications/Utilities/GroundTruth/GroundTruthDetail";

export default {
  name: "GroundTruthTable",
  components: {
    GroundTruthDetail,
  },
  data() {
    return {
      perPage: 10,
    };
  },
  props: {
    checkedRows: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    finalRows() {
      let self = this;
      return this.rows.map((obj) => {
        let src = self.sourceWithNm(obj.information.actual_source);
        return {
          ...obj,
          roomName:
              obj && src && src.room
                  ? getObjectInArrayById(this.$store.state.rooms, src.room, {
                    name: "Room not found",
                  }).name
                  : "",
        };
      });
    },
  },
  methods: {
    sortPOU(a, b, isAsc) {
      let valA =
              this.sourceWithNm(get(a, "information.actual_source"), {}).name || "",
          valB =
              this.sourceWithNm(get(b, "information.actual_source"), {}).name || "";
      return isAsc ? valA.localeCompare(valB) : valB.localeCompare(valA);
    },
    sortQualityFunction(a, b, isAsc) {
      let valA = get(a, "evaluation.quality", 0),
          valB = get(b, "evaluation.quality", 0),
          levels = {low: 1, medium: 2, good: 3, excellent: 4},
          levelA = levels[valA] || 0,
          levelB = levels[valB] || 0;
      return isAsc ? levelA - levelB : levelB - levelA;
    },
    rowIsCheckable(row) {
      return (
          this.checkedRows.length <= 10 ||
          this.checkedRows.map((r) => r.id).includes(row.id)
      );
    },
    getObjectInArrayById,
    iconFor,
    shortHumanizer,
    rowUpdated(row) {
      this.$emit("update", row);
    },
    sourceWithNm(srcNm) {
      return getObjectInArrayById(
          this.$store.state.sources,
          srcNm.replace("SRC:", ""),
          {}
      );
    },
    shortenedLocaleString(d) {
      if (typeof d !== "object") {
        return d;
      }
      let options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return d.toLocaleDateString("en", options); //en is language option, you may specify..
    },
  },
};
</script>

<style scoped></style>
