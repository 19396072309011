import { component } from "vue-fullscreen";

/**
 *
 */
export default {
  name: "FullscreenMixin",
  components: { fullscreen: component },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    toggleFullscreenApi() {
      this.$fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
      });
    },
  },
  data() {
    return {
      fullscreen: false,
      teleport: true,
      pageOnly: false,
    };
  },
};
