<template>
  <div class="panel is-default">
    <div class="panel-heading">Device events</div>
    <p class="panel-tabs">
      <a
        v-for="name in Object.keys(tabs)"
        :class="{ 'is-active': currentTab === name }"
        @click="currentTab = name"
        :key="name"
      >
        {{ name }}
      </a>
    </p>
    <div class="panel-block">
      <b-table
        :data="data"
        striped
        bordered
        :loading="firstLoad"
        class="is-fullwidth full"
        expanded
        :total="total"
        :per-page="perPage"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :detailed="$store.state.user_roles.is_root"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
      >
        <b-table-column field="timestamp" label="Occurred at" v-slot="props">
          {{
            props.row.timestamp
              ? props.row.timestamp.toLocaleString()
              : "Unavailable"
          }}
        </b-table-column>
        <b-table-column field="event_type" label="Event type" v-slot="props">
          {{ eventType(props.row) }}
        </b-table-column>
        <b-table-column field="comment" label="Comment" v-slot="props">
          <span v-if="props.row.comment">{{ props.row.comment }}</span>
          <i v-else>None</i>
        </b-table-column>
        <b-table-column v-if="canDelete" v-slot="props" label="">
          <b-button
            type="is-danger"
            @click="deleteClicked(props.row)"
            v-if="canDelete"
            :icon-left="iconFor('delete')"
            size="is-small"
          />
        </b-table-column>
        <template #detail="props" v-if="$store.state.user_roles.is_root">
          <json-viewer
            :value="wrangled(props.row)"
            :expand-depth="5"
            copyable
            boxed
            sort
          />
        </template>
        <template #empty>
          <p class="has-text-centered has-text-weight-bold">
            No events found for this entity
          </p>
        </template>
      </b-table>
    </div>
    <div class="panel-list">
      <b-button
        expanded
        :icon-right="canLoadMore ? 'chevron-down' : 'times-circle'"
        :disabled="!canLoadMore"
        :loading="loading"
        @click="
          page += 1;
          onPageChange(page);
        "
      >
        {{ canLoadMore ? "Load more events" : "No further events" }}
      </b-button>
    </div>
  </div>
</template>

<script>
import iconFor from "../../icons";
import serialize from "../../apiClient/serializer";
import { get } from "lodash";

import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";

import DeviceEvent from "../../dataClasses/DeviceEvent";

export default {
  name: "EventHistory",
  components: { JsonViewer },
  data() {
    return {
      currentTab: Object.keys(this.tabs).length
        ? Object.keys(this.tabs)[0]
        : null,
      data: [],
      total: 0,
      firstLoad: true,
      loading: false,
      sortField: "timestamp",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      page: 1,
      perPage: this.paginateBy,
    };
  },
  props: {
    paginateBy: {
      type: Number,
      default: 5,
    },
    /**
     * {"Tab Name":fsRefForDevice}
     */
    tabs: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    iconFor,
    deleteClicked(event) {
      let self = this;
      self.$buefy.dialog.confirm({
        title: "Delete this device event?",
        type: "is-danger",
        message: `Delete device event <b>${event.id}</b> of type <b>${
          event.event_type
        }</b>
            ${event.comment ? "(comment: <i>" + event.comment + "</i>" : ""}?`,
        onConfirm() {
          self.loading = true;
          DeviceEvent.deleteById(event.id)
            .then(() => {
              self.$handleSuccess("Device event removed");
              self.reset();
              self.loadAsyncData();
            })
            .catch((e) =>
              self.$handleError(
                `Could not remove device event '${event.id}'; see console`,
                e
              )
            )
            .finally(() => {
              self.loading = false;
            });
        },
      });
    },
    reset() {
      this.data = [];
      this.total = 0;
      this.firstLoad = true;
      this.loading = false;
      this.sortField = "timestamp";
      this.sortOrder = "desc";
      this.defaultSortOrder = "desc";
      this.page = 1;
      this.perPage = this.paginateBy;
    },
    wrangled(jsonData) {
      return serialize(jsonData || {});
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    eventType(event) {
      let it = get(event, "event_type", null);
      return it ? this.capitalizeFirstLetter(it.replace(/_/g, " ")) : null;
    },
    loadAsyncData() {
      this.loading = true;
      DeviceEvent.query([["parent", "==", this.tabParent]], {
        page: this.page,
        paginate_by: this.paginateBy,
        order_field: "timestamp",
        direction: "desc",
        fields: this.$store.state.user_roles.is_root
          ? ""
          : "timestamp,event_type,comment",
      })
        .then((results) => {
          // api.themoviedb.org manage max 1000 pages
          this.data = this.data.concat(results);
          this.total = results.length * this.page;
        })
        .catch((e) => {
          this.$handleError(e, e);
        })
        .finally(() => {
          this.loading = false;
          this.firstLoad = false;
        });
    },
    onTabChange() {
      this.firstLoad = true;
      this.data = [];
      this.page = 1;
      this.loadAsyncData();
    },
  },
  computed: {
    canDelete() {
      return (
        this.$store.state.user_roles && this.$store.state.user_roles.is_root
      );
    },
    canLoadMore() {
      let results = this.data.length;
      return results >= this.page * this.perPage;
    },
    tabParent() {
      return this.tabs[this.currentTab];
    },
  },
  mounted() {
    this.loadAsyncData();
  },
  watch: {
    currentTab: {
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.onTabChange();
        }
      },
    },
  },
};
</script>

<style scoped>
.full {
  width: 100% !important;
}
</style>
