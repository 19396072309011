<template>
  <div class="hierarchy-objects-display">
  <div
      v-for="(val, key) in hierarchyObjects"
      :key="key"
      class="has-text-justified has-text-justified-left"
  >
    <div class="is-inline-block " v-if="show==='all' || (Array.isArray(show) && show.includes(key)) ">
      <b>{{ key }}</b>:
      <div v-if="!val" class="ml-6"><i>None</i></div>
      <div v-else-if="typeof val === 'object'" class="ml-6">
        <generic-display
            :value="val"
            :show-link-icon="showLinkIcons"
            link-icon-no-pull
            show-link-icon
            :show-tooltip="false"
        />
      </div>
      <span v-else-if="typeof val === 'string'" class="ml-6">{{ val }}</span>
    </div>
  </div>
    </div>
</template>

<script>
import GenericDisplay from "./GenericDisplay";
export default {
  name: "HierarchyObjectsDisplay",
  components: {GenericDisplay},
  props: {
    /**
     * Should be {Name: dataClassInstance, ...}
     */
    hierarchyObjects: {
      type: Object,
      default: null,
      required: true
    },
    show: {
      type: [String, Array],
      default: 'all'
    },
    showLinkIcons: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>