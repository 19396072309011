<template>
  <div class="well">
    <list-or-query-result-input
      :data-class="dataClass"
      query-field="name"
      ref="resultInput"
      :paginate-by="25"
      paginated
      :base-queries="[
        [
          'bundle_state.event_type',
          'in',
          viewRetired
            ? ['bundle_retired']
            : ['bundle_available', 'bundle_shipped'],
        ],
      ]"
      @loading="loading = $event"
      @updated="results = $event"
    >
      <div class="level-item">
        <b-field>
          <b-button
            @click="viewRetired = !viewRetired"
            type="is-primary"
            class="is-light"
            outlined
          >
            {{
              viewRetired ? "List retired bundles" : "List non-retired bundles"
            }}
          </b-button>
        </b-field>
      </div>
    </list-or-query-result-input>

    <bundle-table
      :loading="loading"
      :rows="results"
      :view-on-click="false"
      @select="select($event)"
    />
  </div>
</template>

<script>
import BundleTable from "../../tables/BundleTable";
import ListOrQueryResultInput from "../Inputs/ListOrQueryResultInput";
import Bundle from "../../dataClasses/Bundle";

export default {
  name: "SelectBundle",
  components: {
    BundleTable,
    ListOrQueryResultInput,
  },
  data() {
    return {
      loading: false,
      results: [],
    };
  },
  computed: {
    dataClass() {
      return Bundle;
    },
    viewRetired: {
      get() {
        return this.$route.query.retired == 1;
      },
      set(retired) {
        this.queryReplace({ retired: retired ? 1 : 0 });
      },
    },
  },
  methods: {
    select(obj) {
      this.$emit("select", obj);
    },
  },
  watch: {
    viewRetired: {
      handler(val) {
        this.$refs.resultInput.getResults();
      },
    },
  },
};
</script>

<style scoped></style>
