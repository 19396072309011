import distinctColors from "distinct-colors";

const defaults = {
  chromaMin: 70,
  chromaMax: 100,
  lightMin: 30,
  lightMax: 100,
  hueMax: 300,
  samples: 2500,
  quality: 25,
};

export default function getHexColorArray(opts = {}) {
  const options = { ...defaults, ...opts };
  return distinctColors(options).map((c) => c.hex());
}
