<template>
  <div
    class="train-source-container"
    @keydown.right="nextSource ? nextSourceClicked() : () => {}"
    @keydown.left="prevSource ? prevSourceClicked() : () => {}"
    tabindex="0"
  >
    <loading-indicator v-if="loadingSources" />
    <div v-if="!loadingSources">
      <div class="card" v-if="!loadingSources">
        <div class="card-header">
          <div class="card-header-title" v-if="source" autofocus>
            <span style="font-weight: normal">Point-of-use &nbsp;</span>
            <source-display :value="source" />
            <span style="font-weight: normal"> &nbsp; in room &nbsp; </span>
            <room-display :value="source.$room" v-if="source && source.$room" />
          </div>
          <div class="card-header-icon">
            <b-icon
              icon="times"
              class="pointer"
              @click.native="source.$area.$goToTrainingOverview($route.query)"
            />
          </div>
        </div>
        <div class="card-content">
          <b-tabs v-model="tab">
            <b-tab-item key="pou" value="pou" label="Classify incoming flow">
              <div class="columns">
                <div class="column is-6">
                  <b-field
                    label="Show events starting at:"
                    :message="timeWarning"
                    :class="{ 'has-text-danger': timeWarning }"
                  >
                    <p class="control">
                      <b-datetimepicker
                        :value="start"
                        @input="timeSelected($event)"
                        rounded
                        enable-seconds
                        :placeholder="start.toLocaleString()"
                        icon="clock"
                      />
                    </p>
                    <p class="control" title="Hide all previous alarms">
                      <b-icon
                        icon="clock"
                        class="pointer mt-1"
                        size="is-medium"
                        @click.native="
                          timeSelected(new Date());
                          queryRemoveKeys(['detail', 'start']);
                        "
                      />
                    </p>
                  </b-field>
                  <b-field label="Number of events to show">
                    <b-select v-model="limit">
                      <option
                        v-for="limitCount in limitOptions"
                        :value="limitCount"
                        :key="limitCount"
                      >
                        {{ limitCount }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="Amount of ground truth">
                    <b-icon
                      v-if="loading.ground_truth"
                      icon="spinner"
                      class="fa-spin"
                    />
                    <ground-truth-amount v-else :value="groundTruthDuration" />
                  </b-field>
                  <b-field grouped label="Show classified alarms">
                    <b-checkbox v-model="includeClassified" />
                  </b-field>
                  <b-field grouped label="View sensors as table">
                    <b-checkbox v-model="viewAsTable" />
                  </b-field>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item
              key="gt"
              value="gt"
              :label="`Ground truth${
                loading.ground_truth
                  ? ''
                  : ` (${ground_truth.length} record${
                      ground_truth.length === 1 ? '' : 's'
                    })`
              }`"
            >
              <div class="mb-3">
                <b-icon
                  v-if="loading.ground_truth"
                  icon="spinner"
                  class="fa-spin"
                />
                <ground-truth-amount :value="groundTruthDuration" v-else />
              </div>
              <ground-truth-table
                :loading="loading.ground_truth"
                :rows="ground_truth"
                :checked-rows.sync="checkedRows"
              >
                <template #footer>
                  <div
                    class="has-text-justified has-text-right is-justify-content-right"
                  >
                    <b-button
                      type="is-danger"
                      icon="times"
                      :disabled="
                        !checkedRows ||
                        !checkedRows.length ||
                        checkedRows.length > 10
                      "
                      @click="openDeleteDialog()"
                    >
                      Delete checked ({{ checkedRows.length }})
                    </b-button>
                  </div>
                </template>
                <template #bottom-left><span>&nbsp;</span></template>
              </ground-truth-table>
            </b-tab-item>
          </b-tabs>

          <transition name="slide">
            <div v-if="tab === 'pou'">
              <transition name="slide">
                <p v-if="includeClassified" class="mt-3 mb-6 has-text-centered">
                  <span class="has-text-weight-bold has-text-danger"
                    >Caution:
                  </span>
                  You are viewing flow events that have already been classified.
                  any changes will overwrite previous classifications.
                </p>
              </transition>
              <transition name="slide">
                <UserMessageDetail
                  @update:loading="loading.alarm = $event.loading"
                  :loading="loading.alarm"
                  @classified="
                    queryRemoveKeys(['detail']);
                    getGroundTruth(false, $event);
                  "
                  @update:dismissed="queryRemoveKeys(['detail'])"
                  use-collection="alarms"
                  v-if="$route.query.detail"
                  :id="$route.query.detail"
                  :on-close-function="() => queryRemoveKeys(['detail'])"
                />
              </transition>
              <transition name="slide">
                <div class="" v-if="!$route.query.detail">
                  <fade-transition
                    tag="div"
                    class="tile is-12 is-child"
                    :duration="300"
                    group
                  >
                    <select-source-alarm
                      v-for="alarm in alarms"
                      @threshold-edit-clicked="
                        $emit('threshold-edit-clicked', $event)
                      "
                      @click="viewDetail(alarm)"
                      @flow-from-different-pou="viewDetail(alarm)"
                      @classified="getGroundTruth(false, $event)"
                      :view-as-table="viewAsTable"
                      :key="alarm.id"
                      :alarm="alarm"
                      :source="source"
                      :available-positions="positions"
                    />
                  </fade-transition>
                  <fade-transition
                    :duration="500"
                    tag="div"
                    class="is-12 is-fullwidth"
                  >
                    <b-message
                      :closable="false"
                      :type="includeClassified ? 'is-warning' : 'is-info'"
                      class="mb-6 mt-3 has-text-centered"
                      v-if="
                        source &&
                        source.$area &&
                        !newSourceToggled &&
                        !(alarms && alarms.length)
                      "
                      :title="
                        includeClassified
                          ? 'No training data found for point-of-use'
                          : 'Listening for flowing water'
                      "
                    >
                      <p class="m-3 has-text-centered">
                        <b-icon
                          :icon="includeClassified ? 'times-circle' : 'tint'"
                          :class="
                            !includeClassified ? 'faa-burst animated' : ''
                          "
                          size="is-large"
                        />
                      </p>

                      <div class="m-3" v-if="!includeClassified">
                        Run water from the point-of-use
                        <b>{{ source.$displayString }}</b>
                        <span v-if="source.$room">
                          in <b>{{ source.$room.$displayString }}</b></span
                        >.

                        <div class="m-3">
                          Within 5 seconds of shutting the water off you should
                          see new events appear here.
                        </div>
                      </div>
                      <div v-else>
                        No alarms found classified as
                        <b>{{ source.$displayString }}</b>
                        <span v-if="source.$room">
                          in <b>{{ source.$room.$displayString }}</b></span
                        >.
                      </div>
                    </b-message>
                  </fade-transition>
                </div>
              </transition>
            </div>
          </transition>
        </div>
      </div>
      <div class="columns is-multiline mt-3" v-if="!loadingSources">
        <div class="column is-12-mobile is-12-tablet is-half-desktop">
          <b-button
            :type="prevSource ? 'is-outlined' : 'is-primary is-outlined'"
            icon-left="chevron-left"
            class="is-fullwidth"
            @click="prevSourceClicked()"
          >
            <div v-if="prevSource">
              <source-display
                classes="is-inline-block"
                :value="prevSource"
                show-temp-text
              />
              <span v-if="prevSource && prevSource.$room">
                in {{ prevSource.$room.$displayString }}</span
              >
            </div>
            <span v-else>Return to POUs</span>
          </b-button>
        </div>

        <div class="column is-12-mobile is-12-tablet is-half-desktop">
          <b-button
            :type="nextSource ? 'is-outlined' : 'is-primary'"
            icon-right="chevron-right"
            class="is-fullwidth"
            @click="nextSourceClicked()"
          >
            <div v-if="nextSource">
              <source-display
                classes="is-inline-block"
                :value="nextSource"
                show-temp-text
              />
              <span v-if="nextSource && nextSource.$room">
                in {{ nextSource.$room.$displayString }}</span
              >
            </div>
            <span v-else> Review training quality </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSourceAlarm from "../../components/Alarms/SelectSourceAlarm";
import UserMessageDetail from "../../components/UserMessage/UserMessageDetail";
import { firestore as db } from "../../firebase";
import { clone, get, groupBy, isEqual, max, reduce, sum } from "lodash";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import { mergeById } from "../../store/shared";

import GroundTruthTable from "../../tables/GroundTruthTable";
import FadeTransition from "../../transitions/FadeTransition";
import trackWindowSize from "../../mixins/trackWindowSize";

export default {
  name: "TrainPointOfUse",
  mixins: [trackWindowSize],
  components: {
    FadeTransition,
    GroundTruthTable,
    SelectSourceAlarm,
    UserMessageDetail,
  },
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
    loadingPositions: {
      type: Boolean,
      default: false,
    },
    allSources: {
      type: Array,
      default: () => [],
    },
    sourceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newSourceToggled: false,
      loading: {
        ground_truth: false,
        alarm: false,
      },
      fs_alarms: [],
      timeWarning: null,
      ground_truth: [],
      checkedRows: [],
      limitOptions: [10, 25, 50, 100],
    };
  },
  mounted() {
    // TODO: change if includeClassified
    if (!this.start) {
      this.start = new Date();
    }
  },
  computed: {
    _allSources() {
      return this.allSources;
    },
    currentSourceIndex() {
      let sources = this.$route.query.sources
        ? this.$route.query.sources.map((srcId) =>
            getObjectInArrayById(this._allSources, srcId)
          )
        : this._allSources;
      return sources.findIndex(
        (item) => this.source && item.id === this.source.id
      );
    },
    prevSource() {
      let sources = this.$route.query.sources
        ? this.$route.query.sources.map((srcId) =>
            getObjectInArrayById(this._allSources, srcId)
          )
        : this._allSources;
      return sources[this.currentSourceIndex - 1];
    },
    nextSource() {
      let sources = this.$route.query.sources
        ? this.$route.query.sources.map((srcId) =>
            getObjectInArrayById(this._allSources, srcId)
          )
        : this._allSources;
      return sources[this.currentSourceIndex + 1];
    },
    loadingSources() {
      return this.$store.state.loading.sources;
    },
    viewAsTable: {
      get() {
        return this.$route.query.hasOwnProperty("sensorTableView");
      },
      set(val) {
        val
          ? this.queryReplace({ sensorTableView: "" })
          : this.queryRemoveKeys(["sensorTableView"]);
      },
    },
    includeClassified: {
      get() {
        return this.$route.query.hasOwnProperty("classified");
      },
      set(val) {
        val
          ? this.queryReplace({ classified: "" })
          : this.queryRemoveKeys(["classified"]);
      },
    },
    tab: {
      get() {
        return this.$route.query.tab || "pou";
      },
      set(tab) {
        this.queryReplace({ tab });
      },
    },
    start: {
      get() {
        return this.$route.query.start
          ? new Date(this.$route.query.start)
          : new Date();
      },
      /**
       * @param {Date} start
       */
      set(start) {
        this.queryReplace({ start: start.toISOString() });
      },
    },
    groundTruthPerPositionId() {
      return groupBy(this.ground_truth, "information.position.id");
    },
    groundTruthDurationPerPosition() {
      let durationPerPositionId = {};
      for (const positionId in this.groundTruthPerPositionId) {
        let listOfGT = this.groundTruthPerPositionId[positionId] || [];
        let durations = listOfGT.map((gt) => gt.information.duration_seconds);
        durationPerPositionId[positionId] = sum(durations);
      }
      return durationPerPositionId;
    },
    groundTruthDuration() {
      return max(Object.values(this.groundTruthDurationPerPosition));
    },
    /**
     *
     * @returns {{area: (firebase.firestore.DocumentReference|firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*|null), uid: String, start: Date, limit: Number, source: (firebase.firestore.DocumentReference|firebase.firestore.DocumentReference<firebase.firestore.DocumentData>|*|null)}}
     */
    options() {
      return {
        start: this.start,
        source: this.source ? this.source.$FSRef : null,
        area:
          this.source && this.source.$area ? this.source.$area.$FSRef : null,
        includeClassified: this.includeClassified,
        uid: this.$store.state.user.uid,
        limit: this.limit,
      };
    },
    /**
     *
     * @returns {Source|null}
     */
    source() {
      return this.sourceId
        ? getObjectInArrayById(this.$store.state.sources, this.sourceId)
        : null;
    },
    alarms() {
      return this.fs_alarms
        ? this.fs_alarms.map((m) => new this.$dataClasses.Alarm(m))
        : [];
    },
    limit: {
      get() {
        return this.$route.query.limit || this.limitOptions[0];
      },
      set(limit) {
        this.queryReplace({ limit: limit || this.limitOptions[0] });
      },
    },
  },
  methods: {
    prevSourceClicked() {
      this.$router.push({
        name: this.prevSource ? "training:by-source" : "training:overview",
        params: {
          areaId: this.$route.params.areaId,
          sourceId: this.prevSource ? this.prevSource.id : null,
        },
        query: this.$route.query,
      });
    },
    nextSourceClicked() {
      this.nextSource
        ? this.$router.replace({
            params: { sourceId: this.nextSource.id },
            query: this.$route.query,
          })
        : this.$emit("rebuild-model-clicked");
    },
    /**
     *
     * @param alarm {Alarm}
     */
    viewDetail(alarm) {
      alarm
        ? this.queryPush({ detail: alarm.id })
        : this.queryRemoveKeys(["detail"]);
    },
    getAlarms() {
      this.loading.alarms = true;
      let query = db
        .collection(this.$dataClasses.Alarm.collectionName)
        .where("area", "==", this.options.area)
        .where("topic", "==", "source_selection");
      if (this.options.includeClassified) {
        query = query.where("classified_as.source", "==", this.source.$FSRef);
      } else {
        query = query
          .where("classified_as.source", "==", null)
          .where("classified_as.flowType", "==", null)
          .where("_about.last_updated_at", ">=", this.options.start);
      }
      query = query
        .orderBy("_about.last_updated_at", "desc")
        .limit(this.options.limit);
      this.$bind("fs_alarms", query).then(() => {
        this.loading.alarms = false;
      });
    },
    getGroundTruth(reset = false, event = null) {
      if (reset || !this.source) {
        this.ground_truth = [];
      }
      if (this.source) {
        let queries = [["information.source", "==", this.source.$FSRef]];
        if (event) {
          queries.push(["information.alarm_id", "==", event.alarm.id]);
        } else {
          this.ground_truth = [];
          this.loading.ground_truth = true;
        }
        const urlParams = {
          order_field: "information.post_dt",
          order_direction: "desc",
          paginate_by: 100,
        };
        this.$dataClasses.GroundTruth.query(queries, urlParams)
          .then((results) => {
            this.ground_truth = mergeById(this.ground_truth)
              .with(results)
              .filter(
                (gt) =>
                  this.toId(get(gt, "information.source")) === this.sourceId
              );
          })
          .catch((e) => this.$handleError(e))
          .finally(() => (this.loading.ground_truth = false));
      }
    },
    openDeleteDialog() {
      let self = this;
      self.$buefy.dialog.confirm({
        title: "Delete ground truth?",
        type: "is-danger",
        message: `Are you sure you want to delete ${self.checkedRows.length} piece(s) of ground truth?`,
        onConfirm() {
          self.editingSource = false;
          self.loading.ground_truth = true;
          this.$dataClasses.GroundTruth.bulkDelete(
            self.checkedRows.map((r) => r.id)
          )
            .then((res) => {
              this.$handleSuccess(res.message);
              let deletedIds = res.results.map((r) => r.id);
              self.ground_truth = self.ground_truth.filter(
                (gt) => !deletedIds.includes(gt.id)
              );
              self.checkedRows = [];
            })
            .catch((e) =>
              this.$handleError(
                "Could not delete ground truth; See console for details",
                e
              )
            )
            .finally(() => {
              self.loading.ground_truth = false;
            });
        },
      });
    },
    timeSelected(datetime) {
      let now = new Date();
      this.start = datetime;
      if (datetime > now) {
        this.timeWarning = `${datetime.toLocaleString()} is in the future!`;
      } else {
        this.timeWarning = null;
      }
    },
  },
  watch: {
    "options.includeClassified": {
      handler() {
        this.viewDetail(null);
      },
    },
    start: {
      handler(val) {
        this.timeSelected(val);
      },
    },
    options: {
      immediate: true,
      handler(val, prevVal) {
        let _val = clone(val),
          _prevVal = clone(prevVal);
        [_val, _prevVal].forEach((item) => {
          if (item && item.area) {
            item.area = this.toId(item.area);
          }
          if (item && item.source) {
            item.source = this.toId(item.source);
          }
        });
        if (!prevVal) {
          this.getAlarms();
          return;
        }
        let differences = reduce(
          _val,
          function (result, value, key) {
            return isEqual(value, _prevVal[key]) ? result : result.concat(key);
          },
          []
        );
        if (
          get(_val, "includeClassified", false) ||
          get(_val, "includeClassified", false) !==
            get(_prevVal, "includeClassified", false) ||
          (differences &&
            differences.length &&
            !(differences.length === 1 && differences[0] === "source"))
        ) {
          this.getAlarms();
        }
      },
    },
    source: {
      immediate: true,
      handler(val) {
        this.newSourceToggled = true;
        this.getGroundTruth(true);
        setTimeout(
          () => (this.newSourceToggled = val && this.sourceId !== val.id),
          1000
        );
      },
    },
  },
};
</script>

<style scoped>
@-webkit-keyframes burst {
  0% {
    opacity: 0.6;
  }

  50% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes burst {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.6;
  }

  50% {
    -webkit-transform: scale(1.8);
    -ms-transform: scale(1.8);
    transform: scale(1.8);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.faa-burst.animated,
.faa-burst.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-burst {
  -webkit-animation: burst 3s infinite linear;
  animation: burst 3s infinite linear;
}
</style>
