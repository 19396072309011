<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Developer API Tokens</div>
      <div class="card-header-icon">
        <b-icon :icon="iconFor('token')" />
      </div>
    </div>
    <div class="card-content">
      <loading-card v-if="!firstLoadComplete" />
      <transition name="fade">
        <div v-show="$store.state.user_account && firstLoadComplete">
          <b-loading :active="loading"></b-loading>
          <div v-if="$store.state.user_roles.is_admin">
            <b-table
              :data="tokens"
              v-if="tokens.length"
              :default-sort="['expires']"
              default-sort-direction="desc"
            >
              <b-table-column field="id" label="ID" v-slot="props">
                {{ props.row.id.slice(0, 8) }}-...
              </b-table-column>
              <b-table-column
                field="expires"
                label="Expiration"
                v-slot="props"
                sortable
              >
                {{
                  props.row.expires ? props.row.expires.toUTCString() : "Never"
                }}
              </b-table-column>
              <b-table-column v-slot="props" label="Roles">
                <role-tags :roles-array="props.row.roles" />
              </b-table-column>
              <b-table-column numeric v-slot="props">
                <b-tooltip
                  :active="isExpired(props.row)"
                  position="is-left"
                  type="is-warning"
                  label="This token has expired"
                >
                  <b-button
                    :disabled="isExpired(props.row)"
                    @click="revokeConfirmationDialog(props.row)"
                    size="is-small"
                    :type="isExpired(props.row) ? 'is-default' : 'is-danger'"
                  >
                    <b-icon icon="times" />
                  </b-button>
                </b-tooltip>
              </b-table-column>
            </b-table>
            <p class="has-text-centered big-pad" v-else>
              You have no active API tokens.
            </p>
            <input type="hidden" id="developer-token-confirmed" />

            <add-developer-token-modal
              :active.sync="modalActive"
              @updated="
                modalActive = false;
                getTokens();
              "
            />
          </div>
          <p v-else>
            Please contact your account administrator if you'd like to request
            an API token.
          </p>
        </div>
      </transition>
    </div>
    <div class="card-footer">
      <div class="card-footer-item">
        <b-button
          @click="$router.push({ name: 'settings' })"
          expanded
          icon-left="chevron-left"
        >
          Settings
        </b-button>
      </div>
      <div class="card-footer-item">
        <b-button
          expanded
          type="is-primary is-pulled-right"
          @click="modalActive = true"
          :icon-left="iconFor('create')"
        >
          Add new token
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import iconFor from "../../../icons";
import LoadingCard from "../../../components/Skeletons/LoadingCard";
import RoleTags from "../../../components/Roles/RoleTags";
import AddDeveloperTokenModal from "../../../applications/Settings/Sections/AddDeveloperTokenModal";

export default {
  name: "DeveloperTokens",
  props: ["user"],
  components: {
    AddDeveloperTokenModal,
    LoadingCard,
    RoleTags,
  },
  data() {
    return {
      modalActive: false,
      loading: true,
      firstLoadComplete: false,
      tokens: [],
    };
  },
  computed: {
    authUser() {
      return this.$store.state.user;
    },
  },
  methods: {
    iconFor,
    isExpired(token) {
      return token.expires && token.expires < Date.now();
    },
    revokeConfirmationDialog(token) {
      let self = this;
      this.$buefy.dialog.prompt({
        message: `
                        <p>
                            Please confirm you'd like to revoke this token by entering the first 8 characters of
                            the key in the box below. This will <b>immediately</b> disable the token.
                        </p>
                        <p>
                            <b>API key:</b> ${token.id.slice(0, 8)}-...
                        </p>
                    `,
        inputAttrs: {
          type: "text",
          placeholder: token.id.slice(0, 8),
          value: "",
          length: 8,
        },
        confirmText: "Confirm",
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, { close }) => {
          if (value.length === 8 && token.id.startsWith(value)) {
            self.revokeToken(token.id, close);
          } else {
            self.$handleError(
              `Please enter '${token.id.slice(
                0,
                8
              )}' in the box to confirm that you'd like to revoke this token`,
              null
            );
          }
        },
      });
    },
    revokeToken(tokenId, dialogCloseEvent) {
      let self = this;
      this.$openToast({ message: `Revoking token` });
      self.loading = true;

      this.$dataClasses.DeveloperToken.revoke(tokenId)
        .then((response) => {
          self.getTokens();
          if (response.success) {
            dialogCloseEvent();
          }
        })
        .catch((e) => {
          self.$handleError("Could not revoke token: " + e, e);
          self.loading = false;
        });
    },
    getTokens() {
      let self = this;
      self.loading = true;
      this.$dataClasses.DeveloperToken.query([
        ["requestor_user_id", "==", self.$store.state.user.uid],
        ["expires", ">=", new Date()],
      ])
        .then((results) => (self.tokens = results))
        .catch((e) => {
          self.$handleError("Error receiving response: " + e, e);
        })
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    this.getTokens();
  },
  watch: {
    loading: {
      handler(isLoading, wasLoading) {
        if (wasLoading && !isLoading) {
          this.firstLoadComplete = true;
        }
      },
    },
  },
};
</script>

<style scoped>
.big-pad {
  padding: 5vh 5vw;
}
</style>
