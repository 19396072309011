<template>
  <div>
    <option-driven-radio
        new-lines
        :options="validOptions.map(building => ({value: building,label: building.name}))"
        :value="value"
        @input="$emit('input', $event)"
    >
      <template #default="option">
        <building-display :value="option.value"/>
      </template>
    </option-driven-radio>

    <div class="option-radio" v-for="invOption in invalidOptions" :key="invOption.id">
      <b-tooltip label="Can not install sensor in an area that has no gateways">
        <b-radio :value="null" disabled>
          <building-display :value="invOption"/>
        </b-radio>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import OptionDrivenRadio from "./OptionDrivenRadio";
import {sortBy} from "lodash";

export default {
  name: "BuildingRadio",
  components: {OptionDrivenRadio},
  props: {
    filterFunc: {
      type: Function,
      default: (building) => true,
    },
    value: {
      type: Object,
    },
  },
  computed: {
    invalidOptions() {
      return sortBy(this.$store.state.buildings.filter(opt => !this.validOptions.includes(opt)), 'name');
    },
    validOptions() {
      let buildings = this.$store.state.buildings.filter(this.filterFunc);
      return sortBy(buildings, "name")
    },
  },
};
</script>

<style scoped></style>
