<template>
  <div class="column">
    <div class="columns">
      <div
        :class="{
          'doink box column is-12 is-5-desktop is-4-fullhd has-border-success': true,
          'is-hidden-mobile is-hidden-tablet-only': selectedAlarmId,
        }"
      >
        <div>
          <div class="has-text-centered subtitle m-3">
            {{ title || `Alarms: ${capitalizeFirstLetter(category)}` }}
          </div>
          <div class="has-text-centered">
            <b-icon
              icon="sync"
              :class="{ 'fa-spin': refreshing, 'pointer mb-3': true }"
              size="is-small"
              @click.native="refreshExisting"
            />
          </div>
          <div class="scroller" id="scroller-messages">
            <b-dropdown
              aria-role="list"
              v-if="selected.length"
              class="mb-2"
              expanded
            >
              <template #trigger="{ active }">
                <b-button
                  expanded
                  :label="
                    selected.length +
                    ' message' +
                    (selected.length === 1 ? '' : 's') +
                    ' selected'
                  "
                  type="is-primary"
                  :icon-right="active ? 'chevron-up' : 'chevron-down'"
                />
              </template>
              <b-dropdown-item aria-role="listitem" @click="dismissSelected">
                Dismiss selected
              </b-dropdown-item>
            </b-dropdown>
            <transition-group name="message" tag="p">
              <div v-for="alarm in alarms" :key="alarm.id">
                <user-message-box
                  :alarm-object="alarm"
                  :ref="'alarm-' + alarm.id"
                  :id="'alarm-' + alarm.id"
                  :selected="selected.includes(alarm.id)"
                  @click="selectAlarm(alarm)"
                />
              </div>
            </transition-group>
            <loading-card
              v-if="loading && (!alarms || !alarms.length)"
              :sections="['body', 'footer']"
            />
            <p
              class="has-text-centered mt-6"
              v-if="!loading && !refreshing && (!alarms || !alarms.length)"
            >
              No alarms found.
            </p>
          </div>
          <div class="has-text-centered">
            <b-button
              type="is-primary"
              outlined
              :loading="loading"
              @click="fetchAlarms(() => {})"
              :disabled="!alarms.length"
            >
              Load more
            </b-button>
          </div>
        </div>
      </div>
      <div class="column is-7-desktop is-8-fullhd">
        <user-message-detail-inner
          v-if="selectedAlarm"
          :alarm-object="selectedAlarm"
          :on-close-function="
            () => {
              selectedAlarmId = null;
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy, uniqBy } from "lodash";
import iconFor from "../../icons";
import UserMessageDetailInner from "../../components/UserMessage/UserMessageDetailInner";
import UserMessageBox from "../../components/UserMessage/UserMessageBox";
import WindowSizeMixin from "../../mixins/trackWindowSize";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import IntervalFunctionMixin from "../../mixins/intervalFunctionMixin";
import LoadingCard from "../../components/Skeletons/LoadingCard";

export default {
  name: "AlarmHistory",
  components: { LoadingCard, UserMessageDetailInner, UserMessageBox },
  mixins: [IntervalFunctionMixin, WindowSizeMixin],
  props: {
    category: {
      type: String,
      default: null,
    },
    millisecondsPerPoll: {
      type: Number,
      default: 1000 * 30,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tab: 0,
      selected: [],
      dismissedPage: 1,
      filters: [],
      alarms: [],
      loadedAlarm: null,
      loading: false,
      refreshing: false,
    };
  },
  mounted() {
    this.fetchAlarms();
  },
  computed: {
    selectedAlarmId: {
      get: function () {
        return this.$route.query.detail;
      },
      set: function (val) {
        this.queryPush({ detail: val });
      },
    },
    alarmFromArray() {
      return getObjectInArrayById(this.alarms, this.selectedAlarmId, null);
    },
    selectedAlarm() {
      if (!this.selectedAlarmId) {
        return null;
      }
      return this.alarmFromArray || this.loadedAlarm;
    },
    oldestAlarmLastUpdateDt() {
      if (!this.alarms || !this.alarms.length) {
        return new Date();
      }
      let oldestAlarm = this.alarms.reduce(function (prev, curr) {
        return prev.last_update_dt < curr.last_update_dt ? prev : curr;
      });
      return oldestAlarm.last_update_dt;
    },
    newestAlarmLastUpdateDt() {
      if (!this.alarms || !this.alarms.length) {
        return new Date();
      }
      let newestAlarm = this.alarms.reduce(function (prev, curr) {
        return prev.last_update_dt > curr.last_update_dt ? prev : curr;
      });
      return newestAlarm.last_update_dt;
    },
  },
  methods: {
    onPoll() {
      this.refreshing = true;
      this.fetchAlarms(() => {
        this.refreshing = false;
      }, true);
    },
    refreshExisting() {
      this.refreshing = true;
      this.alarms = [];
      this.fetchAlarms(() => {
        this.refreshing = false;
      });
    },
    fetchAlarms(finallyCallable, searchForNew) {
      // every time called
      let self = this,
        queries = self.category
          ? [["categories", "array_contains", self.category]]
          : [];
      queries.push(["site", "==", self.toFSRef(self.$store.getters.site)]);
      if (searchForNew) {
        queries.push(["last_update_dt", ">", this.newestAlarmLastUpdateDt]);
      } else {
        queries.push(["last_update_dt", "<", this.oldestAlarmLastUpdateDt]);
      }
      self.loading = true;
      this.$dataClasses.Alarm.query(queries, {
        order_field: "last_update_dt",
        direction: "desc",
        paginate_by: 10,
      })
        .then((results) => {
          if (!results.length && !searchForNew) {
            self.$handleSuccess("End of results reached");
          }
          self.alarms = orderBy(
            uniqBy(self.alarms.concat(results), "id"),
            ["last_update_dt"],
            ["desc"]
          );
        })
        .catch((e) =>
          self.$handleError(
            "Could not fetch alarms; See console for more information",
            e
          )
        )
        .finally(() => {
          self.loading = false;
          if (finallyCallable) {
            finallyCallable();
          }
        });
    },
    iconFor,
    selectAlarm(alarm) {
      this.scrollToMessage(alarm);
      this.selectedAlarmId = alarm.id;
    },
    scrollToMessage(alarm, force) {
      if (force || this.isDesktop) {
        document.getElementById("alarm-" + alarm.id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    selectedAlarmId: {
      immediate: true,
      handler(val) {
        this.loadedAlarm = null;
        if (val && !this.alarmFromArray) {
          this.$dataClasses.Alarm
              .fetchById(val)
              .then(
                  (result) => {this.loadedAlarm = result}
              );
        }
      },
    },
    "$store.state.sites": {
      handler(val) {
        if (val) {
          this.alarms = [];
          setTimeout(this.fetchAlarms, 500);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.dismissed-message-enter-active,
.dismissed-message-leave-active,
.message-enter-active,
.message-leave-active {
  transition: opacity 0.4s ease-out;
  border: 0;
  border-radius: 0;
}

.dismissed-message-enter, .dismissed-message-leave-to,
.message-enter, .message-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-30px);
  border: 0;
  border-radius: 0;
}

.content .has-text-centered {
  margin: 20vh 2.5vw;
}

.message-outer {
  border: 2px solid transparent;
  border-radius: 7px;
}

.scroller {
  height: calc(100vh - 227px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tab-item-outer {
  :deep(.tab-content) {
    border: 1px solid lightgrey;
    background: white;
  }
}

.doink {
  height: fit-content;
}

:deep(.tabs ul li) {
  margin-left: 0;
}
</style>
