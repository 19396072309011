<template>
  <b-message type="is-source_selection has-border-success is-calibration-inner">
    <div class="has-text-weight-bold" v-if="!buildings || !buildings.length">
      Area not found
    </div>
    <div v-else>
      <p class="has-text-weight-bold">Please classify this event:</p>
      <b-field>
        <b-radio
          v-model="selectedFlowType"
          :disabled="loading"
          :class="{ bold: selectedFlowType === 'noflow' }"
          native-value="noflow"
        >
          This is not an actual flow event
        </b-radio>
      </b-field>
      <b-field>
        <b-radio
          v-model="selectedFlowType"
          :disabled="loading"
          :class="{ bold: selectedFlowType === 'unknown' }"
          native-value="unknown"
        >
          I am not sure whether this event is flow
        </b-radio>
      </b-field>
      <b-field>
        <b-radio
          :disabled="loading"
          :class="{ bold: selectedFlowType === 'flow' }"
          v-model="selectedFlowType"
          native-value="flow"
        >
          I am sure this is flow and from the following point-of-use:
        </b-radio>
      </b-field>
      <div class="field source-selection-field">
        <div v-if="selectableSources.length">
          <div v-for="building in sortedBuildings" :key="building.id">
            <building-display :value="building" />
            <b-radio
              class="source-select ml-5"
              :disabled="loading"
              v-for="src in buildingSources(building)"
              :key="src.id"
              v-model="selectedSourceId"
              :native-value="src.id"
            >
              <source-display :value="src" show-training-level />
            </b-radio>
            <div
              v-for="room in sortedRooms.filter(
                (rm) => rm.building.id === building.id && roomSources(rm).length
              )"
              :key="room.id"
              class="mt-3 ml-5"
            >
              <room-display :value="room" show-floor show-unit />
              <b-radio
                class="source-select ml-5"
                v-for="src in roomSources(room)"
                :key="src.id"
                :disabled="loading"
                v-model="selectedSourceId"
                :native-value="src.id"
              >
                <source-display :value="src" show-training-level />
              </b-radio>
            </div>
          </div>
          <div v-for="src in standaloneSources()" :key="src.id">
            <b-radio
              class="source-select"
              v-model="selectedSourceId"
              :native-value="src.id"
              :disabled="loading"
            >
              <source-display :value="src" show-training-level />
            </b-radio>
          </div>
        </div>
        <div v-else>
          <p>There are no current sources</p>
        </div>
        <b-button
          type="is-link"
          class="mt-5"
          @click="modalOpen = true"
          :disabled="loading"
          >Add new point-of-use
        </b-button>
        <add-source-modal
          v-model="modalOpen"
          :parent="area"
          :building="buildings ? buildings[0] : null"
          :building-not-editable="!!area.building"
          :id="null"
          @saved="selectedSourceId = $event.id"
        ></add-source-modal>
      </div>
    </div>
  </b-message>
</template>

<script>
import { get, sortBy } from "lodash";
import AddSourceModal from "../../components/Admin/Sources/AddSourceModal";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import iconFor from "../../icons";

const dontShowSourceNames = [
  "src:flow",
  "src:noflow",
  "src:anomaly",
  "anomaly",
  "flow",
  "noflow",
];

export default {
  name: "CalibrationSelectSource",
  components: {
    AddSourceModal,
  },
  data() {
    let selectedSouceId = null;
    if (
      this.alarmObject &&
      this.alarmObject.classified_as &&
      this.alarmObject.classified_as.source
    ) {
      selectedSouceId = this.toId(this.alarmObject.classified_as.source);
    }
    return {
      selectedFlowType: this.flowType,
      selectedSourceId: selectedSouceId,
      modalOpen: false,
    };
  },
  computed: {
    buildings() {
      let areaId = this.toId(this.alarmObject.area),
        area = areaId
          ? getObjectInArrayById(this.$store.state.areas, areaId, null)
          : null,
        building = area
          ? getObjectInArrayById(this.$store.state.buildings, area.building)
          : null;
      return building ? [building] : null;
    },
    rooms() {
      return this.$store.state.rooms.filter((r) => r.site.id === this.site.id);
    },
    sortedBuildings() {
      return sortBy(this.buildings, ["name"]);
    },
    sortedRooms() {
      return sortBy(this.rooms, ["floor", "unit", "name"]);
    },
    area() {
      return this.areaId
        ? getObjectInArrayById(this.$store.state.areas, this.areaId, null)
        : null;
    },
    site() {
      return this.area && get(this.area, "site", null)
        ? getObjectInArrayById(
            this.$store.state.sites,
            this.area.parent,
            null
          )
        : null;
    },
    selectableSources() {
      // return [];
      return this.sources
        .filter((src) => {
          return (
            src.name && !dontShowSourceNames.includes(src.name.toLowerCase())
          );
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  methods: {
    getObjectInArrayById,
    iconFor,
    buildingSources(building) {
      let self = this;
      return self.selectableSources.filter(
        (src) =>
          src.building && !src.room && self.toId(src.building) === building.id
      );
    },
    roomSources(room) {
      let self = this;
      return self.selectableSources.filter(
        (src) => src.room && self.toId(src.room) === room.id
      );
    },
    standaloneSources() {
      let self = this;
      return self.selectableSources.filter((src) => !src.room && !src.building);
    },
  },
  props: {
    alarmObject: {
      type: Object,
      required: true,
    },
    areaId: {
      type: String,
    },
    sources: {
      type: Array,
      default: () => {
        return [];
      },
    },
    flowType: {
      type: String,
      default: null,
    },
    sourceId: {
      type: String,
      default: null,
    },
    allowCreation: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  watch: {
    alarmObject: {
      handler(val) {
        let selectedSourceId = get(val, "classified_as.source", null);
        this.selectedFlowType = get(val, "classified_as.flowType", null);
        this.selectedSourceId = selectedSourceId
          ? this.toId(selectedSourceId)
          : null;
      },
    },
    selectedFlowType: {
      handler(val) {
        if (val !== "flow" && this.selectedSourceId) {
          this.selectedSourceId = null;
        }
        this.$emit("update:flow-type", this.toId(val));
      },
    },
    selectedSourceId: {
      handler(val, oldVal) {
        if (val) {
          this.selectedFlowType = "flow";
        }
        this.$emit("update:source-id", val);
      },
    },
    sourceId: {
      handler(val) {
        this.selectedSourceId = val ? this.toId(val) : null;
      },
    },
  },
};
</script>

<style scoped>
.source-selection-field {
  padding-left: 50px;
}

.source-select {
  display: flex !important;
  padding-top: 10px;
}
</style>
