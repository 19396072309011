import store from "../../../store";
import { get, sortBy } from "lodash";
import { compareFieldFunc } from "../../../dataClasses/functions";

export default function hierarchyToTree({
  _sites = null,
  _buildings = null,
  arrayToFilter = null,
  filterFunction = () => true,
}) {
  let sites = _sites || store.state.sites,
    buildingsArg = _buildings || store.state.buildings || [];
  arrayToFilter = arrayToFilter || store.state.sources;
  let objects = arrayToFilter
      .filter(filterFunction)
      .sort(compareFieldFunc("name"))
      .reverse(),
    roomIds = objects
      .filter((src) => src && src.room)
      .map((src) => src.room.id),
    rooms = store.state.rooms.filter((rm) => roomIds.includes(rm.id)) || [],
    buildingIds = objects
      .filter((src) => src && src.building)
      .map((src) => src.building.id)
      .concat(
        rooms.filter((rm) => rm && rm.building).map((rm) => rm.building.id)
      ),
    buildings = buildingsArg.filter((b) => buildingIds.includes(b.id)),
    buildingStructure = {
      name: "Area",
      val: buildings.sort(compareFieldFunc("name")).reverse(),
      parentNames: ["site"],
      children: {
        name: "Room",
        val: sortBy(rooms, ["floor", "unit", "name"]),
        parentNames: ["building", "site"],
      },
    },
    structure =
      _sites && _sites.length
        ? {
            name: "Site",
            val: sites,
            parentNames: [],
            children: buildingStructure,
          }
        : buildingStructure;

  /**
   *
   * @param struct({Object})
   * @param parentId({String})
   */
  function recurse(struct, parentId) {
    let level = [],
      objs = parentId
        ? struct.val.filter((v) => v.parent && v.parent.id === parentId)
        : struct.val;
    for (const obj of objs) {
      let entry = {
          id: obj.id,
          label: `${struct.name}: ${obj.name}`,
          refPath: `${struct.name.toLowerCase()}s/${obj.id}`,
          isA: struct.name ? struct.name.toLowerCase() : null,
        },
        children = struct.children ? recurse(struct.children, obj.id) : [];

      let childSources = objects
        .filter((src) => {
          if (struct.name === "Site") {
            return (
              src.site && !src.building && !src.room && src.site.id === obj.id
            );
          } else if (struct.name === "Building") {
            return src.building && !src.room && src.building.id === obj.id;
          } else if (struct.name === "Room") {
            return src.room && src.room.id === obj.id;
          }
        })
        .sort(compareFieldFunc("name"))
        .reverse()
        .map((src) => {
          return {
            id: src.id,
            label: `${src.constructor.defaultDisplayString}: ${src.name}`,
            refPath: `${src._reference.path}`,
            isA: src.constructor.collectionName.slice(0, -1),
          };
        });
      children = children.concat(childSources);

      if (children && children.length > 0) {
        entry.children = children;
      }
      level.push(entry);
    }
    return level;
  }

  return recurse(structure);
}

export function getSourcesFromHierarchyResult(hierResults) {
  let resultIDs = hierResults.map((res) => res.id);
  return store.state.sources.filter((src) => {
    let idValuesMatch = [
      resultIDs.includes(src.id),
      resultIDs.includes(get(src, `site.id`, "NO")),
      resultIDs.includes(get(src, `area.id`, "NO")),
      resultIDs.includes(get(src, `building.id`, "NO")),
      resultIDs.includes(get(src, `room.id`, "NO")),
    ];
    return idValuesMatch.some((r) => r === true);
  });
}
