import Gateway from "../dataClasses/Gateway";
import Probe from "../dataClasses/Probe";
import Sensor from "../dataClasses/Sensor";

/**
 * Object of data extracted from device QR code
 * @typedef {{protocol: string, authCode: string, serial: string, deviceType: ('gateway'|'probe'|'sensor')}} DeviceQRCodeData
 */
export const deviceCodesToDeviceTypes = {
  G: "gateway",
  P: "probe",
  S: "sensor",
  T: "probe",
};
export const deviceTypesToClasses = {
  gateway: Gateway,
  probe: Probe,
  sensor: Sensor,
};
export const deviceTypes = Array.from(Object.values(deviceCodesToDeviceTypes));
export const SERIAL_REGEX = /^[2][0-9][.][GSTP][A-Z0-9]{4}$/;

export function isValidSerial(serial) {
  return serial && serial.match(SERIAL_REGEX);
}

export function getDeviceTypeFromSerial(serial) {
  if (!isValidSerial(serial)) {
    throw `Malformed device serial number: '${serial}'`;
  }
  const deviceChar = serial.split(".")[1][0],
    deviceType = deviceCodesToDeviceTypes[deviceChar];
  if (!deviceType) {
    throw `Malformed device serial number: '${serial}'`;
  }
  return deviceType;
}

export function getPositionCollectionFromSerial(serial) {
  let dType = getDeviceTypeFromSerial(serial);
  return {
    gateway: "gateway_positions",
    sensor: "positions",
  }[dType];
}

export function getPLFieldNameFromSerial(serial) {
  let dType = getDeviceTypeFromSerial(serial);
  return {
    sensor: "position",
    probe: "probe",
  }[dType];
}

/**
 *
 * @param deviceType
 * @returns {Gateway|Sensor|Probe}
 * @constructor
 */
export function getDeviceClassFromDeviceType(deviceType) {
  return deviceTypesToClasses[deviceType];
}

export function getDeviceClassFromSerial(serial) {
  const deviceType = getDeviceTypeFromSerial(serial);
  return getDeviceClassFromDeviceType(deviceType);
}

/**
 *
 * @param serial
 * @return {Promise<(Gateway|Sensor|Probe)|Error>}
 */
export function getDeviceInstanceFromSerial(serial) {
  return getDeviceClassFromSerial(serial).queryForSingle([
    ["serial", "==", serial],
  ]);
}

/**
 *
 * @param $qrCodeUrl {string}: The url from a device QR code
 * @return {DeviceQRCodeData}: Extracted data as object
 */
export default function extractDeviceInfoFromQRCode($qrCodeUrl) {
  if (!$qrCodeUrl.includes("?")) {
    throw new Error("Not a device QR code");
  }
  let params = new URLSearchParams($qrCodeUrl.split("?")[1]),
    entries = Array.from(params.entries());
  if (!entries || !entries.length) {
    throw new Error("Not a device QR code");
  }
  let protocol = entries[0][0],
    serial,
    deviceType,
    authCode;
  if (protocol === "A") {
    serial = entries[0][1].split("~")[0];
    authCode = entries[0][1].split("~")[1];
    deviceType = getDeviceTypeFromSerial(serial);
  } else {
    throw new Error(`Unexpected QR code protocol: '${protocol}'`);
  }
  let data = {
    authCode,
    deviceType,
    protocol,
    serial,
  };
  return data;
}
