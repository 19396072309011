<template>
  <div class="sensor-port-radio">
    <div :class="{ control: horizontal }" v-for="port in ports" :key="port">
      <b-radio
        :value="value"
        :disabled="!selectableOptions.includes(port)"
        type="is-primary"
        @input="$emit('input', $event)"
        :native-value="port"
      >
        <slot name="label" :port="port">
          {{ port > 0 ? "External" : "Internal" }} port {{ port }}
          <slot name="post-label" :port="port"></slot>
        </slot>
      </b-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: "SensorPortRadio",
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
    showInternal: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
    selectableOptions: {
      type: Array,
      validator(arr) {
        return Array.isArray(arr) && arr.every((v) => [0, 1, 2].includes(v));
      },
      default: () => {
        return [0, 1, 2];
      },
    },
  },
  computed: {
    ports() {
      if (this.showInternal) {
        return [0, 1, 2];
      }
      return [1, 2];
    },
  },
};
</script>

<style scoped></style>
