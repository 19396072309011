import Vue from "vue";
import CustomerHierarchySelectionFromList from "../components/Selects/CustomerHierarchySelectionFromList";
import BuildingDisplay from "../components/Display/BuildingDisplay";
import BundleDetails from "../components/Display/BundleDetails";
import DirectoryTile from "../components/Display/DirectoryTile";
import DirectoryTilesWrapper from "../components/Display/DirectoryTilesWrapper";
import EventHistory from "../components/Display/EventHistory";
import FileData from "../components/Display/FileData";
import GatewayDisplay from "../components/Display/GatewayDisplay";
import GatewayPositionDisplay from "../components/Display/GatewayPositionDisplay";
import GenericDisplay from "../components/Display/GenericDisplay";
import GroundTruthAmount from "../components/Display/GroundTruthAmount";
import HierarchyObjectsDisplay from "../components/Display/HierarchyObjectsDisplay";
import InTrainingMode from "../components/Display/tips/InTrainingMode";
import InstallLocation from "../components/Display/InstallLocation";
import LiveGatewayDisplay from "../components/Display/LiveGatewayDisplay";
import LiveSensorDisplay from "../components/Display/LiveSensorDisplay";
import PipeLocationDisplay from "../components/Display/PipeLocationDisplay";
import PositionDisplay from "../components/Display/PositionDisplay";
import ProbeDisplay from "../components/Display/ProbeDisplay";
import RoomDisplay from "../components/Display/RoomDisplay";
import SensorBattery from "../components/Display/SensorBattery";
import SensorDetailForClip from "../components/Display/SensorDetailForClip";
import SensorDisplay from "../components/Display/SensorDisplay";
import SensorSignalIndicator from "../components/Display/SensorSignalIndicator";
import SiteDevices from "../components/Display/SiteDevices";
import SiteDisplay from "../components/Display/SiteDisplay";
import SourceDisplay from "../components/Display/SourceDisplay";
import SpinnerWithCheckmark from "../components/Display/SpinnerWithCheckmark";
import UnitConversionDisplay from "../components/Display/UnitConversionDisplay";

export default function registerGlobalComponents() {
    Vue.component('customer-hierarchy-selection', CustomerHierarchySelectionFromList);
    Vue.component('building-display',BuildingDisplay );
    Vue.component('bundle-details', BundleDetails);
    Vue.component('directory-tile', DirectoryTile);
    Vue.component('directory-tiles-wrapper', DirectoryTilesWrapper);
    Vue.component('event-history', EventHistory);
    Vue.component('file-data', FileData);
    Vue.component('gateway-display', GatewayDisplay);
    Vue.component('gateway-position-display', GatewayPositionDisplay);
    Vue.component('generic-display', GenericDisplay);
    Vue.component('ground-truth-amount', GroundTruthAmount);
    Vue.component('hierarchy-objects-display', HierarchyObjectsDisplay);
    Vue.component('in-training-mode', InTrainingMode),
    Vue.component('install-location', InstallLocation);
    Vue.component('live-gateway-display', LiveGatewayDisplay);
    Vue.component('live-sensor-display', LiveSensorDisplay)
    Vue.component('pipe-location-display', PipeLocationDisplay);
    Vue.component('position-display', PositionDisplay);
    Vue.component('probe-display', ProbeDisplay);
    Vue.component('room-display', RoomDisplay);
    Vue.component('sensor-battery', SensorBattery);
    Vue.component('sensor-detail-for-clip', SensorDetailForClip);
    Vue.component('sensor-display',SensorDisplay );
    Vue.component('sensor-signal-indicator', SensorSignalIndicator);
    Vue.component('site-devices', SiteDevices);
    Vue.component('site-display', SiteDisplay);
    Vue.component('source-display', SourceDisplay);
    Vue.component('spinner-with-checkmark', SpinnerWithCheckmark);
    Vue.component('unit-conversion-display', UnitConversionDisplay);
}