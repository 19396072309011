var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gateway-detail-wrapper"},[_c('table',{staticClass:"table is-striped is-bordered is-fullwidth"},[_c('tbody',_vm._l((_vm.info),function(value,name){return _c('tr',{key:name},[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(name))]),(name === 'Bundle' && _vm.$store.state.user_roles.is_root)?_c('td',[(_vm.bundleLoading)?_c('b-icon',{staticClass:"fa-spin",attrs:{"icon":"spinner"}}):(_vm.bundle.id)?_c('router-link',{attrs:{"to":{
              name: 'administration:shipments:view',
              params: { id: _vm.bundle.id },
            }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('i',[_vm._v("No bundle found")])],1):(name === 'Shipped to')?_c('td',[(value)?_c('router-link',{attrs:{"to":{
              name: 'administration:view-subcustomer',
              params: { id: value.id },
            }}},[_vm._v(" "+_vm._s(value.$displayString)+" ")]):_c('i',[_vm._v("n/a")])],1):(_vm.gatewayPosition)?_c('td',{class:{
            'has-text-danger':
              name === 'Last seen' &&
              (_vm.gatewayPosition.$lastSeenClassification !== 'success' ||
                value === 'Unknown'),
          }},[(_vm.gatewayPosition && name === 'Last seen')?_c('span',[_c('b-icon',{class:_vm.gatewayPosition.$isHealthy ? '' : 'fa-throb',attrs:{"icon":"cloud-upload-alt","type":_vm.gatewayPosition.$isHealthy ? 'is-success' : 'is-danger'}})],1):_vm._e(),_vm._v(" "+_vm._s(value)+" ")]):_c('td',[_vm._v(" "+_vm._s(value)+" ")])])}),0)]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }