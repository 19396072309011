<template>
  <div>
    <div class="box" v-if="forced && loading">
      <b-loading v-if="forced && loading" active />
    </div>
    <div class="message-detail-wrapper">
      <b-message v-if="forced && (message || Object.keys(message).length)">
        <b-loading v-if="loading" :is-full-page="false" />
        <p v-else>
          No message data found. You may not have access to this resource.
        </p>
      </b-message>
      <b-message
        :v-if="message || alarm"
        :title="title"
        :type="'is-' + alarm.topic"
        :has-icon="windowWidth > 450"
        :icon="iconFor(alarm.topic)"
        :class="{
          'box has-shadow selected-message-wrapper': true,
          clicked: clicked,
        }"
        @close="onCloseFunction({ message, alarm })"
      >
        <div class="has-text-centered" v-if="loading || !$store.getters.loaded">
          <b-icon icon="sync" class="fa-spin" size="is-large" />
        </div>
        <transition name="slide">
          <div v-if="!loading && alarm">
            <div class="mb-2">
              <p
                class="has-text-centered message-is-alarm mb-5"
                v-if="alarm && alarm.alert_type"
              >
                <b-icon
                  class="has-text-centered"
                  :type="alarm.$isWarning ? 'is-danger' : 'is-primary'"
                  :icon="
                    alarm.$isWarning ? 'exclamation-triangle' : 'info-circle'
                  "
                  size="is-small"
                />
                <span :class="{ 'has-text-danger': alarm.$isWarning }">
                  {{ alarm.$isWarning ? "Warning" : "Info" }} notification
                </span>
              </p>
              <div class="message-location">
                <div>
                  <alarm-breadcrumb-controller
                    :alarm-object="alarm"
                    show-address
                    show-floors
                    show-links
                    show-title
                    is-detail
                  />
                </div>
                <div v-if="alarmSite && alarmSite.lat_long" class="address">
                  <div v-if="showMap">
                    <map-with-marker
                      :marker="{
                        lat: alarmSite.lat_long.latitude,
                        lng: alarmSite.lat_long.longitude,
                      }"
                    />
                    <span
                      class="is-pulled-right pointer"
                      @click="showMap = false"
                      >Hide map</span
                    >
                  </div>
                  <p v-else>
                    <span
                      class="is-pulled-right pointer"
                      @click="showMap = true"
                      >View map</span
                    >
                  </p>
                </div>
                <message-time-detail :alarm-object="alarm" />
              </div>
            </div>
            <div v-if="!loading && alarm && alarm.topic">
              <p class="has-text-weight-bold mt-5">Details:</p>
              <div
                v-if="alarm.topic === 'flow'"
                class="message-details flow-details"
              >
                <flow-detail-block :alarm="alarm" />
              </div>
              <div
                v-if="alarm.topic === 'health'"
                class="message-details health-details"
              >
                <health-detail-block :alarm="alarm" />
              </div>
              <div
                v-if="alarm.topic === 'noflow'"
                class="message-details noflow-details"
              >
                <no-flow-block :alarm="alarm" />
              </div>
              <div
                v-if="alarm.topic === 'source_selection'"
                class="message-details source-selection-details"
              >
                <div v-if="!area">
                  <p>
                    Area not found matching query (id:{{
                      toId(alarm.get("area"))
                    }}) !!. This is required to perform training.
                  </p>
                  <p>Please contact support if this issue persists.</p>
                </div>
                <div v-else>
                  <b-message
                    type="info"
                    v-if="predictedSource"
                    title="Predicted source of flow:"
                    :closable="false"
                  >
                    <message-breadcrumb
                      :source-id="predictedSource.id"
                      :is-detail="false"
                      show-address
                    />
                  </b-message>
                  <alarm-sensors-block :alarm-object="alarm" />
                  <calibration-select-source
                    :alarm-object="alarm"
                    :allow-creation="true"
                    :area-id="toId(alarm.get('area'))"
                    :flow-type.sync="flowType"
                    :source-id.sync="selectedSourceId"
                    :sources="
                      forceSourceId
                        ? [forcedIdSource]
                        : sourcesForSelectedMessage
                    "
                    :loading="loading"
                    @update:loading="$emit('update:loading', $event)"
                  />
                </div>
              </div>
              <div
                v-if="alarm.topic === 'temperature'"
                class="message-details temperature-details"
              >
                <temperature-detail-block :alarm="alarm" />
              </div>
            </div>
            <clip-detail-report-link :alarm="alarm" />
            <div class="has-buttons">
              <div v-if="!loading && actions && actions.length">
                <b-button
                  v-for="action in alarm.topic !== 'source_selection'
                    ? actions || []
                    : []"
                  :key="action.button_title"
                  class="has-margin"
                  :disabled="disabled"
                  expanded
                  :loading="loading"
                  :label="action.button_title"
                  :type="buttonClass(action.type)"
                  @click="handleButtonAction(action)"
                >
                  {{ action.button_title }}
                </b-button>
              </div>
              <b-button
                @click="submitSourceSelection()"
                :loading="loading"
                type="is-default"
                :expanded="isMobile"
                :disabled="
                  !flowType || (flowType === 'flow' && !selectedSourceId)
                "
                :icon-left="iconFor('update')"
                v-if="alarm.topic === 'source_selection'"
              >
                {{
                  get(alarm, "classified_as.flowType", null)
                    ? "Update classification"
                    : "Submit classification"
                }}
              </b-button>
              <b-button
                type="is-warning"
                @click="toggleDismissed('_messages')"
                v-if="message && !message.dismissed && message.$canBeDismissed"
                :disabled="dismissClicked"
                :expanded="isMobile"
                :icon-left="iconFor('dismiss')"
                class="is-pulled-right"
                :loading="loading"
              >
                Dismiss message
              </b-button>
              <b-button
                v-if="message && message.dismissed"
                type="is-warning is-outlined"
                @click="toggleDismissed('_dismissedMessages')"
                :disabled="dismissClicked"
                :expanded="isMobile"
                icon-left="inbox"
                class="is-pulled-right has-background-white"
                :loading="loading"
              >
                Un-dismiss message
              </b-button>
            </div>
          </div>
        </transition>
      </b-message>
    </div>
  </div>
</template>

<script>
import intervalFunctionMixin from "../../mixins/intervalFunctionMixin";
import trackWindowSize from "../../mixins/trackWindowSize";
import CalibrationSelectSource from "../../components/Selects/CalibrationSelectSource";
import MapWithMarker from "../../components/MapWithMarker";
import MessageBreadcrumb from "../../components/UserMessage/MessageBreadcrumb";

import iconFor from "../../icons";
import { get } from "lodash";
import isUserMessageMixin from "../../mixins/isUserMessageMixin";
import AlarmBreadcrumbController from "./AlarmBreadcrumbController";
import TemperatureDetailBlock from "./TopicDetailBlocks/TemperatureDetailBlock";
import NoFlowBlock from "./TopicDetailBlocks/NoFlowBlock";
import AlarmSensorsBlock from "./DetailBlocks/AlarmSensorsBlock";
import MessageTimeDetail from "./DetailBlocks/MessageTimeDetail";
import FlowDetailBlock from "./TopicDetailBlocks/FlowDetailBlock";
import HealthDetailBlock from "./TopicDetailBlocks/HealthDetailBlock";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import ClipDetailReportLink from "./ClipDetailReportLink";

let d = new Date();

function isValue(val) {
  return typeof val !== "undefined" && val !== null && val !== "";
}

export default {
  name: "UserMessageDetailInner",
  components: {
    ClipDetailReportLink,
    HealthDetailBlock,
    FlowDetailBlock,
    MessageTimeDetail,
    AlarmSensorsBlock,
    NoFlowBlock,
    TemperatureDetailBlock,
    AlarmBreadcrumbController,
    CalibrationSelectSource,
    MapWithMarker,
    MessageBreadcrumb,
  },
  mixins: [intervalFunctionMixin, isUserMessageMixin, trackWindowSize],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    forced: {
      type: Boolean,
      default: false,
    },
    onCloseFunction: {
      type: Function,
      default: () => {},
      required: true,
    },
    forceSourceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      Source: this.$dataClasses.Source,
      showMap: false,

      // source selection
      flowType: null,
      selectedSourceId: null,
      clicked: false,

      overridingMessage: null,

      // meta
      isDetail: true,
    };
  },
  computed: {
    forcedIdSource() {
      return this.forceSourceId
        ? getObjectInArrayById(
            this.$store.state.sources,
            this.forceSourceId,
            null
          )
        : [];
    },
  },
  watch: {
    forceSourceId: {
      handler: function (val) {
        this.selectedSourceId = val;
      },
    },
  },
  methods: {
    get,
    iconFor,
    submitSourceSelection() {
      let self = this,
        flow_type = this.flowType;
      this.$emit("update:loading", {
        loading: true,
        messageId: this.message ? this.message.id : null,
        alarmId: this.alarm ? this.alarm.id : null,
      });
      let objectClass = self.message
        ? this.$dataClasses.UserMessage
        : this.$dataClasses.Alarm;
      this.alarm
        .classifyClip({
          flow_type,
          source_id: self.selectedSourceId,
        })
        .then(() => {
          self.$handleSuccess(
            "This event has been marked as " +
              (flow_type === "unknown"
                ? "ambiguous"
                : flow_type === "noflow"
                ? "not flow"
                : flow_type)
          );
          self.$emit("update:loading", {
            loading: false,
            alarmId: this.alarmObject ? this.alarmObject.id : null,
            messageId: this.message ? this.message.id : null,
          });
          self.$emit("classified", {
            alarm: this.alarm,
            message: this.message,
          });
          if (this.message && self.$route.params.id === this.message.id) {
            self.$router.push({ name: "notifications" });
          }
        })
        .catch((e) => {
          self.$emit("update:loading", {
            loading: false,
            alarmId: this.alarmObject.id,
            messageId: this.message ? this.message.id : null,
          });
          self.$handleError(e, e);
        });
    },
    buttonClass(actionType) {
      return "is-" + this.alarm.topic + actionType === "primary"
        ? " is-outlined"
        : "";
    },
    /**
     *
     * @param {{action_url:String,visit_url:String,button_title:String,custom_alert_description:String}} action
     */
    handleButtonAction(action) {
      let self = this;
      if (isValue(action.visit_url)) {
        window.open(action.visit_url, "_blank");
      }
      if (isValue(action.action_url)) {
        self.$emit("update:loading", {
          loading: true,
          messageId: self.message.id,
        });
        self
          .fetchWithAuth(action.action_url)
          .then((res) => res.json())
          .then((res) => {
            self.$emit("update:loading", {
              loading: false,
              messageId: self.message.id,
            });
            self.$handleSuccess(
              `The action '${action.button_title}' has completed`
            );
            if (
              self.message &&
              !self.message.dismissed &&
              self.message.$canBeDismissed
            ) {
              self.toggleDismissed("_messages");
              if (self.$route.params.id === self.message.id) {
                self.$router.push({ name: "notifications" });
              }
            }
          })
          .catch((e) => {
            self.$emit("update:loading", {
              loading: true,
              messageId: this.message ? this.message.id : null,
              alarmId: this.alarm ? this.alarm.id : null,
            });
            self.$handleError("Could not complete button action", e);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../mixins/buefy/styles";

.message-detail-wrapper {
  @media (max-width: $desktop - 1px) {
    margin-top: 0;
  }
}

.box.selected-message-wrapper {
  padding: 0;
}

.selected-message-wrapper > .message-header {
  margin-bottom: 0;
}

.UserMessage {
  max-width: 384px;
  padding: 8px;
}

.has-margin {
  margin: 10px 0;
}

.has-buttons {
  margin-top: 15px;
}

.righter {
  cursor: pointer;
  float: right;
  transition: 0.2s filter ease-out;
  background-color: black;
  border-radius: 15px;

  & :hover {
    filter: contrast(1.5);
  }
}

:deep(.message-header p) {
  margin-bottom: 0;
}

.header-p {
  width: 100%;
  margin-bottom: 0;
}

:deep(.message-header div) {
  width: 100%;
}

.address {
  margin-left: 37px;
}

@media all and (max-width: $tablet - 1) {
  .address {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.clicked {
  opacity: 0.7;
  filter: saturate(1.2);
  transition: 0.1s all ease;
}
</style>
