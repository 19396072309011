<template>
  <div class="aq-form">
    <schema-form
        v-if="created && ! Object.values($store.state.loading).length"
        :schema="filteredSchema"
        :submit-blocked="schema && schema.required && schema.required.includes('for_object_path') && !value.for_object_path"
        :value="value"
        @input="handleInput($event)"
        @update:scope="$emit('update:scope', $event)"
        @submit="handleSubmit()"
    >
      <template
          slot="for_object_path"
          slot-scope="{ item: { schema } }"
      >
        <div class="mt-2 mb-2">
          <b-field
              label="Report level"
              v-if="showScope"
              :custom-class="scopeInputProblem ? 'has-text-danger' : null"
              :type="scopeInputProblem ? 'is-danger' : null"
              :message="scopeInputProblem ? 'Please select a scope level' : null"
          >
            <b-select :value="scope" @input="scope !== $event ? $emit('update:scope', $event) : null">
              <option
                  v-for="collection in schema.collections"
                  :key="collection"
                  :value="collection"
              >
                {{ (collection === 'buildings') ? "Area" : capitalizeFirstLetter(collection).slice(0, -1).replaceAll("_", " ") }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="false">
          show scope ? {{ showScope }}
          scope ? {{ scope }}
          value ? {{ value.for_object_path }} {{ typeof value.for_object_path }}
        </div>
        <div v-if="showScope && scope" class="mb-2">
          <b-field
              label="Selected entity for report"
              :custom-class="selectedEntityHasProblem ? 'has-text-danger' : null"
              :type="selectedEntityHasProblem ? 'is-danger' : null"
              :message="selectedEntityHasProblem ? 'This field is required' : null"
          >
            <select-objects-button-input
                :value="value.for_object_path"
                :type="selectedEntityHasProblem ? 'is-danger is-outlined' : null"
                @input="handleInput({...value, for_object_path:$event})"
                :objects="$store.state[scope]"
            />
          </b-field>
        </div>
      </template>
      <template slot="more-actions">
        <slot name="more-actions"></slot>
      </template>
    </schema-form>
  </div>
</template>

<script>
import {get, sortBy} from 'lodash';
import {SchemaForm} from "../../external/vue-json-schema-form/components";
import SelectObjectsButtonInput from "../Inputs/SelectObjectsButtonInput";


/**
 * This component is used as a wrapper around the vue-json-schema-form lib component
 */
export default {
  name: "AqJsonSchemaForm",
  components: {
    SchemaForm,
    SelectObjectsButtonInput,
  },
  props: {
    fieldsToHide: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    scope: {
      type: String,
      required: false,
    },
    submitUrl: {
      type: String,
      required: false,
    },
  },
  /**
   *
   * @returns {{created: boolean}}
   */
  data() {
    return {
      created: false
    };
  },
  created() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.created = true;
        if (this.value && !this.scope && this.value.for_object_path) {
          this.$emit('update:scope', this.value.for_object_path.split('/')[0]);
        }
      });
    });
  },
  computed: {
    showScope() {
      return Object.keys(this.filteredSchema.properties).includes('for_object_path')
    },
    scopeInputProblem() {
      return this.showScope && !this.scope
    },
    selectedEntityHasProblem() {
      return this.filteredSchema &&
          this.filteredSchema.required &&
          this.filteredSchema.required.includes('for_object_path') &&
          (
              !this.value.for_object_path ||
              !this.$store.state[this.scope].map(obj => this.toFSRef(obj).path).includes(this.value.for_object_path)
          )
    },
    /**
     * values for the form generated via SchemaForm component, stored in json-stringified > query param 'form_value'
     */
    filteredSchema() {
      let schema = this.schema,
          fieldsToHide = this.fieldsToHide,
          props = Object.entries(schema.properties).filter((nameAndObj) => {
            return fieldsToHide && !fieldsToHide.includes(nameAndObj[0]);
          });
      schema.properties = Object.fromEntries(props);
      return schema;
    },
    forObjectPathProps() {
      try {
        return get(this.schema.properties, 'for_object_path', null);
      } catch (e) {
        return null;
      }
    },
    showScopeSelect() {
      return !!this.forObjectPathProps && this.forObjectPathProps.collections && this.forObjectPathProps.collections.length;
    },
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    sortBy,
    getFieldPropsFromSchema(fieldName) {
      return get(this.schema.properties, fieldName, {});
    },
    fieldEnumValues(fieldName) {
      let fieldSchema = this.getFieldPropsFromSchema(fieldName);
      return get(fieldSchema, 'enum', null);
    },
    handleInput(value) {
      if (!this.created) {
        return
      }
      if (
          value &&
          value.for_object_path &&
          this.forObjectPathProps.collections &&
          !this.forObjectPathProps.collections.includes(value.for_object_path.split('/')[0])
      ) {
        let scope = this.forObjectPathProps.collections[0],
            obj = this.getDefaultForObjectPath(this.forObjectPathProps.collections[0]);

        // console.log("handlling input facily", {
        //   scope,
        //   obj
        // });
        this.$emit('update:scope', scope)
        value.for_object_path = obj;
      }
      // console.trace("AqJsonSchemaForm.$emit:input", value)
      this.$emit("input", value);
    },
    handleSubmit() {
      if (!this.created) {
        return
      }
      this.$emit("submit", this.value);
    },
    getDefaultForObjectPath(scope) {
      return (this.$store.state[scope] && this.$store.state[scope].length) ? this.toFSRef(this.$store.state[scope][0]).path : null
    },
    handleSchemaChange(val, prev) {
      // console.log({okNow: this.value})
      if (!this.created) {
        return
      }
      if (
          this.value.for_object_path
      ) {
        // console.log
        let collOfCurrent = (this.value && this.value.for_object_path) ? this.value.for_object_path.split('/')[0] : null,
            collections = get(this.forObjectPathProps, 'collections', []) || [];
        // console.log('hsc 1', {
        //   collOfCurrent,
        //   collections,
        //   value: this.value
        // })
        // If same collection as for_object_path available select it
        // console.log('SCHEMA: same collection as for_object_path available select it:', collOfCurrent)
        if (collections.includes(collOfCurrent) && this.scope !== collOfCurrent) {
          // console.log('hsc 1.1')
          this.$emit('update:scope', collOfCurrent);
          this.$emit("input", this.value);
        }
        // return
      }
      // But if not, select first option
      else if (this.forObjectPathProps.collections && this.forObjectPathProps.collections.length) {
        // console.log('hsc 2', {
        //   scopeUpdateTo: this.forObjectPathProps.collections[0],
        //   value: this.value
        // });
        // console.log("SCHEMA: select first option", this.forObjectPathProps.collections[0])
        this.$emit('update:scope', this.forObjectPathProps.collections[0]);
        this.$emit("input", this.value);
      }
    }
  },
  watch: {
    scope: {
      handler(scope, prev) {
        if (!this.created) {
          return
        }
        // console.log("2.watch.scope:"+scope||'null');
        if (scope && !this.value.for_object_path) {
          const objs = this.$store.state[scope];
          this.handleInput({
            ...this.value,
            for_object_path: (objs && objs.length) ? get(objs[0], '$FSRef.path', null) : null
          });
        } else if (scope && this.value.for_object_path && !this.value.for_object_path.startsWith(scope + '/')) {
          this.$set(this.value, 'for_object_path', null);
        }
      }
    },
    schema: {
      handler(val, prev) {
        // console.log(1, this.value)
        this.handleSchemaChange(val, prev);
        // console.log(2, this.value)
      },
    },
  }
};
</script>

<style scoped>

</style>
