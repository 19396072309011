<template>
  <modal-with-form
    :allow-delete="$store.state.user_roles && $store.state.user_roles.is_admin"
    :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
    :value="isActive"
    @input="activeChanged($event)"
    :can-submit="isValid"
    :full-page="false"
    :id="id"
    :inputs="inputs"
    :class="stateVarName + '-modal'"
    obj-display-name="Area"
    :state-var-name="stateVarName"
    @closed="isActive = false"
    @saved="saved($event)"
    @deleted="deleted($event)"
  >
    <area-form
      v-model="inputs"
      :parent="parent"
      :id="id"
      @validates="validChanged($event)"
    ></area-form>
  </modal-with-form>
</template>

<script>
import AreaForm from "./AreaForm";
import ModalWithForm from "../generic/ModalWithForm";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";

export default {
  name: "AreaModal",
  components: { AreaForm, ModalWithForm },
  computed: {
    objectData() {
      let self = this,
        parent = self.parent ? self.parent.$FSRef : null;
      return this.id
        ? Object.assign(
            getObjectInArrayById(this.$store.state.areas, this.id),
            { parent }
          )
        : { parent };
    },
  },
  data() {
    let self = this;
    return {
      inputs: this.id
        ? Object.assign(
            { parent: self.toFSRef(self.parent) },
            getObjectInArrayById(self.$store.state.areas, self.id)
          )
        : { parent: self.toFSRef(self.parent) },
      isValid: false,
      isActive: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    stateVarName: {
      type: String,
      default: "areas",
    },
  },
  methods: {
    validChanged(valid) {
      this.isValid = valid;
    },
    activeChanged(val) {
      this.$emit("input", this.isActive);
    },
    saved(val) {
      this.$emit("saved", val);
      if (!this.id) {
        this.inputs = {
          parent: this.toFSRef(this.parent, "sites"),
        };
      }
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
  watch: {
    isActive: {
      handler(val) {
        this.activeChanged(val);
      },
    },
    value: {
      handler(val) {
        this.isActive = val;
      },
    },
  },
};
</script>

<style scoped></style>
