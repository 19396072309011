/**
 * API client for AQ FS REST API. Call the HTTP method to use as the instance method.
 * Self-refreshes FirebaseAuth token. Looks for cookie for "Authorization" (OAuth ID token)
 * and "refreshToken" (OAuth2 refresh token) as initial.
 *
 * This uses https://github.com/Backendless/Request as transport mechanism.
 * Calling client.get, .put, .patch, .post, .delete will return a promise.
 * On success, that promises passes the deserialized response body as an object.
 *
 * @example
 * const client = new Client();
 * client.post(
 *   '/endpoint-url',
 *   {thisIs: "My JSON data"}
 * ).then(
 *   response => {
 *     console.log("Do something here! Your JSON data:", response);
 *   }
 * ).catch(
 *   errMessage => alert(errMessage)
 * );
 *
 */
import Request from "backendless-request";
import Cookies from "js-cookie";
import serialize from "./serializer";
import deserialize from "./deserializer";
import isValue from "../scripts/valueCheck";
import Querystring from "querystring";

const authenticateUrl = "/users/authenticate/",
  tokenRefreshUrl = "/users/refresh-token/";

Request.verbose = process.env.VUE_APP_VERBOSE_API_LOGGING;

/**

 */
export default class Client {
  constructor({
    email = null,
    password = null,
    basePath = null,
    refreshAuth = false,
    useAuthFromClient = null,
  }) {
    let authCookie,
      refreshCookie,
      retryDelay = 1500,
      nonRetryStatuses = [400, 403],
      self = this;

    if (!isValue(basePath)) {
      basePath = process.env.VUE_APP_API_URL;
    }

    // Initialize values
    this.authHeaderExpires = null;
    this.authHeaderKey = "Authorization";
    this.basePath = basePath;
    this.checkRefreshInterval = 1000 * 60 * 0.25; // in ms; 15 secs
    this.middlewares = [];
    this.refreshAuth = refreshAuth;
    this.refreshIfExpiresWithin = 1000 * 60 * 0.5; // in ms; 30 secs

    // Allow for passing email/password. If not, try to read from cookies.
    if (isValue(email)) {
      if (!isValue(password)) {
        throw "You must supply a password if an email is entered";
      }
      this.getAuth(email, password);
    } else {
      authCookie = Cookies.get("Authorization");
      refreshCookie = Cookies.get("refreshToken");
      this.authHeaderValue = authCookie;
      this.refreshToken = refreshCookie;
    }

    // serialize data submitted to API, deserialize results returned
    Request.methods.forEach((method) => {
      this[method] = (path, body, queryParams) => {
        if (path.slice(-1) !== "/") {
          // Auto-add ending /
          path += "/";
        }
        if (!path.length || path[0] !== "/" || path.slice(-1) !== "/") {
          throw (
            "Path must be relative to base url (`" +
            this.basePath +
            "`) and start/end with `/`. " +
            "Got `" +
            path +
            "` instead."
          );
        }
        function executeAPICall(resolve, reject, attemptNumber) {
          let fullUrl = `${basePath}${path}?${Querystring.stringify(
            queryParams || {}
          )}`;
          self
            .request(fullUrl, method, serialize(body))
            .then((result) => {
              if (typeof result === "object") {
                // De-serialize result, return promise
                let deserializedResult = deserialize(result);
                resolve(deserializedResult);
              } else {
                reject(
                  `No payload received from "${basePath + path}": ` +
                    `Unexpected response payload type ${typeof result} (expected 'object')`
                );
              }
            })
            .catch((response) => {
              if (
                !attemptNumber &&
                !nonRetryStatuses.includes(response.status)
              ) {
                setTimeout(() => {
                  executeAPICall(resolve, reject, 1);
                }, retryDelay);
              } else {
                reject(response.message.detail || response.message.error);
              }
            });
        }
        return new Promise(executeAPICall);
      };
    });
    this.addMiddleware((request) => {
      if (this.authHeaderValue) {
        request.set(this.authHeaderKey, this.authHeaderValue);
      }
    });
    this.addMiddleware((request) => {
      request.set('Content-Type', 'application/json')
    })
    // get this started
    this.getRefreshToken();
  }

  getAuth(email, password) {
    let self = this;
    self
      .post(authenticateUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        self.setAuth(
          response.Authorization,
          response.refreshToken,
          response.expires
        );
        self.getRefreshToken();
      })
      .catch((e) => {
        throw e;
      });
  }

  setAuth(authHeaderValue, refreshToken, authHeaderExpires) {
    this.authHeaderValue = authHeaderValue;
    this.refreshToken = refreshToken;
    this.authHeaderExpires = new Date(authHeaderExpires);
  }

  unsetAuth() {
    this.authHeaderValue = this.refreshToken = this.authHeaderExpires = null;
  }

  get tokenShouldBeRefreshed() {
    return (
      this.authHeaderValue &&
      this.refreshToken &&
      (!this.authHeaderExpires ||
        this.authHeaderExpires - Date.now() <= this.refreshIfExpiresWithin)
    );
  }

  getRefreshToken(force) {
    let self = this;
    if (force === true || this.tokenShouldBeRefreshed) {
      self
        .post(tokenRefreshUrl, {
          refreshToken: self.refreshToken,
        })
        .then((response) => {
          self.setAuth(
            response.Authorization,
            response.refreshToken,
            response.expires
          );
        });
    }
    setTimeout(this.getRefreshToken, this.checkRefreshInterval);
  }

  request(path, method, body) {
    let request = new Request(path, method, body);
    this.middlewares.forEach((middleware) => {
      if (!middleware.method || middleware.method === method) {
        request = middleware.handler(request) || request;
      }
    });
    return request;
  }

  addMiddleware(method, handler) {
    if (typeof method === "function") {
      handler = method;
      method = undefined;
    }
    this.middlewares.push({ method, handler });
  }
}
