import { get } from "lodash";

/**
 * Can be used for :custom-sort directives. Returns sort function.
 * @param {string} fieldName
 * @returns {function(FirestoreDataClass, FirestoreDataClass, boolean): number}
 */
export function compareFieldFunc(fieldName) {
  return (a, b, isAsc) => {
    let val1 = get(a, fieldName) || "",
      val2 = get(b, fieldName) || "";
    return isAsc
      ? val1.localeCompare(val2, "en", { numeric: true })
      : val2.localeCompare(val1, "en", { numeric: true });
  };
}
