<template>
  <div>
    <div v-if="object">
      <generic-view
        object-name="customer"
        :object="object"
        :children="children"
        child-name="subcustomer"
        child-title="Sub-customers/Divisions"
        collection-name="customers"
        @edit-clicked="editClicked()"
        state-var-name="customers"
      >
        <level :values="levelArray" />
        <template v-slot:childrendisplay>
          <subcust-table :rows="children" />
        </template>
        <template v-slot:addnewchild>
          <b-button
            @click="childModalOpen = true"
            v-if="$store.state.user_roles.is_admin"
            type="is-create is-outlined"
            >Add new sub-customer/division
          </b-button>
        </template>
      </generic-view>

      <add-customer-modal
        v-model="modalOpen"
        :id="object.id"
        :inputs="object"
        state-var-name="customers"
      ></add-customer-modal>

      <add-subcustomer-modal
        v-model="childModalOpen"
        :parent="object"
        :id="null"
      ></add-subcustomer-modal>
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID </b-message>
    </div>
  </div>
</template>

<script>
import AddCustomerModal from "../../../components/Admin/Customer/AddCustomerModal";
import AddSubcustomerModal from "../../../components/Admin/SubCustomers/AddSubcustomerModal";
import GenericView from "./genericView";
import Level from "../../../components/Level/Level";

export default {
  name: "ViewCustomer",
  // mixins: [onScopeChangeMixin],
  components: {
    AddCustomerModal,
    AddSubcustomerModal,
    subcustTable: () => import("../../../tables/SubcustTable"),
    Level,
    GenericView,
  },
  methods: {
    editClicked() {
      this.modalOpen = true;
    },
    idChanged() {
      if (this.id && this.id !== this.$store.state.customers) {
        this.$router.push({
          name: "administration:view-customer",
          params: { id: this.id },
        });
      }
    },
  },
  computed: {
    levelArray() {
      return this.object ? [{ label: "Name", value: this.object.name }] : [];
    },
    parent() {
      return null;
    },
    object() {
      return this.$store.state.customers[0];
    },
    children() {
      return this.$store.state.subcustomers.filter(
        (sc) => sc.customer && sc.customer.id === this.id
      );
    },
  },
  data() {
    return {
      endpointSegment: "customers",
      childModalOpen: Object.keys(this.$route.query).includes("addChild"),
      modalOpen: Object.keys(this.$route.query).includes("edit"),
    };
  },
  watch: {
    modalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { edit: null } : {} });
      },
    },
    childModalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { addChild: null } : {} });
      },
    },
    id: {
      handler: function () {
        return this.idChanged();
      },
    },
    "$store.state.customers": {
      handler(val) {
        if (val && val.length && val[0].id !== this.id) {
          this.$router.push({
            name: "administration:view-customer",
            params: { id: val[0].id },
          });
        }
      },
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
