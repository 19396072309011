var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.forced && _vm.loading)?_c('div',{staticClass:"box"},[(_vm.forced && _vm.loading)?_c('b-loading',{attrs:{"active":""}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"message-detail-wrapper"},[(_vm.forced && (_vm.message || Object.keys(_vm.message).length))?_c('b-message',[(_vm.loading)?_c('b-loading',{attrs:{"is-full-page":false}}):_c('p',[_vm._v(" No message data found. You may not have access to this resource. ")])],1):_vm._e(),_c('b-message',{class:{
        'box has-shadow selected-message-wrapper': true,
        clicked: _vm.clicked,
      },attrs:{"v-if":_vm.message || _vm.alarm,"title":_vm.title,"type":'is-' + _vm.alarm.topic,"has-icon":_vm.windowWidth > 450,"icon":_vm.iconFor(_vm.alarm.topic)},on:{"close":function($event){return _vm.onCloseFunction({ message: _vm.message, alarm: _vm.alarm })}}},[(_vm.loading || !_vm.$store.getters.loaded)?_c('div',{staticClass:"has-text-centered"},[_c('b-icon',{staticClass:"fa-spin",attrs:{"icon":"sync","size":"is-large"}})],1):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(!_vm.loading && _vm.alarm)?_c('div',[_c('div',{staticClass:"mb-2"},[(_vm.alarm && _vm.alarm.alert_type)?_c('p',{staticClass:"has-text-centered message-is-alarm mb-5"},[_c('b-icon',{staticClass:"has-text-centered",attrs:{"type":_vm.alarm.$isWarning ? 'is-danger' : 'is-primary',"icon":_vm.alarm.$isWarning ? 'exclamation-triangle' : 'info-circle',"size":"is-small"}}),_c('span',{class:{ 'has-text-danger': _vm.alarm.$isWarning }},[_vm._v(" "+_vm._s(_vm.alarm.$isWarning ? "Warning" : "Info")+" notification ")])],1):_vm._e(),_c('div',{staticClass:"message-location"},[_c('div',[_c('alarm-breadcrumb-controller',{attrs:{"alarm-object":_vm.alarm,"show-address":"","show-floors":"","show-links":"","show-title":"","is-detail":""}})],1),(_vm.alarmSite && _vm.alarmSite.lat_long)?_c('div',{staticClass:"address"},[(_vm.showMap)?_c('div',[_c('map-with-marker',{attrs:{"marker":{
                      lat: _vm.alarmSite.lat_long.latitude,
                      lng: _vm.alarmSite.lat_long.longitude,
                    }}}),_c('span',{staticClass:"is-pulled-right pointer",on:{"click":function($event){_vm.showMap = false}}},[_vm._v("Hide map")])],1):_c('p',[_c('span',{staticClass:"is-pulled-right pointer",on:{"click":function($event){_vm.showMap = true}}},[_vm._v("View map")])])]):_vm._e(),_c('message-time-detail',{attrs:{"alarm-object":_vm.alarm}})],1)]),(!_vm.loading && _vm.alarm && _vm.alarm.topic)?_c('div',[_c('p',{staticClass:"has-text-weight-bold mt-5"},[_vm._v("Details:")]),(_vm.alarm.topic === 'flow')?_c('div',{staticClass:"message-details flow-details"},[_c('flow-detail-block',{attrs:{"alarm":_vm.alarm}})],1):_vm._e(),(_vm.alarm.topic === 'health')?_c('div',{staticClass:"message-details health-details"},[_c('health-detail-block',{attrs:{"alarm":_vm.alarm}})],1):_vm._e(),(_vm.alarm.topic === 'noflow')?_c('div',{staticClass:"message-details noflow-details"},[_c('no-flow-block',{attrs:{"alarm":_vm.alarm}})],1):_vm._e(),(_vm.alarm.topic === 'source_selection')?_c('div',{staticClass:"message-details source-selection-details"},[(!_vm.area)?_c('div',[_c('p',[_vm._v(" Area not found matching query (id:"+_vm._s(_vm.toId(_vm.alarm.get("area")))+") !!. This is required to perform training. ")]),_c('p',[_vm._v("Please contact support if this issue persists.")])]):_c('div',[(_vm.predictedSource)?_c('b-message',{attrs:{"type":"info","title":"Predicted source of flow:","closable":false}},[_c('message-breadcrumb',{attrs:{"source-id":_vm.predictedSource.id,"is-detail":false,"show-address":""}})],1):_vm._e(),_c('alarm-sensors-block',{attrs:{"alarm-object":_vm.alarm}}),_c('calibration-select-source',{attrs:{"alarm-object":_vm.alarm,"allow-creation":true,"area-id":_vm.toId(_vm.alarm.get('area')),"flow-type":_vm.flowType,"source-id":_vm.selectedSourceId,"sources":_vm.forceSourceId
                      ? [_vm.forcedIdSource]
                      : _vm.sourcesForSelectedMessage,"loading":_vm.loading},on:{"update:flowType":function($event){_vm.flowType=$event},"update:flow-type":function($event){_vm.flowType=$event},"update:sourceId":function($event){_vm.selectedSourceId=$event},"update:source-id":function($event){_vm.selectedSourceId=$event},"update:loading":function($event){return _vm.$emit('update:loading', $event)}}})],1)]):_vm._e(),(_vm.alarm.topic === 'temperature')?_c('div',{staticClass:"message-details temperature-details"},[_c('temperature-detail-block',{attrs:{"alarm":_vm.alarm}})],1):_vm._e()]):_vm._e(),_c('clip-detail-report-link',{attrs:{"alarm":_vm.alarm}}),_c('div',{staticClass:"has-buttons"},[(!_vm.loading && _vm.actions && _vm.actions.length)?_c('div',_vm._l((_vm.alarm.topic !== 'source_selection'
                  ? _vm.actions || []
                  : []),function(action){return _c('b-button',{key:action.button_title,staticClass:"has-margin",attrs:{"disabled":_vm.disabled,"expanded":"","loading":_vm.loading,"label":action.button_title,"type":_vm.buttonClass(action.type)},on:{"click":function($event){return _vm.handleButtonAction(action)}}},[_vm._v(" "+_vm._s(action.button_title)+" ")])}),1):_vm._e(),(_vm.alarm.topic === 'source_selection')?_c('b-button',{attrs:{"loading":_vm.loading,"type":"is-default","expanded":_vm.isMobile,"disabled":!_vm.flowType || (_vm.flowType === 'flow' && !_vm.selectedSourceId),"icon-left":_vm.iconFor('update')},on:{"click":function($event){return _vm.submitSourceSelection()}}},[_vm._v(" "+_vm._s(_vm.get(_vm.alarm, "classified_as.flowType", null) ? "Update classification" : "Submit classification")+" ")]):_vm._e(),(_vm.message && !_vm.message.dismissed && _vm.message.$canBeDismissed)?_c('b-button',{staticClass:"is-pulled-right",attrs:{"type":"is-warning","disabled":_vm.dismissClicked,"expanded":_vm.isMobile,"icon-left":_vm.iconFor('dismiss'),"loading":_vm.loading},on:{"click":function($event){return _vm.toggleDismissed('_messages')}}},[_vm._v(" Dismiss message ")]):_vm._e(),(_vm.message && _vm.message.dismissed)?_c('b-button',{staticClass:"is-pulled-right has-background-white",attrs:{"type":"is-warning is-outlined","disabled":_vm.dismissClicked,"expanded":_vm.isMobile,"icon-left":"inbox","loading":_vm.loading},on:{"click":function($event){return _vm.toggleDismissed('_dismissedMessages')}}},[_vm._v(" Un-dismiss message ")]):_vm._e()],1)],1):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }