<template>
  <div>
    <transition name="slide">
      <div v-if="$store.state.fetching.pipe_locations">
        <loading-chart-card spinner />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="!$store.state.fetching.pipe_locations && object">
        <generic-view
          :editable="$store.state.user_roles.is_installer"
          object-name="pipe_location"
          parent-name="area"
          parent-title="Area"
          :ancestors="ancestors"
          :parent="parent"
          :object="object"
          :state-var-name="endpointSegment"
          @edit-clicked="editClicked()"
        >
          <div>
            <level :values="levelArray">
              <div class="level">
                <div class="level-left">
                  <div class="level-item has-text-weight-bold">Tags/Groups</div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <b-taglist v-if="object.tags && object.tags.length">
                      <b-tag v-for="tag in object.tags" :key="tag">{{
                        tag
                      }}</b-tag>
                    </b-taglist>
                    <p v-else>None</p>
                  </div>
                </div>
              </div>
            </level>
          </div>
          <div class="card mt-6">
            <div class="card-header">
              <div class="card-header-title">Downstream points-of-use</div>
            </div>
            <div class="card-content">
              <source-table :rows="sources" show-flow-rate>
                <template #empty>
                  <div class="has-text-centered mt-2">
                    <i>No downstream points-of-use</i>
                  </div>
                </template>
              </source-table>
            </div>
          </div>
        </generic-view>

        <add-pipe-location-modal
          v-if="object"
          ref="pipeLocationModal"
          :building="building"
          :id="id"
          :parent="area"
          :room="room"
          v-model="modalOpen"
          @save="
            $store.commit('add', {
              name: 'pipe_locations',
              result: $event,
            });
            object = $event;
            modalOpen = false;
          "
        />
      </div>
    </transition>
    <transition name="slide">
      <div v-if="!loading && !object">
        <b-message type="is-error">
          No object found with that ID at site:
          <site-display
            show-link-icon
            is-multiline
            :value="$store.getters.site"
          />
        </b-message>
      </div>
    </transition>
  </div>
</template>

<script>
import { getAncestors } from "../../../scripts/filterHelpers";
import iconFor from "../../../icons";
import AddPipeLocationModal from "../../../components/Admin/PipeLocation/AddPipeLocationModal";
import GenericView from "./genericView";
import Level from "../../../components/Level/Level";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";

import SourceTable from "../../../tables/SourceTable";
import LoadingChartCard from "../../../components/Skeletons/LoadingChartCard";


export default {
  name: "ViewPipeLocation",
  components: {
    LoadingChartCard,
    AddPipeLocationModal,
    GenericView,
    Level,
    SourceTable,
  },
  mixins: [onScopeChangeMixin],
  methods: {
    iconFor,
    editClicked() {
      this.modalOpen = true;
    },
  },
  computed: {
    customer() {
      return this.object.$customer;
    },
    subcustomer() {
      return this.object.$subcustomer;
    },
    site() {
      return this.object.$site;
    },
    building() {
      return this.object.$building;
    },
    area() {
      return this.object.$area;
    },
    room() {
      return this.object.$room;
    },
    sources() {
      return this.object.downstream_sources
        .map((s) => this.$dataClasses.Source.fromStore(this.$store.state.sources, s.id))
        .filter((s) => !!s);
    },
    ancestors() {
      if (!this.object) {
        return [];
      }
      return getAncestors([
        {
          title: "Customer",
          object: this.customer,
          endpointName: "administration:view-customer",
        },
        {
          title: "Sub-Customer",
          object: this.subcustomer,
          endpointName: "administration:view-subcustomer",
        },
        {
          title: "Site",
          object: this.site,
          endpointName: "administration:view-site",
        },
        {
          title: "Area",
          object: this.building,
          endpointName: "administration:view-area",
        },
        {
          title: "Room",
          object: this.room,
          endpointName: "administration:view-room",
        },
      ]);
    },
    levelArray() {
      return this.object
        ? [
            {
              label: "Name",
              value: this.object.name,
            },
            {
              label: "Description",
              value: this.object
                ? this.object.description || "Not set"
                : "Not set",
            },
            {
              label: "Room ",
              value:
                this.object && this.object.$room
                  ? this.object.$room.name || "Not set"
                  : "Not set",
            },
            {
              label: "Temperature",
              value:
                this.object && this.object.temperature
                  ? this.capitalizeFirstLetter(this.object.temperature)
                  : "Not set",
              icon: iconFor(this.object.temperature),
              iconType: this.object.temperature
                ? `is-${this.object.temperature}`
                : null,
            },
            {
              label: "Floor",
              value: this.object.$floor || "Not set",
            },
          ]
        : [];
    },
    parent() {
      return this.object.$room;
    },
    modalOpen: {
      get() {
        return Object.keys(this.$route.query).includes("edit");
      },
      set(val) {
        val ? this.queryReplace({ edit: "" }) : this.queryRemoveKeys(["edit"]);
      },
    },
    object() {
      return this.id
        ? this.$dataClasses.PipeLocation.fromStore(
            this.$store.state.pipe_locations,
            this.id,
            null
          )
        : null;
    },
  },
  data() {
    return {
      loading: false,
      endpointSegment: "pipe-locations",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
