<template>
  <div class="card" :v-show="value">
    <header class="card-header" v-if="sections.includes('header')">
      <div class="card-header-icon">
        <b-skeleton
          width="150px"
          height="150px"
          class="mb-5"
          animated
          circle
        ></b-skeleton>
      </div>
      <div class="card-header-title">
        <b-skeleton width="40%" animated></b-skeleton>
      </div>
    </header>
    <section class="card-content" v-if="sections.includes('body')">
      <section>
        <slot> No object found. </slot>
      </section>
    </section>
    <footer class="card-footer" v-if="sections.includes('footer')">
      <a class="card-footer-item">
        <b-button @click="$router.push('home')">Return to home screen</b-button>
      </a>
    </footer>
  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    /**
     * Whether to show animated skeleton
     */
    value: {
      type: Boolean,
      default: true,
    },
    /**
     * Which sections to show; By default is all- ['header', 'body', 'footer']
     */
    sections: {
      type: Array,
      required: false,
      default: () => {
        return ["header", "body", "footer"];
      },
    },
  },
};
</script>

<style scoped></style>
