import { saveAs } from "file-saver";
import FirestoreDataClass from "./_baseClasses";

export default class Report extends FirestoreDataClass {
  static collectionName = "reports";

  download() {
    function checkForError(response) {
      if (!response.ok || response.status !== 200) {
        response.json().then((r) => {
          throw new Error(r.detail || r.error || r.message);
        });
      }
      return response;
    }
    if (!this.saved) {
      throw "Cannot download unsaved report";
    }
    const options = {
        headers: {
          Authorization: this.constructor.$client.authHeaderValue,
        },
      },
      self = this,
      downloadUrl = `${self.constructor.$client.basePath}${self.constructor.APIEndpointSegment}/${self.id}/download/`;

    return new Promise(function (resolve, reject) {
      fetch(downloadUrl, options)
        .then((res) => checkForError(res))
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, self.filename);
          resolve(blob);
        })
        .catch((e) => reject(e));
    });
  }
}
