import { pick } from "lodash";
import { firestore as db } from "../../firebase";
import toRef from "../../appLevelFunctions/toRef";

/**
 * None of these should be subscribed to without user roles being set!
 *
 * Assumptions-
 *   - Any variable described here will be reset if no user
 *   - Otherwise, default get Vuex action will be used on user load.
 *
 * Defaults for Vuex state variables. If not supplied, will fall back to null.
 * @type object Key: Vuex state variable name, Value: default (beginning & reset) value
 */

const userMessageCollection = "user_messages";

export const stateVariables = {
  // used outside hierarchy for selections only
  customers: {
    default: [],
    collection_name: "customers",
    children: [
      "subcustomers",
      "sites",
      "areas",
      "sources",
      "buildings",
      "rooms",
      "pipe_locations",
    ],
  },
  subcustomers: {
    default: [],
    collection_name: "subcustomers",
    inHierarchy: true,
    children: [
      "sites",
      "areas",
      "sources",
      "buildings",
      "rooms",
      "pipe_locations",
    ],
  },
  sites: {
    default: [],
    collection_name: "sites",
    inHierarchy: true,
    children: ["areas", "sources", "buildings", "rooms", "pipe_locations"],
  },
  areas: {
    default: [],
    collection_name: "areas",
    inHierarchy: true,
    children: [],
  },
  sources: {
    default: [],
    collection_name: "sources",
    inHierarchy: true,
    children: [],
  },
  buildings: {
    default: [],
    collection_name: "buildings",
    inHierarchy: true,
    children: [],
  },
  rooms: {
    default: [],
    collection_name: "rooms",
    inHierarchy: true,
    children: [],
  },
  pipe_locations: {
    default: [],
    collection_name: "pipe_locations",
    inHierarchy: true,
    children: [],
  },

  // Non-hierarchy
  _messages: {
    default: [],
    collection_name: "user_messages",
    directSubscription: true,
    getQuery: ({ state, getters }) => {
      let stateVar = "_messages";
      return db
        .collection(userMessageCollection)
        .where("user_id", "==", state.user.uid)
        .where("alarm.site", "==", toRef(getters.site))
        .where("dismissed", "==", false)
        .orderBy("last_update_dt", "desc")
        .limit(getters.limitForNextPage(stateVar, state.pagination[stateVar]));
    },
  },
  _dismissedMessages: {
    default: [],
    collection_name: "user_messages",
    directSubscription: true,
    getQuery: ({ state, getters }) => {
      let stateVar = "_dismissedMessages";
      return db
        .collection(userMessageCollection)
        .where("user_id", "==", state.user.uid)
        .where("alarm.site", "==", toRef(getters.site))
        .where("dismissed", "==", true)
        .orderBy("last_update_dt", "desc")
        .limit(getters.limitForNextPage(stateVar, state.pagination[stateVar]));
    },
  },
  user: {
    default: null,
  },
};

/**
 * Vuex state variable defaults, key:name value:defaultValue.
 * Used in initial state and on .reset
 * @type object
 */
export function getStateVariableDefault(varName) {
  let objMeta = stateVariables[varName];
  return objMeta ? JSON.parse(JSON.stringify(objMeta.default)) : null;
}

/**
 * If should fetch single object from API for this variable.
 * @param stateVariableKey
 * @returns {Boolean}
 */
export function shouldFetchSingle(stateVariableKey) {
  let conditions = [
    !shouldFetchList(stateVariableKey) &&
      stateVariables[stateVariableKey].collection_name,
  ];
  return conditions.every((val) => Boolean(val));
}

/**
 * If should fetch list from API (as opposed to single object, or not use API at all)
 * @param stateVariableKey
 * @returns {Boolean}
 */
export function shouldFetchList(stateVariableKey) {
  let conditions = [
    defaultIsArray(stateVariableKey),
    stateVariables[stateVariableKey].collection_name,
  ];
  return conditions.every((val) => Boolean(val));
}

/**
 * Given a collection name, return variable objects of children objects
 * @param varName
 * @returns {Array} Matched variable objects from stateVariables
 */
export function getHierarchyChildren(varName) {
  let stateVariableDefinition = stateVariables[varName];
  return pick(
    stateVariables,
    stateVariableDefinition ? stateVariableDefinition.children || {} : {}
  );
}

/**
 * Given a collection name, return parent variable object
 * @param varName
 * @returns {Object}
 */
export function getHierarchyParent(varName) {
  let match = null;
  Object.keys(stateVariables).forEach((varName) => {
    let objMeta = stateVariables[varName];
    if (!match && objMeta.children && objMeta.children.includes(varName)) {
      match = objMeta;
    }
  });
  return match;
}

/**
 * Is the default value an array?
 * @param varName
 * @returns {Boolean}
 */
export function defaultIsArray(varName) {
  return Array.isArray(getStateVariableDefault(varName));
}
