<template>
  <div class="header" v-if="user">
    <div class="nav-section" v-if="showHamburger">
      <b-button
        class="hamburgler"
        size="is-small"
        icon-left="bars"
        @click="hamburgerClick()"
      ></b-button>
      <router-link :to="{ name: 'home' }">
        <img :src="swoosh" class="ml-2 mt-1" alt="AquaSeca Logo" />
      </router-link>
    </div>
    <div class="nav-section">
      <vue-breadcrumbs v-if="showBreadcrumbs" :separator="'>'" />
    </div>
    <div class="nav-section is-pulled-right">
      <b-tooltip
        label="Change the scope you are working within"
        position="is-left"
        v-if="$store.getters.requiresScopeSelection"
      >
        <b-navbar-item
          @click="scopeSelectClicked()"
          title="Change which customer you are viewing"
        >
          <b-icon :icon="iconFor('customer')" />
          <span v-if="$store.getters.topLevel.object">{{
            $store.getters.topLevel.object.name
          }}</span>
        </b-navbar-item>
      </b-tooltip>

      <b-navbar-item tag="router-link" :to="{ name: 'notifications' }">
        <b-icon
          :icon="iconFor('notifications')"
          :type="
            $route.name === 'notifications' ||
            $route.name.startsWith('notifications:')
              ? 'is-primary'
              : null
          "
        />
        <span
          :class="{
            'icon-text': true,
            'has-text-primary':
              $route.name === 'notifications' ||
              $route.name.startsWith('notifications:'),
          }"
        >
          {{ messages.length ? messages.length : ""
          }}{{
            messages && messages.length && messages.length % 10 === 0 ? "+" : ""
          }}
        </span>
      </b-navbar-item>
    </div>
  </div>
</template>

<script>
import VueBreadcrumbs from "../components/vue-breadcrumbs";
import { mapGetters } from "vuex";
import iconFor from "../icons";
import swoosh from "../assets/swoosh-sm.png";

export default {
  name: "Navbar",
  components: { VueBreadcrumbs },
  computed: {
    ...mapGetters(["messages"]),
  },
  data() {
    return {
      swoosh,
      profileHovered: false,
      showSelectCustomer: false,
    };
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    showBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    showHamburger: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    iconFor,
    hamburgerClick() {
      this.$emit("hamburgered");
    },
    scopeSelectClicked() {
      this.$emit("scope-select-clicked", true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../mixins/buefy/styles.scss";

.header {
  position: fixed;
  background-color: $navbar-background;
  z-index: 20;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  margin-bottom: 15px;
  display: inline;
  width: calc(100% - 260px);
  border-bottom: 1px solid lightgrey;
}
@media screen and (max-width: $desktop) {
  .header {
    width: 100%;
    z-index: 7;
  }
}

.header .nav-section {
  display: inline-block;
}

.header .nav-section .navbar-item {
  display: inline;
}

.pointer {
  cursor: pointer;
}

.hover-me:hover .dropdown-trigger {
  color: $link;
}
</style>
