<template>
  <table class="table is-fullwidth">
    <thead>
    <tr>
      <th class="has-text-centered">Point-of-use</th>
      <th class="has-text-centered">Suggested action</th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="tq in rows"
        :key="tq.source_nm"
    >
      <td>
        <source-display
            :value="$store.getters.objectByID(tq.source_nm.replace('SRC:', ''))"
            show-room
            :show-tooltip="false"
            :show-link-icon="false"
            show-temp-icon
            :show-training-level="false"
        />
      </td>
      <td>
        <b-button
            class="mt-1"
            size="is-small"
            @click="sourceClicked([$store.getters.objectByID(tq.source_nm.replace('SRC:', ''))])"
            type="is-success"
        >
          Add training
        </b-button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {getObjectInArrayById} from "../scripts/filterHelpers";

export default {
  name: "TrainingQualitySummaryTable",
    props: {
    rows: {
      type: Array,
      required: true,
    }
  },
  methods: {
    getObjectInArrayById,
    sourceClicked(srcArr) {
      this.$emit('input', srcArr);
    }
  },
}
</script>

<style scoped>

</style>