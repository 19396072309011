<template>
  <div>
    <div v-if="value && value.length">
      <b-table
      draggable
      :data="rows"
      detailed
      narrowed
      :default-sort="['name', 'description']"
      ref="table"
      :opened-detailed="$route.query.id ? [$route.query.id] : []"
      detail-key="id"
    >
        <template #empty>
          <div class="has-text-centered">
            <i>No rows to display</i>
          </div>
        </template>
      <b-table-column label="Name" field="$displayString" v-slot="props" sortable searchable :custom-sort="alphanumericSort">
        <b-tooltip
          position="is-top"
          label="This alarm schedule has not been saved"
          type="is-danger"
        >
          <b-icon
            type="is-danger"
            v-if="!props.row.id"
            :icon="iconFor('danger')"
          />
        </b-tooltip>
        {{ props.row.$displayString || "Un-named" }}
      </b-table-column>
      <b-table-column label="Active" v-slot="props" field="is_active" sortable>
        <b-icon :icon="props.row.is_active ? 'check' : 'times'" />
      </b-table-column>
      <b-table-column label="Scope" v-slot="props">
        <span
          v-if="props.row.pipe_locations && props.row.pipe_locations.length"
        >
          <b-icon :icon="iconFor('pipe_location')" />
          {{ props.row.pipe_locations.length }} pipe location{{
            props.row.pipe_locations.length !== 1 ? "s" : ""
          }}
        </span>
        <span v-else-if="props.row.sources && props.row.sources.length"
          ><b-icon :icon="iconFor('source')" />
          {{ props.row.sources.length }} source{{
            props.row.sources.length !== 1 ? "s" : ""
          }}
        </span>
        <building-display
          v-else-if="buildingForArea(props.row)"
          :value="buildingForArea(props.row)"
          show-link-icon
          :show-floors="false"
        />
        <i v-else>None</i>
      </b-table-column>
      <b-table-column
        label="Description"
        field="description"
        v-slot="props"
        sortable
        searchable
      >
        {{ props.row.schedule ? props.row.schedule.description : "Not set" }}
      </b-table-column>
      <b-table-column label="Topic" field="topic" v-slot="props" sortable>
        {{
          props.row.topic
            ? props.row.topic.replace("_", " ").replace("_", " ")
            : ""
        }}
      </b-table-column>
      <b-table-column
        label="Alarm type"
        field="$readableAlarmType"
        v-slot="props"
        sortable
        searchable
      >
        {{ props.row.$readableAlarmType }}
      </b-table-column>
      <b-table-column
        label="Alert level"
        field="alert_type"
        v-slot="props"
        sortable
        searchable
      >
        <b-tag :type="'is-' + props.row.alert_type" v-if="props.row.alert_type">
          {{
            props.row.alert_type
              ? props.row.alert_type.replace("_", " ").replace("_", " ")
              : ""
          }}
        </b-tag>
      </b-table-column>
      <template #detail="props">
        <message-settings-indiv-record
          :value="props.row"
          :site="site"
          @create="
            ['input', 'update:value'].forEach((evt) =>
              $emit(evt, value.filter((a) => !!a.id).concat($event))
            )
          "
          @update="
            ['input', 'update:value'].forEach((evt) =>
              $emit(evt, value.filter((a) => a.id !== $event.id).concat($event))
            )
          "
          @delete="$emit('remove', props.row)"
        />
      </template>
    </b-table>
    </div>
    <b-message
      v-else
      type="is-info"
      title="No alarm schedules"
      :closable="false"
    >
      <p>There are currently no alarm schedules set for this site</p>
    </b-message>
  </div>
</template>

<script>
import { get } from "lodash";
import iconFor from "../icons";
import MessageSettingsIndivRecord from "../components/UserMessage/MessageSettingsIndivRecordRaw";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import AlarmSchedule from "../dataClasses/AlarmSchedule";

export default {
  name: "AlarmScheduleTable",
  components: { MessageSettingsIndivRecord },
  data() {
    return {
      AlarmSchedule,
      alarmTypes: {
        user_filter: "Continuous POU flow",
        agg_filter_30min: "Aggregated flow",
        // health_alarm: "System status",
        noflow: "No-Flow/Stagnation",
      },
    };
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
    internalAreaId: {
      type: String,
      required: false,
    }
  },
  computed: {
    rows() {
      return this.value.filter(
          alarmSched => {
            return (
                this.internalAreaId
                && (
                    alarmSched.reference_path && (
                        alarmSched.reference_path.id === this.internalAreaId
                    )
                )
            ) || !this.internalAreaId
          }
      );
    }
  },
  methods: {
    get,
    iconFor,
    buildingForArea(row) {
      if (!row.reference_path) {
        return null;
      }
      let area = getObjectInArrayById(
        this.$store.state.areas,
        row.reference_path,
        null
      );
      return area && area.building
        ? getObjectInArrayById(this.$store.state.buildings, area.building)
        : null;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val || !val.length) {
          return;
        }
        if (!val[val.length - 1].id) {
          this.$nextTick(() => {
            this.$refs.table.openDetailRow(val[val.length - 1]);
          });
        }
      },
    },
  },
};
</script>

<style scoped></style>
