<template>
  <div class="connect-weight-wrapper">
    <b-table bordered striped class="is-fullwidth" :data="rows">
      <b-table-column label="Sensor" v-slot="props" field="serial">
        {{ sensorForSerial(props.row.sensor.id).serial }}
      </b-table-column>
      <b-table-column
        label="Weight"
        numeric
        sortable
        field="weight"
        v-slot="props"
      >
        {{ props.row.weight }}
      </b-table-column>
      <slot name="end" />
      <template #empty>
        <slot name="empty">
          <p>No connection weights found</p>
        </slot>
      </template>
    </b-table>
    <slot name="post-table" />
  </div>
</template>

<script>
import { clone, uniqBy } from "lodash";

export default {
  name: "ConnectWeightTable",
  props: {
    connectWeights: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    sensors: {
      type: Array,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rows() {
      return this.connectWeights && this.connectWeights.length
        ? uniqBy(clone(this.connectWeights), 'sensor.id').sort((a, b) => a.weight > b.weight)
        : [];
    },
  },
  methods: {
    sensorForSerial(id) {
      let matches = this.sensors.filter((s) => s.sensor.id === id);
      return matches.length ? matches[0] : null;
    },
  },
};
</script>

<style scoped>
.connect-weight-wrapper {
  width: 100%;
  margin: 0 auto;
}
</style>
