import toRef from "./toRef";

export default function toId(_obj, collectionName=undefined) {
  if (!_obj) {
    return null;
  }
  if (typeof _obj === "string") {
    return _obj.includes("/")
      ? _obj.split("/")[_obj.split("/").length - 1]
      : _obj;
  }
  try {
    let ref = toRef(_obj, collectionName);
    return ref && ref.id ? ref.id : null;
  } catch (e) {
    if (_obj.id) {
      return _obj.id;
    } else {
      throw e;
    }
  }
}
