/**
 * Access actions via store.dispatch(actionName)
 */

import Roles from "../../components/Roles";
import {assertAllProperies, hasAllProperties, hasSomeProperties, paginateBy, stateVariables,} from "../shared";
import isValue from "../../scripts/valueCheck";
import {firestoreAction} from "vuexfire";
import toRef from "../../appLevelFunctions/toRef";
import router from "../../router";

function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const actions = {
    setUserTokenLoading({commit}, payload) {
        commit("setUserTokenLoading", payload);
    },
    isLoading({commit}) {
        commit("setLoading", true);
    },
    isDoneLoading({commit}) {
        commit("setLoading", false);
    },
    showSelectScopeModal() {
        router.replace({query: {sss: "1"}});
    },
    hideSelectScopeModal() {
        router.replace({query: {sss: null}});
    },
    getObjFromIdStringAction({commit, dispatch, getters, state}, payload) {
        let self = this;
        self._vm.$client
            .get("/by-id-string/", {}, {id_string: payload.idString})
            .then((res) => {
                if (res.result) {
                    let newOne = {};
                    newOne[payload.idString] = res.result;
                    state.idStrToObj = Object.assign(newOne, state.idStrToObj);
                }
            })
            .catch((e) => self._vm.$handleError(e, e));
    },
    getUserAccount({commit, dispatch, getters, state}, payload) {
        let user = state.user,
            self = this;
        if (user) {
            self._vm.$dataClasses.UserAccount.fetchById(user.uid)
                .then((result) => {
                    commit("setUserAccount", result);
                    if (payload && payload.callback) {
                        payload.callback(result);
                    }
                })
                .catch((e) => {
                    self._vm.$handleError(
                        "No registered AquaSeca account found for user " + user.email,
                        e
                    );
                    commit("setUserAccount", null);
                })
                .finally(payload.onFinally || function () {
                });
        } else {
            commit("setUserAccount", null);
        }
    },
    /**
     *
     * @param commit
     * @param state
     * @param dispatch
     * @param getters
     * @param id_str
     * @param callback
     * @param onFinally
     */
    setScopeFromIdStr(
        {commit, state, dispatch, getters},
        {id_str = null, callback = null, onFinally = null}
    ) {
        if (id_str && id_str === state.selectedScopeIdStr) {
            return;
        }
        if (!id_str) {
            dispatch("unsubscribeFromHierarchy");
            return;
        }
        let segments = id_str.split("|"),
            self = this;
        if (segments && segments.length !== 5 && state.selectedScopeIdStr) {
            self._vm.$handleError(
                `Can only load site-level scope; 
                Received an id-string with ${segments.length-1} segments
            `);
            return;
        }
        let customerId = segments[2],
            subcustomerId = segments[3],
            siteId = segments[4];
        // console.log({segments, customerId, subcustomerId, siteId})
        // let topLevelName = getters.singleNameByIdString(id_str);
        dispatch("reset", {
            name: "customers",
        });
        commit("set", {name: "selectedScopeIdStr", value: id_str});

        dispatch("get", {
            name: "customers",
            id: customerId,
        });
        dispatch("get", {
            name: "subcustomers",
            id: subcustomerId,
        });
        dispatch("get", {
            name: "sites",
            id: siteId,
            isTopLevel: true,
            callback,
            onFinally
        });
    },
    /**
     * Called after user token updated from FB auth on login
     * @param commit
     * @param getters
     * @param state
     * @param dispatch
     * @param payload{user:Object}
     */
    onAuthStateChanged({commit, getters, state, dispatch}, payload) {
        let user = payload.user,
            self = this;
        if (!user) {
            dispatch("setUserTokenLoading", true);
            dispatch("unsubscribeFromFirestore");
            dispatch("setScopeFromIdStr", {id_str: null});
            commit("set", {name: "user", value: null});
            commit("set", {name: "user_account", value: {}});
            commit("set", {name: "user_roles", value: new Roles([])});
            commit("setLoading", false);
            dispatch("setUserTokenLoading", false);
        } else {
            let nextUrl =
                router.history.current.query.nextUrl ||
                router.history.current.query.next;
            if (
                nextUrl &&
                (nextUrl.startsWith("http://") || nextUrl.startsWith("https://"))
            ) {
                // catch time next url is external
                window.location.href = nextUrl;
                return;
            }
            self._vm
                .$client
                .get("/ping/")
                .then(() => {
                    let fetchHierarchy = !state.user || state.user.uid !== user.uid;
                    dispatch("setUserTokenLoading", true);
                    commit("set", {name: "user", value: user});
                    dispatch("getUserAccount", {
                        id: user.uid,
                        onFinally: () => {
                            dispatch("setUserTokenLoading", false);
                            commit("setLoading", false);
                        },
                        callback: !fetchHierarchy
                            ? null
                            : () => {
                                function goToNext() {
                                    if (nextUrl) {
                                        // console.log(' r1')
                                        if (
                                            nextUrl.startsWith("/") ||
                                            nextUrl.startsWith(window.location.origin)
                                        ) {
                                            // console.log(' r1.1', nextUrl)
                                            router.push(nextUrl);
                                        } else {
                                            // console.log(' r1.2')
                                            window.location.href = nextUrl;
                                        }
                                    } else {
                                        // console.log("NOT NEZXT URL")
                                        router.push({name: "home"});
                                    }
                                }

                                // Need to select customer if roles point to more than one.

                                let scopeToLoad = router.history.current.query.nextUrl
                                    ? getParameterByName(
                                        "loadScope",
                                        router.history.current.query.nextUrl
                                    )
                                    : router.currentRoute.query.loadScope
                                        ? router.currentRoute.query.loadScope
                                        : state.user_account.default_site_id_str;
                                if (state.user_account && scopeToLoad) {
                                    // console.log('b1')
                                    dispatch("setScopeFromIdStr", {
                                        id_str: scopeToLoad,
                                    }).then(goToNext);
                                } else if (getters.requiresScopeSelection) {
                                    // console.log('b2')
                                    dispatch("get", {
                                        name: "customers",
                                        where: [["id_str", "in", state.user_roles.paths]],
                                    }).then(goToNext);
                                } else {
                                    // console.log('b3')
                                    // Get customer by ID string in order to get ID for subscription
                                    let accessPathsAtSiteLevel =
                                        state.user_roles._roles_data.filter(
                                            (r) => Roles.pipesInPath(r.access) === 4
                                        );
                                    let id_str =
                                        accessPathsAtSiteLevel.length === 1
                                            ? accessPathsAtSiteLevel[0].access
                                            : null;
                                    if (id_str) {
                                        // console.log('b3.1')
                                        dispatch("setScopeFromIdStr", {id_str});
                                    } else {
                                        // console.log('b3.2')
                                        dispatch("reset", {name: "customers"});
                                    }
                                }
                            },
                    });
                })
                .catch((e) => {
                    self._vm.$handleError("Could not reach server; please try again", e);
                    router.push({name: "logout", query: {nomessage: 1}});
                });
        }
    },

    /**
     * Generic Vuex action methods, acting as dispatch wrapper for our API.
     * These actions use the stateVariable metadata found in ./settings and fetch object from
     * an API endpoint. The 'name' member dictates what the Vuex state variable to be updated is.
     *
     * Each of these accepts a payload where:
     * - payload.name: Name of variable to update on fetch
     * - payload.callback: Function to execute on *successfully* fetch from API, after state variable updated in Vuex store
     * - payload.id: ID of object to execute on. For `get` method, will fetch list if id is not passed.
     *
     * The generic action methods are:
     * - reset will return the variable to it's default value (and will unbind FS subscriptions if they exist)
     * - get (This is *not quite* synonymous with HTTP GET method, but instead representing
     *     that you'd like to get an object of list of objects. If `where` is supplied,
     *     a POST request will be sent to the API!)
     * - partial_update
     * - update
     * - create
     * - delete
     *
     * See individual actions for additional options.
     * TODO: Add perform_action and more in time. Perhaps unpack custom viewsets, or allow by name.
     * TODO: Allow some to be subscribed directly from FS?
     */
    reset: firestoreAction(
        ({unbindFirestoreRef, commit, dispatch}, payload) => {
            assertAllProperies(payload, ["name"], payload);
            let objMeta = stateVariables[payload.name];
            if (objMeta.directSubscription) {
                assertAllProperies(objMeta, ["default"], payload);
                unbindFirestoreRef(payload.name);
            }
            commit("reset", payload);
            if (payload.callback) {
                payload.callback();
            }
        }
    ),
    /**
     * Don't called directly, instead use `dispatch('get', {name:varName})`.
     * Fetches initial results, call again to fetch next page.
     */
    fsSubscribe: firestoreAction(
        (
            {
                bindFirestoreRef,
                unbindFirestoreRef,
                commit,
                dispatch,
                getters,
                state,
            },
            payload
        ) => {
            let objMeta = stateVariables[payload.name];
            assertAllProperies(payload, ["name"]);
            bindFirestoreRef(
                payload.name,
                payload.value ? payload.value : objMeta.getQuery({state, getters})
            ).then((r) => (state.pagination[payload.name] += 1));
            if (payload.isTopLevel) {
                commit("setLoading", true);
                assertAllProperies(payload, ["value"]);
                payload.value.get().then((obj) => {
                    let fsReference = obj.ref,
                        asData = obj.data();
                    commit("add", {
                        name: getters.topLevel.stateVarName,
                        results: [asData],
                    });
                    dispatch("getMessages");
                    dispatch("getDismissedMessages");
                    Object.keys(stateVariables)
                        .filter((svKey) => stateVariables[svKey].inHierarchy)
                        .forEach((svKey) => {
                            dispatch(`get`, {
                                name: svKey,
                                where: [[getters.topLevel.stateVarName, "==", fsReference]],
                            });
                        });
                    commit("setLoading", false);
                });
            }
        }
    ),
    /**
     * Can be used to fetch results via the REST API or from Firestore directly (if objMeta.directSubscription is true).
     * Subsequent calls will increase the limit via state.pagination
     */
    get({commit, dispatch, getters, state}, payload) {
        assertAllProperies(payload, ["name"], payload);
        if (hasAllProperties(payload, ["where", "id"], payload)) {
            throw "Cannot pass both `where` and `id` to .get action.";
        }
        let objMeta = stateVariables[payload.name];
        if (objMeta.directSubscription) {
            // then subscribe via FS
            dispatch("fsSubscribe", payload);
            // get from API
        } else {
            assertAllProperies(objMeta, ["collection_name"], payload);
            let url = `/${objMeta.collection_name}/`,
                bodyJson = {},
                queryParams = {paginate_by: paginateBy},
                method = "get";
            if (isValue(payload.where)) {
                // Is a query
                method = "post";
                url += "query/";
                bodyJson = {queries: payload.where};
                queryParams["order_field"] = payload.where[0][0];
            } else if (isValue(payload.id)) {
                // Is getting a single obj
                url += `${payload.id}/`;
            } else if (isValue(payload.id_str)) {
                url = "/by-id-string/";
                queryParams = {
                    id_string: payload.id_str,
                };
                payload.name = getters.singleNameByIdString(payload.id_str) + "s";
            } else if (!hasSomeProperties(payload, ["where", "id"])) {
                // Is getting a list
                queryParams = {page: page, paginate_by: paginateBy};
            }

            let page = state.pagination[payload.name] + 1,
                self = this;

            if (!payload.hideFetching) {
                state.fetching[payload.name] = true;
            }
            self._vm.$client[method](url, bodyJson, queryParams)
                .then((response) => {
                    // Commit results, update pagination
                    let name = payload.name;
                    commit(`add`, {
                        results: response.results || [response.result],
                        page: page,
                        name: name,
                    });

                    if (payload.isTopLevel) {
                        dispatch("getMessages");
                        dispatch("getDismissedMessages");

                        stateVariables[payload.name].children.forEach((svKey) => {
                            dispatch(`get`, {
                                name: svKey,
                                where: [
                                    [
                                        getters.topLevel.singularName,
                                        "==",
                                        toRef(
                                            getters.topLevel.object,
                                            getters.topLevel.stateVarName
                                        ),
                                    ],
                                ],
                            });
                        });
                        commit("setLoading", false);
                    }

                    if (payload.callback) {
                        payload.callback(response.results || response.result, {
                            commit,
                            dispatch,
                            getters,
                            state,
                        });
                    }
                })
                .catch((e) =>
                    self._vm.$handleError(`Could not fetch ${payload.name}: ${e}`, e)
                )
                .finally(() => {
                    if (payload.onFinally) {
                        payload.onFinally({commit, dispatch, getters, state});
                    }
                    state.fetching[payload.name] = false;
                });
        }
    },
    modify({dispatch}, payload) {
        dispatch("partial_update", payload);
    },
    partial_update({commit, dispatch, getters, state}, payload) {
        assertAllProperies(payload, ["name", "data"], payload);
        let objMeta = stateVariables[payload.name],
            isObject =
                typeof payload.data === "object" && !Array.isArray(payload.data),
            hasId = isValue(payload.data.id),
            self = this;
        assertAllProperies(objMeta, ["collection_name"], payload);
        if (!isObject || !hasId) {
            throw "Payload must be an object with an `id` attribute";
        }
        // state.fetching[payload.name] = true;
        self._vm.$client
            .patch(`/${objMeta.collection_name}/${payload.data.id}/`, payload.data)
            .then((res) => {
                if (!objMeta.directSubscription) {
                    if (Array.isArray(objMeta.default)) {
                        commit(`add`, {
                            result: res.results || res.result,
                            name: payload.name,
                        });
                    } else {
                        commit(`set`, {
                            value: res.result,
                            name: payload.name,
                        });
                    }
                }
                if (payload.callback) {
                    payload.callback(res.results || res.result, {
                        commit,
                        dispatch,
                        getters,
                        state,
                    });
                }
            })
            .catch((e) =>
                self._vm.$handleError(
                    `Could not do partial update on ${payload.name}: ${e}`,
                    e
                )
            )
            .finally(() => {
                if (payload.onFinally) {
                    payload.onFinally({commit, dispatch, getters, state});
                }
                state.fetching[payload.name] = false;
            });
    },
    update({commit, dispatch, getters, state}, payload) {
        assertAllProperies(payload, ["name", "data"], payload);
        let objMeta = stateVariables[payload.name],
            isObject =
                typeof payload.data === "object" && !Array.isArray(payload.data),
            hasId = isValue(payload.data.id),
            self = this;
        assertAllProperies(objMeta, ["collection_name"], payload);
        if (!isObject || !hasId) {
            throw "Payload must be an object with an `id` attribute";
        }
        // state.fetching[payload.name] = true;
        self._vm.$client
            .put(`/${objMeta.collection_name}/${payload.data.id}/`, payload.data)
            .then((res) => {
                if (!objMeta.directSubscription) {
                    commit(`add`, {result: res.result, name: payload.name});
                }
                if (payload.callback) {
                    payload.callback(res.result, {commit, dispatch, getters, state});
                }
            })
            .catch((e) =>
                self._vm.$handleError(`Could not do update on ${payload.name}: ${e}`, e)
            )
            .finally(() => {
                if (payload.onFinally) {
                    payload.onFinally({commit, dispatch, getters, state});
                }
                state.fetching[payload.name] = false;
            });
    },
    create({commit, dispatch, getters, state}, payload) {
        assertAllProperies(payload, ["name", "data"], payload);
        let objMeta = stateVariables[payload.name],
            isObject =
                typeof payload.data === "object" && !Array.isArray(payload.data),
            hasId = isValue(payload.data.id),
            self = this;
        if (!isObject || hasId) {
            throw "Payload must be an object without an `id` attribute";
        }
        // state.fetching[payload.name] = true;
        self._vm.$client
            .post(`/${objMeta.collection_name}/`, payload.data)
            .then((res) => {
                if (!objMeta.directSubscription) {
                    commit(`add`, {result: res.result, name: payload.name});
                }
                if (payload.callback) {
                    payload.callback(res.result, {commit, dispatch, getters, state});
                }
            })
            .catch((e) =>
                self._vm.$handleError(`Could not create ${payload.name}: ${e}`, e)
            )
            .finally(() => {
                if (payload.onFinally) {
                    payload.onFinally({commit, dispatch, getters, state});
                }
                state.fetching[payload.name] = false;
            });
    },
    delete({commit, dispatch, getters, state}, payload) {
        assertAllProperies(payload, ["name", "data"], payload);
        let objMeta = stateVariables[payload.name],
            isObject =
                typeof payload.data === "object" && !Array.isArray(payload.data),
            hasId = isValue(payload.data.id),
            self = this;
        if (!isObject || !hasId) {
            throw "Payload must be an object with an `id` attribute";
        }
        // state.fetching[payload.name] = true;
        self._vm.$client
            .delete(`/${objMeta.collection_name}/${payload.data.id}/`)
            .then((res) => {
                if (!objMeta.directSubscription) {
                    commit(`remove`, {
                        id: payload.data.id,
                        name: payload.name,
                    });
                }
                if (payload.callback) {
                    payload.callback(res.result, {commit, dispatch, getters, state});
                }
            })
            .catch((e) =>
                self._vm.$handleError(`Could not delete ${payload.name}: ${e}`, e)
            )
            .finally(() => {
                if (payload.onFinally) {
                    payload.onFinally({commit, dispatch, getters, state});
                }
                state.fetching[payload.name] = false;
            });
    },
    /**
     * Firestore DIRECT SUBSCRIPTION actions
     */
    getMessages: firestoreAction(
        (
            {
                bindFirestoreRef,
                unbindFirestoreRef,
                commit,
                state,
                dispatch,
                getters,
            },
            payload
        ) => {
            if (!payload) {
                payload = {};
            }
            let topLevel = getters.topLevel;
            if (payload.unbind || !state.selectedScopeIdStr || !topLevel.object) {
                unbindFirestoreRef("_messages");
                return;
            }
            let page = payload ? payload.page || 1 : 1,
                startingQuery = stateVariables._messages.getQuery({state, getters});
            if (payload && payload.filters) {
                payload.filters.forEach((filter) => {
                    startingQuery = startingQuery.where(...filter);
                });
            }
            let result = startingQuery.limit(10 * page);
            bindFirestoreRef("_messages", result, {maxRefDepth: 0}).then(() =>
                commit("loadingMessages", false)
            );
        }
    ),
    getDismissedMessages: firestoreAction(
        (
            {
                bindFirestoreRef,
                unbindFirestoreRef,
                commit,
                state,
                dispatch,
                getters,
            },
            payload
        ) => {
            if (!payload) {
                payload = {};
            }
            commit("loadingDismissedMessages", true);
            let topLevel = getters.topLevel;
            if (payload.unbind || !state.selectedScopeIdStr || !topLevel.object) {
                unbindFirestoreRef("_dismissedMessages");
                commit("loadingDismissedMessages", false);
                return;
            }
            let page = payload ? payload.page || 1 : 1;
            let startingQuery = stateVariables._dismissedMessages.getQuery({
                state,
                getters,
            });
            if (payload && payload.filters) {
                payload.filters.forEach((filter) => {
                    startingQuery = startingQuery.where(...filter);
                });
            }
            let result = startingQuery.limit(10 * page);
            bindFirestoreRef("_dismissedMessages", result, {maxRefDepth: 0}).then(
                (res) => {
                    commit("loadingDismissedMessages", false);
                }
            );
        }
    ),
    unsubscribeFromHierarchy({commit, dispatch, getters, state}, ignoreKeys) {
        if (!ignoreKeys) {
            ignoreKeys = [];
        }
        Object.keys(stateVariables)
            .filter((varKey) => !ignoreKeys.includes(varKey))
            .forEach((key) => {
                dispatch("reset", {name: key});
            });
    },
    unsubscribeFromFirestore({commit, dispatch, getters, state}, ignoreKeys) {
        Object.keys(stateVariables).forEach((key) => {
            dispatch("reset", {name: key});
        });
    },
    saveInstallData({commit}, installData) {
        commit("saveInstallData", installData);
    },
};
export default actions;
