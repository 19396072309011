<template>
  <div class="sensor-detail-wrapper">
    <table class="table is-striped is-bordered is-fullwidth">
      <tbody>
        <tr v-for="(value, name) in info" :key="name">
          <td class="has-text-weight-bold">{{ name }}</td>
          <td v-if="name === 'Bundle' && $store.state.user_roles.is_root">
            <b-icon icon="spinner" class="fa-spin" v-if="bundleLoading" />
            <router-link
              v-else-if="bundle.id"
              :to="{
                name: 'administration:shipments:view',
                params: { id: bundle.id },
              }"
            >
              {{ value }}
            </router-link>
            <i v-else>Error loading</i>
          </td>
           <td v-else-if="name === 'Shipped to'">
            <router-link
              v-if="value"
              :to="{
                name: 'administration:view-subcustomer',
                params: { id: value.id },
              }"
            >
              {{ value.$displayString }}
            </router-link>
            <i v-else>n/a</i>
          </td>
          <td v-else-if="name === 'Gateways'">
            <p v-if="value && value.length" class="mb-0">
              <b-button
                v-for="gw in value"
                :key="gw.serial"
                tag="router-link"
                size="is-small"
                rounded
                expanded
                type="is-link is-outlined"
                :to="{
                  name: 'devices:gateway-detail',
                  params: { siteId: $route.params.siteId, serial: gw.serial },
                }"
              >
                {{ gw.serial }}
              </b-button>
            </p>
            <span v-else>None found</span>
          </td>
          <td v-else-if="name === 'Calibration state' && position">
            {{ capitalizeFirstLetter(position.$calibrationState.replace('recalibration', 'training mode')) }}
            <in-training-mode
              v-if="position && position.$isInTrainingMode"
              :area="position.$area"
              message="This sensor is currently in training mode"
            />
          </td>
          <td
            v-else
            :class="{
              'has-text-danger':
                name === 'Last seen' &&
                ((position && millisecondsSinceLastSeen > 3600000) ||
                  value === 'Unknown'),
            }"
          >
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
    <slot></slot>
  </div>
</template>

<script>
import shortHumanizer from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import intervalFunctionMixin from "../mixins/intervalFunctionMixin";
import { get } from "lodash";

export default {
  name: "SensorDetailTable",
  mixins: [intervalFunctionMixin],
  data() {
    return {
      bundle: { name: "Loading..." },
      bundleLoading: false,
      millisecondsSinceLastSeen: null,
      subcustomer: null,
    };
  },
  props: {
    sensor: {
      type: Object,
      required: true,
    },
    position: {
      type: Object,
      required: false,
    },
  },
  computed: {
    area() {
      return {};
    },
    lastSeen() {
      return this.position &&
        this.position.telemetry &&
        this.position.telemetry.status &&
        this.position.telemetry.status.timestamp
        ? this.position.telemetry.status.timestamp
        : null;
    },
    subcustomerRef() {
      return get(this.bundle, 'bundle_state.subcustomer', null);
    },
    info() {
      let _ = {};
      _["Bundle"] = this.bundle.name;
      _["Shipped to"] = this.subcustomer;
      _["Status"] = this.position ? 'Installed' : this.bundle ? "Un-installed / Bundled" : "Un-installed / Un-bundled";
      if (this.position) {
        let voltage = get(this.position, "telemetry.status.vbatt", null),
          firmwareV = get(this.position, "telemetry.version.firmware.app", null),
          hardwareProductID = get(
            this.position,
            "telemetry.version.hardware.product_id",
            undefined
          ),
          hardwarePWA4 = get(
            this.position,
            "telemetry.version.hardware.pwa4",
            undefined
          ),
          hardwarePWB4 = get(
            this.position,
            "telemetry.version.hardware.pwb4",
            undefined
          );
        _["Battery level"] = `${this.position.$batteryLevel}%`;
        _["Battery voltage"] = voltage ? voltage.toFixed(2) + "v" : "Unknown";
        _["Calibration state"] = this.capitalizeFirstLetter(
          this.position ? this.position.$calibrationState || "unknown" : "unknown"
        );
        if (firmwareV) {
          _["Firmware"] = firmwareV;
        }
        let sensorId = this.sensor ? this.sensor.id : "!!!";
        _["Gateways"] = this.$store.getters
          .gatewaysForSensorSerial(this.sensor.serial)
          .filter(
            (gw) =>
              gw.connect_weights &&
              gw.connect_weights.filter(
                (cw) => cw.sensor.id === sensorId && cw.weight > 0
              ).length > 0
          );
        if (
          typeof hardwareProductID !== "undefined" &&
          this.$store.state.user_roles.is_root
        ) {
          _["Hardware (product ID)"] = hardwareProductID;
        }
        if (typeof hardwarePWA4 !== "undefined") {
          _["Hardware (PWA4)"] = hardwarePWA4;
        }
        if (typeof hardwarePWB4 !== "undefined") {
          _["Hardware (PWB4)"] = hardwarePWB4;
        }
        _["Last seen"] =
          this.millisecondsSinceLastSeen !== null
            ? shortHumanizer(this.millisecondsSinceLastSeen) + " ago"
            : "Unknown";
        _["RSSI (raw)"] = this.position ? this.position.$rssi : null;
      }

      // filter out where connect_weight is 0 for gw <=> sensor
      _["MAC address"] = get(this.sensor, "hwaddr", "Unavailable");
      _["Serial"] = this.sensor.serial;
      function sortObject(obj) {
          return Object.keys(obj).sort().reduce(function (result, key) {
              result[key] = obj[key];
              return result;
          }, {});
      }
      return sortObject(_);
    },
  },
  mounted() {
    this.getBundle();
  },
  methods: {
    onPoll() {
      let now = new Date();
      let timeSinceSeen = this.lastSeen ? now - this.toDate(this.lastSeen) : null;
      this.millisecondsSinceLastSeen = this.lastSeen
        ? parseInt(timeSinceSeen / 1000) * 1000
        : null;
    },
    getBundle() {
      let self = this;
      if (!self.sensor) {
        self.bundle = {};
        return;
      }
      self.bundleLoading = true;
      this.$dataClasses.Bundle.query([
        [
          "devices",
          "array_contains",
          self.toFSRef(self.sensor._reference, "sensors"),
        ],
      ])
        .then((results) => {
          if (results && results.length) {
            self.bundle = results[0];
          } else {
            self.bundle = {};
          }
        })
        .catch((e) => self.$handleError(e, e))
        .finally(() => {
          self.bundleLoading = false;
        });
    },
  },
  watch: {
    subcustomerRef: {
      immediate: true,
      handler(val) {
        if (val) {
          let _id = this.toId(val);
          if (
            this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            )
          ) {
            this.subcustomer = this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            );
          } else {
            this.$dataClasses.SubCustomer.fetchById(this.toId(val))
              .then((subcustomer) => (this.subcustomer = subcustomer))
              .catch((e) => (this.subcustomer = null));
          }
        }
      },
    },
  }
};
</script>

<style scoped>
.sensor-detail-wrapper {
  width: 100%;
  margin: 0 auto;
}
</style>
