/**
 *
 * @param val {numer}
 * @param minimum {number}
 * @param maximum {number}
 * @param toDigits {number}
 * @returns {number}
 */
export default function bounded0To100({val, minimum=0, maximum=100, toDigits=1}) {
  let outputVal;
  if (val > maximum) {
    outputVal = maximum;
  } else if (val < minimum) {
    outputVal = minimum;
  } else {
    outputVal = val || minimum;
  }
  return Number(outputVal.toFixed(toDigits));
};
