<template>
  <modal-with-form
    :allow-delete="$store.state.user_roles && $store.state.user_roles.is_admin"
    :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
    :value="isActive"
    @input="activeChanged($event)"
    :can-submit="isValid"
    :full-page="false"
    :id="id"
    :inputs="inputs"
    :object-data="objectData"
    :class="stateVarName + '-modal'"
    obj-display-name="Point-of-use"
    :state-var-name="stateVarName"
    @closed="isActive = false"
    @saved="saved($event)"
    @deleted="deleted($event)"
  >
    <source-form
      v-model="inputs"
      :building-not-editable="buildingNotEditable"
      :parent="parent"
      :id="id"
      @validates="validChanged($event)"
    ></source-form>
  </modal-with-form>
</template>

<script>
import SourceForm from "./SourceForm";
import ModalWithForm from "../generic/ModalWithForm";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import { cloneDeep } from "lodash";

export default {
  name: "AddSourceModal",
  components: { SourceForm, ModalWithForm },
  computed: {
    initial() {
      return this.getInitial();
    },
    objectData() {
      return this.id
        ? Object.assign(
            this.initial,
            getObjectInArrayById(this.$store.state.sources, this.id, {})
          )
        : this.initial;
    },
  },
  data() {
    let initial = {
        parent: this.parent ? this.toFSRef(this.parent, "areas") : null,
        room: this.room ? this.room : null,
        building: this.building
          ? this.building.$FSRef
          : (this.room && this.room.$FSRef)
          ? this.room.building.$FSRef
          : null,
      },
      existing = getObjectInArrayById(this.$store.state.sources, this.id, {}),
      theInputs = this.id ? existing : initial;
    return {
      inputs: theInputs,
      isValid: false,
      isActive: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    /**
     * parent == area
     */
    parent: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    building: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
    buildingNotEditable: {
      type: Boolean,
      default: false,
    },
    room: {
      type: Object,
      required: false,
      default: () => {
        return null;
      },
    },
    stateVarName: {
      type: String,
      default: "sources",
    },
  },
  methods: {
    getInitial() {
      return {
        parent: this.parent ? this.toFSRef(this.parent, "areas") : null,
        room: this.room ? this.room : null,
        building: this.building
          ? this.toFSRef(this.building, "buildings")
          : this.room
          ? this.room.building
          : null,
      };
    },
    validChanged(valid) {
      this.isValid = valid;
    },
    activeChanged(val) {
      this.$emit("input", this.isActive);
      if (this.$route.query.next) {
        this.$router.push(this.$route.query.next);
      }
    },
    saved(val) {
      this.$emit("saved", val);
      if (!this.id) {
        this.inputs = cloneDeep(this.initial);
      }
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
  watch: {
    building: {
      handler(val) {
        this.$set(this.inputs, 'building', val ? this.toFSRef(val) : null);
      }
    },
    room: {
      handler(val) {
        this.$set(this.inputs, 'room', val ? this.toFSRef(val) : null);
      }
    },
    isActive: {
      handler(val, oldVal) {
        this.activeChanged(val);
      },
    },
    value: {
      handler(val, oldVal) {
        this.isActive = val;
      },
    },
  },
};
</script>

<style scoped></style>
