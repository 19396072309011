<template>
  <modal-with-form
      prevent-cancel-if-loading
      :allow-delete="$store.state.user_roles && $store.state.user_roles.is_admin"
      :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
      :value="isActive"
      @input="activeChanged($event)"
      :can-submit="isValid"
      :full-page="false"
      :id="id"
      :inputs="inputs"
      :class="stateVarName + '-modal'"
      obj-display-name="Area"
      :state-var-name="stateVarName"
      @closed="isActive = false"
      @saved="saved($event)"
      @deleted="deleted($event)"
  >
    <building-form
        @close="$emit('close')"
        v-model="inputs"
        :parent="parent"
        :id="id"
        @validates="validChanged($event)"
    ></building-form>
  </modal-with-form>
</template>

<script>
import BuildingForm from "./BuildingForm";
import ModalWithForm from "../generic/ModalWithForm";
import {cloneDeep} from "lodash";
import {getObjectInArrayById} from "../../../scripts/filterHelpers";

export default {
  name: "BuildingModal",
  components: {BuildingForm, ModalWithForm},
  computed: {
    objectData() {
      let self = this,
          parent = self.parent ? self.parent.$FSRef : null;
      return this.id
          ? Object.assign(
              getObjectInArrayById(this.$store.state.buildings, this.id),
              {parent}
          )
          : {parent};
    },
  },
  data() {
    let self = this,
        parent = self.parent ? self.toFSRef(self.parent) : null;
    return {
      inputs: this.id
          ? Object.assign(
              getObjectInArrayById(this.$store.state.buildings, this.id),
              {parent: parent}
          )
          : {parent: parent},
      isValid: false,
      isActive: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    /**
     * site
     */
    parent: {
      type: Object,
      required: false,
    },
    stateVarName: {
      type: String,
      default: "buildings",
    },
  },
  methods: {
    validChanged(valid) {
      this.isValid = valid;
    },
    activeChanged(val) {
      this.$emit("input", this.isActive);
      if (this.$route.query.next) {
        this.$router.push(this.$route.query.next);
      }
    },
    saved(val) {
      let self = this;
      if (!self.id) {
        self.inputs = {
          parent: cloneDeep(self.toFSRef(self.parent, "sites")),
        };
      }
      self.$store.dispatch("get", {
        name: "buildings",
        id: val.id,
        hideFetching: true,
      }).then(() => {
        self.$store.dispatch("get", {
          name: "areas",
          id: val.area.id,
          hideFetching: true,
          callback: (newArea) => {
            self.$emit("save", val);
            self.$store.dispatch("get", {
              name: "sources",
              where: [["area", "==", self.toFSRef(newArea, "areas")]],
            });
          },
        });
      });
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
  watch: {
    isActive: {
      handler(val) {
        this.activeChanged(val);
      },
    },
    value: {
      handler(val) {
        this.isActive = val;
      },
    },
  },
};
</script>

<style scoped></style>
