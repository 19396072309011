import { render, staticRenderFns } from "./RoleTags.vue?vue&type=template&id=9255c43e&scoped=true&"
import script from "./RoleTags.vue?vue&type=script&lang=js&"
export * from "./RoleTags.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9255c43e",
  null
  
)

export default component.exports