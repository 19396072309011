import humanizeDuration from "humanize-duration";

const shortHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  maxDecimalPoints: 0,
});

export default shortHumanizer;

export const readableHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "years",
      mo: () => "months",
      w: () => "weeks",
      d: () => "days",
      h: () => "hours",
      m: () => "minutes",
      s: () => "seconds",
      ms: () => "milliseconds",
    },
  },
  maxDecimalPoints: 0,
});
