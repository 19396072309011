var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{staticClass:"columns"},[_c('div',{class:{
        'doink box column is-12 is-5-desktop is-4-fullhd has-border-success': true,
        'is-hidden-mobile is-hidden-tablet-only': _vm.selectedAlarmId,
      }},[_c('div',[_c('div',{staticClass:"has-text-centered subtitle m-3"},[_vm._v(" "+_vm._s(_vm.title || `Alarms: ${_vm.capitalizeFirstLetter(_vm.category)}`)+" ")]),_c('div',{staticClass:"has-text-centered"},[_c('b-icon',{class:{ 'fa-spin': _vm.refreshing, 'pointer mb-3': true },attrs:{"icon":"sync","size":"is-small"},nativeOn:{"click":function($event){return _vm.refreshExisting.apply(null, arguments)}}})],1),_c('div',{staticClass:"scroller",attrs:{"id":"scroller-messages"}},[(_vm.selected.length)?_c('b-dropdown',{staticClass:"mb-2",attrs:{"aria-role":"list","expanded":""},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return [_c('b-button',{attrs:{"expanded":"","label":_vm.selected.length +
                  ' message' +
                  (_vm.selected.length === 1 ? '' : 's') +
                  ' selected',"type":"is-primary","icon-right":active ? 'chevron-up' : 'chevron-down'}})]}}],null,false,1603978551)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.dismissSelected}},[_vm._v(" Dismiss selected ")])],1):_vm._e(),_c('transition-group',{attrs:{"name":"message","tag":"p"}},_vm._l((_vm.alarms),function(alarm){return _c('div',{key:alarm.id},[_c('user-message-box',{ref:'alarm-' + alarm.id,refInFor:true,attrs:{"alarm-object":alarm,"id":'alarm-' + alarm.id,"selected":_vm.selected.includes(alarm.id)},on:{"click":function($event){return _vm.selectAlarm(alarm)}}})],1)}),0),(_vm.loading && (!_vm.alarms || !_vm.alarms.length))?_c('loading-card',{attrs:{"sections":['body', 'footer']}}):_vm._e(),(!_vm.loading && !_vm.refreshing && (!_vm.alarms || !_vm.alarms.length))?_c('p',{staticClass:"has-text-centered mt-6"},[_vm._v(" No alarms found. ")]):_vm._e()],1),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","loading":_vm.loading,"disabled":!_vm.alarms.length},on:{"click":function($event){_vm.fetchAlarms(() => {})}}},[_vm._v(" Load more ")])],1)])]),_c('div',{staticClass:"column is-7-desktop is-8-fullhd"},[(_vm.selectedAlarm)?_c('user-message-detail-inner',{attrs:{"alarm-object":_vm.selectedAlarm,"on-close-function":() => {
            _vm.selectedAlarmId = null;
          }}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }