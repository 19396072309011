var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.site)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_c('site-display',{attrs:{"value":_vm.site}})],1)]),_c('div',{staticClass:"card-content"},[(!_vm.site.timezone)?_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('b-icon',{attrs:{"type":"is-danger","icon":"exclamation-circle","size":"is-large"}})],1),_c('div',{staticClass:"media-content"},[_vm._v(" You must "),_c('router-link',{attrs:{"to":{
            name: 'administration:view-site',
            params: { id: _vm.site.id },
            query: { edit: null },
          }}},[_vm._v(" set a timezone for this site ")]),_vm._v(" before you may edit schedules. ")],1)]):_vm._e(),(_vm.loadingAll)?_c('b-loading',{attrs:{"active":"","is-full-page":false}}):(_vm.site.timezone)?_c('div',[_c('div',{staticClass:"has-text-justified has-text-right-tablet has-text-right-desktop mb-2",staticStyle:{"zoom":"85%"}},[_c('b-field',{attrs:{"size":"is-small","label":"Filter by area"}},[_c('b-select',{model:{value:(_vm.areaId),callback:function ($$v) {_vm.areaId=$$v},expression:"areaId"}},[_c('option',{domProps:{"value":null}},[_vm._v("None")]),_vm._l((_vm.buildings),function(building){return _c('option',{key:building.id,domProps:{"value":building.$area.id}},[_vm._v(_vm._s(building.$displayString))])})],2)],1)],1),_c('alarm-schedule-table',{attrs:{"internal-area-id":_vm.areaId,"value":_vm.allAlarmSchedules,"site":_vm.site},on:{"remove":function($event){_vm.allAlarmSchedules = $event.id
            ? _vm.allAlarmSchedules.filter((a) => a.id !== $event.id)
            : _vm.allAlarmSchedules.filter((a) => !!a.id)},"update:value":function($event){_vm.allAlarmSchedules=$event}}}),_c('div',{staticClass:"mb-6"},[(_vm.$store.state.user_roles.is_admin)?_c('b-button',{staticClass:"is-pulled-right mt-3",attrs:{"disabled":_vm.allAlarmSchedules.filter((a) => !a.id).length >= 1,"icon-left":_vm.iconFor('create')},on:{"click":function($event){_vm.allAlarmSchedules.push(
              new _vm.$dataClasses.AlarmSchedule({
                is_active: true,
                name: '',
                criteria: {},
                schedule: {
                  description: '',
                  times: [],
                },
              })
            )}}},[_vm._v(" Add new ")]):_vm._e()],1)],1):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }