import FirestoreDataClass from "./_baseClasses";
import router from "../router";

export default class Probe extends FirestoreDataClass {
  static collectionName = "probes";

  get $qrCodeUrl() {
    if (!this.serial) {
      return "";
    }
    return this.$client.basePath + "/devices/qr_code/?serial=" + this.serial;
  }

  get $deviceType() {
    return "probe";
  }

  $goToAttachProbe(building) {
    router.push({
      name: "attach-probe",
      query: {
        serial: this.serial,
        building: building ? building.id : null,
        sm1: "select",
      },
    });
  }
}
