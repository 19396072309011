import { render, staticRenderFns } from "./SensorDetailForClip.vue?vue&type=template&id=6097007d&scoped=true&"
import script from "./SensorDetailForClip.vue?vue&type=script&lang=js&"
export * from "./SensorDetailForClip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6097007d",
  null
  
)

export default component.exports