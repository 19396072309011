<template>
  <div :class="classes">
    <div class="is-pulled-left" v-if="refreshable">
      <b-icon
        :class="{ 'fa-spin': loading, pointer: true }"
        icon="sync"
        size="is-small"
        @click.native.prevent="$emit('refresh-clicked')"
      />
    </div>
    <div class="buttons is-pulled-right">
      <b-button
        :disabled="page <= 1"
        :type="page === 1 ? 'is-primary' : null"
        @click="$emit('input', { page: 1 })"
        >1</b-button
      >
      <b-button
        :disabled="page !== 3"
        v-if="page > 2"
        @click="$emit('input', { page: 2 })"
        >{{ page === 3 ? "2" : "..." }}</b-button
      >
      <b-button v-if="page > 1" disabled type="is-primary">{{ page }}</b-button>
      <b-button
        v-if="hasMoreResults && !loading"
        @click="$emit('input', { page: page + 1 })"
        >{{ page + 1 }}</b-button
      >
    </div>
    <p class="is-size-7" v-if="hintText">
      {{ hintText }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PaginationControls",
  props: {
    classes: {
      type: String,
      default: "mt-3 mb-3",
    },
    hintText: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    hasMoreResults: {
      type: Boolean,
      default: false,
    },
    refreshable: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped></style>
