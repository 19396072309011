<template>
  <div class="level mt-1 mb-1">
    <div class="level-left">
      <div class="level-item" v-if="showInput">
        <b-input
          ref="input"
          v-model="q"
          icon-right="times"
          icon-right-clickable
          :placeholder="placeholder"
          @keyup.native.enter="getResults()"
          @icon-right-click="reset()"
        />
      </div>
    </div>
    <slot name="default" />
    <div class="level-right">
      <div class="level-item" v-if="showInput">
        <b-button
          :disabled="q === lastSubmittedQ"
          :loading="loading"
          @click="getResults()"
          >Search
        </b-button>
      </div>
      <slot name="pagination">
        <div class="well" v-if="paginated">
          <div class="level-item">
            <pagination-controls
              classes="mt-0"
              :has-more-results="hasMoreResults"
              :loading="loading"
              :page="page"
              @input="page = $event.page"
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import { paginateBy } from "../../store/shared";
import PaginationControls from "../../components/Pagination/PaginationControls";

export default {
  name: "ListOrQueryResultInput",
  components: { PaginationControls },
  data() {
    return {
      lastSubmittedQ: "",
      loading: false,
      page: 1,
      placeholder: "Enter a search term here",
      q: "",
      results: [],
    };
  },
  computed: {
    hasMoreResults() {
      return !this.outOfResults;
    },
    outOfResults() {
      return this.results.length < this.paginateBy;
    },
    urlParams() {
      return {
        page: this.page,
        paginate_by: this.paginateBy,
      };
    },
  },
  mounted() {
    if (this.showInput) {
      this.$refs.input.focus();
    }
    this.getResults();
  },
  props: {
    dataClass: {
      required: true,
    },
    baseQueries: {
      type: Array,
      default: function () {
        return [];
      },
    },
    paginateBy: {
      type: Number,
      default: paginateBy,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    queryField: {
      type: String,
      default: "lowercase_name",
    },
    showInput: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getResults() {
      let self = this;
      self.loading = true;
      if (self.q !== self.lastSubmittedQ) {
        self.page = 1;
      }
      if (self.q.length || self.baseQueries.length) {
        let q = self.queryField.includes("lowercase")
          ? self.q.toLowerCase()
          : self.q;
        self.dataClass
          .query(
            [
              ...self.baseQueries,
              [self.queryField, ">=", q],
              [self.queryField, "<=", q + "~~~"],
            ],
            { ...self.urlParams, order_field: self.queryField }
          )
          .then((results) => {
            self.updated(results);
            self.lastSubmittedQ = q;
          })
          .finally(() => {
            self.loading = false;
          });
      } else {
        self.dataClass
          .list({ ...self.urlParams, order_field: self.queryField })
          .then((results) => {
            self.updated(results);
          })
          .finally(() => {
            self.loading = false;
          });
      }
    },
    reset() {
      this.lastSubmittedQ = "";
      this.page = 1;
      this.q = "";
      this.getResults();
    },
    updated(results) {
      this.results = results;
      this.$emit("updated", results);
    },
  },
  watch: {
    page: {
      handler() {
        this.getResults();
      },
    },
    loading: {
      handler(val) {
        this.$emit("loading", val);
      },
    },
  },
};
</script>

<style scoped>
.padme {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
