export function typeGivenIDString(idString) {
  let indexFor = idString.split("|").length - 2;
  return ["root", "customer", "subcustomer", "site", "area", "source"][
    indexFor
  ];
}

/**
 * Tests that path_to_test is at the same node or is a child node of the customer hierarchy
 * @param base_path
 * @param path_to_test
 * @returns {boolean|*}
 * @example
 * > is_equal_or_lower_in_hierarchy('|root|Customer A', '|root')
 * false
 * > is_equal_or_lower_in_hierarchy('|root|Customer A', '|root|Customer A')
 * true
 * > * > is_equal_or_lower_in_hierarchy('|root|Customer A', '|root|Customer A|Marketing|Building 4')
 * true
 */
export function is_equal_or_lower_in_hierarchy(base_path, path_to_test) {
  return (
    path_to_test &&
    base_path &&
    (path_to_test === base_path || path_to_test.startsWith(`${base_path}|`))
  );
}

/**
 * Remove object from array if it has supplied ID.
 * @param array
 * @param idString
 * @returns {{removed: [*], results: [*]}}
 */
export function filterOutObjectWithIDFromArray(array, idString) {
  return {
    results: array.filter((obj) => obj.id !== idString),
    removed: array.filter((obj) => obj.id === idString),
  };
}

/**
 * Remove object from array if obj.parent.id matches given id.
 * Can operate on FS references or nested data.
 * @param array
 * @param parentIdString
 * @returns {{removed: [*], results: [*]}}
 */
export function filterOutObjectWithParentIdFromArray(array, parentIdString) {
  return {
    results: array.filter((obj) => obj.parent.id !== parentIdString),
    removed: array.filter((obj) => obj.parent.id === parentIdString),
  };
}
