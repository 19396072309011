import store from "../store";
import { get } from "lodash";

import Roles from "../components/Roles";


function getInitialUserData() {
  return {
    email: "",
    displayName: "",
    phone: "",
    sms_enabled: false,
  };
}

const userDataMixin = {
  data() {
    return {
      loading: false,
      userAccount: {},
      userData: getInitialUserData(),
      userRoles: new Roles([], false),
    };
  },
  props: {
    userId: {
      type: String,
      required: false,
    },
  },
  computed: {
    additional() {
      return get(this.userAccount, "_additional", {});
    },
    currentUser() {
      return store.state.user;
    },
    currentUserAccount() {
      return store.state.user_account;
    },
    currentUserRoles() {
      return store.state.user_roles;
    },
    allowEditOrDelete() {
      return store.state.user_roles.canEditPath(this.userAccount.parent_path);
    },
    accountCreatedAt() {
      return this.userAccount
        ? get(this.userAccount, "_about.created_at", null)
        : null;
    },
  },
  beforeMount() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      if (!this.userId) {
        return;
      }
      let self = this;
      self.loading = true;
      if (self.userId === self.currentUser.uid) {
        let additional = get(self.currentUserAccount, "_additional", {});
        for (const key of Object.keys(self.userData)) {
          self.userData[key] = get(
            self.currentUserAccount,
            key,
            get(additional, key, null)
          );
          self.userAccount = self.currentUserAccount;
          self.userRoles = self.$store.state.user_roles;
        }
        self.loading = false;
      } else {
        this.$dataClasses.UserAccount.fetchById(self.userId)
          .then((result) => {
            let additionalUserData = get(result, "_additional", {});
            self.userAccount = result;
            self.userData = {};
            self.userRoles = new Roles(result.roles);
            for (const key of Object.keys(getInitialUserData())) {
              self.userData[key] = get(
                self.user,
                key,
                get(additionalUserData, key, null)
              );
            }
          })
          .catch((e) => {
            self.$handleError(e, e);
          })
          .finally(() => {
            self.loading = false;
          });
      }
    },
    updateHook() {
      alert(1);
    },
  },
  watch: {
    userId: {
      handler(val) {
        this.getUserData();
      },
    },
  },
};

export default userDataMixin;
