<template>
  <option-driven-radio
    new-lines
    :options="options"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import OptionDrivenRadio from "./OptionDrivenRadio";

export const options = [
  {
    value: "recirculating_loop",
    label: "This is located within a recirculation loop",
  },
  { value: "riser", label: "This is located on a riser supply line" },
  {
    value: "branch",
    label: "This is located upstream of one or more points-of-use",
  },
];

export default {
  name: "PlumbingStyleRadio",
  components: { OptionDrivenRadio },
  props: {
    value: {
      type: String,
      validator(val) {
        return !val || options.map((o) => o.value).includes(val);
      },
    },
  },
  data() {
    return {
      options,
    };
  },
};
</script>

<style scoped></style>
