import {get, toString} from 'lodash';

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;

export default function alphanumericSort(a, b) {
    a = toString(a);
    b = toString(b);
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
        var aN = parseInt(a.replace(reN, ""), 10);
        var bN = parseInt(b.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
        return aA > bA ? 1 : -1;
    }
}

/**
 * To be used via .sort, not via lodash methods!
 * @example
 * var sortedArray = myArray.sort(alphanumericSortByProperty('someValueKey'))
 * @param propertyName {String}
 * @returns {(function(*, *): (number))|*}
 */
export function alphanumericSortByProperty(propertyName) {
    return function alphanumericSortProp(a, b) {
        var propA = toString(get(a, propertyName, '')),
            propB = toString(get(b, propertyName, '')),
            aA = propA.replace(reA, "").toLowerCase(),
            bA = propB.replace(reA, "").toLowerCase();
        if (aA === bA) {
            var aN = parseInt(propA.replace(reN, ""), 10);
            var bN = parseInt(propB.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    };
}