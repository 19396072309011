<template>
  <transition name="slide">
    <p v-if="loading" class="has-text-centered">
      <b-icon icon="sync" class="fa-spin mt-6" />
    </p>
  </transition>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
