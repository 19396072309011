<template>
  <b-message :class="{'is-source_selection':true,'faded':loading}"
             :closable="closable && !alarm.classified_as.flowType && !alarm.classified_as.source && !loading"
             @close="submitClassification({flow_type:'unknown'})"
             :title="alarm.$displayString">
    <div class="columns">
      <div class="column is-3">
        <ul>
          <li >Length of flow: <span :class="{'has-text-danger':alarm.$duration>58}">{{ alarm.$duration }} second{{ alarm.$duration === 1 ? '' : 's' }}</span></li>
          <li>Started: {{ timeConversion(secondsSinceStart * 1000) }} ago</li>
          <li>Ended: {{ timeConversion(secondsSinceEnd * 1000) }} ago</li>
        </ul>
      </div>
      <div class="column is-9 has-text-centered" v-if="viewAsTable">
        <p>Sensors involved</p>
        <table class="table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Room</th>
              <th>Unit</th>
              <th>Floor</th>
              <th>Pipe loc.</th>
              <th>Involved in alarm</th>
              <th>Amplitude</th>
              <th>Amplitude ratio</th>
            </tr>
          </thead>
          <tbody>
            <local-scope
                v-for="position in sortedAvailablePositionSerials.map(ser => positions.find(pos=>pos.serial===ser)).filter(p=>!!p)"
                :key="position.serial"
                :is-included-in-alarm="includedInAlarm(position.serial)"
                :tooltip="`${position.serial} is ${includedInAlarm(position.serial) ? '':'not '}included in this alarm`"
                :alarm-sensor-data="alarm.sensorData(position.serial)"
                :amplitude="(alarm.sensorData(position.serial) || {}).amplitude || null"
                :amplitudeRatio="(alarm.sensorData(position.serial) || {}).amplitude_ratio || (alarm.sensorData(position.serial) || {}).anmplitude_ratio || null"
                v-slot="{amplitude, amplitudeRatio, alarmSensorData, isIncludedInAlarm, tooltip}">
              <tr>
                <td>
                  {{ position.serial }}
                  <b-icon icon="edit" type="is-primary" class="is-outlined pointer"
                          @click.native="position.$goToSensorDetail()"/>
                </td>
                <td>
                  <room-display
                      v-if="position.$room"
                      classes="is-inline-block"
                      :show-icon="false"
                      :value="position.$room"
                      :show-link-icon="false"
                      :show-floor="false"
                      :show-unit="false"/>
                </td>
                <td>
                  {{position.get('$room.unit', '')}}
                </td>
                <td>
                  {{position.get('$room.floor', '')}}
                </td>
                <td>
                  <pipe-location-display
                      v-if="position.$pipeLocation"
                      :show-icon="false"
                      :value="position.$pipeLocation"
                      classes="is-inline-block"
                      :show-link-icon="false"/>
                </td>
                <td>
                  <b-icon
                      v-if="isIncludedInAlarm"
                      icon="check"
                      type="is-success"
                  />
                  <b-icon
                      v-else
                      icon="times-circle"
                      type="is-danger"
                      class="is-outlined"
                  />
                </td>
                <td>
                  {{ amplitude ? amplitude.toFixed(2) : ''}}
                </td>
                <td>
                  {{ amplitudeRatio ? amplitudeRatio.toFixed(2) : '' }}
                </td>
              </tr>
            </local-scope>
          </tbody>
        </table>

      </div>
      <div class="column is-9 has-text-centered" v-else>
        <local-scope
            v-for="position in sortedAvailablePositionSerials.map(ser => positions.find(pos=>pos.serial===ser)).filter(p=>!!p)"
            :key="position.serial"
            :is-included-in-alarm="includedInAlarm(position.serial)"
            :tooltip="`${position.serial} is ${includedInAlarm(position.serial) ? '':'not '}included in this alarm`"
            :alarm-sensor-data="alarm.sensorData(position.serial)"
            :amplitudeRatio="(alarm.sensorData(position.serial) || {}).amplitude_ratio || (alarm.sensorData(position.serial) || {}).anmplitude_ratio || null"
            v-slot="{amplitudeRatio, alarmSensorData, isIncludedInAlarm, tooltip}">
          <live-sensor-display
              :tooltip="tooltip"
              :is-inactive="!isIncludedInAlarm"
              zoom="90%"
              size="small"
              :show-battery="false"
              :show-signal-indicator="false"
              :position="position"
              show-location
          >
            <template #body>
              <span style="top:23px;left:24px;position:absolute;z-index:10" v-if="amplitudeRatio">
                <b-tooltip
                    :label="`Amplitude ratio: ${amplitudeRatio.toFixed(2)} (${((amplitudeRatio/maxAmplitudeRatio) * 100).toFixed(1)}%)`"
                    class="pointer">
                  <vu-meter
                      :width="22"
                      :height="84"
                      :val="amplitudeRatio"
                  />
                </b-tooltip>
              </span>
              <span>
                <b-dropdown>
                  <template #trigger>
                    <b-button
                        class="mt-1"
                        title="View location data"
                        icon-left="map-pin"
                        size="is-small"
                        type="is-info is-outlined"
                        @mouseenter.native="showTooltipForSerial=position.serial"
                        @mouseleave.native="showTooltipForSerial=null"
                    />
                  </template>
                  <b-dropdown-item
                      :focusable="false"
                      :has-link="false"
                      :paddingless="false"
                      style="cursor:default"
                  >
                    <sensor-detail-for-clip
                        :position="position"
                        :max-amplitude-ratio="maxAmplitudeRatio"
                        :amplitude-ratio="amplitudeRatio"
                        :included-in-alarm="includedInAlarm(position.serial)"
                        @threshold-edit-clicked="$emit('threshold-edit-clicked', $event)"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </live-sensor-display>
        </local-scope>
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-centered">
        <p class="has-text-weight-bold" v-if="!alarm.classified_as.flowType">Did water flow from the following
          point-of-use during this time?</p>
        <p class="has-text-weight-bold" v-else>This is marked as
          {{
            alarm.classified_as.flowType
          }}{{ alarm.classified_as.source ? ' from the point-of-use seen in this dialog' : '' }}.
          To re-classify, select an option below.</p>
        <p class="m-3">
          <room-display :value="source.$room" v-if="source.$room"/>
          <source-display :value="source"/>
        </p>
        <b-field grouped group-multiline style=" display: inline-block;">
          <p class="control">
            <b-button
                class="is-fullwidth"
                @click.prevent="submitClassification({flow_type:'flow'})"
                :loading="loading && loading.includes('flow')"
                :disabled="loading"
                icon-left="thumbs-up"
                type="is-success is-outlined"
            >
              Yes - <b>Accurate flow and POU</b>
            </b-button>
          </p>
          <p class="control">
            <b-button
                class="is-fullwidth"
                @click.prevent="$emit('flow-from-different-pou')"
                :loading="loading && loading.includes('different-pou')"
                :disabled="loading"
                icon-left="not-equal"
                type="is-info is-outlined"
            >
              No - <b>Wrong POU</b>
            </b-button>
          </p>
          <p class="control">
            <b-button
                class="is-fullwidth"
                @click.prevent="submitClassification({flow_type:'noflow'})"
                :loading="loading && loading.includes('noflow')"
                :disabled="loading"
                icon-left="thumbs-down"
                type="is-danger is-outlined"
            >
              No - <b>No flow</b>
            </b-button>
          </p>
          <p class="control">
            <b-button
                class="is-fullwidth"
                @click.prevent="submitClassification({flow_type:'unknown'})"
                :loading="loading && loading.includes('unknown')"
                :disabled="loading"
                icon-left="question"
                type="is-dark is-outlined"
            >
              <b>I'm not sure</b>
            </b-button>
          </p>
        </b-field>
      </div>
    </div>
  </b-message>
</template>

<script>
import {LocalScope} from "vue-local-scope";
import intervalFunctionMixin from "../../mixins/intervalFunctionMixin";
import Alarm from "../../dataClasses/Alarm";
import Source from "../../dataClasses/Source";

import vuMeter from "../DataVisualations/vu-meter";

const maxAmplitudeRatio = 10;
const vuVals = [0, .02, .05, .1, .25, .5, .75, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default {
  name: "SelectSourceAlarm",
  components: {
    LocalScope,
    vuMeter
  },
  mixins: [intervalFunctionMixin],
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    alarm: {
      type: Alarm,
      required: true
    },
    availablePositions: {
      type: Array,
      default: () => []
    },
    source: {
      type: Source,
      required: true
    },
    viewAsTable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      vuVals,
      maxAmplitudeRatio,
      secondsSinceStart: null,
      secondsSinceEnd: null,
      loading: null,
      showTooltipForSerial: null,
    }
  },
  computed: {
    sortedAvailablePositionSerials() {
      return this.serials.sort(this.alphanumericSort);
    },
    serials() {
      return this.availablePositions.map(pos => pos.serial)
    },
    serialsInvolved() {
      return this.alarm.get('sensors', []).map(sensorEntry => sensorEntry.serial);
    },
    positions() {
      return this.availablePositions.filter(pos => this.serials.includes(pos.serial));
    }
  },
  methods: {
    onPoll() {
      let now = new Date();
      this.secondsSinceStart = (now - this.alarm.$startTime) / 1000;
      this.secondsSinceEnd = (now - this.alarm.$endTime) / 1000;
    },
    includedInAlarm(serial) {
      return this.serialsInvolved.includes(serial)
    },
    /**
     *
     * @param flow_type {'flow'|'noflow'|'unknown'}
     */

    submitClassification({flow_type}) {
      this.loading = [flow_type];
      let sourceDisplay = this.source.$displayString,
          args = {
        flow_type,
        source_id: flow_type === 'flow' ? this.source.id : null,
        alarm: this.alarm,
        message: this.message,
      };
      this.alarm
          .classifyClip(args)
          .then(() => {
            this.$handleSuccess(`Event classified as ${flow_type.replace('noflow', 'not actual flow')} ${flow_type === 'flow' ? 'from point-of-use ' + sourceDisplay : ''}`);
            this.$emit('classified', args);
          })
          .catch(
              e => this.$handleError(e)
          )
          .finally(
              () => setTimeout(()=>this.loading = null,500)
          )
    }
  }
}
</script>

<style scoped>
  .faded {
    opacity: .6;
  }
</style>