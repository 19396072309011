<template>
  <div>
    <b>Classified sources</b>
    <area-classified-source-table :area="area" />
  </div>
</template>

<script>
import AreaClassifiedSourceTable from "../../../applications/Utilities/AreaSourceDetail/AreaClassifiedSourceTable";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";

export default {
  name: "AreaSourceDetailInner",
  components: {
    AreaClassifiedSourceTable,
  },
  props: {
    area: {
      type: Object,
      required: true,
    },
  },
  computed: {
    building() {
      return this.area
        ? getObjectInArrayById(
            this.$store.state.buildings,
            this.area.building ? this.area.building.id : "~!!~!~!!~",
            null
          )
        : null;
    },
  },
};
</script>

<style scoped></style>
