<template>
  <div class="site-introspection">
    <div class="card-content" v-if="!site">
        <div>
          Welcome to AquaSeca's customer portal.
        </div>
        <div class="mt-3">
          There is currently no site loaded.
        </div>
        <div class="mt-3 has-text-centered">
          <b-button
            class="mt-3"
            type="is-primary is-outlined"
            @click="queryReplace({sss:1})"
          >
            View available sites
          </b-button>
        </div>
      </div>
      <div class="card-content" v-if="$store.getters.loaded && site && !areas.length">
        <div>
          Welcome to AquaSeca's customer portal.
        </div>
        <div class="mt-3">
          The selected site, <b>{{ site.$displayString }}</b>, does not currently have any areas defined.
          You must create an area before you can install any hardware.
        </div>
        <div class="mt-3">
          You can always change the name of your area after it is created.
          It is common to create an area-per-floor in larger buildings.
          In smaller settings (such as a residential unit) a single area that covers the whole building will suffice.
        </div>
        <div class="mt-3 has-text-centered">
          <b-button
              class="mt-3"
            type="is-primary is-outlined"
            @click="$router.push({name:'administration:view-site', params: {id:site.id}, query:{areaModal:true,next:$route.fullPath}})"
          >
            Add a new area
          </b-button>
        </div>
      </div>
      <div class="card-content" v-else-if="site && (!site.$sensorCount || !site.$gatewayCount)">
        <div>
          Welcome to AquaSeca's customer portal.
        </div>
        <div class="mt-3">
          The selected site needs to have additional hardware installed before it can be used.
        </div>
        <div class="mt-3 has-text-centered" v-if="site && !site.$gatewayCount">
          <b-button
              v-if="site ? $store.state.user_roles.match('installer', get(site, 'id_str', '|||||')) : false"
              class="mt-3"
              type="is-primary is-outlined"
              @click="$router.push({name: 'add-gateway', query:{next:$route.fullPath}})"
          >
            Install a gateway
          </b-button>
          <i v-else>
            No gateway installed. You do not have permission to install a gateway.
            Please talk to your site administrator.
          </i>
        </div>
        <div class="mt-3 has-text-centered" v-else-if="!site.$sensorCount">
          <b-button
              v-if="site ? $store.state.user_roles.match('installer', get(site, 'id_str', '|||||')) : false"
              class="mt-3"
              type="is-primary is-outlined"
              @click="$router.push({name: 'add-sensor', query:{next:$route.fullPath}})"
          >
            Install a sensor
          </b-button>
          <i v-else>
            No sensors installed. You do not have permission to install a sensor.
            Please talk to your site administrator.
          </i>
        </div>
      </div>
      <div class="card-content"
           v-else-if="!areas.length === 1 && areas[0].$calibrationState === 'uncalibrated'">
        <div>
          Welcome to AquaSeca's customer portal.
        </div>
        <div class="mt-3">
          Now that you have created an area and installed hardware, you must perform system training.
          Click on the button below to begin.
        </div>
        <div class="mt-3 has-text-centered">
          <b-button
              v-if="(areas && areas.length) ? $store.state.user_roles.match('installer', areas[0].get('id_str', '|||||')) : false"
              type="is-primary is-outlined"
              @click="areas[0].$goToTrainingOverview()"
          >
            Begin system training
          </b-button>
          <i v-else>
            You do not have permissions to perform system training. Please alert your site administer if this is
            unexpected.
          </i>
        </div>
      </div>
      <div class="card-content" v-else-if="exempted">
        <slot/>
      </div>
      <div class="card-content" v-else>
        <slot/>
      </div>
  </div>
</template>

<script>
import {get} from "lodash";


export default {
  name: "SiteIntrospectionCardContent",
  methods: {
    get
  },
  computed: {
    exempted: {
      get() {
        return this.$route.query.hasOwnProperty('exempted');
      },
      set(val) {
        val ? this.queryReplace({exempted:1}) : this.queryRemoveKeys(['exempted']);
      }
    },
    site() {
      return this.$store.getters.site || {};
    },
    areas() {
      return this.$store.state.areas || [];
    }
  }
}
</script>

<style scoped>

</style>