import FirestoreDataClass from "./_baseClasses";

export default class DeviceEvent extends FirestoreDataClass {
  static collectionName = "device_events";

  static latestFor(forDocRefOrDocRefArray) {
    let self = this;

    return new Promise(function (resolve, reject) {
      self.$client
        .post(
          self.APIEndpointSegment + "/latest-for/",
          Array.isArray(forDocRefOrDocRefArray)
            ? { for_objects: forDocRefOrDocRefArray }
            : { for_object: forDocRefOrDocRefArray }
        )
        .then((res) => resolve(res.results || res.result))
        .catch((e) => reject(e));
    });
  }
}
