<template>
  <ul class="for-obj-ul">
    <li
        v-for="obj in objects"
        :key="obj[valueKey]"
        class="for-obj-li pointer"
    >
      <b-button
          style="zoom:70%;"
          size="is-small"
          :icon-left="coercer(obj) === value ? 'check' : ' '"
          :type="coercer(obj) === value ? 'is-primary' : type"
          rounded
          @click="valueSelected(obj)"
      />
        <span class="ml-2">
          <generic-display
              :value="obj"
              :show-tooltip="false"
              :show-link-icon="false"
              class="pointer"
              @click.native="valueSelected(obj)"
          />
        </span>
    </li>
  </ul>
</template>

<script>
import toRef from "../../appLevelFunctions/toRef";

export default {
  name: "SelectObjectsButtonInput",
  props: {
    objects: {
      type: Array,
      default: () => []
    },
    coercer: {
      type: Function,
      default: (obj) => {
        return obj ? toRef(obj).path : undefined;
      }
    },
    type: {
      type: String,
      default: null,
    },
    value: {
      required: false
    },
    valueKey: {
      type: String,
      default: 'id',
    },
  },
  methods: {
    valueSelected(val) {
      let coercedVal = this.coercer(val);
      // console.log('SOBI emit', coercedVal)
      this.$emit('input', coercedVal);
      this.$emit('update:value', coercedVal);
    }
  },
  computed: {
    coercedObjects() {
      return this.objects.map(obj=>this.coercer(obj));
    }
  },
  watch: {
    coercedObjects: {
      immediate: true,
      handler(coercedObjects, prev) {
        // console.log("0.watch.coercedObjects", {coercedObjects, prev}, coercedObjects && !coercedObjects.includes(prev))
        if (
            coercedObjects &&
            !coercedObjects.includes(this.value)
        ) {
          this.$nextTick(()=>{
            this.valueSelected(this.objects[0]);
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.for-obj-ul {
  list-style: inherit;
}

.for-obj-li {
  display: flex;
  margin-left: 15px;
}
</style>