<template>
  <div>
    <b-table
      :data="rows"
      bordered
      detailed
      narrowed
      striped
      :default-sort="['position_model_timestamp']"
      default-sort-direction="desc"
      detail-transition="fade"
      v-if="rows && rows.length"
    >
      <b-table-column label="Floor" v-slot="props" field="std_log_spectrum_max">
        {{ room(src_(props.row)) ? room(src_(props.row)).floor : "" }}
      </b-table-column>
      <b-table-column label="Room" v-slot="props">
        <room-display
          :value="room(src_(props.row))"
          v-if="room(src_(props.row))"
          show-link-icon
          :show-floor="false"
        />
      </b-table-column>
      <b-table-column label="POU" v-slot="props">
        <source-display
          :value="src_(props.row)"
          v-if="src_(props.row)"
          show-link-icon
          show-training-level
        />
      </b-table-column>
      <b-table-column
        field="sensor_count"
        numeric
        sortable
        label="Sensors"
        v-slot="props"
      >
        {{ props.row.sensor_count }}
      </b-table-column>
      <b-table-column
        field="position_model_count"
        label="Position Models"
        numeric
        sortable
        v-slot="props"
      >
        {{ props.row.position_model_count }}
      </b-table-column>
      <b-table-column
        field="position_model_timestamp"
        label="Position Timestamp"
        sortable
        v-slot="props"
      >
        {{
          props.row.position_model_timestamp
            ? new Date(props.row.position_model_timestamp).toLocaleString()
            : ""
        }}
      </b-table-column>
      <b-table-column
        field="std_log_spectrum_max"
        label="Spectrum Max"
        sortable
        v-slot="props"
      >
        {{
          props.row.std_log_spectrum_max
            ? props.row.std_log_spectrum_max.toFixed(4)
            : ""
        }}
      </b-table-column>
      <template #detail="props">
        <p>
          <b>Sensor detection</b> for
          <span v-if="room(src_(props.row))">
            <room :value="room(src_(props.row))" classes="inline-block" />
          </span>
          <source-display :value="src_(props.row)" show-training-level />
        </p>
        <source-sensor-detection-table :src="src_(props.row)" />
      </template>
    </b-table>
    <i v-else>No classified source data found</i>
  </div>
</template>

<script>
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import SourceSensorDetectionTable from "../../../tables/SourceSensorDetectionTable";

export default {
  name: "AreaClassifiedSourceTable",
  components: {
    SourceSensorDetectionTable,
  },
  computed: {
    rows() {
      return this.area.analytics.classified_sources;
    },
  },
  methods: {
    getObjectInArrayById,
    sensorDetection(classifiedSourceRow) {
      let src = this.src_(classifiedSourceRow);
      return src && src._sensor_detection ? src._sensor_detection : null;
    },
    src_(classifiedSourceRow) {
      return classifiedSourceRow && classifiedSourceRow.source
        ? getObjectInArrayById(
            this.$store.state.sources,
            classifiedSourceRow.source.id,
            null
          )
        : null;
    },
    building(src) {
      return src.building
        ? getObjectInArrayById(
            this.$store.state.buildings,
            src.building.id,
            null
          )
        : null;
    },
    room(src) {
      return src.room
        ? getObjectInArrayById(this.$store.state.rooms, src.room.id, null)
        : null;
    },
  },
  props: {
    area: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
