<template>
  <div>
    <img :src="sensorImage" class="sensor-img" />
    {{ sensor.serial }}
    <slot></slot>
  </div>
</template>

<script>
import sensorImage from "../../assets/sensor-64.png";
export default {
  name: "Sensor",
  data() {
    return {
      sensorImage,
    };
  },
  props: {
    sensor: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
