<template>
  <div>
    <b-table
      :data="rowData"
      :default-sort="['serial']"
      v-bind="$props"
      :sticky-header="stickyHeader"
      :loading="loading"
    >
      <b-table-column label="Serial" v-slot="props" sortable field="serial">
        {{ props.row.serial }}
        <b-tooltip
          v-if="
            showTrainingModeWarning &&
            props.row.$deviceType === 'sensor' &&
            props.row.$isInTrainingMode
          "
          type="is-danger"
          :label="`Sensor ${props.row.serial} is currently in training mode`"
          class="pointer"
          @click.native="$emit('training-mode-clicked', props.row)"
        >
          <b-icon icon="exclamation-circle" type="is-danger" />
        </b-tooltip>
      </b-table-column>
      <b-table-column
        label="Device type"
        v-slot="props"
        sortable
        field="$deviceType"
      >
        <span class="is-inline-flex smaller">
          <b-icon :icon="iconFor(props.row.$deviceType)" />
          {{ capitalizeFirstLetter(props.row.$deviceType) }}
        </span>
      </b-table-column>
      <b-table-column
        v-if="hasSensors"
        label="Port 1"
        v-slot="props"
      >
        <b-tooltip
          v-if="props.row.get('port_1.probe')"
          :label="`Serial #${props.row.get('port_1.serial')}`"
          class="pointer"
          @click.native="$router.push({name:'devices:probe-detail', params:{serial:props.row.get('port_1.serial')}})"
        >
          <b-icon  icon="check" type="is-success"/>
        </b-tooltip>
        <b-icon v-else icon="times" type="is-light"/>
      </b-table-column>
      <b-table-column
        v-if="hasSensors"
        label="Port 2"
        v-slot="props"
      >
        <b-tooltip
          v-if="props.row.get('port_2.probe')"
          :label="`Serial #${props.row.get('port_2.serial')}`"
          class="pointer"
          @click.native="$router.push({name:'devices:probe-detail', params:{serial:props.row.get('port_2.serial')}})"
        >
          <b-icon  icon="check" type="is-success"/>
        </b-tooltip>
        <b-icon v-else icon="times" type="is-light"/>
      </b-table-column>
      <b-table-column
        v-if="showInstallationLocation"
        label="Last seen"
        v-slot="props"
        sortable
        field="telemetry.status.timestamp"
      >
        <span class="smaller">
          {{ lastSeen(props.row) }}
        </span>
      </b-table-column>
      <b-table-column
        v-if="showInstallationLocation"
        v-slot="props"
        label="Area"
        sortable
        field="$building.name"
      >
        <building-display
          :value="props.row.$building"
          :show-floors="false"
          :show-training-mode-warning="false"
          v-if="props.row.$building"
          classes="smaller"
        />
      </b-table-column>
      <b-table-column
        v-if="showInstallationLocation"
        v-slot="props"
        label="Room"
        field="$room.name"
        sortable
      >
        <room-display
          :value="props.row.$room"
          :show-floor="false"
          v-if="props.row.$room"
          classes="smaller"
        />
      </b-table-column>
      <b-table-column
        v-if="
          showInstallationLocation &&
          rowData.filter((el) => !!el.get('$room.floor')).length
        "
        v-slot="props"
        field="$room.floor"
        label="Floor"
        sortable
      >
        {{ props.row.get("$room.floor") }}
      </b-table-column>
      <b-table-column
        v-if="
          showInstallationLocation &&
          rowData.filter((el) => !!el.get('$room.unit')).length
        "
        v-slot="props"
        field="$room.unit"
        label="Unit"
        sortable
      >
        {{ props.row.get("$room.unit") }}
      </b-table-column>
      <b-table-column v-if="showInstallationLocation" v-slot="props">
        <span v-if="props.row.$deviceType === 'sensor'" class="is-inline-flex">
          <sensor-battery
            style="zoom: 75%"
            class="in-row"
            :position="props.row"
          />
          <sensor-signal-indicator
            style="zoom: 50%"
            class="in-row"
            :position="props.row"
          />
        </span>
        <span v-if="props.row.$deviceType === 'gateway'" class="is-inline-flex">
          <b-icon
            icon="cloud-upload-alt"
            :type="props.row.$isHealthy ? 'is-success' : 'is-danger'"
            :class="props.row.$isHealthy ? '' : 'fa-throb'"
          />
        </span>
      </b-table-column>
      <b-table-column v-if="area && hasSensors" label="Probes">
        {{ area }}
      </b-table-column>
      <slot name="select-column">
        <b-table-column v-slot="props">
          <b-button
            rounded
            outlined
            @click="$emit('click', props.row)"
            type="is-primary "
            size="is-small"
          >
            Select
          </b-button>
        </b-table-column>
      </slot>
      <template #empty>
        <p class="has-text-centered"><i>No devices found</i></p>
      </template>
    </b-table>
  </div>
</template>

<script>
import iconFor from "../icons";
import Area from "../dataClasses/Area";

export default {
  name: "DeviceTable",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Area,
      required: false,
    },
    /**
     * rows is an Array of positions and gatewayPositions
     */
    rows: {
      type: Array,
      default: () => [],
    },
    showInstallationLocation: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    showTrainingModeWarning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasSensors() {
      return (
        this.rows &&
        this.rows.filter((row) => row.$deviceType === "sensor").length > 0
      );
    },
    site() {
      return this.$store.getters.site;
    },
    rowData() {
      return this.rows;
    },
  },
  methods: {
    iconFor,
    lastSeen(device) {
      return device &&
        device.telemetry &&
        device.telemetry.status &&
        device.telemetry.status.timestamp
        ? device.telemetry.status.timestamp.toLocaleString()
        : "n/a";
    },
  },
};
</script>

<style scoped>
.in-row {
  display: inline-block;
}

.smaller {
  zoom: 85%;
}
</style>
