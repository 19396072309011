<template>
  <div>
    <b-field :label="label" grouped>
      <b-select
        :type="hasError ? 'is-danger' : ''"
        select
        v-model="hour"
        :disabled="disabled"
        v-if="showHours"
      >
        <option v-for="n in allowedHours" :value="n" :key="n">
          <span v-if="hourFormatter">{{ hourFormatter(n) }}</span>
          <span v-else>
            {{ n.toString() }} hour<span v-if="n !== 1">s</span>
          </span>
        </option>
      </b-select>
      <b-select
        name=""
        v-model="minute"
        id=""
        :type="hasError ? 'is-danger' : ''"
        :disabled="disabled"
        v-if="showMinutes"
      >
        <option v-for="n in Array(maxMinutes + 1).keys()" :value="n" :key="n">
          {{ n }} minute<span v-if="n !== 1">s</span>
        </option>
      </b-select>
      <p class="control">
        <b-button
          type="is-danger"
          @click="reset()"
          :disabled="initialValue === value || disabled"
        >
          <b-icon icon="times" />
        </b-button>
      </p>
    </b-field>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      hour: 0,
      minute: 5,
      initialValue: this.resetValue || this.value,
    };
  },
  computed: {
    timeString() {
      let hourPadding = this.hour < 100 ? 2 : 3;
      return (
        this.hour.toString().padStart(hourPadding, "0") +
        ":" +
        this.minute.toString().padStart(2, "0")
      );
    },
  },
  props: {
    value: {
      type: String,
      default: "00:04",
    },
    resetValue: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showHours: {
      type: Boolean,
      default: true,
    },
    showMinutes: {
      type: Boolean,
      default: true,
    },
    maxMinutes: {
      type: Number,
      default: 60,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    allowedHours: {
      type: Array,
      default: () => {
        return Array.from(Array(11).keys());
      },
    },
    hourFormatter: {
      type: Function,
      default: null,
    },
  },
  watch: {
    timeString: {
      handler(val, prev) {
        if (val !== prev) {
          this.$emit("input", val);
          this.$emit("update:value", val);
        }
      },
    },
    value: {
      handler(val, prev) {
        if (val !== prev) {
          this.setValue(val);
        }
      },
    },
    resetValue: {
      handler(val) {
        this.initialValue = val;
      },
    },
  },
  methods: {
    reset() {
      if (!this.disabled) {
        this.setValue(this.initialValue);
      }
    },
    setValue(valString) {
      this.hour = parseInt(valString.split(":")[0]);
      this.minute = parseInt(valString.split(":")[1]);
    },
  },
  created() {
    if (this.value) {
      this.hour = parseInt(this.value.split(":")[0]);
      this.minute = parseInt(this.value.split(":")[1]);
    }
    if (!this.showHours) {
      this.hour = 0;
    }
    if (!this.showMinutes) {
      this.minute = 0;
    }
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
