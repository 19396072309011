<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Add sensor</div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small" />
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step" :has-navigation="false">
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <p class="is-size-5">
              Select the area where you will be adding this gateway
            </p>
            <building-radio
              :value="building"
              @input="handleBuildingSelection"
            />
          </div>
        </b-step-item>

        <b-step-item :step="1" label="Gateway" :type="stepType(1)">
          <transition name="slide">
            <div
              class="column is-offset-2-desktop is-8-desktop"
              v-if="
                !selectedDevice && (!positionLoading || selectedDeviceLoading)
              "
            >
              <p class="is-size-5">Which gateway would you like to install?</p>
              <scan-or-select-device
                v-if="area"
                :area="area"
                ref="step1Scan"
                device-state="device_shipped"
                :device-types="['gateway']"
                :device-paths="null"
                label=""
                method-query-key="sm1"
                @input="handleGatewaySelection"
                :selected-serial="serial"
                @update:loading="componentLoading = $event"
              />
            </div>
          </transition>
          <transition name="slide">
            <div class="has-text-centered" v-if="selectedDeviceLoading">
              <b-icon icon="sync" class="fa-spin" size="is-large" />
            </div>
          </transition>

          <transition name="slide">
            <div v-if="selectedDevice" class="column has-text-centered">
              <div class="has-text-centered">
                <p class="is-size-5">
                  You have selected gateway <b>{{ serial }}</b>
                </p>
                <div>
                  <b-button
                    icon-left="times"
                    outlined
                    size="is-small"
                    type="is-danger"
                    @click="uninstallGateway() ; serial = null"
                    :disabled="gatewayPositionLoading"
                  >
                    Change selected gateway
                  </b-button>
                </div>
              </div>
              <transition name="slide">
                <p class="has-text-centered" v-if="gatewayPositionLoading">
                  <b-icon icon="sync" class="fa-spin" size="is-large" />
                </p>
              </transition>
              <div
                class="has-text-centered ml-4 mr-4"
                v-if="liveGatewayPosition && serial"
              >
                <live-gateway-display
                  :serial="serial"
                  :position="liveGatewayPosition"
                />
                <transition name="slide">
                  <div
                    class="column is-8-desktop is-offset-2-desktop"
                    v-if="connectionWarnings.length"
                  >
                    <b-message
                      type="is-danger"
                      has-icon
                      icon="exclamation-circle"
                      class="mt-3 has-text-justified has-text-left"
                    >
                      <div class="ml-6-tablet">
                        <b>Warnings:</b>
                        <transition-group tag="ul" class="ul-doink">
                          <li
                            v-for="warning in connectionWarnings"
                            :key="warning"
                          >
                            {{ warning }}
                          </li>
                        </transition-group>
                      </div>
                    </b-message>
                  </div>
                </transition>
              </div>
            </div>
          </transition>
        </b-step-item>

        <b-step-item label="Floor-plan" optional :step="2" :type="stepType(2)">
          <transition name="slide">
            <aq-image-map-array
              @no-files="(step === 2 && lastStep <= 2) ? goForward() : null"
              v-if="building && gatewayPosition && step >= 1"
              :show-non-editable="false"
              :parent-object="building"
              :editable-entity-paths="editableEntityPaths"
            />
          </transition>
        </b-step-item>
        <b-step-item :step="3" label="Confirm" :type="stepType(3)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps are missing data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div
            class="column is-6-desktop is-offset-3-desktop is-10-table is-offset-1-tablet"
            v-else
          >
            <div class="box">
              <p class="subtitle has-text-weight-bold">
                <b-icon
                  icon="check"
                  type="is-success"
                  v-if="!invalidSteps.length"
                />
                Add Gateway
              </p>
              <level :values="valuesToShow" />
              <div
                class="has-text-centered"
                v-if="liveGatewayPosition && serial"
              >
                <live-gateway-display
                  :serial="serial"
                  :position="liveGatewayPosition"
                />
              </div>
              <b-message
                class="mt-6"
                type="is-info"
                :closable="false"
                title="Confirm installation"
                has-icon
                icon="info-circle"
              >
                <p class="is-size-5">
                  Click below to finalize installation of gateway
                  <b>{{ serial }}</b
                  >.
                </p>
              </b-message>
            </div>
          </div>
        </b-step-item>

        <template>
          <div class="has-text-centered">
            <forward-backward
              classes="float-right"
              :allow-forward="validate(step)"
              :min-step="stepArray[0]"
              :max-step="stepArray[stepArray.length - 1]"
              :step="step"
              :confirm-loading="confirmLoading"
              @forward="goForward"
              @backward="goBackward"
              @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import AqImageMapArray from "../../components/Wrappers/AqImageMapArray";
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";
import iconFor from "../../icons";
import { initial } from "lodash";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import Level from "../../components/Level/Level";

const stepArray = [0, 1, 2, 3];

/**
 * Device centric action component to
 */
export default {
  name: "AddGateway",
  components: {
    AqImageMapArray,
    BuildingRadio,
    ForwardBackward,
    Level,
    ScanOrSelectDevice,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {Position,null}, pipeLocationId: {string,null}}}
   */
  data() {
    return {
      pipeLocation: null,
      deviceType: "gateway",

      // Inputs
      position: null,
      livePosition: null,
      gatewayPosition: null,
      liveGatewayPositionRaw: null,

      componentLoading: false,
      confirmLoading: false,
      gatewayLoading: false,
      gatewayPositionLoading: false,
      loadingPipeLocation: false,
      positionLoading: false,
    };
  },
  mounted() {
    if (this.$store.state.buildings.length === 1) {
      this.handleBuildingSelection(this.$store.state.buildings[0])
    }
    if (this.serial) {
      this.handleGatewaySelection({ serial: this.serial });
    }
  },
  computed: {
    liveGatewayPosition() {
      return this.liveGatewayPositionRaw
        ? new this.$dataClasses.GatewayPosition(this.liveGatewayPositionRaw)
        : null;
    },
    connectionWarnings() {
      let warnings = [];
      if (this.liveGatewayPosition && !this.liveGatewayPosition.$isHealthy) {
        warnings.push(
          `Gateway ${this.serial} doesn't appear to be communicating properly.
            Ensure the gateway is powered on and connected to the internet.`
        );
      }
      return warnings;
    },
    valuesToShow() {
      return [
        {
          label: "Area",
          value: this.building ? this.building.name : "None",
        },
        { label: "Gateway", value: this.serial },
      ];
    },
    port: {
      get() {
        return this.$route.query.port ? parseInt(this.$route.query.port) : null;
      },
      set(val) {
        this.queryReplace({ port: val });
      },
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    /**
     * @returns {string|null} ID for building
     */
    buildingId() {
      return this.position ? this.position.get("building.id") : null;
    },
    /**
     * @returns {string}
     */
    notes: {
      get() {
        return this.$route.query.notes;
      },
      set(val) {
        this.queryReplace({ notes: val });
      },
    },
    editableEntityPaths() {
      return this.gatewayPosition ? [this.gatewayPosition.$path] : [];
    },
  },
  methods: {
    iconFor,
    uninstallGateway() {
      if (!this.area) {
        this.$handleError("No area defined for gateway; Cannot be uninstalled")
        return
      }
      let self = this;
      self.selectedDeviceLoading = true;
      self.area
          .performAction(
            "remove_devices",
            {
              devices: [
                self.selectedDevice.$FSRef,
              ]
            }
          )
          .then(() => {
            self.serial = null;
            self.handleGatewaySelection(null);
            self.$handleSuccess('Please select which gateway you would like to install');
            self.$refs['step1Scan'].getAvailableDevices();
          })
          .catch((e) => {
            self.$handleError('Error uninstalling gateway; See console', e)
          })
          .finally(() => {
            self.selectedDeviceLoading = false;
          })
    },
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return !!this.serial && !!this.selectedDevice;
      } else if (step === 2) {
        return true;
      } else if (step === 3) {
        return (
          initial(stepArray).filter((_step) => !this.validate(_step)).length ===
          0
        );
      }
    },
    /**
     * @param {object} building
     */
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && building && this.building) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} gateway
     */
    handleGatewaySelection(gateway) {
      this.serial = gateway ? gateway.serial : null;
    },
    /**
     * Finalize installation
     */
    onComplete() {
      if (this.invalidSteps.length) {
        this.$handleError(
          "Please correct any steps with missing data or errors",
          "Please correct any steps with missing data or errors"
        );
        return;
      }
      let serial = this.serial;
      this.reloadArea().then(() => {
        this.$handleSuccess(`Gateway ${serial} installation complete`);
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next);
        }
        this.$router.push({
          name: "devices:gateway-detail",
          params: { serial: this.serial },
        });
      });
    },
    unbindLiveGatewayPosition() {
      try {
        this.$unbind("liveGatewayPositionRaw");
      } catch (e) {
        console.log("No unbind: liveGatewayPositionRaw");
      }
    },
  },
  watch: {
    selectedDevice: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.unbindLiveGatewayPosition();
          return;
        }
        this.gatewayPositionLoading = true;
        let self = this;
        if (!this.area) {
          return;
        }
        self.area
          .performAction("add_devices", {
            devices: [self.selectedDevice.$FSRef],
          })
          .then(() =>
            self.$dataClasses.GatewayPosition.queryForSingle([["serial", "==", this.serial]])
          )
          .then((obj) => {
            self.gatewayPosition = obj;
            self.$bind("liveGatewayPositionRaw", self.gatewayPosition.$FSRef);
            self.gatewayPositionLoading = false;
            setTimeout(() => {
              if (
                val &&
                self.step === 1 &&
                self.validate(1) &&
                self.liveGatewayPosition &&
                !self.connectionWarnings.length
              ) {
                self.goForward();
              }
            }, 1000);
            self.reloadArea();
            return self.area.$enableRanging(5);
          })
          .catch((e) => {
            self.gatewayPositionLoading = false;
            self.$handleError("Could not load position for sensor " + self.serial, e);
          })
          .finally(() => (self.gatewayPositionLoading = false));
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}

.constrain {
  max-width: 200px;
}

.inline {
  display: inline-block;
}
</style>
