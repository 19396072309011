<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Detailed File Edit: {{ fileObject ? fileObject.metadata.filename : "" }}
      </div>
      <div class="card-header-icon">
        <b-icon icon="times" class="pointer" @click.native="leave()" />
      </div>
    </div>
    <div class="card-content">
      <component
        ref="editorComponent"
        v-bind:is="editorComponent"
        :file-object="fileObject"
        :parent-object="parentObject"
      />
      <slot v-if="!editorComponent" name="placeholder" />
      <b-button class="mt-3" expanded icon-left="chevron-left" @click="leave()">
        Return to file view
      </b-button>
    </div>
  </div>
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";
import AqImageMap from "./Editors/AqImageMap";
import iconFor from "../../icons";

export const categoryToComponent = {
  floor_plan: AqImageMap,
};

export default {
  name: "GenericFileEdit",
  mixins: [trackWindowSize],
  props: {
    fileObject: {
      type: Object,
      required: true,
    },
    parentObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    editorComponent() {
      return categoryToComponent[this.fileObject.metadata.category];
    },
    changed() {
      return this.$refs.editorComponent.changed;
    },
  },
  methods: {
    iconFor,
    leave() {
      this.$refs.editorComponent.leave();
    },
  },
};
</script>

<style scoped></style>
