<template>
  <div>
    <div class="columns">
      <div class="column is-8 is-offset-2 mb-6">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">Create customer</div>
          </div>
          <div class="card-content">
            <b-loading :active="loading" :is-full-page="false" />
            <b-field label="Customer name">
              <b-input minlength="3" v-model="inputs.customer_name" />
            </b-field>
            <b-field label="Sub-Customer name">
              <b-input minlength="3" v-model="inputs.subcustomer_name" />
            </b-field>
            <b-field label="Site name">
              <b-input minlength="3" v-model="inputs.site_name" />
            </b-field>
          </div>
          <div class="card-footer">
            <b-button
              :loading="loading"
              expanded
              class="ml-5 mr-5 mb-5 mt-5"
              type="is-primary"
              @click="save()"
              :disabled="!formIsValid"
              >Submit
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CreateCustomer",
  data() {
    return {
      inputs: {},
      loading: false,
    };
  },
  computed: {
    formIsValid() {
      return (
        this.inputs.customer_name &&
        this.inputs.customer_name.length >= 3 &&
        this.inputs.subcustomer_name &&
        this.inputs.subcustomer_name.length >= 3 &&
        this.inputs.site_name &&
        this.inputs.site_name.length >= 3
      );
    },
  },
  methods: {
    save() {
      let self = this;
      self.loading = true;
      this.$dataClasses.Customer.save(null, { name: self.inputs.customer_name })
        .then((obj) => {
          self.$handleSuccess("Customer " + obj.name + " has been created");
          return this.$dataClasses.SubCustomer.save(null, {
            parent: obj.$FSRef,
            name: self.inputs.subcustomer_name,
          });
        })
        .then((obj) => {
          self.$handleSuccess("Sub-Customer " + obj.name + " has been created");
          return self.$dataClasses.Site.save(null, {
            parent: obj.$FSRef,
            name: self.inputs.site_name,
          });
        })
        .then((obj) => {
          self.$handleSuccess("Site " + obj.name + " has been created");
          self.$store
            .dispatch("setScopeFromIdStr", { id_str: obj.id_str })
            .then(() => self.$router.push({ name: "administration" }));
        })
        .catch((err) => self.$handleError(err, err))
        .finally(() => {
          self.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
