<template>
  <div
    class="has-text-justified has-text-right mt-3"
    v-if="$store.state.user_roles.is_root && link"
  >
    <b-button
      :type="`is-outlined is-${topic}`"
      expanded
      @click="window.open(link, '_blank')"
    >
      View {{ reportName }}
    </b-button>
  </div>
</template>

<script>

/**
 *
 * @param areaId
 * @param areaStore
 * @param AreaDataClass
 * @returns {Promise<Area>}
 */
function getBuildingPath(areaId, areaStore, AreaDataClass) {
  return new Promise((resolve) => {
    AreaDataClass.fetchById(areaId)
      .then((area) => Promise.resolve(area))
      .then((area) =>
        area
          ? Promise.resolve(area)
          : areaId
          ? AreaDataClass.fetchById(areaId)
          : Promise.resolve(null)
      )
      .then((area) => resolve(area ? area.get("building.path") : null));
  });
}

export default {
  name: "ClipDetailReportLink",
  props: {
    alarm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      window,
      buildingPath: null,
    };
  },
  computed: {
    areaValue() {
      return this.alarm.get("area");
    },
    areaRef() {
      return this.areaValue ? this.toFSRef(this.areaValue, "areas") : null;
    },
    areaId() {
      return this.areaRef ? this.areaRef.id : null;
    },
    areaPath() {
      return this.areaRef ? this.areaRef.path : null;
    },
    topic() {
      return this.alarm.get("topic");
    },
    clipId() {
      return this.alarm.get("clip_id");
    },
    /**
     *
     * @return {Date}
     */
    issueDt() {
      return this.toDate(this.alarm.get("issue_dt"));
    },
    durationSeconds() {
      return this.alarm.get("flow_data.duration_seconds");
    },
    startDt() {
      if (this.alarm.get('temperature_data.start_time')) {
        return this.toDate(this.alarm.get('temperature_data.start_time'));
      }
      else if (this.issueDt && this.durationSeconds) {
        let startDt = new Date(this.issueDt.getTime());
        startDt.setSeconds(this.issueDt.getSeconds() - this.durationSeconds);
        return startDt;
      }
    },
    reportName() {
      if (this.topic === "source_selection") {
        return "clip labelling report";
      } else if (["flow", "noflow"].includes(this.topic) && this.startDt) {
        return "clip trend report";
      } else if (this.topic === 'temperature') {
        return "temperature trend report"
      }
    },
    link() {
      let clipId = this.alarm.get("clip_id");
      if (this.topic === "source_selection") {
        return `${process.env.VUE_APP_AQUACLOUD_URL}/reporting/clip_labeling?clip_id=${
          clipId
        }&create_usermessage=true&for_object_path=${
          this.buildingPath
        }`;
      } else if (["flow", "noflow"].includes(this.topic) && this.startDt) {
        let startDt = new Date(this.startDt.getTime()),
          endDt = new Date(this.issueDt.getTime());
        startDt.setHours(startDt.getHours() - 1);
        endDt.setHours(endDt.getHours() + 1);
        return `${
          process.env.VUE_APP_AQUACLOUD_URL
        }/reporting/clip_trend_report?anomaly_report_link=True&for_object_path=${
          this.areaPath
        }&start_timestamp=${
          startDt.toISOString()
        }&end_timestamp=${
          endDt.toISOString()
        }`;
      } else if (this.topic === 'temperature' && !! this.startDt) {
        let startDt = new Date(this.startDt.getTime()),
          endDt = new Date(this.issueDt.getTime());
        startDt.setHours(startDt.getHours() - 1);
        endDt.setHours(endDt.getHours() + 1);
        return `${
          process.env.VUE_APP_AQUACLOUD_URL
        }/reporting/temperature_trend_report?&for_object_path=${
          this.areaPath
        }&start_dt=${
          startDt.toISOString()
        }&end_dt=${
          endDt.toISOString()
        }high_temperature_F=110&low_temperature_F=68&temperature_unit=default`
      }
    },
  },
  watch: {
    areaId: {
      immediate: true,
      handler(val) {
        if (this.topic === 'source_selection')
        getBuildingPath(
          val,
          this.$store.state.areas,
          this.$dataClasses.Area
        ).then((buildingPath) => (this.buildingPath = buildingPath));
      },
    },
  },
};
</script>

<style scoped></style>
