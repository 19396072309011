<template>
  <div>
    <transition name="slide">
      <div class="card" v-if="$route.name === 'devices:area-list'">
        <div class="card-header">
          <div class="card-header-title">
            Devices by area at site {{ $store.getters.site.name }}
          </div>
        </div>
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column">
              <area-table
                  :rows="$store.state.areas"
                  has-select-column
                  :show-name-column="false"
              >
                <template #pre-columns>
                  <b-table-column
                    label="Area"
                    field="name"
                    sortable
                    :custom-sort="compareFieldFunc('$building.name')"
                    v-slot="props"
                  >
                    <building-display
                      v-if="props.row.$building"
                      :value="props.row.$building"
                      :show-floors="false"
                    />
                  </b-table-column>
                  <b-table-column
                    label="Operating mode"
                    v-slot="props"
                    sortable
                    :custom-sort="compareFieldFunc('$operatingState')"
                  >
                    <i>{{
                      capitalizeFirstLetter(props.row.$operatingState)
                    }}</i>
                  </b-table-column>
                  <b-table-column
                    label="Training status"
                    v-slot="props"
                    sortable
                    :custom-sort="compareFieldFunc('$calibrationState')"
                  >
                    <i>{{
                      capitalizeFirstLetter(props.row.$calibrationState)
                    }}</i>
                  </b-table-column>
                  <b-table-column label="Gateways" v-slot="props" numeric>
                    {{ deviceCounts(props.row).gateways }}
                  </b-table-column>
                  <b-table-column label="Sensors" v-slot="props" numeric>
                    {{ deviceCounts(props.row).sensors }}
                  </b-table-column>
                </template>
                <template #select-column>
                  <b-table-column v-slot="props">
                    <b-button
                      type="is-primary"
                      outlined
                      rounded
                      class="is-pulled-right"
                      icon-right="chevron-right"
                      size="is-small"
                      @click="
                        $router.push({
                          name: 'devices:area-detail',
                          params: { areaId: props.row.id },
                        })
                      "
                    >
                      View
                    </b-button>
                  </b-table-column>
                </template>
              </area-table>
              <div class="mb-6">
                <b-button
                  outlined
                  type="is-link is-pulled-right"
                  @click="
                    $router.push({
                      name: 'devices:site-detail',
                      query: $route.query,
                    })
                  "
                >
                  View all devices<span class="is-hidden-mobile">
                    for site {{ $store.getters.site.name }}</span
                  >
                </b-button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </transition>
    <transition name="slide">
      <router-view v-if="$route.name !== 'devices:area-list'" />
    </transition>
  </div>
</template>

<script>

import { compareFieldFunc } from "../../../dataClasses/functions";
import iconFor from "../../../icons";
import { mapGetters, mapState } from "vuex";
import AreaTable from "../../../tables/AreaTable";
import DeviceTable from "../../../tables/DeviceTable";

export default {
  name: "AreaList",
  components: {
    DeviceTable,
    AreaTable,
  },
  data() {
    return {
      hovered: [],
    };
  },
  computed: {
    ...mapGetters(["subcustomer", "site"]),
    ...mapState(["areas", "buildings"]),
  },
  methods: {
    compareFieldFunc,
    iconFor,
    deviceCounts(area) {
      return {
        gateways: area.gateways ? area.gateways.length : 0,
        probes: area.probes ? area.probes.length : 0,
        sensors: area.sensors ? area.sensors.length : 0,
      };
    },
  },
  watch: {
    subcustomer: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.uninstalledDevices = [];
          return;
        }
        const queries = [
            ["device_state.event_type", "==", "device_shipped"],
            ["device_state.subcustomer", "==", this.subcustomer.$FSRef],
          ],
          urlParams = { order_field: "device_state.event_type", paginate_by:250 };
        this.uninstalledDevicesLoading = true;
        this.uninstalledDevices = [];
        Promise.all([
          this.$dataClasses.Gateway.query(queries, urlParams),
          this.$dataClasses.Probe.query(queries, urlParams),
          this.$dataClasses.Sensor.query(queries, urlParams),
        ])
          .then((resultLists) => {
            resultLists.forEach((resultList) => {
              this.uninstalledDevices = [
                ...this.uninstalledDevices,
                ...resultList,
              ];
            });
          })
          .catch((e) => this.$handleError("Could not load devices", e))
          .finally(() => (this.uninstalledDevicesLoading = false));
      },
    },
  },
};
</script>

<style scoped lang="scss">
ul.area-values-list {
  list-style: inherit;

  &li {
    text-decoration: none;
  }
}

.area-display {
  transition: 0.25s all;
  padding: 5px;
  border: 1px solid #4a4a4a;
  border-radius: 2px;

  &.hovered {
    background-color: #4a4a4a;
    color: white;
    border: 1px solid white;
  }
}

.device-list {
  display: inline-block;
  list-style: inherit;
}
</style>
