<template>
  <div class="tile dashboard-widget is-child">
    <p class="widget-title">{{ title }}</p>
    <div class="widget-content">
      <slot name="ok" />
      <loading-spinner :loading="loading" />
      <transition name="slide">
        <div v-if="alarms && alarms.length">
          <div>
            <p class="has-text-centered" style="zoom: 75%">
              {{ alarms.length }}{{ isMax ? "+" : "" }} alarm{{
                alarms.length === 1 ? "" : "s"
              }}
              over last {{ days }} day{{ days === 1 ? "" : "s" }}
            </p>
          </div>
          <div v-for="i in alarms" :key="i.id" class="widget-message-wrapper">
            <user-message-box
              :alarm-object="i"
              :show-floors="false"
              :closable="false"
              :click-action="() => $emit('alarm-click', i)"
            />
          </div>
        </div>
      </transition>
      <transition name="slide">
        <div class="has-text-centered mt-6" v-if="!loading && !alarms.length">
          <i>None found</i>
        </div>
      </transition>
    </div>
    <a class="widget-bottom-link" @click="$emit('bottom-click')"> View more </a>
  </div>
</template>

<script>
import LoadingSpinner from "./components/LoadingSpinner";
import UserMessageBox from "../../../components/UserMessage/UserMessageBox";
export default {
  name: "UserMessageDashboardTile",
  components: {
    LoadingSpinner,
    UserMessageBox,
  },
  props: {
    isMax: {
      type: Boolean,
      default: false,
    },
    days: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alarms: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
