<template>
  <div :class="classes">
    <treeselect
      ref="treeselect"
      v-model="valueInner"
      value-format="object"
      :multiple="true"
      :searchable="true"
      :options="options"
      :disabled="disabled"
    >
      <label slot="option-label" slot-scope="{ node }">
        <span>
          <generic-display
            :value="
              $store.getters.objectByID(
                node.id,
                null,
                $store.getters.objectStack
              )
            "
            :show-link-icon="showLinkIcons"
            show-icon
          />
        </span>
      </label>
      <template #after-list>
        <div class="m-2">
          <b>Groups/Tags</b>
          <div v-if="tags && tags.length">
            <b-field>
              <b-taglist
                attached
                type="is-primary"
                v-for="tag in tags"
                :key="tag"
                class="m-1 taglist"
              >
                <b-tag :type="tagType(tag)">
                  <b>{{ tag }}</b> ({{ countByTag(tag) }})
                </b-tag>
                <b-tag
                  :type="tagType(tag)"
                  class="pointer"
                  @click.native="selectAllByTag(tag)"
                  v-if="!tagProps[tag].all"
                >
                  +
                </b-tag>
                <b-tag
                  :type="tagType(tag)"
                  class="pointer"
                  @click.native="deselectAllByTag(tag)"
                  v-if="tagProps[tag].some"
                >
                  -
                </b-tag>
              </b-taglist>
            </b-field>
          </div>
          <i v-else class="ml-3">No tags present</i>
        </div>
      </template>
    </treeselect>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import store from "../../store";

export default {
  name: "SelectHierarchy",
  components: {
    Treeselect,
  },
  data() {
    return {
      yes: true,
      valueInner: this.value,
    };
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    includeTags: {
      type: Boolean,
      default: true,
    },
    /**
     * @type {Array.<{id:String,isA:String,label:String,refPath:String,children:Array}>}
     */
    options: {
      type: Array,
      required: true,
    },
    siteIds: {
      type: Array,
      default: function () {
        return store.state.sites.map((site) => site.id);
      },
    },
    /**
     * @type {Array<Object>}
     */
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showLinkIcons: {
      type: Boolean,
      default: false,
    },
    /**
     * Must take 1D array as arg and return 1D array
     */
    returnTransformFunction: {
      type: Function,
      default: function (valArray) {
        return valArray;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    arrayName: {
      type: String,
      validator(val) {
        return ["sources", "pipe_locations"].includes(val);
      },
      required: true,
    },
  },
  computed: {
    ...mapState(["sources", "pipe_locations"]),
    objects() {
      if (this.arrayName === "sources") {
        return this.sources;
      } else if (this.arrayName === "pipe_locations") {
        return this.pipe_locations;
      } else {
        throw "Unknown style-prop for SelectHierarchy: " + this.style;
      }
    },
    tags() {
      return store.getters.tagsForObjectsInArray(this.objects, "", this.value);
    },
    selectedIds() {
      return this.valueInner;
    },
    tagProps() {
      let val = this.valueInner,
        returnVal = {};
      this.tags.forEach((tag) => {
        let allSrcIds = this.objectsByTag(tag).map((v) => v.id),
          selectedSrcIds = val.map((v) => v.id),
          all = allSrcIds.every((id) => selectedSrcIds.includes(id)),
          some = allSrcIds.some((id) => selectedSrcIds.includes(id)),
          none = !all;
        returnVal[tag] = {
          all,
          some,
          none,
        };
      });
      return returnVal;
    },
  },
  methods: {
    getObjectInArrayById,
    objectsByTag(tag) {
      return this.objects.filter(
        (src) => src.tags && src.tags.length && src.tags.includes(tag)
      );
    },
    tagType(tag) {
      let props = this.tagProps[tag] || {};
      if (props.all) {
        return "is-all";
      } else if (props.some) {
        return "is-some";
      }
      return "is-none";
    },
    countByTag(tag) {
      return this.objectsByTag(tag).length;
    },
    deselectAllByTag(tag) {
      let self = this,
        sourceIdsByTag = self.objectsByTag(tag).map((src) => src.id);
      self.valueInner = self.valueInner.filter(
        (item) => !sourceIdsByTag.includes(item.id)
      );
    },
    selectAllByTag(tag) {
      let selectedIds = this.valueInner.map((v) => v.id);
      this.objectsByTag(tag).forEach((src) => {
        if (!selectedIds.includes(src.id)) {
          let node = this.$refs.treeselect.getNode(src.id);
          this.valueInner.push(node);
        }
      });
    },
  },
  watch: {
    valueInner: {
      handler(val) {
        let returnData = this.returnTransformFunction(val);
        this.$emit("update:value", returnData);
        this.$emit("change", returnData);
        this.$emit("input", returnData);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.taglist {
  display: inline-flex;
  flex-flow: row;

  .tag {
    display: inline;
    padding-top: 3px;
  }
}

.is-80-wide {
  max-width: 80%;
}
</style>
