<template>
  <div :class="'feature-flag flag__' + flag" v-if="enabled">
    <b-tooltip
      v-if="showTooltip"
      :label="`Enabled via the '${readableFlagName(flag)}' flag`"
      class="pointer"
    >
      <b-icon icon="flag" class="flag-icon" size="is-small" type="is-primary" />
    </b-tooltip>
    <b-icon
      v-else-if="showIcon"
      icon="flag"
      class="flag-icon"
      size="is-small"
      type="is-primary"
    />
    <slot v-if="enabled" />
  </div>
</template>

<script>
import { FEATURE_FLAGS } from "./index";
import { VALID_FLAGS } from "./index";

export default {
  name: "FeatureFlagWrapper",
  props: {
    flag: {
      type: String,
      validator(val) {
        return VALID_FLAGS.includes(val);
      },
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    readableFlagName(key) {
      return FEATURE_FLAGS.filter((f) => f.key === key)[0].title;
    },
  },
  computed: {
    enabled() {
      return this.$store.getters.hasFlag(this.flag);
    },
  },
};
</script>

<style scoped>
.feature-flag {
  width: 100%;
  position: relative;
}

.feature-flag .flag-icon {
  position: absolute;
  top: 5px;
  left: -15px;
  zoom: 60%;
}
</style>
