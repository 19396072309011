var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sensor-detail-wrapper"},[_c('table',{staticClass:"table is-striped is-bordered is-fullwidth"},[_c('tbody',_vm._l((_vm.info),function(value,name){return _c('tr',{key:name},[_c('td',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(name))]),(name === 'Bundle' && _vm.$store.state.user_roles.is_root)?_c('td',[(_vm.bundleLoading)?_c('b-icon',{staticClass:"fa-spin",attrs:{"icon":"spinner"}}):(_vm.bundle.id)?_c('router-link',{attrs:{"to":{
              name: 'administration:shipments:view',
              params: { id: _vm.bundle.id },
            }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('i',[_vm._v("Error loading")])],1):(name === 'Shipped to')?_c('td',[(value)?_c('router-link',{attrs:{"to":{
              name: 'administration:view-subcustomer',
              params: { id: value.id },
            }}},[_vm._v(" "+_vm._s(value.$displayString)+" ")]):_c('i',[_vm._v("n/a")])],1):(name === 'Gateways')?_c('td',[(value && value.length)?_c('p',{staticClass:"mb-0"},_vm._l((value),function(gw){return _c('b-button',{key:gw.serial,attrs:{"tag":"router-link","size":"is-small","rounded":"","expanded":"","type":"is-link is-outlined","to":{
                name: 'devices:gateway-detail',
                params: { siteId: _vm.$route.params.siteId, serial: gw.serial },
              }}},[_vm._v(" "+_vm._s(gw.serial)+" ")])}),1):_c('span',[_vm._v("None found")])]):(name === 'Calibration state' && _vm.position)?_c('td',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.position.$calibrationState.replace('recalibration', 'training mode')))+" "),(_vm.position && _vm.position.$isInTrainingMode)?_c('in-training-mode',{attrs:{"area":_vm.position.$area,"message":"This sensor is currently in training mode"}}):_vm._e()],1):_c('td',{class:{
            'has-text-danger':
              name === 'Last seen' &&
              ((_vm.position && _vm.millisecondsSinceLastSeen > 3600000) ||
                value === 'Unknown'),
          }},[_vm._v(" "+_vm._s(value)+" ")])])}),0)]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }