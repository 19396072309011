var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"header"},[(_vm.showHamburger)?_c('div',{staticClass:"nav-section"},[_c('b-button',{staticClass:"hamburgler",attrs:{"size":"is-small","icon-left":"bars"},on:{"click":function($event){return _vm.hamburgerClick()}}}),_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"ml-2 mt-1",attrs:{"src":_vm.swoosh,"alt":"AquaSeca Logo"}})])],1):_vm._e(),_c('div',{staticClass:"nav-section"},[(_vm.showBreadcrumbs)?_c('vue-breadcrumbs',{attrs:{"separator":'>'}}):_vm._e()],1),_c('div',{staticClass:"nav-section is-pulled-right"},[(_vm.$store.getters.requiresScopeSelection)?_c('b-tooltip',{attrs:{"label":"Change the scope you are working within","position":"is-left"}},[_c('b-navbar-item',{attrs:{"title":"Change which customer you are viewing"},on:{"click":function($event){return _vm.scopeSelectClicked()}}},[_c('b-icon',{attrs:{"icon":_vm.iconFor('customer')}}),(_vm.$store.getters.topLevel.object)?_c('span',[_vm._v(_vm._s(_vm.$store.getters.topLevel.object.name))]):_vm._e()],1)],1):_vm._e(),_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'notifications' }}},[_c('b-icon',{attrs:{"icon":_vm.iconFor('notifications'),"type":_vm.$route.name === 'notifications' ||
          _vm.$route.name.startsWith('notifications:')
            ? 'is-primary'
            : null}}),_c('span',{class:{
          'icon-text': true,
          'has-text-primary':
            _vm.$route.name === 'notifications' ||
            _vm.$route.name.startsWith('notifications:'),
        }},[_vm._v(" "+_vm._s(_vm.messages.length ? _vm.messages.length : "")+_vm._s(_vm.messages && _vm.messages.length && _vm.messages.length % 10 === 0 ? "+" : "")+" ")])],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }