import FirestoreDataClass from "./_baseClasses";

export default class UserMessage extends FirestoreDataClass {
  static collectionName = "user_messages";

  /**
   *
   * @param flow_type {'flow'|'noflow'|'unknown'}
   * @param source_id {String}
   * @returns {Promise<unknown>}
   */
  classifyClip({ flow_type, source_id }) {
    const self = this;
    if (self.alarm.topic !== "source_selection") {
      throw "Cannot classify clip for message without `source_selection` topic";
    }
    return new Promise(function (resolve, reject) {
      self.constructor.$client
        .post(
          `${self.constructor.APIEndpointSegment}/${self.id}/classify_clip/`,
          { flow_type, source_id }
        )
        .then(() => resolve())
        .catch((e) => reject(e));
    });
  }

  get $canBeDismissed() {
    return this.get("alarm.topic") !== "calibrate";
  }

  get $displayString() {
    return this.get("alarm.description");
  }
}
