<template>
  <div>
    <div
      class="columns"
      v-if="userAccount.id && currentUser.uid !== userAccount.id"
    >
      <div class="column is-8 is-offset-2">
        <b-message
          type="is-danger"
          size="is-small"
          has-icon
          icon="exclamation"
          closable
          title="Editing another user"
        >
          You are logged in with the email <b>{{ currentUser.email }}</b
          >. If you wish to edit your own profile, go
          <router-link
            :to="{ name: 'profile', params: { userId: currentUser.uid } }"
            >here
          </router-link>
          .
        </b-message>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="card">
          <div v-if="!userId" class="card-header">
            <div class="card-header-title">Add new user</div>
          </div>
          <div v-if="!userAccount || loading" class="card-content">
            <loading-card />
          </div>
          <div class="card-content" v-else>
            <div class="media" v-if="userId">
              <div v-if="additional.photoUrl" class="media-left">
                <img :src="additional.photoUrl" />
              </div>
              <div class="media-content">
                <div class="media-name subtitle">
                  <b>{{ additional.display_name }}</b
                  ><span v-if="userId"> - </span>{{ $route.meta.title }}
                </div>
              </div>
            </div>
            <hr v-if="userId" />
            <transition name="slide">
              <router-view v-if="$route.name !== 'profile'" />
            </transition>
            <transition name="slide">
              <div v-if="$route.name === 'profile'">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item has-text-weight-bold">
                      Email address:
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      {{ userAccount.email }}
                    </div>
                  </div>
                </div>
                <div class="level">
                  <div class="level-left">
                    <div class="level-item has-text-weight-bold">
                      Phone number:
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      {{ userAccount.phone }}
                    </div>
                  </div>
                </div>
                <div class="level" v-if="accountCreatedAt">
                  <div class="level-left">
                    <div class="level-item has-text-weight-bold">
                      Account created:
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      {{
                        additional.createdAt
                          ? additional.createdAt.toLocaleDateString()
                          : "Not saved"
                      }}
                    </div>
                  </div>
                </div>
                <flags
                  v-if="userAccount.id && (currentUser.uid === userAccount.id || $store.state.user_roles.is_root)"
                  :user-account="userAccount"
                  @update:user-account="$store.commit('setUserAccount', $event)"
                />
              </div>
            </transition>
            <div>
              <transition name="slide">
                <div class="buttons mt-5" v-if="$route.name === 'profile'">
                  <b-button
                    expanded
                    v-for="route in children"
                    :key="route.name"
                    :icon-left="route.meta.icon"
                    tag="router-link"
                    :to="{
                      name: route.name,
                      params: { userId: $route.params.userId },
                    }"
                  >
                    {{ route.meta.title }}
                  </b-button>
                  <b-button
                    expanded
                    icon-left="lock-open"
                    :loading="passwordResetLoading"
                    :disabled="passwordResetLoading"
                    @click="passwordResetClicked()"
                    type="is-warning"
                  >
                    Reset password
                  </b-button>
                </div>
              </transition>
            </div>
            <transition name="slide">
              <div
                class="has-text-centered"
                v-if="userId && $route.name !== 'profile'"
              >
                <b-button
                  expanded
                  class="mt-3"
                  tag="router-link"
                  :to="{ name: 'profile', params: $route.params }"
                  icon-left="chevron-left"
                >
                  Back to profile
                </b-button>
              </div>
              <div class="has-text-centered" v-if="$store.state.user_roles.is_root">
                <b-button expanded class="mt-3" @click="toggleDarkMode()">
                    Toggle dark-mode (<span class="has-text-danger">Beta</span>)
                </b-button>
              </div>
            </transition>
            <div v-if="$store.state.user_roles.is_admin">
              <hr />
              <b-button
                expanded
                class="mt-3"
                tag="router-link"
                :to="{ name: 'administration:users' }"
                icon-left="chevron-left"
              >
                User administration home
              </b-button>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userDataMixin from "../../mixins/userDataMixin";
import LoadingCard from "../../components/Skeletons/LoadingCard";

import Flags from "./Flags";

export default {
  name: "ProfileHome",
  components: {
    Flags,
    LoadingCard,
  },
  mixins: [userDataMixin],
  data() {
    return {
      children: [],
      passwordResetLoading: false,
    };
  },
  mounted() {
    this.getChildren(this.$route);
  },
  methods: {
    toggleDarkMode() {
      document.body.classList.toggle("dark");
    },
    getChildren(current) {
      const route = this.$router.options.routes.find(
        (route) => route.name === current.name
      );
      if (
        route &&
        Array.isArray(route.children) &&
        route.name !== "profile:new"
      ) {
        this.children = route.children.filter((cd) => {
          return cd.meta.showAsButton;
        });
      } else if (route) {
        this.children = [];
      }
    },
    passwordResetClicked() {
      let self = this,
        additionalInfo = `
          After clicking below you should receive an email with a password reset link. If you do not, do the following:
          <ul>
            <li>Check your spam folder</li>
            <li>Give up to 5 minutes while waiting for message</li>
            <li>Whitelist noreply@.firebaseapp.com in your account</li>
            <li>Ask your IT department to whitelist above address</li>
          </ul>
        `;
      self.$buefy.dialog.confirm({
        icon: "redo",
        message: `
          <div class="content">
            <p>This will send a password reset email to <b>${self.additional.email}</b>.</p>
            <p>${additionalInfo}</p>
            <p>Would you like to continue?</p>
          </div>
        `,
        onConfirm() {
          self.passwordResetLoading = true;
          self.$client
            .get("/users/send_password_reset/" + self.additional.uid)
            .then((res) => {
              self.$handleSuccess(
                `A password reset email has been sent to '${self.additional.email}'`
              );
            })
            .catch((e) => {
              self.$handleError("There was an error. Please try again.", e);
            })
            .finally(() => {
              self.passwordResetLoading = false;
            });
        },
      });
    },
    updateHook() {
      this.$forceUpdate();
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.getChildren(val);
      },
    },
    additional: {
      handler() {
        this.updateHook();
      },
    },
    "$store.state.user_roles": {
      handler() {
        this.getUserData();
        this.updateHook();
      },
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
