<template>
  <b-table
      :data="similarityTableData"
      title="POU: Risk of confusion"
  >
    <b-table-column
        label="Comment"
        v-slot="props"
    >
      {{ capitalizeFirstLetter(props.row.Comment) }}
    </b-table-column>
    <b-table-column
        label="Distance"
        field="Distance"
        numeric
        sortable
        v-slot="props">
      {{ props.row.Distance.toFixed(3) }}
    </b-table-column>
    <b-table-column
      label="Norm2 Dist."
      field="norm2_dist"
      numeric
      sortable
      v-slot="props">
      {{ props.row.norm2_dist.toFixed(3)}}
    </b-table-column>
    <b-table-column
      label="POU 1"
      v-slot="props">
      <source-display
        :value="getSource(props.row.source_1)"
        :show-icon="false"
        :show-link-icon="false"
        :show-tooltip="false"
        show-parents
      />
    </b-table-column>
    <b-table-column
      label="POU 2"
      v-slot="props">
      <source-display
        :value="getSource(props.row.source_2)"
        :show-icon="false"
        :show-link-icon="false"
        :show-tooltip="false"
        show-parents
      />
    </b-table-column>
  </b-table>
</template>

<script>
import {get} from 'lodash';
import {getObjectInArrayById} from "../scripts/filterHelpers";

export default {
  name: "SourceTrainingSimilarityTable",
  props: {
    similarityTableData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    sourceIds() {
      let ids = new Set();
      this.similarityTableData.forEach(row => {
        ids.add(this.toId(row.source_1));
        ids.add(this.toId(row.source_2));
      });
      return Array.from(ids);
    },
    sources() {
      return this.sourceIds.map(_id => {
        let val = getObjectInArrayById(
            this.$store.state.sources,
            _id,
            null
        );
        if (!val) {
          console.error(`Could not find 'sources/${_id}`);
        }
        return val
      })
    }
  },
  methods: {
    get,
    getSource(ref) {
      return getObjectInArrayById(
          this.sources,
          this.toId(ref),
          {},
      )
    },
    sourceClicked(src) {
      this.$emit('input', src);
    }
  }
}
</script>

<style scoped>

</style>