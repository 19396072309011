<template>
  <b-field :label="schema.title" :message="schema.description">
    <b-datetimepicker
      :value="_value"
      @input="$emit('input', formatter($event))"
      :datetime-formatter="formatter"
      :datetime-parser="parser"
      placeholder="Click to select a date and time"
    >
    </b-datetimepicker>
  </b-field>
</template>

<script>
export default {
  name: "DateTimePickerElement",
  props: {
    naive: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
      required: true,
    },
    value: {
      default: null,
    },
  },
  computed: {
    _value() {
      if (!this.value) {
        return;
      }
      if (this.value instanceof Date) {
        return this.value;
      } else if (typeof this.value === "string") {
        return new Date(this.value);
      } else {
        return this.value;
      }
    },
  },
  methods: {
    parser(value) {
      if (value && typeof value === "string") {
        return new Date(value);
      }
    },
    formatter(date) {
      if (date && date instanceof Date) {
        let isoString = date.toISOString();
        if (this.naive) {
          isoString = isoString.slice(0, -1);
        }
        return isoString;
      }
    },
  },
};
</script>
