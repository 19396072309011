<template>
  <div class="box m-6">
    <p>You are being logged out.</p>
  </div>
</template>

<script>
import "firebase/auth";

export default {
  name: "Logout",
  methods: {
    logOut() {
      let self = this;
      self.$fb
        .auth()
        .signOut()
        .then(() => {
          if (!this.$route.query.nomessage) {
            this.$openToast({
              message: "You have been logged out",
              type: "is-info",
              position: "is-bottom",
              duration: 6000,
            });
          }
        });
    },
  },
  created() {
    this.logOut();
  },
};
</script>

<style scoped></style>
