<template>
  <div>
    <router-view />

    <serial-number-input
      label="Scan a QR code or enter a serial number to view a device"
      @input="
        $router.push({ name: 'go-to-device', params: { serial: $event } })
      "
      :show-input="!cameraOpen"
    >
      <template #before>
        <qr-code-reader-button
          label=""
          @open-changed="cameraOpen = $event"
          @input="goToDevicePage"
        />
      </template>
    </serial-number-input>
  </div>
</template>

<script>

import QrCodeReaderButton from "../../../components/QrCodeReader/QrCodeReaderButton";
import SerialNumberInput from "../../../components/Inputs/SerialNumberInput";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import trackWindowSize from "../../../mixins/trackWindowSize";
import { getDeviceTypeFromSerial } from "../../../scripts/deviceQrCodes";
import extractDeviceInfoFromQRCode from "../../../scripts/deviceQrCodes";

export default {
  name: "SitesDirectory",
  mixins: [trackWindowSize],
  components: { QrCodeReaderButton, SerialNumberInput },
  data() {
    return {
      serialInput: "",
      cameraOpen: false,
    };
  },
  computed: {
    serialInputValid() {
      return this.serialInput && this.serialInput.length > 7;
    },
    isOnDetailPage() {
      return this.$route.name !== "devices";
    },
    siteId() {
      return this.isOnDetailPage ? this.$route.params.siteId : null;
    },
    rows() {
      let self = this;
      if (!self.$store.state.sites.length) {
        return [];
      }
      if (self.isOnDetailPage && self.siteId) {
        return [getObjectInArrayById(self.$store.state.sites, self.siteId)];
      }
      return self.$store.state.sites;
    },
  },
  methods: {
    /**
     *
     * @param {String} qrCodeOrSerial
     */
    goToDevicePage(qrCodeOrSerial) {
      let deviceType, serial;
      if (qrCodeOrSerial.length <= 9) {
        try {
          deviceType = getDeviceTypeFromSerial(qrCodeOrSerial);
          serial = qrCodeOrSerial;
        } catch (e) {
          this.$handleError(e, e);
        }
      } else {
        try {
          let data = extractDeviceInfoFromQRCode(qrCodeOrSerial);
          deviceType = data.deviceType;
          serial = data.serial;
        } catch (e) {
          this.$handleError(e, e);
        }
      }
      try {
        this.$router.push({ name: "go-to-device", params: { serial } });
      } catch (e) {
        this.$handleError(e, e);
      }
    },
    redirect() {
      if (this.$route.name === "devices") {
        this.$router.replace({
          name: "devices:area-list",
          query: this.$route.query,
        });
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.redirect();
      },
    },
    "$store.state.sites": {
      immediate: true,
      handler() {
        this.redirect();
      },
    },
  },
};
</script>

<style scoped></style>
