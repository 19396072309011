

export default {
  name: "onScopeChangeMixin",
  watch: {
    "$store.state.user": {
      handler() {
        this.loadSelectedScope();
      },
    },
    "$store.state.user_roles": {
      handler() {
        this.loadSelectedScope();
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler(val, oldVal) {
        if (val && oldVal && !val.startsWith(oldVal)) {
          this.$router.push({
            name: "administration:hierarchy",
          });
        } else {
          this.$forceUpdate();
        }
      },
    },
    children: {
      handler() {
        this.$forceUpdate();
      },
    },
    ancestors: {
      handler() {
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    this.loadSelectedScope();
  },
  methods: {
    loadSelectedScope() {
      let self = this;
      // console.log("LOAD SELECTED SCOPE for obj:", this.object);
      // console.log({
      //   1: !! typeof self.object === 'object'
      // })
      // console.log({
      //   2: !self.object || Object.keys(self.object).length === 0,
      // });
      // console.log({
      //   3: !!self.$store.state.user,
      // })
      // console.log({
      //   4: !!self.$store.state.user_roles,
      // })
      // console.log({
      //   5: !self.$store.state.selectedScopeIdStr,
      // })
      if (
        typeof self.object === "object" &&
        (!self.object || Object.keys(self.object).length === 0) &&
        self.$store.state.user &&
        self.$store.state.user_roles &&
        !self.$store.state.selectedScopeIdStr
      ) {
        if (
          self.$store.state.selectedScopeIdStr &&
          self.object &&
          self.object.id &&
          self.$store.state.selectedScopeIdStr.includes(self.object.id)
        ) {
          return;
        }

        self.$client
          .get(`/${self.endpointSegment}/${self.id}/`)
          .then((res) => {
            let idStrForObject = res.result.id_str,
              idStrToLoad =
                self.$store.state.user_roles.getHighestLevelScopeToLoadFromIdString(
                  idStrForObject
                );
            if (idStrToLoad) {
              self.$store.dispatch("setScopeFromIdStr", {
                id_str: idStrToLoad,
              });
            } else {
              self.$handleError(
                `Object not found or access not granted to '${self.endpointSegment}' object with id '${self.id}'`,
                `Object not found or access not granted to '${self.endpointSegment}' object with id '${self.id}'`
              );
            }
          })
          .catch((e) => {
            self.$handleError(
              `Could not fetch '${self.endpointSegment}' object with id '${self.id}' from server; see console.`,
              e
            );
          });
      }
    },
  },
};
