import isValue from "../../scripts/valueCheck";
import { extend, find, map } from "lodash";

/**
 * Throw error if hasAllProperties does not evaluate
 */
export function assertAllProperies(object, properties) {
  if (!hasAllProperties(object, properties)) {
    throw `Object does not have all properties from ${properties.toString()} (obj - ${object})`;
  }
}

/**
 * Throw error if hasSomeProperties does not evaluate
 */
export function assertSomeProperies(object, properties) {
  if (!hasSomeProperties(object, properties)) {
    throw `Object does not have some properties from ${properties.toString()}`;
  }
}

/**
 * Verify that all properties are present and have truthy values
 * @param object Object to test
 * @param properties Array of string identifiers for props to check
 * @returns Boolean
 */
export function hasAllProperties(object, properties, assertTruthiness) {
  if (!Array.isArray(properties)) {
    throw `Expected array; got ${typeof properties} instead`;
  }
  return (
    !object ||
    properties.every(
      (prop) =>
        Object.prototype.hasOwnProperty.call(object, prop) &&
        (!assertTruthiness || isValue(object[prop]))
    )
  );
}

/**
 * Verify that at least one of supplied properties is present and has truthy value
 * @param object Object to test
 * @param properties Array of string identifiers for props to check
 * @returns Boolean
 */
export function hasSomeProperties(object, properties, assertTruthiness) {
  if (!Array.isArray(properties)) {
    throw `Expected array; got ${typeof properties} instead`;
  }
  return (
    !object ||
    properties.some(
      (prop) =>
        Object.prototype.hasOwnProperty.call(object, prop) &&
        (!assertTruthiness || isValue(object[prop]))
    )
  );
}

/**
 * Replaces item in array with matching ID, or adds item to array
 * @param a1 {Array}
 * @param.with a2 {Array}
 * @returns {{with: (function(*=): Array)}}
 *
 * @example
 * var result = mergeById(
 *   [
 *     {id:'a',v:1},
 *     {id:'b',v:1}
 *   ]
 *  ).with(
 *   [
 *     {id:'b',v:2},
 *     {id:'c',v:2}
 *   ]
 * );
 * // [{id:'a',v:1},{id:'b',v:2},{id:'c',v:2}]
 */
export function mergeById(a1) {
  return {
    with: function (a2) {
      //
      if (!Array.isArray(a1)) {
        a1 = a1 ? [a1] : [];
      }
      if (!Array.isArray(a2)) {
        a2 = a2 ? [a2] : [];
      }
      if ((!a1 || !a1.length) && a2 && a2.length) {
        return a2;
      }
      if ((!a2 || !a2.length) && a1 && a1.length) {
        return a1;
      }
      let a1Ids = a1.map((a) => a.id).filter((aId) => !!aId),
        a2ToConcat = a2.filter((a) => !a1Ids.includes(a.id));
      return map(a1, function (item) {
        return extend(item, find(a2, { id: item.id }));
      }).concat(a2ToConcat);
    },
  };
}
