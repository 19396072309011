<template>
  <b-modal
      :active="active"
      @update:active="$emit('update:active', $event)"
      @close="$emit('update:active', $event)"
      has-modal-card
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">Edit location for {{ name }}</div>
      </div>
      <div class="modal-card-body">
        <slot></slot>
        <building-and-room
            :site="site"
            :selected-building-id="object.building ? toId(object.building) : null"
            :selected-room-id="object.room ? toId(object.room) : null"
            :installation-notes="object.installation_notes"
            :show-notes="showNotes"
            :disabled="loading"
            :building-editable="!toId(object) || !object.building"
            @input="toSave = $event"
        >
          <div class="well" v-if="showPipeLocation">
            <pipe-location-table
                can-add
                :area="(toSave.building && toSave.building.$area) || object.$area"
                :building="toSave.$building || (object.$area && object.$area.$building)"
                :rows="
                  $store.state.pipe_locations.filter(
                    (pl) =>
                      !pl.probe &&
                      (!pl.position || toId(pl.position) === toId(object)) &&
                      pl.building &&
                      object.building &&
                      pl.building.id === toId(object.building) &&
                      (!pl.room || toId(toSave.room) === toId(pl.room))
                  )
                "
                :loading="$store.state.fetching.pipe_locations"
                :title="`Select or create a pipe location for ${object.serial}`"
                :selected-id="toSave.pipe_location ? toId(toSave.pipe_location, 'pipe_locations') : initialPipeLocationId || null"
                :paginate_by="25"
                @input="handlePLSelection($event)"
            >
              <template #post-name/>
            </pipe-location-table>
          </div>
        </building-and-room>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button @click="$emit('update:active', false); $emit('close')" :disabled="loading">
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button :loading="loading"
                    type="is-primary"
                    @click="submit()"
                    :disabled="!canSubmit"
          >Submit
          </b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BuildingAndRoom from "./BuildingAndRoom";
import PipeLocationTable from "../../tables/PipeLocationTable";

function getData(object) {
  return {
    building: object.building,
    buildingId: object.buildingId,
    room: object.room,
    roomId: object.roomId,
    installation_notes: object.installation_notes,
    pipe_location: object.pipe_location
  };
}

export default {
  name: "BuildingAndRoomModal",
  components: {BuildingAndRoom, PipeLocationTable},
  data() {
    return {
      loading: false,
      toSave: {},
      // ...getData(this.object),
    };
  },
  created() {
    this.toSave = getData(this.object)
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    initialPipeLocationId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
    buildingEditable: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      required: true,
    },
    showNotes: {
      type: Boolean,
      default: true,
    },
    showPipeLocation: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    patchEndpoint() {
      return `/${this.object.APIEndpointSegment}/${this.object.id}/`
    },
    canSubmit() {
      return this.toSave.room &&
          (this.toSave.pipe_location || !this.showPipeLocation)
    }
  },
  methods: {
    /**
     *
     * @param pl {PipeLocation}
     */
    handlePLSelection(pl) {
      if (pl) {
        this.$store.commit('add', {
          name: 'pipe_locations',
          result: pl,
        });
        this.toSave = {...this.toSave, pipe_location: this.toFSRef(pl, 'pipe_locations')};
      } else {
        this.toSave = {...this.toSave, pipe_location:null}
      }
    },
    submit() {
      let self = this;
      self.loading = true;
      self.$client
          .patch(self.patchEndpoint, self.toSave)
          .then((res) => {
            // TODO: update object in app
            self.$handleSuccess(
                `${self.capitalizeFirstLetter(self.name)} has been updated`
            );
            self.$emit("saved", res.result);
            setTimeout(() => {
              self.$emit('update:active', false);
              self.$emit('close');
              self.loading = false;
            }, 200);
          })
          .catch((e) => {
            self.loading = false;
            this.$handleError(e, e);
          });
    },
  },
};
</script>

<style scoped></style>
