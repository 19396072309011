var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-3"},[(!_vm.schedule)?_c('p',[_vm._v("No schedule")]):_vm._e(),(_vm.schedule)?_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_c('b-field',{attrs:{"type":!_vm.schedule.name ? 'is-danger' : 'is-default',"message":{ 'Please enter a name': !_vm.schedule.name },"expanded":""}},[_c('b-input',{ref:"name",attrs:{"expanded":"","disabled":_vm.loading,"placeholder":"Name this schedule"},model:{value:(_vm.schedule.name),callback:function ($$v) {_vm.$set(_vm.schedule, "name", $$v)},expression:"schedule.name"}})],1)],1),_c('div',{staticClass:"card-header-icon"},[_c('b-button',{staticClass:"is-pulled-right",attrs:{"type":"is-danger","icon-left":_vm.iconFor('delete'),"disabled":_vm.loading,"loading":_vm.deleting},on:{"click":function($event){return _vm.deleteSchedule(_vm.schedule)}}})],1)]):_vm._e(),(_vm.schedule)?_c('div',{staticClass:"card-content"},[_c('b-field',{attrs:{"label":"Active","type":{ 'is-danger': !_vm.schedule.is_active },"message":{
        'If not active, this will not trigger any alerts':
          !_vm.schedule.is_active,
      }}},[_c('b-switch',{model:{value:(_vm.schedule.is_active),callback:function ($$v) {_vm.$set(_vm.schedule, "is_active", $$v)},expression:"schedule.is_active"}})],1),_c('b-field',{attrs:{"label":"Alarm type","message":{
        'Please select an alarm type': !Object.keys(_vm.alarmTypes).includes(
          _vm.schedule.alarm_type
        ),
      },"type":{
        'is-danger': !Object.keys(_vm.alarmTypes).includes(_vm.schedule.alarm_type),
      }}},[(_vm.schedule.id)?_c('p',[_vm._v(_vm._s(_vm.alarmTypes[_vm.schedule.alarm_type]))]):_vm._l((_vm.alarmTypes),function(readableType,type){return _c('b-radio',{key:type,attrs:{"disabled":_vm.schedule.id || _vm.loading,"native-value":type},on:{"input":function($event){$event ? _vm.assignTopic(_vm.schedule.alarm_type) : null}},model:{value:(_vm.schedule.alarm_type),callback:function ($$v) {_vm.$set(_vm.schedule, "alarm_type", $$v)},expression:"schedule.alarm_type"}},[_vm._v(" "+_vm._s(readableType)+" ")])})],2),_c('b-field',{attrs:{"label":"Scope","disabled":_vm.loading}},[_c('b-radio',{attrs:{"native-value":"building","disabled":_vm.loading},model:{value:(_vm.scopeType),callback:function ($$v) {_vm.scopeType=$$v},expression:"scopeType"}},[_vm._v(" An area ")]),_c('b-radio',{attrs:{"native-value":"sources","disabled":_vm.loading || _vm.schedule.alarm_type === 'unrecognized'},model:{value:(_vm.scopeType),callback:function ($$v) {_vm.scopeType=$$v},expression:"scopeType"}},[_vm._v(" Selected points-of-use only ")]),_c('b-radio',{attrs:{"native-value":"pipe_locations","disabled":_vm.loading || _vm.schedule.alarm_type !== 'temperature'},model:{value:(_vm.scopeType),callback:function ($$v) {_vm.scopeType=$$v},expression:"scopeType"}},[_vm._v(" Selected pipe locations ")])],1),_c('div',{staticClass:"ml-6"},[(['pipe_locations', 'sources'].includes(_vm.scopeType))?_c('b-field',{attrs:{"label":`${
          _vm.scopeType === 'sources' ? 'Points-of-use' : 'Pipe locations'
        } to include`,"type":{
          'is-danger': !_vm.schedule[_vm.scopeType] || !_vm.schedule[_vm.scopeType].length,
        },"message":!_vm.schedule[_vm.scopeType] || !_vm.schedule[_vm.scopeType].length
            ? 'You must select at least one ' +
              _vm.scopeType.slice(0, -1).replaceAll('_', ' ')
            : null}},[(_vm.scopeType === 'sources' && _vm.showHier)?_c('select-hierarchy',{attrs:{"options":_vm.hierOptions,"array-name":'sources',"site-ids":[_vm.site.id],"value":_vm.sourceIds,"return-transform-function":_vm.normalizer,"disabled":_vm.loading},on:{"change":function($event){_vm.$set(_vm.schedule, 'reference_path', null);
            _vm.$set(_vm.schedule, _vm.scopeType, $event);}}}):(_vm.showHier)?_c('select-hierarchy',{attrs:{"options":_vm.hierOptions,"array-name":'pipe_locations',"site-ids":[_vm.site.id],"value":_vm.pipeLocationIds,"return-transform-function":_vm.normalizer,"disabled":_vm.loading},on:{"change":function($event){_vm.$set(_vm.schedule, 'reference_path', null);
            _vm.$set(_vm.schedule, _vm.scopeType, $event);}}}):_vm._e()],1):_c('b-field',{attrs:{"label":"Area","type":{ 'is-danger': !_vm.selectedBuildingId },"message":{ 'Please select an area': !_vm.selectedBuildingId }}},_vm._l((_vm.buildings),function(building){return _c('b-radio',{key:building.id,attrs:{"disabled":_vm.loading,"native-value":building.id},model:{value:(_vm.selectedBuildingId),callback:function ($$v) {_vm.selectedBuildingId=$$v},expression:"selectedBuildingId"}},[_c('building-display',{attrs:{"value":building,"show-training-mode-tooltip":false,"show-training-mode-warning":""},scopedSlots:_vm._u([{key:"post",fn:function(){return [(!building.area)?_c('b-tooltip',{attrs:{"type":"is-danger","label":'This building (id:' +
                  building.id +
                  ') does not have an area assigned.'}},[_c('b-icon',{attrs:{"icon":"exclamation","type":"is-danger"}})],1):_vm._e()]},proxy:true}],null,true)})],1)}),1)],1),_c('transition',{attrs:{"name":"slide"}},[(_vm.schedule.alarm_type === 'noflow')?_c('b-field',{attrs:{"label":"Aggregate by room","message":"If active, will show information at the room level, not at POU level"}},[_c('b-checkbox',{model:{value:(_vm.schedule.room_aggregation),callback:function ($$v) {_vm.$set(_vm.schedule, "room_aggregation", $$v)},expression:"schedule.room_aggregation"}})],1):_vm._e()],1),_c('b-field',{staticClass:"mt-3",attrs:{"label":"Active hours","type":{
        'is-danger':
          !_vm.schedule.schedule ||
          !_vm.schedule.schedule.times ||
          !_vm.schedule.schedule.times.length,
      },"message":{
        'Please specify the times for this alarm':
          !_vm.schedule.schedule.times || !_vm.schedule.schedule.times.length,
      }}},[_c('div',{staticClass:"week-range-outer"},[_c('vue-week-time-range-picker',{attrs:{"has-half-hour":"","value":_vm.DOWFormatToTimerange(_vm.schedule.schedule.times)},on:{"input":function($event){_vm.set(_vm.schedule, 'schedule.times', _vm.timerangeToDOWFormat($event))},"select-all":function($event){_vm.schedule.schedule.description = 'Always'}}})],1)]),_c('b-field',{attrs:{"label":"Description of alarm period (i.e. 'After-hours')"}},[_c('b-input',{attrs:{"disabled":_vm.loading},model:{value:(_vm.schedule.schedule.description),callback:function ($$v) {_vm.$set(_vm.schedule.schedule, "description", $$v)},expression:"schedule.schedule.description"}})],1),_c('b-field',{attrs:{"label":"Do you want this alarm schedule to trigger SMS (text) messages?","type":{ 'is-danger': !_vm.alertTypes.includes(_vm.schedule.alert_type) },"message":{
        'Please select an alert type': !_vm.alertTypes.includes(
          _vm.schedule.alert_type
        ),
        'Info-level alarms will trigger notifications but not send text messages':
          _vm.schedule.alert_type === 'info',
        'Warning-level alarms will trigger notifications and text messages':
          _vm.schedule.alert_type === 'warning',
      }}},_vm._l((_vm.alertTypes),function(type){return _c('b-radio',{key:type,attrs:{"type":'is-' + (type === 'info' ? 'info' : 'danger'),"native-value":type,"disabled":_vm.loading},model:{value:(_vm.schedule.alert_type),callback:function ($$v) {_vm.$set(_vm.schedule, "alert_type", $$v)},expression:"schedule.alert_type"}},[_vm._v(" "+_vm._s(type === "info" ? "No" : "Yes")+" ("),_c('span',{class:'has-text-' + (type === 'info' ? 'info' : 'danger')},[_vm._v(_vm._s(type)+" ")]),_vm._v(" level) ")])}),1),_c('transition',{attrs:{"name":"slide"}},[(_vm.schedule.alarm_type === 'agg_filter_30min')?_c('b-message',{staticClass:"mt-4",attrs:{"title":"Aggregated flow threshold settings","closable":false}},[_c('b-field',{attrs:{"group-multiline":""}},[_c('div',{staticClass:"control"},[_c('b-field',{attrs:{"label":"Alert when flow detected for more than","type":{ 'is-danger': !_vm.schedule.criteria.minimum },"message":{
                'Please the amount of aggregated flow that will trigger an alarm':
                  !_vm.schedule.criteria.minimum,
              }}},[_c('time-picker',{attrs:{"max-minutes":59,"reset-value":"00:30","disabled":_vm.loading,"value":_vm.fromSecondsToTimePickerString(_vm.schedule.criteria.minimum)},on:{"input":function($event){_vm.set(
                    _vm.schedule,
                    'criteria.minimum',
                    _vm.fromTimePickerStringToSeconds($event)
                  )}}})],1)],1),_c('div',{staticClass:"control"},[_c('b-field',{attrs:{"label":"over a period of","type":{ 'is-danger': !_vm.schedule.criteria.minimum },"message":{
                'Please specify  time period to monitor for aggregated flow':
                  !_vm.schedule.criteria.minimum,
              }}},[_c('time-picker',{attrs:{"show-minutes":false,"reset-value":"03:00","disabled":_vm.loading,"value":_vm.fromPDStringToTimePickerString(_vm.schedule.criteria.agg_window)},on:{"input":function($event){_vm.set(
                    _vm.schedule,
                    'criteria.agg_window',
                    _vm.fromTimePickerStringToPDString($event)
                  )}}})],1)],1)])],1):_vm._e(),(_vm.schedule.alarm_type === 'noflow')?_c('b-message',{staticClass:"mt-4",attrs:{"title":"Low-flow/Stagnation settings","closable":false}},[_c('b-field',{attrs:{"label":"Alert if flow does not occur for at least","type":{ 'is-danger': !_vm.schedule.criteria.maximum },"message":{
            'Please specify flow required to prevent alerts':
              !_vm.schedule.criteria.maximum,
          }}},[_c('time-picker',{attrs:{"max-minutes":59,"reset-value":"00:30","disabled":_vm.loading,"value":_vm.fromSecondsToTimePickerString(_vm.schedule.criteria.maximum)},on:{"input":function($event){_vm.set(
                _vm.schedule,
                'criteria.maximum',
                _vm.fromTimePickerStringToSeconds($event)
              )}}})],1),_c('b-field',{attrs:{"label":"over a period of","type":{ 'is-danger': !_vm.schedule.criteria.agg_window },"message":{
            'Please specify the time period to monitor for lack of flow':
              !_vm.schedule.criteria.agg_window,
          }}},[_c('time-picker',{attrs:{"allowed-hours":_vm.aggWindowNoFlowChoices,"show-minutes":false,"reset-value":"72:00","disabled":_vm.loading,"value":_vm.fromPDStringToTimePickerString(_vm.schedule.criteria.agg_window)},on:{"input":function($event){_vm.set(
                _vm.schedule,
                'criteria.agg_window',
                _vm.fromTimePickerStringToPDString($event)
              )}}})],1)],1):_vm._e(),(_vm.schedule.alarm_type === 'temperature')?_c('b-message',{staticClass:"mt-4",attrs:{"title":"Temperature threshold settings","closable":false}},[_c('b-field',{attrs:{"label":"Generate an alarm","horizontal":""}},[_c('div',{staticStyle:{"display":"none"}},[_vm._v(".")])]),_c('b-field',{attrs:{"label":"when the","horizontal":"","type":{ 'is-danger': !_vm.schedule.criteria.aggregation_method },"message":{
            'Please select the calculated temperature value for this alarm schedule':
              !_vm.schedule.criteria.aggregation_method,
          }}},[_c('b-select',{model:{value:(_vm.schedule.criteria.aggregation_method),callback:function ($$v) {_vm.$set(_vm.schedule.criteria, "aggregation_method", $$v)},expression:"schedule.criteria.aggregation_method"}},_vm._l((_vm.aggregation_methods),function(name,value){return _c('option',{key:value,attrs:{"id":value},domProps:{"value":value}},[_vm._v(" "+_vm._s(name)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"temperature is","horizontal":"","type":{ 'is-danger': !_vm.schedule.criteria.operator },"message":{
            'Select a comparison method': !_vm.schedule.criteria.operator,
          }}},[_c('b-select',{model:{value:(_vm.schedule.criteria.operator),callback:function ($$v) {_vm.$set(_vm.schedule.criteria, "operator", $$v)},expression:"schedule.criteria.operator"}},_vm._l((_vm.criteriaOperators),function(name,value){return _c('option',{key:value,attrs:{"id":value},domProps:{"value":value}},[_vm._v(" "+_vm._s(name)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"a value of ","horizontal":"","type":{ 'is-danger': !_vm.schedule.criteria.limit }}},[_c('unit-conversion-input',{ref:"tempInput",attrs:{"display-prefix":"°","measurement":"temperature","convert-to-unit":"C","unit":"C","initial-value":_vm.schedule.criteria.limit,"decimals":2},on:{"input":function($event){_vm.schedule.criteria.limit = $event}}})],1),_c('b-field',{attrs:{"label":"aggregated over ","horizontal":"","type":{
            'is-danger': !(
              _vm.schedule &&
              _vm.schedule.criteria &&
              _vm.schedule.criteria.agg_window
            ),
          }}},[_c('b-select',{model:{value:(_vm.schedule.criteria.agg_window),callback:function ($$v) {_vm.$set(_vm.schedule.criteria, "agg_window", $$v)},expression:"schedule.criteria.agg_window"}},_vm._l((_vm.temperature_agg_windows),function(an_agg_window){return _c('option',{key:an_agg_window,domProps:{"value":an_agg_window}},[_vm._v(" "+_vm._s(an_agg_window)+" ")])}),0)],1),(
            _vm.schedule.criteria.limit &&
            _vm.schedule.criteria.aggregation_method &&
            _vm.schedule.criteria.agg_window &&
            _vm.$refs.tempInput
          )?_c('p',[_vm._v(" An alarm will be generated when the "),_c('b',[_vm._v(" "+_vm._s(_vm.aggregation_methods[ _vm.schedule.criteria.aggregation_method ].toLowerCase())+" ")]),_vm._v(" temperature is "),_c('b',[_vm._v(" "+_vm._s(_vm.schedule.criteria.operator))]),_c('b',[_vm._v(_vm._s(_vm.schedule.criteria.limit.toFixed(1))+"°C")]),(
              _vm.$refs.tempInput._data._unit &&
              _vm.$refs.tempInput._data._unit !== 'C'
            )?_c('span',[_vm._v(" ("),_c('b',[_vm._v(_vm._s(_vm.$refs.tempInput._data._value))]),_c('b',[_vm._v("°"+_vm._s(_vm.$refs.tempInput._data._unit))]),_vm._v(") ")]):_vm._e(),_vm._v(" over the last "),_c('b',[_vm._v(_vm._s(_vm.schedule.criteria.agg_window))])]):_vm._e()],1):_vm._e(),(
          _vm.schedule.alarm_type === 'user_filter' ||
          _vm.schedule.alarm_type === 'unrecognized'
        )?_c('b-message',{staticClass:"mt-4",attrs:{"title":"Flow threshold settings","closable":false}},[_c('b-field',{attrs:{"label":"Minimum flow length before alert","type":{ 'is-danger': !_vm.schedule.criteria.minimum },"message":{
            'Please specify how long a continuous flow should occur before triggering alerts':
              !_vm.schedule.criteria.minimum,
          }}},[_c('time-picker',{attrs:{"disabled":_vm.loading,"value":_vm.fromSecondsToTimePickerString(_vm.schedule.criteria.minimum),"reset-value":_vm.schedule.alert_type === 'info' ? '00:02' : '00:05'},on:{"input":function($event){_vm.set(
                _vm.schedule,
                'criteria.minimum',
                _vm.fromTimePickerStringToSeconds($event)
              )}}})],1)],1):_vm._e()],1),_c('FeatureFlagWrapper',{attrs:{"flag":"developer_mode","show-tooltip":""}},[_c('b-field',{attrs:{"label":"Webhook URL","type":{ 'is-danger': !_vm.isValidWebhook },"message":{
          'Please enter a valid url': !_vm.isValidWebhook,
          'Webhook (optional) will be sent a request via POST method with alarm data as payload when an alarm is triggered':
            _vm.isValidWebhook,
        }}},[_c('b-input',{model:{value:(_vm.schedule.webhook_url),callback:function ($$v) {_vm.$set(_vm.schedule, "webhook_url", $$v)},expression:"schedule.webhook_url"}})],1)],1)],1):_vm._e(),(_vm.schedule)?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"card-footer-item"},[_c('b-button',{staticClass:"is-pulled-right",attrs:{"disabled":!_vm.isValid,"loading":_vm.loading,"type":"is-success"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.schedule.id ? "Update schedule" : "Create schedule")+" ")])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }