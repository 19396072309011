/**
 * Override onPoll method to get to it
 */
export default {
  name: "intervalFunctionMixin",
  data() {
    return {
      _polling: null,
    };
  },
  props: {
    millisecondsPerPoll: {
      type: Number,
      default: 1000, // every second
    },
  },
  methods: {
    poll() {
      this._polling = setInterval(() => {
        this.onPoll();
      }, this.millisecondsPerPoll);
    },
    onPoll() {
      console.error("polling; please override method onPoll");
    },
    /**
     *
     * @param durationInMS {Number}
     * @returns {string}
     */
    timeConversion(durationInMS) {
      const portions = [],
        msInHour = 1000 * 60 * 60;
      let days = 0,
        hours = Math.trunc(durationInMS / msInHour);

      if (hours > 24) {
        days = Math.floor(hours / 24);
        hours = hours % 24;
      }

      if (days > 0) {
        portions.push(days + "d");
        durationInMS = durationInMS - days * 24 * msInHour;
      }

      if (hours > 0) {
        portions.push(hours + "h");
        durationInMS = durationInMS - hours * msInHour;
      }

      const msInMinute = 1000 * 60,
        minutes = Math.trunc(durationInMS / msInMinute);
      if (minutes > 0) {
        portions.push(minutes + "m");
        durationInMS = durationInMS - minutes * msInMinute;
      }

      const seconds = Math.trunc(durationInMS / 1000);
      if (seconds > 0) {
        portions.push(seconds + "s");
      }

      return portions.join(" ");
    },
  },
  beforeDestroy() {
    clearInterval(this._polling);
  },
  created() {
    this.onPoll();
    this.poll();
  },
};
