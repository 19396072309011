<template>
  <b-modal
    :active="active"
    @update:active="$emit('update:active', $event)"
    @close="$emit('update:active', false)"
    :can-cancel="true"
    has-modal-card
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          Run auto-threshold loop for sensors in
          <building-display :value="area.$building" classes="is-inline-block" />
        </div>
      </div>
      <div class="modal-card-body">
        <b-loading :is-full-page="false" :active="loading" />
        <p class="mb-3">
          Select the sensors you wish to run an auto-threshold loop on.
        </p>
        <b-table
          :data="positions"
          :loading="loading"
          checkable
          :checked-rows.sync="checkedRows"
          default-sort="serial"
          default-sort-direction="asc"
        >
          <b-table-column field="serial" sortable label="Serial" v-slot="props">
            {{ props.row.serial }}
          </b-table-column>
          <b-table-column
            field="analytics.autothreshold.completion_timestamp"
            label="Last auto-threshold completed"
            sortable
            v-slot="props"
          >
            {{
              get(
                props.row,
                "analytics.autothreshold.completion_timestamp",
                "Never"
              )
            }}
          </b-table-column>
          <b-table-column
            field="$calibrationState"
            label="Current state"
            sortable
            v-slot="props"
          >
            <b-tooltip
              :active="props.row.$isInTrainingMode"
              label="This sensor is already in training mode"
            >
              <span
                :class="{
                  'has-text-weight-bold has-text-primary':
                    props.row.$isInTrainingMode,
                  'has-text-success has-text-weight-bold':
                    props.row.$calibrationState === 'trained',
                }"
              >
                {{
                  capitalizeFirstLetter(
                    get(props.row, "$calibrationState", "Unknown")
                  )
                }}
              </span>
            </b-tooltip>
          </b-table-column>
          <b-table-column
            field="$calibrationState"
            label="Since"
            sortable
            v-slot="props"
          >
            {{
              get(props.row, "analytics.calibration.timestamp", "")
                ? get(
                    props.row,
                    "analytics.calibration.timestamp",
                    ""
                  ).toLocaleDateString("en-US") +
                  " " +
                  get(
                    props.row,
                    "analytics.calibration.timestamp",
                    ""
                  ).toLocaleTimeString("en-US")
                : ""
            }}
          </b-table-column>
          <b-table-column
            field="$building.name"
            label="Area"
            sortable
            v-slot="props"
          >
            <building-display
              :value="props.row.$building"
              v-if="props.row.$building"
              :show-floors="false"
            />
          </b-table-column>
          <b-table-column
            field="$room.name"
            label="Room"
            sortable
            v-slot="props"
          >
            <room-display :value="props.row.$room" />
          </b-table-column>
        </b-table>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button expanded @click="$emit('update:active', false)">
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button
            expanded
            type="is-success"
            @click="submitClicked()"
            :disabled="!(checkedRows && checkedRows.length)"
            :loading="loading"
          >
            Run on {{ checkedRows.length }} sensor{{
              checkedRows.length === 1 ? "" : "s"
            }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Area from "../../dataClasses/Area";
import { get } from "lodash";

export default {
  name: "SelectPositionsForAutoThresholdModal",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Area,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    positions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedRows: [],
    };
  },
  methods: {
    get,
    submitClicked() {
      if (this.checkedRows.length) {
        this.$emit(
          "input",
          this.checkedRows.map((p) => p.sensor_reference)
        );
        this.$emit("update:active", false);
      }
    },
    initializeCheckedRows() {
      this.checkedRows = this.positions.filter(
        (p) => !p.$lastAutothresholdTimestamp && !p.$isCurrentlyInAutothreshold
      );
    },
  },
  watch: {
    positions: {
      handler() {
        this.initializeCheckedRows();
      },
    },
    active: {
      immediate: true,
      handler(is, was) {
        this.initializeCheckedRows();
        if (!is && was) {
          this.$route.query.next ? this.$router.push(this.$route.query.next) : null
        }
      },
    },
  },
};
</script>

<style scoped></style>
