var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.object)?_c('div',[_c('generic-view',{attrs:{"editable":_vm.$store.state.user_roles.is_installer,"object-name":"point-of-use","parent-name":"area","parent-title":"Area","ancestors":_vm.ancestors,"parent":_vm.parent,"object":_vm.object,"state-var-name":_vm.endpointSegment},on:{"edit-clicked":function($event){return _vm.editClicked()}}},[_c('level',{attrs:{"values":_vm.levelArray}},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item has-text-weight-bold"},[_vm._v("Flow rate (mean)")])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('unit-conversion-display',{attrs:{"value":_vm.get(_vm.object, 'flow_rate.typical', ''),"measurement":"volumeFlowRate","decimals":3}})],1)])]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item has-text-weight-bold"},[_vm._v("Tags/Groups")])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[(_vm.object.tags && _vm.object.tags.length)?_c('b-taglist',_vm._l((_vm.object.tags),function(tag){return _c('b-tag',{key:tag},[_vm._v(_vm._s(tag))])}),1):_c('p',[_vm._v("None")])],1)])])]),_c('div',{staticClass:"mt-5 mb-5"},[(true)?_c('aq-image-upload',{attrs:{"parent-object-path":_vm.object.$FSRef.path,"category":"install_picture"}}):_vm._e()],1),(_vm.user_roles.is_root)?_c('div',{staticClass:"mt-5 mb-5"},[_vm._v(" Assigned to area "),(_vm.area)?_c('router-link',{attrs:{"to":{ name: 'administration:view-internal-area', params: { id: _vm.area.id } }}},[_vm._v(_vm._s(_vm.area.name)+" ")]):_vm._e(),_vm._v(" . Click "),_c('router-link',{attrs:{"to":{
            name: 'utilities:source-detail',
            query: {
              detailAreas: _vm.object.$area.id,
              siteId: _vm.object.$site.id,
              viewby: 'area'
            }
          }}},[_vm._v(" here ")]),_vm._v(" to view point-of-use detail for this area. ")],1):_vm._e(),(_vm.$store.state.user_roles.is_root)?_c('div',[_c('div',[(!_vm.sensorDetectionTableOpen)?_c('a',{on:{"click":function($event){_vm.sensorDetectionTableOpen = true}}},[_c('b-icon',{attrs:{"icon":"chevron-down","size":"is-small"}}),_vm._v(" Show sensor detection details (root only) ")],1):_c('a',{staticClass:"mb-1",on:{"click":function($event){_vm.sensorDetectionTableOpen = false}}},[_c('b-icon',{attrs:{"icon":"chevron-up","size":"is-small"}}),_vm._v(" Hide sensor detection details ")],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('source-sensor-detection-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.sensorDetectionTableOpen),expression:"sensorDetectionTableOpen"}],attrs:{"src":_vm.object}})],1)],1):_vm._e()],1),_c('add-source-modal',{attrs:{"building":_vm.object.$building,"parent":_vm.object.$parent,"room":_vm.object.$room,"id":_vm.id,"inputs":_vm.object},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}})],1):_c('div',[_c('b-message',{attrs:{"type":"is-error"}},[_vm._v(" No object found with that ID")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }