<template>
  <div class="gateway-detail-wrapper">
    <table class="table is-striped is-bordered is-fullwidth">
      <tbody>
        <tr v-for="(value, name) in info" :key="name">
          <td class="has-text-weight-bold">{{ name }}</td>
          <td v-if="name === 'Bundle' && $store.state.user_roles.is_root">
            <b-icon icon="spinner" class="fa-spin" v-if="bundleLoading" />
            <router-link
              v-else-if="bundle.id"
              :to="{
                name: 'administration:shipments:view',
                params: { id: bundle.id },
              }"
            >
              {{ value }}
            </router-link>
            <i v-else>No bundle found</i>
          </td>
           <td v-else-if="name === 'Shipped to'">
            <router-link
              v-if="value"
              :to="{
                name: 'administration:view-subcustomer',
                params: { id: value.id },
              }"
            >
              {{ value.$displayString }}
            </router-link>
            <i v-else>n/a</i>
          </td>
          <td
            v-else-if="gatewayPosition"
            :class="{
              'has-text-danger':
                name === 'Last seen' &&
                (gatewayPosition.$lastSeenClassification !== 'success' ||
                  value === 'Unknown'),
            }"
          >
            <span v-if="gatewayPosition && name === 'Last seen'">
              <b-icon
                icon="cloud-upload-alt"
                :type="gatewayPosition.$isHealthy ? 'is-success' : 'is-danger'"
                :class="gatewayPosition.$isHealthy ? '' : 'fa-throb'"
              />
            </span>
            {{ value }}
          </td>
          <td v-else>
            {{value}}
          </td>
        </tr>
      </tbody>
    </table>
    <slot></slot>
  </div>
</template>

<script>
import shortHumanizer from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import intervalFunctionMixin from "../mixins/intervalFunctionMixin";
import { get } from "lodash";

export default {
  name: "GatewayDetailTable",
  mixins: [intervalFunctionMixin],
  data() {
    return {
      bundle: { name: "Loading..." },
      bundleLoading: false,
      millisecondsSinceLastSeen: null,
      subcustomer: null,
    };
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
    gatewayPosition: {
      type: Object,
      required: false,
    },
    serial: {
      type: String,
      required: true,
    },
  },
  computed: {
    lastSeen() {
      return this.position && this.position.$lastSeen
        ? this.position.$lastSeen
        : null;
    },
    position() {
      return this.gatewayPosition;
    },
    info() {
      let _ = {};

      _["Bundle"] = this.bundle ? this.bundle.name : "Unknown";
      if (this.position) {
        let firmwareV = this.gatewayPosition
          ? get(this.gatewayPosition, "telemetry.version.firmware.app", null)
          : null;
        if (firmwareV) {
          _["Firmware"] = firmwareV;
        }
        _["Last seen"] =
        this.millisecondsSinceLastSeen !== null
          ? shortHumanizer(this.millisecondsSinceLastSeen) + " ago"
          : "Unknown";
      }
      if (this.$store.state.user_roles.is_root) {
        _["Kit name"] = this.gateway ? this.gateway.kitid : 'Not available';
      }

      _["Serial"] = this.serial;
      _["Shipped to"] = this.subcustomer;
      return _;
    },
    subcustomerRef() {
      return get(this.bundle, "bundle_state.subcustomer", null);
    },
  },
  methods: {
    onPoll() {
      this.millisecondsSinceLastSeen = this.position ? this.position.$msSinceLastSeen : null;
    },

    getBundle() {
      let self = this;
      if (!self.gateway) {
        self.bundle = {};
        return;
      }
      self.bundleLoading = true;
      this.$dataClasses.Bundle.queryForSingle([
        ["devices", "array_contains", self.toFSRef(self.gateway, "gateways")],
      ])
        .then((result) => {
          self.bundle = result ? result : {};
        })
        .catch((e) => self.$handleError(e, e))
        .finally(() => {
          self.bundleLoading = false;
        });
    },
  },
  watch: {
    gateway: {
      immediate: true,
      handler(val, oldVal) {
        if (val && (!oldVal || Object.keys(oldVal).length === 0)) {
          this.getBundle();
        }
      },
    },
    subcustomerRef: {
      immediate: true,
      handler(val) {
        if (val) {
          let _id = this.toId(val);
          if (
            this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            )
          ) {
            this.subcustomer = this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            );
          } else {
            this.$dataClasses.SubCustomer.fetchById(this.toId(val))
              .then((subcustomer) => (this.subcustomer = subcustomer))
              .catch((e) => (this.subcustomer = null));
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.gateway-detail-wrapper {
  width: 100%;
  margin: 0 auto;
}
</style>
