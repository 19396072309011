import { getObjectInArrayById } from "../scripts/filterHelpers";
import SourceDisplay from "../components/Display/SourceDisplay";
import RoomDisplay from "../components/Display/RoomDisplay";
import BuildingDisplay from "../components/Display/BuildingDisplay";
import SiteDisplay from "../components/Display/SiteDisplay";
import Vue from "vue";
import { get } from "lodash";

var SourceComponentClass = Vue.extend(SourceDisplay);
var RoomComponentClass = Vue.extend(RoomDisplay);
var BuildingComponentClass = Vue.extend(BuildingDisplay);
var SiteComponentClass = Vue.extend(SiteDisplay);

export default {
  name: "MostRecentMessageNotificationMixin",
  watch: {
    "$store.state.messages": {
      handler(messageList) {
        if (
          this.$store.state.selectedScopeIdStr &&
          this.$store.state.user_roles &&
          messageList &&
          messageList.length
        ) {
          let lastMessageDt = messageList[0].last_update_dt;
          if (
            lastMessageDt &&
            this.$store.state.mostRecentMessageReceivedAt < lastMessageDt
          ) {
            this.$store.commit("setMostRecentMessageReceivedAt", lastMessageDt);
          }
        } else {
          this.$store.commit(
            "setMostRecentMessageReceivedAt",
            new Date(2100, 12, 31, 23, 59)
          );
        }
      },
    },
    "$store.state.mostRecentMessageReceivedAt": {
      handler(val, oldVal) {
        let self = this,
          lastMessage =
            this.$store.state.messages && this.$store.state.messages.length
              ? this.$store.state.messages[0]
              : null;
        if (!lastMessage) {
          return;
        }
        let site = get(lastMessage, "alarm.site", null)
            ? getObjectInArrayById(
                self.$store.state.sites,
                lastMessage.alarm.site,
                null
              )
            : null,
          _source = get(lastMessage, "alarm.flow_data.source", null),
          source = _source
            ? getObjectInArrayById(
                self.$store.state.sources,
                lastMessage.alarm.flow_data.source,
                null
              )
            : null,
          room = source
            ? getObjectInArrayById(
                self.$store.state.rooms,
                source.room,
                null
              )
            : null,
          building = source
            ? getObjectInArrayById(
                self.$store.state.buildings,
                source.building,
                null
              )
            : null;

        if (val > oldVal && lastMessage && self.$store.state.user_roles) {
          let sourceInstance = source
              ? new SourceComponentClass({
                  propsData: { value: source },
                })
              : null,
            roomInstance = room
              ? new RoomComponentClass({
                  propsData: { value: room },
                })
              : null,
            buildingInstance = building
              ? new BuildingComponentClass({
                  propsData: { value: building },
                })
              : null,
            siteInstance = site
              ? new SiteComponentClass({
                  propsData: { value: site },
                })
              : null;
          let id = "-" + Math.random().toString(5).replace(".", "");
          this.$buefy.snackbar.open({
            message: `
                                <p>New message: <b>${lastMessage.heading}</b></p>
                                <div id="site-${id}"></div>
                                <div id="building-${id}"></div>
                                <div id="room-${id}"></div>
                                <div id="source-${id}"></div>
                        `,
            type: `is-${lastMessage.alarm.topic}`,
            position: "is-bottom-right",
            duration: 8000,
            actionText: "View",
            queue: false,
            onAction() {
              self.$router.push({
                name: "notifications:view",
                params: { id: lastMessage.id },
              });
            },
          });
          if (sourceInstance) {
            sourceInstance.$mount(document.getElementById("source-" + id));
          }
          if (roomInstance) {
            roomInstance.$mount(document.getElementById("room-" + id));
          }
          if (buildingInstance) {
            buildingInstance.$mount(document.getElementById("building-" + id));
          }
          if (siteInstance) {
            siteInstance.$mount(document.getElementById("site-" + id));
          }
        }
      },
    },
  },
};
