<template>
  <div>
    <transition name="slide">
      <loading-chart-card v-if="anyLoading"/>
    </transition>
    <transition name="slide">
      <div v-if="!anyLoading">
        <div class="mb-1">
          <b-field
              label="Email addresses"
              :message="fieldErrors.email_addresses"
              :custom-class="fieldErrors.email_addresses ? 'has-text-danger' : null"
              :type="fieldErrors.email_addresses ? 'is-danger' : 'is-success'"
          >
            <b-taginput
                v-model="formValue.email_addresses"
                :create-tag="(tag)=>tag?tag.toLowerCase().trim():null"
                :before-adding="validateEmail"
            />
          </b-field>
          <b-field
              label="Cadence"
              :message="fieldErrors.cadence"
              :custom-class="fieldErrors.cadence ? 'has-text-danger' : null"
              :type="fieldErrors.cadence ? 'is-danger' : 'is-success'"
          >
            <b-select v-model="formValue.frequency.cadence">
              <option v-for="i in ['month', 'week', 'day']" :key="i" :value="i">
                Every {{ i }}
              </option>
            </b-select>
          </b-field>
          <b-field
              label="Day(s) of month"
              v-if="formValue.frequency.cadence === 'month'"
              :custom-class="fieldErrors.days_of_month ? 'has-text-danger' : null"
              :message="fieldErrors.days_of_month"
              :type="fieldErrors.days_of_month ? 'is-danger' : 'is-success'"
          >
            <div class="boxx">
              <b-checkbox
                  v-model="formValue.frequency.days_of_month"
                  v-for="i in Array(31).keys()"
                  :key="i"
                  :native-value="(i + 1).toString()"
              >
                {{ i + 1 }}
              </b-checkbox>
            </div>
          </b-field>
          <div class="boxx mb-4">
            <b-field
                label="Day(s) of week"
                v-if="formValue.frequency.cadence === 'week'"
                :custom-class="fieldErrors.days_of_week ? 'has-text-danger' : null"
                :message="fieldErrors.days_of_week"
                :type="fieldErrors.days_of_week ? 'is-danger' : 'is-success'"
            >
              <b-checkbox
                  v-for="(weekday, index0) in weekdays"
                  :key="weekday"
                  :native-value="index0"
                  :value="formValue.frequency.days_of_week"
                  @input="formValue.frequency = {...formValue.frequency, days_of_week: $event}"
              >
                {{ weekday }}
              </b-checkbox>
            </b-field>
          </div>
          <div class="boxx mb-4">
            <b-field
                label="Hour of day"
                v-if="formValue.frequency.cadence"
                :custom-class="fieldErrors.hours ? 'has-text-danger' : null"
                :message="fieldErrors.hours"
                :type="fieldErrors.hours ? 'is-danger' : 'is-success'"
            >
              <b-checkbox v-model="formValue.frequency.hours" :native-value="0">
                Midnight
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="1">
                1AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="2">
                2AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="3">
                3AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="4">
                4AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="5">
                5AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="6">
                6AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="7">
                7AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="8">
                8AM
              </b-checkbox>
              <b-checkbox v-model="formValue.frequency.hours" :native-value="9">
                9AM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="10"
              >
                10AM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="11"
              >
                11AM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="12"
              >
                Noon
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="13"
              >
                1PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="14"
              >
                2PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="15"
              >
                3PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="16"
              >
                4PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="17"
              >
                5PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="18"
              >
                6PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="19"
              >
                7PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="20"
              >
                8PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="21"
              >
                9PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="22"
              >
                10PM
              </b-checkbox>
              <b-checkbox
                  v-model="formValue.frequency.hours"
                  :native-value="23"
              >
                11PM
              </b-checkbox>
            </b-field>
          </div>
        </div>
        <timezone-picker
            v-if="formValue && formValue.frequency"
            v-model="formValue.frequency.timezone"

        />
        <b-field
            label="Note"
            message="If you enter a note here, it will be included in the emails sent out"
        >
          <b-input v-model="formValue.note" maxlength="300" type="textarea"/>
        </b-field>
        <report-form
            v-if="gotten && !loading"
            :initial="{
            ...formValue.parameters,
            report_name: formValue.report_name,
          }"
            :category="selectedCategory"
            :fields-to-hide="[
            'dt_start',
            'dt_end',
            'start_timestamp',
            'end_timestamp',
          ]"
            :loading="loading"
            @submit="handleSubmit($event)"
            :report-name="formValue.report_name"
            @update:report-name="formValue.report_name=$event"
        >
          <template #post-buttons>
            <b-button
                @click="deletePrompt()"
                v-if="canBeDeleted"
                type="is-danger"
            >
              Delete this subscription
            </b-button>
          </template>
        </report-form>

      </div>
    </transition>
  </div>
</template>

<script>
import {get} from "lodash";
import ReportForm from "../Usage/ReportForm";
import TimezonePicker from "../../components/Selects/TimezonePicker";

import store from "../../store";
import LoadingChartCard from "../../components/Skeletons/LoadingChartCard";

function getDefaultFormValue() {
  return {
    email_addresses: [store.state.user.email],
    report_name: null,
    parameters: {},
    frequency: {
      cadence: "week",
      days_of_month: [],
      days_of_week: [],
      hours: [],
      timezone: get(store.getters.site, 'timezone', null),
    },
    note: "",
  };
}

export default {
  name: "ReportSubscriptionForm",
  components: {
    LoadingChartCard,
    ReportForm,
    TimezonePicker,
  },
  data() {
    return {
      gotten: false,
      allCategories: this.$dataClasses.ReportEmailSubscription.allCategories,
      formValue: getDefaultFormValue(),
      loading: false,
    };
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    anyLoading() {
      return this.loading ||
          Object.values(this.$store.state.fetching).some(i => i);
    },
    fieldErrors() {
      if (!this.formValue) {
        return {}
      }
      let it = {
        email_addresses:
            this.formValue.email_addresses.length > 0
                ? null
                : "Please enter at least one email address",
        report_name: this.formValue.report_name
            ? null
            : "This field is required",
        cadence: this.formValue.frequency.cadence
            ? null
            : "This field is required",
        days_of_month:
            this.formValue.frequency.cadence !== "month" ||
            this.formValue.frequency.days_of_month.length > 0
                ? null
                : "Please select one of more days of month you'd like to receive this report on",
        days_of_week:
            (this.formValue.frequency.cadence !== "week" ||
            this.formValue.frequency.days_of_week.length > 0)
                ? null
                : "Please select one or more days of week to receive report on",
        hours: this.formValue.frequency.hours.length > 0
            ? null
            : "Please select hour(s) when you'd like to receive this report",
        timezone: get(this.formValue.frequency, 'timezone', null)
            ? null
            : "Please select a valid timezone",
      };
      return it
    },
    weekdays() {
      return [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
    },
    selectedCategory: {
      get() {
        return this.$route.query.reportCategory || "";
      },
      set(val) {
        this.queryReplace({reportCategory: val});
      },
    },
    canBeDeleted() {
      return this.formValue && this.formValue.id;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.formValue);
    },
    validateEmail(email) {
      let valid = email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (!valid) {
        this.$buefy.toast.open({
          message: `Please enter a valid email address; you entered '${email}'`,
          position: "is-bottom",
          type: "is-danger",
        });
      }
      return valid;
    },
    handleSubmit({values, selectedSchemaObj}) {
      if (!this.formValue.created_by) {
        this.formValue.created_by = this.$store.state.user_account.$FSRef;
      }
      this.formValue.parameters = values;
      this.formValue.report_name = selectedSchemaObj.report_name;
      if (Object.values(this.fieldErrors).filter((i) => !!i).length > 0) {
        this.$handleError("The form above contains errors; Please fix and try again");
      } else {
        this.loading = true;
        let obj = new this.$dataClasses.ReportEmailSubscription(this.formValue);
        obj
            .save()
            .then(obj => {
              this.formValue = obj;
              this.$handleSuccess("Your subscription has been saved");
              this.$emit("submit");
            })
            .catch(e => this.$handleError("Error saving this report subscription", e))
            .finally(() => {
              this.loading = false;
            });
      }
    },
    deletePrompt() {
      const self = this;
      this.$buefy.dialog.confirm({
        type: "is-danger",
        message:
            "Are you sure you want to delete this subscription? This will affect all subscribers.",
        title: "Delete email subscription?",
        onConfirm() {
          self.delete();
        },
      });
    },
    delete() {
      if (this.canBeDeleted) {
        this.formValue.delete().then(() => {
          this.$emit("input");
          this.$handleSuccess("This subscription has been deleted");
          this.$router.push({name: "report-subscriptions"});
        });
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loading = true;
          this.$dataClasses.ReportEmailSubscription.fetchById(val)
              .then((obj) => {
                this.formValue = obj;
                this.gotten = true;
              })
              .catch((e) => {
                this.$handleError("Could not load object", e);
                this.$router.push({name: "report-subscriptions"});
              })
              .finally(() => (this.loading = false));
        } else {
          this.formValue = getDefaultFormValue();
          this.gotten = true;
        }
      },
    },
  },
};
</script>

<style scoped>
.boxx :deep(.field) {
  display: block;
}
</style>
