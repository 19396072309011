<template>
  <div class="tile dashboard-widget is-child is-short">
    <p class="widget-title">Devices Installed</p>
    <div class="widget-content">
      <p>
        <span class="widget-left-count">
          {{ site.$gatewayCount }}
        </span>
        Base Station{{ site.$gatewayCount !== 1 ? "s" : "" }} (Gateway{{
          site.$gatewayCount !== 1 ? "s" : ""
        }})
      </p>
      <p>
        <span class="widget-left-count">
          {{ site.$sensorCount }}
        </span>
        Sensor{{ site.$sensorCount !== 1 ? "s" : "" }}
      </p>
    </div>
    <router-link
      class="widget-bottom-link"
      :to="{ name: 'devices:site-detail' }"
    >
      View devices
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DevicesInstalled",
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
