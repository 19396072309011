import firebase from "firebase/app";

export default function serialize(x) {
  /*
   * Serializes data to be submitted to the FS REST API
   */
  if (isArray(x)) {
    return traverseArray(x);
  } else if (x instanceof Date) {
    return {
      _type: "date",
      value: x.toISOString(),
    };
  } else if (x instanceof firebase.firestore.GeoPoint) {
    return {
      _type: "geopoint",
      latitude: x.latitude,
      longitude: x.longitude,
    };
  } else if (x instanceof firebase.firestore.DocumentReference) {
    return {
      _type: "reference",
      path: x.path,
    };
  } else if (x instanceof firebase.firestore.DocumentSnapshot) {
    return serialize(x.ref);
  } else if (typeof x === "object" && x !== null) {
    return traverseObject(x);
  } else {
    return x;
  }
}

function traverseArray(arr) {
  let value = [];
  arr.forEach(function (x) {
    value.push(serialize(x));
  });
  return value;
}

function traverseObject(obj) {
  let value = {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      value[key] = serialize(obj[key]);
    }
  }
  return value;
}

function isArray(o) {
  return Object.prototype.toString.call(o) === "[object Array]";
}
