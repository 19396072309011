<template>
  <div :class="classes">
    <slot name="pre-icon" />
    <b-icon :icon="icon" />
    Gateway: <b>{{ value.serial }}</b>
    <slot>
      <span v-if="showLinkIcon" class="is-pulled-right mr-3">
        <b-icon
          :icon="linkIcon"
          type="is-link"
          :class="{ pointer: true }"
          @click.native="
            $router.push(getLinkFunction(value));
            $event.preventDefault();
          "
        />
      </span>
    </slot>
    <slot name="end" />
  </div>
</template>

<script>
import iconFor from "../../icons";

export default {
  name: "GatewayPositionDisplay",
  computed: {
    icon() {
      return iconFor("gateway_position");
    },
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    linkIcon: {
      type: String,
      default: iconFor("update"),
    },
    getLinkFunction: {
      type: Function,
      default: function (pos) {
        return this.$router.match({
          name: "go-to-device",
          params: { serial: pos.serial },
        });
      },
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.inline-block {
  display: inline-block;
}
</style>
