<template>
  <div>
    <b-table
      :data="rows"
      striped
      bordered
      narrowed
      v-if="sensorDetection && sensorDetection.length"
      detail-transition="fade"
    >
      <b-table-column field="serial" label="Serial" sortable v-slot="props">
        {{ props.row.serial }}
        <router-link
          class="has-text-justified is-pulled-right"
          :to="{ name: 'go-to-device', params: { serial: props.row.serial } }"
        >
          <b-icon :icon="iconFor('device')" size="is-small" />
        </router-link>
      </b-table-column>
      <b-table-column
        field="nb_seconds"
        label="Seconds"
        sortable
        numeric
        v-slot="props"
      >
        {{ props.row.nb_seconds }}
      </b-table-column>
      <b-table-column
        field="hwaddr_raw"
        label="Address"
        sortable
        v-slot="props"
      >
        {{ props.row.hwaddr_raw }}
      </b-table-column>
      <b-table-column
        field="pct_gain3_pre"
        label="% Gain"
        sortable
        numeric
        v-slot="props"
      >
        {{ props.row.pct_gain3_pre ? props.row.pct_gain3_pre.toFixed(2) : "" }}
      </b-table-column>
    </b-table>
    <i v-else>No sensor detection data found</i>
  </div>
</template>

<script>
import iconFor from "../icons";

export default {
  name: "AreaSourceDetailTable",
  props: {
    src: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rows() {
      return this.sensorDetection;
    },
    sensorDetection() {
      return this.src && this.src._sensor_detection
        ? this.src._sensor_detection
        : [];
    },
  },
  methods: {
    iconFor,
  },
};
</script>

<style scoped></style>
