<template>
  <div v-if="displayComponent && value" :class="classes">
    <a
      class="inline-block is-xs pointer"
      v-if="collapsed"
      @click="collapsed = !collapsed"
    >
      +
    </a>
    <component
      classes="inline-block"
      v-if="!collapsible || (!collapsed && value)"
      v-bind:is="displayComponent"
      v-bind="$props"
      :value="value"
    >
      <template #pre-icon>
        <a
          class="inline-block is-xs pointer"
          v-if="collapsible && !collapsed"
          @click="collapsed = !collapsed"
        >
          -
        </a>
      </template>
      <template #end>
        <slot name="end" />
      </template>
    </component>
    <div v-else-if="!value">Unknown value: {{ value }}</div>
  </div>
</template>

<script>
import SiteDisplay from "./SiteDisplay";
import SourceDisplay from "./SourceDisplay";
import BuildingDisplay from "./BuildingDisplay";
import RoomDisplay from "./RoomDisplay";
import PositionDisplay from "./PositionDisplay";
import PipeLocationDisplay from "./PipeLocationDisplay";
import GatewayPosition from "./GatewayPositionDisplay";

const collectionToDisplayComponent = {
  sites: SiteDisplay,
  sources: SourceDisplay,
  buildings: BuildingDisplay,
  rooms: RoomDisplay,
  positions: PositionDisplay,
  gateway_positions: GatewayPosition,
  pipe_locations: PipeLocationDisplay,
};

export default {
  name: "GenericDisplay",
  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    showLinkIcon: {
      type: Boolean,
      default: true,
    },
    linkIconNoPull: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    collection() {
      return this.value && this.value._reference
        ? this.value._reference.path.split("/")[0]
        : null;
    },
    displayComponent() {
      if (this.collection) {

        let comp = collectionToDisplayComponent[this.collection];
        if (!comp) {
          throw new Error(`Unknown collection for generic display: '${this.collection}'. Expected one of: ${Object.keys(collectionToDisplayComponent).join(',')}`)
        }
        return comp
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.inline-block {
  display: inline-block;
}
</style>
