<template>
  <div class="has-freeform-input">
    <b-radio
      v-for="option in options"
      :type="type"
      :key="getOptionValue(option)"
      @input="valueUpdated($event)"
      :value="value"
      :native-value="getOptionValue(option)"
    >
      <slot name="label" :option="option">
        {{ getOptionLabel(option) }}
      </slot>
    </b-radio>
    <transition name="slide">
      <b-input
        class="mt-2"
        v-if="showFreeformInput"
        :value="value"
        :validation-message="
          value && value.toLowerCase() === 'other'
            ? 'Please select an option above or enter a value'
            : null
        "
        :type="value && value.toLowerCase() === 'other' ? 'is-danger' : type"
        placeholder="Select a value from above or enter a new value"
        @input="valueUpdated($event)"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: "SelectOrAddFreeformOtherOption",
  props: {
    type: {
      type: String,
      default: null,
    },
    /**
     * @typedef {Array.<String|{value:Any,label:String}>} options
     */
    options: {
      type: Array,
      default: () => ["other"],
    },
    standardOptions: {
      type: Array,
      default: () => ["other"],
    },
    value: {
      type: String,
      required: false,
    },
  },
  computed: {
    refinedValue() {
      return this.options
        .map((opt) => this.getOptionValue(opt))
        .includes(this.value)
        ? this.value
        : this.options.filter(
            (opt) =>
              opt &&
              this.getOptionValue(opt) &&
              this.getOptionValue(opt).toLowerCase() === "other"
          )[0];
    },
    showFreeformInput() {
      return this.refinedValue && this.refinedValue.toLowerCase() === "other";
    },
  },
  methods: {
    getOptionValue(option) {
      // return option;
      if (typeof option === "string") {
        return option;
      } else {
        return option.value;
      }
    },
    getOptionLabel(option) {
      // return option;
      if (typeof option === "string") {
        return option;
      } else {
        return option.label;
      }
    },
    valueUpdated(value) {
      this.$emit("input", value);
      this.$emit("update:value", value);
    },
  },
};
</script>

<style scoped></style>
