<template>
  <div>
    <div v-if="loading">
      <loading-card :sections="['body', 'footer']"/>
      <hr/>
      <loading-card :sections="['body']"/>
    </div>
    <div v-else>
      <b-loading :is-full-page="false" :active="submitting"/>
      <b-field
          label="Display name"
          :message="errors.display_name"
          :type="errors.display_name ? 'is-danger' : 'is-success'"
      >
        <b-input
            v-model="inputs.display_name"
            :disabled="submitting"
            autocomplete="off"
        />
      </b-field>
      <b-field
          label="Email address"
          :message="errors.email"
          :type="errors.email ? 'is-danger' : 'is-success'"
      >
        <b-input
            v-model="inputs.email"
            :disabled="submitting"
            autocomplete="off"
        />
      </b-field>
      <b-field
          label="Password"
          v-if="!userId"
          :message="errors.password"
          :type="errors.password ? 'is-danger' : null"
      >
        <b-input
            v-model="inputs.password"
            type="password"
            autocomplete="off"
            :disabled="submitting"
            password-reveal
        >
        </b-input>
      </b-field>
      <b-field
          label="Phone number"
          :message="errors.phone"
          :type="errors.phone ? 'is-danger' : 'is-success'"
          :class="{ 'is-danger': phoneHasError }"
      >
        <vue-tel-input
            :class="{ 'has-errors': phoneHasError }"
            :disabled="submitting"
            expanded
            required
            validCharactersOnly
            autocomplete="off"
            mode="international"
            v-model="inputs.phone"
            @validate="onPhoneValidate($event)"
        />
        <div v-if="phoneHasError" class="is-error">
          Please enter a valid phone number
        </div>
      </b-field>
      <b-field label="Enable SMS texts for subscriptions (flow alerts, etc.)">
        <b-switch v-model="inputs.sms_enabled" :disabled="loading"/>
      </b-field>
      <b-field label="Default measurement system">
        <select-measurement-system
            v-model="inputs.measurement_system"
            :disabled="loading"
        />
      </b-field>
      <b-field
          label="Membership"
          :type="errors.parent_path ? 'is-danger' : null"
          :message="
          errors.parent_path || 'To what organization does this user belong?'
        "
      >
        <b-message v-if="editingMembership || !inputs.parent_path" class="mb-0 no-bottom">
          <customer-hierarchy-selection
              :cascade-select="false"
              @lowest-selected="
              inputs.parent_path =
                $event && $event.id_str ? $event.id_str : null;
              inputs = Object.assign({}, inputs);
            "
              down-to-scope="site"
              :allow-at-root="$store.state.user_roles.is_root"
          />
          <div class="buttons">
            <b-button
                @click="editingMembership = false"
                :disabled="!inputs.parent_path"
                class="mt-4"
                type="is-primary is-outlined"
            >
              Set membership path
            </b-button>
            <b-button
                @click="editingMembership = false"
                class="mt-4"
            >
              Cancel
            </b-button>
          </div>
        </b-message>
        <b-message v-else class="mb-0 no-bottom">
          <p>
            This user belongs to
            <b>{{ parentPathReadableString }}.</b>
          </p>
          <b-button v-if="allowEditOrDelete" @click="editingMembership = true">
            Click here to edit membership
          </b-button>
        </b-message>
      </b-field>
      <div
          v-if="(!userId && inputs.parent_path) || (userAccount && userRoles)"
          class="mb-5"
      >
        <hr/>
        <b-field
            :message="
            errors.roles || 'What resources does this user have access to?'
          "
            :type="errors.roles ? 'is-danger' : null"
        >
          <user-roles
              v-if="!loading"
              v-model="roles"
              :disabled="submitting"
              :parent-path="userId ? userAccount.parent_path : inputs.parent_path"
              :editable="
              $store.state.user_roles &&
              $store.state.user_roles.canEditPath(inputs.parent_path)
            "
              @validates="validRoles = $event ? $event : false"
          />
        </b-field>
      </div>
      <div v-if="allowEditOrDelete" class="mb-5" @click="deleteUserPrompt">
        <b-button
            type="is-danger"
            class="is-pulled-right mb-5"
            icon-left="times"
        >Delete user
        </b-button>
      </div>
      <div>
        <b-button
            class="is-pulled-right mb-5 is-primary"
            expanded
            @click="submitClicked()"
            icon-right="chevron-right"
            :disabled="!valid"
            :loading="submitting"
        >Submit
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {pick} from "lodash";
import LoadingCard from "../../components/Skeletons/LoadingCard";
import UserRoles from "../../components/Roles/UserRoles";
import userDataMixin from "../../mixins/userDataMixin";

import SelectMeasurementSystem from "../../components/Selects/SelectMeasurementSystem";


export default {
  name: "ModifyUser",
  components: {
    LoadingCard,
    SelectMeasurementSystem,
    UserRoles,
  },
  mixins: [userDataMixin],
  data() {
    return {
      editingMembership: !this.userId,
      phoneHasError: false,
      submitting: false,
      validRoles: false,
      inputs: {measurement_system: null},
      roles: [],
      sms_enabled: false,
    };
  },
  computed: {
    parentPathReadableString() {
      return this.readableNameFromIDStr(this.inputs.parent_path) || '...';
    },
    valid() {
      return (
          !this.errors ||
          Object.values(this.errors).filter((e) => !!e).length === 0
      );
    },
    errors() {
      return {
        parent_path: !this.inputs.parent_path ? "This field is required" : null,
        phone:
            this.inputs.phone && this.phoneHasError
                ? "Please enter a valid phone number"
                : null,
        password:
            !this.userId &&
            (!this.inputs.password || this.inputs.password.length < 8)
                ? "Password must have at least 8 characters"
                : null,
        display_name: !this.inputs.display_name
            ? "This field is required"
            : null,
        email:
            !this.inputs.email ||
            !this.inputs.email.includes("@") ||
            !this.inputs.email.includes(".")
                ? "Please enter a valid email address"
                : null,
        roles: !this.validRoles ? "Please enter valid roles/permissions" : null,
      };
    },
  },
  mounted() {
    this.assignInputs();
  },
  methods: {
    deleteUserPrompt() {
      let self = this,
          isSelf = this.userAccount.id === this.currentUserAccount.id;
      this.$buefy.dialog.confirm({
        type: "is-danger",
        title: "Delete user?",
        message:
            `
            <p>Are you sure you want to delete the user account for <b>${this.userAccount.email}</b>?</p>
          ` +
            (isSelf
                ? `
            <p><b class="has-text-danger">WARNING:</b> You are removing your own account. You will lose access if you continue.</p>
          `
                : ""),
        onConfirm: () => {
          self.loading = true;
          self.userAccount
              .delete()
              .then(() => {
                self.$handleSuccess(
                    `User account for ${self.userAccount.email} has been deleted`
                );
                self.$router.push(
                    isSelf ? {name: "logout"} : {name: "administration:users"}
                );
              })
              .catch((e) => self.$handleError("Error deleting user", e))
              .finally(() => {
                self.loading = false;
              });
        },
      });
    },
    readableNameFromIDStr(idString) {
      let object = this.$store.getters.objectByIDString(idString),
          _type = this.$store.getters.objectTypeByIDString(idString);
      return `${
          object && object.name ? object.name : _type === "Root" ? "" : "..."
      } ${_type ? `(${_type})` : ""}`;
    },
    assignInputs() {
      this.inputs = Object.assign(
         {},
          pick(this.userAccount._additional, "display_name", "email",)
      );
      this.inputs.measurement_system = this.userAccount.measurement_system;
      this.inputs.parent_path = this.userAccount.parent_path;
      this.inputs.phone = this.userAccount.phone;
      this.inputs.sms_enabled = this.userAccount.sms_enabled || false;
      this.roles = this.userRoles._roles_data;
    },
    onPhoneValidate(a) {
      if (typeof a === "string") {
        this.phoneHasError === !!a;
      } else {
        this.phoneHasError = a.number.input && !a.valid;
      }
    },
    submitClicked() {
      let self = this;
      if (
          self.roles
              .map((r) => r.access)
              .some((path) => {
                return (
                    path &&
                    (self.inputs.parent_path.length > path.length ||
                        !path.startsWith(self.inputs.parent_path))
                );
              })
      ) {
        self.$buefy.dialog.confirm({
          title: "Roles outside membership",
          message: `
            This user has permissions/roles outside of the scope of their membership path. Are you sure you want to continue?
          `,
          onConfirm() {
            self.submit();
          },
        });
      } else {
        self.submit();
      }
    },
    submit() {
      let self = this,
          data = {...self.inputs};
      data["roles"] = self.roles;
      data["parent_path"] = self.inputs.parent_path;
      data["sms_enabled"] = self.inputs.sms_enabled;

      self.submitting = true;
      self.$dataClasses.UserAccount.save(self.userId, data)
          .then((result) => {
            if (self.userId === self.$store.state.user.uid) {
              self.$store.dispatch("getUserAccount", {
                callback: self.getUserData,
              });
            }
            if (self.userId) {
              self.$handleSuccess("This user has been updated");
            } else {
              self.$handleSuccess("This user has been added");
              self.$router.push({
                name: "profile:edit",
                params: {userId: result.id},
              });
            }
            self.editingMembership = false;
          })
          .catch((e) => {
            self.$handleError(e, e);
          })
          .finally(() => {
            self.submitting = false;
            if (self.userId) {
              self.getUserData();
              self.assignInputs();
            }
          });
    },
  },
  watch: {
    userAccount: {
      handler(val) {
        this.assignInputs();
        this.$forceUpdate();
      },
    },
    userRoles: {
      handler(val) {
        this.assignInputs();
        this.$forceUpdate();
      },
    },
  },
};
</script>

<style scoped>
.no-bottom {
  margin-bottom: 0 !important;
}
</style>
