import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";

// Sets up VeeValidator with rules and component registration
//
export default function registerVueFormulate() {
    Vue.use(VueFormulate, {
        classes: {
            outer: "field",
            wrapper: "control",
            input: "input",
            error: "help is-error",
            errors: "has-errors",
            // label: '',
            // element: '',
            help: "help is-danger has-errors",
        },
    });
}