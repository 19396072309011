import Vue from "vue";
import {replace} from "lodash";
import {ToastProgrammatic as Toast} from "buefy";
import store from "../store";

export default function registerToastHandlers() {
    Vue.prototype.$replaceTagChars = function (str) {
        return replace(replace(str, "<", "‹"), ">", "›");
    }

    /**
     * Consistent way to display and print errors.
     * @param {string, error} message Message to display
     * @param {string, error} [error] If truthy: will be thrown (and output to console)
     * @param {{hasHtml:boolean}} [options] Options object will be passed to Buefy Toast instance
     */
    Vue.prototype.$handleError = function (message, error, options = {hasHtml: false}) {
        if (!options.hasHtml) {
            message = this.$replaceTagChars(message);
        }
        let combinedOptions = Object.assign(
            {
                message: message,
                type: "is-danger",
                position: "is-bottom",
                duration: 8000,
            },
            options
        );
        if (message) {
            this.$openToast(combinedOptions);
        }
        if (error) {
            throw error;
        }
    }

    /**
     * Displays a 'success' style toast
     * @param {string} message
     * @param {{hasHtml:boolean}} [options]
     */
    Vue.prototype.$handleSuccess = function (message, options) {
        if (!options) {
            options = {};
        }
        if (!options.hasHtml) {
            message = this.$replaceTagChars(message);
        }
        let combinedOptions = Object.assign(
            {
                message: message,
                type: "is-success",
                position: "is-bottom",
                duration: 8000,
            },
            options
        );
        this.$openToast(combinedOptions);
    }

    /**
     * Generic toast triggering function
     * @param {Object} toastOptions
     */
    Vue.prototype.$openToast = function (toastOptions) {
        if (!toastOptions.message) {
            throw "Toast requires a message";
        }
        if (!toastOptions.position) {
            toastOptions.position = "is-bottom";
        }
        let toast = Toast.open(toastOptions);
        store.commit("toastOpened", {
            position: toastOptions.position,
            toast: toast,
        });
    }
}
