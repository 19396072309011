<template>
  <div class="probe-detail-wrapper is-fullwidth" style="width: 100%">
    <table class="table is-striped is-bordered is-fullwidth">
      <tbody>
        <tr v-for="(value, name) in info" :key="name">
          <td class="has-text-weight-bold">{{ name }}</td>
          <td v-if="name === 'Installed'">
            <b-icon
              size="is-small"
              :icon="value ? 'check' : 'times'"
              :type="`is-${value ? 'success' : 'danger'}`"
            />
          </td>
          <td v-else-if="name === 'Sensor' && value">
            <router-link
              :to="{ name: 'devices:sensor-detail', params: { serial: value } }"
              >{{ value }}
            </router-link>
          </td>
          <td v-else-if="name === 'Shipped to'">
            <router-link
              v-if="value"
              :to="{
                name: 'administration:view-subcustomer',
                params: { id: value.id },
              }"
            >
              {{ value.$displayString }}
            </router-link>
            <i v-else>n/a</i>
          </td>
          <td v-else-if="name === 'Bundle'">
            <div v-if="value">
              <router-link
                :to="{
                  name: 'administration:shipments:view',
                  params: { id: value.id },
                }"
                v-if="$store.state.user_roles.is_root"
              >
                {{ value.$displayString }}
              </router-link>
              <span v-else>{{ value.$displayString }}</span>
            </div>
            <div v-else><i>n/a</i></div>
          </td>
          <td v-else>
            <span v-if="!!value">{{ value }}</span>
            <i v-else>n/a</i>
          </td>
        </tr>
      </tbody>
    </table>
    <slot />
  </div>
</template>

<script>
import { get } from "lodash";

export default {
  name: "ProbeDetailTable",
  props: {
    probe: {
      type: Object,
      required: true,
    },
    bundle: {
      type: Object,
      required: false,
    },
    sensorPosition: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      subcustomer: null,
    };
  },
  computed: {
    info() {
      const _ = {};
      _["Installed"] = !!this.sensorSerial;
      _["Model"] = this.model;
      _["Port"] = this.port;
      _["Bundle"] = this.bundle;
      _["Shipped to"] = this.subcustomer;
      // _["Bundle"] = get(this.probe, "device_state.bundle");
      _["Sensor"] = this.sensorSerial;
      _["Serial"] = this.serial;
      _["Type"] = this.type;
      return _;
    },
    subcustomerRef() {
      return get(this.bundle, "bundle_state.subcustomer", null);
    },
    model() {
      return this.portEntryInPosition ? this.portEntryInPosition.model : null;
    },
    portEntryInPosition() {
      if (!(this.sensorPosition && this.serial)) {
        return null;
      }
      if (this.sensorPosition.port_0.serial === this.serial) {
        return this.sensorPosition.port_0;
      } else if (this.sensorPosition.port_1.serial === this.serial) {
        return this.sensorPosition.port_1;
      } else if (this.sensorPosition.port_2.serial === this.serial) {
        return this.sensorPosition.port_2;
      }
      return null;
    },
    port() {
      if (!(this.sensorPosition && this.serial)) {
        return null;
      }
      if (this.sensorPosition.port_0.serial === this.serial) {
        return 0;
      } else if (this.sensorPosition.port_1.serial === this.serial) {
        return 1;
      } else if (this.sensorPosition.port_2.serial === this.serial) {
        return 2;
      }
      return null;
    },
    sensorSerial() {
      return this.sensorPosition ? this.sensorPosition.serial : null;
    },
    serial() {
      return this.probe ? this.probe.serial : null;
    },
    type() {
      return this.portEntryInPosition ? this.portEntryInPosition.type : null;
    },
  },
  watch: {
    subcustomerRef: {
      immediate: true,
      handler(val) {
        if (val) {
          let _id = this.toId(val);
          if (
            this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            )
          ) {
            this.subcustomer = this.$store.getters.objectByID(
              _id,
              null,
              this.$store.state.subcustomers
            );
          } else {
            this.$dataClasses.SubCustomer.fetchById(this.toId(val))
              .then((subcustomer) => (this.subcustomer = subcustomer))
              .catch((e) => (this.subcustomer = null));
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
