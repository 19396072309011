<template>
  <div>
    <site-devices v-if="$route.name==='devices:site-detail'"/>
    <router-view v-else/>

    <transition name="slide">
      <div class="card" v-if="showUninstalledSection">
        <div class="card-header">
          <div class="card-header-title">
            Un-installed devices shipped to
            <i class="ml-1">{{ subcustomer.name }}</i>
          </div>
        </div>
        <div class="card-content">
          <device-table
              :loading="uninstalledDevicesLoading"
              :rows="uninstalledDevices"
              :show-installation-location="false"
          >
            <template #select-column>
              <b-table-column v-slot="props">
                <b-button
                    type="is-primary"
                    outlined
                    rounded
                    class="is-pulled-right"
                    icon-right="chevron-right"
                    @click="handleDeviceClick(props.row)"
                    size="is-small"
                >
                  {{
                    props.row.$deviceType === "probe" ? "Attach" : "Add"
                  }}
                  this {{ props.row.$deviceType }}
                </b-button>
              </b-table-column>
            </template>
          </device-table>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {uniqBy} from "lodash";
import SerialNumberInput from "../../../components/Inputs/SerialNumberInput";
import {getChildrenOfId} from "../../../scripts/filterHelpers";
import DeviceTable from "../../../tables/DeviceTable";

export default {
  name: "SiteDetail",
  components: {DeviceTable, SerialNumberInput},
  data() {
    return {
      serialInput: "",
      uninstalledGateways: [],
      uninstalledSensors: [],

      uninstalledDevices: [],
      uninstalledDevicesLoading: false,

      showOnRoutes: ['devices', 'devices:area-list', 'devices:site-detail']
    };
  },
  computed: {
    showUninstalledSection() {
      return this.showOnRoutes.includes(this.$route.name) && this.subcustomer;
    },
    subcustomer() {
      return this.$store.state.subcustomers.length ? this.$store.state.subcustomers[0] : null;
    },
    siteId() {
      return this.$store.getters.site ? this.$store.getters.site.id : null;
    },
    site() {
      return this.$store.getters.site;
    },
    areaIds() {
      if (this.siteId && this.siteId !== "uninstalled") {
        return uniqBy(
            getChildrenOfId(
                this.$store.state.areas.filter(
                    (a) => a.devices && a.devices.length
                ),
                this.site.id,
                []
            ).map((a) => a.id),
            "id"
        );
      }
      return [];
    },
  },
  methods: {
    handleDeviceClick(device) {
      let deviceType = device.$deviceType;
      if (deviceType === "probe") {
        device.$goToAttachProbe();
      } else if (deviceType === "sensor") {
        device.$goToAddSensor();
      } else if (deviceType === "gateway") {
        this.$router.push({
          name: "add-gateway",
        });
      }
    },
    loadUninstalledDevices() {
      if (!this.subcustomer) {
        this.uninstalledDevices = [];
        return;
      }
      const queries = [
            ["device_state.event_type", "==", "device_shipped"],
            ["device_state.subcustomer", "==", this.subcustomer.$FSRef],
          ],
          urlParams = {order_field: "device_state.event_type", paginate_by: 250};
      this.uninstalledDevicesLoading = true;
      this.uninstalledDevices = [];
      Promise.all([
        this.$dataClasses.Gateway.query(queries, urlParams),
        this.$dataClasses.Probe.query(queries, urlParams),
        this.$dataClasses.Sensor.query(queries, urlParams),
      ])
          .then((resultLists) => {
            resultLists.forEach((resultList) => {
              this.uninstalledDevices = [
                ...this.uninstalledDevices,
                ...resultList,
              ];
            });
          })
          .catch((e) => this.$handleError("Could not load devices", e))
          .finally(() => (this.uninstalledDevicesLoading = false));
    }
  },
  watch: {
    showUninstalledSection: {
      immediate: true,
      handler(shouldShow, wasShown) {
        if (shouldShow && !wasShown) {
          this.loadUninstalledDevices()
        }
      }
    },
  },
};
</script>

<style scoped></style>
