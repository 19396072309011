<template>
  <div>
    <input type="hidden" v-if="hidden && !showHidden" :value="value" />
    <div v-else :class="{ hidden, 'is-schema-field': true }">
      <component
        :is="type"
        :schema="schema"
        :value="value"
        :name="name"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script>
import FormElementWrapper from "../../components/elements/FormElementWrapper";
import TextElement from "../../components/elements/TextElement";
import TextareaElement from "../../components/elements/TextareaElement";
import NumberElement from "../../components/elements/NumberElement";
import SelectElement from "../../components/elements/SelectElement";
import CheckboxElement from "../../components/elements/CheckboxElement";
import CheckboxGroupElement from "../../components/elements/CheckboxGroupElement";
import RadioButtonElement from "../../components/elements/RadioButtonElement";
import FormElementMultiple from "../../components/elements/FormElementMultiple";
import DatePickerElement from "../../components/elements/DatePickerElement";
import DateTimePickerElement from "../../components/elements/DateTimePickerElement";

export default {
  name: "FormElement",
  props: {
    name: {
      type: String,
      default: "",
    },
    schema: {
      type: Object,
      required: true,
    },
    showHidden: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
  },
  computed: {
    type() {
      let component = TextElement;
      if (this.schema.widget !== undefined) {
        // allow the schema to specify widget
        // currently only textarea is supported
        if (this.schema.widget === "textarea") {
          component = TextareaElement;
        }
      } else if (this.schema.anyOf !== undefined) {
        component = RadioButtonElement;
      } else if (this.schema.enum !== undefined) {
        component = SelectElement;
      } else if (this.schema.type === "boolean") {
        component = CheckboxElement;
      } else if (this.schema.type === "array") {
        component =
          this.schema.items.anyOf === undefined
            ? FormElementMultiple
            : CheckboxGroupElement;
      } else if (this.schema.type === "object") {
        component = FormElementWrapper;
      } else if (
        this.schema.type === "integer" ||
        this.schema.type === "number"
      ) {
        component = NumberElement;
      } else if (this.schema.format === "date") {
        component = DatePickerElement;
      } else if (this.schema.format === "date-time") {
        component = DateTimePickerElement;
      }
      return component;
    },
    hidden() {
      return this.schema.hidden;
    },
  },
  methods: {
    toName(string) {
      return string
        .toLowerCase()
        .replace(/[^a-z0-9 -]/gi, "")
        .replaceAll(" ", "_");
    },
  },
};
</script>

<style scoped>
.hidden,
.hidden :deep(*) {
  color: #8a8a8a !important;
}
</style>
