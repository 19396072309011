<template>
  <div :class="`panel image-panel ${type}`" :title="title">
    <div
      class="panel-heading zoom-80"
      :style="type === 'is-standard' ? 'background-color:#4a4a4a' : null"
    >
      {{ fullTitle }}
      <b-icon
        v-if="uploading || uploadingIndexes.length > 0"
        icon="sync"
        size="is-small"
        class="fa-spin is-pulled-right"
      />
    </div>
    <div class="panel-block is-fullwidth">
      <div
        :class="{
          'aq-image-list-wrapper': true,
          'has-images': images && images.length,
          loading: loading,
        }"
      >
        <transition name="slide">
          <div v-if="loading" class="has-text-centered">
            <spinner-with-checkmark :loading="loading" />
          </div>
        </transition>
        <div v-if="!loading && images.length" class="aq-image-list">
          <aq-image
            :has-delete-button="canBeDeleted"
            :has-edit-button="canBeEdited"
            v-for="image in images"
            :key="image.path"
            :src="getImagePath(image)"
            :responsive="false"
            :for-small-row="true"
            :is-being-deleted="deletingImagePaths.includes(getImagePath(image))"
            classes="pointer hover-image"
            @delete-clicked="deleteClicked(image)"
            @edit-clicked="editClicked(image)"
          />
        </div>
        <div
          v-if="!loading && images.length === 0"
          class="mt-5 has-text-centered"
        >
          No <b>{{ category.replace("_", " ") }}</b> files for this object
        </div>
      </div>

      <transition name="slide">
        <div class="has-text-centered force-block mt-2" v-show="!loading">
          <form id="idso">
            <input
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              @change="uploadImagesFromButton('camera')"
              accept="image/*;capture=environment"
              capture="environment"
              ref="camera"
              v-show="false"
            />
            <input
              type="file"
              multiple
              @change="uploadImagesFromButton('file')"
              ref="file"
              v-show="false"
            />
          </form>
          <b-button
            v-if="showCameraButton"
            rounded
            icon-left="camera"
            :loading="uploading || uploadingIndexes.length > 0"
            size="is-small"
            :type="type"
            @click="$refs.camera.click()"
            class="mr-1"
          >
            Camera
          </b-button>
          <b-button
            rounded
            :loading="uploading || uploadingIndexes.length > 0"
            icon-left="upload"
            size="is-small"
            :type="type"
            @click="$refs.file.click()"
            class="mr-1"
          >
            Upload
          </b-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import TrackWindowSize from "../../../mixins/trackWindowSize";
import AqImage from "../AqImage";
import { b64toBlob } from "../../../files";
import iconFor from "../../../icons";

export default {
  name: "AqImageUpload",
  mixins: [TrackWindowSize],
  components: { AqImage },
  props: {
    parentObjectPath: {
      type: String,
      required: true,
      validator: function (val) {
        let allowedCollections = ["positions", "gateway_positions", "sources"];
        return (
          val.includes("/") &&
          val.split("/").length === 2 &&
          allowedCollections.some((col) => val.startsWith(col + "/"))
        );
      },
    },
    category: {
      type: String,
      required: true,
    },
    allowCamera: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Images",
    },
    type: {
      type: String,
      default: "is-primary",
    },
  },
  data() {
    return {
      cameraModalActive: false,
      images: [],
      loading: true,
      deletingImagePaths: [],
      uploading: false,
      uploadingIndexes: [],
    };
  },
  mounted() {
    this.getImagesForObject();
  },
  computed: {
    showCameraButton() {
      return "mediaDevices" in navigator;
    },
    fullTitle() {
      return `${this.title}${
        this.loading ? "" : " (" + this.images.length + ")"
      }`;
    },
    canBeDeleted() {
      return true;
    },
    canBeEdited() {
      return true;
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_URL}/files/${this.parentObjectPath}/`;
    },
  },
  methods: {
    editClicked(imageObj) {
      const filePath = imageObj.path;
      this.$router.push({
        name: "administration:file-detail",
        params: { filePath },
      });
    },
    deleteClicked(imageObj) {
      const self = this,
        imagePath = self.getImagePath(imageObj);
      self.$buefy.dialog.confirm({
        message: `Are you sure you want to delete file '${imageObj.metadata.filename}'? This will also delete any data tied to this file.`,
        hasIcon: true,
        icon: iconFor("delete"),
        type: "is-danger",
        onConfirm: () => {
          self.deletingImagePaths.push(imagePath);
          self.$client
            .delete(`/files/${imageObj.path}/`)
            .then((response) => {
              if (response.success) {
                self.images = self.images.filter(
                  (im) => im.path !== imageObj.path
                );
                self.$handleSuccess("Your image has been deleted");
              } else {
                throw response.message;
              }
            })
            .catch((e) => self.$handleError("Could not delete image", e))
            .finally(
              () =>
                (self.deletingImagePaths = self.deletingImagePaths.filter(
                  (p) => p !== imagePath
                ))
            );
        },
      });
    },
    uploadB64Image(b64Data) {
      const blob = b64toBlob(b64Data);
      this.uploadImage(blob);
    },
    uploadImagesFromButton(ref) {
      const files = Array.from(this.$refs[ref].files),
        blobs = files.map((file) => new Blob([file], { type: file.type })),
        self = this;
      blobs.forEach((blob, index) => {
        self.uploadingIndexes.push(index);
        self.uploadImage(blob, files[index].name, false, () => {
          self.uploadingIndexes = self.uploadingIndexes.filter(
            (ind) => ind !== index
          );
        });
      });
    },
    uploadImage(blob, name, isSingle = true, onFinally = null) {
      const formData = new FormData(),
        self = this;
      formData.append("category", this.category);
      formData.append("file", blob, name);
      if (isSingle) {
        self.uploading = true;
      }
      self
        .fetchWithAuth(self.uploadUrl, {
          method: "POST",
          body: formData,
        })
        .then((r) => {
          if (!r.ok) {
            throw Error(r.statusText);
          }
          return r.json();
        })
        .then((res) => {
          self.images.unshift(res.result);
        })
        .catch((e) => self.$handleError("Could not upload file", e))
        .finally(() => {
          if (isSingle) {
            self.uploading = false;
          }
          if (onFinally) {
            onFinally();
          }
        });
    },
    getImagePath(imageObject) {
      return process.env.VUE_APP_API_URL + "/files/" + imageObject.path + "/";
    },
    getImagesForObject() {
      let self = this;
      self.loading = true;
      self.$client
        .post("/files/list/", {
          object: self.parentObjectPath,
          category: self.category,
        })
        .then((res) => {
          self.images = res.result.files;
        })
        .catch((e) => self.$handleError(e, e))
        .finally(() => {
          self.loading = false;
        });
    },
    imageDimClass(imageObj) {
      return `is-${imageObj.metadata.w}by${imageObj.metadata.h}`;
    },
  },
};
</script>

<style scoped lang="scss">
.pic-wrapper {
  width: 100%;
}

.aq-image-list-wrapper {
  height: 40px;
  transition: 0.2s ease-in height;

  &.loading {
    height: 100px;
  }

  &.has-images {
    overflow-x: scroll;
    height: 151px;
  }
}

.aq-image-list {
  overflow-x: scroll;
  width: max-content;
}

.aq-image-list .aq-image {
  display: inline-block;
  margin: 0 2px;
}

.hover-image:hover,
.hover-image:focus {
  border: 2px solid lightgray;
}

.zoom-80 {
  zoom: 80%;
}
</style>
