<!--suppress ALL -->
<template>
  <div>
    <b-message>
      <slot name="title"><b>Roles</b></slot>
      <b-table :data="sortedRows" v-if="value && value.length">
        <b-table-column label="Role" field="role" v-slot="props">
          <div v-if="!editingRow(props.row.access)">
            <span>{{ title(props.row.role) }}</span>
            <b-button
              icon-left="edit"
              class="ml-3"
              :disabled="disabled || !editable"
              v-if="
                user_roles.canEditPath(parentPath) &&
                props.row.access &&
                props.row.role !== 'root'
              "
              @click.native="
                props.row.role = null;
                editRow(props.row);
              "
              size="is-small"
            />
          </div>
          <div v-else>
            <span v-if="!editingRow(props.row.access)">{{
              title(props.row.role)
            }}</span>
            <b-field
              :message="
                'What role level would you like this ' + noun + ' to have?'
              "
              v-if="props.row.access"
              :type="props.row.role ? 'is-success' : 'is-danger'"
            >
              <b-select
                required
                expanded
                :disabled="disabled || !editable"
                :value="props.row.role"
                style="zoom:80%;"
                @click="click($event)"
                @input="
                  selectedRole = $event;
                  props.row.role = $event;
                  props.row = Object.assign({}, props.row);
                  check(props.index, $event);
                "
              >
                <option :value="null"><i>Please select a role</i></option>
                <option
                  v-for="role in availableRoles(props.row.access)"
                  :key="role"
                  :value="role"
                >
                  {{ title(role) }}
                </option>
              </b-select>
            </b-field>
          </div>
        </b-table-column>
        <b-table-column label="For" v-slot="props">
          <div v-if="props.row.access">
            <span
              v-if="props.row.access"
              :title="user_roles.is_root ? props.row.access : ''"
            >
              {{ readableNameFromIDStr(props.row.access) }}
            </span>
          </div>
          <div v-else>
            <customer-hierarchy-selection
              :allow-at-root="user_roles.is_root && parentPath === '|root'"
              down-to-scope="site"
              @lowest-selected="currentLowestSelected = $event"
            />
            <p class="control">
              <b-button
                icon-right="check"
                type="is-success"
                class="mt-4"
                :disabled="!currentLowestSelected"
                @click="
                  setAccessPathForEmptyRow(
                    currentLowestSelected ? currentLowestSelected.id_str : null
                  )
                "
              >
                Select
              </b-button>
            </p>
          </div>
        </b-table-column>
        <b-table-column
          v-if="user_roles.canEditPath(parentPath)"
          v-slot="props"
        >
          <div class="buttons is-pulled-right">
            <b-button
              v-if="editable"
              @click="
                removeRow(props.index);
                editingRolePath = false;
              "
              :disabled="disabled"
              title="Delete"
              size="is-small"
              type="is-danger"
              icon-right="times"
            />
          </div>
        </b-table-column>
      </b-table>
      <p v-else>
        This {{ noun }} has no valid roles. Without any valid role the
        {{ noun }} will not be able to access anything within AquaSeca. Add a
        role to get started.
      </p>
      <p class="has-text-danger is-size-6" v-if="validationMessage">
        {{ validationMessage }}
      </p>
      <b-button
        size="is-small"
        class="is-pulled-right mt-2"
        icon-left="plus"
        :type="validationMessage ? 'is-danger' : ''"
        @click="addRow()"
        :disabled="
          !editable ||
          disabled ||
          validationMessage ||
          editingRolePath ||
          (value.length &&
            value.filter((row) => row.access && row.role).length <
              value.length) ||
          value.filter((r) => r.role === 'root').length > 0
        "
        v-if="
          !value || value.length === 0 || user_roles.canEditPath(parentPath)
        "
        >Add another role
      </b-button>
    </b-message>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

import { mapState } from "vuex";
import Roles from "../Roles";

function title(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export default {
  name: "ViewRoles",
  data() {
    return {
      currentLowestSelected: null,
      editingRolePath: null,
      selectedRole: null,
      validationMessage: null,
    };
  },
  computed: {
    ...mapState(["user_roles"]),
    sortedRows() {
      return cloneDeep(this.value).sort(this.sortFunction);
    },
  },
  methods: {
    click(val) {
      console.log("click", val);
    },
    check(index, val) {
      this.sortedRows[index].role = val;
      this.editingRolePath = false;
      this.$emit("input", this.sortedRows);
    },
    sortFunction(roleRow1, roleRow2) {
      if (roleRow1 && !roleRow2) {
        return 1;
      } else if (
        roleRow2 &&
        roleRow2.id_str &&
        !(roleRow1 || roleRow1.id_str)
      ) {
        return -1;
      }
      let pipes1 = Roles.pipesInPath(roleRow1.id_str),
        pipes2 = Roles.pipesInPath(roleRow2.id_str);
      if (pipes1 < pipes2) {
        return 1;
      } else if (pipes1 === pipes2) {
        return 0;
      } else {
        return -1;
      }
    },
    readableNameFromIDStr(idString) {
      let object = this.$store.getters.objectByIDString(idString) ||
              this.$store.dispatch("getObjFromIdStringAction", { idString }),
        _type = this.$store.getters.objectTypeByIDString(idString);
      return `${
        object && object.name ? object.name : _type === "Root" ? "" : "..."
      } ${_type ? `(${_type})` : ""}`;
    },
    availableRoles(path) {
      let available = [];
      if (this.user_roles.is_root && path === "|root") {
        available = Roles.atOrAbove("root");
      } else if (this.user_roles.is_admin) {
        available = Roles.atOrBelow("admin");
      }
      return available;
    },
    emitIfValidates(roleData) {
      this.$nextTick(() => {
        try {
          if (!roleData.every((r) => r.access && r.role)) {
            throw "Each entry must have an access path and valid role";
          }
          let asRoles = new Roles(roleData, true),
            isValid =
              asRoles.isValid(this.user_roles, true) &&
              this.user_roles &&
              this.user_roles.match("admin", this.parentPath);
          this.validationMessage = null;
          this.$emit("validates", isValid);
        } catch (e) {
          this.validationMessage = e;
          this.$emit("validates", false);
          console.error(e);
        }
      });
    },
    title: title,
    editingRow(access) {
      return this.editingRolePath && this.editingRolePath === access;
    },
    editRow(roleDataRow) {
      this.editingRolePath = roleDataRow.access;
    },
    stopEditingRow() {
      this.editingRolePath = null;
      this.selectedRole = null;
    },
    addRow() {
      this.$emit("input", this.value.concat([{ role: null, access: null }]));
    },
    removeRow(index) {
      this.sortedRows.splice(index, 1);
      this.$emit("input", this.sortedRows);
    },
    updateRoleFor(accessPath, role) {
      // TODO: Verify user can set!
      let _roleData = [];
      for (let each of this.value) {
        let row = each;
        if (each.access === accessPath) {
          row.role = role;
        }
        _roleData.push(row);
      }
      this.$emit("input", _roleData);
    },
    setAccessPathForEmptyRow(accessPath) {
      let _roleData = this.value.length
        ? this.value.slice(0, this.value.length - 1)
        : [];
      if (
        !accessPath ||
        this.value.filter((r) => r.access === accessPath).length >= 1
      ) {
        this.$handleError("You must select a path not already assigned to a role");
        return;
      }
      let row = this.value.slice(-1);
      if (!row || !row.access) {
        row = {};
        row.access = accessPath;
        if (row.access === "|root") {
          row.role = "root";
        } else {
          row.role = null;
        }
      } else {
        _roleData.push(row);
      }
      // will always be last row
      _roleData.push(row);
      // clear selection
      this.currentLowestSelected = null;
      // unregister editing of path
      this.editingRolePath = null;
      this.$emit("input", _roleData);
      if (row.role !== "root") {
        this.editRow(row);
      }
    },
  },
  props: {
    noun: {
      type: String,
      default: "user",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    parentPath: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.emitIfValidates(this.value);
  },
  watch: {
    value: {
      handler(val) {
        this.emitIfValidates(val);
      },
    },
    parentPath: {
      handler(val) {
        this.emitIfValidates(this.value);
      },
    },
    selectedRole: {
      handler(val) {
        if (val) {
          this.stopEditingRow();
        }
      },
    },
  },
};
</script>

<style scoped></style>
