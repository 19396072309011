<template>
  <div
    :class="' column is-3-fullhd is-4-desktop is-6-tablet ' + type"
    @click="goToRoute()"
    v-if="showRoute"
  >
    <div class="box pointer">
      <p class="our-title">
        <slot name="title-icon">
          <b-icon
            v-if="icon || routeObj.meta.icon"
            :icon="icon || routeObj.meta.icon"
            size="is-small"
            class="mr-2"
          />
        </slot>
        <slot name="title">
          {{ title }}
        </slot>
      </p>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DirectoryTile",
  props: {
    route: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "is-info",
    },
  },
  computed: {
    ...mapState(["user", "user_roles"]),
    showRoute() {
      let route = this.$router.match(this.route);
      if (!route) {
        console.error("COULD NOT FIND MATCH", route);
      }
      if (route && (!this.user || !this.user_roles.is_viewer)) {
        return route.meta && route.meta.public;
      }
      return route && route.meta
        ? !route.meta.allowIf ||
            route.meta.allowIf({
              user: this.user,
              roles: this.user_roles || {},
              topLevel: this.$store.getters.topLevel,
            })
        : false;
    },
    routeObj() {
      return this.$router.match(this.route);
    },
  },
  methods: {
    goToRoute() {
      this.$router.push(this.route);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../mixins/buefy/styles.scss";
.content {
  height: 200px;
  margin-top: 20px;
}

.pointer {
  height: 250px;
  cursor: pointer;
  transition: 0.1s all;
  text-decoration: none;
  padding: 10px;
}

.pointer:hover,
.pointer:hover .title {
  background-color: #3298dc;
  text-decoration: none;
  color: white;
}

@media screen and (min-width: 1024px) {
  .content {
    font-size: 16px;
    margin-top: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .pointer {
    height: 175px;
  }
}
.our-title {
  font-size: 18px;
}
</style>
