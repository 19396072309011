<template>
  <div>
    <div v-if="object">
      <generic-view
        :editable="$store.state.user_roles.is_installer"
        object-name="room"
        parent-name="area"
        parent-title="Area"
        :ancestors="ancestors"
        :parent="parent"
        :object="object"
        :children="children"
        child-name="source"
        child-title="Points-of-use (POUs)"
        :state-var-name="endpointSegment"
        @edit-clicked="modalOpen = true"
      >
        <level :values="levelArray">
          <div class="level">
            <div class="level-left">
              <div class="level-item has-text-weight-bold">Tags/Groups</div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-taglist v-if="object.tags && object.tags.length">
                  <b-tag v-for="tag in object.tags" :key="tag">{{ tag }}</b-tag>
                </b-taglist>
                <p v-else>None</p>
              </div>
            </div>
          </div>
        </level>
        <template #pre-children>
          <children :children="[]" name="Pipe locations">
            <template v-slot:childrendisplay>
              <div class="card children-card">
                <div class="card-header">
                  <div class="card-header-icon">
                    <b-icon :icon="iconFor('pipe_location')" />
                  </div>
                  <div class="card-header-title">Pipe Locations</div>
                </div>
                <div class="card-content">
                  <div>
                    <pipe-location-table
                      :title="null"
                      :rows="
                        $store.state.pipe_locations.filter(
                          (pl) => pl.room && pl.room.id === id
                        )
                      "
                      :paginate_by="paginatePLBy"
                      :loading="$store.state.fetching.pipe_locations"
                      can-copy
                      @input="
                        $store.commit('add', {
                          name: 'pipe_locations',
                          result: $event,
                        });
                        $router.push({
                          name: 'administration:view-pipe-location',
                          params: { id: $event.id },
                        });
                      "
                      @copy-clicked="copyPLClicked"
                    >
                      <template #empty-content>
                        <p class="has-text-centered has-text-grey-light">
                          {{
                            $store.state.fetching.pipe_locations
                              ? ""
                              : "No pipe locations found"
                          }}
                        </p>
                      </template>
                    </pipe-location-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="card-footer-item" v-if="object.id">
                    <slot
                      name="addnewchild"
                      v-if="$store.state.user_roles.is_installer"
                    >
                      <b-button
                        @click="addNewPipeLocation"
                        type="is-create is-outlined"
                      >
                        Add new pipe location
                      </b-button>
                    </slot>
                  </div>
                </div>
              </div>
            </template>
          </children>
        </template>
        <template v-slot:childrendisplay>
          <source-table :rows="children" show-flow-rate>
            <template #empty><span /></template>
          </source-table>
        </template>
        <template v-slot:addnewchild>
          <b-button
            @click="addNewSource()"
            type="is-create is-outlined"
            v-if="$store.state.user_roles.is_installer"
            >Add new point-of-use
          </b-button>
        </template>
      </generic-view>

      <room-modal
        v-model="modalOpen"
        :parent="parent"
        :room="object"
        :id="object.id"
      />
      <add-pipe-location-modal
        ref="pipeLocationModal"
        :building="parent"
        v-if="area"
        :parent="area"
        :room="object"
        v-model="pipeLocationModalOpen"
        @save="
          $store.commit('add', {
            name: 'pipe_locations',
            result: $event,
          });
          pipeLocationModalOpen = false;
        "
      />
      <div v-if="area">
        <source-modal
          v-model="childModalOpen"
          :parent="area"
          :building="object.$building"
          :room="object"
          :id="null"
        />
      </div>
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import { copyFirestoreData } from "../../../scripts/firestoreHelpers";
import {
  getAncestors,
  getChildrenOfIdByKey,
  getObjectInArrayById,
} from "../../../scripts/filterHelpers";
import iconFor from "../../../icons";
import AddPipeLocationModal from "../../../components/Admin/PipeLocation/AddPipeLocationModal";
import Children from "./children";
import GenericView from "./genericView";
import Level from "../../../components/Level/Level";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";
import PipeLocationTable from "../../../tables/PipeLocationTable";
import RoomModal from "../../../components/Admin/Rooms/AddRoomModal";
import SourceModal from "../../../components/Admin/Sources/AddSourceModal";
import SourceTable from "../../../tables/SourceTable";

export default {
  name: "ViewRoom",
  components: {
    AddPipeLocationModal,
    Children,
    GenericView,
    Level,
    PipeLocationTable,
    RoomModal,
    SourceModal,
    SourceTable,
  },
  mixins: [onScopeChangeMixin],
  methods: {
    iconFor,
    copyPLClicked(pl) {
      this.pipeLocationModalOpen = true;
      this.$refs.pipeLocationModal.updateInputs(
        copyFirestoreData(pl, ["lowercase_name", "name", "original_name"])
      );
      this.$handleSuccess(`Pipe location data copied from '${pl.name}'`);
    },
    editClicked() {
      this.modalOpen = true;
    },
    addNewSource() {
      this.childModalOpen = true;
    },
    addNewPipeLocation() {
      this.pipeLocationModalOpen = true;
    },
  },
  computed: {
    site() {
      return this.object && this.object.site
        ? getObjectInArrayById(this.$store.state.sites, this.object.site, {})
        : {};
    },
    ancestors() {
      if (!this.object) {
        return [];
      }
      return getAncestors([
        {
          title: "Customer",
          object: getObjectInArrayById(
            this.$store.state.customers,
            this.object.customer,
            null
          ),
          endpointName: "administration:view-customer",
        },
        {
          title: "Sub-Customer",
          object: getObjectInArrayById(
            this.$store.state.subcustomers,
            this.object.subcustomer,
            null
          ),
          endpointName: "administration:view-subcustomer",
        },
        {
          title: "Site",
          object: getObjectInArrayById(
            this.$store.state.sites,
            this.object.site,
            null
          ),
          endpointName: "administration:view-site",
        },
        {
          title: "Area",
          object: getObjectInArrayById(
            this.$store.state.buildings,
            this.object.building,
            null
          ),
          endpointName: "administration:view-area",
        },
      ]);
    },
    levelArray() {
      return this.object
        ? [
            { label: "Name", value: this.object.name },
            {
              label: "Description",
              value: this.object.description || "Not set",
            },
            { label: "Room type", value: this.object.type },
            {
              label: "Unit",
              value: this.object.unit || "Not set",
            },
            {
              label: "Floor",
              value: this.object.floor ? this.object.floor : "Not set",
            },
          ]
        : [];
    },
    parent() {
      return this.object ? this.object.$building || this.object.$site : null;
    },
    object() {
      return getObjectInArrayById(this.$store.state.rooms, this.id, {});
    },
    children() {
      return this.object
        ? getChildrenOfIdByKey(this.$store.state.sources, this.id, [], "room")
        : [];
    },
    area() {
      return this.parent ? this.parent.$area : null;
    },
    areas() {
      let obj = getObjectInArrayById(this.$store.state.rooms, this.id, {});
      return getChildrenOfIdByKey(
        this.$store.state.areas,
        obj.site.id,
        {},
        "parent"
      );
    },
    pipeLocationModalOpen: {
      get() {
        return Object.keys(this.$route.query).includes("plModal");
      },
      set(val) {
        if (val) {
          this.queryReplace({ plModal: "" });
        } else {
          this.queryRemoveKeys(["plModal"]);
        }
      },
    },
    childModalOpen: {
      get() {
        return Object.keys(this.$route.query).includes("addChild");
      },
      set(val) {
        if (val) {
          this.queryReplace({ addChild: "" });
        } else {
          this.queryRemoveKeys(["addChild"]);
        }
      },
    },
    modalOpen: {
      get() {
        return Object.keys(this.$route.query).includes("edit");
      },
      set(val) {
        if (val === this.modalOpen) {
          return;
        }
        if (val) {
          this.$router.replace({ query: { edit: "" } });
        } else {
          this.queryRemoveKeys(["edit"]);
        }
      },
    },
    pipeLocations() {
      return this.$store.state.pipe_locations;
    },
  },
  data() {
    return {
      endpointSegment: "rooms",
      paginatePLBy: 25,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
