<template>
  <option-driven-radio
    :options="options"
    :value="value"
    type-from-value
    @input="$emit('input', $event)"
  >
    <template #default="option">
      <b-icon
        :icon="iconFor(option.value)"
        size="is-small"
        :type="'is-' + option.value"
      />
      {{ option.label }}
    </template>
  </option-driven-radio>
</template>

<script>
import OptionDrivenRadio from "./OptionDrivenRadio";
import iconFor from "../../../icons";

export const options = [
  { value: "hot", label: "Hot" },
  { value: "cold", label: "Cold" },
  { value: "mixed", label: "Mixed" },
];

export default {
  name: "PipeTemperature",
  components: { OptionDrivenRadio },
  props: {
    value: {
      type: String,
      validator(val) {
        return !val || options.map((o) => o.value).includes(val);
      },
    },
  },
  data() {
    return {
      options,
    };
  },
  methods: {
    iconFor,
  },
};
</script>

<style scoped></style>
