<template>
  <b-table :data="data" :loading="loading" :default-sort="['report_name']">
    <template #empty> There are no report subscriptions to show </template>
    <b-table-column label="Report name" field="report_name" v-slot="props" sortable>
      {{ capitalizeFirstLetter(props.row.report_name).replaceAll("_", " ") }}
    </b-table-column>
    <b-table-column label="Subscribers" v-slot="props">
      {{ props.row.email_addresses.length }}
    </b-table-column>
    <b-table-column
      label="Subscribed"
      v-slot="props"
    >
      <b-icon
          :icon="(props.row.email_addresses || []).includes($store.state.user.email) ? 'check' : 'times'"
          :type="(props.row.email_addresses || []).includes($store.state.user.email) ? 'is-success' : 'is-danger'"
          size="is-small"
        />
    </b-table-column>
    <b-table-column label="Cadence" v-slot="props">
      {{ props.row.frequency.cadence }}
    </b-table-column>
    <b-table-column label="Scope" v-slot="props">
      <generic-display
        :value="objForPath(props.row.parameters.for_object_path)"
      />
    </b-table-column>
    <b-table-column v-slot="props">
      <b-button
        size="is-small"
        type="is-primary"
        @click="$emit('input', props.row)"
      >
        Select
      </b-button>
    </b-table-column>
  </b-table>
</template>

<script>
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import {uniqBy} from "lodash";

export default {
  name: "ReportSubscriptionTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    objectCache: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    pathObjects() {
      return uniqBy(
        this.$store.getters.objectStack.concat(this.objectCache),
        "id"
      );
    },
  },
  methods: {
    objForPath(path) {
      return getObjectInArrayById(
          this.pathObjects,
          path.split('/')[1],
          {name:'...'}
      );
    }
  }
};
</script>

<style scoped></style>
