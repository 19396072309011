<template>
  <div class="image-map-array">
    <slot
      name="empty"
      v-if="!loadingFiles && (!fileObjects || !fileObjects.length)"
    >
      <div class="has-text-centered">
        <i class="m-6"> No files found for this entity </i>
      </div>
    </slot>
    <transition name="slide">
      <div v-if="loadingFiles" class="has-text-centered">
        <b-icon size="is-large" icon="sync" class="fa-spin" />
      </div>
    </transition>
    <transition name="slide">
      <div v-if="!loadingFiles && fileObjects && fileObjects.length">
        <b-collapse
          class="card"
          animation="slide"
          v-for="(fileObject, index) of fileObjects"
          :key="index"
          :open="isOpen === index"
          @open="isOpen = index"
        >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :aria-expanded="props.open"
            >
              <div class="card-header-title">
                {{ fileObject.metadata.description }} ({{
                  fileObject.metadata.filename
                }})
              </div>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'">
                </b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="content">
              <aq-image-map
                :editable-entity-paths="editableEntityPaths"
                :file-object="fileObject"
                :parent-object="parentObject"
                v-bind="$props"
                ref="imageMaps"
                :data-key="index"
              />
            </div>
          </div>
        </b-collapse>
      </div>
    </transition>
  </div>
</template>

<script>
import { getFilesForObjectPath } from "../../mixins/hasObjectWithFilesMixin";
import AqImageMap from "../Files/Editors/AqImageMap";
import { uniqBy } from "lodash";

export default {
  name: "AqImageMapArray",
  components: { AqImageMap },
  props: {
    editableEntityPaths: {
      type: [String, Array],
      default: "all",
      validator(val) {
        return val === "all" || Array.isArray(val) || !val;
      },
    },
    parentObject: {
      Object: true,
      required: true,
    },
  },
  data() {
    return {
      fileObjects: [],
      isOpen: 0,
      loadingFiles: false,
    };
  },
  computed: {
    /**
     *
     * @return {Array.<AqImageMap>}
     */
  },
  methods: {
    saveAll() {
      this.$refs.imageMaps.forEach((imageMapComponent) => {
        imageMapComponent.save();
      });
    },
    loadFileObjects() {
      this.fileObjects = [];
      if (!this.parentObject) {
        this.$emit("no-files");
        return;
      }
      this.loadingFiles = true;
      getFilesForObjectPath(
        this.$client,
        this.parentObject._reference.path,
        (response) => {
          this.fileObjects = [];
          uniqBy(response.result.files, "path").forEach((result) =>
            this.fileObjects.push(result)
          );
          this.$emit("files-loaded", this.fileObjects);
          if (!this.fileObjects.length) {
            this.$emit("no-files");
          }
        },
        () => (this.loadingFiles = false)
      );
    },
  },
  watch: {
    parentObject: {
      immediate: true,
      handler() {
        this.loadFileObjects();
      },
    },
  },
};
</script>

<style scoped></style>
