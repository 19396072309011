<template>
  <div class="column">
    <div class="columns">
      <div
        v-if="!forced"
        :class="{
          'column is-12 is-5-desktop is-4-fullhd': true,
          'is-hidden-mobile is-hidden-tablet-only':
            $route.name === 'notifications:view',
        }"
      >
        <b-tabs
          type="is-boxed"
          expanded
          size="is-small"
          v-model="tab"
          class="tab-item-outer"
        >
          <b-tab-item
            :label="'New (' + messages.length + ')'"
            class="tab-item-inner"
          >
            <div class="scroller" id="scroller-messages">
              <b-dropdown
                aria-role="list"
                v-if="selected.length"
                class="mb-2"
                expanded
              >
                <template #trigger="{ active }">
                  <b-button
                    expanded
                    :label="
                      selected.length +
                      ' message' +
                      (selected.length === 1 ? '' : 's') +
                      ' selected'
                    "
                    type="is-primary"
                    :icon-right="active ? 'chevron-up' : 'chevron-down'"
                  />
                </template>
                <b-dropdown-item aria-role="listitem" @click="dismissSelected">
                  Dismiss selected
                </b-dropdown-item>
              </b-dropdown>
              <transition-group name="slide" tag="p">
                <div
                  v-for="message in messages.filter((m) => !m.dismissed)"
                  :key="message.id"
                  :class="{
                    selected: selected.includes(message.id),
                  }"
                >
                  <user-message-box
                    state-var="_messages"
                    v-if="message"
                    :message="message"
                    :loading="loadingMessageIds.includes(message.id)"
                    :ref="'message-' + message.id"
                    :id="'message-' + message.id"
                    :selected="selected.includes(message.id)"
                    @update:loading="handleLoading($event)"
                    @close="returnToUnselectedView(message)"
                    @click="scrollToMessage(message)"
                  />
                </div>
              </transition-group>
              <p
                class="has-text-centered mt-6"
                v-if="
                  !$store.state.loadingMessages &&
                  (!messages || !messages.length)
                "
              >
                No messages found.
                <span v-if="filters && filters.length">
                  You may want to try
                  <a @click="filters = []">resetting filters</a>.
                </span>
              </p>
            </div>
            <b-button
              class="mt-2"
              v-if="messages.length === activePage * 10"
              @click="activePage += 1"
              expanded
            >
              View more
            </b-button>
          </b-tab-item>
          <b-tab-item
            :label="'Dismissed (' + dismissedMessages.length + ')'"
            class="tab-item-inner"
          >
            <div class="scroller">
              <transition-group name="slide" tag="p">
                <user-message-box
                  state-var="_dismissedMessages"
                  v-for="message in dismissedMessages.filter(
                    (m) => m.dismissed
                  )"
                  :loading="loadingMessageIds.includes(message.id)"
                  @update:loading="handleLoading($event)"
                  :key="message.id"
                  :ref="'message-' + message.id"
                  :id="'message-' + message.id"
                  :message="message"
                  @close="returnToUnselectedView(message)"
                  @click="scrollToMessage(message)"
                />
              </transition-group>
              <p
                class="has-text-centered mt-6"
                v-if="
                  !$store.state.loadingDismissedMessages &&
                  (!dismissedMessages || !dismissedMessages.length)
                "
              >
                No dismissed messages found.
                <span v-if="filters && filters.length">
                  You may want to try
                  <a @click="filters = []">resetting filters</a>.
                </span>
              </p>
            </div>
            <b-button
              v-if="dismissedMessages.length === dismissedPage * 10"
              @click="dismissedPage += 1"
              expanded
            >
              View more
            </b-button>
          </b-tab-item>
          <b-tab-item
            :label="'Filters (' + filters.length + ')'"
            class="tab-item-inner"
          >
            <div class="scroller" id="scroller-dismissedMessage">
              <message-filter
                @change="
                  activePage = 1;
                  dismissedPage = 1;
                  filters = $event;
                "
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
      <div
        :class="{
          'column is-7-desktop is-8-fullhd': !forced,
          'column is-12': forced,
        }"
      >
        <router-view
          :loading="loadingMessageIds.includes($route.params.id)"
          @update:loading="handleLoading($event)"
        />
        <div class="content is-hidden-tablet-only is-hidden-mobile-only">
          <div class="has-text-centered" v-if="$route.name === 'notifications'">
            <b-message
              type="is-info"
              title="About messages and alarms"
              icon-left="info-circle"
              :closable="false"
              :has-icon="windowWidth > 883"
            >
              <div class="mb-5">
                Select a message to view more details or use
                <a @click="tab = 2">filters</a>
                to find specific message types.
              </div>
              <b-button
                expanded
                :icon-right="iconFor('newWindow')"
                type="is-info"
                @click="
                  window.open(
                    'https://wiki.aquaseca.com/en/user-manual/messages-and-alarms',
                    '_blank'
                  )
                "
              >
                Click here to learn more
              </b-button>
            </b-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconFor from "../../icons";
import { mapActions, mapGetters } from "vuex";
import MessageFilter from "../../components/filters/MessageFilter";
import UserMessageBox from "../../components/UserMessage/UserMessageBox";
import WindowSizeMixin from "../../mixins/trackWindowSize";

const alarmFiltersByCategory = {
  efficiency: [["topic", "in", ["flow"]]],
  hygiene: [["topic", "in", ["flow", "noflow", "temperature"]]],
  leaks: [
    ["topic", "in", ["flow"]],
    ["source_nm", "in", ["src:anomaly", "SRC:Anomaly"]],
  ],
  system: [["topic", "in", ["calibrate", "install", "health", "hardware"]]],
};

export default {
  name: "MessagesHome",
  components: { MessageFilter, UserMessageBox },
  mixins: [WindowSizeMixin],
  props: {
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tab: 0,
      selected: [],
      activePage: 1,
      dismissedPage: 1,
      filters: [],
      loadingMessageIds: [],
    };
  },
  computed: {
    ...mapGetters(["messages", "dismissedMessages"]),
    forced() {
      return this.$route.query.fetch == 1;
    },
  },
  methods: {
    iconFor,
    ...mapActions(["getMessages", "getDismissedMessages"]),
    handleLoading({ loading, messageId }) {
      if (loading) {
        this.loadingMessageIds.push(messageId);
        setTimeout(() => {
          this.loadingMessageIds = this.loadingMessageIds.filter(
            (mId) => mId !== messageId
          );
        }, 10 * 6000); // ugh, but safe; can't rely on component events
      } else {
        this.loadingMessageIds = this.loadingMessageIds.filter(
          (id) => id !== messageId
        );
      }
    },
    scrollToMessage(message, force) {
      if (force || this.isDesktop) {
        document.getElementById("message-" + message.id).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    returnToUnselectedView(message) {
      let self = this;
      if (
        self.$route.name === "notifications:view" &&
        self.$route.params.id === message.id
      ) {
        this.$router.push({ name: "notifications" });
      }
    },
    triggerMessageFetch() {
      let self = this;
      self.getMessages({ filters: self.filters, page: self.activePage });
      self.getDismissedMessages({
        filters: self.filters,
        page: self.dismissedPage,
      });
    },
    dismissSelected() {
      this.$buefy.dialog.alert(
        "This is strictly a placeholder for now.<br/><br/>Any ideas for bulk actions on messages? Let us know!"
      );
    },
  },
  watch: {
    "$route.params.id": {
      handler(val, oldVal) {
        if (oldVal && !val && this.loadingMessageIds.includes(oldVal)) {
          this.loadingMessageIds = this.loadingMessageIds.filter(
            (id) => id !== oldVal
          );
        }
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler(val, oldVal) {
        if (this.forced) {
          return;
        }
        if (val !== oldVal && !val.startsWith(oldVal)) {
          this.$router.replace({ params: {} });
        }
      },
    },
    filters: {
      handler() {
        this.triggerMessageFetch();
      },
    },
    activePage: {
      handler() {
        this.triggerMessageFetch();
      },
    },
    dismissedPage: {
      handler() {
        this.triggerMessageFetch();
      },
    },
    messages: {
      handler(val) {
        let allIds = val.map((message) => message.id);
        this.selected = this.selected.filter((messId) =>
          allIds.includes(messId)
        );
      },
    },
    tab: {
      handler(val, old) {
        if (val !== old && this.$route.name === "notifications:view") {
          this.$router.push({ name: "notifications" });
        }
      },
    },
  },
  mounted() {
    if (this.forced) {
      setTimeout(() => {
        this.$store.dispatch("hideSelectScopeModal");
      }, 500);
      return;
    }
    if (
      this.$store.state.user &&
      this.$store.state.user_roles &&
      !this.$store.state.selectedScopeIdStr &&
      this.$route.params.id
    ) {
      let self = this;
      self.$dataClasses.UserMessage.fetchById(self.$route.params.id)
        .then((result) => {
          if (result.alarm.customer) {
            let custId = result.alarm.customer.id,
              subcustId = result.alarm.subcustomer.id,
              siteId = result.alarm.site.id,
              id_str = `|root|${custId}|${subcustId}|${siteId}`,
              idStrToLoad =
                self.$store.state.user_roles.getHighestLevelScopeToLoadFromIdString(
                  id_str
                );
            self.$store
              .dispatch("setScopeFromIdStr", { id_str: idStrToLoad })
              .then(() => {
                self.triggerMessageFetch();
              });
          }
        })
        .catch((e) =>
          self.$handleError("Could not fetch message; It may have been deleted.", e)
        )
        .finally(() => {
          self.$router.replace({ query: {} });
        });
    }
  },
  created() {
    if (!this.dismissedMessages.length) {
      this.$store.dispatch("getDismissedMessages");
    }
  },
  /*
   * When component destroyed (navigated away from) return to non-filtered state
   */
  beforeDestroy() {
    if (this.filters.length > 0) {
      this.triggerMessageFetch([]);
    }
  },
};
</script>

<style scoped lang="scss">
.dismissed-message-enter-active,
.dismissed-message-leave-active,
.message-enter-active,
.message-leave-active {
  transition: opacity 0.4s ease-out;
  border: 0;
  border-radius: 0;
}

.dismissed-message-enter, .dismissed-message-leave-to,
.message-enter, .message-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-30px);
  border: 0;
  border-radius: 0;
}

.content .has-text-centered {
  margin: 20vh 2.5vw;
}

.message-outer {
  border: 2px solid transparent;
  border-radius: 7px;
}

.scroller {
  height: calc(100vh - 227px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tab-item-outer :deep(.tab-content) {
  border: 1px solid lightgrey;
  background: white;
}

:deep(.tabs ul li) {
  margin-left: 0;
}
</style>
