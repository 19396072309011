<template>
  <vue-apex-charts
      ref="apex-chart"
      type="heatmap"
      class="pointer"
      :height="220 + 100 * sources.length"
      :width="isMobile ? 220 + 120 * sources.length : '100%'"
      :options="options"
      :series="series"
  />
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";
import VueApexCharts from "vue-apexcharts";
import {getObjectInArrayById} from "../../scripts/filterHelpers";
import {get} from "lodash";

export default {
  name: "SourceSimilarityHeatmap",
  components: {VueApexCharts},
  mixins: [trackWindowSize],
  props: {
    /**
     * @type {Array<{Comment:String,Distance:Number,norm2_dist:Number,source_1:Any,source_2:Any}>}
     */
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    get,
    getRow(array, source1id, source2id) {
      let matches = array.filter(el => {
        return this.toId(el.source_1, 'sources') === source1id && this.toId(el.source_2, 'sources') === source2id ||
            this.toId(el.source_1, 'sources') === source1id && this.toId(el.source_2, 'sources') === source2id
      });
      return (matches && matches.length) ? matches[0] : null
    },
    getSource(ref) {
      return getObjectInArrayById(
          this.sources,
          this.toId(ref, 'sources'),
          {},
      )
    },
    sourceOrSourcesClicked(srcArr) {
      this.$emit('input', srcArr);
    }
  },
  computed: {
    sourceIds() {
      let ids = new Set();
      this.rows.forEach(row => {
        ids.add(this.toId(row.source_1, 'sources'));
        ids.add(this.toId(row.source_2, 'sources'));
      });
      return Array.from(ids);
    },
    sources() {
      return this.sourceIds.map(_id => {
        let val = getObjectInArrayById(
            this.$store.state.sources,
            _id,
            null
        );
        if (!val) {
          console.error(`Could not find 'sources/${_id}`);
        }
        return val
      })
    },
    series() {
      return this
          .sources
          .map(src => {
            let rowsForSource = this.rows.filter(row => this.toId(row.source_1, 'sources') === src.id);
            return {
              name: src.$getLocationSegments(true, false),
              data: this.sources.map(src2 => {
                let row = this.getRow(rowsForSource, src.id, src2.id);
                return {
                  x: row ? this.toId(row.source_2, 'sources') : '',
                  y: row ? row.Distance.toFixed(3) : null,
                }
              })
            }
          })
    },
    options() {
      let self = this;
      return {
        chart: {
          events: {
            click: function(event, chartContext, config) {
              let seriesIndex = config.seriesIndex,
                  dataPointIndex = config.dataPointIndex;
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              let source1Id = config.globals.seriesX[seriesIndex][dataPointIndex],
                source2Id = config.globals.seriesX[dataPointIndex][seriesIndex];
              let source1 = getObjectInArrayById(self.$store.state.sources, source1Id),
                source2 = getObjectInArrayById(self.$store.state.sources, source2Id);
              self.sourceOrSourcesClicked([source1, source2])
            },
            mouseMove: function(event, chartContext, config) {
                var tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
                var pointsArray = config.globals.pointsArray;
                var seriesIndex = config.seriesIndex;
                var dataPointIndex = config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

                if (seriesIndex !== -1) {
                    var position = pointsArray[seriesIndex][dataPointIndex];

                    tooltip.style.top = position[1] + 'px';
                    tooltip.style.left = position[0] + 'px';
                }
            },
          }
        },
        dataLabels: {
          enabled: true,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 1.0
          },
        },
        legend: {
          show: false,
        },
        title: {
          text: undefined,
          show: false,
        },
        plotOptions: {
          heatmap: {
            // radius: 2,
            enableShades: true,
            shadeIntensity: 0.9,
            reverseNegativeShade: false,
            distributed: true,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [{
                from: 0.000000000001,
                to: .9999999999999999999,
                color: '#ff1616',
              }, {
                from: 1,
                to: 1.99999999999999999999,
                color: '#ffcc00',
              }, {
                from: 2,
                to: 15,
                color: '#049000',
              }],
              min: 0,
              max: 15
            },
          }
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex, w}) => {
            let source1 = w.globals.labels[dataPointIndex],
                source2 = w.globals.labels[seriesIndex],
                value = series[seriesIndex][dataPointIndex];
            if (Array.isArray(source1)) {
              source1 = source1.map(i => {
                return Array.isArray(i) ? i.filter(a => !!a).join(', ') : i;
              }).filter(
                  i => i && i.length
              ).join(', ');
            }
            if (Array.isArray(source2)) {
              source2 = source2.map(i => {
                return Array.isArray(i) ? i.filter(a => !!a).join(', ') : i;
              }).filter(
                  i => i && i.length
              ).join(', ');
            }
            return value ? `
              <div class="m-4">
                <b>${value}</b> separation score (higher is better) between:
                <p>- <b>${source1}</b></p>
                <p>- <b>${source2}</b></p>
                <p class="mt-2">
                  <i>Click to add or edit training data for these POUs</i>
                </p>
              </div>

              ` : null;
          },
          enabled: true,
          shared: true,
          fillSeriesColor: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          x: {
            show: false,
          },
          y: {
            show: false,
          },
          z: {
            show: false,
          },
          marker: {
            show: true,
          },
        },
        xaxis: {
          categories: this
              .sources
              .map(
                  src => src.$getLocationSegments(true, false)
              ),
          labels: {
            show: true,
          },
          tooltip: {
            enabled: false,
          }

        }
      };
    },
  },
}
</script>

<style scoped>

</style>

<style scoped></style>
