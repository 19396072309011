<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Pull reports</div>
    </div>
    <div class="card-content">
      <b-field label="Category">
        <b-radio-button
          v-for="cat in allCategories"
          :key="cat"
          :native-value="cat"
          v-model="selectedCategory"
        >
          {{ capitalizeFirstLetter(cat) }}
        </b-radio-button>
        <b-radio-button v-model="selectedCategory" native-value="">
          All
        </b-radio-button>
      </b-field>
      <div v-if="selectedCategory" class="is-size-7 mb-3">
        <router-link :to="{name:`${selectedCategory}:reports`}">
          View saved {{selectedCategory}} reports
        </router-link>
      </div>
      <report-form :category="selectedCategory" @submit="submit($event)" />
    </div>
  </div>
</template>

<script>
import ReportForm from "../Usage/ReportForm";
export default {
  name: "PullReports",
  data() {
    return {
      allCategories: this.$dataClasses.ReportEmailSubscription.allCategories,
    };
  },
  computed: {
    selectedCategory: {
      get() {
        return this.$route.query.reportCategory || "";
      },
      set(val) {
        this.queryReplace({ reportCategory: val });
      },
    },
  },
  methods: {
    submit({ values, selectedSchemaObj }) {
      let url = new URL(this.getSubmitUrl(selectedSchemaObj));
      url.search = new URLSearchParams(values).toString();
      window.open(url, "_blank");
    },
    getSubmitUrl(selectedSchemaObj) {
      if (selectedSchemaObj && selectedSchemaObj.full_url) {
        return selectedSchemaObj.full_url;
      } else if (selectedSchemaObj && selectedSchemaObj.url) {
        return `${process.env.VUE_APP_AQUACLOUD_URL}${selectedSchemaObj.url}`;
      } else {
        return null;
      }
    },
  },
  components: {
    ReportForm,
  },
};
</script>

<style scoped></style>
