<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Remove sensor {{ serial ? ": " + serial : "" }}
      </div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small" />
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step">
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <b-field
              label="Please select the area where sensor to you'd like to remove is currently installed"
            />
            <building-radio
              :value="building"
              @input="handleBuildingSelection"
            />
          </div>
        </b-step-item>
        <b-step-item :step="1" label="Sensor" :type="stepType(1)">
          <transition name="slide">
            <div class="column is-offset-2-desktop is-8-desktop">
              <scan-or-select-device
                checkable
                :serials-checked.sync="serialsChecked"
                :area="area"
                ref="step1Scan"
                device-state="device_installed"
                :device-types="['sensor']"
                label="Select which sensor to remove"
                method-query-key="sm1"
                @input="handleSensorSelection"
                :selected-serial="serial"
                @update:loading="componentLoading = $event"
              >
                <template #select-device-text>Select sensor(s)</template>
              </scan-or-select-device>
              <b-message
                v-if="selectedDevice && !canRemoveSensor"
                class="mt-6"
                type="is-danger"
                :closable="false"
                title="Can not remove sensor"
                has-icon
                icon="exclamation-circle"
              >
                <p class="is-size-5">This sensor <b>can not be removed</b>.</p>
              </b-message>
            </div>
          </transition>
        </b-step-item>

        <b-step-item :step="2" label="Confirm" :type="stepType(5)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps have missing or invalid data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            <div
              class="column is-6-desktop is-offset-3-desktop is-10-table is-offset-1-tablet"
            >
              <div class="box">
                <level :values="valuesToShow" />
                <b-message
                  class="mt-6"
                  type="is-info"
                  :closable="false"
                  title="Remove sensor"
                  has-icon
                  icon="info-circle"
                >
                  <div class="is-size-5">
                    After confirming below, you may remove the sensors listed
                    below:
                    <ul style="list-style: disc">
                      <li v-for="ser in serialsChecked" :key="ser">
                        {{ ser }}
                      </li>
                    </ul>
                  </div>
                </b-message>
              </div>
            </div>
          </div>
        </b-step-item>

        <template #navigation>
          <div class="has-text-centered">
            <forward-backward
              classes="float-right"
              :allow-forward="validate(step)"
              :confirm-loading="confirmLoading"
              confirm-type="is-warning"
              :min-step="stepArray[0]"
              :max-step="stepArray[stepArray.length - 1]"
              :step="step"
              @forward="goForward"
              @backward="goBackward"
              @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";

import iconFor from "../../icons";
import { initial } from "lodash";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import Level from "../../components/Level/Level";

const stepArray = [0, 1, 2];

/**
 * Device centric action component to
 */
export default {
  name: "RemoveSensor",
  components: {
    BuildingRadio,
    ForwardBackward,
    Level,
    ScanOrSelectDevice,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {object,null}}}
   */
  data() {
    return {
      componentLoading: false,
      deviceType: "sensor",
      selectedDevices: [],
      confirmLoading: false,
      sensors: [],
    };
  },
  mounted() {
    if (this.serial) {
      this.handleSensorSelection({ serial: this.serial });
    }
    this.goToNextInvalidOrFinalStep();
  },
  computed: {
    serialsChecked: {
      get() {
        return this.$route.query.serials || [];
      },
      set(val) {
        this.queryReplace({ serials: val });
      },
    },
    canRemoveSensor() {
      return (
        this.area &&
        this.serialsChecked &&
        this.serialsChecked.length &&
        this.area.sensors &&
        this.area.sensors.filter((d) => this.serialsChecked.includes(d.serial))
          .length === this.sensors.length
      );
    },
    valuesToShow() {
      return [{ label: "Sensors to remove", value: "" }];
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    building: {
      get() {
        let buildingId = this.$route.query.building;
        return buildingId
          ? getObjectInArrayById(this.$store.state.buildings, buildingId, null)
          : null;
      },
      set(val) {
        if (val instanceof Object) {
          val = val.id;
        }
        this.queryReplace({ building: val });
      },
    },
  },
  methods: {
    iconFor,
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return !!this.serialsChecked && this.serialsChecked.length;
      } else if (step === 2) {
        return (
          initial(stepArray).filter((_step) => !this.validate(_step)).length ===
          0
        );
      }
    },
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && building && this.building) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} sensor
     */
    handleSensorSelection(sensor) {
      this.serialsChecked = [sensor.serial];
      setTimeout(() => {
        if (sensor && this.step === 1 && this.validate(1)) {
          this.goForward();
        }
      }, 100);
    },
    /**
     * Finalize installation
     */
    onComplete() {
      if (this.invalidSteps.length) {
        this.$handleError(
          "Please correct any steps with missing data or errors",
          "Please correct any steps with missing data or errors"
        );
        return;
      }
      this.confirmLoading = true;

      let self = this;
      self.area
        .performAction("remove_devices", {
          devices: self.sensors.map((s) => s.$FSRef),
        })
        .then(() => self.reloadArea())
        .then(() => {
          self.$store.dispatch('get', {name:'pipe_locations'});
          return Promise.resolve();
        })
        .then(() => {
          self.$handleSuccess(
            `Sensors ${self.serialsChecked.join(", ")} have been removed`
          );
          if (self.$route.query.next) {
            self.$router.push(self.$route.query.next)
          } else {
            self.area.$goToSystemDetail();
          }
        })
        .catch((e) =>
          self.$handleError(
            "Could not complete sensor removal; see console for details",
            e
          )
        )
        .finally(() => (self.confirmLoading = false));
    },
  },
  watch: {
    serialsChecked: {
      immediate: true,
      handler(serials) {
        if (serials && serials.length) {
          this.loading = true;
          this.$dataClasses.Sensor.query([["serial", "in", serials]])
            .then((results) => {
              this.sensors = results;
            })
            .catch((e) => this.$handleError(e, e))
            .finally(() => (this.loading = false));
        } else {
          this.sensors = [];
        }
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}
</style>
