<template>
  <b-table
    :loading="loading"
    :checkable="checkable"
    :checked-rows.sync="checkedRows"
    :data="sortedRows"
    :detailed="detailed"
    :show-detail-icon="detailed"
    :row-class="rowClass"
    :sticky-header="stickyHeader"
    v-bind="$props"
  >
    <b-table-column
        field="$displayString"
        label="Name"
        searchable
        sortable
        v-slot="props"
        :custom-sort="alphanumericSort"
    >
      <source-display :value="props.row" />
    </b-table-column>
    <b-table-column
      label="Area"
      field="$building.name"
      v-slot="props"
      searchable
      sortable
      v-if="showArea"
      :custom-sort="compareFieldFunc('$building.name')"
    >
      {{ buildingName(props.row.building ? props.row.building.id : null) }}
    </b-table-column>
    <b-table-column
      field="$room.name"
      label="Room"
      v-slot="props"
      searchable
      sortable
      :custom-sort="compareFieldFunc('$room.name')"
    >
      {{ roomName(props.row.room ? props.row.room.id : null) }}
    </b-table-column>
    <b-table-column
      field="$room.unit"
      label="Unit"
      v-slot="props"
      searchable
      sortable
      :custom-sort="compareFieldFunc('$room.unit')"
    >
      {{ props.row.$room ? props.row.$room.unit : "" }}
    </b-table-column>
    <b-table-column
      field="$room.floor"
      label="Floor"
      v-slot="props"
      searchable
      sortable
      :custom-sort="compareFieldFunc('$room.floor')"
    >
      {{ props.row.$room ? props.row.$room.floor : "" }}
    </b-table-column>
    <b-table-column
      field="$trainingLevel"
      sortable
      searchable
      label="Level of training"
      v-slot="props"
    >
      <span
        :class="'has-text-weight-bold ' + props.row.$trainingLevelBuefyClass"
      >
        {{ props.row.$trainingLevel }}
      </span>
    </b-table-column>

    <b-table-column
      field="fixture"
      label="Fixture type"
      v-slot="props"
      searchable
      sortable
    >
      {{ props.row.fixture ? cap(props.row.fixture) : "None" }}
    </b-table-column>
    <slot name="flow-rate-column" v-if="showFlowRate">
      <b-table-column
          field="flow_rate.typical"
          label="Flow rate"
          sortable
          v-slot="props"
      >
        <unit-conversion-display
            classes="is-size-7"
            measurement="volumeFlowRate"
            :decimals="3"
            :value="props.row.get('flow_rate.typical')"
          />
      </b-table-column>
    </slot>
    <slot name="select-column">
      <b-table-column v-slot="props">
        <b-button
          @click="onClick(props.row)"
          rounded
          type="is-primary is-outlined"
          size="is-small"
          class="is-pulled-right"
          icon-right="chevron-right"
          >Select
        </b-button>
      </b-table-column>
    </slot>
    <template #empty>
      <slot name="empty">
        <div class="has-text-centered"><i>No points-of-use to display</i></div>
      </slot>
    </template>
    <template #detail="props">
      <slot name="detail" v-bind:props="props" />
    </template>
  </b-table>
</template>

<script>
import {get, sortBy} from 'lodash';
import { getObjectInArrayById } from "../scripts/filterHelpers";
import { compareFieldFunc } from "../dataClasses/functions";
import router from "../router";

export default {
  name: "SourceTable",
  data() {
    return {
      checkedRows: [],
    };
  },
  computed: {
    sortedRows() {
      return sortBy(this.rows, ['$room.floor', '$room.unit', '$room.name', 'name'])
    }
  },
  methods: {
    get,
    compareFieldFunc,
    cap(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    buildingName(id) {
      let building = id
        ? getObjectInArrayById(this.$store.state.buildings, id, {})
        : null;
      return building ? building.name || "None" : "None";
    },
    roomName(id) {
      let room = id
        ? getObjectInArrayById(this.$store.state.rooms, id, {})
        : null;
      return room ? room.name || "None" : "None";
    },
  },
  props: {
    showArea: {
      type: Boolean,
      default: true,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    rowClass: {
      type: Function,
      default: () => false,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    showFlowRate: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: (row) => {
        router.push({
          name: 'administration:view-source',
          params: { id: row.id },
        })
      }
    }
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
};
</script>

<style scoped></style>
