<template>
  <div class="option-radio">
    <component
      :is="newLines ? 'div' : 'span'"
      v-for="option in options"
      :key="option.value.id || option.value"
    >
      <b-radio
        :native-value="
          option.value instanceof Object ? option.value.id : option.value
        "
        :value="value instanceof Object ? value.id : value"
        :type="typeFromValue ? 'is-' + option.value : 'is-primary'"
        @input="input(option.value)"
      >
        <slot name="default" v-bind="option">
          {{ option.label }}
        </slot>
      </b-radio>
    </component>
  </div>
</template>

<script>
/**
 * @typedef {Array.<{value:*,label:string}>} OptionArray
 */

export default {
  name: "OptionDrivenRadio",
  props: {
    typeFromValue: {
      type: Boolean,
      default: false,
    },
    /**
     * @type {OptionArray}
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * @type {string|number}
     */
    value: {
      type: [Number, String, Object],
      default: null,
    },
    newLines: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.new-lines {
  display: block;
}
</style>
