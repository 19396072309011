<template>
  <div>
    <div class="columns" v-if="object && !deleted">
      <div class="column is-12 is-10-fullhd is-offset-1-fullhd mb-6">
        <div class="card">
          <b-loading :is-full-page="false" :active="loading" />
          <div class="card-header generic-card-header">
            <div class="buttons">
              <b-button
                expanded
                class="mb-0 no-round"
                tag="router-link"
                :to="{ name: 'administration:hierarchy' }"
                icon-left="chevron-left"
              >
                Return to site administration
              </b-button>
              <slot name="ancestors">
                <b-button
                  v-for="ancestor in ancestors"
                  :key="ancestor.object.id"
                  class="mb-0 no-round"
                  expanded
                  :icon-left="ancestor.icon ? ancestor.icon : 'chevron-up'"
                  tag="router-link"
                  :to="ancestor.endpoint"
                >
                  {{ ancestor.title }}:
                  <b>{{ ancestor.object.name }}</b>
                </b-button>
              </slot>
            </div>
          </div>
          <div class="card-content">
            <div class="">
              <div class="card-content-header">
                <div class="subtitle mt-2 is-vcentered">
                  <b-icon
                    v-if="iconFor(objectName)"
                    :icon="iconFor(objectName)"
                  />
                  {{ $route.meta.title }}: <b>{{ object.name }}</b>
                  <slot name="header-buttons">
                    <b-button
                      v-if="canDelete"
                      :loading="loading"
                      :title="'Delete ' + object.name"
                      type="is-delete"
                      class="is-pulled-right"
                      :icon-right="iconFor('delete')"
                      @click="openDeleteDialog(object)"
                    />
                    <b-button
                      v-if="canEdit"
                      :loading="loading"
                      type="is-edit is-outlined"
                      :title="'Edit ' + object.name"
                      class="is-pulled-right ml-5"
                      :icon-right="iconFor('edit')"
                      @click="$emit('edit-clicked', true)"
                    />
                  </slot>
                </div>
              </div>
            </div>
            <hr />
            <div class="well has-text-centered">
              <generic-display :value="object" :show-link-icon="false"/>
            </div>
            <slot></slot>
            <hr v-if="childName" />
            <b-message :class="{ 'mt-6': !childName }">
              <slot name="pre-children" />
              <children
                v-if="childName"
                :name="childName"
                :children="children ? children : []"
              >
                <template v-slot:childrendisplay>
                  <div class="card children-card">
                    <div class="card-header">
                      <div class="card-header-icon" v-if="childIcon">
                        <b-icon :icon="childIcon" />
                      </div>
                      <div class="card-header-title">
                        {{ childTitle }}
                      </div>
                    </div>
                    <div class="card-content">
                      <slot name="childrendisplay" />
                      <p
                        class="has-text-centered has-text-grey-light"
                        v-if="!children.length"
                      >
                        None found
                      </p>
                    </div>
                    <div class="card-footer">
                      <div
                        class="card-footer-item"
                        v-if="object.id && !deleted"
                      >
                        <slot
                          name="addnewchild"
                          v-if="canEdit"
                          type="is-create is-outlined"
                        >
                          Add new child
                        </slot>
                      </div>
                    </div>
                  </div>
                </template>
              </children>
            </b-message>
          </div>
        </div>
      </div>
    </div>
    <div v-else>No object found</div>
  </div>
</template>

<script>
import Children from "./children";
import iconFor from "../../../icons";

export default {
  name: "GenericView",
  components: { Children },
  data() {
    return {
      deleted: false,
      loading: false,
    };
  },
  computed: {
    childNameForIcon() {
      return this.childName.split("/")[0].replace("-", "_").toLowerCase();
    },
    childIcon() {
      return iconFor(this.childNameForIcon);
    },
    canDelete() {
      return (
        this.allowDeletion &&
        this.canEdit &&
        (!this.children || this.children.length === 0) &&
        this.otherChildren.length === 0
      );
    },
    canEdit() {
      if (typeof this.editable !== "undefined") {
        return this.editable;
      }
      let id_str = this.object
        ? this.object.id_str || this.object.site_id_str
        : null;
      return (
        id_str &&
        this.$store.state.user_roles &&
        this.$store.state.user_roles.canEditPath(id_str)
      );
    },
  },
  methods: {
    iconFor,
    openDeleteDialog(obj) {
      if (this.children && this.children.length > 0) {
        this.$handleError("Please delete all child objects first.");
        return;
      }
      if (!obj.id) {
        throw "Object has no id";
      }
      let self = this,
        name = obj.name;
      self.$buefy.dialog.confirm({
        type: "is-danger",
        icon: "times",
        message: `Are you sure you want to delete <b>${name}</b>?<br/>
                  This can <b>not</b> be undone.`,
        onConfirm: () => {
          self.$emit("delete", obj);
          if (!self.stateVarName) {
            return;
          }
          self.loading = true;
          self.$store.dispatch("delete", {
            name: self.stateVarName,
            data: obj,
            onFinally: () => {
              self.loading = false;
              self.deleted = true;
              if (self.stateVarName === "sources") {
                if (self.object.room) {
                  self.$router.push({
                    name: `administration:view-room`,
                    params: { id: self.object.room.id },
                  });
                } else if (self.object.building) {
                  self.$router.push({
                    name: `administration:view-area`,
                    params: { id: self.object.building.id },
                  });
                } else {
                  self.$router.push({
                    name: `administration:view-site`,
                    params: { id: self.object.site.id },
                  });
                }
              } else if (self.stateVarName === "rooms") {
                if (self.object.building) {
                  self.$router.push({
                    name: `administration:view-area`,
                    params: { id: self.object.building.id },
                  });
                } else {
                  self.$router.push({
                    name: `administration:view-site`,
                    params: { id: self.object.site.id },
                  });
                }
              } else if (self.id) {
                self.$router.push({
                  name: `administration:view-${self.objectName}`,
                  params: { id: self.id },
                });
              } else if (self.parent && self.parentName) {
                self.$router.push({
                  name: `administration:view-${self.parentName}`,
                  params: { id: self.parent.id },
                });
              } else {
                self.$router.push({
                  name: "administration:hierarchy",
                });
              }
              self.$handleSuccess(`'${name}' has been deleted`);
            },
          });
        },
      });
    },
  },
  props: {
    ancestors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allowDeletion: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: undefined,
    },
    parent: {
      type: Object,
      required: false,
    },
    parentName: {
      type: String,
      required: false,
    },
    parentTitle: {
      type: String,
      required: false,
    },
    childName: {
      type: String,
      required: false,
    },
    childTitle: {
      type: String,
      required: false,
    },
    children: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    otherChildren: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    stateVarName: {
      type: String,
      required: false,
    },
    object: {
      type: Object,
      required: true,
    },
    objectName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.generic-card-header {
  display: block;
}

.no-round {
  border-radius: 0;
}
</style>
