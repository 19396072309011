<template>
  <b-table
    bordered
    striped
    :loading="loading"
    :checkable="checkable"
    :checked-rows.sync="checkedRows"
    :data="rows"
    detailed
    detail-key="id"
    @details-open="
      (row) => {
        getDetails(row);
      }
    "
  >
    <template #empty>
      <div class="has-text-centered">
        <i>No bundles found</i>
      </div>
    </template>
    <b-table-column field="$displayString" label="Name" sortable v-slot="props" :custom-sort="alphanumericSort"
      >{{ props.row.$displayString }}
    </b-table-column>
    <b-table-column label="Gateways" v-slot="props"
      >{{ props.row.gateways ? props.row.gateways.length : 0 }}
    </b-table-column>
    <b-table-column label="Sensors" v-slot="props"
      >{{ props.row.sensors ? props.row.sensors.length : 0 }}
    </b-table-column>
    <b-table-column label="Probes" v-slot="props"
      >{{ props.row.probes ? props.row.probes.length : 0 }}
    </b-table-column>
    <b-table-column label="Assigned" v-slot="props">
      <b-icon
        v-if="get(props.row, 'bundle_state.subcustomer')"
        icon="check"
        type="is-success"
      />
    </b-table-column>
    <b-table-column v-slot="props">
      <b-button
        @click="handleClick(props.row)"
        type="is-primary is-outlined"
        rounded
        size="is-small"
        class="is-pulled-right"
        icon-right="chevron-right"
        >Select</b-button
      >
    </b-table-column>

    <template slot="detail" slot-scope="props">
      <bundle-details
        :bundle="props.row"
        show-event-history
        :loading="parentByID.loading"
        :subcustomer="parentByID[props.row.id]"
      />
    </template>
  </b-table>
</template>

<script>
import DeviceEvent from "../dataClasses/DeviceEvent";
import { get } from "lodash";

export default {
  name: "BundleTable",
  data() {
    return {
      checkedRows: [],
      parentByID: {},
      lastEventByID: {},
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    get,
    handleClick(val) {
      this.$emit("select", val);
    },
    subcustomerIdForRow(row) {
      return get(row, "bundle_state.subcustomer.id", null);
    },
    getDetails(row) {
      let self = this,
        subcustomerId = self.subcustomerIdForRow(row);
      if (row && !self.parentByID[row.id]) {
        if (subcustomerId) {
          self.parentByID.loading = true;
          self.$dataClasses.SubCustomer.fetchById(subcustomerId)
            .then((res) => {
              self.$set(self.parentByID, row.id, res);
            })
            .catch((e) => self.$handleError(e, e))
            .finally(() => {
              self.$set(self.parentByID, "loading", false);
            });
        }
        self.lastEventByID.loading = true;
        DeviceEvent.latestFor(row._reference)
          .then((result) => {
            self.lastEventByID = self.$set(self.lastEventByID, row.id, result);
          })
          .catch((e) => self.$handleError(e, e))
          .finally(() => {
            self.lastEventByID.loading = false;
          });
      }
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.detail-media {
  display: flex;
  & .media-col {
    flex: 1;
    flex-wrap: wrap;
  }
  & .media-col:first-child {
    margin-left: 25px;
  }
}
</style>
