<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Sensor detail {{ serial ? `for ${serial}` : 'loading' }}</div>
      <div class="card-header-icon">
        <b-icon
            v-if="loading || loadingAction"
            icon="sync"
            class="fa-spin"
            size="is-small"
        />
        <b-icon v-else :icon="iconFor('sensor')"/>
      </div>
    </div>
    <div class="card-content">
      <div class="columns" v-if="loading || loadingPosition">
        <div class="column">
          <loading-card/>
        </div>
      </div>
      <div class="columns" v-else-if="!device">
        <div class="column has-text-centered">
          <p class="subtitle mt-6 mb-6">
            Error: No sensor found with serial <b>{{ serial }}</b>
          </p>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column is-2-desktop has-text-centered">
          <div class="block">
            <live-sensor-display v-if="position" :position="position"/>
            <live-sensor-display v-else :show-battery="false" :show-location="false" :show-signal-indicator="false"/>
          </div>
          <transition name="slide">
            <div v-if="serial">
              <b-dropdown
                  :triggers="isMobile ? ['click'] : ['hover', 'click']"
                  aria-role="list"
                  class="has-text-left"
              >
                <template #trigger>
                  <b-button
                      :loading="loadingAction"
                      :disabled="loadingAction"
                      :expanded="isMobile"
                      label="I'd like to:"
                      type="is-primary"
                      icon-right="chevron-down"
                  />
                </template>
                <b-dropdown-item
                    :disabled="!!position"
                    aria-role="listitem"
                    @click="device.$goToAddSensor()">
                  Install this sensor
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!position"
                    aria-role="listitem"
                    @click="position.$goToRemoveSensor()"
                >
                  Remove this sensor
                </b-dropdown-item>
                <hr/>
                <b-dropdown-item
                    :disabled="!position || !position.$canAttachProbe"
                    aria-role="listitem"
                    @click="position.$goToAttachProbe()"
                >
                  Attach a probe to sensor {{ serial }}
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!position || !position.$canRemoveProbe"
                    aria-role="listitem"
                    @click="position.$goToRemoveProbe()"
                >
                  Remove probe from sensor {{ serial }}
                </b-dropdown-item>
                <hr/>
                <b-dropdown-item
                    :disabled="
                    !position ||
                    !device ||
                    !device.$canStartAutothresholdForSingleSensor
                  "
                    aria-role="listitem"
                    @click="
                    promisary(
                      device.$startAutothresholdForSingleSensor(area),
                      'The auto-threshold loop for sensor ' +
                        serial +
                        ' has been initialized'
                    )
                  "
                >
                  Run auto-threshold loop for this sensor
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="!position"
                  aria-role="listitem"
                  @click="showThresholdModal=true"
                  >
                  Edit sensor threshold
                </b-dropdown-item>
                <hr/>
                <b-dropdown-item
                    :disabled="!position || position.$isInTrainingMode"
                    @click="
                    promisary(
                      position.$enterTrainingMode(),
                      `Sensor ${serial} is now in training mode`
                    )
                  "
                >
                  Enter training mode
                </b-dropdown-item>
                <b-dropdown-item
                    :disabled="!position || !position.$isInTrainingMode"
                    @click="position.$area.$goToTurnOffTrainingMode({next: $route.fullPath})"
                >
                  Exit training mode
                </b-dropdown-item>
                <hr/>
                <b-dropdown-item
                    aria-role="list-item"
                    @click="sendSupportEmailClicked('sensor')"
                >
                  Request technical support
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </transition>
        </div>
        <div class="column is-10-desktop">
          <div class="columns">
            <div class="column is-half-desktop">
              <div class="panel is-info">
                <div class="panel-heading">Sensor information</div>
                <div class="panel-block is-fullwidth">
                  <div class="mb-3">
                    <transition name="slide">
                      <sensor-detail-table
                          v-if="device"
                          :sensor="device"
                          :position="position"
                      >
                      </sensor-detail-table>
                    </transition>
                    <div class="buttons">
                      <b-button
                          v-if="$store.state.user_roles.is_root"
                          expanded
                          type="is-link"
                          @click="window.open((position||device).$qrCodeUrl, '_blank')"
                      >
                        QR Code
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half-desktop">
              <div class="panel is-primary" v-if="device">
                <div class="panel-heading">Installation location</div>
                <div class="panel-block is-fullwidth">
                  <div>
                    <install-location
                        v-if="position"
                        :position="position"
                        show-pipe-location
                        allow-edit
                        :table-classes="[
                          'is-bordered',
                          'is-striped',
                          'is-fullwidth',
                          'is-fullhd',
                        ]"
                        warning-in-modal="Warning: Changing the physical location of a sensor requires a re-training process. Please consult with AquaSeca."
                    >
                      <aq-image-upload
                          class="mt-3"
                          category="install_picture"
                          :parent-object-path="position.$path"
                      />
                    </install-location>
                    <div class="has-text-centered" v-else>
                      <i>
                        This device is not currently installed
                      </i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel is-success-passive" v-if="position && !showEventHistory">
                <div class="panel-heading">
                  Settings
                </div>
                <div class="panel-block is-fullwidth">
                  <div class="table is-fullwidth">
                    <div class="table is-fullwidth">
                      <tr>
                        <td class="has-text-weight-bold">
                          Threshold
                        </td>
                        <td>
                          <span v-if="position.$areaSensorEntry">
                            {{ position.$detectThreshold || 'None' }}
                          </span>
                          <span v-else>
                            No sensor in area
                          </span>
                        </td>
                      </tr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          ATTACHED HERE-->
          <transition name="slide">
            <b-message
                v-if="position"
                title="Attached probes"
                :closable="false"
            >
              <probe-table-for-position ref="probeTable" :position="position">
                <template #end>
                  <b-table-column v-slot="props">
                    <b-button
                        v-if="props.row.serial && props.row.installed"
                        type="is-link"
                        size="is-small"
                        class="is-pulled-right"
                        @click="
                        $router.push({
                          name: 'devices:probe-detail',
                          params: { serial: props.row.serial },
                        })
                      "
                    >
                      View
                    </b-button>
                  </b-table-column>
                </template>
                <template #post-table>
                  <b-button
                      class="is-pulled-right mt-3"
                      icon-left="plus-circle"
                      type="is-primary  "
                      :disabled="!position.$canAttachProbe"
                      @click="position.$goToAttachProbe()"
                  >
                    Add a probe to sensor {{ serial }}
                  </b-button>
                </template>
              </probe-table-for-position>
            </b-message>
          </transition>

          <transition name="slide">
            <b-button
                expanded
                size="is-small"
                v-if="!showEventHistory"
                @click="showEventHistory = true"
            >
              View sensor event history
            </b-button>
          </transition>
          <transition name="slide">
            <div v-if="showEventHistory">
              <event-history v-if="showEventHistory" :tabs="tabs"/>
              <b-button
                  expanded
                  size="is-small"
                  @click="showEventHistory = false"
              >
                Hide event history
              </b-button>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="card-footer">

        <div class="card-footer-item" v-if="positionId">
          <b-button
              :loading="!(position && position.$area)"
              expanded
              outlined
              icon-left="chevron-left"
              @click="$router.push({name:'devices:system-detail', params: {areaId: position.$area.id}})"
              type="is-link"
          >
            Return to area
          </b-button>
        </div>
        <div class="card-footer-item" v-if="positionId">
          <b-button
              :loading="!singleGateway"
              expanded
              outlined
              icon-left="chevron-left"
              @click="
            $router.push({
              name: 'devices:gateway-detail',
              params: { serial: singleGateway.serial },
            })
          "
              type="is-link"
          >
            <b-icon v-if="isDesktop" :icon="iconFor('gateway')" class="ml-3 mr-3"/>
            Gateway {{ singleGateway ? singleGateway.serial : '' }}
          </b-button>
        </div>
    </div>
    <edit-thresholds-modal
        trap-focus
        v-model="showThresholdModal"
        :internal-area="area"
        :positions="[position]"
      />
  </div>
</template>

<script>
import trackWindowSize from '../../../mixins/trackWindowSize';
import LoadingCard from '../../../components/Skeletons/LoadingCard';
import iconFor from '../../../icons';
import {get} from 'lodash';
import SensorDetailTable from '../../../tables/SensorDetailTable';
import DeviceSupportEmailMixin from '../../../applications/Settings/Devices/DeviceSupportEmailMixin';
import AqImageUpload from '../../../components/Files/Editors/AqImageUpload';
import ProbeTableForPosition from '../../../tables/ProbeTableForPosition';
import EditThresholdsModal from "../../../components/Dialogs/Modals/EditThresholdsModal";

export default {
  name: 'SensorDetail',
  components: {
    AqImageUpload,
    EditThresholdsModal,
    LoadingCard,
    ProbeTableForPosition,
    SensorDetailTable,
  },
  mixins: [DeviceSupportEmailMixin, trackWindowSize],
  data() {
    /**
     * @return {{device:Sensor}}
     */
    return {
      window,
      device: null,
      loading: false,
      loadingAction: false,
      loadingPosition: false,
      modalOpen: false,
      positionId: null,
      positionRaw: null,
      showEventHistory: false,
    };
  },
  props: {
    serial: {
      type: String,
      required: true,
    },
  },
  methods: {
    get,
    iconFor,
    promisary(prom, message = null) {
      this.loadingAction = true;
      prom.then(() => (message ? this.$handleSuccess(message) : null)).catch((e) => this.$handleError(e, e)).finally(() => (this.loadingAction = false));
    },
    getSensorDevice() {
      if (!this.serial) {
        this.positionId = null;
        this.device = null;
        return;
      }
      this.loading = true;
      this.$dataClasses.Sensor.queryForSingle([['serial', '==', this.serial]]).then((result) => {
        this.device = result;
        return this.$dataClasses.Position.queryForSingle([['serial', '==', this.serial]]);
      }).then((result) => {
        this.positionId = result ? result.id : null;
      }).catch((e) => {
        // this.$handle(
        //   `Sensor ${this.serial} is not currently installed`,
        //   null,
        // );
        this.$openToast({
          message: `Sensor ${this.serial} is not currently installed`,
          type: 'is-info',
          duration: 7000,
          position: 'is-bottom',
        });
        this.positionId = null;
        this.loading = false;
        // this.$router.push({name: 'devices'});
      }).finally(() => (this.loading = false));
    },
  },
  computed: {
    showThresholdModal: {
      get() {
        return Object.hasOwn(this.$route.query, "showThresholdModal");
      },
      set(showThresholdModal) {
        showThresholdModal
          ? this.queryReplace({ showThresholdModal })
          : this.queryRemoveKeys(["showThresholdModal"]);
      },
    },
    area() {
      return this.position ? this.position.$area : null;
    },
    position() {
      return this.positionRaw ? new this.$dataClasses.Position(this.positionRaw) : null;
    },
    $hasAvailablePort() {
      return this.position ? this.position.$hasAvailablePort : false;
    },
    $anyPortInUse() {
      if (!this.position) {
        return false;
      }
      return this.position.$anyPortInUse;
    },
    canAttachProbe() {
      return this.$hasAvailablePort;
    },
    singleGateway() {
      if (!this.position) {
        return null;
      }

      let gwArray = get(this, 'gateways', []) || [],
          gws = gwArray.filter((gw) =>
              (gw.connect_weights||[]).filter(
              (cw) => cw.sensor.id === this.positionId && !!cw.weight,
          ),
      );
      return gws.length === 1 ? gws[0] : null;
    },
    gateways() {
      return this.serial
          ? this.$store.getters.gatewaysForSensorSerial(this.serial)
          : [];
    },
    site() {
      return this.position ? this.position.$site : null;
    },
    tabs() {
      let val = {};
      if (this.device) {
        val.Sensor = this.toFSRef(this.device, 'sensors');
      }
      if (this.positionId) {
        val.Position = this.$db.collection(this.$dataClasses.Position.collectionName).doc(this.positionId);
      }
      return val;
    },
  },
  watch: {
    serial: {
      immediate: true,
      handler() {
        this.getSensorDevice();
      },
    },
    position: {
      immediate: true,
      handler(pos) {
        if (pos && pos.$siteIdStr && pos.$siteIdStr !== this.$route.query.loadScope) {
          this.queryReplace({loadScope: pos.$siteIdStr});
        }
      },
    },
    positionId: {
      immediate: true,
      handler(val) {
        this.loadingPosition = true;
        if (!val) {
          if (this.positionRaw) {
            this.$unbind('positionRaw');
          }
          this.positionRaw = null;
          this.loadingPosition = false;
        } else {
          // subscribe
          this.$bind(
              'positionRaw',
              this.$db.collection(this.$dataClasses.Position.collectionName).doc(val),
              {maxRefDepth: 0},
          ).then(() => {
            this.loadingPosition = false;
          });
        }
      },
    },
  },
};
</script>

<style scoped></style>
