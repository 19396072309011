<template>
  <div :class="classes">
    <slot name="title">
      <p :class="{'widget-title':true,titleClasses:true}">{{ title }}</p>
    </slot>
    <div :class="classes.includes('widget') ? 'widget-content' : ''">
      <div class="has-text-centered mt-3">
        <spinner-with-checkmark :loading="loading" :success="success"/>
        <transition name="fade">
          <div v-if="success && !loading" class="has-text-success">
            All devices functioning
          </div>
        </transition>
        <transition name="fade">
          <div v-if="!success && !loading" :class="{'has-text-danger':true}">
            <p class="is-underlined mb-2">
              Warning: {{ unhealthyDevices.length }} device issue{{
                unhealthyDevices.length === 1 ? "" : "s"
              }}
              found
            </p>
            <ul class="has-text-justified has-text-left has-text-small-zoom">
              <li v-for="device in unhealthyDevices" :key="device.serial">
                <router-link
                    :to="{
                    name: 'go-to-device',
                    params: { serial: device.serial }
                  }"
                >{{ device.serial }}
                </router-link>
                :
                <span>{{ serialToUnhealthText[device.serial] }}</span>
              </li>
            </ul>
          </div>
          <slot name="default"/>
        </transition>
      </div>
    </div>
    <slot name="link">
      <router-link
          class="widget-bottom-link"
          :to="{ name: 'devices:area-list' }"
      >
        View systems
      </router-link>
    </slot>
  </div>
</template>

<script>
import intervalFunctionMixin from "../../../mixins/intervalFunctionMixin";


function getSerialsToUnhealthTextObject(positions) {
  const retObj = {}
  positions.forEach(p => retObj[p.serial] = p.$notHealthyText)
  return retObj
}

export default {
  name: "SystemStatusTile",
  mixin: [intervalFunctionMixin],
  props: {
    classes: {
      type: String,
      default: "tile dashboard-widget is-child is-short"
    },
    titleClasses: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false,
    },
    gatewayPositions: {
      type: Array,
      default: () => [],
    },
    positions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Area status"
    }
  },
  data() {
    return {
      serialToUnhealthText: {}
    }
  },
  methods: {
    onPoll() {
      this.serialToUnhealthText = getSerialsToUnhealthTextObject(this.unhealthyDevices)
    }
  },
  computed: {
    unhealthyDevices() {
      return this.gatewayPositions
          .filter((gwP) => !gwP.$isHealthy)
          .concat(this.positions.filter((p) => !p.$isHealthy));
    },
    success() {
      return this.unhealthyDevices.length === 0;
    },
  },
  watch: {
    unhealthyDevices: {
      immediate: true,
      handler(unhealthyDevices) {
        this.serialToUnhealthText = getSerialsToUnhealthTextObject(unhealthyDevices);
      }
    }
  }
};
</script>

<style scoped>
.has-text-small-zoom {
  font-size: 12px !important;
}
</style>
