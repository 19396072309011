<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            File: {{ fileObject ? fileObject.metadata.filename : "" }}
          </div>
          <div class="card-header-icon">
            <b-icon v-if="loading" icon="loader" class="fa-spin" />
            <b-icon v-else :icon="iconFor('file')" />
          </div>
        </div>
        <div class="card-content">
          <b-loading active v-if="loading" />

          <div v-else-if="fileObject">
            <file-data
              ref="fileData"
              v-if="!detailedEdit"
              show-image
              :file-object="fileObject"
            >
              <template #pre-fields>
                <b-field label="Parent" v-if="parent">
                  <generic-display :value="parent" />
                </b-field>
              </template>
              <template #inside-buttons v-if="canHaveDetailedEdit">
                <b-button
                  :icon-left="iconFor('update')"
                  @click="detailedEdit = true"
                >
                  Edit {{ fileObject.metadata.category.replace("_", " ") }}
                </b-button>
              </template>
            </file-data>
            <generic-file-edit
              v-else
              :parent-object="parent"
              :file-object="fileObject"
            />
          </div>

          <p v-else>File not found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconFor from "../../icons";
import GenericFileEdit, {
  categoryToComponent,
} from "../../components/Files/GenericFileEdit";

export default {
  name: "FileDetail",
  components: { GenericFileEdit },
  props: {
    filePath: {
      type: String,
      required: true,
      validator: function (path) {
        return path && path.split("/").filter((s) => !!s).length === 3;
      },
    },
  },
  data() {
    return {
      loading: false,
      fileObject: null,
    };
  },
  mounted() {
    this.loading = true;
    this.$client
      .get("/files/" + this.filePath, {}, { metadata_only: true })
      .then((response) => {
        this.fileObject = response.result;
      })
      .catch((e) => this.$handleError(e))
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    detailedEdit: {
      get() {
        return this.$route.query.detailEdit;
      },
      set(val) {
        this.queryPush({ detailEdit: !!val });
      },
    },
    canHaveDetailedEdit() {
      if (
        !this.fileObject ||
        !this.fileObject.metadata ||
        !this.fileObject.metadata.category
      ) {
        return false;
      }
      let component = categoryToComponent[this.fileObject.metadata.category];
      return !!component;
    },
    pathSegments() {
      return this.filePath ? this.filePath.split("/") : null;
    },
    objectType() {
      return this.pathSegments ? this.pathSegments[0] : null;
    },
    parentId() {
      return this.pathSegments ? this.pathSegments[1] : null;
    },
    fileName() {
      return this.pathSegments ? this.pathSegments[2] : null;
    },
    parent() {
      return this.parentId
        ? this.$store.getters.objectByID(this.parentId)
        : null;
    },
  },
  methods: {
    iconFor,
  },
};
</script>

<style scoped></style>
