var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"active":_vm.active,"has-modal-card":""},on:{"update:active":function($event){return _vm.$emit('update:active', $event)},"close":function($event){return _vm.$emit('update:active', $event)}}},[_c('div',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-card-head"},[_c('div',{staticClass:"modal-card-title"},[_vm._v("Edit location for "+_vm._s(_vm.name))])]),_c('div',{staticClass:"modal-card-body"},[_vm._t("default"),_c('building-and-room',{attrs:{"site":_vm.site,"selected-building-id":_vm.object.building ? _vm.toId(_vm.object.building) : null,"selected-room-id":_vm.object.room ? _vm.toId(_vm.object.room) : null,"installation-notes":_vm.object.installation_notes,"show-notes":_vm.showNotes,"disabled":_vm.loading,"building-editable":!_vm.toId(_vm.object) || !_vm.object.building},on:{"input":function($event){_vm.toSave = $event}}},[(_vm.showPipeLocation)?_c('div',{staticClass:"well"},[_c('pipe-location-table',{attrs:{"can-add":"","area":(_vm.toSave.building && _vm.toSave.building.$area) || _vm.object.$area,"building":_vm.toSave.$building || (_vm.object.$area && _vm.object.$area.$building),"rows":_vm.$store.state.pipe_locations.filter(
                  (pl) =>
                    !pl.probe &&
                    (!pl.position || _vm.toId(pl.position) === _vm.toId(_vm.object)) &&
                    pl.building &&
                    _vm.object.building &&
                    pl.building.id === _vm.toId(_vm.object.building) &&
                    (!pl.room || _vm.toId(_vm.toSave.room) === _vm.toId(pl.room))
                ),"loading":_vm.$store.state.fetching.pipe_locations,"title":`Select or create a pipe location for ${_vm.object.serial}`,"selected-id":_vm.toSave.pipe_location ? _vm.toId(_vm.toSave.pipe_location, 'pipe_locations') : _vm.initialPipeLocationId || null,"paginate_by":25},on:{"input":function($event){return _vm.handlePLSelection($event)}},scopedSlots:_vm._u([{key:"post-name",fn:function(){return undefined},proxy:true}],null,false,1080591515)})],1):_vm._e()])],2),_c('div',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){_vm.$emit('update:active', false); _vm.$emit('close')}}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"loading":_vm.loading,"type":"is-primary","disabled":!_vm.canSubmit},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }