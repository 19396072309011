<template>
  <form @submit.prevent="submit">
    <template v-for="(property, key) in schema.properties">
      <slot
        :name="key"
        :item="{
          key: key,
          schema: property,
          value: items[key],
          update: updateValue,
        }"
      >
        <form-element
          :show-hidden="showHidden"
          :key="key"
          :schema="property"
          :name="key"
          :value="items[key]"
          @input="updateValue($event, key)"
        ></form-element>
      </slot>
    </template>
    <slot name="header" :error-messages="activeErrorMessages">
      <b-notification
        v-show="activeErrorMessages.length"
        type="is-danger"
        :closable="false"
        id="error-messages"
      >
        <div v-for="(error, index) in activeErrorMessages" :key="index">
          <div>{{ error }}</div>
        </div>
      </b-notification>
    </slot>
    <slot name="actions">
      <div class="buttons mt-3 mb-3">
        <b-button
            type="is-primary"
            :disabled="submitBlocked"
            @click="submit()">
          Submit
        </b-button>
        <b-button
          @click="showHidden = !showHidden"
          v-if="$store.state.user_roles.is_root"
        >
          {{ showHidden ? "Hide" : "Show" }} hidden fields
        </b-button>
        <slot name="more-actions"></slot>
      </div>
      <p class="has-text-grey is-size-6" v-if="$store.state.user_roles.is_root">
        Note: Hidden fields and schema refresh are
        <b>only available to root users</b>.
      </p>
    </slot>
  </form>
</template>

<script>
import Ajv from "ajv";
import JSONPointer from "json-pointer";
import FormElement from "../components/elements/FormElement";
import {
  pruneEmptyMembers,
  scaffoldFromSchema,
} from "../utility/json-schema-helpers";
import {isEqual} from "lodash";

function getAJV() {
  const ajv = new Ajv({
    allErrors: true,
    jsonPointers: true,
    format: "full",
    removeAdditional: true,
  });
  ajv.addFormat("date-time", function (dateTimeString) {
    if (typeof dateTimeString === "object") {
      dateTimeString = dateTimeString.toISOString();
    }
    return !isNaN(Date.parse(dateTimeString)); // any test that returns true/false
  });
  return ajv;
}

//
// const dateTimeRegex = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9]) (2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?$');
// ajv.addFormat('date-time', {
//   validate: (dateTimeString) => dateTimeRegex.test(dateTimeString)
// });

export default {
  name: "SchemaForm",
  components: {
    FormElement,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object,
    },
    submitBlocked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
    element: {
      default() {
        return "form-element";
      },
    },
  },
  data() {
    return {
      items:
        this.value !== undefined ? this.value : scaffoldFromSchema(this.schema),
      activeErrorMessages: [],
      ajv: getAJV(),
      showHidden: false,
    };
  },
  methods: {
    input() {
      // console.log("1.input", pruneEmptyMembers(this.items));
      this.$emit("input", pruneEmptyMembers(this.items));
    },
    submit() {
      if (this.validate()) {
        this.activeErrorMessages = [];
        this.input();
        this.$emit("submit");
      } else {
        this.buildErrors();
      }
    },
    validate() {
      // validate against schema
      return this.ajv.validate(this.schema, pruneEmptyMembers(this.items));
    },
    buildErrors() {
      this.activeErrorMessages = this.ajv.errors.map((error) => {
        if (error.keyword === "required") {
          const path =
              error.dataPath.length === 0
                ? `/properties/${error.params.missingProperty}`
                : error.schemaPath.substring(1, error.schemaPath.length - 8) +
                  `properties/${error.params.missingProperty}`,
            property = JSONPointer.get(this.schema, path);
          return property.title + " is required";
        } else if (error.keyword === "format") {
          const path = error.schemaPath.substring(
              1,
              error.schemaPath.length - 7
            ),
            property = JSONPointer.get(this.schema, path);
          return `${property.title} is not in the correct format. Eg: ${property.format}`;
        } else if (error.keyword === "pattern") {
          const path = error.schemaPath.substring(
              1,
              error.schemaPath.length - 8
            ),
            property = JSONPointer.get(this.schema, path);
          return `${property.title} is not in the correct format. Eg: ${property.example}`;
        } else if (error) {
          const path = error.schemaPath.substring(
              1,
              error.schemaPath.length - 5
            ),
            property = JSONPointer.get(this.schema, path);
          return `${property.title}: ${error.message}`;
        }
      });
    },
    updateValue(value, child) {
      // console.log('schemaForm.updateValue', {value,child})
      let updateDict = {};
      updateDict[child] = value;
      this.items = { ...this.items, ...updateDict };
    },
  },
  watch: {
    value(val) {
      // console.log('1.watch.value', val);
      for (const [fieldName,val] of Object.entries(val)){
        this.updateValue(val, fieldName)
      }
    },
    schema: {
      immediate: true,
      handler(cur, prev) {
        if (isEqual(cur, prev)) {
          return
        }
        // console.log("SCHEMA CHANGE")
        // console.log('1. items=scaffoldFromSchema', this.items)
        this.items = scaffoldFromSchema(this.schema, undefined, this.value);
        if (this.items && this.items.scope) {
          this.$emit('update:scope', this.items.scope);
        }
        this.activeErrorMessages = [];
        this.ajv = getAJV();
      },
    },
    items: {
      handler(curItems,prevItems) {
        // console.log("1.watch.items",{curItems,prevItems})
        this.input();
      },
    },
  },
};
</script>
