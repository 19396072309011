<template>
  <div class="flags card">
    <div class="card-header">
      <div class="card-header-title">Features</div>
      <div class="card-header-icon">
        <b-icon :class="{ 'fa-spin': loading }" icon="sync" v-if="loading" />
      </div>
    </div>
    <div class="card-content">
      <div v-for="feature in FEATURE_FLAGS" :key="feature.key" class="level">
        <div class="level-left">
          <b-field :label="feature.title" :message="feature.description" />
        </div>
        <div class="level-right">
          <b-switch
            :disabled="loading"
            :value="getFlag(feature.key)"
            @input="handleInput(feature.key, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";

import FEATURE_FLAGS from "../../components/Flags";

export default {
  name: "Flags",
  props: {
    userAccount: {
      type: Object,
    },
  },
  data() {
    return {
      FEATURE_FLAGS,
      loading: false,
    };
  },
  methods: {
    getFlag(key) {
      return this.flags[key];
    },
    handleInput(key, value) {
      let updateObj = {};
      updateObj[key] = value;
      if (get(this.flags, key) !== value) {
        this.submit(Object.assign(this.flags, updateObj));
      }
    },
    submit(value) {
      this.loading = true;
      this.$dataClasses.UserAccount.save(this.userAccount.id, {
        flags: value,
      })
        .then((result) => {
          this.$emit("update:user-account", result);
        })
        .catch((e) => this.$handleError("Could not update, please try again", e))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    flags() {
      return this.userAccount.flags || {};
    },
  },
};
</script>

<style scoped></style>
