<template>
  <div class="probe-table">
    <b-table :data="rows" v-bind="$props">
      <template #empty> No position manifest found </template>
      <b-table-column label="Port" v-slot="props">
        <b>{{ props.row.port }}</b>
      </b-table-column>
      <b-table-column label="Part" v-slot="props">
        {{ props.row.part }}
      </b-table-column>
      <b-table-column label="Type" v-slot="props">
        {{ props.row.type }}
      </b-table-column>
      <b-table-column label="Serial" v-slot="props">
        <i v-if="!props.row.installed"> Not installed </i>
        <span v-else>
          {{ props.row.serial }}
        </span>
      </b-table-column>
      <slot name="end" v-slot="props" />
    </b-table>
    <slot name="post-table" :has-available-port="$hasAvailablePort" />
  </div>
</template>

<script>
export default {
  name: "ProbeTableForPosition",
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  computed: {
    $hasAvailablePort() {
      if (!this.position) {
        return false;
      }
      return !this.position.port_1.serial || !this.position.port_2.serial;
    },
    rows() {
      return [
        {
          port: "1",
          part: this.position.port_1.model,
          type: this.position.port_1.type, //todo
          serial: this.position.port_1.serial || "Not installed",
          installed: !!this.position.port_1.serial,
        },
        {
          port: "2",
          part: this.position.port_2.model,
          type: this.position.port_2.type, //todo
          serial: this.position.port_2.serial || "Not installed",
          installed: !!this.position.port_2.serial,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
