<template>
  <b-modal
    :active="active"
    @close="$emit('update:active', false)"
    can-cancel
    has-modal-card
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          Select which sensors to put into training mode
        </div>
      </div>
      <div class="modal-card-body">
        <b-loading :is-full-page="false" :active="loading" />
        <p class="mb-3">
          Select the sensors you wish to put into training mode
        </p>
        <b-table
          :loading="loading"
          :data="positions"
          checkable
          :checked-rows.sync="checkedRows"
          :is-row-checkable="(row) => !row.$isInTrainingMode"
          :default-sort="['serial']"
          default-sort-direction="asc"
        >
          <b-table-column field="serial" sortable label="Serial" v-slot="props">
            {{ props.row.serial }}
          </b-table-column>
          <b-table-column
            field="$lastAutothresholdTimestamp"
            label="Last auto-threshold completed"
            v-slot="props"
          >
            <a
                v-if="!props.row.$lastAutothresholdTimestamp"
                class="has-text-red"
                @click="props.row.$area.$goToAutoThresholdDialog({next:$route.query.next || $route.fullPath})"
            >
              <b-icon icon="times" type="is-danger"/>
              PLEASE RUN
            </a>
            <span v-else>{{ props.row.$lastAutothresholdTimestamp }}</span>
          </b-table-column>
          <b-table-column
            field="$calibrationState"
            label="Current state"
            sortable
            v-slot="props"
          >
            <b-tooltip
              :active="props.row.$isInTrainingMode"
              label="This sensor is already in training mode"
            >
              <span
                :class="{
                  'has-text-weight-bold has-text-primary':
                    props.row.$isInTrainingMode,
                  'has-text-success has-text-weight-bold':
                    props.row.$calibrationState === 'trained',
                }"
              >
                {{
                  capitalizeFirstLetter(
                    get(props.row, "$calibrationState", "Unknown")
                  )
                }}
              </span>
            </b-tooltip>
          </b-table-column>
          <b-table-column
            field="$calibrationState"
            label="Since"
            sortable
            v-slot="props"
          >
            {{
              get(props.row, "analytics.calibration.timestamp", "")
                ? get(
                    props.row,
                    "analytics.calibration.timestamp",
                    ""
                  ).toLocaleDateString("en-US") +
                  " " +
                  get(
                    props.row,
                    "analytics.calibration.timestamp",
                    ""
                  ).toLocaleTimeString("en-US")
                : ""
            }}
          </b-table-column>
          <b-table-column
            field="$building.name"
            label="Area"
            sortable
            v-slot="props"
          >
            <building-display
              :value="props.row.$building"
              v-if="props.row.$building"
              :show-floors="false"
            />
          </b-table-column>
          <b-table-column
            field="$room.name"
            label="Room"
            sortable
            v-slot="props"
          >
            <room-display :value="props.row.$room" v-if="props.row.$room" />
          </b-table-column>
        </b-table>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button expanded @click="$emit('update:active', false)">
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button
            expanded
            type="is-success"
            @click="submitClicked()"
            :disabled="!(checkedRows && checkedRows.length)"
            :loading="loading"
          >
            Switch {{ checkedRows.length }}
            <span v-if="!isMobile"
              >sensor{{ checkedRows.length === 1 ? "" : "s" }}</span
            >
            to training mode
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import trackWindowSize from "../../mixins/trackWindowSize";
import Area from "../../dataClasses/Area";
import { get } from "lodash";

export default {
  name: "SelectSensorsForTraining",
  mixins: [trackWindowSize],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Area,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    positions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedRows: [],
    };
  },
  methods: {
    get,
    submitClicked() {
      if (this.checkedRows.length) {
        this.$emit(
          "input",
          this.checkedRows.map((p) => p.sensor_reference)
        );
        this.$emit("update:active", false);
      }
    },
    initializeCheckedRows() {
      this.checkedRows = this.positions.filter((p) => p && !p.$isInTrainingMode);
    },
  },
  watch: {
    positions: {
      handler() {
        this.initializeCheckedRows();
      },
    },
    active: {
      immediate: true,
      handler(is, was) {
        this.initializeCheckedRows();
        if (!is && was) {
          this.$route.query.next ? this.$router.push(this.$route.query.next) : null
        }
      },
    },
  },
};
</script>

<style scoped></style>
