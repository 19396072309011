<template>
  <div :class="classes">
    <slot name="pre-icon" />
    <b-icon :icon="icon" />
    {{ value.$displayString }}
    <slot name="post-name"></slot>
    <i v-if="showAddress && value.address && !isMobile && !isMultiline"
      >- {{ value.address }}</i
    >
    <div v-if="showAddress && value.address && isMultiline">
      {{ value.address }}
    </div>
    <slot>
      <span v-if="showLinkIcon" class="is-pulled-right mr-3">
        <b-icon
          :icon="linkIcon"
          type="is-link"
          :class="{ pointer: true }"
          @click.native="
            $router.push(getLinkFunction(value));
            $event.preventDefault();
          "
        />
      </span>
    </slot>
    <slot name="end" />
  </div>
</template>

<script>
import iconFor from "../../icons";
import trackWindowSize from "../../mixins/trackWindowSize";

export default {
  name: "Site",
  mixins: [trackWindowSize],
  computed: {
    icon() {
      return iconFor("site");
    },
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
    showLinkIcon: {
      type: Boolean,
      default: false,
    },
    linkIcon: {
      type: String,
      default: iconFor("update"),
    },
    getLinkFunction: {
      type: Function,
      default: function (src) {
        return this.$router.match({
          name: "administration:view-site",
          params: { id: src.id },
          query: {
            loadScope: src.id_str
              ? src.id_str.split("|").slice(0, 5).join("|")
              : null,
          },
        });
      },
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    isMultiline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
inline-block {
  display: inline-block;
}
</style>
