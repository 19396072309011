<template>
  <div>
    <transition name="slide">
      <loading-chart-card v-if="loading" />
    </transition>
    <transition name="slide">
      <div v-if="!loading && shipment">
        <div class="columns">
          <div class="column is-12">
            <p class="subtitle">
              Viewing bundled shipment information for {{ shipment.name }}
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="panel is-primary">
              <div class="panel-heading">Bundle information</div>
              <div class="panel-block">
                <json-viewer
                  :value="_shipment"
                  :expand-depth="5"
                  expanded
                  copyable
                  boxed
                  sort
                />
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="panel is-info">
              <div class="panel-heading">General details</div>
              <div class="panel-block">
                <div class="control">
                  <bundle-details :bundle="shipment" :subcustomer="subcustomer" />
                </div>
              </div>
            </div>
            <event-history :tabs="tabs" :paginate-by="10" />
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide">
      <div v-if="!loading && !shipment">Shipment not found</div>
    </transition>
  </div>
</template>

<script>
import serialize from "../../../apiClient/serializer";
import JsonViewer from "vue-json-viewer";
import { get } from "lodash";
import LoadingChartCard from "../../../components/Skeletons/LoadingChartCard";

export default {
  name: "ViewShipment",
  components: {
    LoadingChartCard,
    JsonViewer,
  },
  data() {
    return {
      loading: false,
      shipment: this.shipmentData,
      subcustomer: null,
    };
  },
  computed: {
    tabs() {
      let _ = {};
      _["Bundle"] = this.toFSRef(this.shipment, "bundles");
      return _;
    },
    _shipment() {
      return this.shipment ? this.wrangled(this.shipment) : {};
    },
    subcustomerId() {
      return this.shipment
        ? get(this.shipment, "bundle_state.subcustomer.id", null)
        : null;
    },
  },
  methods: {
    wrangled(jsonData) {
      return serialize(jsonData || {});
    },
    onUpdate() {
      let self = this;
      self.$nextTick(() => {
        if (!self.shipment || self.shipment.id !== self.id) {
          self.loading = true;
          this.$dataClasses.Bundle.fetchById(self.id)
            .then((obj) => {
              self.shipment = obj;
              return self.subcustomerId
                ? this.$dataClasses.SubCustomer.fetchById(self.subcustomerId)
                : Promise.resolve(null);
            })
            .then((result) => (self.subcustomer = result))
            .catch((e) => self.$handleError("Could not load shipment data", e))
            .finally(() => {
              self.loading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.onUpdate();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    shipmentData: {
      type: Object,
      required: false,
    },
  },
  watch: {
    id: {
      handler(val) {
        this.onUpdate();
      },
    },
  },
};
</script>

<style scoped>
.panel-fullwidth {
  width: 100%;
  min-width: 100%;
  margin: 0 auto;
  display: block;
}
</style>
