<template>
  <b-modal
    has-modal-card
    trap-focus
    :active="value"
    :class="stateVarName + '-modal has-overflow'"
    :can-cancel="!(preventCancelIfLoading && loading)"
    @close="close()"
  >
    <b-loading
      :active="$store.state.fetching[stateVarName]"
      :is-full-page="fullPage"
    />
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title">
            {{ title }}
          </slot>
        </p>
        <div class="model-card-icon" v-if="iconFor(objDisplayName)">
          <b-icon
            :icon="iconFor(objDisplayName)"
            size="is-medium"
            class="mr-3"
          />
        </div>
      </header>

      <section class="modal-card-body">
        <slot> Form slot is empty</slot>
      </section>

      <footer class="modal-card-foot">
        <slot name="footer">
          <div class="card-footer-item">
            <b-button
                expanded
                label="Cancel"
                :disabled="preventCancelIfLoading && loading"
                @click="close()"
            />
          </div>
          <div class="card-footer-item">
            <b-button
              label="Submit"
              expanded
              :disabled="!!!canSubmit"
              :loading="loading"
              @click="submitClicked()"
              type="is-primary"
            />
          </div>
        </slot>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import store from "../../../store";
import iconFor from "../../../icons";
export default {
  name: "ModalWithForm",
  computed: {
    title() {
      return this.id
        ? `Edit ${this.objDisplayName} ${
            this.objectData ? this.objectData.name : ""
          }`
        : `Add ${this.objDisplayName}`;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    preventCancelIfLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "is-default",
    },
    /**
     * Whether modal is open
     */
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Data for this object as in form
     */
    inputs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * ID for object if editing existing, otherwise null (for creating)
     */
    id: {
      type: String,
      default: null,
      required: false,
    },
    /**
     * Whether to show delete button (not implemented) or allow deletion method
     */
    allowDelete: {
      type: Boolean,
      default: false,
    },
    /**
     * General label for object of this type, i.e. "Customer"
     */
    objDisplayName: {
      type: String,
      required: true,
    },
    /**
     * A variable defined in stateVariables; Form will, on save:
     * - Add this object to array if object has been created (saved, no id supplied before) and variable is array
     * - Replace this object in array if variable is array and object is being edited (id passed)
     * - Otherwise will assign value of saved return value to var
     */
    stateVarName: {
      type: String,
      required: true,
    },
    /**
     * Whether to make modal full-page
     */
    fullPage: {
      type: Boolean,
      required: false,
    },
    /**
     * Whether form can be submitted (validation hook)
     */
    canSubmit: {
      required: true,
    },
  },
  methods: {
    iconFor,
    /**
     * Can be used via parent v-model
     */
    save() {
      let self = this,
        dispatchAction = self.id ? "modify" : "create",
        verb = self.id ? "updated" : "added";
      self.loading = true;
      self.inputs.id = self.id;
      store.dispatch(dispatchAction, {
        name: self.stateVarName,
        data: self.inputs,
        hideFetching: true,
        callback: function (result) {
          self.$handleSuccess(`Your ${self.objDisplayName} has been ${verb}`);
          self.$emit("saved", result);
          self.close();
        },
        onFinally: function () {
          self.loading = false;
        },
      });
    },
    delete() {
      let self = this;
      if (!self.allowDelete || !self.id) {
        throw "Delete is not available for this object";
      } else {
        store.dispatch("delete", {
          name: self.stateVarName,
          data: { id: self.id },
          callback: function (result) {
            self.$emit("deleted", result);
            self.$handleSuccess(
              `Object of type '${self.objDisplayName}' has been deleted`
            );
          },
          onFinally: function () {
            self.loading = false;
          },
        });
      }
    },
    /**
     * When submit button clicked
     * @event input
     * @event submit
     */
    submitClicked() {
      this.save();
    },
    /**
     * @event changed
     */
    close() {
      this.$emit("closed", false);
    },
    /**
     * @event loading-changed
     */
    loadingChanged(loading) {
      this.$emit("loading-changed", loading);
    },
  },
  watch: {
    loading: {
      handler(val) {
        this.loadingChanged(val);
      },
    },
  },
};
</script>

<style scoped>
.modal-card-body {
  min-width: 60vw;
}
</style>
