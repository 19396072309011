<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">User administration</div>
    </div>
    <div class="card-content">
      <div v-if="$route.name === 'administration:users'">
        <b-field label="View:" class="mb-3 mt-1">
          <b-radio
              v-model="style"
              native-value="all"
              v-if="$store.state.user_roles.is_root">
            all users
          </b-radio>
          <b-radio
              v-model="style"
              native-value="root"
              v-if="$store.state.user_roles.is_root"
          >
            root users
          </b-radio>
          <b-radio
              v-model="style"
              native-value="path"
              v-if="$store.state.user_roles.is_root || $store.state.user_roles.is_admin">
            <b>{{ $store.getters.topLevel.object.name }}</b>&nbsp;users
          </b-radio>
          <b-radio
              v-model="style"
              native-value="search"
          >
            users that match search
          </b-radio>
        </b-field>
        <transition name="slide">
          <div v-if="style==='search'">
            <b-field grouped message="Enter email, name, or phone. Phone number inputs must follow format: +1 654-321-0987">
              <b-field
                  label="Search (email/name/phone)"
                  class="mt-2 mb-1"
              >
                <b-input
                    :value="searchInput"
                    @input="setSearchInput($event)"
                    @keyup.enter.native="searchInput=$data._searchInput"
                    :loading="loading"
                />
                <p class="control">
                  <b-button @click="searchInput=$data._searchInput" :disabled="loading">
                    Submit
                  </b-button>
                </p>
              </b-field>
            </b-field>
          </div>
        </transition>
      </div>

      <div v-if="$route.name === 'administration:users'">
        <user-account-table :loading="loading" :rows="userAccounts">
          <template #pagination>
            <pagination-controls
                v-if="userAccounts && userAccounts.length && !loading"
                classes="is-pulled-right mt-3 mb-3"
                :page="pageNumber"
                :has-more-results="hasMoreResults"
                @input="pageNumber = $event.page"
            />
          </template>
        </user-account-table>
        <p
            class="has-text-centered"
            v-if="!userAccounts || userAccounts.length < 1"
        >
          <b-message type="is-info" v-if="!loading">
            There are no users to show
            <span v-if="style === 'search' && searchInput">
              for search term <i>{{ searchInput }}</i>.
            </span>
            <span v-else-if="style === 'search'">
              ; Please enter a search term.
            </span>
            <span v-if="style === 'all'">
                in full user list at page {{ pageNumber }}
              </span>
            <span v-else-if="style === 'root'">
                with root access at page {{ pageNumber }}
              </span>
            <span v-else-if="style === 'path' && $store.getters.topLevel.object">
                at <i>{{parentPath}}</i>.
            </span>
          </b-message>
          <b-loading v-else :is-full-page="false"/>
        </p>
        <router-view/>
      </div>
      <div class="card-footer">
        <div class="card-footer-item">
          <b-button
              class="is-pulled-right mt-2"
              icon-left="user"
              tag="router-link"
              :to="{ name: 'profile:new' }"
              v-if="user_roles.is_admin"
          >Add a new user
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {debounce, isEqual} from "lodash";
import {mapState} from "vuex";
import UserAccountTable from "../../tables/UserAccountTable";
import PaginationControls from "../../components/Pagination/PaginationControls";
import {mergeById} from "../../store/shared";

const paginate_by = 10;

export default {
  name: "ManageUsers",
  components: {PaginationControls, UserAccountTable},
  data() {
    return {
      loading: false,
      userAccounts: [],
      queryResults: [],
      _searchInput: '',
    };
  },
  mounted() {
    if (!this.parentPath && !this.$store.state.user_roles.is_root) {
      this.updateParentPath();
    }
    this.$data._searchInput = this.searchInput;
  },
  computed: {
    ...mapState(["selectedScopeIdStr", "user_roles"]),
    parentPath() {
      return this.$store.state.user_roles.getHighestLevelScopeToLoadFromIdString(
          this.$store.state.selectedScopeIdStr
      );
    },
    style: {
      get() {
        return this.$route.query.qStyle || 'path'
      },
      set(qStyle) {
        qStyle ? this.queryReplace({qStyle}) : this.queryRemoveKeys(['qStyle']);
      }
    },
    onlyRootUsers: {
      get() {
        return !!this.$route.query.root;
      },
      set(val) {
        if (val) {
          this.queryRemoveKeys(["path"]);
          this.queryReplace({root: 1, page: 1});
        } else {
          this.queryRemoveKeys(["root"]);
        }
      },
    },
    searchInput: {
      get() {
        return this.$route.query.q
      },
      set(q) {
        q ? this.queryReplace({q}) : this.queryRemoveKeys(['q'])
      }
    },
    showAll: {
      get() {
        return !this.onlyRootUsers && !this.parentPath;
      },
      set(val) {
        if (val) {
          this.queryReplace({page: 1});
          this.queryRemoveKeys(["path", "root"]);
        } else {
          let topLevelIdRecord =
              this.$store.state.user_roles
                  .getHighestLevelScopeToLoadFromIdString(
                  this.$store.state.selectedScopeIdStr
              );
          this.updateParentPath(topLevelIdRecord);
        }
      },
    },
    hasMoreResults() {
      return this.userAccounts && this.userAccounts.length >= paginate_by;
    },
    pageNumber: {
      get() {
        return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      },
      set(page) {
        this.queryReplace({page});
      },
    },
    /**
     *
     * @returns {Array}
     */
    queryPayloads() {
      let payloads = [];
      try {
        if (this.searchInput && this.searchInput.length > 0 && this.style==='search') {
          let searchTerm = this.searchInput.toLowerCase(),
              sILimit = this.$store.getters.incrementLastCharBy1(this.searchInput.toLowerCase());
          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "email",
            queries: [
              ['email', '>=', searchTerm],
              ['email', '<', sILimit],
            ]
          });
          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "lowercase_name",
            queries: [
              ['lowercase_name', '>=', searchTerm],
              ['lowercase_name', '<', sILimit],
            ]
          });

          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "phone",
            queries: [
              ['phone', '>=', searchTerm],
              ['phone', '<', sILimit],
            ]
          });
        } else if (this.style === 'root' && this.$store.state.user_roles.is_root) {
          // style = 'root';
          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "parent_path",
            queries: [
              ["parent_path", "==", "|root"]
            ]
          });
        } else if (this.style === 'path') {
          // style = 'path';
          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "parent_path",
            queries: [
              ["parent_path", ">=", this.parentPath],
              ["parent_path", "<", this.$store.getters.incrementLastCharBy1(this.parentPath)]
            ]
          })
        } else if (this.style === 'all') {
          // style = 'all';
          payloads.push({
            paginate_by,
            page: this.pageNumber,
            order_field: "parent_path",
            queries: [
              ["parent_path", ">=", "|root"]
            ]
          });
        } else {
          // style = 'all'
          payloads.push({queries: [], urlParams: {}});
        }
      } catch(e) {
        setTimeout(()=>{throw e}, 50);
      }
      return payloads;
    },

  },
  methods: {
    debounce,
    setSearchInput(val) {
      this.$data._searchInput = val;
    },
    updateParentPath() {
      this.updateParentPath(this.$store.state.user_roles.getHighestLevelScopeToLoadFromIdString(
          this.$store.state.selectedScopeIdStr
      ));
    },
    /**
     *
     * @param {'root'|'all'|'path'} style
     */
    setQueryStyle(style) {
      if (style === "root") {
        this.onlyRootUsers = true;
      } else if (style === "all") {
        this.showAll = true;
      } else if (style === "path") {
        let topLevelIdStr =
            this.$store.state.user_roles.getHighestLevelScopeToLoadFromIdString(
                this.selectedScopeIdStr
            );
        this.onlyRootUsers = null;
        this.updateParentPath(topLevelIdStr);
      } else {
        throw "Unknown query style: " + style;
      }
    },
    get() {
      const self = this,
          UserAccount = this.$dataClasses.UserAccount;
      self.userAccounts = [];
      if (
          !self.queryPayloads ||
          self.queryPayloads.filter(
              pl => (!pl.queries || pl.queries.length === 0)
          ).length > 0
      ) {
        return;
      }
      self.loading = true;
      Promise
          .all(
              self.queryPayloads.map(
                  pl => UserAccount.query(pl.queries, {
                    page: pl.page,
                    paginate_by: pl.paginate_by
                  })
              )
          )
          .then(arrOfResultArrs => arrOfResultArrs.forEach(results => {
            self.userAccounts = mergeById(self.userAccounts).with(results);
          }))
          .catch((e) => {
            self.$handleError("Could not load users", e);
          })
          .finally(() => {
            self.loading = false;
          });
    },
  },
  watch: {
    queryPayloads: {
      immediate: true,
      handler(val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.get();
        }
      },
    },
    "$store.state.selectedScopeIdStr": {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.updateParentPath();
        }
      },
    },
  },
};
</script>

<style scoped></style>
