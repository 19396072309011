<template>
  <ol class="aqbreadcrumb is-size-6-mobile is-size-6-tablet is-size-5">
    <li v-for="(item, index) in list" :key="item.name">
      <span class="active" v-if="isLast(index)">{{ showName(item) }}</span>
      <router-link :to="item" v-else>
        <span v-if="item.name === 'home'" class="has-text-link">
          <b-icon icon="home" />
        </span>
        <span v-else class="has-text-link">
          {{ showName(item) }}
        </span>
      </router-link>
    </li>
  </ol>
</template>

<script>
export default {
  computed: {
    list() {
      let self = this,
        homeCrumb = { path: "home", to: "/home", text: "Home", name: "home" },
        pathArray = self.$route.path.split("/");
      pathArray.shift();
      if (!pathArray) {
        return [];
      }
      let bcArray = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (
          self.$route.matched[idx] &&
          self.$route.matched[idx].name !== "home"
        ) {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: self.$route.matched[idx].meta.title || path,
            name: self.$route.matched[idx].name,
          });
        }
        return breadcrumbArray;
      }, []);
      return [homeCrumb].concat(bcArray);
    },
  },
  props: {
    separator: String,
  },
  mounted() {
    if (this.separator && this.$el.style) {
      this.$el.style.setProperty("--separator", `"${this.separator}"`);
    }
  },
  methods: {
    isLast(index) {
      return index === this.list.length - 1;
    },
    showName(item) {
      return item ? item.text || item.name : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.aqbreadcrumb {
  // > \003e
  // / \2044
  --separator: "\2044";
  list-style: none;
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  & .active {
    font-weight: bold;
  }
  & > li + li:before {
    padding: 0 5px;
    color: #ccc;
    content: var(--separator, "\2044");
  }
}
</style>
