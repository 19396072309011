import FirestoreDataClass from "./_baseClasses";
import router from '@/router';

export default class Gateway extends FirestoreDataClass {
  static collectionName = "gateways";

  get $qrCodeUrl() {
    if (!this.serial) {
      return null;
    }
    return this.constructor.$client.basePath + "/devices/qr_code/?serial=" + this.serial;
  }

  get $deviceType() {
    return "gateway";
  }

  $goToAddGateway(building) {
    router.push({
      name: "add-gateway",
      query: {
        building: building ? building.id : null,
        serial: this.serial,
      },
    });
  }
}
