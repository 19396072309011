/**
 * Mixin for main App.vue use (requires window context); If FCM push notification received,
 * displays snackbar with link to message.
 * Expected format:
 *    "notification": {
 *      "title": "Alert found: ...",
 *      "body": "This is an FCM Message",
 *    },
 *    "data":{
 *      "id":<user_message.id>,
 *      "customerId": <customer_id of object that user_message is related to; will force loading of scope>
 *    }
 */
const fcmMixin = {
  data() {
    return {
      hasFCMPermission: false,
    };
  },
  created() {
    if (this.$store.state.user) {
      this.registerFCM();
    }
  },
  methods: {
    registerFCM() {
      let self = this;
      if (!self.$messaging) {
        return;
      }
      Notification.requestPermission()
        .then(() => {
          return self.$messaging.getToken({
            vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
          });
        })
        .then((token) => {
          return self.$client.post("/users/store-fcm-token/", { token: token });
        })
        .then((res) => {
          self.hasFCMPermission = true;
        })
        .catch(function (err) {
          self.hasFCMPermission = false;
        });
      self.$messaging.onMessage((payload) =>
        self.showFCMMessageInBrowser(payload)
      );
    },
    showFCMMessageInBrowser(payload) {
      let self = this;
      if (!self.hasFCMPermission) {
        return;
      }
      self.$buefy.snackbar.open({
        type:
          payload.data && payload.data.topic
            ? "is-" + payload.data.topic
            : "is-primary",
        position: "is-bottom-right",
        message:
          "<b>New notification received</b>: <br/>" +
          payload.notification.title,
        duration: 7000,
        onAction: () => {
          if (payload.data && payload.data.id) {
            let params = { id: payload.data.id };
            if (payload.data.customerId) {
              self.$store.dispatch(
                "setSelectedCustomerID",
                payload.data.customerId
              );
            }
            self.$router.push({
              params,
              name: "notifications:view",
            });
          }
        },
        actionText: payload.data && payload.data.id ? "View" : "OK",
      });
    },
    unregisterFCM() {
      this.hasFCMPermission = false;
    },
    handleUserFCM(user) {
      user ? this.registerFCM() : this.unregisterFCM();
    },
  },
  watch: {
    "$store.state.user": {
      handler(val) {
        this.handleUserFCM(val);
      },
    },
  },
};

export default fcmMixin;
