<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Devices on-site: {{ site ? site.$displayString : "Unknown" }}
      </div>
    </div>
    <div :class="{ 'card-content': true, 'px-0 py-0': isMobile }">
      <div class="columns">
        <div class="column">
          <device-table
            :rows="devices"
            :loading="loading"
            @click="goToDeviceDetail($event)"
          />
          <p v-if="lastUpdated" class="has-text-justified has-text-right">
            Last updated: <i>{{ lastUpdated.toLocaleString() }}</i>
            <b-icon
              icon="sync"
              :class="{
                'fa-spin has-text-blue': loading,
                'pointer ml-1': true,
              }"
              @click.native="getDevices"
            />
          </p>
        </div>
      </div>
      <div class="mb-6">
        <b-button
          outlined
          class="mb-6"
          type="is-link is-pulled-right"
          @click="$router.push({ name: 'devices:area-list' })"
        >
          View all systems
          <span class="is-hidden-mobile">
            for site <b>{{ site ? site.$displayString : "Unknown" }}</b></span
          >
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from "vuex";
import WindowSizeMixin from "../../mixins/trackWindowSize";
import DeviceTable from "../../tables/DeviceTable";

/**
 * This component fetches devices to show in device table
 */
export default {
  name: "SiteDevices",
  components: {
    DeviceTable,
  },
  mixins: [WindowSizeMixin],
  data() {
    return {
      devices: [],
      entryUsed: false,
      loading: false,
      lastUpdated: null,
    };
  },
  computed: {
    ...mapGetters(["site"]),
    ...mapState(["areas", "buildings", "rooms", "sites"]),
    entrySerial: {
      get() {
        return this.$route.params.serial;
      },
      set(serial) {
        this.queryReplace({ serial });
      },
    },
  },
  methods: {
    goToDeviceDetail(deviceObj) {
      this.$router.push({
        name: "devices:" + deviceObj.$deviceType + "-detail",
        params: {
          siteId: this.site.id,
          serial: deviceObj.serial,
        },
      });
    },
    getDevices() {
      //todo: Move to outer component and pass as rows
      if (!this.site || !this.areas.length) {
        return;
      }

      const promises = [
        this.$dataClasses.Position.query([["site", "==", this.toFSRef(this.site)]], {
          order_field: "site",
          paginate_by: 500
        }),
        this.$dataClasses.GatewayPosition.query(
          [["parent", "in", this.areas.map((b) => this.toFSRef(b))]],
          {
            paginate_by: 500,
            order_field: "parent"
          }
        ),
      ];
      this.loading = true;
      Promise.all(promises)
        .then((resultsResponses) => {
          this.devices = [];
          resultsResponses.forEach((results) => {
            this.devices = this.devices.concat(results || []);
          });
          this.lastUpdated = new Date();
        })
        .catch((e) => {
          this.$handleError(e);
          this.lastUpdated = null;
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    areas: {
      immediate: true,
      handler: function () {
        this.getDevices();
      },
    },
    site: {
      immediate: true,
      handler: function () {
        this.getDevices();
      },
    },
    "$route.name": {
      immediate: true,
      handler(val) {
        this.entryUsed =
          val === "devices:sensor-detail" ||
          val === "devices:gateway-detail" ||
          val === "devices:probe-detail";
      },
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

:deep(.zoom-sm) {
  zoom: 85% !important;
}

.mt-neg-3 {
  margin-top: -50px;
}

@media screen and (max-width: 768px) {
  .card-content {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-inner {
    padding: 10px;
  }
}
</style>
