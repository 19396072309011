<template>
  <div>
    <img :src="gatewayImage" class="sensor-img" />
    {{ gateway.serial }}
    <slot></slot>
  </div>
</template>

<script>
import gatewayImage from "../../assets/gateway-65.png";
export default {
  name: "Gateway",
  data() {
    return {
      gatewayImage,
    };
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
