import Vue from "vue";
import Vuex from "vuex";

import { firestore } from "../firebase";
import firebaseApp from "../firebase";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});

store.$fb = firebaseApp; // Firebase application
store.$db = firestore;

export default store;
