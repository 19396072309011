<template>
  <div>
    <b-table
      @page-change="$emit('page-change', $event)"
      :data="rows"
      :loading="loading"
    >
      <b-table-column label="Name" v-slot="props"
        >{{ profileData(props.row).display_name }}
      </b-table-column>
      <b-table-column label="Email" v-slot="props"
        >{{ profileData(props.row).email }}
      </b-table-column>
      <b-table-column label="Phone" v-slot="props">
        <span v-if="props.row.phone">
          {{props.row.phone}}
        </span>
      </b-table-column>
      <b-table-column label="Belongs to" v-slot="props">
        <span v-if="props.row.parent_path">
          {{ nameFromIdString(props.row.parent_path) }}
          ({{ typeFromIdString(props.row.parent_path) }})
        </span>
      </b-table-column>
      <b-table-column v-slot="props">
        <b-button
          rounded
          @click="
            $router.push({
              name: 'profile',
              params: { userId: props.row.id },
            })
          "
          type="is-primary is-outlined"
          size="is-small"
          class="is-pulled-right"
          icon-right="edit"
          >Select
        </b-button>
      </b-table-column>
    </b-table>
    <slot name="pagination"></slot>
  </div>
</template>

<script>
export default {
  name: "UserAccountTable",
  methods: {
    nameFromIdString(idString) {
      let match = this.$store.getters.objectByIDString(idString) ||
          this.$store.dispatch("getObjFromIdStringAction", { idString });
      return match ? match.name : "...";
    },
    typeFromIdString(id_str) {
      return this.$store.getters.objectTypeByIDString(id_str);
    },
    profileData(row) {
      return row._additional ? row._additional : {};
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
