/**
 * Vuex store state.
 * Variable metadata from ./shared is used to dictate default values and action/mutation behavior.
 */

import { stateVariables } from "../shared";
import { getStateVariableDefault, shouldFetchList } from "../shared";

const stateVariableDefaults = {};
Object.keys(stateVariables).forEach((varName) => {
  stateVariableDefaults[varName] = getStateVariableDefault(varName);
});

/**
 * Dynamically created state variables. See ./settings module for meta-data, props
 * @type object
 */
const fetching = {},
  lastUpdated = {},
  pagination = {},
  lastDocument = {},
  listeners = {};

for (const key of Object.keys(stateVariables)) {
  const stateVariable = stateVariables[key];
  fetching[key] = false;
  lastUpdated[key] = new Date();
  if (shouldFetchList(key)) {
    pagination[key] = 0;
  }
  if (stateVariable.directSubscription) {
    lastDocument[key] = null;
    listeners[key] = null;
  }
}

const stateAdditions = Object.assign(
  {
    fetching,
    lastDocument,
    lastUpdated,
    listeners,
    pagination,
  },
  stateVariableDefaults
);

/**
 * The final Vuex state object.
 *
 * Most variables are set via configuration in stateVariableDefaults; these are programmatically added to the store
 * via stateAdditions.
 *
 * @type object
 */
const state = Object.assign(
  {
    user: {},
    user_account: {},
    user_roles: {},
    idStrToObj: {},
    mostRecentMessageReceivedAt: new Date(2100, 12, 31, 23, 59),
    installData: null,

    selectedScopeIdStr: null,
    userTokenLoading: false,

    // Dialog
    dialog: {
      active: false,
      message: null,
      type: null,
    },
    loading: false,
    // key: position (i.e. 'is-bottom'), val: toast obj
    lastToast: null,
  },
  stateAdditions
);

export default state;
