import Vue from "vue";

import Alarm from "../dataClasses/Alarm";
import AlarmSchedule from "../dataClasses/AlarmSchedule";
import Area from "../dataClasses/Area";
import Building from "../dataClasses/Building";
import Bundle from "../dataClasses/Bundle";
import Customer from "../dataClasses/Customer";
import DeveloperToken from "../dataClasses/DeveloperToken";
import DeviceEvent from "../dataClasses/DeviceEvent";
import Gateway from "../dataClasses/Gateway";
import GatewayPosition from "../dataClasses/GatewayPosition";
import GroundTruth from "../dataClasses/GroundTruth";
import ImageMap from "../dataClasses/ImageMap";
import PipeLocation from "../dataClasses/PipeLocation";
import Position from "../dataClasses/Position";
import Probe from "../dataClasses/Probe";
import Report from "../dataClasses/Report";
import ReportEmailSubscription from "../dataClasses/ReportEmailSubscription";
import Room from "../dataClasses/Room";
import Sensor from "../dataClasses/Sensor";
import Site from "../dataClasses/Site";
import Source from "../dataClasses/Source";
import SubCustomer from "../dataClasses/SubCustomer";
import UserAccount from "../dataClasses/UserAccount";
import UserMessage from "../dataClasses/UserMessage";
import UserSubscriptionLink from "../dataClasses/UserSubscriptionLink";

const allDataClasses = {
    Alarm,
    AlarmSchedule,
    Area,
    Building,
    Bundle,
    Customer,
    DeveloperToken,
    DeviceEvent,
    Gateway,
    GatewayPosition,
    GroundTruth,
    ImageMap,
    PipeLocation,
    Position,
    Probe,
    Report,
    ReportEmailSubscription,
    Room,
    Sensor,
    UserAccount,
    UserMessage,
    UserSubscriptionLink,
    Site,
    Source,
    SubCustomer,
};
let dataClassesByCollection = {};

Object.values(allDataClasses).forEach(dc => {
    dataClassesByCollection[dc.collectionName] = dc;
});


/**
 *
 * @param collectionName
 */
function getDataClass(collectionName) {
    return dataClassesByCollection[collectionName];
}

/**
 *
 * @param collectionName
 * @param value
 * @returns {FirestoreDataClass|any}
 */
function toDataClass(collectionName, value) {
    const dataClass = getDataClass(collectionName);
    return dataClass ? new dataClass(value) : value;
}

export default function registerDataClasses() {
    Vue.prototype.$getDataClass = getDataClass;
    Vue.prototype.$toDataClass = toDataClass;
    Vue.prototype.$dataClassesByCollection = dataClassesByCollection;
    Vue.prototype.$dataClasses = allDataClasses;

}