<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">Source Detail</div>
        <div class="card-header-icon">
          <b-icon :icon="iconFor('source_detail')" />
        </div>
      </div>
      <div class="card-content">
        <transition name="slide">
          <div class="card" v-if="selectedSite">
            <div class="card-content">
              <b-field label="Source summary by building" />
              <building-table
                :rows="buildings"
                detailed
                detail-key="areaId"
                :opened-detailed="areaIdsOpened"
                @details-open="buildingRowOpened($event)"
                @details-close="buildingRowClosed($event)"
                :has-select-column="false"
              >
                <template #detail="props">
                  <div v-if="props.props.row.area">
                    <area-source-detail-inner
                      :area="
                        getObjectInArrayById(
                          $store.state.areas,
                          props.props.row.area.id,
                          null
                        )
                      "
                    />
                  </div>
                  <p v-else>
                    No area found for building <b>{{ props.props.row.name }}</b
                    >.
                  </p>
                </template>
              </building-table>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import { mapState } from "vuex";
import iconFor from "../../../icons";
import AreaSourceDetailInner from "../../../applications/Utilities/AreaSourceDetail/AreaSourceDetailInner";
import AreaTable from "../../../tables/AreaTable";
import BuildingTable from "../../../tables/BuildingTable";

export default {
  name: "AreaSourceDetail",
  components: {
    AreaSourceDetailInner,
    AreaTable,
    BuildingTable,
  },
  mounted() {
    let query = cloneDeep(this.$route.query);
    this.selectViewBy(
      "building",
      query
    );
  },
  computed: {
    ...mapState(["sites"]),
    areaIdsOpened() {
      return this.$route.query.detailAreas
        ? this.$route.query.detailAreas.split(",")
        : [];
    },
    viewAreaOrBuilding() {
      return "building";
    },
    selectedSite() {
      return this.$store.getters.site;
    },
    selectedArea() {
      return this.$route.query.areaId
        ? getObjectInArrayById(
            this.$store.state.areas,
            this.$route.query.areaId,
            null
          )
        : null;
    },
    selectedBuilding() {
      let matches = this.selectedArea
        ? this.$store.state.buildings.filter(
            (b) => b.area && b.area.id === this.selectedArea.id
          )
        : [];
      return matches.length ? matches[0] : null;
    },
    buildings() {
      let b = [];
      if (this.selectedArea) {
        b = cloneDeep(
          this.$store.state.buildings.filter(
            (b) => b.area && b.area.id === this.selectedArea.id
          )
        );
      } else if (this.selectedSite) {
        b = cloneDeep(
          this.$store.state.buildings.filter(
            (b) => b.site && b.site.id === this.selectedSite.id
          )
        );
      }
      b.forEach((_b) => (_b.areaId = _b.area ? _b.area.id : null));
      return b;
    },
    areas() {
      if (this.selectedArea) {
        return [this.selectedArea];
      }
      return this.selectedSite
        ? this.$store.state.areas.filter(
            (a) => a.site && a.site.id === this.selectedSite.id
          )
        : [];
    },
    sources() {
      return this.selectedArea
        ? this.$store.state.sources.filter(
            (s) => s.area && s.area.id === this.selectedArea.id
          )
        : [];
    },
  },
  methods: {
    getObjectInArrayById,
    iconFor,
    /*
            For `viewBy` url param
             */
    selectViewBy(viewBy, query) {
      if (!query) {
        query = this.$route.query ? cloneDeep(this.$route.query) : {};
      }
      query.viewBy = viewBy;
      this.$router.replace({ query }).catch(() => {});
    },
    areaRowOpened(row) {
      let query = this.$route.query ? cloneDeep(this.$route.query) : {};
      if (
        this.$route.query.detailAreas &&
        this.$route.query.detailAreas.length > 1
      ) {
        query.detailAreas = query.detailAreas + "," + row.id;
      } else {
        query.detailAreas = row.id;
      }
      this.$router.replace({ query }).catch(() => {});
    },
    areaRowClosed(row) {
      let query = this.$route.query ? cloneDeep(this.$route.query) : {};
      if (
        this.$route.query.detailAreas &&
        this.$route.query.detailAreas.length
      ) {
        query.detailAreas = query.detailAreas
          .replace(row.id + ",", "")
          .replace(row.id, "");
      }
      this.$router.replace({ query }).catch(() => {});
    },
    buildingRowOpened(row) {
      if (row && row.area && row.area.id) {
        this.areaRowOpened(row.area);
      }
    },
    buildingRowClosed(row) {
      if (row && row.area && row.area.id) {
        this.areaRowClosed(row.area);
      }
    },
  },
  watch: {
    "$route.query.viewBy": {
      immediate: true,
      handler(val) {
        if (!val) {
          this.selectViewBy(
            this.$store.state.user_roles.is_root
              ? this.$route.query.viewBy || "area"
              : "building"
          );
        }
      },
    },
  },
};
</script>

<style scoped></style>
