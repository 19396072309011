<template>
  <div>
    <div v-if="object">
      <generic-view
        :editable="$store.state.user_roles.is_installer"
        object-name="point-of-use"
        parent-name="area"
        parent-title="Area"
        :ancestors="ancestors"
        :parent="parent"
        :object="object"
        :state-var-name="endpointSegment"
        @edit-clicked="editClicked()"
      >
        <level :values="levelArray">
          <div class="level">
            <div class="level-left">
              <div class="level-item has-text-weight-bold">Flow rate (mean)</div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <unit-conversion-display
                  :value="get(object, 'flow_rate.typical', '')"
                  measurement="volumeFlowRate"
                  :decimals="3"
                />
              </div>
            </div>
          </div>
          <div class="level">
            <div class="level-left">
              <div class="level-item has-text-weight-bold">Tags/Groups</div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-taglist v-if="object.tags && object.tags.length">
                  <b-tag v-for="tag in object.tags" :key="tag">{{ tag }}</b-tag>
                </b-taglist>
                <p v-else>None</p>
              </div>
            </div>
          </div>
        </level>
        <div class="mt-5 mb-5">
          <aq-image-upload
            :parent-object-path="object.$FSRef.path"
            v-if="true"
            category="install_picture"
          />
        </div>

        <div v-if="user_roles.is_root" class="mt-5 mb-5">
          Assigned to area
          <router-link
            v-if="area"
            :to="{ name: 'administration:view-internal-area', params: { id: area.id } }"
            >{{ area.name }}
          </router-link>
          . Click
          <router-link
            :to="{
              name: 'utilities:source-detail',
              query: {
                detailAreas: object.$area.id,
                siteId: object.$site.id,
                viewby: 'area'
              }
            }"
          >
            here
          </router-link>
          to view point-of-use detail for this area.
        </div>
        <div v-if="$store.state.user_roles.is_root">
          <div>
            <a
              @click="sensorDetectionTableOpen = true"
              v-if="!sensorDetectionTableOpen"
            >
              <b-icon icon="chevron-down" size="is-small" />
              Show sensor detection details (root only)
            </a>
            <a v-else @click="sensorDetectionTableOpen = false" class="mb-1">
              <b-icon icon="chevron-up" size="is-small" />
              Hide sensor detection details
            </a>
          </div>
          <transition name="slide">
            <source-sensor-detection-table
              :src="object"
              v-show="sensorDetectionTableOpen"
            />
          </transition>
        </div>
      </generic-view>
      <add-source-modal
        v-model="modalOpen"
        :building="object.$building"
        :parent="object.$parent"
        :room="object.$room"
        :id="id"
        :inputs="object"
      ></add-source-modal>
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import AddSourceModal from "../../../components/Admin/Sources/AddSourceModal";
import Level from "../../../components/Level/Level";
import {get} from "lodash";
import {
  getAncestors,
  getObjectInArrayById,
} from "../../../scripts/filterHelpers";
import GenericView from "./genericView";
import { mapState } from "vuex";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";
import SourceSensorDetectionTable from "../../../tables/SourceSensorDetectionTable";
import AqImageUpload from "../../../components/Files/Editors/AqImageUpload";

function cap(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  name: "ViewSource",
  components: {
    AqImageUpload,
    AddSourceModal,
    GenericView,
    Level,
    SourceSensorDetectionTable,
  },
  mixins: [onScopeChangeMixin],
  methods: {
    get,
    test(image) {
      console.log("test", image);
    },
    editClicked() {
      this.modalOpen = true;
    },
  },
  computed: {
    ...mapState(["user_roles", "areas", "buildings", "rooms"]),
    site() {
      return this.object.$site;
    },
    area() {
      return this.object && this.parent
        ? getObjectInArrayById(this.areas, this.parent, {})
        : null;
    },
    ancestors() {
      const ancestorData =
        this.object && this.object.id
          ? [
              {
                title: "Customer",
                object: this.object.$customer,
                endpointName: "administration:view-customer",
              },
              {
                title: "Sub-Customer",
                object: this.object.$subcustomer,
                endpointName: "administration:view-subcustomer",
              },
            ]
          : [];
      if (this.object && this.object.$site) {
        try {
          ancestorData.push({
            title: "Site",
            object: this.object.$site,
            endpointName: "administration:view-site",
          });
        } catch (e) {
          console.error(e);
        }
      }
      if (this.object && this.object.$building) {
        try {
          ancestorData.push({
            title: "Area",
            object: this.object.$building,
            endpointName: "administration:view-area",
          });
        } catch (e) {
          console.error(e);
        }
      }
      if (this.object && this.object.$room) {
        try {
          ancestorData.push({
            title: "Room",
            object: this.object.$room,
            endpointName: "administration:view-room",
          });
        } catch (e) {
          console.error(e);
        }
      }
      return getAncestors(ancestorData);
    },
    levelArray() {
      let arrData =
        this.object && this.object.id
          ? [
              { label: "Name", value: this.object.name },
              {
                label: "Temperature classification",
                value: this.object.temperature
                  ? cap(this.object.temperature)
                  : "Not set",
              },
              {
                label: "Fixture type",
                value: this.object.fixture
                  ? cap(this.object.fixture)
                  : "Not set",
              },
              {
                label: "Training level",
                value: this.object.$trainingLevel,
                class: 'has-text-weight-bold '+this.object.$trainingLevelBuefyClass,
              }
            ]
          : [];
      if (
        this.object &&
        this.object.fixture === "appliance" &&
        this.object.appliance
      ) {
        arrData.push(
          {
            label: " - Appliance type",
            value: this.object.appliance.type
              ? cap(this.object.appliance.type)
              : "Not set",
          },
          {
            label: " - Manufacturer/make",
            value: this.object.appliance.make
              ? cap(this.object.appliance.make)
              : "Not set",
          },
          {
            label: " - Model",
            value: this.object.appliance.model
              ? cap(this.object.appliance.model)
              : "Not set",
          }
        );
      }
      return arrData;
    },
    parent() {
      return this.object.$parent
    },
    /**
     * The absolute object being viewd
     * @returns {Source}
     */
    object() {
      return getObjectInArrayById(this.$store.state.sources, this.id, {});
    },
  },
  data() {
    let obj = getObjectInArrayById(this.$store.state.sources, this.id, {});
    return {
      sensorDetectionTableOpen: false,
      endpointSegment: "sources",
      building: obj && obj.building ? obj.building : null,
      room: obj && obj.room ? obj.room : null,
      modalOpen: Object.keys(this.$route.query).includes("edit"),
      loading: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    modalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { edit: null } : {} });
      },
    },
    childModalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { addChild: null } : {} });
      },
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
