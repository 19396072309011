var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'message mb-2': true,
    selected: _vm.selected,
    dismissing: _vm.dismissing,
  }},[_c('b-message',{ref:"messageBox",staticClass:"pointer-point",class:{ clicked: _vm.clicked },attrs:{"animation":"fadeInUp","disabled":_vm.loading,"type":_vm.alarm.$BClass,"size":_vm.size,"closable":false,"title":_vm.alarm.$title},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-p is-fullwidth pointer",on:{"click":_vm.onClick}},[_c('div',{staticClass:"header-left header-part"},[(_vm.alarm.alert_type === 'alarm')?_c('b-icon',{staticClass:"mr-1",attrs:{"type":_vm.alarm.topic === 'noflow' ? 'is-white' : 'is-danger',"icon":"exclamation-triangle"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.alarm.$title)+" ")],1),_c('div',{staticClass:"header-right header-part",on:{"click":function($event){$event.stopPropagation();_vm.loading ? null : _vm.onClose()}}},[(_vm.loading)?_c('b-icon',{staticClass:"fa-spin ml-1",attrs:{"icon":"sync","size":"is-small"}}):(_vm.dismissable)?_c('b-icon',{class:{
              faded: _vm.loading || _vm.dismissClicked,
              'has-text-white ml-1 top pointer': true,
            },attrs:{"icon":_vm.message && _vm.message.dismissed ? 'inbox' : 'trash',"size":"is-small","type":"is-white","title":"Dismiss this message"},on:{"click":function($event){_vm.message ? _vm.toggleDismissed(_vm.stateVar) : null}}}):_vm._e()],1)])]},proxy:true}])},[_c('div',{staticClass:"pointer-point",on:{"click":_vm.onClick}},[_c('alarm-breadcrumb-controller',{attrs:{"alarm-object":_vm.alarm,"is-detail":false}})],1),_c('message-time-detail',{attrs:{"alarm-object":_vm.alarm}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }