<template>
  <modal-with-form
    :allow-delete="$store.state.user_roles && $store.state.user_roles.is_admin"
    :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
    :can-submit="isValid"
    :class="stateVarName + '-modal'"
    :full-page="false"
    :id="id"
    :inputs="inputs"
    :object-data="objectData"
    :value="isActive"
    :state-var-name="stateVarName"
    obj-display-name="Customer"
    @closed="isActive = false"
    @input="activeChanged($event)"
    @saved="saved($event)"
    @deleted="deleted($event)"
  >
    <customer-form
      v-if="!hasError"
      v-model="inputs"
      :id="id"
      @validates="validChanged($event)"
    />
    <div v-else>
      <i>There was an error fetching this object</i>
    </div>
  </modal-with-form>
</template>

<script>
import CustomerForm from "./CustomerForm";
import ModalWithForm from "../generic/ModalWithForm";
import { cloneDeep } from "lodash";
import Customer from "../../../dataClasses/Customer";

export default {
  name: "CustomerModal",
  components: { CustomerForm, ModalWithForm },
  created() {
    this.get();
  },
  data() {
    return {
      hasError: false,
      inputs: {},
      objectData: {},
      isValid: false,
      isActive: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    stateVarName: {
      type: String,
      default: "customers",
    },
  },
  methods: {
    get() {
      let self = this;
      self.loading = true;
      if (self.id) {
        if (
          self.$store.state.customers &&
          self.$store.state.customers.filter((c) => c.id === self.objectId)
            .length
        ) {
          self.inputs = Object.assign(
            {},
            self.$store.state.customers.filter((c) => c.id === self.objectId)[0]
          );
        } else {
          this.$dataClasses.Customer.fetchById(self.id)
            .then((result) => {
              self.objectData = result;
            })
            .catch((e) => {
              self.hasError = true;
            })
            .finally(() => {
              self.loading = false;
            });
        }
      }
    },
    validChanged(valid) {
      this.isValid = valid;
    },
    activeChanged(val) {
      this.$emit("input", this.isActive);
    },
    saved(val) {
      this.$emit("saved", val);
      if (!this.id) {
        this.inputs = {};
      }
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
  watch: {
    isActive: {
      handler(val) {
        this.activeChanged(val);
      },
    },
    objectData: {
      handler(val) {
        this.inputs = cloneDeep(val);
      },
    },
    value: {
      handler(val) {
        this.isActive = val;
      },
    },
    id: {
      immediate: true,
      handler() {
        this.get();
      },
    },
  },
};
</script>

<style scoped></style>
