import firebase from "firebase/app";
import { firestore } from "../firebase";

export default function toRef(_obj, collectionName) {
  if (!_obj) {
    // console.log("_obj", _obj, collectionName);
    // console.trace("_obj was undefined");
  }
  if (_obj instanceof firebase.firestore.DocumentReference) {
    return _obj;
  } else if (typeof _obj === "string" && _obj.includes("/")) {
    let collection = _obj.split("/")[0],
      id = _obj.split("/")[1];
    return firestore.collection(collection).doc(id);
  } else if (typeof _obj === "string" && collectionName) {
    return firestore.collection(collectionName).doc(_obj);
  } else if (typeof _obj === "object" && "_reference" in _obj) {
    return _obj._reference;
  } else if (typeof _obj === "object" && "id" in _obj) {
    if (!collectionName) {
      // console.trace({_obj})
      throw "collectionName required";
    }
    return firestore.collection(collectionName).doc(_obj.id);
  }
  // console.log("Could not generate ref", _obj);
  return null;
}
