<template>
  <battery v-bind="$attrs" :battery-level="position.$batteryLevel" />
</template>

<script>
import battery from "./../battery";
import Position from "../../dataClasses/Position";

export default {
  name: "SensorBattery",
  components: { battery },
  props: {
    position: {
      type: Object,
      required: true,
      validator: (val) => val instanceof Position,
    },
  },
};
</script>

<style scoped></style>
