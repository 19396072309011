<template>
  <div>
    <b-field
      :message="validationMessage"
      :type="validationMessage ? 'is-danger' : ''"
    />
    <b-field label="Area">
      <div class="fff ml-3 mb-0">
        <b-radio
          :disabled="disabled"
          :native-value="'<>'"
          v-model="selectedBuildingId"
          v-if="buildingEditable"
        >
          <b>None</b>
        </b-radio>
      </div>
      <div
        v-for="_building in buildings.sort(alphanumericSortByProperty('$displayString'))"
        class="fff ml-3 mb-0"
        :key="_building.id"
      >
        <b-radio
          v-if="buildingEditable || !selectedBuildingId || _building.id === selectedBuildingId"
          :disabled="disabled"
          :native-value="_building.id"
          v-model="selectedBuildingId"
        >
          <building-display :value="_building" />
        </b-radio>
      </div>
    </b-field>
    <b-field
      label="Room"
      v-if="selectedBuildingId && selectedBuildingId !== '<>'"
    >
      <div class="fff ml-3 mb-0">
        <b-radio
          :disabled="disabled"
          :native-value="'<>'"
          v-model="selectedRoomId"
        >
          <b>None</b>
        </b-radio>
      </div>
      <div class="fff ml-3 mb-0" v-for="_room in rooms" :key="_room.id">
        <b-radio
          :disabled="disabled"
          :native-value="_room.id"
          v-model="selectedRoomId"
        >
          <room-display :value="_room" />
        </b-radio>
      </div>
    </b-field>
    <b-field label="Source(s)">
      <div class="fff ml-3 mb-0">
        <b-radio
          v-model="selectedSourceId"
          :disabled="disabled"
          :native-value="'<>'"
        >
          <b>None</b>
        </b-radio>
      </div>
      <div class="fff ml-3 mb-0" v-for="_src in sources" :key="_src.id">
        <b-radio
          :disabled="disabled"
          v-model="selectedSourceId"
          :native-value="_src.id"
        >
          <source-display :value="_src" show-training-level />
        </b-radio>
      </div>
    </b-field>
  </div>
</template>

<script>
import { getObjectInArrayById } from "../../scripts/filterHelpers";

export default {
  name: "SelectBuildingRoomSource",
  props: {
    validationMessage: {
      type: String,
      default: null,
    },
    site: {
      type: Object,
      required: true,
    },
    buildingId: {
      type: String,
      default: "<>",
    },
    buildingEditable: {
      type: Boolean,
      default: true,
    },
    roomId: {
      type: String,
      default: "<>",
    },
    sourceId: {
      type: String,
      default: "<>",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedBuildingId: this.buildingId || "<>",
      selectedRoomId: this.roomId || "<>",
      selectedSourceId: this.sourceId || "<>",
    };
  },
  computed: {
    building() {
      return this.selectedBuildingId && this.selectedBuildingId !== "<>"
        ? getObjectInArrayById(
            this.$store.state.buildings,
            this.selectedBuildingId,
            null
          )
        : null;
    },
    room() {
      return this.selectedRoomId && this.selectedRoomId !== "<>"
        ? getObjectInArrayById(
            this.$store.state.rooms,
            this.selectedRoomId,
            null
          )
        : null;
    },
    src() {
      return this.selectedSourceId && this.selectedSourceId !== "<>"
        ? getObjectInArrayById(
            this.$store.state.sources,
            this.selectedSourceId,
            null
          )
        : null;
    },
    siteRef() {
      return this.toFSRef(this.site, "sites");
    },
    buildings() {
      return this.$store.state.buildings.filter(
        (b) => b.site && b.site.id === this.siteRef.id
      ).sort(
          this.alphanumericSortByProperty('$displayString')
      );
    },
    rooms() {
      if (this.selectedBuildingId && this.selectedBuildingId !== "<>") {
        return this.$store.state.rooms.filter(
            (r) => r.building && r.building.id === this.selectedBuildingId
          ).sort(
            this.alphanumericSortByProperty('$displayString')
          );
      }
      return this.$store.state.rooms.filter(
        (r) => r.site && r.site.id === this.siteRef.id
      ).sort(
          this.alphanumericSortByProperty('$displayString')
      );
    },
    sources() {
      let results = this.$store.state.sources.filter(
        (s) => s.site && s.site.id === this.siteRef.id
      );
      if (this.selectedBuildingId && this.selectedBuildingId !== "<>") {
        results = results.filter(
          (r) => r.building && r.building.id === this.selectedBuildingId
        );
      }
      if (this.selectedRoomId && this.selectedRoomId !== "<>") {
        results = results.filter(
          (r) => r.room && r.room.id === this.selectedRoomId
        );
      }
      return results.sort(
          this.alphanumericSortByProperty('$displayString')
      );
    },
  },
  watch: {
    buildingId: {
      handler(val) {
        this.selectedBuildingId = val;
      },
    },
    roomId: {
      handler(val) {
        this.selectedRoomId = val;
      },
    },
    sourceId: {
      handler(val) {
        this.selectedSourceId = val;
      },
    },
    selectedBuildingId: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.selectedRoomId = "<>";
          this.$nextTick(() => {
            this.$emit("update:building-id", val === "<>" ? null : val);
          });
        }
      },
    },
    selectedRoomId: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.selectedSourceId = "<>";
          this.$nextTick(() => {
            this.$emit("update:room-id", val === "<>" ? null : val);
          });
        }
      },
    },
    selectedSourceId: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.$nextTick(() => {
            this.$emit("update:source-id", val === "<>" ? null : val);
          });
        }
      },
    },
  },
};
</script>

<style scoped>
:deep(.field.has-addons) {
  display: block !important;
}
.fff,
.fff input {
  clear: left;
  display: block !important;
}
</style>
