<template>
  <div>
    <div v-if="object">
      <generic-view
        :editable="$store.state.user_roles.is_installer"
        object-name="building"
        parent-name="site"
        parent-title="Site"
        :ancestors="ancestors"
        :parent="parent"
        :object="object"
        :children="children"
        child-name="room"
        child-title="Rooms"
        :state-var-name="endpointSegment"
        @edit-clicked="editClicked()"
      >
        <div>
          <level :values="levelArray" />
          <div class="level">
            <div class="level-left">
              <div class="level-item">
                <b-button
                  v-if="$store.state.user_roles.is_installer"
                  @click="
                    $router.push({
                      name: 'devices:area-detail',
                      params: { areaId: object.$area.id },
                    })
                    "
                >
                  Go to devices in area
                  <in-training-mode
                    :area="object.$area"
                    v-if="object.$area && object.$area.$isInTrainingMode"
                  />
                </b-button>
                <b-button
                  v-if="$store.state.user_roles.is_root"
                  class="ml-1"
                  @click="
                    $router.push({
                      name: 'administration:view-internal-area',
                      params: { id: object.$area.id },
                    })
                    "
                >
                  Go to internal area
                  <in-training-mode
                    :area="object.$area"
                    v-if="object.$area && object.$area.$isInTrainingMode"
                  />
                </b-button>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <file-attachment-modal
                  full-screen
                  :object="object"
                  :categories="['floor_plan']"
                />
              </div>
            </div>
          </div>
        </div>

        <template v-slot:pre-children>
          <b-message
            icon-left="info-circle"
            has-icon
            type="is-info"
            title="About points-of-use"
          >
            <p>
              You can assign points-of-use (POUs) to a specific room in an
              area, or just to an area. POUs shown below are
              area-assigned; view any room to view room-assigned sources.
            </p>

            <p>
              To learn more, click
              <a
                href="https://wiki.aquaseca.com/en/user-manual/defining-sources"
                target="_blank"
                >here</a
              >.
            </p>
          </b-message>

          <children name="source" :children="sources">
            <template v-slot:childrendisplay>
              <div class="card children-card">
                <div class="card-header">
                  <div class="card-header-icon">
                    <b-icon :icon="iconFor('source')" />
                  </div>
                  <div class="card-header-title">Points-of-use (POUs)</div>
                </div>
                <div class="card-content">
                  <p
                    class="has-text-centered has-text-grey-light"
                    v-if="!sources.length"
                  >
                    None found
                  </p>
                  <div v-else>
                    <p><i>Does not include sources assigned to rooms</i></p>
                    <source-table :rows="sources" show-flow-rate>
                      <template #empty><span /></template>
                    </source-table>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="card-footer-item" v-if="object.id">
                    <slot
                      name="addnewchild"
                      v-if="$store.state.user_roles.is_installer"
                    >
                      <b-button
                        @click="addNewSource()"
                        type="is-create is-outlined"
                      >
                        Add new point-of-use
                      </b-button>
                    </slot>
                  </div>
                </div>
              </div>
            </template>
          </children>
        </template>
        <template v-slot:childrendisplay>
          <room-table :rows="children" />
        </template>
        <template
          v-slot:addnewchild
          v-if="$store.state.user_roles.is_installer"
        >
          <b-button @click="childModalOpen = true" type="is-create is-outlined"
            >Add new room
          </b-button>
        </template>
      </generic-view>

      <building-modal
        v-model="modalOpen"
        :parent="object.parent"
        :id="object.id"
        :inputs="object"
      ></building-modal>
      <add-room-modal
        v-if="object && object.id"
        v-model="childModalOpen"
        :parent="object"
      />
      <add-source-modal
        v-if="object && object.id"
        v-model="childSourceModalOpen"
        :building="object"
        :room="null"
        :id="null"
        :parent="area"
      />
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import {
  getAncestors,
  getChildrenOfIdByKey,
  getObjectInArrayById,
} from "../../../scripts/filterHelpers";
import iconFor from "../../../icons";
import BuildingModal from "../../../components/Admin/Buildings/BuildingModal";
import Children from "../../../applications/Administration/View/children";
import Level from "../../../components/Level/Level";
import AddRoomModal from "../../../components/Admin/Rooms/AddRoomModal";
import RoomTable from "../../../tables/RoomTable";
import SourceTable from "../../../tables/SourceTable";
import AddSourceModal from "../../../components/Admin/Sources/AddSourceModal";
import GenericView from "./genericView";
import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";
import FileAttachmentModal from "../../../components/Files/FileAttachmentModal";

export default {
  name: "ViewBuilding",
  mixins: [onScopeChangeMixin],
  components: {
    AddSourceModal,
    BuildingModal,
    Children,
    FileAttachmentModal,
    GenericView,
    Level,
    AddRoomModal,
    RoomTable,
    SourceTable,
  },
  methods: {
    iconFor,
    editClicked() {
      this.modalOpen = true;
    },
    addNewSource() {
      if (this.object && !this.object.area) {
        this.$handleError(
          "This customer area has no linked internal area; Please contact support"
        );
      } else {
        this.childSourceModalOpen = true;
      }
    },
  },
  computed: {
    childSourceModalOpen: {
      get() {return this.$route.query.hasOwnProperty('pouModal')},
      set(pouModal) {pouModal ? this.queryReplace({pouModal:true}) : this.queryRemoveKeys(['pouModal'])}
    },
    tab() {
      return this.$route.query.tab || "children";
    },
    levelArray() {
      return this.object && this.object.id
        ? [
            {
              label: "Name",
              value: this.object.name,
            },
            {
              label: "Floors (above ground)",
              value: this.object.floors || "None",
            },
            {
              label: "Floors (below ground)",
              value: this.object.sub_floors || "None",
            },
            {
              label: "Area type",
              value: this.object.type,
            },
            {
              label: "Rooms",
              value: this.rooms ? this.rooms.length || "None" : "None",
            },
          ]
        : [];
    },
    ancestors() {
      return this.object && this.object.id
        ? getAncestors([
            {
              title: "Customer",
              object: getObjectInArrayById(
                this.$store.state.customers,
                this.object.customer,
                null
              ),
              endpointName: "administration:view-customer",
            },
            {
              title: "Sub-Customer",
              object: getObjectInArrayById(
                this.$store.state.subcustomers,
                this.object.subcustomer,
                null
              ),
              endpointName: "administration:view-subcustomer",
            },
            {
              title: "Site",
              object: getObjectInArrayById(
                this.$store.state.sites,
                this.object.site,
                null
              ),
              endpointName: "administration:view-site",
            },
          ])
        : [];
    },
    rooms() {
      return this.children;
    },
    /**
     * Parent of this building (a site)
     * @return {Object}
     */
    parent() {
      return this.object && this.object.id && this.object.parent
        ? getObjectInArrayById(this.$store.state.sites, this.object.site)
        : null;
    },
    object() {
      return getObjectInArrayById(this.$store.state.buildings, this.id, null);
    },
    /**
     * Children of building (rooms)
     * @return {Array<Object>}
     */
    children() {
      return this.object && this.object.id
        ? getChildrenOfIdByKey(this.$store.state.rooms, this.id, [], "building")
        : null;
    },
    /**
     * Show sources assigned to an area but not a room
     * @return {Array<Object>}
     */
    sources() {
      return this.object && this.object.id
        ? getChildrenOfIdByKey(
            this.$store.state.sources,
            this.id,
            [],
            "building"
          ).filter((src) => !src.room)
        : null;
    },
    areas() {
      return this.object && this.object.id
        ? getChildrenOfIdByKey(
            this.$store.state.areas,
            this.object.site.id,
            [],
            "parent"
          )
        : [];
    },
    area() {
      return this.object && this.object.id ? this.object.area : null;
    },
    modalOpen: {
      get() {
        return Object.keys(this.$route.query).includes("edit");
      },
      set(val) {
        this.$nextTick(() => {
          val
            ? this.queryReplace({ edit: true })
            : this.queryRemoveKeys(["edit"]);
        });
      },
    },
  },
  data() {
    return {
      endpointSegment: "buildings",
      childModalOpen: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
