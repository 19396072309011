<template>
  <div class="count-occurence-table columns is-multiline">
    <div class="column is-one-third">
      <b-field label="Select field to summarize">
        <b-select v-model="selectedFieldName">
          <option v-for="field in fields" :key="field" :value="field">
            <slot name="option-display" :field="field">
              {{ fieldDisplayCallable(field) }}
            </slot>
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column is-two-thirds">
      <transition name="slide">
        <b-table
          v-if="selectedFieldName"
          :data="counts"
          :default-sort="['value']"
          @mouseenter="rowHover"
          @mouseleave="rowHoverLeave"
          :row-class="getRowClass"
        >
          <template #empty>
            <p class="has-text-centered">No data found</p>
          </template>
          <b-table-column
            field="value"
            label="Field value"
            v-slot="props"
            sortable
            :custom-sort="compareFieldFunc('value')"
          >
            <span v-if="props.row.value">{{ props.row.value }}</span>
            <i v-else>None</i>
          </b-table-column>
          <b-table-column
            field="count"
            label="Count"
            numeric
            v-slot="props"
            sortable
          >
            {{ props.row.count }}
          </b-table-column>
          <slot name="table-inner" />
        </b-table>
      </transition>
      <transition name="slide">
        <i v-if="!selectedFieldName"> Please select a field to summarize </i>
      </transition>
    </div>
  </div>
</template>

<script>
import { countPropValuesForObjectsInArray } from "../scripts/filterHelpers";
import { compareFieldFunc } from "../dataClasses/functions";
import capitalizeFirstLetter from "../mixins/global/capitalizeFirstLetter";

export default {
  name: "CountOccurenceTable",
  props: {
    rows: {
      type: Array,
      required: true,
    },
    /**
     * @type {Array.<string>}
     */
    fields: {
      type: Array,
      required: true,
    },
    fieldDisplayCallable: {
      type: Function,
      default: (field) =>
        capitalizeFirstLetter(field.replace("$", "").replace(".", " ")),
    },
  },
  /**
   *
   * @returns {{hoveredRow: {{property: string, count: Number, value: any}|null}}}
   */
  data() {
    return {
      hoveredRow: null,
    };
  },
  computed: {
    counts() {
      return this.selectedFieldName
        ? countPropValuesForObjectsInArray(this.rows, this.selectedFieldName)
        : [];
    },
    selectedFieldName: {
      get() {
        return this.$route.query.$field;
      },
      set($field) {
        this.queryReplace({ $field });
      },
    },
  },
  methods: {
    compareFieldFunc,
    getRowClass(row, index) {
      return {
        "pointer no-decoration": true,
        "is-primary":
          this.hoveredRow &&
          (row.value === this.hoveredRow.value ||
            (!this.hoveredRow.value && !row.value)),
      };
    },
    rowHover(data) {
      this.hoveredRow = data;
    },
    rowHoverLeave() {
      this.hoveredRow = null;
    },
  },
  watch: {
    hoveredRow: {
      immediate: true,
      handler(val) {
        this.$emit("hover", val);
      },
    },
    selectedFieldName: {
      handler(val) {
        this.hoveredRow = null;
      },
    },
  },
};
</script>

<style scoped>
.no-decoration {
  text-decoration: none;
}
</style>
