export const dtw = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday",
  },
  allTimes = [
    {
      iden: "0",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Monday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "1",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Tuesday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "2",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Wednesday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "3",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Thursday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "4",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Friday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "5",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Saturday",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
    {
      iden: "6",
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      week: "Sunday ",
      mergeTimes: [
        [
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "24:00",
        ],
      ],
    },
  ];

/**
 * Given a range of start to end time, returns array of {hour,minute} objects bounding it.
 * @example - getTimesArrayFromRange('01:00','2:00') === [{hour:1,minute:0},{hour:1,minute:30},{hour:2,minute:0}]
 *
 * @param start{string} - Format 'HH:MM'
 * @param end{string} - Format 'HH:MM'
 * @return {[<{hour:Number, minute:Number}>]} - i.e.{hour:1,minute:30}
 */
export function getTimesArrayFromRange(start, end) {
  let output = [],
    startHour = parseInt(start.split(":")[0]),
    startMin = parseInt(start.split(":")[1]),
    endHour = parseInt(end.split(":")[0]),
    endMin = parseInt(end.split(":")[1]),
    cursorHour = startHour,
    cursorMin = startMin;
  while (
    cursorHour < endHour ||
    (cursorHour === endHour && cursorMin <= endMin)
  ) {
    if (cursorMin === 0 && cursorHour <= endHour) {
      output.push({ hour: cursorHour, minute: cursorMin });
    }
    cursorMin = 30;
    if (
      cursorHour < endHour ||
      (cursorHour === endHour && cursorMin <= endMin)
    ) {
      output.push({ hour: cursorHour, minute: cursorMin });
    }
    cursorMin = 0;
    cursorHour += 1;
  }
  return output;
}

function minHourObjToString(objOrFloat, anythingElse) {
  let obj =
    typeof objOrFloat === "number"
      ? {
          hour: parseInt(objOrFloat),
          minute: (objOrFloat % 1) * 60,
        }
      : objOrFloat;
  let formattedHour = obj.hour.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }),
    formattedMin = obj.minute.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  return `${formattedHour}:${formattedMin}`;
}

const reducer = (accumulator, val, index, arr) => {
  if (val.length === 1) {
    accumulator.push({
      day: parseInt(val),
      start: "00:00",
      end: "24:00",
    });
    return accumulator;
  }
  let day = parseInt(val.split(":")[0]);
  if (!val.includes(":")) {
    throw "Hmm:" + val;
  }
  let hour = val.includes(":")
      ? parseInt(val.split(":")[1].split(".")[0])
      : null,
    halfHour = val.includes(".5") ? 0.5 : 0.0,
    currentString = minHourObjToString(hour + halfHour),
    halfHourForwardFloat = hour + halfHour + 0.5,
    halfHourForwardString = minHourObjToString(halfHourForwardFloat),
    lastArrIncludesHalfHourBack = accumulator.length
      ? accumulator[accumulator.length - 1].day === day &&
        accumulator[accumulator.length - 1].end === currentString
      : false;

  if (lastArrIncludesHalfHourBack && currentString <= "24:00") {
    accumulator[accumulator.length - 1].end = halfHourForwardString;
  } else {
    accumulator.push({
      day: day,
      start: currentString,
      end: halfHourForwardString,
    });
  }
  return accumulator;
};

/**
 * @param timeArr {Array.<string>}: In format ['0:12.5',...]
 *
 * @return {Array.<{day: Number, start: String, end: String}>} In format
 */
export function extractRangesFromTimeStringArray(timeArr) {
  return timeArr.reduce(reducer, []);
}

/** Convert Firestore saved AlarmSchedule.schedule format to that required by
 * vue-week-daterange-picker
 *
 * @param timesStrings {Array.<string>} - format == ['{day}:HH{.sub_hour?}',...]
 * @return widgetObjects{Array.<Object>}
 *   @return widgetObjects[].iden{String} - day of week, 0-indexed, as sting
 *   @return widgetObjects[].times{Array.<string>}
 *   @return widgetObjects[].week{string} - Readable day of week (i.e. 'Monday')
 *   @return widgetObjects[].mergeTimes{Array.<Array.<string>>}
 *
 */
export function firestoreToWidgetScheduleFormat(timesStrings) {
  if (!timesStrings || !timesStrings.length) {
    return [];
  }
  let output = [],
    times = extractRangesFromTimeStringArray(timesStrings);
  times.forEach((time) => {
    let timesArr = getTimesArrayFromRange(time.start, time.end).map((i) =>
        minHourObjToString(i)
      ),
      mergeTimes = [timesArr],
      timesRaw = timesArr.slice(0, timesArr.length - 1);
    if (output.filter((o) => o.iden === time.day.toString()).length === 0) {
      output.push({
        iden: time.day.toString(),
        times: timesRaw,
        week: dtw[time.day],
        mergeTimes: mergeTimes,
      });
    } else {
      for (var i in output) {
        if (output[i].iden === time.day.toString()) {
          output[i].times = output[i].times.concat(timesRaw);
          output[i].mergeTimes = output[i].mergeTimes.concat(mergeTimes);
          break; //Stop this loop, we found it!
        }
      }
    }
  });
  return output;
}

/**
 * Translates vue-week-daterange-picker required format to Firestore save format
 * @param times
 * @return format == [{day:Number,start:'HH:MM',end:'HH:MM'}]
 *
 * WORKS
 */
export function widgetToFirestoreScheduleFormat(times) {
  let output = [];
  times.forEach((time) => {
    let isAllDay = time.times.length === 48;
    if (isAllDay) {
      output.push(time.iden);
    } else {
      time.mergeTimes.forEach((rec) => {
        let day = parseInt(time.iden),
          start = rec[0],
          end = rec[rec.length - 2],
          objs = getTimesArrayFromRange(start, end);
        objs.forEach((obj) => {
          output.push(`${day}:${obj.hour}${obj.minute === 30 ? ".5" : ""}`);
        });
      });
    }
  });
  return output;
}
