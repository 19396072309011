<template>
  <b-message type="is-primary" class="mt-6 mb-6">
    <b-field :label="label">
      <slot name="before" />
      <transition name="fade">
        <b-input
          v-if="showInput"
          @keyup.native.enter="onValidAction({ serial: serialInput, $router })"
          v-model.trim="serialInput"
        />
      </transition>
      <transition name="fade">
        <div class="control" v-if="showInput">
          <b-button
            @click="$emit('input', serialInput)"
            icon-right="chevron-right"
            :disabled="!serialInputValid"
          >
            Submit
          </b-button>
        </div>
      </transition>
      <slot name="default" />
    </b-field>
  </b-message>
</template>

<script>
import { deviceCodesToDeviceTypes } from "../../scripts/deviceQrCodes";

export default {
  name: "SerialNumberInput",
  data() {
    return {
      innerSerialInput: "",
    };
  },
  computed: {
    serialInput: {
      get() {
        return this.innerSerialInput;
      },
      set(val) {
        this.innerSerialInput = val.toUpperCase();
      },
    },
    serialInputValid() {
      let validDeviceLetters = Object.keys(deviceCodesToDeviceTypes);
      return (
        this.serialInput &&
        this.serialInput.length > 7 &&
        this.serialInput.includes(".") &&
        validDeviceLetters.some((letter) =>
          this.serialInput.split(".")[1].startsWith(letter)
        )
      );
    },
  },
  props: {
    label: {
      type: String,
      default: "Enter a serial number",
    },
    onValidAction: {
      type: Function,
      default: ({ serial, $router }) => {
        $router.push({
          name: "go-to-device",
          params: { serial },
        });
      },
    },
    showInput: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
