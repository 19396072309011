var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"outer-mongolia"},[(_vm.isInMaintenanceMode)?_c('b-modal',{attrs:{"can-cancel":false,"trap-focus":"","active":""}},[_c('div',{staticClass:"has-text-centered"},[_c('div',{staticClass:"has-text-centered"},[_c('img',{staticStyle:{"max-width":"350px"},attrs:{"src":_vm.logo,"alt":"AquaSeca logo"}})]),_c('div',{staticClass:"has-text-white mt-3"},[_c('div',{staticClass:"subtitle is-white has-text-white"},[_vm._v(" Our systems are currently in maintenance mode ")]),_c('div',[_vm._v(" AquaSeca applications are unavailable while we make upgrades. Please check back later. ")])])])]):_c('div',[_c('div',{staticClass:"sidebar-page"},[_c('section',{staticClass:"sidebar-layout"},[_c('b-sidebar',{attrs:{"open":_vm.showSidebar && _vm.sidebarActive,"type":"is-light","can-cancel":!_vm.isDesktop && _vm.mobileSidebarToggle,"overlay":!_vm.isDesktop && _vm.mobileSidebarToggle,"fullheight":""},on:{"close":function($event){_vm.mobileSidebarToggle = false},"open":function($event){_vm.mobileSidebarToggle = true}}},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"block mt-2"},[_c('img',{attrs:{"src":_vm.logo,"alt":"AquaSeca - Water Intelligence"}})]),_c('b-menu',{staticClass:"is-custom-mobile",attrs:{"accordion":false}},[_c('b-menu-list',_vm._l((_vm.routesToShow),function(route){return _c('b-menu-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRoute(route, _vm.$route)),expression:"showRoute(route, $route)"}],key:route.path,attrs:{"expanded":!!route.children,"icon":route.meta.icon,"label":route.meta.customIconFilename ? null : route.meta.title,"active":!route.children &&
                    (_vm.$route.name === route.name ||
                      _vm.$route.name === 'redirect:' + route.name)},on:{"click":function($event){return _vm.routeClicked(route.children ? null : route)}},scopedSlots:_vm._u([(route.meta.customIconFilename)?{key:"label",fn:function(){return [_c('img',{staticClass:"is-custom-icon",attrs:{"src":_vm.getImgUrl(route.meta.customIconFilename),"alt":route.meta.customIconFilename}}),_c('span',{staticClass:"label-after-custom-icon"},[_vm._v(_vm._s(route.meta.title))])]},proxy:true}:null,(route.children)?{key:"default",fn:function(){return [(
                        _vm.showRoute(route, _vm.$route) &&
                        route.meta.inSidebar !== 'children-only'
                      )?_c('TransitionGroup',{attrs:{"name":"slide","tag":"span"}},[_c('b-menu-item',{key:route.path,attrs:{"expanded":"","icon":_vm.iconFor('home'),"label":route.meta.title + ' home',"to":route,"tag":"router-link","active":_vm.$route.name === route.name}})],1):_vm._e(),_c('TransitionGroup',{attrs:{"name":"slide","tag":"span"}},_vm._l((_vm.routesToShowInSidebar(
                          route.children
                        )),function(childRoute){return _c('b-menu-item',{key:childRoute.path,attrs:{"expanded":"","to":childRoute,"icon":childRoute.meta.icon || null,"label":childRoute.name !== 'notifications'
                            ? childRoute.meta.title
                            : null,"active":_vm.$route.name === childRoute.name ||
                          _vm.$route.name.startsWith(childRoute.name + ':') ||
                          _vm.$route.name ===
                            childRoute.name.replace('redirect:', '') ||
                          _vm.$route.name.startsWith(
                            childRoute.name.replace('redirect:', '') + ':'
                          ),"tag":"router-link"},scopedSlots:_vm._u([(childRoute.meta.customIconFilename)?{key:"default",fn:function(){return [_c('img',{staticClass:"is-custom-icon",attrs:{"src":_vm.getImgUrl(childRoute.meta.customIconFilename)}}),_c('span',{staticClass:"label-after-custom-icon"},[_vm._v(" "+_vm._s(childRoute.meta.title)+" ")])]},proxy:true}:{key:"label",fn:function(){return [_vm._v(" "+_vm._s(childRoute.meta.title)+" ")]},proxy:true}],null,true)})}),1),(route.meta.horizontalRuleAfter)?_c('hr',{staticClass:"menu-hr"}):_vm._e()]},proxy:true}:null],null,true)})}),1)],1)],1),_c('div',{staticClass:"is-sidebar-bottom"},[(_vm.$store.state.user && _vm.$store.state.user_roles)?_c('div',[_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'profile', params: { userId: _vm.user.uid } },"icon-left":_vm.iconFor('profile'),"type":_vm.$route.name === 'profile' &&
                  _vm.$route.params.userId === _vm.user.uid
                    ? 'is-primary'
                    : 'is-light'}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('b-button',{attrs:{"tag":"router-link","to":{ name: 'logout' },"icon-left":_vm.iconFor('logout'),"type":_vm.$route.name === 'logout' ? 'is-primary' : 'is-light'}},[_vm._v(" Log-out ")])],1):_vm._e()])])],1),_c('select-selected-scope')],1),_c('div',[(_vm.$store.state.userTokenLoading || _vm.loading)?_c('b-loading',{attrs:{"active":"","is-full-page":false}}):_vm._e(),_c('div',{class:{
          'container-fluid content-container': true,
          'sidebar-active': _vm.sidebarActive,
        }},[_c('navbar',{attrs:{"user":_vm.user,"show-hamburger":!_vm.isDesktop,"show-breadcrumbs":_vm.user && _vm.isDesktop},on:{"hamburgered":function($event){_vm.mobileSidebarToggle = !_vm.mobileSidebarToggle},"scope-select-clicked":function($event){_vm.showScopeSelect = true}}}),(_vm.sidebarActive)?_c('div',{staticClass:"spacer"},[_vm._v("ㅤ")]):_vm._e(),(
            !(_vm.$store.state.user && _vm.$store.state.user_roles) ||
            _vm.$store.state.selectedScopeIdStr ||
            _vm.$route.name === 'logout'
          )?_c('router-view',{on:{"go-to-next":function($event){_vm.$route.query.next ? _vm.$router.push(_vm.$route.query.next) : null}}}):_vm._e(),(_vm.$store.state.user && _vm.$store.state.user_roles && _vm.$route.name !== 'logout')?_c('select-selected-scope',{attrs:{"active":!_vm.$store.state.selectedScopeIdStr || _vm.showScopeSelect},on:{"close":function($event){_vm.showScopeSelect = false}}}):_vm._e()],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }