import FirestoreDataClass from "./_baseClasses";
import { sum } from "lodash";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import SubCustomer from "./SubCustomer";
import store from "../store";

export default class Site extends FirestoreDataClass {
  static collectionName = "sites";

  get $children() {
    if (!this.id) {
      return [];
    }
    return store.state.areas.filter((a) => a.site && a.site.id === this.id);
  }

  get $areas() {
    if (!this.id) {
      return [];
    }
    return store.state.areas.filter((a) => a.site && a.site.id === this.id);
  }

  get $buildings() {
    if (!this.id) {
      return [];
    }
    return store.state.buildings.filter((a) => a.site && a.site.id === this.id);
  }

  get $subcustomer() {
    let data = this.subcustomer
      ? getObjectInArrayById(
          store.state.subcustomers,
          this.subcustomer.id,
          null
        )
      : null;
    return data ? new SubCustomer(data) : null;
  }

  get $parent() {
    return this.$subcustomer;
  }

  get $gatewayCount() {
    return sum(this.$areas.map((area) => area.$gatewayCount));
  }

  get $sensorCount() {
    return sum(this.$areas.map((area) => area.$sensorCount));
  }

  /**
   *
   * @param {'day'|'month'|'week'} group_by
   * @param {String|null} datetime_utc
   * @return {Promise<{results:Array,current_period:{start:String,end:String},previous_period:{start:String,end:String}}|Error>}
   */
  getCurrentAndPreviousAggregatedFlowActivity({
    group_by = "day",
    datetime_utc = null,
  }) {
    const self = this;
    return new Promise(function (resolve, reject) {
      try {
        self.constructor.$client
          .get(
            `${self.constructor.APIEndpointSegment}/flow-activity/`,
            {},
            {
              datetime_utc,
              group_by,
              site_id: self.id,
            }
          )
          .then((response) => resolve(response))
          .catch((e) => reject(e));
      } catch (e) {
        reject(e);
      }
    });
  }
}
