<template>
  <div
      :class="{
      'message mb-2': true,
      selected,
      dismissing,
    }"
  >
    <b-message
        animation="fadeInUp"
        :disabled="loading"
        :type="alarm.$BClass"
        :size="size"
        :closable="false"
        :class="{ clicked }"
        :title="alarm.$title"
        ref="messageBox"
        class="pointer-point"
        @click.native="onClick"
    >
      <template #header>
        <div class="header-p is-fullwidth pointer" @click="onClick">
          <div class="header-left header-part">
            <b-icon
                class="mr-1"
                v-if="alarm.alert_type === 'alarm'"
                :type="alarm.topic === 'noflow' ? 'is-white' : 'is-danger'"
                icon="exclamation-triangle"
            />
            {{ alarm.$title }}
          </div>
          <div
              class="header-right header-part"
              @click.stop="loading ? null : onClose()"
          >
            <b-icon
                icon="sync"
                class="fa-spin ml-1"
                size="is-small"
                v-if="loading"
            />
            <b-icon
                v-else-if="dismissable"
                :class="{
                faded: loading || dismissClicked,
                'has-text-white ml-1 top pointer': true,
              }"
                @click="message ? toggleDismissed(stateVar) : null"
                :icon="message && message.dismissed ? 'inbox' : 'trash'"
                size="is-small"
                type="is-white"
                title="Dismiss this message"
            />
          </div>
        </div>
      </template>
      <div @click="onClick" class="pointer-point">
        <alarm-breadcrumb-controller :alarm-object="alarm" :is-detail="false"/>
      </div>
      <message-time-detail :alarm-object="alarm"/>
    </b-message>
  </div>
</template>

<script>

import isUserMessageMixin from "../../mixins/isUserMessageMixin";
import {get} from "lodash";
import iconFor from "../../icons";
import AlarmBreadcrumbController from "./AlarmBreadcrumbController";
import MessageTimeDetail from "./DetailBlocks/MessageTimeDetail";
// 1152 px is max width of Bulma container; only support up to 1/3rd of screen
// const maxWidth = 1152/3;

function isValue(val) {
  return typeof val !== "undefined" && val !== null && val !== "";
}

export default {
  name: "UserMessageBox",
  mixins: [isUserMessageMixin],
  components: {
    MessageTimeDetail,
    AlarmBreadcrumbController,
  },
  data: () => {
    return {
      dismissing: false,
      clicked: false,
    };
  },
  props: {
    dismissable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    stateVar: {
      type: String,
      default: null,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    showFloors: {
      type: Boolean,
      default: true,
    },
    clickAction: {
      type: Function,
      default: function (self) {
        if (self.message && self.message.id) {
          self.$router.push({
            name: "notifications:view",
            params: {id: this.message.id},
          });
        }
      },
    },
  },
  methods: {
    get,
    iconFor,
    onClick() {
      let self = this;
      if (self.message || self.alarm) {
        self.$refs.messageBox.$el.classList.add("clicked");
        setTimeout(() => {
          if (self.$refs.messageBox && self.$refs.messageBox.$el) {
            self.$refs.messageBox.$el.classList.remove("clicked");
          }
        }, 500);
        self.clickAction(self);
      }
      this.$emit("click", {message: this.message, alarm: this.alarm});
    },
    onClose() {
      let self = this;
      self.toggleDismissed(self.stateVar);
      self.$emit("close", self.message);
    },
    isValue(val) {
      return isValue(val);
    },
    handleButtonAction(action) {
      if (!this.message || !Object.keys(this.message).length) {
        return;
      }
      let self = this;
      if (isValue(action.visit_url)) {
        window.open(action.visit_url, "_blank");
      }
      if (isValue(action.action_url)) {
        this.$emit("update:loading", {
          loading: true,
          messageId: this.message ? this.message.id : null,
          alarmId: this.alarm ? this.alarm.id : null,
        });
        this.fetchWithAuth(action.action_url)
            .then((res) => {
              this.$emit("update:loading", {
                loading: true,
                messageId: this.message ? this.message.id : null,
                alarmId: this.alarm ? this.alarm.id : null,
              });
              if (!self.message.dismissed) {
                self.toggleDismissed(self.stateVar);
              }
            })
            .catch((e) => {
              self.$emit("update:loading", {
                loading: true,
                messageId: this.message ? this.message.id : null,
                alarmId: this.alarm ? this.alarm.id : null,
              });
              self.$handleError(e, e);
            });
      }
    },
  },
  watch: {
    loading: {
      handler(val) {
        this.$emit("update:loading", {
          loading: true,
          messageId: this.message ? this.message.id : null,
          alarmId: this.alarm ? this.alarm.id : null,
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.selected {
  outline: 2px dashed black;
  outline-offset: -2px;
}

.UserMessage {
  max-width: 384px;
  padding: 8px;
}

.has-margin {
  margin: 10px 0;
}

.has-buttons {
  margin-top: 15px;
}

.righter {
  cursor: pointer;
  float: right;
  transition: 0.2s filter ease-out;
  /*background-color: black;*/
  border-radius: 15px;

  &:hover {
    filter: brightness(0.9);
  }
}

:deep(.message-header p) {
  margin-bottom: 0;
}

.header-p {
  width: 100%;
  margin-bottom: 0;
}

:deep(.message-header div) {
  width: 100% !important;
  flex-grow: 1;
}

.address {
  margin-left: 25px;
}

.faded {
  opacity: 0.2;
}

.clicked {
  opacity: 0.7;
  filter: saturate(1.2);
  transition: 0.1s all ease;
}

.pointer-point {
  cursor: pointer;
  transition: 0.2s all;
  width: calc(100% + 40px);
}

.header-left {
  width: calc(100% - 25px);
}

.header-right {
  width: 25px;
  top: 0;
  right: 0;
}

.header-part {
  display: table-cell;

  &:hover {
    text-decoration: none !important;
  }
}

.header-p:hover {
  text-decoration: none !important;
}
</style>
