<template>
  <div>
    <user-message-detail-inner
      v-if="message && Object.keys(message).length"
      :force-source-id="forceSourceId"
      :loading="loading"
      :message="useCollection === 'user_messages' ? message : null"
      :alarm-object="useCollection === 'alarms' ? message : null"
      :on-close-function="onCloseFunction"
      @update:loading="handleLoading($event)"
      @update:dismissed="$emit('update:dismissed', $event)"
      @classified="$emit('classified', $event)"
    />
  </div>
</template>

<script>
import UserMessageDetailInner from "./UserMessageDetailInner";
import { mapGetters, mapState } from "vuex";
import router from "../../router";


function getDefaultData() {
  return {
    messageRaw: null,
  };
}

export default {
  name: "UserMessageDetailWrapper",
  components: { UserMessageDetailInner },
  data() {
    return getDefaultData();
  },
  props: {
    useCollection: {
      type: String,
      default: 'user_messages',
      validator: (val) => ['user_messages', 'alarms'].includes(val)
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    onCloseFunction: {
      type: Function,
      default: ({message, alarm}) => {
        router.push({ name: 'notifications' });
      }
    },
    forceSourceId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(["messages", "dismissedMessages"]),
    ...mapState(["sources", "user_roles"]),
    ObjectClass() {
      return {
        'user_messages': this.$dataClasses.UserMessage,
        'alarms': this.$dataClasses.Alarm,
      }[this.useCollection]
    },
    message() {
      return this.messageRaw ? new this.ObjectClass(this.messageRaw) : null;
    },
    idStr() {
      return (this.alarm && this.alarm.customer && this.alarm.subcustomer && this.alarm.site)
        ? `|root|${this.toId(this.alarm.customer)}|${this.toId(this.alarm.subcustomer)}|${this.toId(this.alarm.site.id)}`
        : null;
    },
  },
  methods: {
    handleLoading($event) {
      this.$emit("update:loading", $event);
    },
    bindMessage() {
      if (!this.id) {
        this.messageRaw = null;
        this.$unbind('messageRaw')
        return
      }
      this.handleLoading({ loading: true });
      this.$bind(
        "messageRaw",
        this.$db.collection(this.ObjectClass.collectionName).doc(this.id)
      )
        .then(this.loadScope)
        .finally(() => this.handleLoading({ loading: false }));
    },
    loadScope() {
      let loadedIdStr = this.$store.state.selectedScopeIdStr;
      if (
        this.alarm &&
        this.alarm.site &&
        loadedIdStr &&
        !loadedIdStr.includes(this.alarm.site.id)
      ) {
        this.$store.dispatch("setScopeFromIdStr", { id_str: this.idStr });
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.bindMessage();
      },
    },
  },
};
</script>

<style scoped></style>
