import FirestoreDataClass from "./_baseClasses";
import store from "../store";

export default class Customer extends FirestoreDataClass {
  static collectionName = "customers";

  get $children() {
    if (!this.id) {
      return [];
    }
    return store.state.subcustomers.filter(
      (sc) => sc.customer && sc.customer.id === this.id
    );
  }
}
