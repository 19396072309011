import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import store from "../store";
import capitalizeFirstLetter from "../mixins/global/capitalizeFirstLetter";

const sourceNameReplacements = {
  "SRC:flow": "General flow",
  "SRC:noflow": "General no-flow",
  "SRC:anomaly": "Unrecognized source",
};

export default class Source extends FirestoreDataClass {
  static collectionName = "sources";
  static defaultDisplayString = "POU";
  static $commonFixtureValues = [
    "faucet",
    "shower",
    "toilet",
    "appliance",
    "hose/outside",
    "other",
  ];
  static $commonApplianceTypes = [
    "dishwasher",
    "pump",
    "washing machine",
    "water dispenser",
    "water fountain",
    "water heater",
    "other",
  ];

  static get $allFixtureValues() {
    return [
      ...this.$commonFixtureValues,
      ...new Set(
        store.state.sources
          .filter(
            (src) =>
              src &&
              src.fixture &&
              !this.$commonFixtureValues.includes(src.fixture)
          )
          .map((src) => src.fixture)
          .sort()
      ),
    ];
  }

  static get $allApplianceValues() {
    return [
      ...this.$commonApplianceTypes,
      ...new Set(
        store.state.sources
          .filter(
            (src) =>
              src &&
              src.appliance &&
              src.appliance.type &&
              !this.$commonApplianceTypes.includes(src.appliance.type)
          )
          .map((src) => src.appliance.type)
          .sort()
      ),
    ];
  }

  get $customer() {
    return this.customer
      ? getObjectInArrayById(store.state.customers, this.customer.id, null)
      : null;
  }

  get $subcustomer() {
    return this.subcustomer
      ? getObjectInArrayById(store.state.subcustomers, this.subcustomer.id, null)
      : null;
  }

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  /**
   *
   * @returns {{position_model_count:Number,position_model_timestamp:String,sensor_count:Number,source:firebase.firestore.DocumentReference, source_nm:String, std_log_spectrum_max:Number}|null}
   */
  get $classifiedSourceRecordInArea() {
    const classifiedSourceAreaArray = this.$area
        ? this.$area.$classifiedSourceArray
        : [],
      matches = classifiedSourceAreaArray.filter(
        (entry) => entry.source.path === this.$FSRef.path
      ),
      match = matches.length ? matches[0] : null;
    return match || null;
  }

  /**
   * Displayed-to-user "level of training", used to hint in display components etc if more training needed
   * @returns {('Un-trained'|'Trained (no model)'|'Active'|'Not available')} Un-trained if no sensor_count, Trained (sensor_count > 0, no position_model_count), active (otherwise)
   *
   */
  get $trainingLevel() {
    const areaSourceClassifiedAnal = this.$classifiedSourceRecordInArea,
      sensorCount = areaSourceClassifiedAnal
        ? areaSourceClassifiedAnal.sensor_count
        : 0,
      positionModelCount = areaSourceClassifiedAnal
        ? areaSourceClassifiedAnal.position_model_count
        : 0;
    if (!areaSourceClassifiedAnal) {
      return "Not available";
    } else if (!sensorCount) {
      return "Un-trained";
    } else if (!positionModelCount) {
      return "Trained (no model)";
    } else {
      return "Active";
    }
  }

  get $trainingLevelOrdinalImportance() {
    const values = {
      Active: 0,
      "Trained (no model)": 1,
      "Un-trained": 2,
      "Not available": 3,
    };
    return values[this.$trainingLevel];
  }

  /**
   * Class to use when displaying $trainingLevel
   * @returns {string}
   */
  get $trainingLevelBuefyClass() {
    const trainingLevel = this.$trainingLevel,
      prefix = "has-text-";
    if (trainingLevel === "Not available") {
      return prefix + "default";
    } else if (trainingLevel === "Un-trained") {
      return prefix + "danger";
    } else if (trainingLevel === "Trained (no model)") {
      return prefix + "warning";
    } else if (trainingLevel === "Active") {
      return prefix + "success";
    }
  }

  /**
   *
   * @returns {Area|null}
   */
  get $area() {
    return this.area
      ? getObjectInArrayById(store.state.areas, this.area.id, null)
      : null;
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building.id, null)
      : null;
  }

  get $room() {
    return this.room
      ? getObjectInArrayById(store.state.rooms, this.room.id, null)
      : null;
  }

  get $parent() {
    return this.$area;
  }

  get $ancestors() {
    return [this.$room, this.$building].filter((a) => !!a);
  }

  $getLocationSegments(includeTemp=false, includeBuilding=true) {
    const segments = []
    if (includeBuilding && this.$building) {
      segments.push(
        this.$building.$displayString
      );
    }
    segments.push([
      this.$room ? `${this.$room.name || ""}${
            this.$room.unit ? ", Unit:" + this.$room.unit : ""
          }${this.$room.floor ? ", Floor:" + this.$room.floor : ""}`
        : "",
    ]);
    if (includeTemp && this.temperature) {
      segments.push(capitalizeFirstLetter(`${this.temperature} temp.`));
    }
    segments.push(this.name);
    return segments.filter((el) => !!el)
  }

  get $locationString() {
    return this
        .$getLocationSegments()
        .join(" › ")
        .replaceAll(">", "›")
        .replaceAll("<", "‹");
  }

  get $displayString() {
    return sourceNameReplacements[this.name] || this.name;
  }
}
