<template>
  <div>
    <div class="mb-0 mt-3 has-text-weight-bold" v-if="title">
      {{ title }}
    </div>
    <b-table
      :loading="loading"
      :checkable="checkable"
      :checked-rows.sync="checkedRows"
      v-if="rows.length"
      :data="rows"
      striped
      :default-sort="['name']"
      sort-icon="chevron-down"
    >
      <slot name="first-column">
        <b-table-column
          field="$displayString"
          label="Name"
          :custom-sort="alphanumericSort"
          :sortable="sortable"
          v-slot="props"
          sticky
        >
          {{ props.row.$displayString }}
          <b-tooltip
            multilined
            :triggers="['hover', 'focus', 'contextmenu']"
            position="is-right"
            class="high-z"
          >
            <b-icon
              icon="home"
              size="is-small"
              type="is-primary"
              v-if="
                $store.state.user_account.default_site_id_str &&
                $store.state.user_account.default_site_id_str ===
                  props.row.id_str
              "
            />
            <template #content>
              This is currently the default site for your account. Click
              <a @click="setDefaultSite(null)">here</a> to remove this setting.
            </template>
          </b-tooltip>
        </b-table-column>
      </slot>

      <slot name="columns">
        <slot name="address-column">
          <b-table-column
            :visible="isMobile || isFullHD"
            field="address"
            label="Address"
            :sortable="sortable"
            v-slot="props"
            >{{ props.row.address }}
          </b-table-column>
        </slot>
        <slot name="inner-column"></slot>
        <slot name="count-columns">
          <b-table-column
            v-slot="props"
            :sortable="sortable"
            numeric
            label="Areas"
          >
            {{ buildingsFor(props.row).length }}
          </b-table-column>
          <slot name="post-building"></slot>
          <b-table-column
            v-slot="props"
            :sortable="sortable"
            numeric
            label="Areas (internal)"
            v-if="$store.state.user_roles.is_root"
          >
            {{ areasFor(props.row).length }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :sortable="sortable"
            numeric
            label="POUs"
          >
            {{ sourcesFor(props.row).length }}
          </b-table-column>
        </slot>
        <slot name="post-inner-columns"></slot>
      </slot>
      <slot name="select-column">
        <b-table-column v-slot="props">
          <b-button
            @click="handleClick(props.row)"
            rounded
            type="is-primary is-outlined "
            size="is-small"
            class="is-pulled-right"
            icon-right="chevron-right"
            >Select
          </b-button>
        </b-table-column>
      </slot>
    </b-table>
    <b-field
      v-if="forScopeSelection"
      horizontal
      label="Set as default"
      message="(Load automatically on login)"
    >
      <b-checkbox v-model="setAsDefault" />
    </b-field>
  </div>
</template>

<script>
import {
  getChildrenOfId,
  getChildrenOfIdByKey,
} from "../scripts/filterHelpers";
import windowSizeMixin from "../mixins/trackWindowSize";

export default {
  name: "SiteTable",
  mixins: [windowSizeMixin],
  data() {
    return {
      checkedRows: [],
      setAsDefault: false,
    };
  },
  props: {
    checkable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    forScopeSelection: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setDefaultSite(site) {
      this.$dataClasses.UserAccount.save(this.$store.state.user_account.id, {
        default_site_id_str: site ? site.id_str : null,
      })
        .then((result) => {
          this.$store.commit("setUserAccount", result);
          this.$handleSuccess(
            site
              ? `Your default site has been set to '${site.name}'`
              : "Your default site has been un-set"
          );
        })
        .catch((e) =>
          this.$handleError("Could not update default site, see console", e)
        );
    },
    handleClick(site) {
      if (this.forScopeSelection) {
        if (this.setAsDefault && site) {
          this.setDefaultSite(site);
        }
        this.$emit("site-selected", site);
        this.$emit("select", site);
      } else {
        this.$router.push({
          name: "administration:view-site",
          params: { id: site.id },
        });
      }
    },
    buildingsFor(site) {
      return getChildrenOfIdByKey(
        this.$store.state.buildings,
        site.id,
        [],
        "site"
      );
    },
    areasFor(site) {
      return getChildrenOfId(this.$store.state.areas, site.id);
    },
    sourcesFor(site) {
      let sources = [];
      for (const area of this.areasFor(site)) {
        getChildrenOfId(this.$store.state.sources, area.id).forEach((child) =>
          sources.push(child)
        );
      }
      return sources;
    },
  },
  watch: {
    checkedRows: {
      deep: true,
      handler(val) {
        this.$emit("update:checked-rows", val);
      },
    },
    windowWidth: {
      handler() {
        this.$forceUpdate();
      },
    },
  },
};
</script>

<style scoped>
.high-z {
  z-index: 2000000 !important;
}
</style>
