import alphanumericSort, {alphanumericSortByProperty} from "../../appLevelFunctions/alphanumericSort";
import capitalizeFirstLetter from "./capitalizeFirstLetter";
import {toDate} from "../../appLevelFunctions/toDate";
import toFSRef from "../../appLevelFunctions/toRef";
import toId from "../../appLevelFunctions/toId";

const globalMixin = {
  methods: {
    alphanumericSort,
    alphanumericSortByProperty,
    capitalizeFirstLetter,
    fetchWithAuth(url, options = {}) {
      let headers = {};
      headers[this.$client.authHeaderKey] = this.$client.authHeaderValue;
      return fetch(url, {
        headers,
        redirect: "follow",
        ...options,
      });
    },
    log(...args) {
      console.log(...args);
    },
    queryPush(query) {
      const q = Object.assign({}, this.$route.query);
      return this.$router.push({ query: Object.assign(q, query) }, () => {});
    },
    /**
     *
     * @param {Object} query
     */
    queryReplace(query) {
      const q = Object.assign({}, this.$route.query);
      return this.$router.replace({ query: Object.assign(q, query) }, () => {});
    },
    /**
     *
     * @param {Array.<String>} queryNameArray
     * @param {Function} onComplete
     */
    queryRemoveKeys(queryNameArray, onComplete=null) {
      let query = Object.assign({}, this.$route.query);
      queryNameArray.forEach((key) => {
        if (Object.hasOwn(query, key)) {
          delete query[key];
        }
      });
      return this.$router.replace({ query }, onComplete);
    },
    /**
     *
     * @param {Array.<String>} queryNameArray
     */
    removeQueryKeysPush(queryNameArray) {
      let query = Object.assign({}, this.$route.query);
      queryNameArray.forEach((key) => {
        if (Object.hasOwn(query, key)) {
          delete query[key];
        }
      });
      return this.$router.push({ query }, () => {});
    },
    toDate,
    toFSRef,
    toId,
  },
};

export default globalMixin;
