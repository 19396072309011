<template>
  <div>
    <div v-if="inputs">
      <div v-if="parent.name" class="mb-3">
        Room will be added at building <i>{{ parent.name }}</i
        >.
      </div>
      <map-with-marker
        v-if="parent && parent.lat_long"
        :marker="{
          lat: parent.lat_long.latitude,
          lng: parent.lat_long.longitude,
        }"
      />
      <b-field
        label="Room type"
        :message="this.errorMessages.type"
        :type="this.errorMessages.type ? 'is-danger' : null"
      >
        <select-or-add-freeform-other-option
          :options="roomTypes"
          :standard-options="standardRoomTypes"
          :value="inputs.type"
          @input="$set(inputs, 'type', $event)"
        />
      </b-field>

      <div v-show="inputs.type">
        <b-field
          label="Room name"
          :message="this.errorMessages.name"
          :type="this.errorMessages.name ? 'is-danger' : null"
        >
          <b-input
            type="text"
            minlength="3"
            :value="inputs.name"
            @input="$set(inputs, 'name', $event)"
            placeholder="i.e. Kitchen, Garage, East Wing..."
            required
          />
        </b-field>
        <b-field label="Description">
          <b-input
            type="text"
            :value="inputs.description"
            @input="$set(inputs, 'description', $event)"
          />
        </b-field>

        <div class="columns">
          <div class="column">
            <b-field label="Unit/Suite">
              <b-input
                :value="inputs.unit"
                @input="$set(inputs, 'unit', $event)"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Floor number"
              :message="
                parent.$floorString
                  ? 'Floors in building: ' + parent.$floorString
                  : null
              "
            >
              <b-input
                :value="inputs.floor"
                @input="$set(inputs, 'floor', $event)"
              />
            </b-field>
          </div>
        </div>

        <b-field label="Tags/Groups">
          <tag-select
            v-model="inputs.tags"
            :maxtags="8"
            :related-tagged-objects="$store.state.rooms"
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import TagSelect from "../../Selects/TagSelect";
import MapWithMarker from "../../../components/MapWithMarker";
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";
import SelectOrAddFreeformOtherOption from "../../Selects/Radios/SelectOrAddFreeformOtherOption";

export default {
  name: "RoomForm",
  components: {
    MapWithMarker,
    SelectOrAddFreeformOtherOption,
    TagSelect,
  },
  data() {
    return {
      inputs:
        this.value && this.value.saved
          ? cloneDeep(this.value)
          : {
              ...this.value,
              ...{
                building: this.parent ? this.parent.$FSRef : null,
                parent: this.parent ? this.parent.$FSRef : null
              },
            },
    };
  },
  mounted() {
    this.onChange();
  },
  computed: {
    ...mapState(["sites", "areas"]),
    hasErrors() {
      return Object.values(this.errorMessages).filter((e) => !!e).length > 0;
    },
    errorMessages() {
      return {
        name:
          this.inputs && this.inputs.name && this.inputs.name.length >= 3
            ? null
            : "Please enter a name (Must be 3 or more characters)",
        type:
          this.inputs && this.inputs.type && this.inputs.type.length > 0
            ? null
            : "Please select or enter a room type",
      };
    },
    standardRoomTypes() {
      return this.$dataClasses.Room.$commonRoomTypes;
    },
    roomTypes() {
      return this.$dataClasses.Room.$allRoomTypeValues;
    },
    site() {
      return this.parent
        ? getObjectInArrayById(this.sites, this.parent.site.id, {})
        : null;
    },
    isValid() {
      return !this.hasErrors;
    },
  },
  props: {
    parent: {
      type: Object,
      required: true,
    },
    value: {
      required: false,
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    getClearedData() {
      return Object.assign({
        building: this.inputs.building,
        name: this.inputs.name,
      });
    },
    /**
     * Emitted when form data changes.
     */
    onChange() {
      this.$emit("input", this.inputs);
    },
  },
  watch: {
    isValid: {
      immediate: true,
      handler() {
        this.$emit("validates", this.isValid);
      },
    },
    inputs: {
      handler() {
        this.onChange();
      },
    },
    "inputs.name": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.floor": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.unit": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.description": {
      handler(val) {
        this.onChange();
      },
    },
    "inputs.tags": {
      handler(val) {
        this.onChange();
      },
    },
    parent: {
      immediate: true,
      handler(val) {
        let building = getObjectInArrayById(this.$store.state.buildings, val);
        this.$set(this.inputs, "building", building ? this.toFSRef(building) : null);
        this.$set(this.inputs, "parent", building ? this.toFSRef(building) : null);
      },
    },
    "inputs.type": {
      handler(val, oldVal) {
        if (this.inputs.name === oldVal && oldVal !== val) {
          this.$set(this.inputs, "name", "");
        }
        if (!this.inputs.name && val) {
          this.$set(this.inputs, "name", val);
        }
        this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
