<template>
  <b-modal
      trap-focus
      v-if="area"
      has-modal-card
      :active="active"
      @active:update="changeActive($event)"
      @close="changeActive(loading)"
      type="is-warning"
  >
    <div class="modal-card">
      <div class="modal-card-head">
        <div class="modal-card-title">
          <span v-if="rebuilding">
            Building model
          </span>
          <span v-else>
            Review quality of training
          </span>
        </div>
      </div>
      <div class="modal-card-body">
        <transition name="slide">
          <div class="has-text-centered"
               v-if="loading || !(!!trainingQuality && trainingQuality.length>0 && !!allMeanSimilarityTable && allMeanSimilarityTable.length>0)">
            <spinner-with-checkmark
                :loading="loading"
                :success="!loading && !!trainingQuality && trainingQuality.length>0 && !!allMeanSimilarityTable && allMeanSimilarityTable.length>0"
            />
            <div v-if="!loading && !(!!trainingQuality && trainingQuality.length>0)"
                 class="has-text-danger has-text-centered">
              Training quality data is empty. Click <a @click="getTrainingStats()">here to re-load</a>.
            </div>
            <div v-if="!loading && !(!!allMeanSimilarityTable && allMeanSimilarityTable.length>0)"
                 class="has-text-danger has-text-centered">
              POU similarity data is empty. Click <a @click="getTrainingStats()">here to re-load</a>.
            </div>
          </div>
        </transition>
        <div v-if="!loading">
          <div v-if="trainingStats">
            <b-tabs v-model="tab" position="is-centered">
              <b-tab-item label="Training quality">
                <div v-if="trainingQuality">
                  <p class="subtitle has-text-centered mt-3">Training quality</p>
                  <div v-if="trainingQuality.filter(tq=>tq.quality!=='good').length"
                       class="has-text-danger has-text-centered"
                       style="text-shadow: 1px 1px grey;">
                    Some POUs have insufficient quality of training data.
                  </div>
                  <ground-truth-training-quality-table
                      @input="sourceOrSourceArrayClicked([$event])"
                      v-if="Array.isArray(trainingQuality) && trainingQuality.length"
                      :training-quality="trainingQuality"
                  />
                  <div
                      v-else
                      class="has-text-danger"
                  >
                    Warning: Could not load training quality data. Please see
                    console for details.
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="POU similarity">
                <div v-if="similarityTables && allMeanSimilarityTable">
                  <p class="subtitle has-text-centered mt-3">Similarity between POUs</p>
                  <div
                      v-if="similarityTableRowsWithHighConfusion.length"
                      class="has-text-danger has-text-centered mb-1"
                      style="text-shadow: 1px 1px grey;"
                  >
                    Some POUs present a high risk of confusion with the current training data.
                  </div>
                  <div
                      v-if="similarityTableRowsWithModerateConfusion.length"
                      class="has-text-warning has-text-centered mb-1"
                      style="text-shadow: 1px 1px grey;"
                  >
                    Some POUs present a moderate risk of confusion with the current training data.
                  </div>
                  <hr class="mt-6 mb-6"/>
                  <b-field
                      label="View similarity/risk-of-confusion by"
                      expanded
                      class="mb-3"
                      message="A lower similarity score indicates a higher risk of confusion; The ideal score is above 2.0"
                  >
                    <similarity-view-select v-model="similarityView"/>
                  </b-field>
                  <div v-show="tab === 1">
                    <div v-if="similarityView === 'all_mean'">
                      <hr class="mt-6 mb-6"/>
                      <div class="subtitle has-text-centered">
                        Similarity / Risk-of-confusion across all sensors (mean)
                      </div>
                      <div style="overflow-x: scroll !important;">
                        <source-similarity-heatmap
                            :rows="addInverseRowsToSimilarityTable(allMeanSimilarityTable)"
                            @input="sourceOrSourceArrayClicked($event)"
                        />
                      </div>
                    </div>
                    <div v-else-if="similarityView === 'all_max'">
                      <hr class="mt-6 mb-6"/>
                      <div class="subtitle has-text-centered">
                        Similarity / Risk-of-confusion across all sensors (max)
                      </div>
                      <div style="overflow-x: scroll !important;" >
                        <source-similarity-heatmap
                            :rows="addInverseRowsToSimilarityTable(allMaxSimilarityTable)"
                            @input="sourceOrSourceArrayClicked($event)"
                        />
                      </div>
                    </div>
                    <div v-else-if="similarityView === 'sensors'">
                      <div>
                        You are viewing POU similarity / risk-of-confusion calculater for each individual sensor.
                        In order to view this table for overall data (mean values),
                        <a @click="similarityView='all_mean'">click here</a>.
                      </div>
                      <div>
                        You can also view this table by <a @click="similarityView='all_max'">max values</a>
                        across all sensors.
                      </div>
                      <div v-for="sensorSimilarityTable in sensorSimilarityTables">
                        <hr class="mt-6 mb-6"/>
                        <div class="subtitle has-text-centered">
                          Similarity / Risk-of-confusion for sensor
                          <router-link :to="{
                            name:'go-to-device',
                            params: {serial:sensorSimilarityTable.serial}
                          }">
                            {{sensorSimilarityTable.serial}}
                          </router-link>
                        </div>
                        <div style="overflow-x: scroll !important;">
                          <source-similarity-heatmap
                              :rows="addInverseRowsToSimilarityTable(sensorSimilarityTable.data)"
                              @input="sourceOrSourceArrayClicked($event)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    v-else
                    class="has-text-danger"
                >
                  Warning: Could not load POU similarity data. Please see
                  console for details.
                </div>
              </b-tab-item>
              <b-tab-item label="Summary">
                <div class="has-text-centered" v-if="trainingQualityIssuesPresent || similarityIssuesPresent">
                  <spinner-with-checkmark
                      :loading="false"
                      :success="false"
                  />
                  <div v-if="trainingQualityRowsWithIssues.length" class="mb-6">
                    <div class="has-text-danger">
                      The following POUs do not have a high quality of training.
                      Please review and follow each suggestion before building a model.
                    </div>
                    <div class="table-container">
                      <training-quality-summary-table
                        :rows="trainingQualityRowsWithIssues"
                        @input="sourceOrSourceArrayClicked($event)"
                      />
                    </div>
                  </div>
                  <div
                      v-if="(similarityTableRowsWithHighConfusion + similarityTableRowsWithModerateConfusion).length > 0"
                      class="mb-6">
                    <div>
                      <div class="has-text-danger">
                        The following pairs of POUs have a risk of confusion.
                        Please review and follow each suggestion before building a model.
                      </div>
                      <div class="table-container">
                        <similarity-summary-table
                          :rows="similarityTableRowsWithHighConfusion.concat(similarityTableRowsWithModerateConfusion)"
                          @input="sourceOrSourceArrayClicked($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    v-if="!loading && trainingQuality && trainingQuality.length && allMeanSimilarityTable && allMeanSimilarityTable.length && (similarityTableRowsWithHighConfusion + similarityTableRowsWithModerateConfusion).length === 0 && trainingQualityRowsWithIssues.length === 0"
                    class="has-text-centered has-text-success"
                >
                  Your training data shows no issues.
                </div>
                <hr class="mt-3 mb-3"/>
                <div class="has-text-centered mt-3">
                  Would you like to continue to build or re-build the prediction model for
                  area <b>{{ area.$displayString }}</b>?
                </div>
                <div class="mt-3 has-text-centered">
                  Doing so will automatically exit training mode.
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
          <div
              v-else
              class="has-text-danger has-text-centered mt-3 mb-3"
          >
            Warning: Could not load training statistics. Please see
            console for details.
          </div>
        </div>
      </div>
      <div class="modal-card-foot">
        <div class="card-footer-item">
          <b-button
              type="is-default"
              @click="changeActive(false)"
              class="is-fullwidth"
              :disabled="rebuilding"
          >
            Cancel
          </b-button>
        </div>
        <div class="card-footer-item">
          <b-button
              type="is-primary"
              v-if="tab <2"
              @click="tab+=1"
              class="is-fullwidth"
              :loading="loading">
            Continue
          </b-button>
          <b-button
              type="is-warning"
              v-if="tab === 2"
              @click="buildModelClicked()"
              class="is-fullwidth"
              :loading="loading"
          >
            Build model
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import trackWindowSize from "../../../mixins/trackWindowSize";
import {get, sortBy} from "lodash";
import Building from "../../../dataClasses/Building";
import GroundTruthTrainingQualityTable from "../../../tables/GroundTruthTrainingQualityTable";
import SourceTrainingSimilarityTable from "../../../tables/SourceTrainingSimilarityTable";
import SourceSimilarityHeatmap from "../../DataVisualations/SourceSimilarityHeatmap";
import {getObjectInArrayById} from "../../../scripts/filterHelpers";
import SimilaritySummaryTable from "../../../tables/SimilaritySummaryTable";
import TrainingQualitySummaryTable from "../../../tables/TrainingQualitySummaryTable";
import SimilarityViewSelect from "../../Selects/SimilarityViewSelect";

export default {
  name: "GroundTruthSummaryModal",
  components: {
    SimilarityViewSelect,
    TrainingQualitySummaryTable,
    SimilaritySummaryTable,
    SourceSimilarityHeatmap, SourceTrainingSimilarityTable, GroundTruthTrainingQualityTable},
  mixins: [trackWindowSize],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * An Area, Source, or Position
     */
    area: {
      type: Building,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      rebuilding: false,
      trainingStats: null,
      tab: 0
    };
  },
  computed: {
    similarityView: {
      get() {return this.$route.query.simView || 'all_mean'},
      set(simView) {simView ? this.queryReplace({simView}) : this.queryRemoveKeys(['simView'])}
    },
    trainingQualityRowsWithIssues() {
      return this.trainingQuality.filter(tq => tq.quality !== 'good')
    },
    trainingQualityIssuesPresent() {
      return this.trainingQuality &&
          this.trainingQuality.length &&
          this.trainingQualityRowsWithIssues.length > 0;
    },
    trainingQuality() {
      return get(this.trainingStats, "training_quality_table", []) || [];
    },
    similarityTableRowsWithHighConfusion() {
      return this.allMeanSimilarityTable.filter(tq => tq.Distance < 1.0)
    },
    similarityTableRowsWithModerateConfusion() {
      return this.allMeanSimilarityTable.filter(tq => tq.Distance < 2.0 && tq.Distance >= 1.0)
    },
    similarityIssuesPresent() {
      return !!(
          this.allMeanSimilarityTable &&
          this.allMeanSimilarityTable.length &&
          (
              this.similarityTableRowsWithModerateConfusion.length ||
              this.similarityTableRowsWithHighConfusion.length
          )
      );
    },
    similarityTables() {
      return get(this.trainingStats, "similarity_table", []) || [];
    },
    allMeanSimilarityTable() {
      return (this.similarityTables && this.similarityTables.length) ?
          this.similarityTables.filter(tbl => tbl.calculation === 'all_mean')[0].data :
          []
    },
    allMaxSimilarityTable() {
      return (this.similarityTables && this.similarityTables.length) ?
          this.similarityTables.filter(tbl => tbl.calculation === 'all_max')[0].data :
          []
    },
    sensorSimilarityTables() {
      return (this.similarityTables && this.similarityTables.length) ?
          sortBy(this.similarityTables.filter(tbl => tbl.calculation === 'sensor'), 'serial') : []
    }
  },
  methods: {
    get,
    getObjectInArrayById,
    addInverseRowsToSimilarityTable(table) {
      let additionalRows = [];
      table.forEach(row => {
        additionalRows.push({...row, source_1:row.source_2, source_2:row.source_1});
      });
      return table.concat(additionalRows);
    },
    changeActive(value) {
      this.$emit("update:active", value);
    },
    buildModelClicked() {
      this.$buefy.dialog.confirm({
        title: "Build prediction model?",
        message: `<p>Are you sure you wish to build a model for area <b>${this.area.$displayString}</b>?</p> <p>This action is irreversible.<p></p>`,
        onConfirm: () => {
          this.rebuildModel();
        }
      })
    },
    rebuildModel() {
      if (!(this.area && this.area.$area)) {
        this.$handleError(
            "You must select an area for which to rebuild the model"
        );
      }
      this.loading = true;
      this.rebuilding = true;
      this.area.$area
          .$exitTrainingMode(true)
          .then(() => {
            this.$handleSuccess(
                "Model rebuild has been scheduled for " + this.area.$displayString
            );
            this.$router.replace({
              name: "training:overview",
              params: {areaId: this.$route.params.areaId},
            });
          })
          .catch((e) => {
            this.$handleError("Could not schedule model rebuild; see console", e);
          })
          .finally(() => {
            this.loading = false;
            this.rebuilding = false;
          });
    },
    getTrainingStats() {
      let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          url = `${process.env.VUE_APP_AQUACLOUD_URL}/training/generate_source_training_quality`,
          body = JSON.stringify({
            obj_path: this.area.$area.$FSRef.path,
            add_similarity_table: true,
          }),
          args = {
            method: "POST",
            headers,
            body,
          };
      headers[this.$client.authHeaderKey] = this.$client.authHeaderValue;
      this.loading = true;
      this.tab = 0;
      fetch(url, args)
          .then((res) => {
            if (!res.ok) {
              throw new Error(res.statusText);
            }
            return res.json();
          })
          .then((json) => {
            if (!json.success) {
              throw new Error(json.message);
            }
            this.trainingStats = json;
          })
          .catch((e) => {
            this.$handleError(
                "Error while loading ground truth quality statistics",
                e
            );
          })
          .finally(() => (this.loading = false));
    },
    sourceOrSourceArrayClicked(srcArray) {
      if (!Array.isArray(srcArray)) {
        throw new Error("Expected array, got " + typeof srcArray, srcArray)
      }
      this.$emit('click', srcArray);
    },
  },
  watch: {
    active: {
      immediate: true,
      handler(isActive, wasActive) {
        if (isActive && !wasActive) {
          this.getTrainingStats();
        }
      },
    },
    "area.$area": {
      handler($area, $prev) {
        if ($area && $prev && $area.id === $prev.id) {
          return;
        }
        if ($area && this.active) {
          this.getTrainingStats();
        }
      },
    },
  },
};
</script>

<style scoped></style>
