<template>
  <div>
    <div :class="'columns is-multiline ' + classes">
      <div class="column is-7-fullhd is-12-tablet">
        <b-message title="Evaluation details" :closable="false">
          <b-table
            :data="evaluationRows"
            v-if="evaluationRows.length"
            :show-header="false"
          >
            <b-table-column v-slot="props" label="Property">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" label="Value">
              <span
                v-if="props.row.name === 'Predicted source'"
                :value="evaluationPredictedSource"
              >
                <span v-if="site">
                  <site-display :value="site" show-address show-link-icon />
                </span>
                <p v-if="get(evaluationPredictedSource, 'building', null)">
                  <building-display
                    :show-floors="false"
                    show-link-icon
                    v-if="
                      getObjectInArrayById(
                        $store.state.buildings,
                        get(evaluationPredictedSource, 'building.id', null),
                        null
                      )
                    "
                    :value="
                      getObjectInArrayById(
                        $store.state.buildings,
                        get(evaluationPredictedSource, 'building.id', null),
                        null
                      )
                    "
                  />
                </p>
                <p v-if="get(evaluationPredictedSource, 'room', null)">
                  <room-display
                    show-link-icon
                    v-if="
                      getObjectInArrayById(
                        $store.state.rooms,
                        get(evaluationPredictedSource, 'room.id', null),
                        null
                      )
                    "
                    :value="
                      getObjectInArrayById(
                        $store.state.rooms,
                        get(evaluationPredictedSource, 'room.id', null),
                        null
                      )
                    "
                  />
                </p>
                <p>
                  <source-display
                    show-link-icon
                    v-if="evaluationPredictedSource"
                    :value="evaluationPredictedSource"
                  />
                </p>
              </span>
              <span v-else>{{ props.row.value }}</span>
            </b-table-column>
          </b-table>
          <i v-else>Evaluation details not found. These will be available after a prediction model is built.</i>
        </b-message>
        <b-message title="Information" :closable="false">
          <b-table
            :data="informationRows"
            v-if="informationRows.length"
            :show-header="false"
          >
            <b-table-column v-slot="props" label="Property">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" label="Value">
              <b-button
                :loading="loading"
                expanded
                size="is-small"
                type="is-primary"
                v-if="props.row.name === 'Notification'"
                @click="viewNotification(props.row.value)"
              >
                View notification
              </b-button>
              <span v-else>{{ props.row.value }}</span>
            </b-table-column>
          </b-table>
          <i v-else>Information not found</i>
        </b-message>
      </div>
      <div class="column is-5-fullhd is-12-tablet">
        <b-message title="Point-of-use" :closable="false">
          <p v-if="site">
            <site-display :value="site" show-address show-link-icon />
          </p>
          <p v-if="sourceWithNm(value.information.actual_source).building">
            <building-display
              :show-floors="false"
              show-link-icon
              :value="
                getObjectInArrayById(
                  $store.state.buildings,
                  sourceWithNm(value.information.actual_source).building.id,
                  null
                )
              "
            />
          </p>
          <p v-if="sourceWithNm(value.information.actual_source).room">
            <room-display
              show-link-icon
              :value="
                getObjectInArrayById(
                  $store.state.rooms,
                  sourceWithNm(value.information.actual_source).room.id,
                  null
                )
              "
            />
          </p>
          <p>
            <source-display
              show-link-icon
              :value="sourceWithNm(value.information.actual_source)"
            />
          </p>
          <transition name="slide">
            <div v-if="editingSource" class="box mt-3">
              <strong class="mb-3"> Update point-of-use to: </strong>
              <div class="ml-6 mb-6">
                <select-building-room-source
                  :validation-message="
                    !inputs.sourceId ? 'Please select a point-of-use' : null
                  "
                  :building-editable="false"
                  :site="site"
                  :building-id="inputs.buildingId"
                  :room-id="inputs.roomId"
                  :source-id="inputs.sourceId"
                  @update:building-id="inputs.buildingId = $event"
                  @update:room-id="inputs.roomId = $event"
                  @update:source-id="inputs.sourceId = $event"
                />
                <div class="buttons mt-3 is-pulled-right has-text-justified">
                  <b-button
                    @click="editingSource = false"
                    type="is-danger is-outlined"
                    icon-left="chevron-up"
                    :loading="loading"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    :disabled="!inputs.sourceId"
                    :loading="loading"
                    type="is-primary"
                    :icon-right="iconFor('save')"
                    @click="update(sourceUpdateDict)"
                  >
                    Update
                  </b-button>
                </div>
              </div>
            </div>
          </transition>
          <b-button
            class="mt-3"
            v-if="!editingSource"
            :loading="loading"
            expanded
            type="is-primary is-outlined"
            @click="editingSource = true"
          >
            Change point-of-use
          </b-button>
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from 'lodash';
import SelectBuildingRoomSource from "../../../components/Selects/SelectBuildingRoomSource";
import iconFor from "../../../icons";
import { getObjectInArrayById } from "../../../scripts/filterHelpers";

import shortHumanizer from "../../../components/DataVisualations/DataTransformations/durationHumanizerShort";

export default {
  name: "GroundTruthDetail",
  components: {
    SelectBuildingRoomSource,
  },
  data() {
    return {
      editingSource: false,
      inputs: {},
      loading: false,
    };
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sourceUpdateDict() {
      let d = {};
      if (this.inputs.sourceId) {
        d["information.actual_source"] = "SRC:" + this.inputs.sourceId;
      }
      return d;
    },
    site() {
      return getObjectInArrayById(
        this.$store.state.sites,
        this.value.for.id,
        null
      );
    },
    evaluationPredictedSource() {
      let self = this;
      return self.value && self.value.evaluation && self.value.evaluation.source
        ? getObjectInArrayById(
            self.$store.state.sources,
            self.value.evaluation.source.id,
            { name: "Not assigned" }
          )
        : null;
    },
    evaluationRows() {
      if (!this.value || (this.value && !this.value.evaluation)) {
        return [];
      }
      return [
        {
          name: "Duration",
          value: shortHumanizer(
            (this.value.evaluation.event_duration_seconds || 0) * 1000
          ),
        },
        {
          name: "Quality",
          value: this.capitalizeFirstLetter(
            this.value.evaluation.quality || ""
          ),
        },
        {
          name: "Predicted source",
          value: this.value.evaluation.source,
        },
      ];
    },
    informationRows() {
      let rows = [],
        self = this;
      if (!self.value || (self.value && !self.value.information)) {
        return rows;
      }
      if (self.value.information.post_dt) {
        rows.push({
          name: "Issued",
          value: self.value.information.post_dt.toLocaleString(),
        });
      }
      if (self.value.information.actual_duration) {
        rows.push({
          name: "Length",
          value: shortHumanizer(self.value.information.actual_duration * 1000),
        });
      }
      if (self.value.information.message_id) {
        rows.push({
          name: "Notification",
          value: self.value.information.message_id,
        });
      }
      if (
        this.$store.state.user_roles.is_root &&
        self.value.information.position_nm
      ) {
        // rows.push({
        //     name: 'Position',
        //     value: self.value.information.position_nm
        // })
      }
      return rows;
    },
  },
  methods: {
    get,
    getObjectInArrayById,
    iconFor,
    shortHumanizer,
    viewNotification(messageId) {
      let self = this;
      self.loading = true;
      let m = "This message was sent to another user";
      this.$dataClasses.UserMessage.fetchById(messageId)
        .then((result) => {
          if (result) {
            self.$router.push({
              name: "notifications:view",
              params: { id: result.id },
              query: { fetch: 1 },
            });
          } else {
            self.$handleError(m, m);
          }
        })
        .catch((e) => self.$handleError(m, e))
        .finally(() => {
          self.loading = false;
        });
    },
    sourceWithNm(srcNm) {
      return getObjectInArrayById(
        this.$store.state.sources,
        srcNm.replace("SRC:", ""),
        {building:{id:null}}
      );
    },
    update(data) {
      let self = this;
      self.loading = true;
      self.$dataClasses.GroundTruth.save(self.value.id, data)
        .then((result) => {
          self.$emit("update", result);
          self.$handleSuccess("Ground truth record saved");
          self.editingSource = false;
        })
        .catch((e) => self.$handleError(e, e))
        .finally(() => {
          self.loading = false;
        });
    },
  },
  watch: {
    editingSource: {
      handler(val, oldVal) {
        if (val && !oldVal) {
          let src = this.sourceWithNm(this.value.information.actual_source);
          this.inputs = {
            buildingId:
              src && src.building && src.building.id ? src.building.id : null,
            roomId: src.room && src.room.id ? src.room.id : null,
            sourceId: src ? src.id : null,
          };
        } else if (val && !oldVal) {
          this.inputs = {};
        }
      },
    },
  },
};
</script>

<style scoped></style>
