<template>
  <div class="mt-2">
    <div class="columns" v-if="showOptions">
      <div class="column has-text-centered has-text-justified">
        <div class="has-text-centered has-text-justified" style="display:inline-block; ">
        <b-field :label="label" class="">
          <b-radio v-model="method" native-value="scan">
            <slot name="scan-qr-code-text"> Scan QR code</slot>
          </b-radio>
          <b-radio v-model="method" native-value="input" v-if="allowSerialInput">
            Input serial
          </b-radio>
          <b-radio v-model="method" native-value="select">
            <slot name="select-device-text"> Select device</slot>
          </b-radio>
          <b-icon icon="sync" class="fa-spin" v-if="loading" size="is-small"/>
        </b-field>
          </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div v-if="method === 'input' && allowSerialInput">
          <b-field
              label="Enter a serial number"
              :message="serialInputIsValid ? null : 'Please enter a valid serial number'"
              :type="serialInputIsValid ? 'is-success' : 'is-danger'"
          >
            <b-input
                v-model="serialInput"
                :loading="loading"
                @keydown.enter="serialInputIsValid ? handleSerialInput(serialInput) : null"
            />
          </b-field>
        </div>
        <div v-if="method === 'scan'">
          <b-field label="Scan QR code for device">
            <qr-code-reader-button @input="handleScan($event)" ref="qr-button"/>
          </b-field>
        </div>
        <div v-else>
          <b-table
              :loading="loadingAvailableDevices"
              title="Select from available devices"
              :default-sort="['serial']"
              :checkable="checkable"
              :checked-rows.sync="checkedRows"
              default-sort-direction="asc"
              :data="availableDevices"
          >
            <template #empty>
              <slot name="empty">
                No {{ deviceState.replace("device_", "").replace("_", " ") }}
                {{ deviceTypes.map((d) => d + "s").join(" or ") }} found
                <span v-if="$store.getters.subcustomer">
                  that have been shipped to
                  <b>{{ $store.getters.subcustomer.name }}</b></span
                >
              </slot>
            </template>
            <b-table-column
                label="Serial"
                field="serial"
                v-slot="props"
                sortable
            >
              {{ props.row.serial }}
            </b-table-column>
            <b-table-column label="Device type" v-slot="props" sortable>
              {{
                capitalizeFirstLetter(getDeviceTypeFromSerial(props.row.serial))
              }}
            </b-table-column>
            <slot name="inner"/>
            <b-table-column label="Shipped to" v-slot="props" sortable>
              {{
                props.row.device_state.subcustomer.id ===
                $store.getters.subcustomer.id
                    ? $store.getters.subcustomer.name
                    : "Unknown"
              }}
            </b-table-column>
            <b-table-column v-slot="props" label="Status" sortable>
              {{
                capitalizeFirstLetter(
                    (props.row.get('device_state.event_type', 'None') || 'None')
                ).replace("_", " ")
              }}
            </b-table-column>
            <b-table-column
                v-if="showLocations"
                v-slot="props"
                label="Area"
            >
              <b-icon
                  icon="sync"
                  class="fa-spin"
                  v-if="fetchingPositionsForSerials.includes(props.row.serial)"
              />
              <p v-else-if="serialToPosition[props.row.serial].building">
                <building-display
                    :show-floors="false"
                    :value="
                    $dataClasses.Building.fromStore(
                      $store.state.buildings,
                      serialToPosition[props.row.serial].building.id
                    )
                  "
                />
              </p>
              <i v-else>None</i>
            </b-table-column>
            <b-table-column v-if="showLocations" label="Room" v-slot="props">
              <b-icon
                  icon="sync"
                  class="fa-spin"
                  v-if="fetchingPositionsForSerials.includes(props.row.serial)"
              />
              <p v-else-if="serialToPosition[props.row.serial].room">
                <room-display
                    :value="
                    Room.fromStore(
                      $store.state.rooms,
                      serialToPosition[props.row.serial].room.id
                    )
                  "
                />
              </p>
              <i v-else>None</i>
            </b-table-column>
            <b-table-column v-slot="props" v-if="!checkable">
              <b-button
                  :outlined="props.row.serial !== selectedSerial"
                  rounded
                  :loading="loading"
                  type="is-primary"
                  size="is-small"
                  @click="handleClick(props.row)"
                  :icon-right="
                  props.row.serial === selectedSerial ? null : 'chevron-right'
                "
              >
                Select{{ props.row.serial === selectedSerial ? "ed" : "" }}
              </b-button>
            </b-table-column>
          </b-table>
          <p class="has-text-justified has-text-right">
            <pagination-controls
                refreshable
                @refresh-clicked="getAvailableDevices()"
                @input="page=$event.page"
                :has-more-results="availableDevices && availableDevices.length >= paginate_by"
                :page="page"
                :loading="loadingAvailableDevices"/>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {chunk, uniqBy} from "lodash";
import QrCodeReaderButton from "../../../components/QrCodeReader/QrCodeReaderButton";
import extractDeviceInfoFromQRCode, {
  deviceTypes,
  getDeviceClassFromDeviceType,
  getDeviceTypeFromSerial,
  isValidSerial,
} from "../../../scripts/deviceQrCodes";

import PaginationControls from "../../../components/Pagination/PaginationControls";

/**
 * Callback for device.
 *
 * @callback handleDeviceCallback
 * @param {Object} deviceDoc
 */

const paginate_by = 25;


export default {
  name: "ScanOrSelectDevice",
  components: {
    PaginationControls,
    QrCodeReaderButton,
  },
  props: {
    allowSerialInput: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    showLocations: {
      type: Boolean,
      default: false,
    },
    serialsChecked: {
      type: Array,
      default: () => [],
    },
    /**
     * @type {Array.<deviceTypes>}
     */
    deviceTypes: {
      type: Array,
      default() {
        return deviceTypes;
      },
      validator(val) {
        return val.every((devType) => deviceTypes.includes(devType));
      },
    },
    /**
     * If devicesPaths (array.<string of firestorePaths for devices i.e. gateways/398ui2as or probes/eeu783iuj>)
     * these will be fetched. Otherwise, queried by deviceState/Area
     */
    devicePaths: {
      type: Array,
      required: false,
      default: () => null,
    },
    /**
     * The device_state.event_type to be shown in selection table.
     * This is used to query for the correct devices to show
     */
    deviceState: {
      type: String,
      default: "device_shipped",
    },
    /**
     * Label shown in table
     */
    label: {
      type: String,
      default: "Select device",
    },
    methodQueryKey: {
      type: String,
      default: "sm",
    },
    area: {
      type: Object,
      default: () => null,
    },
    selectedSerial: {
      type: String,
      default: null,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    filterResultsCallable: {
      type: Function,
      default: () => true,
    },

  },
  /**
   *
   * @returns {{availableDevices: Array<Object>, loading: boolean, loadingAvailableDevices: boolean, serialToPosition: {Object}}}
   */
  data() {
    return {
      /**
       * Array of Sensor/Gateway/Probe objects to be shown in available devices table
       * @type Array.<Object>
       */
      availableDevices: [],
      fetchingPositionsForSerials: [],
      loading: false,
      loadingAvailableDevices: false,
      paginate_by,
      serialToPosition: {},
      page: 1,
      Building: this.$dataClasses.Building,
      Room: this.$dataClasses.Room,
    };
  },
  mounted() {
    // if (this.method === "select") {
    //   if (this.deviceState === "device_shipped") {
    //     if (this.$store.getters.subcustomer) {
    //       this.getAvailableDevices();
    //     }
    //   } else if (this.deviceState === "device_installed") {
    //     this.getAvailableDevices();
    //   }
    // }
    // if (this.autoOpen) {
    //   this.openCamera();
    // }
  },
  computed: {
    serialInput: {
      get() {return this.$route.query.serialInput || null},
      set(serialInput) {this.queryReplace({serialInput})}
    },
    serialInputIsValid() {
      return this.serialInput ? isValidSerial(this.serialInput) : false;
    },
    serials() {
      return this.availableDevices.map((i) => i.serial);
    },
    serialToPositionKeys() {
      return Object.keys(this.serialToPosition);
    },
    serialsWithoutPositions() {
      return this.serials.filter(
          (s) =>
              !this.serialToPositionKeys.includes(s) &&
              !this.fetchingPositionsForSerials.includes(s)
      );
    },
    checkedRows: {
      get() {
        return this.availableDevices.filter((dev) =>
            this.serialsChecked.includes(dev.serial)
        );
      },
      set(checkedRows) {
        this.$emit(
            "update:serials-checked",
            checkedRows.map((row) => row.serial)
        );
      },
    },
    method: {
      get() {
        return this.$route.query[this.methodQueryKey] || "select";
      },
      set(sm) {
        let it = {};
        it[this.methodQueryKey] = sm;
        sm === "select"
            ? this.queryRemoveKeys([this.methodQueryKey])
            : this.queryReplace(it);
      },
    },
    queries() {
      let queries = [["device_state.event_type", "==", this.deviceState]];
      if (this.deviceState === "device_shipped") {
        queries.push([
          "device_state.subcustomer",
          "==",
          this.toFSRef(this.$store.getters.subcustomer),
        ]);
      }
      if (this.deviceState === "device_installed") {
        if (!this.area) {
          throw "Area is required to pull installed devices";
        }
        queries.push(["device_state.area", "==", this.toFSRef(this.area)]);
      }
      return queries;
    },
  },
  methods: {
    openCamera() {
      this.$nextTick(()=>{
        this.$set(this.$refs['qr-button'], 'isOpen', true);
      })
    },
    getDeviceTypeFromSerial,
    /**
     * Given device QR code, returns object of params
     * @param deviceQRCodeUrl {String}
     */
    extractQrCodeData(deviceQRCodeUrl) {
      try {
        return extractDeviceInfoFromQRCode(deviceQRCodeUrl);
      } catch (e) {
        this.$handleError(e, e);
      }
    },
    /**
     * Fetch available devices from API
     */
    getAvailableDevices() {
      //TODO: Fetch based off available types
      const subcustomer = this.$store.getters.subcustomer;
      let promises = [];
      if (!subcustomer) {
        this.availableDevices = [];
        return;
      }
      this.availableDevices = [];
      if (Array.isArray(this.devicePaths)) {
        promises = this.devicePaths.map((path) => {
          const deviceClass = getDeviceClassFromDeviceType(
              path.split("/")[0].slice(0, -1)
          );
          return deviceClass.fetchById(path.split("/")[1]);
        });
      } else {
        promises = this.deviceTypes.map((deviceType) => {
          const deviceClass = getDeviceClassFromDeviceType(deviceType);
          return deviceClass.query(this.queries, {
            order_field: "device_state.event_type",
            paginate_by: this.paginate_by,
            page: this.page
          });
        });
      }
      this.loadingAvailableDevices = true;
      Promise.all(promises)
          .then((responses) => {
            responses.forEach((resultOrResults) => {
              if (Array.isArray(resultOrResults)) {
                resultOrResults
                    .filter(this.filterResultsCallable)
                    .forEach((entry) => {
                      this.availableDevices.push(entry);
                    });
              } else if (typeof resultOrResults === "object") {
                this.availableDevices.push(resultOrResults);
              }
            });
            this.availableDevices = uniqBy(this.availableDevices, "serial");
          })
          .catch((e) => this.$handleError(e, e))
          .finally(() => {
            this.loadingAvailableDevices = false;
          });
    },
    /**
     * When row in table is selected
     * @param deviceRow {{serial: string}}
     */
    handleClick(deviceRow) {
      const deviceType = getDeviceTypeFromSerial(deviceRow.serial);
      if (!deviceType || !this.deviceTypes.includes(deviceType)) {
        this.$handleError(
            `Device must be one of: ${this.deviceTypes}`,
            `Device must be one of: ${this.deviceTypes}; Received: ${deviceType}`
        );
      }
      if (deviceRow && deviceRow.serial === this.selectedSerial) {
        this.input(null);
      } else {
        this.input(deviceRow);
      }
    },
    /**
     * When a device QR code is scanned, the url will be parsed here
     * @param $qrCodeUrl {string}
     */
    handleScan($qrCodeUrl) {
      const data = this.extractQrCodeData($qrCodeUrl),
          deviceType = data.deviceType;
      if (!deviceType || !this.deviceTypes.includes(deviceType)) {
        this.$handleError(
            `Scanned device type is ${
                deviceType || "unknown"
            }, but must be a ${this.deviceTypes.join(" or ")}`,
            `Scanned device type is ${
                deviceType || "unknown"
            }, but must be a ${this.deviceTypes.join(" or ")}`
        );
      } else {
        this.getDeviceFromQRCode($qrCodeUrl, (deviceDoc) => {
          this.input(deviceDoc);
        });
      }
    },
    /**
     * Triggered when device selected
     * @param {Object} device
     */
    input(device) {
      /**
       * @event input
       * @property {Object} device
       */
      this.$emit("input", device);
    },
    handleSerialInput(serial) {
      let deviceType = getDeviceTypeFromSerial(serial),
          deviceClass = getDeviceClassFromDeviceType(deviceType);
      if (!deviceType || !this.deviceTypes.includes(deviceType)) {
        this.$handleError(
            `Device must be one of: ${this.deviceTypes}`,
            `Device must be one of: ${this.deviceTypes}; Received: ${deviceType}`
        );
      }
      this.loading = true;
      deviceClass.queryForSingle(
          [['serial', '==', serial]]
      ).then(
          devDoc => this.input(devDoc)
      ).catch(
          e => this.$handleError(e)
      ).finally(
          () => this.loading = false
      )
    },
    /**
     * Given a scanned device QR code, validate and get serial number, auth c, etc
     * @param {string} deviceQRCodeUrl
     * @param {handleDeviceCallback} callback
     */
    getDeviceFromQRCode(deviceQRCodeUrl, callback) {
      if (!deviceQRCodeUrl || !deviceQRCodeUrl.includes("?")) {
        throw `Unexpected QR code url: ${deviceQRCodeUrl}`;
      }
      let rawParams = new URLSearchParams(deviceQRCodeUrl.split("?")[1]),
          params = Object.fromEntries(rawParams);

      this.loading = true;
      try {
        this.$client
            .get("/devices/from_qr_code/", {}, params)
            .then((res) => {
              if (res.success && res.result) {
                callback(res.result);
              } else {
                throw "No device found";
              }
            })
            .catch((e) => this.$handleError(e, e))
            .finally(() => (this.loading = false));
      } catch (e) {
        this.$handleError(e, e);
      }
    },
  },
  watch: {
    serialInputIsValid: {
      handler(val, oldVal) {
        if (val && !oldVal) {
          this.handleSerialInput(this.serialInput)
        }
      }
    },
    page: {
      immediate: true,
      handler() {this.getAvailableDevices()},
    },
    area(val, oldVal) {
      if (
          (this.method === "select" && val && !oldVal) ||
          (!val && oldVal) ||
          (val && oldVal && oldVal.id !== val.id)
      ) {
        this.getAvailableDevices();
      }
    },
    devicePaths: {
      immediate: true,
      handler(val) {
        if (this.area) {
          this.getAvailableDevices();
        }
      },
    },
    loading: {
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
    method: {
      handler(val, prev) {
        if (prev === "scan" && val !== "scan") {
          this.getAvailableDevices();
        } else if (prev !== "scan" && val === 'scan' && this.autoOpen) {
          this.openCamera()
        }
      },
      immediate: true
    },
    '$store.getters.subcustomer': {
       immediate:true,
      handler() {this.getAvailableDevices()}
    },
    availableDevices: {
      handler(val) {
        if (
            !this.showLocations ||
            !val ||
            !val.length ||
            !this.serialsWithoutPositions ||
            !this.serialsWithoutPositions.length
        ) {
          return;
        }
        if (this.deviceTypes.length > 1 || this.deviceTypes.includes("probe")) {
          throw "Cannot fetch locations via ScanOrSelectDevice if more than one device type or device is probe";
        }
        const self = this;
        let serialsToLoad = this.serialsWithoutPositions.filter(
            (serial) => !this.fetchingPositionsForSerials.includes(serial)
        );
        let serialChunks = chunk(serialsToLoad, 10);
        let PositionClass = this.deviceTypes.includes("gateway")
            ? this.$dataClasses.GatewayPosition
            : this.$dataClasses.Position;
        this.fetchingPositionsForSerials =
            this.fetchingPositionsForSerials.concat(serialsToLoad);
        serialChunks.forEach((serialChunk) => {
          PositionClass.query([["serial", "in", serialChunk]]).then((results) =>
              results.forEach((result) => {
                let newObj = {};
                newObj[result.serial] = result;
                self.serialToPosition = Object.assign(
                    {},
                    self.serialToPosition,
                    newObj
                );
                self.fetchingPositionsForSerials =
                    self.fetchingPositionsForSerials.filter(
                        (serial) => serial !== result.serial
                    );
              })
          );
        });
      },
    },
  },
};
</script>

<style scoped></style>
