<template>
  <div>
    <div v-if="object">
      <generic-view
        object-name="subcustomer"
        parent-name="customer"
        parent-title="Customer"
        :ancestors="ancestors"
        :parent="parent"
        :object="object"
        :children="children"
        child-name="site"
        child-title="Sites"
        :state-var-name="endpointSegment"
        @edit-clicked="editClicked()"
      >
        <level :values="levelArray" />
        <template v-slot:childrendisplay>
          <site-table :rows="children" />
        </template>
        <template
          v-slot:addnewchild
          v-if="$store.state.user_roles.is_installer"
          type="is-create is-outlined"
        >
          <b-button @click="childModalOpen = true" type="is-primary">Add new site</b-button>
        </template>
      </generic-view>

      <add-subcustomer-modal
        v-model="modalOpen"
        :parent="object.parent"
        :id="object.id"
        :inputs="object"
      ></add-subcustomer-modal>

      <add-site-modal
        v-model="childModalOpen"
        :parent="object"
        @saved="childModalOpen = false"
      ></add-site-modal>
    </div>
    <div v-else>
      <b-message type="is-error"> No object found with that ID</b-message>
    </div>
  </div>
</template>

<script>
import AddSubcustomerModal from "../../../components/Admin/SubCustomers/AddSubcustomerModal";
import AddSiteModal from "../../../components/Admin/Sites/AddSiteModal";
import Level from "../../../components/Level/Level";
import SiteTable from "../../../tables/SiteTable";
import genericView from "./genericView";
import {
  getObjectInArrayById,
  getChildrenOfId,
  getAncestors,
} from "../../../scripts/filterHelpers";
// import onScopeChangeMixin from "../../../applications/Administration/View/onScopeChangeMixin";

export default {
  name: "ViewSubcustomer",
  components: {
    genericView,
    AddSiteModal,
    AddSubcustomerModal,
    Level,
    SiteTable,
  },
  // mixins: [onScopeChangeMixin],
  methods: {
    editClicked() {
      this.modalOpen = true;
    },
  },
  computed: {
    ancestors() {
      return this.object
        ? getAncestors([
            {
              title: "Customer",
              object: getObjectInArrayById(
                this.$store.state.customers,
                this.object.customer.id,
                null
              ),
              endpointName: "administration:view-customer",
            },
          ])
        : [];
    },
    levelArray() {
      return this.object ? [{ label: "Name", value: this.object.name }] : [];
    },
    parent() {
      return this.object && this.object.customer && this.object.customer.id
        ? getObjectInArrayById(
            this.$store.state.customers,
            this.object.customer.id,
            null
          )
        : null;
    },
    object() {
      return getObjectInArrayById(
        this.$store.state.subcustomers,
        this.id,
        null
      );
    },
    children() {
      return this.object
        ? getChildrenOfId(this.$store.state.sites, this.id)
        : null;
    },
  },
  data() {
    return {
      endpointSegment: "subcustomers",
      childModalOpen: Object.keys(this.$route.query).includes("addChild"),
      modalOpen: Object.keys(this.$route.query).includes("edit"),
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    modalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { edit: null } : {} });
      },
    },
    childModalOpen: {
      handler(val) {
        this.$router.replace({ query: val ? { addChild: null } : {} });
      },
    },
  },
};
</script>

<style scoped>
.media-name {
  padding-top: 73px;
}
</style>
