import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import alphanumericSort from "../appLevelFunctions/alphanumericSort";
import router from "../router";
import store from "../store";

export default class Building extends FirestoreDataClass {
  static collectionName = "buildings";

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  /**
   *
   * @returns {Area|null}
   */
  get $area() {
    return this.area
      ? getObjectInArrayById(store.state.areas, this.area.id, null)
      : null;
  }

  get $rooms() {
    if (this.id) {
      return store.state.rooms.filter(
        (r) => r.building && r.building.id === this.id
      );
    }
    return [];
  }

  get $sources() {
    if (this.id) {
      return store.state.sources.filter(
        (r) => r.building && r.building.id === this.id
      );
    }
    return [];
  }

  get $floors() {
    return Array.from(
      new Set(this.$rooms.map((r) => r.floor).filter((r) => !!r))
    ).sort(alphanumericSort);
  }

  get $floorString() {
    const segments = [];
    if (this.floors) {
      segments.push(`${this.floors} floor${this.floors !== 1 ? "s" : ""}`);
    }
    if (this.sub_floors) {
      segments.push(
        `${this.sub_floors} sub-floor${this.sub_floors !== 1 ? "s" : ""}`
      );
    }
    return segments.join(", ");
  }

  get $displayString() {
    return this.name + (this.$floorString ? ` (${this.$floorString})` : "");
  }

  $goToTrainingOverview(query={}) {
    return router.push({
      name: 'training:overview',
      params: {
        areaId: this.id
      },
      query
    })
  }

  $goToReviewTrainingQuality() {
    return this.$goToTrainingOverview({showModelModal: true})
  }
}
