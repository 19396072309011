<template>
  <div
    :class="{
      'circle-loader': true,
      'load-complete': !loading,
      success: loading || success,
      'danger dangery': !loading && !success,
    }"
  >
    <div
      :class="{ checkmark: success, draw: !loading }"
      v-if="!loading && success"
    />
    <transition name="fade">
      <p v-if="!loading && !success">
        <b-icon
          icon="exclamation"
          type="is-danger"
          size="is-large"
          class="fa-throb is-exclamation"
        />
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SpinnerWithCheckmark",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@use "sass:math";
@import "../../mixins/buefy/styles.scss";

$brand-success: $success;
$brand-danger: $danger;
$loader-size: 50px;
$check-height: math.div($loader-size, 2);
$check-width: math.div($check-height, 2);
$check-left: math.div($loader-size, 6) + math.div($loader-size, 12);
$check-thickness: 3px;
$check-color: $brand-success;
$times-color: $brand-danger;

.is-exclamation {
  margin-top: 8px;
}

.circle-loader {
  margin-bottom: math.div($loader-size, 6);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  margin-top: math.div($loader-size, 2);
  transition: 0.3s ease-in margin;

  &.load-complete {
    margin-top: 0;
    -webkit-animation: none;
    animation: none;

    transition: border 500ms ease-out, border-color 500ms ease-in;

    &.success {
      border-color: $check-color;
    }

    &.danger {
      border-color: $times-color;
    }
  }
}

.checkmark {
  display: none;

  &.draw {
    display: block;
  }

  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

.dangery {
  animation: pulse 2s linear infinite;
}

@keyframes pulse {
  from {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
</style>
