<template>
  <GmapMap
    :center="marker"
    :draggable="draggable"
    :zoom="zoom"
    :map-type-id="type"
    style="width: 100%; height: 400px"
    ref="$map"
    @click="mapClick($event)"
  >
    <GmapMarker clickable @click="markerClick($event)" :position="marker" />
  </GmapMap>
</template>

<script>
import { GeoPoint } from "../firebase";

export default {
  name: "MapWithMarker",
  props: {
    type: {
      type: String,
      default: "satellite",
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    // Weird, but use via :marker="{lat:parent.lat_long.latitude,lng:parent.lat_long.longitude}"
    marker: {
      required: true,
      type: [GeoPoint, Object],
    },
    zoom: {
      required: false,
      type: Number,
      default: 16,
    },
  },
  methods: {
    mapClick($event) {
      this.$emit("map-click", $event);
    },
    markerClick($event) {
      this.$emit("marker-click", $event);
    },
  },
};
</script>

<style scoped></style>
