import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import store from "../store";

export default class Room extends FirestoreDataClass {
  static collectionName = "rooms";
  static $commonRoomTypes = [
    "Bathroom",
    "Kitchen",
    "Outbuilding/Garage",
    "Outdoor area",
    "Utility/Laundry",
    "Other",
  ];

  static get $allRoomTypeValues() {
    return [
      ...this.$commonRoomTypes,
      ...new Set(
        store.state.rooms
          .filter(
            (rm) => rm && rm.type && !this.$commonRoomTypes.includes(rm.type)
          )
          .map((rm) => rm.type)
          .sort()
      ),
    ];
  }

  $getUnitOrFloorText() {
    if (this.unit) {
      return "#" + this.unit;
    }
    else if (this.floor) {
      return "Fl." + this.floor;
    }
  }

  $getDisplayString({showUnit= true, showFloor= true}) {
    let segments = [],
      output = `${this.name}`;
    if (this.unit && showUnit) {
      segments.push("Unit " + this.unit);
    }
    else if (this.floor && showFloor) {
      segments.push("Floor " + this.floor);
    }
    if (segments.length) {
      output += " (";
      output += segments.join(", ");
      output += ")";
    }
    return output;
  }

  get $displayString() {
    return this.$getDisplayString({showFloor:!this.unit})
  }

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  get $area() {
    return this.area
      ? getObjectInArrayById(store.state.areas, this.area.id, null)
      : null;
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building.id, null)
      : null;
  }

  get $parent() {
    return this.$building || this.$site;
  }

  get $sources() {
    return this.id
      ? store.state.sources.filter((r) => r.room && r.room.id === this.id)
      : [];
  }

  get $hasSources() {
    return this.$sources.length > 0;
  }
}
