<template>
  <b-tooltip
    style="z-index: 10"
    type="is-danger"
    :label="message"
    class="pointer"
    @click.native="area.$goToTurnOffTrainingMode({next:$route.fullPath})"
  >
    <b-icon class="ml-1 mr-1" icon="exclamation-circle" type="is-danger" />
  </b-tooltip>
</template>

<script>
import Area from "../../../dataClasses/Area";

export default {
  name: "InTrainingMode",
  props: {
    next: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      default: "This area currently has sensors in training mode",
    },
    area: {
      type: Area,
      required: true,
    },
    tooltipActive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
