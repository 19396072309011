<template>
  <div class="tile dashboard-widget is-child is-short">
    <p class="widget-title">Site Location</p>
    <div class="widget-content">
      <site-display :value="site" show-address is-multiline />
    </div>
    <router-link class="widget-bottom-link" :to="{ name: 'administration' }">
      Site administration
    </router-link>
  </div>
</template>

<script>

export default {
  name: "SiteLocationTile",
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
