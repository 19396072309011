<template>
  <div>
    <p class="mb-5" v-if="alarm.status_message">
      <i>{{ alarm.status_message }}</i>
    </p>
    <p class="has-text-weight-bold">Affected devices:</p>
    <ul class="ml-4">
      <li v-for="device in alarm.health" :key="device.serial">
        <router-link
          :to="{
            name: 'go-to-device',
            params: {
              serial: device.serial || device.gateway_serial,
            },
          }"
        >
          <b>{{ device.serial || device.gateway_serial }}</b></router-link
        >
        : {{ device.message }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HealthDetailBlock",
  props: {
    alarm: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
