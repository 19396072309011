<template>
  <div></div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "RedirectToHome",
  computed: {
    ...mapState(["user"]),
  },
  created() {
    if (this.$route.path !== "/") {
      this.$handleError(
        "We could not find a page at url '" + this.$route.path + "'",
        null
      );
    }
    this.$router.push({
      name: "login",
      query: { nextUrl: this.$route.query.nextUrl },
    });
  },
};
</script>

<style scoped></style>
