<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Remove gateway {{ serial ? ": " + serial : "" }}
      </div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small"/>
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step">
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <b-field
                label="Please select the area where gateway to you'd like to remove is currently installed"
            />
            <building-radio
                :value="building"
                @input="handleBuildingSelection"
            />
          </div>
        </b-step-item>
        <b-step-item :step="1" label="Gateway" :type="stepType(1)">
          <transition name="slide">
            <div class="column is-offset-2-desktop is-8-desktop">
              <scan-or-select-device
                  :area="area"
                  ref="step1Scan"
                  device-state="device_installed"
                  :device-types="['gateway']"
                  label="Select which gateway to remove"
                  method-query-key="sm1"
                  show-locations
                  @input="
                  handleGatewaySelection($event);
                "
                  :selected-serial="serial"
                  @update:loading="componentLoading = $event"
              />
            </div>
          </transition>
        </b-step-item>

        <b-step-item :step="2" label="Confirm" :type="stepType(5)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps have missing or invalid data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div v-else>
            <div
                class="column is-8-desktop is-offset-2-desktop is-10-tablet is-offset-1-tablet"
            >
              <div class="box">
                <level :values="valuesToShow"/>
                <b-message
                    class="mt-3"
                    title="Warning: Sensors connected to this gateway"
                    type="is-danger"
                    has-icon
                    :closable="false"
                    :icon="iconFor('warning')"
                    v-if="
                    position &&
                    area &&
                    connectedSensors &&
                    (connectedSensors.length || loadingConnectedSensors)
                  "
                >
                  <p v-if="loadingConnectedSensors" class="has-text-centered">
                    <b-icon icon="sync" class="fa-spin" size="is-large"/>
                  </p>
                  <div v-else>
                    By removing this gateway, the following sensors will no
                    longer be active until connected to another gateway:
                    <ul style="list-style: disc">
                      <li
                          v-for="sensor in connectedSensors"
                          :key="sensor.serial"
                      >
                        <router-link
                            :to="{
                            name: 'devices:sensor-detail',
                            params: { serial: sensor.serial },
                          }"
                        >
                          {{ sensor.serial }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </b-message>
                <b-message
                    class="mt-3"
                    type="is-info"
                    :closable="false"
                    title="Remove gateway"
                    has-icon
                    icon="info-circle"
                >
                  <p class="is-size-6">
                    Click the "Confirm" button below then un-plug
                    <b>gateway {{ serial }}</b
                    >.
                  </p>
                </b-message>
              </div>
            </div>
          </div>
        </b-step-item>

        <template #navigation>
          <div class="has-text-centered">
            <forward-backward
                classes="float-right"
                :allow-forward="validate(step)"
                :confirm-loading="confirmLoading"
                confirm-type="is-warning"
                :min-step="stepArray[0]"
                :max-step="stepArray[stepArray.length - 1]"
                :step="step"
                @forward="goForward"
                @backward="goBackward"
                @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";

import iconFor from "../../icons";
import {initial} from "lodash";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import {getObjectInArrayById} from "../../scripts/filterHelpers";
import Level from "../../components/Level/Level";

import GatewayPosition from "../../dataClasses/GatewayPosition";

const stepArray = [0, 1, 2];

/**
 * Device centric action component to
 */
export default {
  name: "RemoveGateway",
  components: {
    BuildingRadio,
    ForwardBackward,
    Level,
    ScanOrSelectDevice,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {object,null}}}
   */
  data() {
    return {
      componentLoading: false,
      deviceType: "gateway",
      confirmLoading: false,
      // Inputs
      position: null,
      positionId: null,
      connectedSensors: [],
      loadingConnectedSensors: false,
    };
  },
  mounted() {
    if (this.serial) {
      this.handleGatewaySelection({serial: this.serial});
    }
    this.goToNextInvalidOrFinalStep();
  },
  computed: {
    canRemoveGateway() {
      return this.area && this.selectedDevice;
      //     this.area.gateways &&
      //     this.area.gateways.filter(
      //         d => d.serial===this.serial && d.connect_weights.filter(cw=>cw.weight > 0).length > 0
      //     ).length === 0
    },
    valuesToShow() {
      return [{label: "Gateway", value: this.serial}];
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    building: {
      get() {
        let buildingId = this.$route.query.building;
        return buildingId
            ? getObjectInArrayById(this.$store.state.buildings, buildingId, null)
            : null;
      },
      set(val) {
        if (val instanceof Object) {
          val = val.id;
        }
        this.queryReplace({building: val});
      },
    },
  },
  methods: {
    iconFor,
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return !!this.selectedDevice && this.canRemoveGateway;
      } else if (step === 2) {
        return (
            initial(stepArray).filter((_step) => !this.validate(_step)).length ===
            0
        );
      }
    },
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && building && this.building) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} gateway
     */
    handleGatewaySelection(gateway) {
      this.serial = gateway ? gateway.serial : null;
      setTimeout(() => {
        if (gateway && this.step === 1 && this.validate(1)) {
          this.goForward();
        }
      }, 100);
    },
    /**
     * Finalize installation
     */
    onComplete() {
      if (this.invalidSteps.length) {
        this.$handleError(
            "Please correct any steps with missing data or errors",
            "Please correct any steps with missing data or errors"
        );
        return;
      }
      this.confirmLoading = true;
      this.area
          .performAction("remove_devices", {
            devices: [this.toFSRef(this.selectedDevice)],
          })
          .then(() => this.reloadArea())
          .then(() => {
            this.$handleSuccess(`Gateway ${this.serial} has been removed`);
            if (this.$route.query.next) {
              this.$router.push(this.$route.query.next)
            } else {
              this.$router.push({
                name: "devices",
              });
            }
          })
          .catch((e) =>
              this.$handleError(
                  "Could not complete gateway removal; see console for details",
                  e
              )
          )
          .finally(() => (this.confirmLoading = false));
    },
  },
  watch: {
    selectedDevice: {
      immediate: true,
      handler(gateway) {
        if (gateway) {
          this.loading = true;
          const self = this;
          GatewayPosition.queryForSingle([["serial", "==", gateway.serial]])
              .then((obj) => {
                this.position = obj;
                this.positionId = obj.id;
                if (this.step === 1) {
                  this.goForward();
                }
                self.loadingConnectedSensors = true;
                obj
                    .$getConnectedSensors()
                    .then((positions) => (self.connectedSensors = positions))
                    .catch((e) =>
                        self.$handleSuccess("Could not load connected sensors", e)
                    )
                    .finally(() => (self.loadingConnectedSensors = false));
              })
              .catch((e) => {
                self.handleGatewaySelection(null);
                self.$handleError(e, e);
              })
              .finally(() => (self.loading = false));
        } else {
          this.position = null;
          this.positionId = null;
          this.connectedSensors = [];
        }
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}
</style>
