<template>
  <div
    :class="'card ml-0 mt-0 cardy ' + classes"
    :style="'width:' + this.modalWidth"
  >
    <div class="card-header">
      <div class="card-header-title">
        Viewing {{ category.replace("_", " ") }} files for
        <generic-display :value="object" />
      </div>
    </div>
    <div
      class="card-content"
      :style="'width:' + this.modalWidth + ' !important'"
    >
      <file-table
        :files="filesToShow"
        can-delete
        @file-deleted="fileRemoved($event)"
        @file-updated="fileUpdated($event)"
        :loading="loadingFiles"
      />
      <vue-dropzone
        v-if="canUpload && category"
        v-on:vdropzone-sending="sendingEvent"
        id="dropzone"
        ref="vueFileDropzone"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        @vdropzone-success="onSuccess($event)"
      >
        <p>
          Drop <b>{{ category.replace("_", " ") }}</b> files here to attach to:
          <generic-display
            :value="object"
            :show-link-icon="false"
            :show-tooltip="false"
            :show-training-mode-warning="false"
          />
        </p>
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import hasObjectWithFilesMixin from "../../mixins/hasObjectWithFilesMixin";
import deserializer from "../../apiClient/deserializer";

import FileTable from "../../components/Files/FileTable";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import trackWindowSize from "../../mixins/trackWindowSize";
import onScopeChangeMixin from "../../applications/Administration/View/onScopeChangeMixin";

const allCategories = ["document", "floor_plan", "install_picture"];

export default {
  name: "FileAttachmentCard",
  mixins: [hasObjectWithFilesMixin, onScopeChangeMixin, trackWindowSize],
  components: {
    FileTable,
    vueDropzone: vue2Dropzone,
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    modalWidth() {
      return "100%";
    },
  },
  methods: {
    onSuccess(successEvent) {
      let responseJson = JSON.parse(successEvent.xhr.response);
      let result = deserializer(responseJson.result);
      this.filesForObject.unshift(result);
      // add result to list
      this.$handleSuccess("File upload successful: " + result.metadata.filename);
      this.$refs.vueFileDropzone.removeFile(successEvent);
    },
  },
};
</script>

<style scoped>
.file-attach-card {
  width: auto !important;
}

.cardy {
  min-width: 80vw;
}
</style>
