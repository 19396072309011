import { is_equal_or_lower_in_hierarchy } from "../../store/shared/hierarchy";

class Roles {
  static valid_roles = ["viewer", "installer", "admin", "root"];

  constructor(roleData, validateOnInit) {
    if (!Array.isArray(roleData)) {
      throw "roleData must be array";
    } else {
      roleData.forEach((r) => {
        if (!Roles.isValidRoleEntry(r)) {
          throw `Invalid role entry: ${r}`;
        }
      });
      if (validateOnInit && Roles.hasOverlappingRoles(roleData)) {
        throw `Has overlapping roles: ${JSON.stringify(roleData)}`;
      }
    }
    this._roles_data = roleData;
  }

  /**
   * Static methods
   */

  static validates(roleData, editorRoleObject) {
    let asRoles = new Roles(roleData);
    let conditions = [asRoles.isValid(editorRoleObject)];
    return conditions.every((c) => c);
  }

  static hasOverlappingRoles(roleData) {
    let results = roleData.filter(function (eachRoleEntry) {
      let hasDupePaths =
        roleData.filter((r) => r.access === eachRoleEntry.access).length > 1;
      let subEntries = roleData.filter(
        (r) =>
          r.access &&
          r.access.startsWith(eachRoleEntry.access) &&
          r.access !== eachRoleEntry.access
      );
      let subEntriesHaveSameOrHigherLevel = subEntries.every((subEntry) =>
        Roles.atOrAbove(eachRoleEntry.role).slice(1).includes(subEntry.role)
      );
      return hasDupePaths || !subEntriesHaveSameOrHigherLevel;
    });
    return results.length > 0;
  }

  static isValidRoleEntry(roleEntry) {
    let conditions = [
      Roles.isValidPath(roleEntry.access),
      Roles.isValidRole(roleEntry.role),
      (roleEntry.role === "root" && roleEntry.access === "|root") ||
        (roleEntry.role !== "root" && Roles.pipesInPath(roleEntry.access) > 1),
    ];
    return conditions.every((cond) => cond === true);
  }

  static atOrAbove(r) {
    Roles.isValidRole(r, true);
    return Roles.valid_roles.slice(Roles.valid_roles.indexOf(r));
  }

  static atOrBelow(r) {
    Roles.isValidRole(r, true);
    return Roles.valid_roles.slice(0, Roles.valid_roles.indexOf(r) + 1);
  }

  static isValidRole(r, throwIfInvalid) {
    let isValid = Roles.valid_roles.includes(r);
    if (throwIfInvalid && !isValid) {
      throw `Invalid role: ${r}`;
    }
    return isValid;
  }

  static satisfies(r1, r2) {
    Roles.isValidRole(r1, true);
    Roles.isValidRole(r2, true);
    return Roles.atOrAbove(r2).includes(r1);
  }

  static pipesInPath(accessPath) {
    return typeof accessPath === "string" && accessPath.split("|").length - 1;
  }

  static isValidPath(accessPath) {
    return (
      typeof accessPath === "string" &&
      accessPath
        .split("|")
        .slice(1)
        .every((segment) => segment.length > 0)
    );
  }

  /**
   * Properties
   */

  get hasSingleScopeAtSiteOrLowerLevel() {
    return (
      !this.is_root &&
      this._roles_data.filter((r) => Roles.pipesInPath(r.access) >= 4)
        .length === 1
    );
  }

  get is_root() {
    return this.isAtLeast("root");
  }

  get is_admin() {
    return this.isAtLeast("admin");
  }

  get is_installer() {
    return this.isAtLeast("installer");
  }

  get is_viewer() {
    return this.isAtLeast("viewer");
  }

  get paths() {
    return this._roles_data.map((r) => r.access);
  }

  get roleData() {
    return this._roles_data;
  }

  /**
   * Instance methods
   */

  /**
   * Given an idString, looks for the shortest matching role-access-path.
   * Used to auto-load scopes in some scenarios (such as visiting admin pages)
   * @param idString
   * @return {string|null}
   */

  getHighestLevelScopeToLoadFromIdString(idString) {
    let segments = idString.split("|").filter((seg) => !!seg);
    if (segments.length < 2) {
      throw "Must provide an idString above root; got `" + idString + "`";
    }
    if (this.is_root) {
      // then load the customer for this
      return "|" + segments[0] + "|" + segments[1];
    }
    let matches = this._roles_data.filter(
      (r) => idString && idString.startsWith(r.access)
    );
    if (matches && matches.length) {
      return matches[0].access;
    }
    return null;
  }

  /**
   *
   * @returns Boolean
   */
  isValid(editorRoles, throwIfInvalid) {
    let self = this,
      errorMessage = null,
      passesAllTests = self._roles_data.every((r) => {
        if (!Roles.isValidRoleEntry(r)) {
          errorMessage = `Invalid role entry: ${r}`;
        }
        if (Roles.hasOverlappingRoles(self._roles_data)) {
          errorMessage = `Has overlapping roles: ${self._roles_data}`;
        }
        if (!editorRoles || !editorRoles.canEditPath(r.access)) {
          errorMessage = `You do not have permission to update roles`;
        }
        if (throwIfInvalid && errorMessage) {
          throw errorMessage;
        }
        return !errorMessage;
      });
    if (throwIfInvalid && !passesAllTests) {
      throw errorMessage;
    }

    return passesAllTests;
  }

  addRole(role) {
    if (!Roles.isValidRoleEntry(role)) {
      throw `Invalid role: ${role}`;
    }
    if (
      this._roles_data.filter((r) =>
        is_equal_or_lower_in_hierarchy(r.access, role.access)
      ).length > 0
    ) {
      throw `There is already a role entry at path "${role.access}"`;
    }
    this._roles_data.push(role);
  }

  removeRoleAtPath(path) {
    this._roles_data = this._roles_data.filter((r) => r.access !== path);
  }

  /**
   * Returns whether a given role level is met or exceeded by this object.
   * @param role_level
   * @returns {Boolean}
   * @example
   *
   * >>> let root = new Roles([{role:"root",path:"|root"}]);
   * >>> root.isAtLeast("admin")
   * true
   * >>> let admin = new Roles([{role:"admin",path:"|root|admin"}]);
   * >>> admin.isAtLeast("root")
   * false
   *
   */
  isAtLeast(role_level) {
    Roles.isValidRole(role_level, true);
    return this._roles_data.some((r) =>
      Roles.atOrAbove(role_level).includes(r.role)
    );
  }

  match(role, path) {
    Roles.isValidRole(role, true);

    return this._roles_data.some(
      (r) =>
        Roles.satisfies(r.role, role) &&
        is_equal_or_lower_in_hierarchy(r.access, path)
    );
  }

  roleAtPath(path) {
    let matches = this._roles_data
      .filter((d) => d.access === path)
      .sort((a, b) => Roles.satisfies(a, b));
    return matches.length >= 1 ? matches[0].role : null;
  }

  /**
   * Can be used to get all access paths that match a given level for Roles object.
   * @param role_level
   * @returns {Boolean}
   * @example
   *
   * >>> let userRoles = new Roles([
   * >>>    {role:"admin",access:"|root|Customer A"},
   * >>>    {role:"installer",access:"|root|Customer B"},
   * >>>    {role:"viewer",access:"|root|Customer C"},
   * >>> ]);
   * >>> userRoles.access_paths_with_level("admin")
   * ["|root|Customer A"]
   * >>> userRoles.access_paths_with_level("installer")
   * ["|root|Customer A", "|root|Customer B"]
   * >>> userRoles.access_paths_with_level("viewer")
   * ["|root|Customer A", "|root|Customer B", "|root|Customer C"]
   */
  access_paths_with_level(role_level) {
    let self = this;
    return self
      .filter_by_minimum_level(role_level)
      .map((role_obj) => role_obj.access);
  }

  filter_by_minimum_level(role_level, path) {
    let self = this;
    Roles.isValidRole(role_level, true);

    function _matches_level(role_record) {
      let validOrNoPath = !path
        ? true
        : Roles.satisfies(role_record.role, role_level) &&
          is_equal_or_lower_in_hierarchy(path, role_record["access"]);
      return Roles.satisfies(role_record.role, role_level) && validOrNoPath;
    }

    return self._roles_data.filter((r) => _matches_level(r));
  }

  canEditPath(path) {
    let required_role = path === "|root" ? "root" : "admin";
    return path && this.match(required_role, path);
  }
}

export default Roles;
