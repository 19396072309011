import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import { get } from "lodash";
import store from "../store";

export default class PipeLocation extends FirestoreDataClass {
  static collectionName = "pipe_locations";
  static defaultDisplayString = "Pipe location";

  get $customer() {
    return this.get("$subcustomer.$site");
  }

  get $subcustomer() {
    return this.get("$site.$subcustomer");
  }

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  get $area() {
    return get(this.$building, "$area", null);
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building.id, null)
      : null;
  }

  get $room() {
    return this.room
      ? getObjectInArrayById(store.state.rooms, this.room.id, null)
      : null;
  }

  get $floor() {
    return this.get("$room.floor", null);
  }
}
