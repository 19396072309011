<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        Saved&nbsp;<span v-if="category">{{capitalizeFirstLetter(category)}}&nbsp;</span>Reports
      </div>
      <div class="card-header-icon">
        <b-icon :icon="iconFor('reports')" />
      </div>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <router-link
            :to="{
              name: 'utilities:pull-reports',
              query: { reportCategory: category }
            }"
            >Click here</router-link
          >
          to pull custom reports.
        </div>
      </div>
      <div
        class="columns"
        v-if="selectedSite && Object.keys(selectedSite).length"
      >
        <div class="column">
          <b-field label="Date range">
            <date-range-select v-model="dateRange" :disabled="loading" />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Report types">
            <b-dropdown :triggers="['hover', 'click']">
              <template #trigger>
                <b-button
                  :label="
                    selectedReportNames.length === reportNames.length
                      ? 'Show all'
                      : `${selectedReportNames.length} selected`
                  "
                  type="is-primary"
                  icon-right="chevron-down"
                />
              </template>
              <div class="padded">
                <b-field v-for="reportName in reportNames" :key="reportName">
                  <b-checkbox
                    class="is-fullwidth"
                    :native-value="reportName"
                    :disabled="loading"
                    v-model="selectedReportNames"
                  >
                    {{ reportName }}
                  </b-checkbox>
                </b-field>
              </div>
            </b-dropdown>
          </b-field>
        </div>
      </div>
      <loading-chart-card v-if="loading" />
      <div v-else-if="results.length">
        <report-table :rows="results" :loading="loading" />
        <p class="has-text-centered mt-5">
          <i class="m-3">
            {{ results.length }} result{{ results.length === 1 ? "" : "s" }}
            shown
          </i>
          <b-icon
            icon="sync"
            size="is-small"
            :class="{ 'fa-spin': loading, pointer: true }"
            @click.native="fetch"
          />
        </p>
      </div>
      <p v-else>
        <i v-if="selectedSite">No results found</i>
        <i v-else>Please select a site</i>
      </p>
    </div>
  </div>
</template>

<script>
import iconFor from "../../icons";
import LoadingChartCard from "../../components/Skeletons/LoadingChartCard";
import ReportTable from "../../tables/ReportTable";

import DateRangeSelect from "../../components/Selects/DateRangeSelect";

const paginateBy = 250;

const fetchReportCategories = (apiClient) => {
  let headers = {},
    url = `${process.env.VUE_APP_AQUACLOUD_URL}/reporting/report-schemas?format=categories`;
  headers[apiClient.authHeaderKey] = apiClient.authHeaderValue;
  return fetch(url, {
    headers,
  });
};

export default {
  name: "Reports",
  props: {
    /**
     * I.e. a solution category from which to winnow types
     */
    category: {
      type: String,
      required: true,
      default: "system",
    },
  },
  components: {
    // SiteTable,
    // ReportForm,
    DateRangeSelect,
    LoadingChartCard,
    ReportTable,
  },
  mounted() {
    let self = this;
    self.loading = true;
    this.fetchCategories()
      .then((response) => response.json())
      .then((json) => {
        self.loading = false;
        self.categories = json.entries.map((e) => e.category);
        self.allReportNames = [
          ...new Set(
            json.entries.map((e) => e.reports.map((ee) => ee.name)).flat()
          ),
        ];
        let categoryToReportNames = {};
        json.entries.forEach((entry) => {
          categoryToReportNames[entry.category] = entry.reports.map(
            (e) => e.name
          );
        });
        self.categoryToReportNames = categoryToReportNames;
        self.selectedReportNames = self.categoryToReportNames[self.category];
      })
      .catch((e) => {
        self.loading = false;
        self.$handleError("Could not fetch report metadata", e);
      });
  },
  computed: {
    reportNames() {
      return this.categoryToReportNames && this.category
        ? this.categoryToReportNames[this.category] || []
        : [];
    },
    selectedSite() {
      return this.$store.getters.site;
    },
    selectedReportNames: {
      set: function (val) {
        this.queryPush({ reportNames: val });
      },
      get: function () {
        let isInQuery =
          this.$route.query.reportNames && this.$route.query.reportNames.length;
        return isInQuery ? this.$route.query.reportNames : this.reportNames;
      },
    },
    idStrStart() {
      return this.$store.state.selectedScopeIdStr;
    },
    idStrEnd() {
      let it = this.$store.state.selectedScopeIdStr;
      if (!it || !it.length) {
        return;
      }
      let incrementedLastChar = String.fromCharCode(
        it.charCodeAt(it.length - 1) + 1
      );
      return it.slice(0, -1) + incrementedLastChar;
    },
    filters() {
      if (
        !this.selectedSite ||
        !this.selectedReportNames ||
        !this.selectedReportNames.length
      ) {
        return null;
      }
      return [
        ["timestamp", ">=", this.dateRange.startDate],
        ["timestamp", "<=", this.dateRange.endDate],
        ["parameters.report_name", "in", this.selectedReportNames],
        ["for_object", "==", this.toFSRef(this.selectedSite)],
      ];
    },
    queryParams() {
      return {
        order_field: "timestamp",
        direction: "desc",
        paginate_by: paginateBy,
        page: this.page,
      };
    },
    outOfResults() {
      return this.results.length < paginateBy;
    },
  },
  data() {
    let today = new Date(),
      oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);
    return {
      dateRange: {
        startDate: oneWeekAgo,
        endDate: today,
      },
      loading: false,
      loadingClassifications: false,
      results: [],
      page: 1,

      // TODO: fetch!
      allReportNames: [],
      categories: [],
      categoryToReportNames: [],
    };
  },
  methods: {
    iconFor,
    fetchCategories() {
      return fetchReportCategories(this.$client);
    },
    fetch() {
      let self = this;
      if (!self.filters || !self.filters.length) {
        self.results = [];
        return;
      }
      self.loading = true;
      self.$dataClasses.Report.query(self.filters, self.queryParams)
        .then((results) => (self.results = results))
        .catch((e) => self.$handleError(e, e))
        .finally(() => (self.loading = false));
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetch();
        }
      },
    },
  },
};
</script>

<style scoped>
.padded {
  padding: 15px;
}
</style>
