<template>
  <div class="mb-3">
    <b-field label="Area" v-if="buildingEditable" :horizontal="horizontal">
      <b-select
        :disabled="disabled"
        :icon="iconFor('building')"
        v-model="buildingId"
        :expanded="expanded"
      >
        <option :value="null">Not part of an area</option>
        <option
          v-for="building in buildings"
          :key="building.id"
          :value="building.id"
          :class="{ 'is-italic-option': building.name === '__OPEN_MODAL__' }"
        >
          {{ !isValue(building.id) ? " - " + building.name : building.name }}
        </option>
      </b-select>
      <add-building-modal
        v-if="allowAdd"
        :parent="site"
        v-model="buildingModalActive"
      />
    </b-field>
    <b-field v-else label="Area" :horizontal="horizontal">
      <b-input
        :value="selectedBuilding ? selectedBuilding.name : 'None selected'"
        style="max-width: 80%"
        disabled
      />
    </b-field>
    <b-field
      label="Room"
      :horizontal="horizontal"
      :type="roomErrorMessage ? 'is-danger' : null"
      :message="roomErrorMessage"
    >
      <b-select
        :icon="iconFor('room')"
        v-model="roomId"
        :expanded="expanded"
        :disabled="disabled"
      >
        <option :value="null">Not part of a room</option>
        <option
          :class="{ 'is-italic-option': room.name === '__OPEN_MODAL__' }"
          v-for="room in rooms"
          :key="room.id"
          :value="room.id"
        >
          {{ !isValue(room.id) ? " - " + room.name : room.$displayString }}
        </option>
      </b-select>
      <add-room-modal
        v-if="allowAdd"
        :parent="selectedBuilding"
        v-model="roomModalActive"
        @saved="roomId = $event.id"
      />
    </b-field>
    <slot></slot>
    <b-field
      label="Installation notes"
      v-if="showNotes"
      :horizontal="horizontal"
    >
      <b-input
        :disabled="disabled"
        v-model="installationNotesInner"
        type="textarea"
        maxlength="80"
        rows="2"
        @keypress.native.enter="$event.preventDefault()"
        @keyup.native.enter="$event.preventDefault()"
      />
    </b-field>
  </div>
</template>

<script>
import iconFor from "../../icons";
import { getObjectInArrayById } from "../../scripts/filterHelpers";
import AddBuildingModal from "../../components/Admin/Buildings/BuildingModal";
import AddRoomModal from "../../components/Admin/Rooms/AddRoomModal";

export default {
  name: "SelectBuildingAndRoom",
  components: {
    AddBuildingModal,
    AddRoomModal,
  },
  data() {
    return {
      buildingId: this.selectedBuildingId,
      roomId: this.selectedRoomId,
      buildingModalActive: false,
      roomModalActive: false,
      installationNotesInner: this.installationNotes,
    };
  },
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    buildingEditable: {
      type: Boolean,
      default: true,
    },
    showNotes: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      required: true,
    },
    selectedBuildingId: {
      type: String,
      required: false,
      default: null,
    },
    selectedRoomId: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    installationNotes: {
      type: String,
      required: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    roomErrorMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    buildings() {
      return [{ id: "__OPEN_MODAL__", name: "Add new area" }].concat(
        this.$store.state.buildings
          .filter((b) => b.site.id === this.site.id)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    },
    selectedBuilding() {
      return this.buildingId
        ? getObjectInArrayById(
            this.$store.state.buildings,
            this.buildingId,
            null
          )
        : {};
    },
    selectedRoom() {
      return this.roomId
        ? getObjectInArrayById(this.$store.state.rooms, this.roomId, null)
        : {};
    },
    rooms() {
      let self = this,
        startingRooms = this.isValue(this.buildingId)
          ? [
              {
                id: "__OPEN_MODAL__",
                name: "Add new room",
              },
            ]
          : [],
        filteredRooms = self.$store.state.rooms.filter((r) => {
          if (self.buildingId) {
            return r.building && r.building.id === self.buildingId;
          }
          return r.site.id === self.site.id;
        });
      return startingRooms.concat(
        filteredRooms.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    },
    toEmit() {
      let _ = {
        site: this.site ? this.toFSRef(this.site, "sites") : null,
        roomId: this.roomId,
        buildingId: this.buildingId,
        room: this.roomId ? this.toFSRef(this.selectedRoom, "rooms") : null,
        building: this.buildingId
          ? this.toFSRef(this.selectedBuilding, "buildings")
          : null,
      };
      if (this.showNotes) {
        _["installation_notes"] = this.installationNotesInner;
      }
      return _;
    },
  },
  mounted() {
    this.onChange();
  },
  methods: {
    iconFor,
    isValue(val) {
      return val && val !== "__OPEN_MODAL__";
    },
    onBuildingIDChange(val) {
      if (!val) {
        this.roomId = null;
      }
      let building = val
        ? getObjectInArrayById(this.buildings, val, null)
        : null;
      this.$emit("update:building-id", this.buildingId);
      this.$emit("update:building", building);
      this.onChange();
    },
    onRoomIDChange(val) {
      let room = val ? getObjectInArrayById(this.rooms, val, null) : null;
      if (room && room.building && !this.buildingId) {
        this.buildingId = room.building.id;
      }
      this.$emit("update:room-id", this.roomId);
      this.$emit("update:room", room);
      this.onChange();
    },
    onChange() {
      this.$emit("input", this.toEmit);
    },
  },
  watch: {
    selectedBuildingId: {
      handler(val) {
        this.buildingId = val;
      },
    },
    selectedRoomId: {
      handler(val) {
        this.roomId = val;
      },
    },
    buildingId: {
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        if (val === "__OPEN_MODAL__") {
          this.buildingModalActive = true;
          this.buildingId = null;
          this.onBuildingIDChange(null);
        } else {
          this.onBuildingIDChange(val);
        }
      },
    },
    roomId: {
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        if (val === "__OPEN_MODAL__") {
          this.roomModalActive = true;
          this.roomId = null;
          this.onRoomIDChange(null);
        } else {
          this.onRoomIDChange(val);
        }
      },
    },
    installationNotesInner: {
      handler(val) {
        this.$emit("update:installation-notes", val);
        this.onChange();
      },
    },
  },
};
</script>

<style scoped></style>
