import { get } from "lodash";
import iconFor from "../icons";
import toId from "../appLevelFunctions/toId";

/**
 *
 * @param {Array.<Object>} arr
 * @param {String} fieldName; Can be dot-path (uses lodash.get)
 * @returns {Array.<{property: string, count: Number, value: any}>}
 */
export function countPropValuesForObjectsInArray(arr, fieldName) {
  let allValues = new Set(
    arr.map((el) => {
      return _.get(el, fieldName);
    })
  );
  return Array.from(allValues).map((val) => {
    return {
      property: fieldName,
      value: val,
      count: arr.filter((el) => get(el, fieldName) === val).length,
    };
  });
}

export function getObjectInArrayById(arrayOfObjects, objectOrId, defaultIfNone = null) {
  if (!objectOrId) {
    return defaultIfNone;
  }
  if (typeof objectOrId === "string" && objectOrId.includes("/")) {
    let splitComponents = objectOrId.split("/");
    objectOrId = splitComponents[splitComponents.length - 1];
  } else if (typeof objectOrId === "object") {
    objectOrId = objectOrId.id;
  }
  let matches = arrayOfObjects.filter((obj) => obj.id === objectOrId);
  if (matches.length > 1) {
    throw `Multiple matches with id ${objectOrId}`;
  } else if (
    objectOrId &&
    matches.length !== 1 &&
    typeof defaultIfNone === "undefined"
  ) {
    console.trace({ message: "Not found", id: objectOrId });
    throw `Could not find object with id ${objectOrId}`;
  } else if (matches.length === 1) {
    return objectOrId ? matches[0] : defaultIfNone;
  }
  return defaultIfNone;
}

export function getChildrenOfId(arrayOfObjects, parentId, defaultIfNone) {
  return getChildrenOfIdByKey(
    arrayOfObjects,
    parentId,
    defaultIfNone,
    "parent"
  );
}

export function getChildrenOfIdByKey(
  arrayOfObjects,
  parentId,
  defaultIfNone,
  key
) {
  if (!defaultIfNone) {
    defaultIfNone = [];
  }
  let results = arrayOfObjects.filter((obj) => {
    let val = get(obj, `${key}.id`, {});
    return val === parentId;
  });
  return results.length ? results : defaultIfNone;
}

/**
 * Get ancestors array for display
 *
 * @param {Array.<{object: Object, title: String, endpointName: String}>} fromObjsArray
 * [{
 *   from: arrayToPullFrom,
 *   title: "Generic Name to display:"
 *   endpointName: "administration:view-something",
 * }... ]
 *
 * @return
 */
export function getAncestors(fromObjsArray) {
  let ancestors = [];
  for (const ancObj of fromObjsArray) {
    const { endpointName, object, title } = ancObj;
    if (ancObj.object) {
      let titleForIcon = title.split("/")[0].replace("-", "_").toLowerCase();
      ancestors.push({
        title: title,
        object: object,
        icon: iconFor(titleForIcon),
        endpoint: { name: endpointName, params: { id: object.id } },
      });
    }
  }
  return ancestors;
}
