<template>
  <div :class="_classes">
    <slot>
      {{ value }} second{{ value !== 1 ? 's' : '' }}
    </slot>
    <slot name="icon">
      <b-icon :icon="icon" :type="`is-${qualityRating}`"/>
    </slot>
    <slot name="message">
      {{ message }}
    </slot>
  </div>
</template>

<script>
import iconFor from "../../icons";
import {floorToValue} from "../../scripts/numericFunctions";


const qualityBySeconds = {
      0: 'low-quality',
      1: 'medium-quality',
      30: 'high-quality',
    },
    messageBySeconds = {
      0: 'Needs training',
      1: 'Additional training suggested',
      30: 'Good level of training'
    };

export default {
  name: "GroundTruthAmount",
  props: {
    /**
     * Seconds of ground truth
     */
    value: {
      type: Number,
      default: 0
    },
    classes: {
      type: String,
      default: 'ground-truth-wrapper'
    },
  },
  computed: {
    qualityRating() {
      let q = floorToValue(this.value, Object.keys(qualityBySeconds).map(i => parseInt(i)));
      return qualityBySeconds[q];
    },
    icon() {
      return iconFor(this.qualityRating);
    },
    _ratingClass() {
      return `has-text-${this.qualityRating}`;
    },
    _classes() {
      return `${this._ratingClass} ${this.classes}`;
    },
    message() {
      return messageBySeconds[floorToValue(this.value, Object.keys(messageBySeconds).map(i => parseInt(i)))]
    }
  }
}
</script>

<style scoped>

</style>