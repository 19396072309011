import FirestoreDataClass from "./_baseClasses";
import router from "../router";
import Area from "./Area";

export default class Sensor extends FirestoreDataClass {
  static collectionName = "sensors";

  get $qrCodeUrl() {
    if (!this.serial) {
      return null;
    }
    return (
      this.constructor.$client.basePath +
      "/devices/qr_code/?serial=" +
      this.serial
    );
  }

  get $deviceType() {
    return "sensor";
  }

  $goToAddSensor(building) {
    router.push({
      name: "add-sensor",
      query: {
        building: building ? building.id : null,
        serial: this.serial,
      },
    });
  }

  /**
   * @returns {boolean}
   */
  get $canStartAutothresholdForSingleSensor() {
    return true;
  }

  /**
   *
   * @param {Area} area
   * @returns {Promise<Object|Error>}
   */
  $startAutothresholdForSingleSensor(area) {
    if (!(area instanceof Area)) {
      throw "Did not receive an Area instance";
    }
    return area.$startAutoThreshold({
      devices: [this.$FSRef],
    });
  }
}
