<template>
  <div class="columns directory-tiles is-multiline">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DirectoryTilesWrapper",
};
</script>

<style scoped></style>
