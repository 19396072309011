<template>
  <div>
    <b-tag
      v-for="role in rolesArray"
      :key="role.access"
      :closable="deletable"
      @close="close($event)"
    >
      {{ getRoleDisplay(role.role) }}:{{ getAccessDisplay(role.access) }}
    </b-tag>
  </div>
</template>

<script>
export default {
  name: "RoleTags",
  props: {
    rolesArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessPathToObjs: {},
    };
  },
  methods: {
    close($evt) {
      this.$emit("close", $evt);
    },
    getAccessDisplay(accessPath) {
      let storeObj = this.$store.getters.objectByIDString(accessPath),
        _type = this.$store.getters.objectTypeByIDString(accessPath);
      if (!storeObj) {
        storeObj = { name: "Unknown" };
      }
      return _type === "root" ? "(Root)" : `${storeObj.name} (${_type})`;
    },
    getRoleDisplay(role) {
      return this.capitalizeFirstLetter(role);
    },
  },
};
</script>

<style scoped></style>
