<template>
  <loading-card/>
</template>

<script>

import LoadingCard from "../../components/Skeletons/LoadingCard";
import {getDeviceTypeFromSerial} from "../../scripts/deviceQrCodes";

export default {
  name: "RedirectToDevice",
  components: {
    LoadingCard,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    serial: {
      type: String,
      required: true,
    },
  },
  created() {
    this.goToDestination();
  },
  computed: {
    deviceType() {
      try {
        return getDeviceTypeFromSerial(this.serial);
      } catch (e) {
        this.$handleError(e);
        return null;
      }
    },
  },
  methods: {
    goToDestination() {
      setTimeout(function () {
        if (this.$route.name !== 'go-to-device') {
          return
        }
        if (!this.deviceType) {
          this.$router.go(-1);
        } else {
          this.$router.replace({
            name: `devices:${this.deviceType}-detail`,
            params: {serial: this.serial},
          }).catch(()=>{});
        }
      }.bind(this), 1000)
    },
  },
};
</script>

<style scoped></style>
