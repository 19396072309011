<template>
  <modal-with-form
    obj-display-name="Sub-Customer/Division"
    :allow-delete="$store.state.user_roles && $store.state.user_roles.is_admin"
    :allow-save="$store.state.user_roles && $store.state.user_roles.is_admin"
    :can-submit="isValid"
    :class="stateVarName + '-modal'"
    :full-page="false"
    :id="id"
    :inputs="inputs"
    :object-data="objectData"
    :state-var-name="stateVarName"
    :value="isActive"
    @closed="isActive = false"
    @input="activeChanged($event)"
    @saved="saved($event)"
    @deleted="deleted($event)"
  >
    <sub-customer-form
      v-model="inputs"
      :parent="toFSRef(parent, 'customers')"
      :id="id"
      @validates="validChanged($event)"
    ></sub-customer-form>
  </modal-with-form>
</template>

<script>
import SubCustomerForm from "./SubcustomerForm";
import ModalWithForm from "../generic/ModalWithForm";
import { getObjectInArrayById, getRef } from "../../../scripts/filterHelpers";
import { cloneDeep } from "lodash";

export default {
  name: "AddSubcustomerModal",
  components: { SubCustomerForm, ModalWithForm },
  computed: {
    objectData() {
      let parent = cloneDeep(this.parent);
      return this.id
        ? Object.assign(
            { parent: parent },
            getObjectInArrayById(this.$store.state.subcustomers, this.id)
          )
        : {
            parent: parent,
          };
    },
  },
  data() {
    let self = this;
    let parent = self.parent ? self.toFSRef(self.parent, "customers") : null;
    return {
      inputs: this.id
        ? Object.assign(
            { parent: parent },
            getObjectInArrayById(this.$store.state.subcustomers, this.id)
          )
        : { parent: parent },
      isValid: false,
      isActive: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
    },
    stateVarName: {
      type: String,
      default: "subcustomers",
    },
  },
  methods: {
    validChanged(valid) {
      this.isValid = valid;
    },
    activeChanged(val) {
      this.$emit("input", this.isActive);
    },
    saved(val) {
      this.$emit("saved", val);
      if (!this.id) {
        this.inputs = {
          parent: this.toFSRef(this.parent, "customers"),
        };
      }
    },
    deleted(val) {
      this.$emit("deleted", val);
    },
  },
  watch: {
    isActive: {
      handler(val) {
        this.activeChanged(val);
      },
    },
    value: {
      handler(val) {
        this.isActive = val;
      },
    },
  },
};
</script>

<style scoped></style>
