var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"well"},[_c('list-or-query-result-input',{ref:"resultInput",attrs:{"data-class":_vm.dataClass,"query-field":"name","paginate-by":25,"paginated":"","base-queries":[
      [
        'bundle_state.event_type',
        'in',
        _vm.viewRetired
          ? ['bundle_retired']
          : ['bundle_available', 'bundle_shipped'],
      ],
    ]},on:{"loading":function($event){_vm.loading = $event},"updated":function($event){_vm.results = $event}}},[_c('div',{staticClass:"level-item"},[_c('b-field',[_c('b-button',{staticClass:"is-light",attrs:{"type":"is-primary","outlined":""},on:{"click":function($event){_vm.viewRetired = !_vm.viewRetired}}},[_vm._v(" "+_vm._s(_vm.viewRetired ? "List retired bundles" : "List non-retired bundles")+" ")])],1)],1)]),_c('bundle-table',{attrs:{"loading":_vm.loading,"rows":_vm.results,"view-on-click":false},on:{"select":function($event){return _vm.select($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }