import FirestoreDataClass from "./_baseClasses";

import Customer from "./Customer";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import store from "../store";


export default class SubCustomer extends FirestoreDataClass {
  static collectionName = "subcustomers";

  get $children() {
    if (!this.id) {
      return [];
    }
    return store.state.sites.filter(
      (s) => s.subcustomer && s.subcustomer.id === this.id
    );
  }

  get $customer() {
    let data = this.customer
      ? getObjectInArrayById(store.state.customers, this.customer.id, null)
      : null;
    return data ? new Customer(data) : null;
  }

  get $parent() {
    return this.$customer;
  }
}
