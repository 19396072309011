<template>
  <div>
    <div class="has-text-centered" v-if="!alarm || !$store.getters.loaded">
      <b-icon icon="sync" class="fa-spin" />
    </div>
    <div v-else>
      <p class="has-text-weight-bold" v-if="pipeLocation">Pipe location:</p>
      <p
        class="has-text-weight-bold"
        v-else-if="get(alarm, 'flow_data.source', null)"
      >
        Point-of-use:
      </p>
      <p class="has-text-weight-bold" v-else-if="alarm.room">Room:</p>
      <p class="has-text-weight-bold" v-else>Location:</p>
      <div class="ml-4">
        <source-display
          v-if="classifiedAsSource && classifiedAsSource.id"
          :value="classifiedAsSource"
          show-parents
        />
        <div
          v-else-if="
            !get(alarm, 'flow_data.identified') &&
            get(alarm, 'flow_data.source')
          "
        >
          <message-breadcrumb
            source-is-unrecognized
            :show-floors="showFloors"
            :source-id="toId(alarm.flow_data.source)"
            :pipe-location-id="pipeLocation ? toId(pipeLocation) : null"
            :show-tooltip="false"
            :is-detail="isDetail"
          />
        </div>
        <message-breadcrumb
          v-else
          :show-address="showAddress"
          :show-links="showLinks"
          :show-floors="showFloors"
          :area-id="alarm.area ? toId(alarm.area) : null"
          :pipe-location-id="pipeLocation ? toId(pipeLocation) : null"
          :room-id="alarm.room ? toId(alarm.room) : null"
          :source-id="toId(get(alarm, 'flow_data.source', {})) || null"
          :show-tooltip="false"
          :is-detail="isDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "lodash";
import MessageBreadcrumb from "./MessageBreadcrumb";
import isUserMessageMixin from "../../mixins/isUserMessageMixin";

export default {
  name: "AlarmBreadcrumbController",
  components: { MessageBreadcrumb },
  mixins: [isUserMessageMixin],
  props: {
    alarmObject: {
      type: Object,
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
    },
    showFloors: {
      type: Boolean,
      default: false,
    },
    showLinks: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    get,
  },
};
</script>

<style scoped></style>
