<template>
  <b-select :value="value" @input="$emit('input', $event)" :disabled="disabled">
    <option :value="null" style="font-style: italic">Please select</option>
    <option v-for="ms in measurementSystems" :value="ms.value" :key="ms.value">
      {{ ms.label }}
    </option>
  </b-select>
</template>

<script>
export const measurementSystems = [
  { label: "Metric/SI", value: "si" },
  { label: "US Customary", value: "us" },
];

export default {
  name: "SelectMeasurementSystem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      validator(val) {
        return !val || measurementSystems.map((ms) => ms.value).includes(val);
      },
    },
  },
  data() {
    return {
      measurementSystems,
    };
  },
};
</script>

<style scoped></style>
