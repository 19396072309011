<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Add sensor</div>
      <div class="card-header-icon">
        <b-icon v-if="anyLoading" icon="sync" class="fa-spin" size="is-small"/>
      </div>
    </div>
    <div class="card-content">
      <b-steps v-model="step">
        <b-step-item :step="0" label="Area" :type="stepType(0)">
          <div class="column is-offset-2-desktop is-8-desktop">
            <p class="is-size-5">
              Select the area where you will be adding a sensor
            </p>
            <building-radio
                :value="building"
                :filter-func="option => option.get('$area.$gatewayCount', 0) > 0"
                @input="handleBuildingSelection"
            >
            </building-radio>
          </div>
        </b-step-item>

        <b-step-item :step="1" label="Sensor" :type="stepType(1)">
          <transition name="slide">
            <div
                class="column is-offset-2-desktop is-8-desktop"
                v-if="
                !selectedDevice && (!positionLoading || selectedDeviceLoading)
              "
            >
              <p class="is-size-5">Which sensor would you like to install?</p>
              <scan-or-select-device
                  auto-open
                  v-if="area"
                  :area="area"
                  ref="step1Scan"
                  device-state="device_shipped"
                  :device-types="['sensor']"
                  label=""
                  method-query-key="sm1"
                  @input="handleSensorSelection"
                  :selected-serial="serial"
                  @update:loading="componentLoading = $event"
              />
            </div>
          </transition>
          <transition name="slide">
            <div class="has-text-centered" v-if="selectedDeviceLoading">
              <b-icon icon="sync" class="fa-spin" size="is-large"/>
            </div>
          </transition>

          <transition name="slide">
            <div v-if="selectedDevice && !selectedDeviceLoading" class="column has-text-centered">
              <div class="has-text-centered">
                <p class="is-size-5">
                  You have selected sensor <b>{{ serial }}</b>
                </p>
                <div>
                  <b-button
                      :disabled="selectedDeviceLoading"
                      icon-left="times"
                      outlined
                      size="is-small"
                      type="is-danger"
                      @click="uninstallSensor();goBackward();"
                  >
                    Change selected sensor
                  </b-button>
                </div>
              </div>
            </div>
          </transition>
        </b-step-item>

        <b-step-item :step="2" label="Gateway" :type="stepType(2)">
          <div class="column is-12">
            <transition name="slide">
              <div v-if="selectedDevice && !selectedDeviceLoading" class="column has-text-centered">
                <div class="has-text-centered">
                  <p class="is-size-5" >
                    You have selected sensor <b>{{ serial }}</b>
                  </p>
                  <div>
                    <b-button
                        :disabled="selectedDeviceLoading"
                        icon-left="times"
                        outlined
                        size="is-small"
                        type="is-danger"
                        @click="uninstallSensor();goBackward();"
                    >
                      Change selected sensor
                    </b-button>
                  </div>
                </div>
              </div>
            </transition>

            <transition name="slide">
              <div class="column is-12" v-if="!selectedGateway">
                <transition name="slide">
                  <div
                      class="has-text-centered"
                      v-if="
                      gatewayLoading ||
                      gatewayPositionLoading ||
                      positionLoading
                    "
                  >
                    <b-icon icon="sync" class="fa-spin" size="is-large"/>
                  </div>
                </transition>
                <transition name="slide">
                  <div
                      v-if="
                      !(gatewayLoading || gatewayPositionLoading) &&
                      !gatewaySerial
                    "
                  >
                    <p class="is-size-5 has-text-centered">
                      Which gateway should sensor <b>{{ serial }}</b> use for
                      communication?
                    </p>
                    <div class="zoom-85">
                      <scan-or-select-device
                          auto-open
                          :show-options="true"
                          :area="area"
                          ref="step1Scan"
                          device-state="device_installed"
                          :device-types="['gateway']"
                          :show-locations="true"
                          label=""
                          method-query-key="sm1"
                          @input="handleGatewaySelection"
                          :selected-serial="gatewaySerial"
                          @update:loading="componentLoading = $event"
                      />
                    </div>
                  </div>
                </transition>
              </div>
            </transition>
            <transition name="slide">
              <div v-if="selectedGateway" class="column has-text-centered">
                <b-message
                    v-if="
                    !positionLoading && !gatewayPositionLoading && !livePosition
                  "
                    title="Error loading sensor position"
                    type="is-danger"
                    has-icon
                    icon="exclamation-circle"
                    :closable="false"
                >
                  Warning: Could not load a position for sensor
                  <b>{{ serial }}</b
                  >.
                </b-message>
                <div class="has-text-centered" v-else>
                  <p class="is-size-5">
                    You have selected gateway <b>{{ gatewaySerial }}</b>
                  </p>
                  <div>
                    <b-button
                        icon-left="times"
                        outlined
                        size="is-small"
                        type="is-danger"
                        @click="gatewaySerial = null"
                    >
                      Change selected gateway
                    </b-button>
                  </div>
                </div>
                <transition name="slide">
                  <div
                      class="has-text-centered mt-3"
                      v-if="positionLoading || gatewayPositionLoading"
                  >
                    <b-icon icon="sync" class="fa-spin" size="is-large"/>
                  </div>
                </transition>
                <transition name="slide">
                  <div v-if="position && gatewayPosition" class="mt-6 mb-0">
                    <p class="is-size-5 has-text-centered">
                      Wake up your sensor and verify it's connection level.
                    </p>
                    <p class="has-text-centered">
                      When you are satisfied with the connection level, click
                      "Continue".
                    </p>
                    <div class="inline">
                      <live-sensor-display
                          :position="livePosition || position"
                          size="small"
                      />
                    </div>
                    <b-icon icon="arrow-right"/>
                    <div class="inline constrain">
                      <live-gateway-display
                          :serial="gatewaySerial"
                          :position="liveGatewayPosition || gatewayPosition"
                          top="74px"
                      />
                    </div>
                    <transition name="slide">
                      <div
                          class="column is-8-desktop is-offset-2-desktop"
                          v-if="connectionWarnings.length"
                      >
                        <b-message
                            type="is-danger"
                            has-icon
                            icon="exclamation-circle"
                            class="mt-3 has-text-justified has-text-left"
                        >
                          <div class="ml-6-tablet">
                            <b>Warnings:</b>
                            <transition-group tag="ul" class="ul-doink">
                              <li
                                  v-for="warning in connectionWarnings"
                                  :key="warning"
                              >
                                {{ warning }}
                              </li>
                            </transition-group>
                          </div>
                        </b-message>
                      </div>
                    </transition>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
        </b-step-item>
        <b-step-item :step="3" label="Location" :type="stepType(3)">
          <div class="columns">
            <div class="column">
              <transition name="slide">
                <p class="has-text-centered" v-if="checkingPLAssigned">
                  <b-icon icon="sync" class="fa-spin" size="is-large"/>
                </p>
              </transition>
              <transition name="slide">
                <b-message
                    type="is-warning"
                    title="Pipe location already assigned"
                    :closable="false"
                    has-icon
                    icon="exclamation-circle"
                    v-if="preAssignedPipeLocation"
                >
                  <div>
                    <p>
                      This sensor has previously been assigned to a pipe
                      location
                      <router-link
                          :to="{
                          name: 'administration:view-pipe-location',
                          params: { id: preAssignedPipeLocation.id },
                        }"
                      >
                        {{ preAssignedPipeLocation.name }}
                      </router-link>
                      .
                    </p>
                    <b-icon
                        v-if="unassigningPL"
                        icon="sync"
                        class="fa-spin"
                        size="is-small"
                    />
                    <p v-else>
                      To remove this assignment and set another pipe location,
                      click
                      <a @click="unassignPreAssignedPL()"> here </a>.
                    </p>
                  </div>
                </b-message>
              </transition>
            </div>
          </div>
          <transition name="slide">
            <div class="columns" v-if="position && !preAssignedPipeLocation">
              <div class="column">
                <pipe-location-table
                    can-add
                    :area="area"
                    :building="building"
                    :rows="
                    $store.state.pipe_locations.filter(
                      (pl) =>
                        !pl.probe &&
                        !pl.position &&
                        pl.building &&
                        pl.building.id === building.id
                    )
                  "
                    :loading="$store.state.fetching.pipe_locations"
                    :title="`Select or create a pipe location for sensor ${serial}`"
                    :selected-id="pipeLocationId"
                    :paginate_by="25"
                    @input="
                    $store.commit('add', {
                      name: 'pipe_locations',
                      result: $event,
                    });
                    pipeLocationId = $event ? $event.id : null;
                  "
                >
                  <template #post-name/>
                </pipe-location-table>
                <div class="is-pulled-right">
                  <b-icon
                      v-if="building"
                      icon="sync"
                      :class="{
                      'fa-spin': $store.state.fetching.pipe_locations,
                      pointer: true,
                    }"
                  />
                </div>
              </div>
            </div>
          </transition>
        </b-step-item>

        <b-step-item label="Floor-plan" optional :step="4" :type="stepType(4)">
          <transition name="slide">
            <aq-image-map-array
                @no-files="(step === 4 && lastStep <= 4) ? goForward() : null"
                v-if="building && position && step >= 4"
                :parent-object="building"
                :show-non-editable="false"
                :editable-entity-paths="editableEntityPaths"
            />
          </transition>
          <transition name="slide">
            <div
                class="has-text-centered"
                v-if="gatewayLoading || gatewayPositionLoading || positionLoading"
            >
              <b-icon icon="sync" class="fa-spin" size="is-large"/>
            </div>
          </transition>
          <transition name="slide">
            <p
                v-if="
                !(building && position) &&
                !(gatewayLoading || gatewayPositionLoading || positionLoading)
              "
            >
              <i>Please select a sensor and gateway.</i>
            </p>
          </transition>
        </b-step-item>
        <b-step-item :step="5" label="Confirm" :type="stepType(5)">
          <div v-if="invalidPreConfirmSteps && invalidPreConfirmSteps.length">
            The following steps are missing data:
            <ul>
              <li v-for="_step in invalidPreConfirmSteps" :key="_step">
                <a @click="step = _step">Step {{ _step }}</a>
              </li>
            </ul>
          </div>
          <div
              class="column is-6-desktop is-offset-3-desktop is-10-table is-offset-1-tablet"
              v-else
          >
            <div class="box">
              <p class="subtitle has-text-weight-bold">
                <b-icon
                    icon="check"
                    type="is-success"
                    v-if="!invalidSteps.length"
                />
                Add Sensor
              </p>
              <level :values="valuesToShow"/>
              <b-message
                  class="mt-6"
                  type="is-info"
                  :closable="false"
                  title="Confirm installation"
                  has-icon
                  icon="info-circle"
              >
                <p class="is-size-5">
                  Click below to finalize installation of sensor
                  <b>{{ serial }}</b
                  >.
                </p>
              </b-message>
            </div>
          </div>
        </b-step-item>

        <template #navigation>
          <div class="has-text-centered">
            <forward-backward
                classes="float-right"
                :allow-forward="validate(step)"
                :min-step="stepArray[0]"
                :max-step="stepArray[stepArray.length - 1]"
                :step="step"
                :confirm-loading="confirmLoading"
                @forward="goForward"
                @backward="goBackward"
                @complete="onComplete"
            />
          </div>
        </template>
      </b-steps>
    </div>
    <b-modal
        :active="areYouDoneModalActive"
        :can-cancel="false"
        has-modal-card
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <p class="is-size-5">Are you adding more sensors to this area?</p>
          <p>
            If not, you will be asked to begin an auto-threshold loop. This
            process is important for all newly installed devices.
          </p>
        </div>
        <div class="modal-card-foot">
          <div class="card-footer-item">
            <b-button expanded @click="modalYesAddMoreSensorsClicked">
              Yes
            </b-button>
          </div>
          <div class="card-footer-item">
            <b-button expanded @click="modalNoAddMoreSensorsClicked">
              No
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AqImageMapArray from "../../components/Wrappers/AqImageMapArray";
import BuildingRadio from "../../components/Selects/Radios/BuildingRadio";
import ForwardBackward from "./Generic/ForwardBackward";
import IsDeviceActionComponentMixin from "../../mixins/isDeviceActionComponentMixin";

import iconFor from "../../icons";
import {initial} from "lodash";
import PipeLocationTable from "../../tables/PipeLocationTable";
import ScanOrSelectDevice from "./StepComponents/ScanOrSelectDevice";
import Level from "../../components/Level/Level";


const stepArray = [0, 1, 2, 3, 4, 5];

/**
 * Device centric action component to
 */
export default {
  name: "AddSensor",
  components: {
    AqImageMapArray,
    BuildingRadio,
    ForwardBackward,
    Level,
    PipeLocationTable,
    ScanOrSelectDevice,
  },
  mixins: [IsDeviceActionComponentMixin],
  /**
   * @returns {{deviceType: string, componentLoading: boolean, selectedSensor: {object,null}, position: {Position,null}, pipeLocationId: {string,null}}}
   */
  data() {
    return {
      pipeLocation: null,
      deviceType: "sensor",
      areYouDoneModalActive: false,

      // Inputs
      position: null,
      livePositionRaw: null,
      gatewayPosition: null,
      liveGatewayPositionRaw: null,
      selectedGateway: null,

      unassigningPL: false,
      preAssignedPipeLocation: null,
      checkingPLAssigned: false,
      componentLoading: false,
      confirmLoading: false,
      gatewayLoading: false,
      gatewayPositionLoading: false,
      loadingPipeLocation: false,
      positionLoading: false,
    };
  },
  mounted() {
    this.$nextTick(this.onMount);
  },
  computed: {
    connectionWarnings() {
      let warnings = [];
      if (this.liveGatewayPosition && !this.liveGatewayPosition.$isHealthy) {
        warnings.push(
            `Gateway ${this.gatewaySerial} doesn't appear to be communicating properly.
            Ensure the gateway is powered on and connected to the internet.`
        );
      } else if (
          this.livePosition &&
          !this.livePosition.$categoricalSignalStrength
      ) {
        warnings.push(
            `Sensor ${this.serial} does not appear to be powered on.`
        );
      }
      return warnings;
    },
    livePosition() {
      return this.livePositionRaw ? new this.$dataClasses.Position(this.livePositionRaw) : null;
    },
    liveGatewayPosition() {
      return this.liveGatewayPositionRaw
          ? new this.$dataClasses.GatewayPosition(this.liveGatewayPositionRaw)
          : null;
    },
    valuesToShow() {
      return [
        {
          label: "Area",
          value: this.building ? this.building.name : "None",
        },
        {label: "Gateway", value: this.selectedGateway.serial},
        {label: "Sensor", value: this.serial},
      ];
    },
    /**
     *
     * @returns {number[]}
     */
    stepArray() {
      return stepArray;
    },
    /**
     *
     * @returns {boolean}
     */
    anyLoading() {
      return this.loading || this.componentLoading;
    },
    /**
     * @returns {string} Serial for selected sensor
     */
    gatewaySerial: {
      get() {
        return this.$route.query.gatewaySerial;
      },
      set(val) {
        setTimeout(() => this.queryReplace({gatewaySerial: val}), 25);
      },
    },
    pipeLocationId: {
      get() {
        return this.$route.query.pLId;
      },
      set(val) {
        setTimeout(() => this.queryReplace({pLId: val}), 25);
      },
    },
    /**
     * @returns {string|null} ID for building
     */
    buildingId() {
      return this.position ? this.position.get("building.id") : null;
    },
    /**
     * @returns {string}
     */
    notes: {
      get() {
        return this.$route.query.notes;
      },
      set(val) {
        this.queryReplace({notes: val});
      },
    },
    editableEntityPaths() {
      const eep = [];
      if (this.position) {
        eep.push(this.position.$path);
      }
      return eep;
    },
  },
  methods: {
    iconFor,
    onMount() {
      if (this.$store.state.buildings.length === 1) {
        this.handleBuildingSelection(this.$store.state.buildings[0])
      }
      if (this.serial) {
        this.handleSensorSelection({serial: this.serial, a: 1});
      }
      if (this.gatewaySerial) {
        this.handleGatewaySelection({serial: this.gatewaySerial});
      }
    },
    /**
     * @param {number} step
     * @returns {boolean}
     */
    validate(step) {
      if (step === 0) {
        return !!this.building;
      } else if (step === 1) {
        return !!this.serial && !!this.selectedDevice;
      } else if (step === 2) {
        return (
            !!this.gatewaySerial &&
            this.selectedGateway &&
            !!this.gatewayPosition &&
            !!this.position
        );
      } else if (step === 3) {
        return (
            !!this.building && this.pipeLocationId && !this.loadingPipeLocation
        );
      } else if (step === 4) {
        return true;
      } else if (step === 5) {
        return (
            initial(stepArray).filter((_step) => !this.validate(_step)).length ===
            0
        );
      }
    },
    /**
     * @param {object} building
     */
    handleBuildingSelection(building) {
      this.building = building;
      setTimeout(() => {
        if (this.step === 0 && building && this.building) {
          this.goForward();
        }
      }, 25);
    },
    /**
     * @param {object} sensor
     */
    handleSensorSelection(sensor) {
      this.serial = sensor ? sensor.serial : null;
    },
    handleGatewaySelection(gateway) {
      this.gatewaySerial = gateway ? gateway.serial : null;
    },
    modalNoAddMoreSensorsClicked() {
      // Go to auto-threshold dialog
      this.$router.push({
        name: "devices:area-detail",
        params: {areaId: this.area.id},
        query: {
          autoThresholdModal: true,
        },
      });
    },
    modalYesAddMoreSensorsClicked() {
      this.areYouDoneModalActive = false;
      setTimeout(() => {
        this.onMount();
      }, 500);
      this.$router.replace({
        name: "add-sensor",
        query: {
          gatewaySerial: this.gatewaySerial,
          building: this.building.id,
        },
      });
    },
    uninstallSensor() {
      if (!this.area) {
        this.$handleError("No area defined for sensor; Cannot be uninstalled")
        return
      }
      let self = this;
      self.selectedDeviceLoading = true;
      self.area
          .performAction(
            "remove_devices",
            {
              devices: [
                self.selectedDevice.$FSRef,
              ]
            }
          )
          .then(() => {
            self.serial = null;
            self.unbindLivePosition();
            self.$handleSuccess('Please select which sensor you would like to install')
          })
          .catch((e) => {
            self.$handleError('Error uninstalling sensor; See console', e)
          })
          .finally(() => {
            self.selectedDeviceLoading = false;
          })
    },
    /**
     * Finalize installation
     */
    onComplete() {
      if (this.invalidSteps.length) {
        this.$handleError(
            "Please correct any steps with missing data or errors",
            "Please correct any steps with missing data or errors"
        );
        return;
      }
      const self = this;
      self.confirmLoading = true;
      self.area
          .performAction("add_devices", {
            devices: [
              self.selectedGateway.$FSRef,
              {
                device: self.selectedDevice.$FSRef,
                pipe_location: self.pipeLocation.$FSRef,
              },
            ],
          })
          .then(() => self.reloadArea())
          .then(() => {
            self.$store.dispatch('get', {name: 'pipe_locations'});
            return Promise.resolve();
          })
          .then(() => {
            self.$handleSuccess(`Sensor ${self.serial} installation complete`);
            self.areYouDoneModalActive = true;
            if (this.$route.query.next) {
              this.$router.push(this.$route.query.next)
            } else {
              self.$router.push({
                name: 'devices:sensor-detail',
                params: {serial: self.serial}
              })
            }
          })
          .catch((e) => self.$handleError("Could not complete", e))
          .finally(() => (self.confirmLoading = false));
    },
    unbindLivePosition() {
      try {
        this.$unbind("livePositionRaw");
      } catch (e) {
        console.log("No lpR yet");
      }
    },
    unbindLiveGatewayPosition() {
      try {
        this.$unbind("liveGatewayPositionRaw");
      } catch (e) {
        console.log("No unbind:liveGatewayPositionRaw");
      }
    },
    checkPLAssigned() {
      this.checkingPLAssigned = true;
      this.$dataClasses.PipeLocation.queryForSingle([["position", "==", this.position.$FSRef]], {
        order_field: "position",
      })
          .then((obj) => {
            this.preAssignedPipeLocation = obj;
            this.pipeLocationId = obj.id;
          })
          .catch((e) => (this.preAssignedPipeLocation = null))
          .finally(() => (this.checkingPLAssigned = false));
    },
    unassignPreAssignedPL() {
      this.pipeLocationId = null;
      this.unassigningPL = true;
      this.$dataClasses.PipeLocation.save(this.preAssignedPipeLocation.id, {position: null})
          .then(() => {
            this.preAssignedPipeLocation = null;
            this.pipeLocationId = null;
          })
          .finally(() => (this.unassigningPL = false));
    },
  },
  watch: {
    step(val) {
      if (val === 3) {
        this.checkPLAssigned();
      }
    },
    selectedDevice: {
      immediate: true,
      handler(val) {
        if (val && this.step === 1 && this.validate(1)) {
          this.goForward();
          if (this.selectedGateway) {
            let gwSer = this.gatewaySerial;
            this.gatewaySerial = null;
            this.$nextTick(() => (this.gatewaySerial = gwSer));
          }
        }
      },
    },
    pipeLocationId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.pipeLocation = null;
        } else {
          this.loadingPipeLocation = true;
          this.$dataClasses.PipeLocation.fetchById(val)
              .then((result) => {
                this.pipeLocation = result;
                if (this.step === 3) {
                  this.goForward();
                }
              })
              .finally(() => (this.loadingPipeLocation = false));
        }
      },
    },
    selectedGateway: {
      immediate: true,
      handler(gateway) {
        if (!gateway) {
          this.gatewayPosition = null;
          return;
        }

        this.gatewayPositionLoading = true;
        this.$dataClasses.GatewayPosition.queryForSingle([["serial", "==", gateway.serial]])
            .then((obj) => {
              this.gatewayPosition = obj;
            })
            .catch((e) => this.$handleError("Could not load gateway position", e))
            .finally(() => (this.gatewayPositionLoading = false));
      },
    },
    gatewayPosition: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.unbindLivePosition();
          this.unbindLiveGatewayPosition();
        }
        if (!this.selectedDevice || !this.selectedGateway || !this.serial) {
          return;
        }
        this.positionLoading = true;
        let self = this;
        self.$bind("liveGatewayPositionRaw", val.$FSRef);
        self.area
            .performAction("add_devices", {
              devices: [self.selectedGateway.$FSRef, self.selectedDevice.$FSRef],
            })
            .then(() => self.$dataClasses.Position.queryForSingle([["serial", "==", this.serial]]))
            .then((obj) => {
              self.position = obj;
              self.$bind("livePositionRaw", obj.$FSRef);
              return self.area.$enableRanging(10);
            })
            .then(() => this.area.refresh())
            .then((area) =>
                self.$store.commit("add", {name: "areas", result: area})
            )
            .catch((e) =>
                self.$handleError("Could not load position for sensor " + self.serial, e)
            )
            .finally(() => (self.positionLoading = false));
      },
    },
    gatewaySerial: {
      immediate: true,
      handler(serial) {
        if (!serial) {
          this.selectedGateway = null;
          return;
        }
        this.gatewayLoading = true;
        this.$dataClasses.Gateway.queryForSingle([["serial", "==", serial]])
            .then((obj) => (this.selectedGateway = obj))
            .catch((e) => this.$handleError("Could not load gateway " + serial, e))
            .finally(() => (this.gatewayLoading = false));
      },
    },
  },
};
</script>

<style scoped>
.steps ul {
  list-style: none !important;
}

.constrain {
  max-width: 200px;
}

.inline {
  display: inline-block;
}

.ul-doink {
  list-style: disc;
}
</style>
