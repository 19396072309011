<template>
  <div>
    <div class="outer-mongolia">
      <b-modal
          v-if="isInMaintenanceMode"
          :can-cancel="false"
          trap-focus
          active
      >
        <div class="has-text-centered">
          <div class="has-text-centered" >
              <img
                :src="logo"
                alt="AquaSeca logo"
                style="max-width: 350px;"
              />
          </div>
          <div class="has-text-white mt-3">
            <div class="subtitle is-white has-text-white">
              Our systems are currently in maintenance mode
            </div>
            <div>
              AquaSeca applications are unavailable while we make upgrades. Please check back later.
            </div>
          </div>
        </div>
      </b-modal>
      <div v-else>
        <div class="sidebar-page">
          <section class="sidebar-layout">
            <b-sidebar
                :open="showSidebar && sidebarActive"
                type="is-light"
                @close="mobileSidebarToggle = false"
                @open="mobileSidebarToggle = true"
                :can-cancel="!isDesktop && mobileSidebarToggle"
                :overlay="!isDesktop && mobileSidebarToggle"
                fullheight
            >
              <div class="p-1">
                <div class="block mt-2">
                  <img :src="logo" alt="AquaSeca - Water Intelligence"/>
                </div>
                <b-menu class="is-custom-mobile" :accordion="false">
                  <b-menu-list>
                    <b-menu-item
                        :expanded="!!route.children"
                        v-for="route in routesToShow"
                        v-show="showRoute(route, $route)"
                        :icon="route.meta.icon"
                        :key="route.path"
                        :label="
                      route.meta.customIconFilename ? null : route.meta.title
                    "
                        :active="
                      !route.children &&
                      ($route.name === route.name ||
                        $route.name === 'redirect:' + route.name)
                    "
                        @click="routeClicked(route.children ? null : route)"
                    >
                      <template #label v-if="route.meta.customIconFilename">
                        <img
                            class="is-custom-icon"
                            :src="getImgUrl(route.meta.customIconFilename)"
                            :alt="route.meta.customIconFilename"
                        />

                        <span class="label-after-custom-icon">{{
                            route.meta.title
                          }}</span>
                      </template>
                      <template #default v-if="route.children">
                        <TransitionGroup
                            name="slide"
                            tag="span"
                            v-if="
                          showRoute(route, $route) &&
                          route.meta.inSidebar !== 'children-only'
                        "
                        >
                          <b-menu-item
                              expanded
                              :icon="iconFor('home')"
                              :key="route.path"
                              :label="route.meta.title + ' home'"
                              :to="route"
                              tag="router-link"
                              :active="$route.name === route.name"
                          />
                        </TransitionGroup>
                        <TransitionGroup name="slide" tag="span">
                          <b-menu-item
                              expanded
                              v-for="childRoute in routesToShowInSidebar(
                            route.children
                          )"
                              :key="childRoute.path"
                              :to="childRoute"
                              :icon="childRoute.meta.icon || null"
                              :label="
                            childRoute.name !== 'notifications'
                              ? childRoute.meta.title
                              : null
                          "
                              :active="
                            $route.name === childRoute.name ||
                            $route.name.startsWith(childRoute.name + ':') ||
                            $route.name ===
                              childRoute.name.replace('redirect:', '') ||
                            $route.name.startsWith(
                              childRoute.name.replace('redirect:', '') + ':'
                            )
                          "
                              tag="router-link"
                          >
                            <template
                                #default
                                v-if="childRoute.meta.customIconFilename"
                            >
                              <img
                                  class="is-custom-icon"
                                  :src="
                                getImgUrl(childRoute.meta.customIconFilename)
                              "
                              />
                              <span class="label-after-custom-icon">
                              {{ childRoute.meta.title }}
                            </span>
                            </template>
                            <template v-else #label>
                              {{ childRoute.meta.title }}
                            </template>
                          </b-menu-item>
                        </TransitionGroup>
                        <hr
                            class="menu-hr"
                            v-if="route.meta.horizontalRuleAfter"
                        />
                      </template>
                    </b-menu-item>
                  </b-menu-list>
                </b-menu>
              </div>

              <div class="is-sidebar-bottom">
                <div v-if="$store.state.user && $store.state.user_roles">
                  <b-button
                      tag="router-link"
                      :to="{ name: 'profile', params: { userId: user.uid } }"
                      :icon-left="iconFor('profile')"
                      :type="
                    $route.name === 'profile' &&
                    $route.params.userId === user.uid
                      ? 'is-primary'
                      : 'is-light'
                  "
                  >
                    {{ user.email }}
                  </b-button>
                  <b-button
                      tag="router-link"
                      :to="{ name: 'logout' }"
                      :icon-left="iconFor('logout')"
                      :type="$route.name === 'logout' ? 'is-primary' : 'is-light'"
                  >
                    Log-out
                  </b-button>
                </div>
              </div>
            </b-sidebar>
          </section>
          <select-selected-scope/>
        </div>
        <div>
          <b-loading
              v-if="$store.state.userTokenLoading || loading"
              active
              :is-full-page="false"
          />
          <div
              :class="{
            'container-fluid content-container': true,
            'sidebar-active': sidebarActive,
          }"
          >
            <navbar
                @hamburgered="mobileSidebarToggle = !mobileSidebarToggle"
                @scope-select-clicked="showScopeSelect = true"
                :user="user"
                :show-hamburger="!isDesktop"
                :show-breadcrumbs="user && isDesktop"
            />
            <div class="spacer" v-if="sidebarActive">ㅤ</div>
            <router-view
                @go-to-next="$route.query.next ? $router.push($route.query.next) : null"
                v-if="
              !($store.state.user && $store.state.user_roles) ||
              $store.state.selectedScopeIdStr ||
              $route.name === 'logout'
            "
            />
            <select-selected-scope
                v-if="$store.state.user && $store.state.user_roles && $route.name !== 'logout'"
                :active="!$store.state.selectedScopeIdStr || showScopeSelect"
                @close="showScopeSelect = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aqImage from "../components/Files/AqImage";
import SelectSelectedScope from "../components/Selects/SelectSelectedScope";
import {mapState} from "vuex";
import {get} from "lodash";
import WindowSizeMixin from "../mixins/trackWindowSize";
import Logo from "../assets/logo500blue.png";
import logo500blue from "../assets/logo500blue.png";
import Navbar from "./Navbar";
import iconFor from "../icons";
import {register} from "register-service-worker";
import fcmMixin from "../mixins/fcmMixin";
import {firestore} from "../firebase";
import intervalFunctionMixin from "../mixins/intervalFunctionMixin";


register("../registerServiceWorker.js");

/**
 * Main Vue application. This is registered by main.js, which has some
 * 'outer-level' actions and registration.
 */
export default {
  name: "AquaSeca",
  mixins: [WindowSizeMixin, fcmMixin, intervalFunctionMixin],
  components: {Navbar, SelectSelectedScope, aqImage},
  props: {
    millisecondsPerPoll: {
      type: Number,
      default: 1000 * 5,
    }
  },
  data() {
    return {
      logo: Logo,
      logo500blue,
      mobileSidebarToggle: false,
      isInMaintenanceMode: false,
      isInMaintenanceModeIn5Mins: false,
      globalSettings: {},
      routesToShow: [],
      _snack: null,
    };
  },
  firestore: {
    globalSettings: firestore.collection('global_settings').doc('GLOBAL'),
  },
  created() {
    this.updateRoutesToShowInSideBar();
    this.onPoll();
    this.$client.get("/ping/").catch((e) => this.$handleError(e, e));
  },
  methods: {
    onPoll() {
      let maintenanceModeStart = get(this.globalSettings, 'MAINTENANCE_MODE_START');
      if (!maintenanceModeStart) {
        this.isInMaintenanceMode = false;
        this.isInMaintenanceModeIn5Mins = false;
      } else {
        let now = new Date(),
            in5Mins = new Date(),
            maintenanceModeStartDT = this.toDate(maintenanceModeStart);
        in5Mins.setMinutes(now.getMinutes() + 5);
        this.isInMaintenanceMode = now >= maintenanceModeStartDT;
        this.isInMaintenanceModeIn5Mins = this.isInMaintenanceMode ? null : in5Mins >= maintenanceModeStartDT;
      }
    },
    iconFor,
    getImgUrl(iconFilename) {
      var images = require.context("../icons/custom/", false);
      return images("./" + iconFilename);
    },
    handleScopeChange() {
      let self = this;
      if (
          self.$route.query.loadScope &&
          self.$route.query.loadScope !== self.$store.state.selectedScopeIdStr
      ) {
        self.$store.dispatch("setScopeFromIdStr", {
          id_str: self.$route.query.loadScope,
        });
      }
    },
    updateRoutesToShowInSideBar() {
      this.routesToShow = this.routesToShowInSidebar(
          this.$router.options.routes
      );
    },
    routesToShowInSidebar(routes) {
      if (!routes) {
        return [];
      }
      return routes.filter((route) => {
        return route.meta && route.meta.inSidebar && this.showRoute(route);
      });
    },
    showRoute(route) {
      if (!this.user || !this.user_roles.is_viewer) {
        return route.meta && route.meta.public;
      }
      return route.meta
          ? route.meta.inSidebar &&
          (!route.meta.allowIf ||
              route.meta.allowIf({
                user: this.user,
                roles: this.user_roles || {},
                topLevel: this.$store.getters.topLevel,
              })) &&
          (!route.meta.enabledByFeatureFlag ||
              (this.$store.state.user_account &&
                  this.$store.state.user_account.flags &&
                  !!this.$store.state.user_account.flags[
                      route.meta.enabledByFeatureFlag
                      ]))
          : false;
    },
    routeClicked(route) {
      if (route) {
        this.open = false;
        this.goToRoute(route);
      }
    },
    goToRoute(route) {
      this.$nextTick(() => {
        this.$router.push(route);
      });
    },
  },
  computed: {
    ...mapState(["user", "user_roles", "loading"]),

    showScopeSelect: {
      get() {
        return this.$route.query.sss === "1";
      },
      set(val) {
        val ? this.queryReplace({sss: 1}) : this.queryRemoveKeys(["sss"]);
      },
    },
    sidebarActive() {
      return Boolean(this.user);
    },
    showSidebar() {
      return this.isDesktop || this.mobileSidebarToggle;
    },
  },
  watch: {
    isInMaintenanceModeIn5Mins: {
      immediate: true,
      handler(willBe, was) {
        if (was && !willBe) {
          this._snack ? this._snack.close() : null;
          this._snack = null;
        }
        if (willBe && !was && !this.isInMaintenanceMode) {
          let maintModeStart = this.toDate(this.globalSettings.MAINTENANCE_MODE_START);
          this._snack = this.$buefy.snackbar.open({
            message: `
              <p>
                AquaSeca will be in maintenance mode starting at ${maintModeStart.toLocaleTimeString()}.
              </p>
              <br/>
              <p >
                Please complete any work you need to before this time. Our applications will be unavailable
                until system maintenance is completed.
              </p>
            `,
            indefinite: true,
            type: 'is-danger',
            actionText: null,
            cancelText: null,
            position: 'is-bottom',
          })
        }
      }
    },
    user_roles: {
      handler(val, oldVal) {
        this.updateRoutesToShowInSideBar();
        if (
            val &&
            !oldVal &&
            this.$store.getters.requiresScopeSelection &&
            !this.$store.state.selectedScopeIdStr
        ) {
          this.$store.dispatch("showSelectScopeModal", true);
        }
      },
    },
    user_account: {
      handler(val) {
        this.updateRoutesToShowInSideBar();
      },
    },
    "user_account.flags": {
      handler(val) {
        this.updateRoutesToShowInSideBar();
      },
    },
    "$store.getters.topLevel": {
      handler(val) {
        this.updateRoutesToShowInSideBar();
      },
    },
    $route: function (to, from) {
      this.handleScopeChange(to);
      if (this.mobileSidebarToggle) {
        this.mobileSidebarToggle = false;
      }
    },
    '$route.query.loadScope': function(val, prev) {
      if (val !== prev) {
        this.handleScopeChange()
      }
    },
    mobileSidebarToggle(val) {
      if (!val) {
        // otherwise y scroll disappear. Test more
        document.documentElement.classList.remove("is-clipped");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../mixins/buefy/styles.scss";

body {
  min-height: 100vh;
}

.outer-mongolia {
  background-color: $main-background;
  min-height: 100vh;
  margin: 0 auto;
}

.spacer {
  margin-top: 20px;
}

.is-sidebar-bottom {
  margin-top: auto;
  padding: 15px;
}

.container-fluid {
  &.content-container {
    padding-top: 15px;
    padding-bottom: 15px;

    &.sidebar-active {
      padding-right: 10px;
      margin-right: 15px;
      margin-left: 15px;
      @media (min-width: $desktop) {
        margin-right: 5px !important;
        margin-left: $sidebar-width + 15px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.logo {
  margin-top: 15px;
  margin-left: 15px;
  max-height: 30px;
  width: auto;
}

.p {
  color: black;
}

.active-link {
  font-weight: bold;
}

/*SIDEBAR*/
.p-1 {
  padding: 1em;
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}

ul {
  &.menu-list {
    list-style: none;

    li {
      margin-left: 0;

      ul {
        list-style: none;
      }
    }
  }
}

img.is-custom-icon {
  height: 18px;
  width: auto;
  margin-right: 5px !important;
}

.label-after-custom-icon {
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .content-container {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.b-sidebar {
  span.icon {
    margin-right: 8px;
  }

  .sidebar-background {
  }

  .sidebar-content {
    background-color: $sidebar-content-background;

    .menu-list ul {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    &.is-mini {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        ul.menu-list {
          li {
            a {
              span {
                &.icon {
                  width: 100%;
                  text-align: center;
                }

                &:not(.icon) {
                  display: none;
                  @media (max-width: $tablet) {
                    display: inline-block;
                  }
                }
              }
            }

            ul {
              padding-left: 0;
              margin-top: 0;
              margin-bottom: 0;

              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }

        .menu-label:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
