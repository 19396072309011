<template>
  <ul class="ml-4">
    <li v-if="alarm.$flowDataAggWindowReadable">
      <b>Period:</b> {{ alarm.$flowDataAggWindowReadable }}
    </li>
    <li><b>Trigger condition</b>: {{ alarm.$triggerCondition }}</li>
    <li>
      <b>Triggering value</b>:
      {{
        $store.getters.localizedTemperatureString(
          alarm.temperature_data.temperature
        )
      }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "TemperatureDetailBlock",
  props: {
    alarm: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
