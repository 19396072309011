<template>
  <transition name="fade">
    <div
      :class="`sensor-wrapper is-size-${size} ${classes}`"
      v-show="imageLoaded"
      :style="`zoom:${zoom};`"
    >
      <b-tooltip
        :class="{
          pointer:
            position.$notHealthyText &&
            position.$notHealthyText.length &&
            !disableTooltip,
        }"
        :active="!disableTooltip && !!_tooltip"
        :label="_tooltip"
        :multilined="isMobile"
        :position="isTablet ? 'is-right' : 'is-top'"
      >
        <div :style="`opacity:${isInactive ? '.35' : '1.0'};`">
          <img
            v-if="sensorImage"
            :src="sensorImage"
            :class="{
              sensor: true,
              'has-signal-indicator': showSignalIndicator,
            }"
            @load="imageLoaded = true"
          />
          <slot name="within" />
          <sensor-signal-indicator
            :position="position"
            class="signal"
            v-if="showSignalIndicator"
          />
          <sensor-battery :position="position" v-if="showBattery" />
          <div
            :class="{
              serial: true,
              'has-signal-indicator': showSignalIndicator,
            }"
          >
            {{ _serial }}
          </div>
          <slot name="post-serial" v-if="showLocation">
            <div class="dillbee">
              <room-display
                :value="position.$room"
                v-if="position.$room"
                :show-link-icon="false"
                :show-icon="false"
                :show-unit="false"
                :show-floor="false"
              />
            </div>
            <div
              class="dillbee"
              v-if="position.$room && position.$room.$getUnitOrFloorText()"
              style="zoom: 80%"
            >
              {{ position.$room.$getUnitOrFloorText() }}
            </div>
            <div class="dillbee">
              <b-icon
                :icon="iconFor('hot')"
                type="is-hot"
                size="is-small"
                v-if="
                  position.$pipeLocation &&
                  ['hot', 'mixed'].includes(position.$pipeLocation.temperature)
                "
              />
              <b-icon
                :icon="iconFor('cold')"
                type="is-cold"
                size="is-small"
                v-if="
                  position.$pipeLocation &&
                  ['cold', 'mixed'].includes(position.$pipeLocation.temperature)
                "
              />
            </div>
          </slot>
        </div>
      </b-tooltip>
      <slot />
      <slot name="body" />
      <b-tooltip
        v-if="inAutothresholdLoop"
        class="pointer floater"
        type="is-info"
        :label="`This sensor is in an auto-threshold until ${
          autothresholdData.end_timestamp ? toDate(autothresholdData.end_timestamp).toLocaleTimeString() : 'an unknown time'
        }`"
      >
        <b-icon
          icon="assistive-listening-systems"
          class="fa-throb has-text-info"
          size="is-large"
        />
      </b-tooltip>
    </div>
  </transition>
</template>

<script>
import iconFor from "../../icons";
import { get } from "lodash";
import sensorImage from "../../assets/sensor-housing.png";
import trackWindowSize from "../../mixins/trackWindowSize";
import intervalFunctionMixin from "../../mixins/intervalFunctionMixin";

import Position from "../../dataClasses/Position";
import RoomDisplay from "./RoomDisplay";

export default {
  name: "LiveSensorDisplay",
  data() {
    return {
      sensorImage,
      imageLoaded: false,
      msSinceLastSeen: null,
      inAutothresholdLoop: false,
      previouslyInAutothresholdLoop: false,
      autothresholdData: {},
      loaded: false,
    };
  },
  mixins: [intervalFunctionMixin, trackWindowSize],
  components: {
    RoomDisplay,
  },
  methods: {
    iconFor,
    onPoll() {
      let now = new Date();
      // last seen
      this.msSinceLastSeen = this.lastSeenTimestamp
        ? now - this.lastSeenTimestamp
        : null;

      // Autothreshold
      let completionTS = this.toDate(get(this.position, "analytics.autothreshold.completion_timestamp", now)),
        startTS = this.toDate(get(this.position, "analytics.autothreshold.start_timestamp", now)),
        endTS = this.toDate(get(this.position, "analytics.autothreshold.end_timestamp", now));
      this.previouslyInAutothresholdLoop = this.inAutothresholdLoop;
      this.inAutothresholdLoop = startTS < now && (!endTS || endTS > now);
      // console.log({completionTS, startTS, endTS});

      if (this.inAutothresholdLoop) {
        this.autothresholdData = this.inAutothresholdLoop
          ? get(this.position, "analytics.autothreshold", {}) || {}
          : {};
      }
      this.loaded = true;
    },
  },
  computed: {
    exitedAutothreshold() {
      return (
        this.loaded &&
        this.previouslyInAutothresholdLoop &&
        !this.inAutothresholdLoop
      );
    },
    enteredAutothreshold() {
      return (
        this.loaded &&
        !this.previouslyInAutothresholdLoop &&
        this.inAutothresholdLoop
      );
    },
    lastSeenTimestamp() {
      return this.position ? this.position.$lastSeen : null;
    },
    lastSeen() {
      return this.lastSeenTimestamp
        ? this.lastSeenTimestamp.toLocaleString()
        : "N/A";
    },
    _serial() {
      return this.position && this.position.serial
        ? this.position.serial
        : null;
    },
    _tooltip() {
      if (this.tooltip === null) {
        return this.tooltip;
      } else {
        let rssi = get(this.position, "$rssi", "unknown"),
          batteryLevel = get(this.position || {}, "$batteryLevel", "unknown");
        return `Battery: ${batteryLevel}%, RSSI: ${rssi}`;
      }
    },
  },
  props: {
    showLocation: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    zoom: {
      type: String,
      default: "100%",
    },
    showSignalIndicator: {
      type: Boolean,
      default: true,
    },
    showBattery: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "large",
      validator(val) {
        return ["small", "large"].includes(val);
      },
    },
    allowLocationEditing: {
      type: Boolean,
      default: false,
    },
    position: {
      type: [Position, Object],
      required: false,
      default: () => {
        return {};
      },
    },
    serial: {
      required: false,
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
  },
  watch: {
    lastSeenTimestamp: {
      handler(val) {
        if (val) {
          let now = new Date();
          if (val) {
            this.msSinceLastSeen = now - val;
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.dillbee {
  zoom: 80%;
  white-space: nowrap;
  max-width: 88px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sensor-wrapper {
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  width: 70px;
  top: 0;

  .sensor {
    top: 0;
    position: relative;
    width: 61px !important;

    &.has-signal-indicator {
      top: 44px;
    }
  }

  .signal {
    position: relative;
    left: 0;
    top: -158px;
  }

  .battery {
    position: relative;
    top: 5px;
    left: 25px;
  }

  .serial {
    font-size: 10px;

    .has-signal-indicator {
      top: -52px;
    }

    left: -2px;
    text-align: center;
    position: relative;
    width: 68px;
    padding-top: 2px;
    margin: 0;
    overflow-x: hidden;
  }
}

.floater {
  position: absolute;
  top: 128px;
  right: 12px;
}
</style>
