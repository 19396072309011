/**
 *
 * @param value {Number}
 * @param acceptableValues {Array<Number>}
 * @returns {Number,undefined}
 */
export function floorToValue(value, acceptableValues) {
  const _acceptableValues = acceptableValues.sort().reverse();
  for (let i = 0; i < _acceptableValues.length; i++) {
    if (value >= _acceptableValues[i]) {
      return _acceptableValues[i]
    }
  }
}