import FirestoreDataClass from "./_baseClasses";
import { getObjectInArrayById } from "../scripts/filterHelpers";
import shortHumanizer, {
  readableHumanizer,
} from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import capitalizeFirstLetter from "../mixins/global/capitalizeFirstLetter";
import durationHumanizerShort from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import { get } from "lodash";
import {toDate} from "../appLevelFunctions/toDate";
import store from "../store";

export default class Alarm extends FirestoreDataClass {
  static collectionName = "alarms";

  /**
   *
   * @param {Site} site
   * @param {String} category
   * @param {Number} paginate_by
   * @param {Date} startDate
   * @param {Array.<Array<String,String,Any>>}
   */
  static byTopic({
    site,
    category,
    paginate_by = 500,
    startDate = null,
    additionalQueries = [],
  }) {
    if (!site || !category) {
      throw "Site and category required for alarm query";
    }
    let queries = [
      ["site", "==", site.$FSRef],
      ["categories", "array_contains", category],
      ...additionalQueries,
    ];
    if (startDate) {
      queries.unshift(["last_update_dt", ">=", startDate]);
    }
    return this.query(queries, {
      order_field: "last_update_dt",
      direction: "desc",
      paginate_by,
    });
  }

  sensorData(serial) {
    let matches = this.get('sensors').filter(sensorEntry => sensorEntry.serial === serial);
    return (matches && matches.length) ? matches[0] : null;
  }

  get $displayString() {
    return this.get("description");
  }

  get $startTime() {
    let start = this.get("flow_data.timestamp", null);
    if (start) {
      start = toDate(start)
    }
    return start;
  }

  get $duration() {
    return this.get("flow_data.duration_seconds", 0);
  }

  get $endTime() {
    return this.$startTime ? new Date(this.$startTime.getTime() + (this.$duration * 1000)) : null;
  }

  get $site() {
    return getObjectInArrayById(store.state.sites, this.site);
  }

  get $area() {
    return getObjectInArrayById(store.state.areas, this.area);
  }

  get $classifiedAsSource() {
    return getObjectInArrayById(
      store.state.sources,
      this.get("classified_as.source.id")
    );
  }

  get $predictedSource() {
    return getObjectInArrayById(
      store.state.sources,
      this.get("flow_data.source.id")
    );
  }

  get $flowDataAggWindow() {
    return this.get("flow_data.agg_window_seconds");
  }

  get $flowDataAggWindowReadable() {
    return this.$flowDataAggWindow
      ? readableHumanizer(this.$flowDataAggWindow * 1000)
      : 0;
  }

  get $BClass() {
    return "is-" + this.get("topic", "info");
  }

  get $title() {
    if (!this.topic) {
      return capitalizeFirstLetter(this.alert_type);
    } else if (this.topic === "flow") {
      return `Flow ${this.alert_type}${
        this.$flowLength ? ": " + this.$flowLengthReadable + " flow" : ""
      }`;
    } else if (this.topic === "source_selection") {
      return this.getSourceSelectionTitle();
    } else if (this.topic === "noflow") {
      return this.getStagnationTitle();
    } else if (this.topic === "health") {
      return this.description;
    } else if (this.topic === "calibrate") {
      return "Calibration: " + this.description;
    } else if (this.topic === "temperature") {
      return this.getTemperatureTitle();
    } else {
      return "!" + this.topic;
    }
  }

  get $isWarning() {
    return ["alarm", "warning"].includes(this.alert_type);
  }

  get $triggerCondition() {
    const agg_window = get(this, "temperature_data.agg_window", "?"),
      feature = get(this, "temperature_data.feature", "?").replace("_", " "),
      operator = get(this, "temperature_data.operator", "?"),
      limitRaw = get(this, "temperature_data.limit", null),
      localizedTempString = limitRaw
        ? store.getters.localizedTemperatureString(limitRaw)
        : null;
    return `${agg_window} ${feature} ${operator} ${localizedTempString}`;
  }

  getTemperatureTitle() {
    const data = this.temperature_data;

    const threshold = store.getters.localizedTemperatureString(data.limit),
      verb = data.temperature > data.limit ? "exceeded" : "subceeded";
    return `Temperature limit of ${threshold} ${verb}`;
  }

  getSourceSelectionTitle() {
    let duration = this.get("flow_data.duration_seconds", 0),
      classifiedAs = this.get("classified_as.flowType");
    if (this.$classifiedAsSource) {
      return (
        `${shortHumanizer(
          duration * 1000
        )} event classified as ${classifiedAs}` +
        (this.$classifiedAsSource
          ? " from " + this.$classifiedAsSource.name
          : "")
      );
    }
    let predictedSourceRecast =
      this.get("$predictedSource.name", "").toLowerCase() === "src:anomaly"
        ? "unknown source"
        : this.predictedSource && this.predictedSource.name
        ? this.predictedSource.name
        : "unknown source";
    return this.predictedSource
      ? "Prediction: " +
          readableHumanizer(duration * 1000) +
          " from " +
          predictedSourceRecast
      : "Please classify flow: " +
          readableHumanizer(duration * 1000) +
          " from unknown source";
  }

  /**
   *
   * @param flow_type {'flow'|'noflow'|'unknown'}
   * @param source_id {String}
   * @returns {Promise<unknown>}
   */
  classifyClip({ flow_type, source_id }) {
    const self = this;
    if (self.topic !== "source_selection") {
      throw "Cannot classify clip for alarm without `source_selection` topic";
    }
    return new Promise(function (resolve, reject) {
      self.constructor
        .$client
        .post(
          `${self.constructor.APIEndpointSegment}/${self.id}/classify_clip/`,
          { flow_type, source_id }
        )
        .then(() => resolve())
        .catch((e) => reject(e));
    });
  }

  getStagnationTitle() {
    let duration = this.$flowLengthReadable.replace(" ", ""),
      minimumDuration = this.$noFlowExpectedFlowLength.replace(" ", "");
    return `Stagnation: Expected ${minimumDuration}, recorded ${duration}`;
  }

  get $flowLength() {
    return this.get("flow_data.duration_seconds");
  }

  get $noFlowExpectedFlowLength() {
    const rds = get(this, "flow_data.required_duration_seconds", null);
    return rds ? durationHumanizerShort(rds * 1000) : "0 s";
  }

  get $flowLengthReadable() {
    return this.$flowLength ? shortHumanizer(this.$flowLength * 1000) : "0 s";
  }
}
