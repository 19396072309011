<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">Manage Bundles/Shipments</div>
    </div>
    <div class="card-content">
      <select-bundle
        @select="select($event)"
        v-if="$route.name === 'administration:shipments'"
      />
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import SelectBundle from "../../components/Selects/SelectBundle";
export default {
  name: "ManageShipments",
  components: { SelectBundle },
  methods: {
    select(val) {
      this.$router.push({
        name: "administration:shipments:view",
        params: { id: val.id },
      });
    },
  },
};
</script>

<style scoped></style>
