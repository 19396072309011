<template>
  <ul class="ml-4">
    <li v-if="alarm.$noFlowExpectedFlowLength">
      <b>Expected flow length</b>:
      {{ alarm.$noFlowExpectedFlowLength }}
    </li>
    <li><b>Recorded flow length</b>: {{ alarm.$flowLengthReadable }}</li>
    <li v-if="alarm.$flowDataAggWindowReadable">
      <b>Period:</b> {{ alarm.$flowDataAggWindowReadable }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "NoFlowBlock",
  props: {
    alarm: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
