import FirestoreDataClass from "./_baseClasses";
import router from "../router";
import { getObjectInArrayById } from "../scripts/filterHelpers";

import shortHumanizer from "../components/DataVisualations/DataTransformations/durationHumanizerShort";
import bounded0To100 from "../scripts/bounded0To100";
import { Timestamp } from "../firebase";
import toId from "../appLevelFunctions/toId";

export const batteryAtZeroVoltageLevel = 1.8;
export const batteryAtFullVoltageLevel = 2.8;
export const healthyIfSeenWithinMinutes = 16.5;
export const healthyBatteryPercentage = 10;
export const batteryLevelRange = batteryAtFullVoltageLevel - batteryAtZeroVoltageLevel;

import store from "../store";

export default class Position extends FirestoreDataClass {
  static collectionName = "positions";

  get $anyPortInUse() {
    return this.$availablePorts.length < 2;
  }

  get $siteIdStr() {
    return this.id_str.split("|").slice(0,5).join("|")
  }

  get $availablePorts() {
    const ports = [];
    if (!this.get("port_1.serial")) {
      ports.push(1);
    }
    if (!this.get("port_2.serial")) {
      ports.push(2);
    }
    return ports;
  }

  get $calibrationState() {
    return this.get("analytics.calibration.state", "Unknown");
  }

  $enterTrainingMode(args = {}) {
    // args.devices = [this.$FSRef];
    return this.performAction("continue_calibration", args);
  }

  get $isInTrainingMode() {
    return ["precalibration", "recalibration"].includes(this.$calibrationState);
  }

  get $hasAvailablePort() {
    return this.$availablePorts.length > 0;
  }

  get $canAttachProbe() {
    return this.$availablePorts.length > 0;
  }

  get $canRemoveProbe() {
    return this.$anyPortInUse;
  }

  get $qrCodeUrl() {
    if (!this.serial) {
      throw "Can not view QR code for device without serial";
    }
    return `${this.$client.basePath}/devices/qr_code/?serial=${this.serial}`;
  }

  $getPipeLocationIdForProbeSerial(serial) {
    if (this.get("port_0.serial") === serial) {
      return this.get("port_0.pipe_location.id");
    }
    if (this.get("port_1.serial") === serial) {
      return this.get("port_1.pipe_location.id");
    }
    if (this.get("port_2.serial") === serial) {
      return this.get("port_2.pipe_location.id");
    }
  }

  $goToSensorDetail() {
    return router.push({
      name: 'devices:sensor-detail',
      params: {serial: this.serial}
    })
  }

  $goToAttachProbe(building) {
    return router.push({
      name: "attach-probe",
      query: {
        sensorSerial: this.serial,
        building: this.get("$area.building.id"),
        sm1: "select",
      },
    });
  }

  $goToRemoveProbe() {
    return router.push({
      name: "remove-probe",
      query: {
        sensorSerial: this.serial,
        building: this.get("$area.building.id"),
      },
    });
  }

  $goToRemoveSensor() {
    return router.push({
      name: "remove-sensor",
      query: {
        serials: [this.serial],
        building: this.get("$area.building.id"),
      },
    });
  }

  get $site() {
    return this.site
      ? getObjectInArrayById(store.state.sites, this.site.id, null)
      : null;
  }

  /**
   *
   * @return {Area|null}
   */
  get $area() {
    return this.area
      ? getObjectInArrayById(store.state.areas, this.area, null)
      : null;
  }

  get $areaSensorEntry() {
    return this.$area ? this.$area.$getSensorEntry(this.serial) : null;
  }

  get $detectThreshold() {
    return this.get("$areaSensorEntry.settings.detect_threshold");
  }

  get $hierarchyObjects() {
    return {
      Area: this.$building,
      Room: this.$room,
      "Pipe location": this.$pipeLocation,
    };
  }

  get $building() {
    return this.building
      ? getObjectInArrayById(store.state.buildings, this.building.id, null)
      : null;
  }

  get $room() {
    return this.room
      ? getObjectInArrayById(store.state.rooms, toId(this.room), null)
      : null;
  }

  get $pipeLocation() {
    return this.pipe_location ?
      getObjectInArrayById(store.state.pipe_locations, toId(this.pipe_location), null) :
      null;
  }

  get $deviceType() {
    return "sensor";
  }

  get $batteryLevelRaw() {
    return this.get("telemetry.status.vbatt", batteryAtZeroVoltageLevel);
  }

  get $batteryLevel() {
    let voltsAboveFloor = this.$batteryLevelRaw - batteryAtZeroVoltageLevel,
      asPercentage = Math.min((voltsAboveFloor / batteryLevelRange) * 100, 100);
    return bounded0To100({val:asPercentage});
  }

  get $rssi() {
    return this.get("telemetry.status.rssi", -110);
  }

  get $categoricalSignalStrength() {
    if (
      !this.$rssi ||
      this.$msSinceLastSeen > healthyIfSeenWithinMinutes * 60 * 1000
    ) {
      return 0;
    } else if (this.$rssi > -70) {
      return 4; // none
    } else if (-70 >= this.$rssi && this.$rssi > -85) {
      return 3;
    } else if (-85 >= this.$rssi && this.$rssi > -92) {
      return 2;
    } else if (-92 >= this.$rssi && this.$rssi > -100) {
      return 1;
    } else {
      return 0;
    }
  }

  get $lastSeen() {
    const val = this.get("telemetry.status.timestamp");
    return val instanceof Timestamp ? val.toDate() : val;
  }

  get $msSinceLastSeen() {
    return this.$lastSeen ? new Date() - this.$lastSeen.getTime() : null;
  }

  get $secondsSinceLastSeen() {
    return this.$msSinceLastSeen
      ? Math.floor(this.$msSinceLastSeen / 1000)
      : null;
  }

  get $sinceLastSeenText() {
    return this.$msSinceLastSeen
      ? "Last seen " +
          shortHumanizer(this.$secondsSinceLastSeen * 1000).replaceAll(
            " ",
            ""
          ) +
          " ago"
      : "No record of last connection";
  }

  get $lastSeenClassification() {
    if (
      !this.$msSinceLastSeen ||
      this.$secondsSinceLastSeen > 60 * healthyIfSeenWithinMinutes
    ) {
      return "danger";
    } else {
      return "success";
    }
  }

  get $isHealthy() {
    return [
      this.$lastSeenClassification === "success",
      this.$batteryLevel >= healthyBatteryPercentage,
    ].every((c) => !!c);
  }

  get $notHealthyText() {
    const issues = [];
    if (this.$lastSeenClassification !== "success") {
      issues.push(this.$sinceLastSeenText);
    }
    if (this.$batteryLevel < healthyBatteryPercentage) {
      issues.push(`Battery at ${this.$batteryLevel}%`);
    }
    return issues.join("; ");
  }

  get $lastSeenBClass() {
    return `is-${this.$lastSeenClassification}`;
  }

  get $lastSeenTextClass() {
    return `has-text-${this.$lastSeenClassification}`;
  }

  get $lastAutothresholdTimestamp() {
    const val = this.get("analytics.autothreshold.completion_timestamp");
    return val instanceof Timestamp ? val.toDate() : val;
  }

  get $isCurrentlyInAutothreshold() {
    const now = new Date();
    return (
      this.$lastAutothresholdTimestamp && now > this.$lastAutothresholdTimestamp
    );
  }

  get $thresholdLevel() {
    return
  }
}
