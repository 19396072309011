<template>
  <table class="table is-fullwidth">
    <thead>
    <tr>
      <th class="has-text-centered">
        POU 1
      </th>
      <th class="has-text-centered">
        POU 2
      </th>
      <th class="has-text-centered">
        Distance score
      </th>
      <th class="has-text-centered">
        Suggested action
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="row in rows"
        :key="row.source_1.id+':'+row.source_2.id+':'+row.Distance"
    >
      <td>
        <source-display
            :value="getObjectInArrayById($store.state.sources, toId(row.source_1,'sources'))"
            show-room
            :show-tooltip="false"
            :show-link-icon="false"
            show-temp-icon
            :show-training-level="false"
        />
      </td>
      <td>
        <source-display
            :value="getObjectInArrayById($store.state.sources, toId(row.source_2,'sources'))"
            show-room
            :show-tooltip="false"
            :show-link-icon="false"
            show-temp-icon
            :show-training-level="false"
        />
      </td>
      <td :class="row.Distance < 1 ? 'has-background-danger' : row.Distance < 2 ? 'has-background-warning' : 'has-background-success'">
                            <span class="has-text-white mt-1" style="text-shadow: 1px 1px black;">
                              {{ row.Distance.toFixed(3) }}
                            </span>
      </td>
      <td>
        <b-button
            class="mt-1"
            size="is-small"
            @click="$emit('input', [
              getObjectInArrayById($store.state.sources, toId(row.source_1,'sources')),
              getObjectInArrayById($store.state.sources, toId(row.source_2,'sources')),
            ])"
            type="is-success"
        >
          Add training
        </b-button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {getObjectInArrayById} from "../scripts/filterHelpers";
export default {
  name: "SimilaritySummaryTable",
  props: {
    rows: {
      type: Array,
      required: true,
    }
  },
  methods: {getObjectInArrayById},
}
</script>

<style scoped>

</style>