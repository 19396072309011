<template>
  <div class="card" v-show="value">
    <section class="card-content">
      <b-loading :is-full-page="false" v-if="spinner" />
      <section>
        <div class="block">
          <b-skeleton width="15%" animated />
          <b-skeleton width="25%" animated />
          <b-skeleton width="55%" animated />
          <b-skeleton width="39%" animated />
          <b-skeleton width="4%" animated />
          <b-skeleton width="59%" animated />
          <b-skeleton width="80%" animated />
          <b-skeleton width="5%" animated />
          <b-skeleton width="15%" animated />
          <b-skeleton width="39%" animated />
          <b-skeleton width="4%" animated />
          <b-skeleton width="59%" animated />
          <b-skeleton width="90%" animated />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "loading-chart-card",
  props: {
    /**
     * Whether to show animated skeleton
     */
    value: {
      type: Boolean,
      default: true,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
